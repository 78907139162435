import React from "react";
import css from './password-indicator.module.scss';

const PasswordStrengthIndicator = ({
    validity: { minChar, number, specialChar, upperCaseChar, lowerCaseChar }
}) => {
    return (
        <div className="password-meter text-left mb-4">
            <p className="text-dark">Password must contain:</p>
            <ul className="text-muted">
                <PasswordStrengthIndicatorItem
                    isValid={minChar}
                    text="Have at least 8 characters"
                />
                <PasswordStrengthIndicatorItem
                    isValid={number}
                    text="Have at least 1 number"
                />
                <PasswordStrengthIndicatorItem
                    isValid={upperCaseChar}
                    text="Have at least 1 upper case letter"
                />
                <PasswordStrengthIndicatorItem
                    isValid={lowerCaseChar}
                    text="Have at least 1 lower case letter"
                />
                <PasswordStrengthIndicatorItem
                    isValid={specialChar}
                    text="Have at least 1 special character"
                />
            </ul>
        </div>
    );
};

const PasswordStrengthIndicatorItem = ({ isValid, text }) => {
    const highlightClass = isValid
        ? css.successText
        : isValid !== null
            ? css.warningText
            : "";
    return <li className={highlightClass}>{text}</li>;
};

export default PasswordStrengthIndicator;