import * as React from 'react';
import { Circle } from 'components/Circle';
import css from './resource-list-item.module.scss';
import classes from 'classnames';

interface IResourceListItemProps {
	Icon: any;
	fhirPits?: string[];
}

interface IResourceListItemState {
	isListActive: boolean;
}

export class ResourceListItem extends React.PureComponent<IResourceListItemProps, IResourceListItemState> {
	constructor(props) {
		super(props);
		this.state = {
			isListActive: false
		};
	}

	toggleListActiveState = () => {
		this.setState({
			isListActive: !this.state.isListActive
		});
	};

	render() {
		const { Icon, fhirPits, children } = this.props;
		return (
			<li className={css.resourceListItem}>
				<Circle size='small' className={css.resourceListItemIcon}>
					<Icon className={css.resourceListItemIconSvg} />
				</Circle>
				<div>
					<h3 className={classes(css.title, 'h6')}>{children}</h3>
					{fhirPits && fhirPits.length > 1 && (
						<button className={css.button} onClick={this.toggleListActiveState}>
							{this.state.isListActive ? 'View Less' : `${fhirPits.length} FHIR PITs`}
						</button>
					)}

					{fhirPits && (
						<>
							{fhirPits.length > 1 ? (
								this.state.isListActive && (
									<ul className={classes('reset', css.list)}>
										{fhirPits.map((fhirPitName, index) => (
											<li key={index} className={classes(css.listItem, css.item)}>
												{fhirPitName.replace(/-/g, ' ')}
											</li>
										))}
									</ul>
								)
							) : (
								<span className={css.item}>{fhirPits[0].replace(/-/g, ' ')}</span>
							)}
						</>
					)}
				</div>
			</li>
		);
	}
}
