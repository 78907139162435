export interface ISmartApp {
	name: string;
	id?: number; 
	client_id: string;
	client_secret?: string;
	client_type: number;
	grant_type: number;
	registered_on: string;
	redirect_uris: string[];
	oauth_scopes: string;
	oauth_server_uri: string;
}

export const client_type_map = {
	1: 'Public',
	2: 'Confidential - Forms Auth',
	3: 'Confidential - Basic Auth'
}

export const grant_type_map = {
	1: 'Authorization Code',
	2: 'Implicit',
	3: 'Client Credentials',
	4: 'Password'
}
