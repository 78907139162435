import * as React from 'react';
import css from './familymember-title-area.module.scss';
import { PageBreadcrumbs } from 'components/Page';
import classes from 'classnames';
import { IPatient } from 'common/interfaces/personaBuilder/IPatient';

interface IFamilyMemberTitleAreaProps {
	patient: IPatient;
}

export const FamilyMemberTitleArea: React.FC<IFamilyMemberTitleAreaProps> = props => {
		const {	patient } = props;

		return (
			<div
				className={classes(css.pageTitleArea, css.center)}
				role='banner'
			>
				<div>
					<PageBreadcrumbs breadcrumbItems={[
						{
							label: 'Creation Dashboard',
							location: '/persona-builder'
						},
						{
							label: 'All Patient Family Members',
							location: `/persona-builder/patient-listing/family`
						},
						{
							label: `${patient.first_name} ${patient.last_name}`,
							location: `/persona-builder/patient-listing/family/${patient.id}`
						}
					]} />
					<h1 className={classes('h2', css.title)}>
            {patient.first_name} {patient.last_name}
					</h1>
				</div>

				<ul className='reset'>
					<li style={{display: 'inline', padding: 12}}>
						
					</li>

				</ul>
			</div>
		);
}