export const hl7Valuesets: { type: string; api_endpoint: string; label: string; columns: any; }[] = [
	{
    type: 'allergy_reactions',
    api_endpoint: 'allergy_reactions',
    label: 'Allergy Reactions',
    columns: [
      {
        name: 'Display',
        sortable: true,
        selector: 'allergy_reaction_display',
      },
      {
        name: 'SNOMED Code',
        sortable: true,
        selector: 'allergy_reaction_snomed',
      }
    ]
  },
  { 
    type: 'allergy_substances',
    api_endpoint: 'allergy_substances',
    label: 'Allergy Substances',
    columns: [
      {
        name: 'Display',
        sortable: true,
        selector: 'allergy_substance_display',
      },
      {
        name: 'SNOMED Code',
        sortable: true,
        selector: 'allergy_substance_snomed',
      }
    ]
  },
  { 
    type: 'body_sites',
    api_endpoint: 'body_sites',
    label: 'Body Sites',
    columns: [
      {
        name: 'Display',
        sortable: true,
        selector: 'body_site',
      },
      {
        name: 'SNOMED Code',
        sortable: true,
        selector: 'body_site_code',
      }
    ]
  },
  { 
    type: 'diagnosis',
    api_endpoint: 'diagnoses',
    label: 'Diagnosis',
    columns: [
      {
        name: 'Name',
        sortable: true,
        selector: 'diagnosis_name',
      },
      {
        name: 'ICD10',
        sortable: true,
        selector: 'diagnosis_icd10_code',
      },
      {
        name: 'SNOMED Code',
        sortable: true,
        selector: 'diagnosis_snomed_code',
      }
    ]
  },
  // { 
  //   type: 'dispositions',
  //   api_endpoint: '',
  //   label: 'Dispositions'
  // },
  // { 
  //   type: 'dosage_reason_as_needed',
  //   api_endpoint: '',
  //   label: 'Dosage Reasons as Needed'
  // },
  // { 
  //   type: 'evaluation',
  //   api_endpoint: '',
  //   label: 'Evaluations'
  // },
  // { 
  //   type: 'goal_code',
  //   api_endpoint: '',
  //   label: 'Goal Codes'
  // },
  // { 
  //   type: 'goal_categories',
  //   api_endpoint: '',
  //   label: 'Goal Categories'
  // },
  // { 
  //   type: 'goal_outcomes',
  //   api_endpoint: '',
  //   label: 'Goal Outcomes'
  // },
  // { 
  //   type: 'physician_orders',
  //   api_endpoint: '',
  //   label: 'Physician Orders'
  // },
  // { 
  //   type: 'reason_code',
  //   api_endpoint: '',
  //   label: 'Reason Codes'
  // },
  // { 
  //   type: 'service_orders',
  //   api_endpoint: '',
  //   label: 'Service Codes'
  // }
];
