import * as React from 'react';

import { AppFrame } from 'components/Page/AppFrame';

import { connect, ResolveThunks } from 'react-redux';
import { organizationActionCreators } from 'stores/actions/organizationActions';
import { modalActionCreators } from 'stores/actions/modalActions';
import { ApplicationState } from 'stores';

import { IOrganization } from 'common/interfaces';

import { Link } from 'react-router-dom';

import { TableBody, TableRow, TableColumn, TableHeader } from 'components/Table';
import { PageTitleArea, InnerContainer } from 'components/Page';
import { FormOrganization } from 'components/Forms/FormOrganization';
import { IModal } from 'common/interfaces';
import { format } from 'date-fns';

import { convertIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';
import { EUpdateState } from 'stores/shared/ENums';
import { TSetErrorMessage } from 'common/types/TSetErrorMessage';
import { TSetFormLoadingAttribute } from 'common/types/TSetFormLoadingAttribute';
import { Loading } from 'components/Loading';

interface OrganizationManagementViewReduxProps {
	organizations: IOrganization[];
	allOrganizationsLoadingState: EUpdateState;
}

const OrganizationManagementViewActionCreators = {
	...organizationActionCreators,
	...modalActionCreators,
};

class OrganizationManagementViewBase extends React.PureComponent<
	OrganizationManagementViewReduxProps &
	ResolveThunks<typeof OrganizationManagementViewActionCreators & typeof modalActionCreators>
> {
	UNSAFE_componentWillMount() {
		this.props.getOrganizations();
	}

	addNewOrganization = (
		organization: IOrganization,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute
	) => {
		this.props.createOrganization(organization, setErrorMessage, setFormLoadingAttribute);
	};

	isLoading = (): boolean => {
		return !(this.props.allOrganizationsLoadingState === EUpdateState.Loaded);
	};

	render() {
		const { organizations, createModal, allOrganizationsLoadingState } = this.props;

		const addOrganizationModal: IModal = {
			primaryButtonLabel: 'Create Organization',
			content: FormOrganization,
			title: 'Add New Organization',
			formAction: this.addNewOrganization,
		};

		if (this.isLoading()) {
			return (
				<AppFrame>
					<Loading />
				</AppFrame>
			);
		}

		return (
			<AppFrame>
				<InnerContainer>
					<PageTitleArea
						buttonList={[
							{
								buttonLabel: 'Add new organization',
								buttonAction: (event) => createModal(addOrganizationModal, event),
							},
						]}
					>
						Organization Management
					</PageTitleArea>
					<TableBody>
						<TableHeader>
							<TableColumn>Organization&nbsp;Name</TableColumn>
							<TableColumn>Organization&nbsp;Code</TableColumn>
							<TableColumn>Contact&nbsp;Email</TableColumn>
							<TableColumn>Address</TableColumn>
							<TableColumn>Member&nbsp;Since</TableColumn>
						</TableHeader>
						{organizations &&
							organizations.map((item, index) => {
								return (
									<TableRow key={index}>
										<TableColumn>
											<Link to={`/organization/${item.id}`}>{item.name}</Link>
										</TableColumn>
										<TableColumn>{item.code}</TableColumn>
										<TableColumn>{item.contact_email}</TableColumn>
										<TableColumn>
											{item.address}, {item.city}, {item.state} {item.postal_code}
										</TableColumn>
										<TableColumn>{format(new Date(item.created_at), 'MM/dd/yyyy')}</TableColumn>
									</TableRow>
								);
							})}
					</TableBody>
				</InnerContainer>
			</AppFrame>
		);
	}
}

const mapStateToProps = (state: ApplicationState): OrganizationManagementViewReduxProps => {
	const organizations = convertIndexedObjectToArray(state.organization.organizations).filter(
		(organization) => typeof organization.parent_organization_id !== 'number'
	);

	return {
		organizations: organizations ? organizations : [],
		allOrganizationsLoadingState: state.organization.allOrganizationsLoadingState,
	};
};

export const OrganizationManagementView = connect(
	mapStateToProps,
	OrganizationManagementViewActionCreators
)(OrganizationManagementViewBase);
