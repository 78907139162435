import * as React from 'react';
import classes from 'classnames';
import { NavLink } from 'react-router-dom';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { authActionCreators } from 'stores/actions/authActions';
import css from './header-drop-down-menu.module.scss';


interface IHeaderDropDownMenuProps {
	isShown: boolean;
	setVisibility: (isVisible: boolean) => void;
	ignoreTarget: any;
}

interface IHeaderDropDownMenuReduxProps {
	currentUserType: string;
}

const headerDropDownMenuActionCreators = {
	...authActionCreators
};

class HeaderDropDownMenuBase extends React.PureComponent<
	IHeaderDropDownMenuProps & IHeaderDropDownMenuReduxProps & ResolveThunks<typeof headerDropDownMenuActionCreators>
> {
	logOut = () => {
		this.props.signOut();
	};

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside, true);
		document.addEventListener('keyup', this.dismissOnEscape, true);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside, true);
		document.removeEventListener('keyup', this.dismissOnEscape, true);
	}

	dismissOnEscape = event => {
		if (event.keyCode === 27) {
			this.props.setVisibility(false);
		}
	};

	handleClickOutside = event => {
		const domNode = this.props.ignoreTarget;

		if (!domNode || !domNode.contains(event.target)) {
			this.props.setVisibility(false);
		}
	};

	render() {
		const { isShown, currentUserType } = this.props;
		return (
			<div
				className={classes('reset', css.dropDown, {
					[css.shown]: isShown
				})}
			>
				<ul className='reset'>
					<li>
						<NavLink className={css.dropDownItem} to='/settings'>
							Account Settings
						</NavLink>
					</li>
					<li>
						<NavLink className={css.dropDownItem} to='/notification-center'>
							Notification
						</NavLink>
					</li>
					{currentUserType === 'mihin-admin' && (
						<>
							<li>
								<NavLink className={css.dropDownItem} to='/organization-management'>
									Organization Management
								</NavLink>
							</li>
							<li>
								<NavLink className={css.dropDownItem} to='/user-management'>
									User Management
								</NavLink>
							</li>
						</>
					)}
					<li>
						<NavLink className={css.dropDownItem} to='/rof-management'>
							Sandbox Management
						</NavLink>
					</li>

					<li>
						<NavLink className={css.dropDownItem} to='/faq'>
							Frequently Asked Questions
						</NavLink>
					</li>
					<li>
						<button className={css.dropDownItem} onClick={this.logOut}>
							Log Out
						</button>
					</li>
				</ul>
			</div>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IHeaderDropDownMenuReduxProps => ({
	currentUserType: state.auth.user.attributes ? state.auth.user.attributes['custom:user_type'] : null
});

export const HeaderDropDownMenu = connect(
	mapStateToProps,
	headerDropDownMenuActionCreators
)(HeaderDropDownMenuBase);
