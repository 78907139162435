import React from 'react';
import css from './radio.module.scss';
import classes from 'classnames';

export const Radio = ({
	field: { name, value, onChange, onBlur },
	id,
	label,
	className,
	...props
}) => {
	return (
		<div className={classes(css.inputWithLabel, className)}>
			<input
				name={name}
				id={id}
				type='radio'
				value={id}
				checked={id === value}
				onChange={onChange}
				onBlur={onBlur}
				className={classes('radio-button', css.input)}
				{...props}
			/>
			{/*<span className={css.radioVisible} />*/}
			{label && (
				<label className={css.label} htmlFor={id}>
					{label}
				</label>
			)}
		</div>
	);
};
