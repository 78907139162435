import * as React from 'react';
import css from './loading-spinner.module.scss';
import classes from 'classnames';

interface ILoadingSpinnerProps {
	className?: string;
	type?: 'light' | 'dark';
	size?: 'small' | 'medium';
}

export class LoadingSpinner extends React.PureComponent<ILoadingSpinnerProps> {
	static defaultProps = {
		type: 'dark'
	};
	render() {
		const { className, type, size } = this.props;

		return (
			<div
				className={classes(css.loadingSpinner, className, css[type], {
					[css[size]]: size
				})}
			/>
		);
	}
}
