import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { TableBody, TableColumn, TableHeader, TableRow, TableColumnDelete } from 'components/Table';
import { ISmartAuthUser, SmartAuthUserType } from 'common/interfaces';
import { Button } from 'components/Button';
import { modalActionCreators } from 'stores/actions/modalActions';
import { rofActionCreators } from 'stores/actions/rofActions';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from 'stores';
import { Message } from 'components/Message';
import { EUpdateState } from 'stores/shared/ENums';
import { IModal } from 'common/interfaces/IModal';
import { TableActionBarTop } from 'components/Table';
import css from './smart-auth-users-tab.module.scss';
import { format } from 'date-fns';
import { convertStringIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';
import { Loading } from 'components/Loading';
import { deleteSmartAuthUserModal } from 'common/modals/deleteSmartAuthUserModal';
import { FormCreateSmartAuthUser } from 'components/Forms/FormCreateSmartAuthUser/FormCreateSmartAuthUser';

const smartAuthUsersTabActionCreators = {
	...modalActionCreators,
	...rofActionCreators
};

interface RofRouteProps {
	rofId?: string;
	pitId?: string;
}

interface SmartAppsTabsReduxProps {
	smartAuthUsers: ISmartAuthUser[];
	smartAuthUsersLoadingState: EUpdateState;
	currentFhirPitName: string;
	currentRofId: number;
	currentPitId: number;
}

class SmartAuthUsersTabBase extends React.PureComponent<
	RouteComponentProps<RofRouteProps> & ResolveThunks<typeof smartAuthUsersTabActionCreators> & SmartAppsTabsReduxProps
> {
	UNSAFE_componentWillMount() {
		const { rofId, pitId } = this.getContext();
		this.props.getSmartAuthUsers(rofId, pitId);
	}

	getContext = (): { rofId: number; pitId: number } => {
		return {
			rofId: Number(this.props.match.params.rofId),
			pitId: Number(this.props.match.params.pitId)
		};
	};

	isLoading = (): boolean => {
		return !(this.props.smartAuthUsersLoadingState === EUpdateState.Loaded);
	};

	render() {
		const {
			smartAuthUsers,
			smartAuthUsersLoadingState,
			currentFhirPitName,
			currentRofId,
			currentPitId,
			createModal
		} = this.props;

		const registerSmartAppModal: IModal = {
			title: 'Create Smart Auth User',
			content: props => <FormCreateSmartAuthUser {...props} rofId={currentRofId} pitId={currentPitId} />,
			description: `Create your SMART user to authenticate against ${currentFhirPitName}.`
		};

		if (this.isLoading()) {
			return <Loading />;
		}

		return (
			<>
				{smartAuthUsersLoadingState === EUpdateState.Loaded && (
					<>
						{smartAuthUsers.length > 0 ? (
							<>
								<TableActionBarTop>
									<Button onClick={event => createModal(registerSmartAppModal, event)}>
										Create a New User
									</Button>
								</TableActionBarTop>
								<TableBody>
									<TableHeader>
										<TableColumn className={css.columnName}>Username</TableColumn>
										<TableColumn className={css.columnPassword}>Password</TableColumn>
										<TableColumn className={css.columnType}>Type</TableColumn>
										<TableColumn className={css.columnFhirId}>FHIR Id</TableColumn>
										<TableColumn className={css.columnCreatedOn}>Created On</TableColumn>
										<TableColumn className={css.lastColumn} />
									</TableHeader>
									{smartAuthUsers &&
										smartAuthUsers.map((smartAuthUser, index) => (
											<TableRow key={index}>
												<TableColumn>{smartAuthUser.username}</TableColumn>
												<TableColumn>{smartAuthUser.password}</TableColumn>
												<TableColumn>{(smartAuthUser.user_type === SmartAuthUserType.Patient ? "Patient" : "Practitioner")}</TableColumn>
												<TableColumn>{smartAuthUser.user_fhir_id}</TableColumn>
												<TableColumn>
													{smartAuthUser.created_at && format(new Date(smartAuthUser.created_at), 'MM/dd/yyyy hh:mm:ss a')}
												</TableColumn>
												<TableColumnDelete
													onClickModal={deleteSmartAuthUserModal(
														smartAuthUser.id,
														this.getContext(),
														this.props.deleteSmartAuthUser
													)}
													actionAriaLabel='Delete User'
												/>
											</TableRow>
										))}
								</TableBody>
							</>
						) : (
							<Message
								title='There are currently no SMART auth users created for this FHIR PIT'
								description='Creating a user will allow you to authenticate using the OAuth 2.0 flow'
							>
								<Button onClick={event => createModal(registerSmartAppModal, event)}>
									Create a New User
								</Button>
							</Message>
						)}
					</>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: ApplicationState): SmartAppsTabsReduxProps => ({
	smartAuthUsers:
		state.rof.allRofPitsSmartAuthUsersLoadingState &&
		state.rof.allRofPitsSmartAuthUsers.hasOwnProperty(state.rof.currentRofId) &&
		state.rof.allRofPitsSmartAuthUsers[state.rof.currentRofId].hasOwnProperty(state.rof.currentPitId)
			? convertStringIndexedObjectToArray(
					state.rof.allRofPitsSmartAuthUsers[state.rof.currentRofId][state.rof.currentPitId]
			  )
			: [],
	smartAuthUsersLoadingState:
		state.rof.allRofPitsSmartAuthUsersLoadingState &&
		state.rof.allRofPitsSmartAuthUsersLoadingState.hasOwnProperty(state.rof.currentRofId) &&
		state.rof.allRofPitsSmartAuthUsersLoadingState[state.rof.currentRofId].hasOwnProperty(state.rof.currentPitId)
			? state.rof.allRofPitsSmartAuthUsersLoadingState[state.rof.currentRofId][state.rof.currentPitId]
			: EUpdateState.Empty,
	currentFhirPitName: state.rof.allRofPits[state.rof.currentRofId][state.rof.currentPitId].name,
	currentRofId: state.rof.currentRofId,
	currentPitId: state.rof.currentPitId
});

export const SmartAuthUsersTab = connect(
	mapStateToProps,
	smartAuthUsersTabActionCreators
)(SmartAuthUsersTabBase);
