import * as React from 'react';
import { Formik } from 'formik';
import { IModalForm } from 'common/interfaces';
import { removePhoneNumberFormatting } from 'common/utils/removePhoneNumberFormatting';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { FormLabel } from 'components/FormElements';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { ErrorFocus } from 'components/ErrorFocus';
import { Grid } from '@material-ui/core';
import css from './form-patient-address.module.scss';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { patientAddressValidationSchema } from 'common/validation-schema/personaBuilder/patientAddressValidationSchema';
import { statesList } from 'common/data/states';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { IsFormDirty } from 'components/RouteGuards/IsFormDirty';

interface IFormPatientAddressState {
	isLoading: boolean;
}

interface IFormPatientAddressReduxProps {
	address_uses: any;
	address_types: any;
}

export class FormPatientAddressBase extends React.PureComponent<
	ResolveThunks<typeof PersonaBuilderActionCreators> &
	IModalForm &
	IFormPatientAddressReduxProps,
	IFormPatientAddressState> {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false
		};
	}

	setFormLoadingAttribute = (isLoading: boolean) => {
		this.setState({
			isLoading
		});
	};

	render() {
		let { setFormLoadingAttribute } = this.props;
		const {
			bindSubmitForm,
			formAction,
			defaultFormContent,
			setErrorMessage,
			address_types,
			address_uses,
			setSaveDisabled
		} = this.props;

		let initialValues = {
			city: '',
			country: '',
			patient_address_use_id: '',
			patient_address_type_id: '',
			patient_id: '',
			state: '',
			street: '',
			zip: ''
		};

		const isEditMode = defaultFormContent ? true : false;

		if (isEditMode) {
			defaultFormContent.home_phone_number = removePhoneNumberFormatting(defaultFormContent.home_phone_number);
			defaultFormContent.mobile_phone_number = removePhoneNumberFormatting(defaultFormContent.mobile_phone_number);
			initialValues = defaultFormContent;
		}
		if (!setFormLoadingAttribute) {
			setFormLoadingAttribute = this.setFormLoadingAttribute;
		}

		return (
			<Formik
				initialValues={initialValues}
				enableReinitialize={true}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					setFormLoadingAttribute(true);
					if (formAction) {
						const trimmedValues = trimAllStringProperties(values);
						formAction(trimmedValues, setErrorMessage, setFormLoadingAttribute);
						resetForm(trimmedValues);
					}
					setSubmitting(false);
				}}
				validationSchema={patientAddressValidationSchema()}
			>
				{props => {
					const {
						values,
						touched,
						errors,
						dirty,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
						handleReset,
						submitForm,
						isValid
					} = props;
					if (bindSubmitForm) {
						bindSubmitForm(submitForm);
					}
					// setSaveDisabled( !isValid || isSubmitting )
					return (
						<>
							<IsFormDirty
								title=""
								message=""
								dirty={dirty}
							/>
							<form onSubmit={handleSubmit}>
								<Grid container xs={12}>

									<Grid item xs={12}>
										<FormLabel htmlFor='patient_address_use_id'>Address Use</FormLabel>
										<select
											id='patient_address_use_id'
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.patient_address_use_id}
											className={errors.patient_address_use_id && touched.patient_address_use_id ? 'text-input error' : 'text-input'}
										>
											<option>Select</option>
											{address_uses.map((address_use, index) => (
												<option key={index} value={address_use.id}>
													{address_use.patient_address_use_display}
												</option>
											))}
										</select>
										{errors.patient_address_use_id && touched.patient_address_use_id && (
											<div className='input-feedback'>{errors.patient_address_use_id}</div>
										)}
									</Grid>

									<Grid item xs={12}>
										<FormLabel htmlFor='patient_address_type_id'>Address Type</FormLabel>
										<select
											id='patient_address_type_id'
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.patient_address_type_id}
											className={errors.patient_address_type_id && touched.patient_address_type_id ? 'text-input error' : 'text-input'}
										>
											<option>Select</option>
											{address_types.map((address_type, index) => (
												<option key={index} value={address_type.id}>
													{address_type.patient_address_type_display}
												</option>
											))}
										</select>
										{errors.patient_address_type_id && touched.patient_address_type_id && (
											<div className='input-feedback'>{errors.patient_address_type_id}</div>
										)}
									</Grid>

									<Grid item xs={12}>
										<FormLabel htmlFor='street'>Street</FormLabel>
										<input
											id='street'
											placeholder='Enter Street'
											type='text'
											value={values.street}
											onChange={handleChange}
											onBlur={handleBlur}
											className={errors.street && touched.street ? 'text-input error' : 'text-input'}
											autoComplete='street-name'
											maxLength={128}
										/>
										{errors.street && touched.street && (
											<div className='input-feedback'>{errors.street}</div>
										)}
									</Grid>

									<Grid item xs={12}>
										<FormLabel htmlFor='city'>City</FormLabel>
										<input
											id='city'
											placeholder='Enter City'
											type='text'
											value={values.city}
											onChange={handleChange}
											onBlur={handleBlur}
											className={errors.city && touched.city ? 'text-input error' : 'text-input'}
											autoComplete='first-name'
											maxLength={128}
										/>
										{errors.city && touched.city && (
											<div className='input-feedback'>{errors.city}</div>
										)}
									</Grid>

									<Grid item xs={12} md={6} className={
										css.gridItem
									}>
										<FormLabel htmlFor='state'>State</FormLabel>
										<select
											id='state'
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.state}
											className={errors.state && touched.state ? 'text-input error' : 'text-input'}
										>
											<option value={""}>Select</option>
											{statesList.map((state, index) => (
												<option key={index} value={state.abbreviation}>
													{state.name}
												</option>
											))}
										</select>
										{errors.state && touched.state && (
											<div className='input-feedback'>{errors.state}</div>
										)}
									</Grid>

									<Grid item xs={12} md={6} className={
										css.gridItem
									}>

										<FormLabel htmlFor='zip'>Zip</FormLabel>
										<input
											id='zip'
											placeholder='Enter Postal Code'
											type='text'
											value={values.zip}
											onChange={handleChange}
											onBlur={handleBlur}
											className={errors.zip && touched.zip ? 'text-input error' : 'text-input'}
											autoComplete='zip'
											maxLength={128}
										/>

										{errors.zip && touched.zip && (
											<div className='input-feedback'>{errors.zip}</div>
										)}
									</Grid>

									<Grid item xs={12}>
										<FormLabel htmlFor='country'>Country</FormLabel>
										<input
											id='country'
											placeholder='Enter Country'
											type='text'
											value={values.country}
											onChange={handleChange}
											onBlur={handleBlur}
											className={errors.country && touched.country ? 'text-input error' : 'text-input'}
											autoComplete='country'
											maxLength={128}
										/>
										{errors.country && touched.country && (
											<div className='input-feedback'>{errors.country}</div>
										)}
									</Grid>
									<ErrorFocus />
								</Grid>
							</form>
						</>
					);
				}}
			</Formik>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IFormPatientAddressReduxProps => ({
	address_uses: state.personaBuilder.dropdowns.address_uses,
	address_types: state.personaBuilder.dropdowns.address_types
});

export const FormPatientAddress = connect(mapStateToProps, PersonaBuilderActionCreators)(FormPatientAddressBase);
