import * as React from 'react';
import { IModal } from 'common/interfaces';

export const deleteSmartAuthUserModal = (
	smartAuthUserId: number,
	context: { rofId: number; pitId: number },
	deleteSmartAuthUser
): IModal => {
	const { rofId, pitId } = context;
	return {
		title: 'Delete SMART Auth User',
		content: () => (
			<p>
				This action is permanent. You will need to re-create your
				SMART auth user to authenticate with it again.
			</p>
		),
		primaryButtonStyle: 'destructive',
		primaryButtonLabel: 'Delete',
		primaryButtonActionWithModalActions: modalActions => {
			deleteSmartAuthUser(
				modalActions,
				rofId,
				pitId,
				smartAuthUserId
			);
		}
	};
};
