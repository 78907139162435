import React, { useEffect, useState } from 'react';
import { ApplicationState } from 'stores';
import * as yup from 'yup';
import { Formik } from 'formik';
import { connect, ResolveThunks } from 'react-redux';
import { authActionCreators } from 'stores/actions/authActions';
import { modalActionCreators } from 'stores/actions/modalActions';
import { TSetSuccessMessage } from 'common/types/TSetSuccessMessage';
import { IModalForm } from 'common/interfaces';
import { Link } from 'react-router-dom';
import { FormLabel } from 'components/FormElements';
import PasswordStrengthIndicator from 'components/FormElements/PasswordStrengthIndicator/PathwordStrengthIndicator';
import {
    isNumberRegx,
    lowerCaseCharRegx,
    specialCharacterRegx,
    upperCaseCharRegx
} from 'common/utils/validatePassword';


const formChangePasswordActionCreators = {
    ...authActionCreators,
    ...modalActionCreators
};
interface IModalContent {
    bindSubmitForm?: (any) => void;
}
interface IFormChangePasswordReduxProps {
    user: any;
}

interface IFormChangePasswordProps {
    setSuccessMessage: TSetSuccessMessage;
}

export const FormChangePasswordBase: React.FunctionComponent<IFormChangePasswordReduxProps &
    ResolveThunks<typeof formChangePasswordActionCreators> &
    IModalContent &
    IModalForm &
    IFormChangePasswordProps>
    = ({
        updatePassword,
        user,
        bindSubmitForm,
        setErrorMessage,
        setFormLoadingAttribute,
        setSuccessMessage,
        getAuth
    }) => {

        useEffect(() => {
            getAuth();
        }, []);

        const [passwordFocused, setPasswordFocused] = useState(false)
        const [passwordValidity, setPasswordValidity] = useState({
            minChar: null,
            number: null,
            upperCaseChar: null,
            lowerCaseChar: null,
            specialChar: null,
        })

        const onChangePassword = (password, values) => {
            values.newPassword = password;

            setPasswordValidity({
                minChar: !!(password.length >= 8),
                number: !!isNumberRegx.test(password),
                specialChar: !!specialCharacterRegx.test(password),
                upperCaseChar: !!upperCaseCharRegx.test(password),
                lowerCaseChar: !!lowerCaseCharRegx.test(password),
            });
        };

        return (
            <div>
                <Formik
                    initialValues={{
                        oldPassword: '',
                        newPassword: '',
                        confirmNewPassword: ''
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setFormLoadingAttribute(true);
                        updatePassword(
                            user,
                            values.oldPassword,
                            values.newPassword,
                            setErrorMessage,
                            setFormLoadingAttribute,
                            setSuccessMessage
                        );
                        setSubmitting(false);
                    }}
                    validationSchema={yup.object().shape({
                        oldPassword: yup.string().required('Required'),
                        newPassword: yup.string().required('Required'),
                        confirmNewPassword: yup
                            .string()
                            .oneOf(
                                [yup.ref('newPassword'), null],
                                'Passwords must match'
                            )
                            .required('Required')
                    })}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                            submitForm
                        } = props;
                        if (bindSubmitForm) {
                            bindSubmitForm(submitForm);
                        }
                        return (
                            <form onSubmit={handleSubmit}>
                                <label
                                    className='hasSubHeading'
                                    htmlFor='rofName'
                                >
                                    Current Password
                                </label>
                                <span className='subLabel'>
                                    <Link to='/forgot-password'>
                                        Forgot Password?
                                    </Link>
                                </span>
                                <input
                                    type='password'
                                    name='oldPassword'
                                    id='oldPassword'
                                    placeholder='Enter Password'
                                    value={values.oldPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                        errors.oldPassword &&
                                            touched.oldPassword
                                            ? 'text-input error'
                                            : 'text-input'
                                    }
                                    autoComplete='current-password'
                                />
                                {errors.oldPassword && touched.oldPassword && (
                                    <div className='input-feedback'>
                                        {errors.oldPassword}
                                    </div>
                                )}
                                <FormLabel
                                    infoText='The password must be at least eight characters long and include the following character types: uppercase, lowercase, number, and special.'
                                    htmlFor='newPassword'
                                >
                                    New Password
                                </FormLabel>
                                <input
                                    type='password'
                                    name='newPassword'
                                    id='newPassword'
                                    placeholder='Enter Password'
                                    value={values.newPassword}
                                    onChange={e =>
                                        onChangePassword(e.target.value, values)
                                    }
                                    onFocus={() => setPasswordFocused(true)}
                                    onBlur={handleBlur}
                                    className={
                                        errors.newPassword &&
                                            touched.newPassword
                                            ? 'text-input error'
                                            : 'text-input'
                                    }
                                    autoComplete='new-password'
                                />
                                {passwordFocused && (
                                    <PasswordStrengthIndicator
                                        validity={passwordValidity}
                                    />
                                )}
                                {errors.newPassword && touched.newPassword && (
                                    <div className='input-feedback'>
                                        {errors.newPassword}
                                    </div>
                                )}
                                <FormLabel htmlFor='confirmNewPassword'>
                                    Confirm New Password
                                </FormLabel>
                                <input
                                    type='password'
                                    name='confirmNewPassword'
                                    id='confirmNewPassword'
                                    placeholder='Enter Password'
                                    value={values.confirmNewPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                        errors.confirmNewPassword &&
                                            touched.confirmNewPassword
                                            ? 'text-input error'
                                            : 'text-input'
                                    }
                                    autoComplete='new-password'
                                />
                                {errors.confirmNewPassword &&
                                    touched.confirmNewPassword && (
                                        <div className='input-feedback'>
                                            {errors.confirmNewPassword}
                                        </div>
                                    )}
                                <input type='submit' />
                            </form>
                        );
                    }}
                </Formik>
            </div>
        );


    }

const mapStateToProps = (
    state: ApplicationState
): IFormChangePasswordReduxProps => ({
    user: state.auth.user
});

export const FormChangePassword = connect(
    mapStateToProps,
    formChangePasswordActionCreators
)(FormChangePasswordBase);