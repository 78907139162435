import { Reducer } from 'redux';

import personaActions from 'stores/actionInterfaces/personaActionInterfaces';
import { PersonaState } from 'stores/stateInterfaces/personaStateInterface';
import { convertArrayToIndexedObject } from 'common/utils/convertArrayToIndexedObject';
import { clone } from 'ramda';

import { EUpdateState } from 'stores/shared/ENums';

const initialState: PersonaState = {
	allPersonas: null,
	allPersonasUpdateState: EUpdateState.Empty,
	currentPersona: null,
	applyPersonaId: null,
	applyPersonaRoFIds: null,
	applyPersonaErrorMessage: null,
	applyPersonaUpdateState: EUpdateState.Empty
};

export const PersonaReducers: Reducer<PersonaState> = (state: PersonaState, action: personaActions) => {
	switch (action.type) {
		case 'GET_PERSONAS_REQUEST': {
			return {
				...state,
				allPersonasUpdateState: EUpdateState.Loading
			};
		}
		case 'GET_PERSONAS_RECEIVED': {
			return {
				...state,
				allPersonas: convertArrayToIndexedObject(action.personas),
				allPersonasUpdateState: EUpdateState.Loaded
			};
		}
		case 'GET_PERSONA_REQUEST': {
			return {
				...state
			};
		}
		case 'GET_PERSONA_RECEIVED': {
			return {
				...state,
				currentPersona: action.persona.id,
				allPersonas: {
					...state.allPersonas,
					[action.persona.id]: action.persona
				}
			};
		}
		case 'APPLY_PERSONA_REQUEST': {
			return {
				...state,
				applyPersonaId: action.personaId,
				applyPersonaRoFIds: action.rofIds,
				applyPersonaUpdateState: EUpdateState.Busy
			};
		}
		case 'APPLY_PERSONA_RECEIVED': {
			return {
				...state,
				applyPersonaId: action.personaId,
				applyPersonaRoFIds: action.rofIds,
				applyPersonaErrorMessage: action.errorMessage,
				applyPersonaUpdateState: action.updateState
			};
		}
		case 'CLEAR_APPLY_PERSONA': {
			return {
				...state,
				applyPersonaId: null,
				applyPersonaRoFIds: null,
				applyPersonaErrorMessage: null,
				applyPersonaUpdateState: EUpdateState.Empty
			};
		}
		case 'DELETE_PERSONA_REQUEST': {
			return {
				...state
			}
		}
		case 'DELETE_PERSONA_RECEIVED': {
			const personas = Object.keys(state.allPersonas).reduce((acc, personaId) => {
				return action.personaId === parseInt(personaId) ? acc : {
					...acc,
					[personaId]: state.allPersonas[personaId]
				}
			}, {})
			return {
				...state,
				personas
			}
		}
		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state || initialState;
};
