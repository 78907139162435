import * as React from 'react';
import css from './button-drop-down-rof.module.scss';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { modalActionCreators } from 'stores/actions/modalActions';
import { personaActionCreators } from 'stores/actions/personaActions';
import { IRof } from 'common/interfaces/IRof';
import { convertIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';
import { Formik, Field } from 'formik';
import classes from 'classnames';
import { EUpdateState } from 'stores/shared/ENums';
import { ActionIcon } from 'components/ActionIcon/ActionIcon';
import { IButtonDropDown } from 'common/interfaces/IButtonDropDown';
import * as yup from 'yup';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { RofState } from 'stores/stateInterfaces/rofStateInterface';
import { isRofBusy } from 'common/utils/isRofBusy';
import { convertArrayToIndexedObject } from 'common/utils/convertArrayToIndexedObject';

const buttonDropDownRofCreators = {
	...modalActionCreators,
	...personaActionCreators
};

interface IButtonDropDownRofReduxProps {
	rofList: IRof[];
	applyPersonaRoFIds: number[];
	applyPersonaErrorMessage: string;
	applyPersonaUpdateState: EUpdateState;
	rof: RofState;
}

interface IButtonDropDownRofProps {
	personaId: number;
}

const Checkbox = props => {
	return (
		<Field name={props.name}>
			{({ field, form }) => (
				<input
					type='checkbox'
					{...props}
					checked={field.value && field.value.includes(props.value)}
					onChange={() => {
						if (field.value.includes(props.value)) {
							const nextValue = field.value.filter(value => value !== props.value);
							form.setFieldValue(props.name, nextValue);
						} else {
							const nextValue = field.value.concat(props.value);
							form.setFieldValue(props.name, nextValue);
						}
					}}
				/>
			)}
		</Field>
	);
};
class ButtonDropDownRofBase extends React.PureComponent<
	IButtonDropDownRofReduxProps &
	IButtonDropDownRofProps &
	IButtonDropDown &
	ResolveThunks<typeof buttonDropDownRofCreators>
> {
	render() {
		const { rofList, personaId, dismissDropDown, rof } = this.props;

		return (
			<>
				{rofList && (
					<Formik
						initialValues={{
							rof: []
						}}
						onSubmit={(values, { setSubmitting }) => {
							console.log('Submitted Values', values);
							console.log('Persona ID', personaId);

							this.props.applyPersona(personaId, values.rof, false);
							dismissDropDown();
							setSubmitting(false);
						}}
						validationSchema={yup.object().shape({
							rof: yup.array().required('Required')
						})}
					>
						{props => {
							const {
								values,
								touched,
								errors,
								dirty,
								isSubmitting,
								handleChange,
								handleBlur,
								handleSubmit,
								handleReset,
								isValid
							} = props;
							return (
								<form className={css.dropDownForm} onSubmit={handleSubmit}>
									<ul className='reset'>
										{rofList &&
											rofList.map((currentRof, index) => (
												<li
													className={classes(
														css.dropDownItem,
														{
															[css.first]: index === 0
														},
														{
															[css.last]: index + 1 === rofList.length
														}
													)}
													key={index}
												>
													<label
														className={classes(css.dropDownItemLabel, {
															[css.dropDownItemDisabled]: isRofBusy(rof, currentRof.id)
														})}
														htmlFor={`${currentRof.id}${index}`}
													>
														{currentRof.name}
														<div className={css.dropDownItemActionContainer}>
															{isRofBusy(rof, currentRof.id) ? (
																<>
																	<LoadingSpinner className={css.spinner} size='medium' />
																</>
															) : (
																<Checkbox
																	name='rof'
																	value={`${currentRof.id}`}
																	id={`${currentRof.id}${index}`}
																/>
															)}
														</div>
													</label>
												</li>
											))}
										<li>
											<button type='submit' className={css.submit} disabled={!isValid}>
												Add Personas
											</button>
										</li>
									</ul>
								</form>
							);
						}}
					</Formik>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IButtonDropDownRofReduxProps => {
	const currentOrganizationId = state.organization.currentOrganization ? state.organization.currentOrganization : state.auth.user.attributes.organizationId;
	const templates = state.rof.templates;
	const allRof = convertIndexedObjectToArray(state.rof.allRof).filter(ringOfFhir => {
		return (
			ringOfFhir.organization_id === currentOrganizationId &&
			convertArrayToIndexedObject(templates)[ringOfFhir.rof_template_id] &&
			convertArrayToIndexedObject(templates)[ringOfFhir.rof_template_id].fhir_version === 'R4'
		);
	});

	return {
		rofList: allRof ? allRof : [],
		applyPersonaRoFIds: state.persona.applyPersonaRoFIds,
		applyPersonaErrorMessage: state.persona.applyPersonaErrorMessage,
		applyPersonaUpdateState: state.persona.applyPersonaUpdateState,
		rof: state.rof
	};
};

export const ButtonDropDownRof = connect(
	mapStateToProps,
	buttonDropDownRofCreators
)(ButtonDropDownRofBase);
