import * as yup from 'yup';
import { validatePhoneNumber } from 'common/utils/validatePhoneNumber';

interface IPatientDescriptionValidationSchemaConfig {
}

interface IPatientDescriptionValidationSchema {
	type: string;
	bio: string;
	description: string;
}

export const patientDescriptionValidationSchema = (
	patientDescriptionValidationSchemaConfig: IPatientDescriptionValidationSchemaConfig = {}
) => {
	let schemaDefinition = {
		type: yup.string().required('First Name is Required'),
		bio: yup.string().required('Middle Name is Required'),
		description: yup.string().required('Last Name is Required')
	};

	return yup.object().shape<any>(schemaDefinition) as any;
};
