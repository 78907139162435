import { ICognitoUser } from 'common/interfaces';
import { ICanCreateRof } from 'common/interfaces/ICanCreateRof';
import { IEntitlementSummary } from 'common/interfaces/IEntitlementSummary';
import { TUserType } from 'common/types/TUserType';

export const canCreateRof = (userType: TUserType): ICanCreateRof => {

	return {
		hasPrivilege: userType === 'mihin-admin' || userType === 'org-admin',
		hasEntitlement: true
	};
};


export const allowRofCreation = (entitlementsSummary: IEntitlementSummary, userType: string): Boolean => {

	if (entitlementsSummary.rof_purchased === null) {
		return ['mihin-admin'].includes(userType)
	}

	const hasEntitlementsRemaining =
		entitlementsSummary.rof_purchased - entitlementsSummary.rof_count > 0;

	if (!hasEntitlementsRemaining) {
		return ['mihin-admin'].includes(userType)
	}

	return ['mihin-admin', 'org-admin'].includes(userType)

}