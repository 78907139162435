import * as React from 'react';
import classes from 'classnames';
import css from './profile.module.scss';
import { Link } from 'react-router-dom';
import { Circle } from 'components/Circle';

interface IProfile {
	image: string;
	name: string;
	age: string;
	type: string;
	className?: string;
	to: string;
}

export const Profile: React.FC<IProfile> = props => {
	const { image, name, age, type, className, to } = props;
	return (
		<Link className={classes(css.profile, className)} to={to}>
			<Circle
				style={{
					backgroundImage: `url('${image}')`
				}}
			/>
			<div className={css.topInfo}>
				<h2 className={classes(css.name, 'h4')}>{name}</h2>
				<ul className='reset'>
					<li className={css.topItem}>
						<b>Age:</b> {age}
					</li>
					<li className={css.topItem}>
						<b>Type:</b> {type}
					</li>
				</ul>
			</div>
		</Link>
	);
};
