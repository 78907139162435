import React from 'react';
import { ApplicationState } from 'stores';
import { clone } from 'ramda';
import { connect, ResolveThunks } from 'react-redux';
import { Section } from 'components/Section/Section';
import { GridContainer, GridItem, GridItemContentAdd } from 'components/Grid';
import { TUserType } from 'common/types/TUserType';
import { modalActionCreators } from 'stores/actions/modalActions';
import { rofActionCreators } from 'stores/actions/rofActions';
import { allowRofCreation } from 'common/utils/canCreateRof';
import { noEntitlementAvailableModal } from 'common/modals/noEntitlementAvailableModal';
import { IRof } from 'common/interfaces/IRof';
import { convertIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';
import { createNewROFModal } from 'common/modals/createNewRofModal';
import { EUpdateState } from 'stores/shared/ENums';
import { Circle } from 'components/Circle/Circle';
import { IEntitlementSummary } from 'common/interfaces/IEntitlementSummary';
import { deleteRofModal } from 'common/modals/deleteRofModal';

interface FormOrganizationUserReduxProps {
    currentOrganizationId: number;
    currentUserType: TUserType;
    currentOrganizationRof: IRof[];
    entitlementsSummary: IEntitlementSummary;
    entitlementsSummaryLoadingState: EUpdateState;
    isCurrentUsersOrganization: boolean;
    isCurrentUsersSuborganization: boolean;
}

const FormOrganizationRofActionCreators = {
    ...modalActionCreators,
    ...rofActionCreators,
};

export const FormOrganizationRofBase: React.FunctionComponent<FormOrganizationUserReduxProps &
    ResolveThunks<typeof FormOrganizationRofActionCreators>> = ({
        currentOrganizationId,
        currentUserType,
        currentOrganizationRof,
        entitlementsSummary,
        entitlementsSummaryLoadingState,
        isCurrentUsersOrganization,
        isCurrentUsersSuborganization,
        createModal,
        deleteRof,
    }) => {

        return (
            <Section
                title={`Sandboxes${currentOrganizationRof.length > 0 ? ` (${currentOrganizationRof.length})` : ''
                    }`}
            >
                <GridContainer>
                    {currentUserType !== 'org-user' &&
                        entitlementsSummaryLoadingState === EUpdateState.Loaded &&
                        <GridItem
                            type='create'
                            action={allowRofCreation(entitlementsSummary, currentUserType) ?
                                event => createModal(createNewROFModal(currentOrganizationId), event) :
                                event => createModal(noEntitlementAvailableModal(entitlementsSummary), event)}
                        >
                            <GridItemContentAdd
                                label='Add Sandbox'
                                secondaryLabel={
                                    (isCurrentUsersSuborganization || isCurrentUsersOrganization) &&
                                        entitlementsSummary.rof_purchased !== null
                                        ? `${entitlementsSummary.rof_purchased - entitlementsSummary.rof_count} Remaining`
                                        : null
                                }
                            />
                        </GridItem>
                    }
                    {currentOrganizationRof &&
                        currentOrganizationRof.length > 0 &&
                        currentOrganizationRof.map((rof, index) => {
                            return (
                                <GridItem
                                    key={index}
                                    contentLevel1={rof.name}
                                    contentLevel2={`${rof.fhir_pit_count} PITs`}
                                    contentLevel3={currentUserType === 'mihin-admin' ? `Created by: ${rof.created_by}` : null}
                                    location={`/sandbox/${rof.id}`}
                                    actionOptions={
                                        currentUserType === 'mihin-admin' || currentUserType === 'org-admin'
                                            ? [
                                                {
                                                    label: 'Delete Sandbox',
                                                    action: event => {
                                                        createModal(deleteRofModal(rof, deleteRof), event);
                                                    }
                                                }
                                            ]
                                            : null
                                    }
                                >
                                    <Circle isCentered={true} icon='rof' />
                                </GridItem>
                            );
                        })}
                </GridContainer>
            </Section>
        )
    }


const mapStateToProps = (state: ApplicationState): FormOrganizationUserReduxProps => {
    const currentOrganizationId = state.organization.currentOrganization;
    const currentUser = state.auth.user;
    const currentOrganizationParentOrganizationId =
        state.organization.organizations && state.organization.organizations[currentOrganizationId]
            ? state.organization.organizations[currentOrganizationId].parent_organization_id
            : null;

    return {
        currentOrganizationId,
        currentUserType: state.auth.user.attributes['custom:user_type'],
        currentOrganizationRof: convertIndexedObjectToArray(clone(state.rof.allRof)).filter(
            (ringOfFhir) => ringOfFhir.organization_id === state.organization.currentOrganization
        ),
        entitlementsSummary: state.auth.entitlementSummary,
        entitlementsSummaryLoadingState: state.auth.entitlementSummaryLoadingState,
        isCurrentUsersOrganization: currentUser.attributes.organizationId === currentOrganizationId,
        isCurrentUsersSuborganization: currentUser
            ? currentUser.attributes.organizationId === currentOrganizationParentOrganizationId
            : null,
    }
};

export const FormOrganizationRof = connect(
    mapStateToProps,
    FormOrganizationRofActionCreators
)(FormOrganizationRofBase);