import * as React from 'react';
import { Formik } from 'formik';
import { IModal, IModalForm, IUser } from 'common/interfaces';
import { removePhoneNumberFormatting } from 'common/utils/removePhoneNumberFormatting';
import { PhoneNumberField } from 'components/FormElements/PhoneNumberField/PhoneNumberField';
import { userValidationSchema } from 'common/validation-schema/userValidationSchema';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { TUserType } from 'common/types/TUserType';
import { IOrganization } from 'common/interfaces/IOrganization';
import { convertIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';
import classes from 'classnames';
import { FormLabel } from 'components/FormElements';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { Button } from 'components/Button';
import { ErrorFocus } from 'components/ErrorFocus';
import { modalActionCreators } from 'stores/actions/modalActions';

interface IFormUserProps {
	isParentOrganization?: boolean;
}

interface IFormUserState {
	isLoading: boolean;
}

interface IFormUserReduxProps {
	currentUserType: TUserType;
	currentOrganization: number;
	isParentOrganization: boolean;
	organizations: IOrganization[];
}

const updateUserToSuperAdminContent = () => {
	return (
		<p>
			Making this user a super admin will remove them from all current organizations.
			Are you sure you want to make this user a Super Admin?
			<br /><br />
			<b>This action can not be undone.</b>
		</p>
	);
};

const FormUserActionCreator = {
	...modalActionCreators,
};

export class FormUserBase extends React.PureComponent<
	ResolveThunks<typeof FormUserActionCreator> &
	IModalForm & IFormUserProps & IFormUserReduxProps,
	IFormUserState
> {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
		};
	}

	setFormLoadingAttribute = (isLoading: boolean) => {
		this.setState({
			isLoading,
		});
	};

	render() {
		let { setFormLoadingAttribute } = this.props;
		const {
			bindSubmitForm,
			formAction,
			defaultFormContent,
			isParentOrganization,
			setErrorMessage,
			organizations,
			currentUserType,
			currentOrganization,
		} = this.props;

		let initialValues = {
			username: '',
			email: '',
			user_type: (defaultFormContent || {}).user_type || '',
			phone_number: '',
			organization_id: null,
		};



		const isEditMode = defaultFormContent ? true : false;

		if (isEditMode) {
			defaultFormContent.phone_number = removePhoneNumberFormatting(defaultFormContent.phone_number);
			initialValues = defaultFormContent;
		}
		if (!setFormLoadingAttribute) {
			setFormLoadingAttribute = this.setFormLoadingAttribute;
		}


		return (
			<Formik
				initialValues={initialValues}
				onSubmit={(values, { setSubmitting }) => {
					values.phone_number = `+1${removePhoneNumberFormatting(values.phone_number)}`;

					if (!isParentOrganization) {
						values['user_type'] = 'org-user';
					}

					setFormLoadingAttribute(true);


					const updateUserToSuperAdminModal: IModal = {
						title: 'Update User to Super Admin',
						content: updateUserToSuperAdminContent,
						primaryButtonLabel: 'Update to Super Admin',
						primaryButtonAction: () => {
							formAction(
								trimAllStringProperties(values),
								setErrorMessage,
								setFormLoadingAttribute
							);
						}
					};
					if (isEditMode && values.user_type === 'mihin-admin' &&
						defaultFormContent.user_type !== 'mihin-admin') {
						this.props.createModal(updateUserToSuperAdminModal)
					}
					else {
						if (formAction) {
							formAction(
								trimAllStringProperties(values),
								setErrorMessage,
								setFormLoadingAttribute
							);
						}
					}
					setSubmitting(false);
				}}
				validationSchema={userValidationSchema({
					isEditSchema: isEditMode,
					isParentOrganization,
				})}
			>
				{(props) => {
					const {
						values,
						touched,
						errors,
						dirty,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
						handleReset,
						submitForm,
					} = props;
					if (bindSubmitForm) {
						bindSubmitForm(submitForm);
					}
					return (
						<>
							<form onSubmit={handleSubmit}>
								{!isEditMode && (
									<>
										<FormLabel htmlFor='username'>Username</FormLabel>
										<input
											id='username'
											placeholder='Enter Username'
											type='text'
											value={values.username}
											onChange={handleChange}
											onBlur={handleBlur}
											className={errors.username && touched.username ? 'text-input error' : 'text-input'}
											autoComplete='username'
											maxLength={128}
										/>
										{errors.username && touched.username && (
											<div className='input-feedback'>{errors.username}</div>
										)}
										<FormLabel htmlFor='email'>Email</FormLabel>
										<input
											id='email'
											placeholder='Enter Email'
											type='email'
											value={values.email}
											onChange={handleChange}
											onBlur={handleBlur}
											className={errors.email && touched.email ? 'text-input error' : 'text-input'}
										/>
										{errors.email && touched.email && (
											<div className='input-feedback'>{errors.email}</div>
										)}
									</>
								)}
								{isParentOrganization && currentUserType && (
									<>
										<FormLabel htmlFor='user_type'>User Type</FormLabel>
										<select
											id='user_type'
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.user_type}
											className={classes({
												default: values.user_type === '',
											})}
										>
											<option value='' disabled>
												Please Choose a User Type
											</option>
											{currentUserType === 'mihin-admin' &&
												(
													<option value='mihin-admin'>Super Admin</option>
												)}
											<option value='org-user'>Organization User</option>
											<option value='org-admin'>Organization Admin</option>
										</select>
										{errors.user_type && touched.user_type && (
											<div className='input-feedback'>{errors.user_type}</div>
										)}
									</>
								)}
								<FormLabel htmlFor='phone_number'>Phone Number</FormLabel>
								<PhoneNumberField
									id='phone_number'
									placeholder='Enter Phone Number'
									value={removePhoneNumberFormatting(values.phone_number)}
									onChange={handleChange}
									onBlur={handleBlur}
									className={
										errors.phone_number && touched.phone_number ? 'text-input error' : 'text-input'
									}
								/>
								{errors.phone_number && touched.phone_number && (
									<div className='input-feedback'>{errors.phone_number}</div>
								)}

								<Button type='submit' isLoading={this.state.isLoading}>
									Create Account
								</Button>
							</form>
							<ErrorFocus />
						</>
					);
				}}
			</Formik>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IFormUserReduxProps => {
	const { currentOrganization, organizations } = state.organization;

	const parentOrgId = organizations[currentOrganization].parent_organization_id;

	return {
		currentUserType:
			state.auth && state.auth.user ? state.auth.user.attributes['custom:user_type'] : null,
		currentOrganization,
		isParentOrganization: !parentOrgId,
		organizations: convertIndexedObjectToArray(organizations).filter(
			// Select organizations with the same parent
			(organization) => organization.parent_organization_id === parentOrgId
		),
	};
};

export const FormUser = connect(mapStateToProps, FormUserActionCreator)(FormUserBase);
