export const removePhoneNumberFormatting = (
	phoneNumber: string = ''
): string => {
	return phoneNumber
		.replace(new RegExp(' ', 'g'), '')
		.replace(new RegExp('\\+1', 'g'), '')
		.replace(new RegExp('_', 'g'), '')
		.replace(new RegExp('-', 'g'), '')
		.replace(new RegExp('\\(', 'g'), '')
		.replace(new RegExp('\\)', 'g'), '');
};
