import * as React from 'react';
import { Formik } from 'formik';
import { connect, ResolveThunks } from 'react-redux';
import { organizationActionCreators } from 'stores/actions/organizationActions';
import { IModalForm, IOrganization } from 'common/interfaces';
import css from './organization.module.scss';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { statesList } from 'common/data/states';
import classes from 'classnames';
import NumberFormat from 'react-number-format';
import { PhoneNumberField } from 'components/FormElements/PhoneNumberField';
import { orgValidationSchema } from 'common/validation-schema/orgValidationSchema';
import { RequiredIndicator } from 'components/FormElements/RequiredIndicator';
import { Button } from 'components/Button';

const addNewOrganizationActionCreators = {
	...organizationActionCreators
};

interface IFormOrganizationState {
	isCodeLoading: boolean;
	isCodeUnique: boolean;
}

interface IFormOrganizationProps {
	parentOrganizationId?: number;
}

class FormOrganizationBase extends React.PureComponent<
	IModalForm &
	ResolveThunks<typeof addNewOrganizationActionCreators> &
	IFormOrganizationProps,
	IFormOrganizationState
> {
	render() {
		const {
			bindSubmitForm,
			createOrganization,
			defaultFormContent,
			formAction,
			parentOrganizationId,
			setErrorMessage,
			setFormLoadingAttribute
		} = this.props;
		const isEditMode = defaultFormContent ? true : false;

		let initialValuesBase: IOrganization = {
			name: ''
		};

		const isParentOrganization = typeof parentOrganizationId !== 'number';

		console.log('isParentOrganization', isParentOrganization);

		if (isParentOrganization) {
			initialValuesBase = {
				...initialValuesBase,
				descr: '',
				address: '',
				city: '',
				state: '',
				postal_code: '',
				contact_name: '',
				contact_email: '',
				contact_phone: '',
			};
		}

		let initialValues = { ...initialValuesBase };

		initialValues = defaultFormContent
			? defaultFormContent
			: initialValuesBase;

		const organizationReferenceName = isParentOrganization
			? 'organization'
			: 'suborganization';

		return (
			<>
				<Formik
					initialValues={initialValues}
					onSubmit={(values, { setSubmitting }) => {
						if (!isParentOrganization && !defaultFormContent) {
							values[
								'parent_organization_id'
							] = parentOrganizationId;
						}

						setFormLoadingAttribute(true);

						if (formAction) {
							formAction(
								trimAllStringProperties(values),
								setErrorMessage,
								setFormLoadingAttribute
							);
						}
						setSubmitting(false);
					}}
					validationSchema={orgValidationSchema({
						isEditSchema: isEditMode,
						isParentOrganization,
					})}
				>
					{props => {
						const {
							values,
							touched,
							errors,
							dirty,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit,
							handleReset,
							submitForm,
							validateForm
						} = props;

						if (bindSubmitForm) {
							bindSubmitForm(submitForm);
						}

						return (
							<form onSubmit={handleSubmit}>
								<label htmlFor='name'>
									{organizationReferenceName} Name
									<RequiredIndicator />
								</label>
								<input
									id='name'
									placeholder='Enter Name'
									type='text'
									value={values.name}
									onChange={handleChange}
									onBlur={handleBlur}
									className={
										errors.name && touched.name
											? 'text-input error'
											: 'text-input'
									}
								/>
								{errors.name && touched.name && (
									<div className='input-feedback'>
										{errors.name}
									</div>
								)}
								{isParentOrganization && (
									<>
										<label htmlFor='descr'>
											{organizationReferenceName}{' '}
											Description
										</label>
										<input
											id='descr'
											placeholder='Enter Description'
											type='text'
											value={values.descr}
											onChange={handleChange}
											onBlur={handleBlur}
											className={
												errors.descr && touched.descr
													? 'text-input error'
													: 'text-input'
											}
										/>
										{errors.descr && touched.descr && (
											<div className='input-feedback'>
												{errors.descr}
											</div>
										)}
									</>
								)}
								{isParentOrganization && (
									<>
										<div role='group'>
											<label htmlFor='name'>
												{organizationReferenceName}{' '}
												Address
												<RequiredIndicator />
											</label>
											<input
												id='address'
												placeholder='Enter Address'
												type='text'
												value={values.address}
												onChange={handleChange}
												onBlur={handleBlur}
												className={
													errors.address &&
														touched.address
														? 'text-input error'
														: 'text-input'
												}
												autoComplete='street-address'
											/>
											{errors.address &&
												touched.address && (
													<div className='input-feedback'>
														{errors.address}
													</div>
												)}
											<div
												className={
													css.inputThirdsContainer
												}
											>
												<div
													className={
														css.inputThirdsItem
													}
												>
													<label
														htmlFor='city'
														className='screenreader-text'
													>
														City
													</label>
													<input
														id='city'
														placeholder='Enter City'
														type='text'
														value={values.city}
														onChange={handleChange}
														onBlur={handleBlur}
														className={
															errors.city &&
																touched.city
																? 'text-input error'
																: 'text-input'
														}
														autoComplete='address-level2'
													/>
													{errors.city &&
														touched.city && (
															<div className='input-feedback'>
																{errors.city}
															</div>
														)}
												</div>
												<div
													className={
														css.inputThirdsItem
													}
												>
													<label
														htmlFor='state'
														className='screenreader-text'
													>
														State
													</label>
													<select
														id='state'
														value={values.state}
														onChange={handleChange}
														onBlur={handleBlur}
														className={classes(
															errors.state &&
																touched.state
																? 'text-input error'
																: 'text-input',
															{
																default:
																	values.state ===
																	''
															}
														)}
														autoComplete='address-level1'
													>
														<option
															value=''
															disabled
														>
															Select State
														</option>
														{statesList.map(
															(state, index) => (
																<option
																	key={index}
																	value={
																		state.abbreviation
																	}
																>
																	{state.name}
																</option>
															)
														)}
													</select>

													{errors.state &&
														touched.state && (
															<div className='input-feedback'>
																{errors.state}
															</div>
														)}
												</div>
												<div
													className={
														css.inputThirdsItem
													}
												>
													<label
														htmlFor='postal_code'
														className='screenreader-text'
													>
														Zip Code
													</label>
													<NumberFormat
														id='postal_code'
														placeholder='Enter Zip'
														value={
															values.postal_code
														}
														onChange={handleChange}
														onBlur={handleBlur}
														className={
															errors.postal_code &&
																touched.postal_code
																? 'text-input error'
																: 'text-input'
														}
														format='#####'
													/>
													{errors.postal_code &&
														touched.postal_code && (
															<div className='input-feedback'>
																{
																	errors.postal_code
																}
															</div>
														)}
												</div>
											</div>
										</div>

										<label htmlFor='contact_name'>
											Main Contact Name
											<RequiredIndicator />
										</label>
										<input
											id='contact_name'
											placeholder='Enter Contact Name'
											type='text'
											value={values.contact_name}
											onChange={handleChange}
											onBlur={handleBlur}
											className={
												errors.contact_name &&
													touched.contact_name
													? 'text-input error'
													: 'text-input'
											}
											autoComplete='name'
										/>
										{errors.contact_name &&
											touched.contact_name && (
												<div className='input-feedback'>
													{errors.contact_name}
												</div>
											)}
										<label htmlFor='contact_email'>
											Main Contact Email
											<RequiredIndicator />
										</label>
										<input
											id='contact_email'
											placeholder='Enter Contact Email'
											type='email'
											value={values.contact_email}
											onChange={handleChange}
											onBlur={handleBlur}
											className={
												errors.contact_email &&
													touched.contact_email
													? 'text-input error'
													: 'text-input'
											}
											autoComplete='email'
										/>
										{errors.contact_email &&
											touched.contact_email && (
												<div className='input-feedback'>
													{errors.contact_email}
												</div>
											)}
										<label htmlFor='contact_phone'>
											Main Contact Phone
											<RequiredIndicator />
										</label>
										<PhoneNumberField
											id='contact_phone'
											placeholder='Enter Contact Phone'
											value={values.contact_phone}
											onChange={handleChange}
											onBlur={handleBlur}
											className={
												errors.contact_phone &&
													touched.contact_phone
													? 'text-input error'
													: 'text-input'
											}
										/>
										{errors.contact_phone &&
											touched.contact_phone && (
												<div className='input-feedback'>
													{errors.contact_phone}
												</div>
											)}
									</>
								)}
								<Button type='submit'>
									Create Organization
								</Button>
							</form>
						);
					}}
				</Formik>
			</>
		);
	}
}

export const FormOrganization = connect(
	null,
	addNewOrganizationActionCreators
)(FormOrganizationBase);
