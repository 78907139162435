import React, { useState, useEffect } from 'react';

import { apiLibraryActionCreators } from 'stores/actions/apiLibraryActions';
import { modalActionCreators } from 'stores/actions/modalActions';

import { ResolveThunks, useSelector, useDispatch } from 'react-redux';
import { Formik, Form, ErrorMessage, Field, FormikActions } from 'formik';
import * as yup from 'yup';
import css from './form-create-rof.module.scss';
import { SegmentedController } from 'components/FormElements';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { url } from 'settings';
import { debounce } from 'debounce';
import { debouncedValidateCodeIsUnique } from 'common/utils/validateCodeIsUnique';
import { RequiredIndicator } from 'components/FormElements/RequiredIndicator';
import { IModalForm } from 'common/interfaces';
import { IApiDoc } from 'common/interfaces/IApiDoc';
import { ApplicationState } from 'stores';
import classes from 'classnames';
import { FormLabel } from 'components/FormElements';
import { sharedHistory } from 'sharedStore';
import { clone, sort } from 'ramda';
import { EUpdateState } from 'stores/shared/ENums';
import { apiDocSchema } from './schemas';
import { decode } from 'he';

const formCreateApiDocActionCreators = {
  ...apiLibraryActionCreators,
  ...modalActionCreators
};

interface IFormCreateApiDocProps {
  organizationId: number;
  apiDoc: IApiDoc;
}

interface IFormCreateApiDocReduxProps { }

export const FormCreateApiDoc: React.StatelessComponent<ResolveThunks<typeof formCreateApiDocActionCreators> &
  IModalForm &
  IFormCreateApiDocProps
> = ({ apiDoc, createApiDoc, setErrorMessage, setFormLoadingAttribute, bindSubmitForm }) => {

  const dispatch = useDispatch();

  const handleFormSubmit = (formData: IApiDoc, { setSubmitting }) => {
    setFormLoadingAttribute(true);
    dispatch(formCreateApiDocActionCreators[formData.id ? 'updateApiDoc' : 'createApiDoc'](
      formData, setErrorMessage, setFormLoadingAttribute)
    );
    setSubmitting(false);
  }

  return (
    <div>
      <Formik
        initialValues={{
          name: '',
          descr: '',
          overview: '',
          documentation_url: '',
          signup_url: '',
          ...apiDoc
        }}
        onSubmit={handleFormSubmit}
        validationSchema={apiDocSchema}
      >
        {(formikProps) => {
          const { values, handleSubmit, submitForm, handleChange, handleBlur, errors, touched } = formikProps;
          if (bindSubmitForm) {
            bindSubmitForm(submitForm)
          }
          return (
            <form onSubmit={handleSubmit}>
              {/* Name */}
              <FormLabel htmlFor='name' subLabel='50 character limit'>
                API Name
              <RequiredIndicator />
              </FormLabel>
              <input
                type='text'
                name='name'
                id='new_api_form_input_name'
                placeholder='The name of the API'
                value={decode(values.name)}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.name && touched.name ? 'text-input error' : 'text-input'}
                maxLength={50}
              />
              <ErrorMessage name="name" component="div" className="input-feedback" />

              {/* Description */}
              <FormLabel htmlFor='descr' subLabel='255 character limit'>
                Description
              <RequiredIndicator />
              </FormLabel>
              <input
                type='text'
                name='descr'
                id='new_api_form_input_descr'
                placeholder='A short description of the API'
                value={decode(values.descr)}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.descr && touched.descr ? 'text-input error' : 'text-input'}
                maxLength={255}
              />
              <ErrorMessage name="descr" component="div" className="input-feedback" />

              {/* Overview */}
              <FormLabel htmlFor='name'>
                Overview
              <RequiredIndicator />
              </FormLabel>
              <textarea
                rows={5}
                name='overview'
                id='new_api_form_textarea_overview'
                placeholder='A more detailed overview of the API'
                value={values.overview}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.overview && touched.overview ? 'text-input error' : 'text-input'}
              />
              <ErrorMessage name="overview" component="div" className="input-feedback" />

              {/* Documentation URL */}
              <FormLabel htmlFor='documentation_url'>
                Documentation URL
              <RequiredIndicator />
              </FormLabel>
              <input
                type='text'
                name='documentation_url'
                id='new_api_form_input_documentation_url'
                placeholder='Official API documentation URL'
                value={values.documentation_url}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.documentation_url && touched.documentation_url ? 'text-input error' : 'text-input'}
                maxLength={512}
              />
              <ErrorMessage name="documentation_url" component="div" className="input-feedback" />

              {/* Signup URL */}
              <FormLabel htmlFor='documentation_url'>
                Signup URL
            </FormLabel>
              <input
                type='text'
                name='signup_url'
                id='new_api_form_input_signup_url'
                placeholder='API registration URL'
                value={values.signup_url}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.signup_url && touched.signup_url ? 'text-input error' : 'text-input'}
                maxLength={512}
              />
              <ErrorMessage name="signup_url" component="div" className="input-feedback" />

              <input type='submit' />
            </form>
          )
        }}
      </Formik>
    </div >
  )
}
