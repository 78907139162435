import { Reducer } from 'redux';

import MedicationActions from 'stores/actionInterfaces/personaBuilder/medicationActionInterfaces';
import { PersonaBuilderMedicationState } from 'stores/stateInterfaces/personaBuilder/medicationStateInterface';
import { EUpdateState } from 'stores/shared/ENums';

const initialState: PersonaBuilderMedicationState = {
	medicationTableState: EUpdateState.Empty,
	medications: [],
	currentMedication: null,
	medication_dropdowns: {
		medication_forms: []
	},
	saveDisabled: true
};

export const PersonaBuilderMedicationReducers: Reducer<PersonaBuilderMedicationState> = (
	state: PersonaBuilderMedicationState,
	action: MedicationActions
) => {

	switch (action.type) {
		case 'GET_MEDICATION_DROPDOWNS_RECEIVED': {
			return {
				...state,
				medication_dropdowns: action.medication_dropdowns
			}
		}
		case 'GET_ALL_MEDICATIONS_REQUEST': {
			return {
				...state,
				medicationTableState: EUpdateState.Loading
			}
		}
		case 'GET_ALL_MEDICATIONS_RECEIVED': {
			return {
				...state,
				medications: action.medications
			}
		}
		case 'GET_MEDICATION_REQUEST': {
			return {
				...state,
				currentMedication: null
			}
		}
		case 'GET_MEDICATION_RECEIVED': {
			return {
				...state,
				currentMedication: action.medication
			}
		}
		case 'SET_SAVE_DISABLED': {
			return {
				...state,
				saveDisabled: action.saveDisabled
			}
		}
	}
	return state || initialState;
};