import * as React from 'react';                        
import { IOrganization } from "common/interfaces/personaBuilder/IOrganization";
import { RouteComponentProps } from "react-router-dom";
import { ResolveThunks, connect } from "react-redux";
import { OrganizationActionCreators } from "stores/actions/personaBuilder/organizationActions";
import { ApplicationState } from "stores";
import { Grid, Button } from "@material-ui/core";
import css from 'components/Forms/PersonaBuilder/FormPersonaBuilder/FormPatientDetails/persona-details.module.scss';
import { MuiButton } from "components/MaterialUI";
import { ChevronLeft } from "@material-ui/icons";
import { Link } from 'react-router-dom';
import classes from 'classnames';
import { FormOrganizationDetails } from "components/Forms/PersonaBuilder/FormPersonaBuilderOrganization/FormOrganizationDetails";


interface IOrganizationDetailsRouteProps {
	id?: string
}

interface IOrganizationDetailsTabReduxProps {
	currentOrganization: any;
}

const OrganizationDetailsTabBase: React.FC<RouteComponentProps<IOrganizationDetailsRouteProps> &
	IOrganizationDetailsTabReduxProps &
	ResolveThunks<typeof OrganizationActionCreators>> = props => {
	const {
		currentOrganization
	} = props;

	let _submitForm = null;
	const _bindSubmitForm = (submitForm) => {
		_submitForm = submitForm;
	};

	const _handleSubmit = () => {
		if (_submitForm) {
			_submitForm();
		}
	};
	

	const isNew = props.match.params.id === 'create';

	const saveOrganization = (values) => {
		if (isNew) {
			props.createOrganization(values);
		} else {
			props.updateOrganization(values);
		}
	};

	return (
		<>
			<FormOrganizationDetails
				bindSubmitForm={_bindSubmitForm}
				defaultFormContent={!isNew && props.currentOrganization}
				formAction={saveOrganization}
			/>
			<Grid container xs={12} className={css.actionsContainer}>
				<Grid container xs={12} md={6} justify={'flex-start'} alignContent={'center'}>
					<Button component={Link} to={`/persona-builder/organization-listing/`}>
						<ChevronLeft className={classes([css.leftIcon], [css.iconSmall])}/>
						Back
					</Button>
				</Grid>
				<Grid container xs={12} md={6} justify={'flex-end'} alignContent={'center'}>
					<MuiButton variant="contained" className={css.button} disabled={false} onClick={_handleSubmit}>
						Save
					</MuiButton>
				</Grid>
			</Grid>
		</>
	);
};

const mapStateToProps = (state: ApplicationState): IOrganizationDetailsTabReduxProps => {
	return {
		currentOrganization: state.personaBuilderOrganization.currentOrganization || {}
	};
};

export const OrganizationDetailsTab = connect(
	mapStateToProps,
	OrganizationActionCreators
)(OrganizationDetailsTabBase);