import * as React from 'react';
import { Circle } from 'components/Circle';
import { ButtonDropDown } from 'components/ButtonDropdown';
import { ButtonDropDownRof } from 'components/ButtonDropdown/ButtonDropDownRof';
import css from './grid-item-content-persona.module.scss';
import classes from 'classnames';
import { Link } from 'react-router-dom';
import { Profile } from 'components/Profile';
import { Button } from 'components/Button';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { connect } from 'react-redux';
import { convertIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';
import { ApplicationState } from 'stores';
import { IRof } from 'common/interfaces/IRof';
import { convertArrayToIndexedObject } from 'common/utils/convertArrayToIndexedObject';

interface IGridItemContentPersonaProps {
	image: string;
	personaId: number;
	firstName: string;
	lastName: string;
	age: string;
	type: string;
	useCases?: string;
	isWorking?: boolean;
}

interface IGridItemContentPersonaState {
	isUseCasesLong: boolean;
	isUseCasesActive: boolean;
	useCasesHeight: number;
}

interface IGridItemContentPersonaReduxProps {
	rofList: IRof[];
}

export class GridItemContentPersonaBase extends React.PureComponent<
	IGridItemContentPersonaProps & IGridItemContentPersonaReduxProps,
	IGridItemContentPersonaState
> {
	private useCasesContainer: React.RefObject<HTMLDivElement>;
	private useCases: React.RefObject<HTMLDivElement>;

	constructor(props) {
		super(props);
		this.useCasesContainer = React.createRef();
		this.useCases = React.createRef();
		this.state = {
			isUseCasesLong: false,
			isUseCasesActive: false,
			useCasesHeight: null
		};
	}

	componentDidMount() {
		const useCasesContainerHeight = this.useCasesContainer.current
			.clientHeight;
		const useCasesHeight = this.useCases.current.clientHeight;
		console.log('useCasesContainerHeight', useCasesContainerHeight);
		console.log('useCasesHeight', useCasesHeight);
		if (useCasesHeight > useCasesContainerHeight) {
			this.setState({
				isUseCasesLong: true,
				useCasesHeight
			});
		}
	}

	toggleUseCases = () => {
		this.setState({
			isUseCasesActive: !this.state.isUseCasesActive
		});
	};

	render() {
		const {
			image,
			personaId,
			firstName,
			lastName,
			age,
			type,
			useCases,
			isWorking,
			rofList
		} = this.props;

		const useCasesHeight = this.state.isUseCasesActive
			? { height: `${this.state.useCasesHeight}px` }
			: null;
		const useCasesContainerHeight = this.state.isUseCasesActive
			? { height: `${this.state.useCasesHeight + 30}px` }
			: null;

		console.log('rofList', rofList);

		return (
			<div className={css.gridItemContentPersona}>
				<Profile
					image={image}
					to={`/persona/${personaId}`}
					name={`${firstName} ${lastName}`}
					age={age}
					type={type}
					className={css.top}
				/>

				<div className={css.bottom}>
					<ul className='reset'>
						{useCases && (
							<li className={css.bottomItem}>
								<div className={css.useCasesSpacer}>
									<div
										style={useCasesContainerHeight}
										className={classes(
											css.useCasesSection,
											{
												[css.active]: this.state
													.isUseCasesActive
											}
										)}
									>
										<div
											className={classes(
												css.useCasesContainer,
												{
													[css.active]: this.state
														.isUseCasesActive
												},
												{
													[css.longUseCases]: this
														.state.isUseCasesLong
												}
											)}
											ref={this.useCasesContainer}
											style={useCasesHeight}
										>
											<div ref={this.useCases}>
												<b>Use Cases: </b>
												{useCases}
											</div>
										</div>
										{this.state.isUseCasesLong && (
											<div
												className={css.showAllContainer}
											>
												<Button
													style='link'
													onClick={
														this.toggleUseCases
													}
												>
													{this.state.isUseCasesActive
														? 'Show Less Use Cases'
														: 'Show More Use Cases'}
												</Button>
											</div>
										)}
									</div>
								</div>
							</li>
						)}
						{rofList.length > 0 && (
							<li className={classes(css.bottomItem)}>
								<ButtonDropDown
									DropDownContent={props => (
										<ButtonDropDownRof
											personaId={personaId}
											{...props}
										/>
									)}
									isLoading={isWorking}
									isLoadingLabel='Adding Persona...'
								>
									Add to Sandbox
								</ButtonDropDown>
							</li>
						)}
					</ul>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (
	state: ApplicationState
): IGridItemContentPersonaReduxProps => {
	const currentOrganizationId = state.organization.currentOrganization ? state.organization.currentOrganization : state.auth.user.attributes.organizationId;
	const templates = state.rof.templates;
	const allRof = convertIndexedObjectToArray(state.rof.allRof).filter(
		ringOfFhir => {
			console.log(
				'convertArrayToIndexedObject',
				convertArrayToIndexedObject(templates)[
				ringOfFhir.rof_template_id
				] &&
				convertArrayToIndexedObject(templates)[
					ringOfFhir.rof_template_id
				].fhir_version === 'R4'
			);
			return (
				ringOfFhir.organization_id === currentOrganizationId &&
				convertArrayToIndexedObject(templates)[
				ringOfFhir.rof_template_id
				] &&
				convertArrayToIndexedObject(templates)[
					ringOfFhir.rof_template_id
				].fhir_version === 'R4'
			);
		}
	);

	console.log('mapStateToProps allRof', allRof);

	return {
		rofList: allRof ? allRof : []
	};
};

export const GridItemContentPersona = connect(mapStateToProps)(
	GridItemContentPersonaBase
);
