import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { AppFrame } from 'components/Page/AppFrame';
import { PageTitleArea, InnerContainer, PageTabBar } from 'components/Page';
import { rofActionCreators } from 'stores/actions/rofActions';
import { ApplicationState } from 'stores';
import { EUpdateState } from 'stores/shared/ENums';
import { TNavigationItems } from 'common/interfaces';
import { RouteComponentProps } from 'react-router';
import { OverviewTab } from './OverviewTab/OverviewTab';
import { CapabilitiesStatementTab } from './CapabilitiesStatementTab';
import { SmartAppsTab } from './SmartAppsTab';
import { VisualizationsTab } from './VisualizationsTab/VisualizationsTab';
import { IRof } from 'common/interfaces/IRof';
import { TUserType } from 'common/types/TUserType';
import { Loading } from 'components/Loading';
import { SandboxStatus } from 'components/SandboxStatus/SandboxStatus';
import { SmartAuthUsersTab } from './SmartAuthUsersTab/SmartAuthUsersTab';
import { organizationActionCreators } from 'stores/actions/organizationActions';

const pitViewActionCreators = {
	...rofActionCreators,
	...organizationActionCreators
};

interface PitRouteProps {
	rofId?: string;
	pitId?: string;
}

interface pitViewReduxProps {
	currentPit: any;
	currentRof: IRof;
	currentUserType: TUserType;
}

class PitViewBase extends React.PureComponent<
	ResolveThunks<typeof pitViewActionCreators> & pitViewReduxProps & RouteComponentProps<PitRouteProps>
> {
	UNSAFE_componentWillMount() {
		const rofId = parseInt(this.props.match.params.rofId);
		const pitId = parseInt(this.props.match.params.pitId);

		this.props.setCurrentRofId(rofId);
		this.props.setCurrentPitId(pitId);
		this.props.getRof(rofId);
		this.props.getRofDetails(rofId);
		this.props.setCurrentRofOrganization(rofId);
	}

	get breadcrumbItems(): TNavigationItems {
		const { currentRof, currentPit } = this.props;
		if (currentRof && currentPit) {
			return [
				{
					label: 'All Sandboxes',
					location: '/rof-management'
				},
				{
					label: currentRof.name,
					location: `/sandbox/${currentRof.id}`
				},
				{
					label: currentPit ? currentPit.name : '',
					location: `/sandbox/${currentRof.id}/pit/${currentPit.id}`
				}
			];
		} else {
			return null;
		}
	}

	isLoading = (): boolean => {
		return !this.props.currentPit;
	};

	render() {
		const { currentPit, currentUserType, currentRof } = this.props;
		const pitId = this.props.match.params.pitId;
		const currentRofId = this.props.match.params.rofId;

		const basePitUrl = `/sandbox/${currentRofId}/pit/${pitId}`;
		const pageTabBarItems: TNavigationItems = [
			{
				label: 'Overview',
				location: basePitUrl,
				route: `/sandbox/:rofId/pit/:pitId`,
				component: OverviewTab
			},
			{
				label: 'Capabilities Statement',
				location: `${basePitUrl}/capabilities-statement`,
				route: `/sandbox/:rofId/pit/:pitId/capabilities-statement`,
				component: CapabilitiesStatementTab
			},
			{
				label: 'SMART Apps',
				location: `${basePitUrl}/app`,
				route: `/sandbox/:rofId/pit/:pitId/app`,
				component: SmartAppsTab
			},
			{
				label: 'SMART Auth Users',
				location: `${basePitUrl}/users`,
				route: `/sandbox/:rofId/pit/:pitId/users`,
				component: SmartAuthUsersTab
			}
		];

		if (currentUserType === 'mihin-admin') {
			pageTabBarItems.push({
				label: 'Visualizations',
				location: `${basePitUrl}/visualizations`,
				route: `/sandbox/:rofId/pit/:pitId/visualizations`,
				component: VisualizationsTab
			});
		}

		if (this.isLoading()) {
			return (
				<AppFrame>
					<Loading />
				</AppFrame>
			);
		}

		return (
			<AppFrame>
				<InnerContainer>
					<SandboxStatus currentSandbox={currentRof}></SandboxStatus>
					<PageTitleArea breadcrumbItems={this.breadcrumbItems}>{currentPit.name}</PageTitleArea>
					<PageTabBar navigationItems={pageTabBarItems} />
				</InnerContainer>
			</AppFrame>
		);
	}
}

const mapStateToProps = (state: ApplicationState): pitViewReduxProps => {
	const currentPit =
		state.rof.allRofPits && state.rof.currentRofId && state.rof.allRofPits[state.rof.currentRofId]
			? state.rof.allRofPits[state.rof.currentRofId][state.rof.currentPitId]
			: null;

	const currentRof = state.rof.allRof[state.rof.currentRofId];

	return {
		currentPit,
		currentRof,
		currentUserType: state.auth.user.attributes['custom:user_type']
	};
};

export const PitView = connect(mapStateToProps, pitViewActionCreators)(PitViewBase);
