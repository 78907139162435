import * as React from 'react';

import { AppFrameAuth } from './AppFrameAuth';

import { Formik } from 'formik';
import * as yup from 'yup';

import { authActionCreators } from 'stores/actions/authActions';
import { ApplicationState } from 'stores';
import {
	connect,
	ResolveThunks,
	HandleThunkActionCreator,
	InferThunkActionCreatorType,
} from 'react-redux';
import { RouteComponentProps, Redirect, Router, withRouter, StaticContext } from 'react-router';
import { Link } from 'react-router-dom';

import css from './auth.module.scss';

import { Error, Success } from 'components/Toast';
import { Button } from 'components/Button';
import { TChallengeName } from 'common/interfaces';
import { IFormActions } from 'common/interfaces/IFormActions';

const queryString = require('query-string');

const loginActionCreators = {
	...authActionCreators,
};

type ILoginDispatchProps = ResolveThunks<typeof loginActionCreators>;
export type LoginLocationState = {
	successMessage: string;
};

interface ILoginProps extends RouteComponentProps<{}, StaticContext, LoginLocationState> {
	redirectUri: string;
}
interface ILoginReduxProps {
	user: any;
	error: string;
	isNewPasswordRequired: boolean;
}

type Props = ILoginProps & ILoginReduxProps & ILoginDispatchProps;

class LoginBase extends React.PureComponent<Props> {
	UNSAFE_componentWillMount() {
		this.props.getAuth();
	}

	render() {
		const { user, error, redirectUri, location, isNewPasswordRequired, signIn } = this.props;

		if (isNewPasswordRequired) {
			console.log('isNewPasswordRequired');
			return <Redirect to='/new-password-required' />;
		}

		if (user) {
			if (redirectUri) {
				window.location.href = redirectUri;
				return;
			} else {
				return <Redirect to='/' />;
			}
		}

		const getParamCustomer = 'token';

		const customerId =
			this.props.location && this.props.location.search
				? queryString.parse(this.props.location.search)[getParamCustomer]
				: null;

		const url = `/create-account?${getParamCustomer}=${encodeURIComponent(customerId)}`;
		console.log('TCL: LoginBase -> render -> customerId', customerId);
		console.log('TCL: LoginBase -> render -> encodedURL', url);

		return (
			<AppFrameAuth
				title='Sign in'
				description='Welcome to Interoperability Land&trade;'
				backgroundImage='hospitalAndChip'
				titleSecondary='Don’t have an Account?'
				descriptionSecondary={`If you don't already have an account, you can create one to access Interoperability
				Land.`}
				hasHeader={customerId}
				ContentSecondary={
					customerId
						? () => {
								return (
									<>
										<Link className='button' to={url}>
											Create Account
										</Link>
									</>
								);
						  }
						: null
				}
				Content={(authFrameProps) => (
					<>
						{location.state && location.state.successMessage && (
							<Success>{location.state.successMessage}</Success>
						)}
						{error ? <Error>{error}</Error> : ''}
						<Formik
							initialValues={{ username: '', password: '' }}
							onSubmit={(values, { setSubmitting }) => {
								signIn(values.username, values.password, authFrameProps.formActions);
								setSubmitting(false);
							}}
							validationSchema={yup.object().shape({
								username: yup.string().required('Required'),
								password: yup.string().required('Required'),
							})}
						>
							{(props) => {
								const {
									values,
									touched,
									errors,
									dirty,
									isSubmitting,
									handleChange,
									handleBlur,
									handleSubmit,
									handleReset,
								} = props;

								return (
									<form onSubmit={handleSubmit}>
										<label htmlFor='username'>Username</label>
										<input
											id='username'
											placeholder='Enter Username'
											type='text'
											value={values.username}
											onChange={handleChange}
											onBlur={handleBlur}
											autoComplete='username'
											className={errors.username && touched.username ? 'text-input error' : 'text-input'}
										/>
										{errors.username && touched.username && (
											<div className='input-feedback'>{errors.username}</div>
										)}

										<div className='row'>
											<label className={css.passwordLabel} htmlFor='password'>
												Password
											</label>
											<Link className={css.forgotPasswordLink} to='/forgot-password'>
												Forgot your password?
											</Link>
										</div>
										<input
											id='password'
											placeholder='Enter Password'
											type='password'
											value={values.password}
											onChange={handleChange}
											onBlur={handleBlur}
											autoComplete='current-password'
											className={errors.password && touched.password ? 'text-input error' : 'text-input'}
										/>
										{errors.password && touched.password && (
											<div className='input-feedback'>{errors.password}</div>
										)}

										<Button type='submit' disabled={isSubmitting}>
											Sign in
										</Button>
									</form>
								);
							}}
						</Formik>
					</>
				)}
			></AppFrameAuth>
		);
	}
}

const mapStateToProps = (state: ApplicationState, ownProps: ILoginProps): ILoginReduxProps => {
	const user = state.auth.user;
	return {
		user,
		error: state.auth.loginError,
		isNewPasswordRequired:
			state.auth.user && state.auth.user.challengeName === 'NEW_PASSWORD_REQUIRED' ? true : false,
	};
};

export const Login = withRouter(connect(mapStateToProps, loginActionCreators)(LoginBase));
