import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { clone } from 'ramda';
import { differenceInCalendarYears } from 'date-fns';
import { GridItem, GridContainer } from 'components/Grid';
import { GridItemContentPersona } from 'components/Grid/GridItemContentPersona';
import { convertIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';

import { ApplicationState } from 'stores';
import { IPersona } from 'common/interfaces';
import { EUpdateState } from 'stores/shared/ENums';
import { personaActionCreators } from 'stores/actions/personaActions';
import { Loading } from 'components/Loading';

const actionCreators = {
	...personaActionCreators
};

interface IAllPersonasTabReduxProps {
	personas: IPersona[];
	applyPersonaId: number;
	applyPersonaUpdateState: EUpdateState;
	allPersonasUpdateState: EUpdateState;
	currentOrganizationRofLoadingState: EUpdateState;
	templatesLoadingState: EUpdateState;
}

class AllPersonasTabBase extends React.PureComponent<ResolveThunks<typeof actionCreators> & IAllPersonasTabReduxProps> {
	UNSAFE_componentWillMount() {
		this.props.getPersonas();
	}

	isLoading = () => {
		return !(
			this.props.allPersonasUpdateState === EUpdateState.Loaded &&
			this.props.currentOrganizationRofLoadingState == EUpdateState.Loaded &&
			this.props.templatesLoadingState === EUpdateState.Loaded
		);
	};

	render() {
		const { personas, applyPersonaId, applyPersonaUpdateState } = this.props;

		if (this.isLoading()) {
			return <Loading />;
		}

		return (
			<GridContainer>
				{personas.map((persona, index) => (
					<GridItem hasPadding={false} key={index}>
						<GridItemContentPersona
							image={persona.image_url}
							personaId={persona.id}
							firstName={persona.first_name}
							lastName={persona.last_name}
							age={differenceInCalendarYears(new Date(), persona.dob).toString()}
							type={persona.type}
							useCases={persona.use_cases}
							isWorking={applyPersonaId == persona.id && applyPersonaUpdateState == EUpdateState.Busy}
						/>
					</GridItem>
				))}
			</GridContainer>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IAllPersonasTabReduxProps => ({
	personas: state.persona.allPersonas ? convertIndexedObjectToArray(clone(state.persona.allPersonas)) : null,
	applyPersonaId: state.persona.applyPersonaId,
	applyPersonaUpdateState: state.persona.applyPersonaUpdateState,
	allPersonasUpdateState: state.persona.allPersonasUpdateState,
	currentOrganizationRofLoadingState:
		state.rof.organizationsRofLoadingState[state.auth.user.attributes.organizationId],
	templatesLoadingState: state.rof.templatesLoadingState
});

export const AllPersonasTab = connect(
	mapStateToProps,
	actionCreators
)(AllPersonasTabBase);
