import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import { IModalForm, IPersonaUploadBioDetails } from 'common/interfaces';
import { RequiredIndicator } from 'components/FormElements/RequiredIndicator';
import * as yup from 'yup';
import { FormLabel } from 'components/FormElements/FormLabel';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { EUpdateState } from 'stores/shared/ENums';
import { Error } from 'components/Toast/Error';
import { clone } from 'ramda';

import { personaUploadActionCreators } from 'stores/actions/personaUploadActions';

import DateFnsUtils from '@date-io/date-fns';
import css from './persona-upload-details.module.scss';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePickerField } from 'components/FormElements/DatePickerField'

let initialValues = {
	first_name: null,
	last_name: null,
	descr: null,
	bio: null,
	persona_type: null,
	dob: null,
	gender: null,
	marital_status: null,
	location: null,
	image_url: null,
	use_cases: []
};

const actionCreators = {
	...personaUploadActionCreators
};

interface IFormPersonaUploadDetailsEntryReduxProps {
	details: IPersonaUploadBioDetails;
	updateState: EUpdateState;
	errorType: string;
	errorMessage: string;
}

class FormPersonaUploadDetailsEntryBase extends React.PureComponent<
	ResolveThunks<typeof actionCreators> & IModalForm & IFormPersonaUploadDetailsEntryReduxProps
> {
	componentDidUpdate(prevProps, nextProps) {
		this.props.setFormLoadingAttribute(this.props.updateState === EUpdateState.Updating);
	}

	master_use_cases = [
		'Data Exchange for Quality Measures (DEQM)',
		'Medication Reconciliation Post-Discharge (MRP)',
		'Coverage Requirements Discovery (CRD)',
		'Documentation Templates and Coverage Rules (DTR)',
		'Clinical Data Exchange (CDex)',
		'Payer Data Exchange (PDex)',
		'Alerts/Notifications',
		'Risk Based Contract Member Identification',
		'Prior Authorization Support',
		'Performing-Laboratory Reporting',
		'Gaps in Care and Information',
		'Chronic Illness Documentation for Risk Adjustment',
		'Patient Cost Transparency',
		'Patient Data Exchange',
		'Payer Coverage Decision Exchange'
	];

	render() {
		const {
			setFormDisabledAttribute,
			bindSubmitForm,
			formAction,
			details,
			isMultistepModal,
			updateState,
			errorMessage
		} = this.props;

		let values = clone(initialValues);

		if (details) {
			values = {
				...details,
				use_cases: (details.use_cases || '').split(',').map(item => item.trim())
			};
		}

		setFormDisabledAttribute(false);

		return (
			<Formik
				initialValues={values}
				onSubmit={async (values, { setSubmitting }) => {
					console.log('Form Submission');
					this.props.setDetails({
						...values,
						use_cases: values.use_cases.join(', ')
					});
					formAction(values);
					setSubmitting(false);
					//this.props.createPersona();
				}}
				validationSchema={yup.object().shape({
					first_name: yup
						.string()
						.max(50)
						.required('Required'),
					last_name: yup
						.string()
						.max(50)
						.required('Required'),
					descr: yup
						.string()
						.max(255)
						.required('Required'),
					bio: yup.string().required('Required'),
					persona_type: yup
						.string()
						.max(50)
						.required('Required'),
					dob: yup.date().required('Required'),
					use_cases: yup
						.array()
						.min(1)
						.required('Required')
				})}
			>
				{props => {
					const {
						values,
						touched,
						errors,
						dirty,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
						handleReset,
						submitForm
					} = props;
					if (bindSubmitForm) {
						bindSubmitForm(submitForm);
					}
					return (
						<form onSubmit={handleSubmit}>
							<p>Enter biographical details.</p>
							{updateState === EUpdateState.Failed && <Error>{errorMessage}</Error>}

							<div className={css.personaDetailsCol1}>
								<FormLabel htmlFor='first_name' isRequired={true}>
									First Name
								</FormLabel>

								<input
									id='first_name'
									placeholder='Enter first name'
									type='text'
									value={values.first_name}
									onChange={handleChange}
									onBlur={handleBlur}
									className={
										errors.first_name && touched.first_name ? 'text-input error' : 'text-input'
									}
								/>
								{errors.first_name && touched.first_name && (
									<div className='input-feedback'>{errors.first_name}</div>
								)}
							</div>
							<div className={css.personaDetailsCol2}>
								<FormLabel htmlFor='last_name' isRequired={true}>
									Last Name
								</FormLabel>

								<input
									id='last_name'
									placeholder='Enter last name'
									type='text'
									value={values.last_name}
									onChange={handleChange}
									onBlur={handleBlur}
									className={
										errors.last_name && touched.last_name ? 'text-input error' : 'text-input'
									}
								/>
								{errors.last_name && touched.last_name && (
									<div className='input-feedback'>{errors.last_name}</div>
								)}
							</div>
							<div className={css.personaDetailsCol1}>
								<FormLabel htmlFor='dob' isRequired={true}>
									Date of Birth
								</FormLabel>

								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<Field
										name='dob'
										value={values.dob}
										component={DatePickerField}
										className={
											errors.dob && touched.dob ? 'mtl-picker-input error' : 'mtl-picker-input'
										}
									/>
								</MuiPickersUtilsProvider>
								{errors.dob && touched.dob && <div className='input-feedback'>{errors.dob}</div>}
							</div>
							<div className={css.personaDetailsCol2}>
								<FormLabel htmlFor='persona_type' isRequired={true}>
									Type
								</FormLabel>

								<input
									id='persona_type'
									placeholder='Enter persona type (e.g. Opioid)'
									type='text'
									value={values.persona_type}
									onChange={handleChange}
									onBlur={handleBlur}
									className={
										errors.persona_type && touched.persona_type ? 'text-input error' : 'text-input'
									}
								/>
								{errors.persona_type && touched.persona_type && (
									<div className='input-feedback'>{errors.persona_type}</div>
								)}
							</div>

							<FormLabel htmlFor='use_cases' isRequired={true}>
								Use Case
							</FormLabel>

							<Select
								id='use_cases'
								multiple
								value={values.use_cases}
								onChange={handleChange('use_cases')}
								input={<Input id='select-multiple-checkbox' />}
								renderValue={selected => (selected as string[]).join(', ')}
								className={
									errors.use_cases && touched.use_cases
										? 'mtl-select-input error'
										: 'mtl-select-input'
								}
							>
								{this.master_use_cases.map(use_case => (
									<MenuItem key={use_case} value={use_case}>
										<Checkbox checked={values.use_cases.indexOf(use_case) > -1} />
										<ListItemText primary={use_case} />
									</MenuItem>
								))}
							</Select>

							<FormLabel htmlFor='descr' isRequired={true}>
								Short Description
							</FormLabel>

							<input
								id='descr'
								placeholder='Enter short description'
								type='text'
								value={values.descr}
								onChange={handleChange}
								onBlur={handleBlur}
								className={errors.descr && touched.descr ? 'text-input error' : 'text-input'}
							/>
							{errors.descr && touched.descr && <div className='input-feedback'>{errors.descr}</div>}

							<FormLabel htmlFor='bio' isRequired={true}>
								Persona Biography
							</FormLabel>

							<textarea
								id='bio'
								placeholder='Enter full persona biography'
								rows={4}
								value={values.bio}
								onChange={handleChange}
								onBlur={handleBlur}
								className={errors.bio && touched.bio ? 'text-input error' : 'text-input'}
							/>
							{errors.bio && touched.bio && <div className='input-feedback'>{errors.bio}</div>}
						</form>
					);
				}}
			</Formik>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IFormPersonaUploadDetailsEntryReduxProps => ({
	details: state.personaUpload.details,
	updateState: state.personaUpload.updateState,
	errorType: state.personaUpload.updateErrorType,
	errorMessage: state.personaUpload.updateErrorMessage
});

export const FormPersonaUploadDetailsEntry = connect(
	mapStateToProps,
	actionCreators
)(FormPersonaUploadDetailsEntryBase);
