import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps } from 'react-router';
// import { useHistory } from 'react-router-dom';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { ApplicationState } from 'stores';
// import { Button, Grid } from '@material-ui/core';
// import { ChevronLeft, ChevronRight, Delete } from '@material-ui/icons';
// import { MuiButton } from 'components/MaterialUI';
// import classes from 'classnames';
// import css from './provider-details-tab.module.scss';
// import { FormProviderDetails } from 'components/Forms/PersonaBuilder/FormPersonaBuilderProvider/FormProviderDetails';

interface IFamilyMemberConditionRouteProps {
	id?: string
}

interface IFamilyMemberConditionTabReduxProps {
	currentPatient: any;
}

export const FamilyMemberConditionTabBase: React.FC<RouteComponentProps<IFamilyMemberConditionRouteProps> &
IFamilyMemberConditionTabReduxProps &
ResolveThunks<typeof PersonaBuilderActionCreators>> = props => {
  return (
    <>Condition</>
  )
}

const mapStateToProps = (state: ApplicationState): IFamilyMemberConditionTabReduxProps => {
	return {
		currentPatient: state.personaBuilder.currentPatient || {}
	};
};

export const FamilyMemberConditionTab = connect(
	mapStateToProps,
	PersonaBuilderActionCreators
)(FamilyMemberConditionTabBase);