import organizationActions from 'stores/actionInterfaces/organizationActionInterfaces';
import { AppThunkAction } from 'stores';

import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { EUpdateState } from 'stores/shared/ENums';
import { url } from 'settings';
import { IOrganization, IUser } from 'common/interfaces';
import { modalActionCreators } from 'stores/actions/modalActions';
import { sharedHistory } from '../../sharedStore';
import { TSetErrorMessage } from 'common/types/TSetErrorMessage';
import { TSetFormLoadingAttribute } from 'common/types/TSetFormLoadingAttribute';
import { messageActionCreators } from './messageActions';
import { IFormActions } from 'common/interfaces/IFormActions';
import { errorMessages } from 'common/data/errorMessages';
import { LoginLocationState } from 'components/Auth/Login';

export const organizationActionCreators = {
	getOrganizations: (): AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			if (getState().organization.allOrganizationsLoadingState === EUpdateState.Loaded) {
				return;
			}

			dispatch({ type: 'GET_ORGANIZATIONS_REQUEST' });

			const headers = await getCurrentAuthHeaders('json');

			try {
				const getOrganizationsRequest = await fetch(`${url}org`, {
					headers
				});

				const organizations: IOrganization[] = await getOrganizationsRequest.json();

				dispatch({
					type: 'GET_ORGANIZATIONS_RECEIVED',
					organizations
				});
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	createOrganization: (
		organization: IOrganization,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute
	): AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'CREATE_NEW_ORGANIZATION_REQUEST' });

			const headers = await getCurrentAuthHeaders('json');

			try {
				const createOrganizationsRequest = await fetch(`${url}org/create`, {
					method: 'POST',
					headers,
					body: JSON.stringify(organization)
				});

				console.log('createOrganizationsRequest', createOrganizationsRequest);

				const createOrganizationsResponse = await createOrganizationsRequest.json();

				if (createOrganizationsRequest.ok === false) {
					console.log('createOrganizationsRequest.ok === false');
					setErrorMessage(createOrganizationsResponse.errorMessage);
					setFormLoadingAttribute(false);
					return;
				}

				console.log('createOrganizationsResponse', createOrganizationsResponse);

				organization['id'] = createOrganizationsResponse.organization_id;

				//@ts-ignore
				dispatch(modalActionCreators.dismissModal());

				dispatch({
					type: 'CREATE_NEW_ORGANIZATION_RECEIVED',
					organization
				});
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	getOrganizationUsers: (organizationId: number): AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			const currentOrganizationLoadingState = getState().organization.organizationsUsersLoadingState[
				organizationId
			];

			if (
				currentOrganizationLoadingState === EUpdateState.Loading
			) {
				return;
			}

			const headers = await getCurrentAuthHeaders('json');
			const fetchURL = `${url}org/${organizationId}/user`;
			try {
				dispatch({
					type: 'GET_CURRENT_ORGANIZATION_USERS_REQUEST',
					organizationId
				});

				const getOrganizationUsersRequest = await fetch(fetchURL, {
					headers
				});

				const users = await getOrganizationUsersRequest.json();

				console.log('getOrganizationUsers users', users);

				dispatch({
					type: 'GET_CURRENT_ORGANIZATION_USERS_RECEIVED',
					organizationId,
					users
				});
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	setCurrentOrganization: (organizationId?: number): AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			organizationId = organizationId ? organizationId : getState().auth.user.attributes.organizationId;
			dispatch({ type: 'SET_CURRENT_ORGANIZATION', organizationId });
		};
	},
	setCurrentRofOrganization: (rofId?: number): AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {

			const headers = await getCurrentAuthHeaders('json');
			const fetchURL = `${url}rof/${rofId}`;
			const getRofRequest = await fetch(fetchURL, {
				headers
			});
			const result = await getRofRequest.json();
			const organizationId = Number(result.organization_id)
			dispatch({ type: 'SET_CURRENT_ORGANIZATION', organizationId });
		};
	},
	getParentOrgName: (organizationId: number): AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'GET_PARENT_ORG_NAME_REQUEST' });
			const headers = await getCurrentAuthHeaders('json');
			const fetchURL = `${url}org/${organizationId}/parent_organization_name`;
			const getParentOrgNameRequest = await fetch(fetchURL, {
				headers
			});

			const result = await getParentOrgNameRequest.json();
			const parentOrgName = String(result.parent_organization_name)
			dispatch({
				type: 'GET_PARENT_ORG_NAME_RECEIVED',
				parentOrgName,
			});
		}
	},
	getOrganization: (organizationId: number): AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			const currentOrganizationLoadingState = getState().organization.organizationLoadingState[organizationId];
			console.log(`getOrganization ${organizationId} ${currentOrganizationLoadingState}`);
			if (
				currentOrganizationLoadingState === EUpdateState.Loading ||
				currentOrganizationLoadingState === EUpdateState.Loaded
			) {
				return;
			}

			organizationId = organizationId ? organizationId : getState().organization.currentOrganization;

			dispatch({
				type: 'GET_CURRENT_ORGANIZATION_REQUEST',
				organizationId
			});
			const headers = await getCurrentAuthHeaders('json');

			try {
				const getOrganizationRequest = await fetch(`${url}org/${organizationId}`, {
					headers
				});

				const organization = await getOrganizationRequest.json();

				dispatch({
					type: 'GET_CURRENT_ORGANIZATION_RECEIVED',
					organization,
					organizationId
				});
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	updateOrganization: (
		organization: IOrganization,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute
	): AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			dispatch({
				type: 'UPDATE_CURRENT_ORGANIZATION_REQUEST',
				organizationId: organization.id
			});

			const headers = await getCurrentAuthHeaders('json');

			const {
				name,
				descr,
				address,
				city,
				state,
				postal_code,
				contact_name,
				contact_phone,
				contact_email,
			} = organization;

			console.log('organization', organization);

			const updatedOrganization = {
				name,
				descr,
				address,
				city,
				state,
				postal_code,
				contact_name,
				contact_phone,
				contact_email,
			};

			console.log('updatedOrganization', updatedOrganization);

			try {
				const updateOrganizationsRequest = await fetch(`${url}org/${organization.id}`, {
					method: 'post',
					headers,
					body: JSON.stringify(updatedOrganization)
				});

				console.log('updateOrganizationsRequest', updateOrganizationsRequest);

				const updateOrganizationsResponse = await updateOrganizationsRequest.json();

				console.log('updateOrganizationsResponse', updateOrganizationsResponse);

				if (updateOrganizationsRequest.ok === false) {
					console.log('updateOrganizationsRequest.ok === false');
					setErrorMessage(updateOrganizationsResponse.errorMessage);
					setFormLoadingAttribute(false);
					return;
				}

				//@ts-ignore
				dispatch(modalActionCreators.dismissModal());

				dispatch({
					type: 'UPDATE_CURRENT_ORGANIZATION_RECEIVED',
					organization,
					organizationId: organization.id
				});
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	createUser: (
		organizationId: number,
		user: IUser,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute
	): AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'CREATE_USER_REQUEST' });
			const headers = await getCurrentAuthHeaders('json');
			const { organization_id, ...userData } = user;
			const bodyContent = {
				user_data: userData
			};

			const fetchUrl = `${url}org/${organizationId}/user/create`;

			try {
				const createUserRequest = await fetch(fetchUrl, {
					method: 'post',
					headers,
					body: JSON.stringify(bodyContent)
				});

				const creatUserResponse = await createUserRequest.json();

				if (createUserRequest.ok === false) {
					setErrorMessage(creatUserResponse.errorMessage);
					setFormLoadingAttribute(false);
					return;
				}

				//@ts-ignore
				dispatch(modalActionCreators.dismissModal());

				dispatch({
					type: 'CREATE_USER_RECEIVED',
					user: creatUserResponse,
					organizationId
				});
			} catch (e) {
				console.log(e);
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	addUser: (
		organizationId: number,
		user: IUser,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute
	): AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'ADD_USER_REQUEST' });
			const headers = await getCurrentAuthHeaders('json');

			const fetchUrl = `${url}org/${organizationId}/user/assign_user`;
			const { organization_id, ...userData } = user;
			const bodyContent = {
				user_data: userData
			};

			try {
				const addUserRequest = await fetch(fetchUrl, {
					method: 'post',
					headers,
					body: JSON.stringify(bodyContent)
				});

				const addUserResponse = await addUserRequest.json();

				if (addUserRequest.ok === false) {
					setErrorMessage(addUserResponse.errorMessage);
					setFormLoadingAttribute(false);
					return;
				}

				//@ts-ignore
				dispatch(modalActionCreators.dismissModal());

				dispatch({
					type: 'ADD_USER_RECEIVED',
					user: addUserResponse,
					organizationId
				});
			} catch (e) {
				console.log(e);
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	updateOrganizationUser: (
		updatedUser: IUser,
		currentOrganizationId: number,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute,
	): AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'UPDATE_ORGANIZATION_USER_REQUEST' });
			const {
				email,
				phone_number,
				user_type,
				organization_id,
				username
			} = updatedUser;
			const headers = await getCurrentAuthHeaders('json');
			const fetchUrl = `${url}org/${currentOrganizationId}/user/${username}`;

			const bodyContent = {
				email,
				phone_number,
				user_type,
				organization_id
			};

			try {
				const updateOrganizationUserRequest = await fetch(fetchUrl, {
					method: 'put',
					headers,
					body: JSON.stringify(bodyContent)
				});

				if (updateOrganizationUserRequest.ok === false) {
					const updateOrganizationUserResponse = await updateOrganizationUserRequest.json();
					setErrorMessage(updateOrganizationUserResponse.errorMessage);
					setFormLoadingAttribute(false);
					return;
				}

				//@ts-ignore
				dispatch(modalActionCreators.dismissModal());
				dispatch({
					type: 'UPDATE_ORGANIZATION_USER_RECEIVED',
					updatedUser,
					organizationId: currentOrganizationId
				});
			} catch (e) {
				console.error('Error in updateOrganizationUser action:', e);
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	deleteOrganization: (organizationId: number): AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'DELETE_ORGANIZATION_REQUEST' });
			const headers = await getCurrentAuthHeaders('json');
			const fetchUrl = `${url}org/${organizationId}`;
			try {
				const deleteOrganizationRequest = await fetch(fetchUrl, {
					method: 'delete',
					headers
				});

				//@ts-ignore
				dispatch(modalActionCreators.dismissModal());
				const parentOrganizationId = getState().organization.organizations[organizationId]
					.parent_organization_id;
				if (parentOrganizationId) {
					if (getState().auth.user.attributes['custom:user_type'] === 'mihin-admin') {
						sharedHistory.push(`/organization/${parentOrganizationId}`);
					} else {
						sharedHistory.push(`/organization`);
					}
				} else {
					sharedHistory.push('/organization-management');
				}

				dispatch({
					type: 'DELETE_ORGANIZATION_RECEIVED',
					organizationId
				});
			} catch (e) {
				console.log(e);
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},

	getSuborganizations: (organizationId: number): AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			const organizationState = getState().organization.organizationSuborganizationLoadingState[organizationId];
			if (organizationState === EUpdateState.Loading || organizationState === EUpdateState.Loaded) {
				return;
			}
			try {
				dispatch({
					type: 'GET_SUBORGANIZATIONS_REQUEST',
					organizationId
				});
				const headers = await getCurrentAuthHeaders('json');

				const getSuborganizationsRequest = await fetch(`${url}org/${organizationId}/suborganization`, {
					headers
				});

				const suborganizations = await getSuborganizationsRequest.json();

				dispatch({
					type: 'GET_SUBORGANIZATIONS_RECEIVED',
					organizations: suborganizations,
					organizationId
				});
			} catch (e) {
				console.log(e);
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	createUserAndOrganization: (
		formData: IOrganization & IUser,
		token: string,
		formActions: IFormActions
	): AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			formActions.setFormLoadingAttribute(true);
			const bodyContent: {
				user_data: IUser;
				org_data: IOrganization;
				token: string;
			} = {
				user_data: {
					username: formData.username,
					email: formData.email,
					user_type: 'org-admin',
					phone_number: formData.phone_number
				},
				org_data: {
					name: formData.name,
					descr: formData.descr,
					code: formData.code,
					address: formData.address,
					city: formData.city,
					state: formData.state,
					postal_code: formData.postal_code,
					contact_name: formData.contact_name,
					contact_email: formData.contact_email,
					contact_phone: formData.contact_phone
				},
				token
			};

			console.log('body 1', bodyContent);

			// const headers = new Headers({
			// 	'Content-Type': 'application/json',
			// });
			// const headers = await getCurrentAuthHeaders();
			const headers = new Headers({
				// Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json'
			});
			try {
				dispatch({ type: 'CREATE_USER_AND_ORGANIZATION_REQUEST' });
				console.log('body 2', bodyContent);

				const createUserAndOrganizationRequest = await fetch(`${url}marketplace/register_complete`, {
					method: 'post',
					headers,
					body: JSON.stringify(bodyContent)
				});

				if (createUserAndOrganizationRequest.ok === false) {
					const createUserAndOrganizationResponse = await createUserAndOrganizationRequest.json();
					formActions.setErrorMessage(createUserAndOrganizationResponse.errorMessage);
					formActions.setFormLoadingAttribute(false);
					return;
				}

				dispatch({ type: 'CREATE_USER_AND_ORGANIZATION_RESPONSE' });

				sharedHistory.push('/login', {
					successMessage: `Your account has been created. Please check your email for further instructions.`
				} as LoginLocationState);
			} catch (error) {
				console.log(error);
				formActions.setFormLoadingAttribute(false);
				formActions.setErrorMessage(errorMessages.general);
			}
		};
	},
	resendUserInvite: (organizationId: number, username: string): AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			try {
				dispatch({ type: 'RESEND_USER_INVITE_REQUEST', organizationId, username });

				const headers = await getCurrentAuthHeaders();
				const resendUserInviteRequest = await fetch(
					`${url}org/${organizationId}/user/${username}/resend_invite`,
					{
						method: 'post',
						headers
					}
				);

				console.log('resendUserInviteRequest', resendUserInviteRequest);

				dispatch({ type: 'RESEND_USER_INVITE_RESPONSE', organizationId, username });
			} catch (error) {
				console.log(error);

				//@ts-ignore
				dispatch(messageActionCreators.createMessage(error));
			}
		};
	},
	deleteUser: (
		organizationId: number,
		username: string,
		formActions: IFormActions
	): AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			try {
				dispatch({ type: 'DELETE_USER_REQUEST', organizationId, username });
				formActions.setFormLoadingAttribute(true);
				// Double-encode username to account for werkzeug's pre-decoding quirks
				const encodedUser = encodeURIComponent(encodeURIComponent(username));
				const headers = await getCurrentAuthHeaders();
				const deleteUserRequest = await fetch(`${url}org/${organizationId}/user/${encodedUser}/delete`, {
					method: 'post',
					headers
				});
				if (deleteUserRequest.ok === false) {
					const deleteUserResponse = await deleteUserRequest.json();
					formActions.setErrorMessage(deleteUserResponse.errorMessage);
					formActions.setFormLoadingAttribute(false);
					return;
				}
				//@ts-ignore
				dispatch(modalActionCreators.dismissModal());
				dispatch({ type: 'DELETE_USER_RESPONSE', organizationId, username });
				console.log('success');
			} catch (error) {
				console.log('error');
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(error));
			}
		};
	},
	removeUser: (
		organizationId: number,
		username: string,
		formActions: IFormActions
	): AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			try {
				dispatch({ type: 'REMOVE_USER_REQUEST', organizationId, username });
				formActions.setFormLoadingAttribute(true);
				// Double-encode username to account for werkzeug's pre-decoding quirks
				const encodedUser = encodeURIComponent(encodeURIComponent(username));
				const headers = await getCurrentAuthHeaders();
				const deleteUserRequest = await fetch(`${url}org/${organizationId}/user/${encodedUser}/remove`, {
					method: 'post',
					headers
				});
				if (deleteUserRequest.ok === false) {
					const deleteUserResponse = await deleteUserRequest.json();
					formActions.setErrorMessage(deleteUserResponse.errorMessage);
					formActions.setFormLoadingAttribute(false);
					return;
				}
				//@ts-ignore
				dispatch(modalActionCreators.dismissModal());
				dispatch({ type: 'REMOVE_USER_RESPONSE', organizationId, username });
				console.log('success');
			} catch (error) {
				console.log('error');
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(error));
			}
		};
	}
};
