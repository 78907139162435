import * as React from 'react';
import css from './simple-frame.module.scss';
import { InnerContainer } from 'components/Page';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { authActionCreators } from 'stores/actions/authActions';
import classes from 'classnames';
import { Link } from 'react-router-dom';

const simpleFrameActionCreators = {
	...authActionCreators
};

interface ISimpleFrameReduxProps {
	isTermsAccepted: boolean;
}

interface ISimpleFrameProps {
	className?: string;
	size?: 'large' | 'small';
	hideBackLink?: boolean;
}

class SimpleFrameBase extends React.Component<
	ISimpleFrameProps & ISimpleFrameReduxProps & ResolveThunks<typeof simpleFrameActionCreators>
	> {
	constructor(props) {
		super(props);
	}

	static defaultProps = {
		size: 'large',
		hideBackLink: false
	};

	render() {
		const { children, isTermsAccepted, signOut, className, size, hideBackLink } = this.props;

		const backLink = isTermsAccepted ? (
			<Link className={classes('backLink', css.backLink)} to='/'>
				Back to Main Site
			</Link>
		) : (
				<Link className={classes('backLink', css.backLink)} to='/agreement'>
					Back to Terms Agreement
				</Link>
			);

		return (
			<div className={css.termsFrame}>
				<div className={css[size]}>
					<InnerContainer className={classes(css.innerContainer, css.nav)}>
						{isTermsAccepted != null && !hideBackLink && backLink}
						{isTermsAccepted != null && (
							<button className={classes('buttonLink', css.logOutBtn)} onClick={signOut}>
								Log Out
							</button>
						)}
					</InnerContainer>
					<InnerContainer className={classes(css.innerContainer, className)}>
						<div className={css.termsWrap}>{children}</div>
					</InnerContainer>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: ApplicationState): ISimpleFrameReduxProps => ({
	isTermsAccepted: state.auth.isTermsAccepted
});

export const SimpleFrame = connect(
	mapStateToProps,
	simpleFrameActionCreators
)(SimpleFrameBase as React.ComponentClass<ISimpleFrameProps & ISimpleFrameReduxProps>) as any;
