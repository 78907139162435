import { AppThunkAction } from '../../index';
import personaBuilderActions from '../../actionInterfaces/personaBuilder/personaBuilderActionInterfaces';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { url } from 'settings';
import { IPatient } from 'common/interfaces/personaBuilder/IPatient';
import { IPatientFamilyMember } from 'common/interfaces/personaBuilder/IPatientFamilyMember';
import { sharedHistory } from 'sharedStore';
import { IPatientAddress } from 'common/interfaces/personaBuilder/IPatientAddress';
import { IPatientUseCase } from 'common/interfaces/personaBuilder/IPatientUseCase';
import { IHL7Valueset } from 'common/interfaces/personaBuilder/IHL7Valueset';
import { IPatientAllergyIntolerance } from 'common/interfaces/personaBuilder/IPatientAllergyIntolerance';
import { IPatientCoverage } from 'common/interfaces/personaBuilder/IPatientCoverage';
import { IPatientMedication } from 'common/interfaces/personaBuilder/IPatientMedication';
import { modalActionCreators } from 'stores/actions/modalActions';

export const PersonaBuilderActionCreators = {
	getPatientAddresses: (patientId: string | number): AppThunkAction<personaBuilderActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'GET_PATIENT_ADDRESSES_REQUEST' })
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const getPatientAddresses = await fetch(`${url}patients/${patientId}/addresses/`, {
					method: 'GET',
					headers
				});

				const response = await getPatientAddresses.json();

				if (getPatientAddresses.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_PATIENT_ADDRESSES_RECEIVED',
					addresses: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	},

	//patients API
	getAllPatients: (): AppThunkAction<personaBuilderActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const getPatients = await fetch(`${url}patients`, {
					method: 'GET',
					headers
				});

				const response = await getPatients.json();

				if (getPatients.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_ALL_PATIENTS_RECEIVED',
					patients: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	},

	getPatient: (
		id: string
	): AppThunkAction<personaBuilderActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const getPatient = await fetch(`${url}patients/${id}`, {
					method: 'GET',
					headers
				});

				const response = await getPatient.json();

				if (getPatient.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_PATIENT_RECEIVED',
					patient: { ...response, ...{ deceased_flag: response.deceased_flag ? 'true' : 'false' } }
				});

			} catch (e) {
				console.log(e);
			}
		};
	},

	createPatient: (
		patient: IPatient
	): AppThunkAction<personaBuilderActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const createPatient = await fetch(`${url}patients/create`, {
					method: 'POST',
					body: JSON.stringify({ ...patient, height: 0, weight: 0, persona_story_flag: true }),
					headers
				});

				const response = await createPatient.json();

				if (createPatient.ok === false) {
					return;
				}

				console.log("PATIENT CREATED: ", response);
				sharedHistory.push(`/persona-builder/patient-listing/${response.patient_id}`);

			} catch (e) {
				console.log(e);
			}
		};
	},

	updatePatient: (
		patient: IPatient
	): AppThunkAction<personaBuilderActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const updatePatient = await fetch(`${url}patients/${patient.id}`, {
					method: 'PUT',
					body: JSON.stringify(patient),
					headers
				});

				const response = await updatePatient.json();

				if (updatePatient.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_PATIENT_RECEIVED',
					patient: { ...response, ...{ deceased_flag: response.deceased_flag ? 'true' : 'false' } }
				});

			} catch (e) {
				console.log(e);
			}
		};
	},

	exportPatient: (
		patient: IPatient
	): AppThunkAction<any> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const exportPatient = await fetch(`${url}/patients/${patient.id}/export`, {
					method: 'POST',
					body: JSON.stringify({ ...patient, height: 0, weight: 0, persona_story_flag: true }),
					headers
				});

				if (exportPatient.ok === false) {
					return;
				}

				dispatch({
					type: 'EXPORT_PATIENT_RECEIVED',
					patient: patient
				});

				return dispatch(modalActionCreators.dismissModal());


			} catch (e) {
				console.log(e);
			}
		};
	},

	//address API
	createAddress: (
		address: IPatientAddress,
		patient_id: string | number
	): AppThunkAction<personaBuilderActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const createAddress = await fetch(`${url}patients/${patient_id}/addresses/create`, {
					method: 'POST',
					body: JSON.stringify(address),
					headers
				});

				const response = await createAddress.json();

				if (createAddress.ok === false) {
					throw Error;
				}

				return response.patient_address_id;

			} catch (e) {
				console.log(e);
			}
		};
	},

	updateAddress: (
		address: IPatientAddress,
		patient_id: string | number
	): AppThunkAction<personaBuilderActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const updateAddress = await fetch(`${url}patients/${patient_id}/addresses/${address.id}`, {
					method: 'PUT',
					body: JSON.stringify(address),
					headers
				});

				if (updateAddress.ok === false) {
					return;
				}


			} catch (e) {
				console.log(e);
			}
		};
	},

	deleteAddress: (
		address_id: string | number,
		patient_id: string | number
	): AppThunkAction<personaBuilderActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const deleteAddress = await fetch(`${url}patients/${patient_id}/addresses/${address_id}`, {
					method: 'DELETE',
					headers
				});

				if (deleteAddress.ok === false) {
					return;
				}


			} catch (e) {
				console.log(e);
			}
		};
	},

	//description API
	getPatientDescription: (patientId: string): AppThunkAction<personaBuilderActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'GET_PATIENT_DESCRIPTION_REQUEST' })
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const request = await fetch(`${url}patients/${patientId}/metadata`, {
					method: 'GET',
					headers
				});

				const response = await request.json();

				if (request.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_PATIENT_DESCRIPTION_RECEIVED',
					description: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	},
	createDescription: (
		description: any
	): AppThunkAction<personaBuilderActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				// pass use_cases into this param: use_case_list
				description.use_case_list = description.use_cases.map(u => { return +u.id });

				const createAddress = await fetch(`${url}patients/${description.patient_id}/metadata/create`, {
					method: 'POST',
					body: JSON.stringify(description),
					headers
				});

				const response = await createAddress.json();

				if (createAddress.ok === false) {
					return;
				}

				return response.id;

			} catch (e) {
				console.log(e);
			}
		};
	},

	updateDescription: (
		description: any
	): AppThunkAction<personaBuilderActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				// pass use_cases into this param: use_case_list
				description.use_case_list = description.use_cases.map(u => { return +u.id });

				const updateDescription = await fetch(`${url}patients/${description.patient_id}/metadata/${description.id}`, {
					method: 'PUT',
					body: JSON.stringify(description),
					headers
				});

				if (updateDescription.ok === false) {
					return;
				}

			} catch (e) {
				console.log(e);
			}
		};
	},
	getPatientDescriptionUseCases: (patientId: string | number): AppThunkAction<personaBuilderActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'GET_PATIENT_DESCRIPTION_REQUEST' })
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const request = await fetch(`${url}patients/${patientId}/use_cases`, {
					method: 'GET',
					headers
				});

				const response = await request.json();

				if (request.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_PATIENT_DESCRIPTION_USE_CASES_RECEIVED',
					useCases: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	},
	setPatientDescriptionUseCases: (useCases: IPatientUseCase[], patientId: string | number): AppThunkAction<personaBuilderActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'GET_PATIENT_DESCRIPTION_REQUEST' })
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const request = await fetch(`${url}patients/${patientId}/description/use_cases`, {
					method: 'POST',
					body: JSON.stringify(useCases),
					headers
				});

				const response = await request.json();

				if (request.ok === false) {
					return;
				}

				return dispatch({
					type: 'SET_PATIENT_DESCRIPTION_USE_CASES_RECEIVED'
				});

			} catch (e) {
				console.log(e);
			}
		};
	},


	//helpers
	populateDropdowns: (): AppThunkAction<personaBuilderActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				Promise.all([
					fetch(`${url}patients/valuesets/races`, { method: 'GET', headers }),
					fetch(`${url}patients/valuesets/marital_statuses`, { method: 'GET', headers }),
					fetch(`${url}patients/valuesets/languages`, { method: 'GET', headers }),
					fetch(`${url}patients/valuesets/genders`, { method: 'GET', headers }),
					fetch(`${url}patients/valuesets/ethnicities`, { method: 'GET', headers }),
					fetch(`${url}patients/valuesets/patient_address_uses`, { method: 'GET', headers }),
					fetch(`${url}patients/valuesets/patient_address_types`, { method: 'GET', headers }),
					fetch(`${url}patients/valuesets/persona_use_cases`, { method: 'GET', headers }),
					fetch(`${url}patients/valuesets/allergy_categories`, { method: 'GET', headers }),
					fetch(`${url}patients/valuesets/allergy_severities`, { method: 'GET', headers }),
					fetch(`${url}patients/valuesets/allergy_criticalities`, { method: 'GET', headers }),
					fetch(`${url}patients/valuesets/allergy_exposure_routes`, { method: 'GET', headers }),
					fetch(`${url}patients/valuesets/relationships`, { method: 'GET', headers }),
					fetch(`${url}patients/valuesets/coverage_classes`, { method: 'GET', headers }),
					fetch(`${url}patients/valuesets/coverage_types`, { method: 'GET', headers }),
					fetch(`${url}patients/valuesets/coverage_statuses`, { method: 'GET', headers }),
					fetch(`${url}patients/valuesets/coverage_dependent_relationships`, { method: 'GET', headers }),
					fetch(`${url}organizations`, { method: 'GET', headers }),
					fetch(`${url}organizations/insurance_organizations`, { method: 'GET', headers }),
					fetch(`${url}medications`, { method: 'GET', headers }),
					fetch(`${url}patients/valuesets/prescription_statuses`, { method: 'GET', headers }),
					fetch(`${url}providers`, { method: 'GET', headers }),
				]).then(async ([
					races,
					marital_statuses,
					languages,
					genders,
					ethnicities,
					address_uses,
					address_types,
					use_cases,
					allergy_categories,
					allergy_severities,
					allergy_criticalities,
					allergy_exposure_routes,
					relationships,
					coverage_classes,
					coverage_types,
					coverage_statuses,
					coverage_dependent_relationships,
					organizations,
					insurance_organizations,
					medications,
					prescription_statuses,
					providers]) => {
					const dropdowns = {
						races: await races.json(),
						marital_statuses: await marital_statuses.json(),
						languages: await languages.json(),
						genders: await genders.json(),
						ethnicities: await ethnicities.json(),
						address_uses: await address_uses.json(),
						address_types: await address_types.json(),
						use_cases: await use_cases.json(),
						allergy_categories: await allergy_categories.json(),
						allergy_severities: await allergy_severities.json(),
						allergy_criticalities: await allergy_criticalities.json(),
						allergy_exposure_routes: await allergy_exposure_routes.json(),
						relationships: await relationships.json(),
						coverage_classes: await coverage_classes.json(),
						coverage_types: await coverage_types.json(),
						coverage_statuses: await coverage_statuses.json(),
						coverage_dependent_relationships: await coverage_dependent_relationships.json(),
						organizations: await organizations.json(),
						insurance_organizations: await insurance_organizations.json(),
						medications: await medications.json(),
						prescription_statuses: await prescription_statuses.json(),
						providers: await providers.json()
					};

					return dispatch({
						type: 'GET_PATIENT_DROPDOWNS_RECEIVED',
						dropdowns: dropdowns
					});

				});


			} catch (e) {
				console.log(e);
			}
		};
	},

	setSaveDisabled: (saveDisabled: boolean) => {
		return async (dispatch, getState) => {
			return dispatch({
				type: 'SET_SAVE_DISABLED',
				saveDisabled: saveDisabled
			});
		};
	},
	//helpers
	getDescriptionUseCases: (): AppThunkAction<personaBuilderActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const updateAddress = await fetch(`${url}patients/descriptions/use_cases`, {
					method: 'GET',
					headers
				});

				const response = await updateAddress.json();

				if (updateAddress.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_DESCRIPTION_USE_CASES_RECEIVED',
					useCases: response
				});



			} catch (e) {
				console.log(e);
			}
		};
	},

	getAllHL7Valuesets: (): AppThunkAction<personaBuilderActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				Promise.all([
					fetch(`${url}incomplete_valuesets/allergy_reactions`, { method: 'GET', headers }),
					fetch(`${url}incomplete_valuesets/allergy_substances`, { method: 'GET', headers }),
					fetch(`${url}incomplete_valuesets/body_sites`, { method: 'GET', headers }),
					fetch(`${url}incomplete_valuesets/diagnoses`, { method: 'GET', headers }),
				]).then(async ([allergy_reactions, allergy_substances, body_sites, diagnosis]) => {
					const hl7Valuesets = {
						allergy_reactions: await allergy_reactions.json(),
						allergy_substances: await allergy_substances.json(),
						body_sites: await body_sites.json(),
						diagnosis: await diagnosis.json()
					};

					return dispatch({
						type: 'GET_HL7VALUESETS_RECEIVED',
						hl7Valuesets: hl7Valuesets
					});

				});
			} catch (e) {
				console.log(e);
			}
		};
	},
	createHL7Valueset: (values: IHL7Valueset, apiEndpoint: string): AppThunkAction<personaBuilderActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const createValueset = await fetch(`${url}incomplete_valuesets/${apiEndpoint}/create`, {
					method: 'POST',
					body: JSON.stringify(values),
					headers
				});

				const response = await createValueset.json();

				if (createValueset.ok === false) {
					throw Error;
				}

				return response.id;

			} catch (e) {
				console.log(e);
			}
		};
	},
	updateHL7Valueset: (values: IHL7Valueset, apiEndpoint: string): AppThunkAction<personaBuilderActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const updateValueset = await fetch(`${url}incomplete_valuesets/${apiEndpoint}/${values.id}`, {
					method: 'PUT',
					body: JSON.stringify(values),
					headers
				});

				if (updateValueset.ok === false) {
					return;
				}

			} catch (e) {
				console.log(e);
			}
		};
	},
	deleteHL7Valueset: (id: number, apiEndpoint: string): AppThunkAction<personaBuilderActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const deleteValueset = await fetch(`${url}incomplete_valuesets/${apiEndpoint}/${id}`, {
					method: 'DELETE',
					headers
				});

				if (deleteValueset.ok === false) {
					return;
				}

			} catch (e) {
				console.log(e);
			}
		};
	},

	getPatientAllergyIntolerances: (patientId: string): AppThunkAction<personaBuilderActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'GET_PATIENT_ALLERGY_INTOLERANCES_REQUEST' })
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const getAllergyIntolerances = await fetch(`${url}patients/${patientId}/allergy_intolerances`, {
					method: 'GET',
					headers
				});

				const response = await getAllergyIntolerances.json();

				if (getAllergyIntolerances.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_PATIENT_ALLERGY_INTOLERANCES_RECEIVED',
					allergyIntolerances: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	},
	createPatientAllergyIntolerance: (
		allergyIntolerance: IPatientAllergyIntolerance,
		patientId: number
	): AppThunkAction<personaBuilderActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const createAllergyIntolerance = await fetch(`${url}patients/${patientId}/allergy_intolerances/create`, {
					method: 'POST',
					body: JSON.stringify(allergyIntolerance),
					headers
				});

				const response = await createAllergyIntolerance.json();

				if (createAllergyIntolerance.ok === false) {
					throw Error;
				}

				return response.id;

			} catch (e) {
				console.log(e);
			}
		};
	},
	updatePatientAllergyIntolerance: (
		allergyIntolerance: IPatientAllergyIntolerance,
		patientId: number
	): AppThunkAction<personaBuilderActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const updateAllergyIntolerance = await fetch(`${url}patients/${patientId}/allergy_intolerances/${allergyIntolerance.id}`, {
					method: 'PUT',
					body: JSON.stringify(allergyIntolerance),
					headers
				});

				if (updateAllergyIntolerance.ok === false) {
					throw Error;
				}

			} catch (e) {
				console.log(e);
			}
		};
	},
	deletePatientAllergyIntolerance: (
		allergyIntolerance: IPatientAllergyIntolerance,
		patientId: number
	): AppThunkAction<personaBuilderActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const deleteAllergyIntolerance = await fetch(`${url}patients/${patientId}/allergy_intolerances/${allergyIntolerance.id}`, {
					method: 'DELETE',
					headers
				});

				if (deleteAllergyIntolerance.ok === false) {
					return;
				}

			} catch (e) {
				console.log(e);
			}
		};
	},

	getAllFamilyMembers: (): AppThunkAction<personaBuilderActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'GET_ALL_FAMILY_REQUEST' })
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const request = await fetch(`${url}patients/all_relatives`, {
					method: 'GET',
					headers
				});

				const response = await request.json();

				if (request.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_ALL_FAMILY_RECEIVED',
					familyMembers: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	},
	getPatientFamilyMembers: (patientId: string | number): AppThunkAction<personaBuilderActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'GET_PATIENT_FAMILY_REQUEST' })
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const request = await fetch(`${url}patients/${patientId}/relatives`, {
					method: 'GET',
					headers
				});

				const response = await request.json();

				if (request.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_PATIENT_FAMILY_RECEIVED',
					familyMembers: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	},
	createPatientFamilyMember: (familyMember: IPatientFamilyMember, patient: IPatient): AppThunkAction<personaBuilderActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				if (patient.id === null || patient.id === 0) {
					// CREATE FAMILY PATIENT DATA
					const createFamilyPatient = await fetch(`${url}patients/create`, {
						method: 'POST',
						body: JSON.stringify({ ...patient, height: 0, weight: 0, persona_story_flag: false, deceased_flag: false }),
						headers
					});

					const familyPatient = await createFamilyPatient.json();
					familyMember.patient_family_member_id = +familyPatient.patient_id;

					if (createFamilyPatient.ok === false) {
						console.log(createFamilyPatient);
						throw Error;
						return;
					}
				}

				// CREATE FAMILY MEMBER DATA
				familyMember.patient_id = +familyMember.patient_id;
				familyMember.patient_relationship_id = +familyMember.patient_relationship_id;

				const createFamilyMember = await fetch(`${url}patients/${familyMember.patient_id}/relatives/create`, {
					method: 'POST',
					body: JSON.stringify(familyMember),
					headers
				});

				const response = await createFamilyMember.json();

				if (createFamilyMember.ok === false) {
					throw Error;
				}

				return response.patient_relative_id;

			} catch (e) {
				console.log(e);
			}
		};
	},
	updatePatientFamilyMember: (familyMember: IPatientFamilyMember, patient: IPatient): AppThunkAction<personaBuilderActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				// UPDATE FAMILY PATIENT DATA IF AVAILABLE TO UPDATE
				if (patient !== null) {
					const updateFamilyPatient = await fetch(`${url}patients/${familyMember.patient_family_member_id}`, {
						method: 'PUT',
						body: JSON.stringify(patient),
						headers
					});

					if (updateFamilyPatient.ok === false) {
						return;
					}
				}

				// UPDATE FAMILY MEMBER DATA
				const updateFamilyMember = await fetch(`${url}patients/${familyMember.patient_id}/relatives/${familyMember.id}`, {
					method: 'PUT',
					body: JSON.stringify(familyMember),
					headers
				});

				if (updateFamilyMember.ok === false) {
					throw Error;
				}

			} catch (e) {
				console.log(e);
			}
		};
	},
	deletePatientFamilyMember: (familyMember: IPatientFamilyMember): AppThunkAction<personaBuilderActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const deleteFamilyMember = await fetch(`${url}patients/${familyMember.patient_id}/relatives/${familyMember.id}`, {
					method: 'DELETE',
					body: JSON.stringify(familyMember),
					headers
				});

				if (deleteFamilyMember.ok === false) {
					throw Error;
				}

			} catch (e) {
				console.log(e);
			}
		};
	},

	getPatientCoverages: (patientId: string | number): AppThunkAction<personaBuilderActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'GET_PATIENT_COVERAGES_REQUEST' })
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const request = await fetch(`${url}patients/${patientId}/coverages`, {
					method: 'GET',
					headers
				});

				const response = await request.json();

				if (request.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_PATIENT_COVERAGES_RECEIVED',
					coverages: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	},
	createPatientCoverage: (coverage: IPatientCoverage, patientId: string | number): AppThunkAction<personaBuilderActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const createCoverage = await fetch(`${url}patients/${patientId}/coverages/create`, {
					method: 'POST',
					body: JSON.stringify({ ...coverage, coverage_number: null }),
					headers
				});

				const response = await createCoverage.json();

				if (createCoverage.ok === false) {
					throw Error;
				}

				return response.id;

			} catch (e) {
				console.log(e);
			}
		};
	},
	updatePatientCoverage: (coverage: IPatientCoverage, patientId: string | number): AppThunkAction<personaBuilderActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const updateCoverage = await fetch(`${url}patients/${patientId}/coverages/${coverage.id}`, {
					method: 'PUT',
					body: JSON.stringify(coverage),
					headers
				});

				if (updateCoverage.ok === false) {
					throw Error;
				}

			} catch (e) {
				console.log(e);
			}
		};
	},
	deletePatientCoverage: (coverage: IPatientCoverage, patientId: string | number): AppThunkAction<personaBuilderActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const deleteCoverage = await fetch(`${url}patients/${patientId}/coverages/${coverage.id}`, {
					method: 'DELETE',
					headers
				});

				if (deleteCoverage.ok === false) {
					return;
				}

			} catch (e) {
				console.log(e);
			}
		};
	},

	getPatientMedications: (patientId: string | number): AppThunkAction<personaBuilderActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'GET_PATIENT_MEDICATIONS_REQUEST' })
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const request = await fetch(`${url}patients/${patientId}/prescription_medications`, {
					method: 'GET',
					headers
				});

				const response = await request.json();

				if (request.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_PATIENT_MEDICATIONS_RECEIVED',
					medications: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	},
	createPatientMedication: (medication: IPatientMedication, patientId: string | number): AppThunkAction<personaBuilderActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const createMedication = await fetch(`${url}patients/${patientId}/prescription_medications/create`, {
					method: 'POST',
					body: JSON.stringify(medication),
					headers
				});

				const response = await createMedication.json();

				if (createMedication.ok === false) {
					throw Error;
				}

				return response.id;

			} catch (e) {
				console.log(e);
			}
		};
	},
	updatePatientMedication: (medication: IPatientMedication, patientId: string | number): AppThunkAction<personaBuilderActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const updateMedication = await fetch(`${url}patients/${patientId}/prescription_medications/${medication.id}`, {
					method: 'PUT',
					body: JSON.stringify(medication),
					headers
				});

				const response = await updateMedication.json();

				if (updateMedication.ok === false) {
					throw Error;
				}

			} catch (e) {
				console.log(e);
			}
		};
	},
	deletePatientMedication: (medication: IPatientMedication, patientId: string | number): AppThunkAction<personaBuilderActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const deleteMedication = await fetch(`${url}patients/${patientId}/prescription_medications/${medication.id}`, {
					method: 'DELETE',
					headers
				});

				if (deleteMedication.ok === false) {
					return;
				}

			} catch (e) {
				console.log(e);
			}
		};
	},

	getPatientConditions: (patientId: string | number): AppThunkAction<personaBuilderActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'GET_PATIENT_CONDITIONS_REQUEST' })
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const request = await fetch(`${url}patients/${patientId}/conditions`, {
					method: 'GET',
					headers
				});

				const response = await request.json();

				if (request.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_PATIENT_CONDITIONS_RECEIVED',
					conditions: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	}
};
