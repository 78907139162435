import * as React from 'react';
import { TableColumn } from 'components/Table/TableColumn';
import { ActionIcon } from 'components/ActionIcon';
import { ReactComponent as IconX } from 'assets/icons/icon-x.svg';
import css from './table-column-delete.module.scss';
import classes from 'classnames';
import { IModal } from 'common/interfaces';
import { connect, ResolveThunks } from 'react-redux';
import { modalActionCreators } from 'stores/actions/modalActions';

interface ITableColumnDelete {
	onClick?: any;
	onClickModal?: IModal;
	actionAriaLabel: string;
	disabled?: boolean;
	isDark?: boolean;
}

const TableColumnDeleteActionCreators = {
	...modalActionCreators
};

const TableColumnDeleteBase: React.FC<
	ITableColumnDelete & ResolveThunks<typeof TableColumnDeleteActionCreators>
> = props => {
	const {
		onClick,
		onClickModal,
		actionAriaLabel,
		disabled,
		createModal,
		isDark
	} = props;
	console.log('disabled', disabled);
	return (
		<TableColumn className={css.tableColumnDelete}>
			<ActionIcon
				className={classes(css.deleteIcon, {
					[css.disabled]: disabled
				})}
				onClick={event => {
					if (onClick) {
						onClick();
					}
					if (onClickModal) {
						createModal(onClickModal, event);
					}
				}}
				ariaLabel={actionAriaLabel}
				type='button'
				disabled={disabled}
				isDark={isDark}
			>
				<IconX />
			</ActionIcon>
		</TableColumn>
	);
};

export const TableColumnDelete = connect(
	null,
	TableColumnDeleteActionCreators
)(TableColumnDeleteBase);
