import * as React from 'react';
import { Link } from 'react-router-dom';
import whatIsAFhirRing from 'assets/faq/What-is-a-FHIR-Ring.png';
import persona from 'assets/faq/Personas.png';
import patientGen from 'assets/faq/Patient-Gen.png';
import smartOnFhirApp from 'assets/faq/Smart-on-FHIR-App.png';
import eventPack from 'assets/faq/Event-Pack.png';

interface IFaqSection {
	title: string;
	id: string;
	questions: {
		question: string;
		answer: React.FC | string;
	}[];
}

type IFaq = IFaqSection[];

export const faqQuestions: IFaq = [
	{
		title: 'Terms and Definitions',
		id: 'termsAndDefinitions',
		questions: [
			{
				question: 'What is Interoperability Land™ (IOL)?',
				answer:
					'Interoperability Land is a cloud-hosted digital collaboration platform designed to power the future of multi-organization development, integration, acceptance, and testing of innovative technologies and open standards. Organizations can learn, build and test healthcare applications and services with no risk of PHI disclosure using highly realistic, clinically relevant, synthetic patient data.'
			},
			{
				question: 'What is FHIR®?',
				answer: () => {
					return (
						<p>
							<a href='https://www.hl7.org/fhir/' target='_BLANK'>
								Fast Healthcare Interoperability Resources (FHIR)
							</a>{' '}
							is an international platform specification developed by{' '}
							<a href='https://www.hl7.org/' target='_BLANK'>
								HL7
							</a>{' '}
							that defines how healthcare information is exchanged, how terminologies are used, how
							information is secured, when information is exchanged, what information is exchanged and
							more. Healthcare data is represented as FHIR “Resources.” Resources are small logically
							discrete unties of data with defined behavior and meaning. Examples include: patient,
							encounter, medication, allergy.
						</p>
					);
				}
			},
			{
				question: 'What is an InterOp PIT?',
				answer:
					'An InterOp Pilot Interoperability Testbed (PIT) represents a single “edge” organization populated with synthetic resources that emulates a real-world entity. Examples include: Electronic Health Record (EHR), Payer, Health System (Hospital).'
			},
			{
				question: 'What is an InterOp Ring?',
				answer: () => {
					return (
						<>
							<p>
								A network of InterOp PITs connected to represent simulated healthcare organizations that
								may participate in a patient’s care plan. Organizations are represented as API’s
								populated with relevant synthetic patient data that is choreographed across each server
								and used to power development and testing of interoperable systems and applications. See
								PatientGen and Persona definitions below for more info on synthetic patient data.
							</p>
							<p>
								<img
									src={whatIsAFhirRing}
									alt='Graphic illustrating persona and PatientGen data being added to a ring and being accessed from an application'
								/>
							</p>
						</>
					);
				}
			},
			{
				question: 'What is a SMART® on FHIR® app?',
				answer: () => {
					return (
						<>
							<p>
								<a
									href='https://smarthealthit.org/an-app-platform-for-healthcare/about/'
									target='_BLANK'
								>
									SMART&reg; on FHIR&reg;
								</a>{' '}
								is a set of open specifications to integrate apps with healthcare systems using an EHR
								system or data warehouse. These apps can be used by patients, physicians, healthcare
								practitioners, etc. to help improve patient care.
							</p>
							<p>
								<img
									src={smartOnFhirApp}
									alt='Graphic illustrating how SMART on FHIR apps can connect to FHIR PITs'
								/>
							</p>
						</>
					);
				}
			},
			{
				question: 'What is an Event Pack?',
				answer: () => {
					return (
						<>
							<p>
								An event pack is multiple FHIR Rings for use at an event such as a Connectathon/Hackathon
								that comes with an eCopy of our FHIR Training Guide as well as a day of virtual support
								of the platform during your event.
							</p>
							<p>
								<img src={eventPack} alt='Graphic illustrating how IOL can create multiple Sandboxes' />
							</p>
						</>
					);
				}
			},
			{
				question: 'What is a Sandbox in IOL?',
				answer:
					'A sandbox is a test platform that enables developers to test code in a safe, PHI-free cloud-based environment. A sandbox is comprised of either an InterOp PIT or an InterOp Ring.'
			},
			{
				question: 'What is PatientGen?',
				answer: () => {
					return (
						<>
							<p>
								PatientGen is a FHIR-compatible “safe” test data generator that produces realistic
								patient histories involving clinically relevant patient encounters at scale. The
								generator models a simulated healthcare network of providers, practices and hospitals
								with a large population of patients who experience changes in their health status and
								mortality risks for many important medical conditions and procedures.
							</p>
							<p>
								<img src={patientGen} alt='Graphic illustrating PatientGen applications' />
							</p>
						</>
					);
				}
			},
			{
				question: 'What are Personas?',
				answer: () => {
					return (
						<>
							<p>
								A persona is a realistic and complete synthetic representation of a person. Personas have
								unique attitudes, conditions, and environments that affect how they interact with each
								other and the healthcare system. Personas are available in the persona library to be
								added to a sandbox. To request a custom persona please reach out to our persona team at{' '}
								<a href='mailto:help@mihin.org' target='_BLANK'>
									help@mihin.org
								</a>
								.
							</p>
							<p>
								<img src={persona} alt='Persona narrative and persona data' />
							</p>
						</>
					);
				}
			}
		]
	},
	{
		title: 'Product Offerings',
		id: 'productOffering',
		questions: [
			{
				question: 'How can IOL staff help host an event?',
				answer: () => {
					return (
						<p>
							We offer Connectathons as a service. This can include tailoring an event to your specific
							needs or offering a turnkey event package which includes our event platform and onsite
							services during the event. The goal of a Connectathon is to promote cross-functional team
							building and accelerate learning and adoption of standards-based technology and use cases.
							This can be done as a private organization or publicly-hosted event. For more information
							and pricing on these services, please contact{' '}
							<a href='mailto:help@mihin.org'>help@mihin.org</a>.
						</p>
					);
				}
			}
		]
	},
	{
		title: 'Data in IOL',
		id: 'dataInIol',
		questions: [
			{
				question: 'What FHIR® versions are supported by IOL?',
				answer: 'DSTU2, STU3, and R4 are supported by IOL at this time.'
			},
			{
				question: 'What data is expected by default in a sandbox?',
				answer:
					'By default, each sandbox is filled with PatientGen synthetic data. This data is mapped across Rings simulating a patient’s real-world travel through healthcare facilities, payers and pharmacies. Each PIT contains resources representing real world vendors. In cases where a vendor does not exist with a real-world example, we have selected resources that are appropriate to the vendor type. For example, a payer will have at minimum claims, claims response, EOB and coverage resources. A pharmacy PIT will have at minimum medication and medication dispense resources.'
			},
			{
				question: 'What personas can I add into a sandbox?',
				answer: () => {
					return (
						<p>
							All the personas are located in the <Link to='/persona'>Persona Library</Link>. From this
							page, users can choose which persona they would like to add and where to add them to.
						</p>
					);
				}
			},
			{
				question: 'Can I load my own data into a sandbox?',
				answer: () => {
					return (
						<p>
							Interoperability Institute provides a suite of personas and synthetic patient data via
							PatientGen. If you would like to us to customize synthetic data for your use, please reach
							out to{' '}
							<a href='mailto:help@mihin.org' target='_BLANK'>
								help@mihin.org
							</a>
							.
						</p>
					);
				}
			},
			{
				question: 'Can I use non-synthetic data?',
				answer: () => {
					return (
						<p>
							PHI is explicitly prohibited in IOL. Only synthetic data can be used in IOL per our Terms
							of Service agreement. If your organization would like to discuss personalized synthetic
							data or personas for IOL, please reach out to{' '}
							<a href='mailto:help@mihin.org'>help@mihin.org</a>.
						</p>
					);
				}
			}
		]
	},
	{
		title: 'Account Access',
		id: 'accounts',
		questions: [
			{
				question: 'How do I create an account?',
				answer:
					'To create an IOL account you must be an organization admin. This will be provided to the designated member(s) of your organization once a plan is purchased.'
			},
			{
				question: 'How do I provision other accounts within my organization?',
				answer: () => {
					return (
						<p>
							Users with organization admin privileges, within your organization, can create users and
							assign privileges. For more information on provisioning accounts, please reach out to{' '}
							<a href='mailto:help@mihin.org'>help@mihin.org</a>.
						</p>
					);
				}
			},
			{
				question: 'How do I change an email address on my account?',
				answer: () => {
					return (
						<p>
							Please reach out to <a href='mailto:help@mihin.org'>help@mihin.org</a> for assistance.
						</p>
					);
				}
			},
			{
				question: 'Where can I reset my password?',
				answer: () => {
					return (
						<p>
							In the <Link to='/settings'>Account Settings page</Link>, there is an option to change your
							password. If you have forgotten your password, there is an option available at the sign in
							page. A password reset link will be sent to your email. If you are unable to reset your
							password using the steps above, please reach out to your organization admin for assistance.
						</p>
					);
				}
			},
			{
				question: 'How many users can I add?',
				answer: 'Organization admins can add up to 25 organization users at a time per account.'
			}
		]
	},
	{
		title: 'Support',
		id: 'support',
		questions: [
			{
				question: 'How do I request help?',
				answer: () => {
					return (
						<p>
							We offer a help desk service to address your needs. Please send a detailed email outlining
							your question or concern to <a href='mailto:help@mihin.org'>help@mihin.org</a>.
						</p>
					);
				}
			},
			{
				question: 'What is my expected response time for a service ticket?',
				answer:
					'A response time depends on the nature of your issue. Upon submitting a ticket, a help desk agent or IOL team member will reach out with a turnaround time.'
			},
			{
				question: 'What do I do if my service is interrupted?',
				answer: () => {
					return (
						<p>
							If you feel your service is interrupted, please submit a detailed email to{' '}
							<a href='mailto:help@mihin.org'>help@mihin.org</a>.
						</p>
					);
				}
			},
			{
				question: 'How do I provide feedback?',
				answer: () => {
					return (
						<p>
							We welcome all feedback! Please email us at{' '}
							<a href='mailto:help@mihin.org'>help@mihin.org</a>.
						</p>
					);
				}
			}
		]
	}
];
