import * as React from 'react';
import { FormOrganization } from 'components/Forms/FormOrganization';
import { IModal } from 'common/interfaces/IModal';
import { organizationActionCreators } from 'stores/actions/organizationActions';

export const addSubOrganizationModal = (
	organizationId: number,
	formAction
): IModal => ({
	primaryButtonLabel: 'Create Suborganization',
	description: 'Fill out the details below to create a new suborganization:',
	content: props => (
		<FormOrganization parentOrganizationId={organizationId} {...props} />
	),
	title: 'Add New Suborganization',
	formAction
});
