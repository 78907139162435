import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import { MedicationActionCreators } from 'stores/actions/personaBuilder/medicationActions';
import { ApplicationState } from 'stores';
import { Button, Grid } from '@material-ui/core';
import { ChevronLeft, ChevronRight, Delete } from '@material-ui/icons';
import { MuiButton } from 'components/MaterialUI';
import classes from 'classnames';
import css from './medication-details-tab.module.scss';
import { FormMedicationDetails } from 'components/Forms/PersonaBuilder/FormPersonaBuilderMedication/FormMedicationDetails';

interface IMedicationDetailsRouteProps {
	id?: string
}

interface IMedicationDetailsTabReduxProps {
	currentMedication: any;
}

export const MedicationDetailsTabBase: React.FC<RouteComponentProps<IMedicationDetailsRouteProps> &
IMedicationDetailsTabReduxProps &
ResolveThunks<typeof MedicationActionCreators>> = props => {
  
	let _submitForm = null;
	const history = useHistory();
	const prevLink = `/persona-builder/medication-listing`;
	
	const currentMedication = props.currentMedication ? props.currentMedication : {
		id: 0,
		proprietary_name: '',
		non_proprietary_name: '',
		substance_name: '',
		medication_ndc_code: '',
		medication_snomed_code: '',
		strength: '',
		ingredient_unit: '',
		medication_form_id: null
	};

	const _bindSubmitForm = (submitForm) => {
		_submitForm = submitForm;
	}

	const handleSubmit = () => {
		if (_submitForm) {
			_submitForm();
		}
	}

	const isNew = props.match.params.id === 'create';

	const saveMedication = (values) => {
		if (isNew) {
			props.createMedication(values).then(response => {
				props.getAllMedications();
				history.push(`/persona-builder/medication-listing/`);
			}).catch((e)=>{console.log(e)});
		} else {
			props.updateMedication(values).then(() => {
				props.getMedication(values.id);
			}).catch((e)=>{console.log(e)});
		}
	}
	const deleteMedication = () => {
		props.deleteMedication(currentMedication.id).then(()=>{
			props.getAllMedications();
			history.push(`/persona-builder/medication-listing/`);
		}).catch((e)=>{console.log(e)});
	}
  
  return (
    <>
      <Grid container>
        <Grid item xs={6} className={css.formHeader}>
          <h4>Medication</h4>
        </Grid>
        <Grid item xs={6} className={css.formDeleteColumn}>
          {!isNew && 
            <Button onClick={deleteMedication}>
              Delete <Delete />
            </Button>
          }
        </Grid>
      </Grid>
      <FormMedicationDetails
        bindSubmitForm={_bindSubmitForm}
        defaultFormContent={!isNew && currentMedication} 
        formAction={saveMedication}
      />
			<Grid container className={css.actionsContainer}>
				<Grid item xs={12} md={6}>
					<Button onClick={() => history.push(`${prevLink}`)}>
						<ChevronLeft className={classes([css.leftIcon], [css.iconSmall])}/>
						Back
					</Button>
				</Grid>
				<Grid item xs={12} md={6} style={{textAlign: 'right'}}>
					<MuiButton variant="contained" className={css.button} onClick={handleSubmit}>
						Save
					</MuiButton>
				</Grid>
			</Grid>
    </>
  )
}

const mapStateToProps = (state: ApplicationState): IMedicationDetailsTabReduxProps => {
	return {
		currentMedication: state.personaBuilderMedication.currentMedication
	};
};

export const MedicationDetailsTab = connect(
	mapStateToProps,
	MedicationActionCreators
)(MedicationDetailsTabBase);