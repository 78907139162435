import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { rofActionCreators } from 'stores/actions/rofActions';
import { Code } from 'components/Code';
import { IRof } from 'common/interfaces/IRof';
import css from './capabilities-statement-tab.module.scss';
import { Button } from 'components/Button';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Message } from 'components/Message';
import { EUpdateState } from 'stores/shared/ENums';

const capabilitiesStatementTabActionCreators = {
	...rofActionCreators
};

interface ICapabilitiesStatementTabReduxProps {
	capabilitiesStatement: any;
	capabilitiesStatementLoadingState: EUpdateState;
	currentRof: IRof;
	currentRofId: number;
	currentPitId: number;
	currentRofDetailsLoadingState: EUpdateState;
}

interface ICapabilitiesStatementTabProps {
	match: {
		params: {
			rofId: string;
			pitId: string;
		};
	};
}

class CapabilitiesStatementTabBase extends React.PureComponent<
	ResolveThunks<typeof capabilitiesStatementTabActionCreators> &
		ICapabilitiesStatementTabReduxProps &
		ICapabilitiesStatementTabProps
> {
	UNSAFE_componentWillMount() {
		const { currentRofId, currentPitId, currentRofDetailsLoadingState } = this.props;
		console.log(`currentRofId: ${currentRofId}, currentPitId: ${currentPitId}`);
		if (currentRofDetailsLoadingState === EUpdateState.Loaded) {
			this.props.getCapabilitiesStatement(currentRofId, currentPitId);
		}
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		console.log(
			`newProps.currentRofDetailsLoadingState !== this.props.currentRofDetailsLoadingState: ${newProps.currentRofDetailsLoadingState} !== ${this.props.currentRofDetailsLoadingState}`,
			newProps.currentRofDetailsLoadingState !== this.props.currentRofDetailsLoadingState
		);
		if (newProps.currentRofDetailsLoadingState !== this.props.currentRofDetailsLoadingState) {
			const { currentRofId, currentPitId, currentRofDetailsLoadingState } = this.props;
			if (newProps.currentRofDetailsLoadingState === EUpdateState.Loaded) {
				this.props.getCapabilitiesStatement(currentRofId, currentPitId);
			}
		}
	}

	render() {
		const { capabilitiesStatement, currentRof, setCurrentRofState, capabilitiesStatementLoadingState } = this.props;
		console.log('currentRof', currentRof);

		return (
			<>
				{currentRof.stack_status === 'UPDATE_IN_PROGRESS' && !capabilitiesStatement ? (
					<LoadingSpinner className={css.loadingSpinner} />
				) : (
					<>
						{capabilitiesStatement ? (
							<>
								<h3>Result Body JSON resource</h3>
								<Code code={JSON.stringify(capabilitiesStatement, null, 2)} />
							</>
						) : (
							<LoadingSpinner className={css.loadingSpinner} />
						)}
					</>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: ApplicationState): ICapabilitiesStatementTabReduxProps => {
	return {
		capabilitiesStatement: state.rof.allRofCapabilitiesStatement[state.rof.currentRofId]
			? state.rof.allRofCapabilitiesStatement[state.rof.currentRofId][state.rof.currentPitId]
			: null,
		capabilitiesStatementLoadingState: state.rof.allRofCapabilitiesStatementLoadingState[state.rof.currentRofId]
			? state.rof.allRofCapabilitiesStatementLoadingState[state.rof.currentRofId][state.rof.currentPitId]
			: EUpdateState.Empty,
		currentRof: state.rof.allRof && state.rof.currentRofId ? state.rof.allRof[state.rof.currentRofId] : null,
		currentRofId: state.rof.currentRofId,
		currentPitId: state.rof.currentPitId,
		currentRofDetailsLoadingState: state.rof.allRofDetailsLoadingState[state.rof.currentRofId]
	};
};

export const CapabilitiesStatementTab = connect(
	mapStateToProps,
	capabilitiesStatementTabActionCreators
)(CapabilitiesStatementTabBase);
