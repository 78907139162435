import React from "react";
import { ResolveThunks, connect } from "react-redux";
import { OrganizationActionCreators } from "stores/actions/personaBuilder/organizationActions";
import { IModalForm } from "common/interfaces";
import { removePhoneNumberFormatting } from "common/utils/removePhoneNumberFormatting";
import { Formik } from "formik";
import { trimAllStringProperties } from "common/utils/trimAllStringProperties";
import { organizationDetailsValidationSchema } from "common/validation-schema/personaBuilder/organizationValidationSchema";
import { ApplicationState } from "stores";
import { Card, CardContent, Grid, FormLabel } from "@material-ui/core";
import css from './organization-details.module.scss';
import { PhoneNumberField } from "components/FormElements/PhoneNumberField/PhoneNumberField";
import { ErrorFocus } from "components/ErrorFocus/ErrorFocus";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IsFormDirty } from 'components/RouteGuards/IsFormDirty';

interface IFormOrganizationDetailsState {
	isLoading: boolean;
}

interface IFormOrganizationDetailsReduxProps {
	org_dropdowns: any;
}

export class FormOrganizationDetailsBase extends React.PureComponent<ResolveThunks<typeof OrganizationActionCreators> &
	RouteComponentProps &
	IModalForm &
	IFormOrganizationDetailsReduxProps,
	IFormOrganizationDetailsState> {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false
		};
	}

	setFormLoadingAttribute = (isLoading: boolean) => {
		this.setState({
			isLoading
		});
	};

	handleFormSubmit = (values, setSubmitting, resetForm) => {
		const { formAction, setErrorMessage, history } = this.props;
		values.phone = `+1${removePhoneNumberFormatting(values.phone)}`;
		this.setFormLoadingAttribute(true);
		if (formAction) {
			const trimmedValues = trimAllStringProperties(values);
			formAction(trimmedValues, setErrorMessage, this.setFormLoadingAttribute);
			resetForm(trimmedValues);
		}
		setSubmitting(false);
		history.push('/persona-builder/organization-listing');
	};

	render() {
		let { setFormLoadingAttribute } = this.props;
		const {
			bindSubmitForm,
			formAction,
			defaultFormContent,
			setErrorMessage,
			org_dropdowns,
			setSaveDisabled,
		} = this.props;

		let initialValues = {
			organization_name: '',
			address: '',
			city: '',
			state: '',
			country: '',
			postal_code: '',
			phone: '',
			organization_type_id: null,
			pit_id: null,
			parent_organization_id: null,
		};

		if (defaultFormContent) {
			defaultFormContent.phone = removePhoneNumberFormatting(defaultFormContent.phone);
			initialValues = defaultFormContent;
		}
		if (!setFormLoadingAttribute) {
			setFormLoadingAttribute = this.setFormLoadingAttribute;
		}

		return (
			<Formik
				initialValues={initialValues}
				enableReinitialize={true}
				onSubmit={(values, { setSubmitting, resetForm }) => this.handleFormSubmit(values, setSubmitting, resetForm)}
				validationSchema={organizationDetailsValidationSchema()}
			>
				{props => {
					const {
						values,
						touched,
						errors,
						dirty,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
						handleReset,
						submitForm,
						isValid
					} = props;
					if (bindSubmitForm) {
						bindSubmitForm(submitForm);
					}

					return (
						<>
							<IsFormDirty
								title=""
								message=""
								dirty={dirty}
							/>
							<Card>
								<CardContent>
									<form onSubmit={handleSubmit}>
										<Grid container xs={12}>
											<Grid item xs={6} className={css.formColumn}>
												<Grid item xs={12}>

													<FormLabel htmlFor='organization_name'>Organization Name</FormLabel>
													<input
														name='organization_name'
														placeholder='Enter Organization Name'
														type='text'
														value={values.organization_name}
														onChange={handleChange}
														onBlur={handleBlur}
														className={errors.organization_name && touched.organization_name ? 'text-input error' : 'text-input'}
														autoComplete='first-name'
														maxLength={128}
													/>
													{errors.organization_name && touched.organization_name && (
														<div className='input-feedback'>{errors.organization_name}</div>
													)}

													<FormLabel htmlFor='address'>Address</FormLabel>
													<input
														name='address'
														placeholder='Enter Address'
														type='text'
														value={values.address}
														onChange={handleChange}
														onBlur={handleBlur}
														className={errors.address && touched.address ? 'text-input error' : 'text-input'}
													/>
													{errors.address && touched.address && (
														<div className='input-feedback'>{errors.address}</div>
													)}

													<FormLabel htmlFor='city'>City</FormLabel>
													<input
														name='city'
														placeholder='Enter City'
														value={values.city}
														onChange={handleChange}
														onBlur={handleBlur}
														className={
															errors.city && touched.city ? 'text-input error' : 'text-input'
														}
													/>
													{errors.city && touched.city && (
														<div className='input-feedback'>{errors.city}</div>
													)}

													<FormLabel htmlFor='state'>State</FormLabel>
													<input
														name='state'
														placeholder='Enter State'
														value={values.state}
														onChange={handleChange}
														onBlur={handleBlur}
														className={
															errors.state && touched.state ? 'text-input error' : 'text-input'
														}
													/>
													{errors.state && touched.state && (
														<div className='input-feedback'>{errors.state}</div>
													)}

													<FormLabel htmlFor='country'>Country</FormLabel>
													<input
														name='country'
														placeholder='Enter Country'
														value={values.country}
														onChange={handleChange}
														onBlur={handleBlur}
														className={
															errors.country && touched.country ? 'text-input error' : 'text-input'
														}
													/>
													{errors.country && touched.country && (
														<div className='input-feedback'>{errors.country}</div>
													)}

													<FormLabel htmlFor='postal_code'>Postal Code</FormLabel>
													<input
														name='postal_code'
														placeholder='Enter Postal Code'
														value={values.postal_code}
														onChange={handleChange}
														onBlur={handleBlur}
														className={
															errors.postal_code && touched.postal_code ? 'text-input error' : 'text-input'
														}
													/>
													{errors.postal_code && touched.postal_code && (
														<div className='input-feedback'>{errors.postal_code}</div>
													)}
												</Grid>

												<Grid item xs={12} md={6} className={
													css.gridItem
												}>

													<FormLabel htmlFor='phone'>Phone</FormLabel>
													<PhoneNumberField
														id='phone'
														placeholder='Enter Phone Number'
														value={removePhoneNumberFormatting(values.phone)}
														onChange={handleChange}
														onBlur={handleBlur}
														autocomplete={'tel'}
														className={
															errors.phone && touched.phone ? 'text-input error' : 'text-input'
														}
													/>

													{errors.phone && touched.phone && (
														<div className='input-feedback'>{errors.phone}</div>
													)}

												</Grid>
											</Grid>
											<Grid item xs={6} className={css.formColumn}>
												<Grid item xs={12}>
													<FormLabel htmlFor='organization_type'>Organization Type</FormLabel>
													<select
														name='organization_type_id'
														onChange={handleChange}
														onBlur={handleBlur}
														value={`${values.organization_type_id}`}
														className={errors.organization_type_id && touched.organization_type_id ? 'text-input error' : 'text-input'}
													>
														<option>Select</option>
														{org_dropdowns.organization_types.map((organization_type, index) => (
															<option key={index} value={organization_type.id}>
																{organization_type.organization_type_display}
															</option>
														))}
													</select>
													{errors.organization_type_id && touched.organization_type_id && (
														<div className='input-feedback'>{errors.organization_type_id}</div>
													)}
												</Grid>
												

												<Grid item xs={12}>
													<FormLabel htmlFor='pit'>Pit</FormLabel>
													<select
														name='pit_id'
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.pit_id}
														className={errors.pit_id && touched.pit_id ? 'text-input error' : 'text-input'}
													>
														<option>Select</option>
														{org_dropdowns.pits.map((pit, index) => (
															<option key={index} value={pit.id}>
																{pit.pit_name}
															</option>
														))}
													</select>
													{errors.pit_id && touched.pit_id && (
														<div className='input-feedback'>{errors.pit_id}</div>
													)}
												</Grid>

												<Grid item xs={12}>
													<FormLabel htmlFor='parent_organization'>Parent Organization</FormLabel>
													<select
														name='parent_organization_id'
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.parent_organization_id}
														className={errors.parent_organization_id && touched.parent_organization_id ? 'text-input error' : 'text-input'}
													>
														<option>Select</option>
														{org_dropdowns.parent_organizations.map((parent_organization, index) => (
															<option key={index} value={parent_organization.id}>
																{parent_organization.organization_name}
															</option>
														))}
													</select>
													{errors.parent_organization_id && touched.parent_organization_id && (
														<div className='input-feedback'>{errors.parent_organization_id}</div>
													)}
												</Grid>

											</Grid>
											<ErrorFocus/>
										</Grid>
									</form>
								</CardContent>

							</Card>
						</>
					);
				}}
			</Formik>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IFormOrganizationDetailsReduxProps => ({
	org_dropdowns: state.personaBuilderOrganization.organization_dropdowns
});

export const FormOrganizationDetails = withRouter(connect(
	mapStateToProps,
	OrganizationActionCreators
)(FormOrganizationDetailsBase));