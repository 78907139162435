import * as React from 'react';
import css from './circle.module.scss';
import classes from 'classnames';
import { ReactComponent as Rof } from 'assets/icons/icon-rof.svg';

interface ICircleProps {
	style?: React.CSSProperties;
	className?: string;
	size?: 'small' | 'medium' | 'large';
	isCentered?: boolean;
	type?: null | 'action';
	border?: boolean;
	icon?: 'rof';
	isLowContrast?: boolean;
}

export const Circle: React.FC<ICircleProps> = props => {
	const { className, style, children, size = 'large', isCentered, type, border = true, icon, isLowContrast } = props;

	return (
		<div
			className={classes(
				css.circle,
				className,
				css[size],
				{ [css.centered]: isCentered === true },
				{ [css.action]: type === 'action' },
				{ [css.border]: border },
				{ [css.isLowContrast]: isLowContrast }
			)}
			style={style}
		>
			{icon === 'rof' && <Rof className={css.iconRof} />}
			{children}
		</div>
	);
};
