import * as React from 'react';
import { clone } from 'ramda';
import { convertIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';
import { AppFrame, InnerContainer, PageTitleArea } from 'components/Page';
import { Success } from 'components/Toast';
import { ICodeSample } from 'common/interfaces';
import { canCreateCodeSample } from 'common/utils/canCreateCodeSample';
import { ICanCreateCodeSample } from 'common/interfaces/ICanCreateCodeSample';
import { codeSampleActionCreators } from 'stores/actions/codeSampleActions';
import { createCodeSampleModal } from 'common/modals/createCodeSampleModal';
import { editCodeSampleModal } from 'common/modals/editCodeSampleModal';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { GridContainer, GridItem, GridItemContentAdd } from 'components/Grid';
import { deleteCodeSampleModal } from 'common/modals/deleteCodeSampleModal';
import { IMultistepModal } from 'common/interfaces';
import { THTMLElementEvent } from 'common/types/THTMLElementEvent';
import { modalActionCreators } from 'stores/actions/modalActions';
import { EUpdateState } from 'stores/shared/ENums';
import { Loading } from 'components/Loading';
import { TUserType } from 'common/types/TUserType';
import css from './code-sample-library.module.scss';

const codeSampleViewActionCreators = {
	...modalActionCreators,
	...codeSampleActionCreators,
};

interface ICodeSampleLibraryViewReduxProps {
	codeSamples: ICodeSample[];
	allCodeSamplesUpdateState: EUpdateState;
	canCreateCodeSample: ICanCreateCodeSample;
	currentUserType: TUserType;
}

export class CodeSampleLibraryViewBase extends React.PureComponent<
	ICodeSampleLibraryViewReduxProps & ResolveThunks<typeof codeSampleViewActionCreators>
	> {
	UNSAFE_componentWillMount() {
		this.props.getCodeSamples();
	}

	isLoading = () => {
		return !(
			this.props.allCodeSamplesUpdateState === EUpdateState.Loaded
		);
	};

	render() {
		const { codeSamples, allCodeSamplesUpdateState, currentUserType, createModal, deleteCodeSample } = this.props;
		const pageDescription: string = 'Browse our gallery of code samples.';

		return (
			<AppFrame>
				<InnerContainer>
					<PageTitleArea
						description={pageDescription}
					>
						Code Sample Gallery
					</PageTitleArea>
					<GridContainer>
						{currentUserType === 'mihin-admin' && (
							<GridItem type='create' action={event => createModal(createCodeSampleModal(), event)}>
								<GridItemContentAdd
									label='Add Code Sample'
								/>
							</GridItem>
						)}
						{this.isLoading() ? <Loading /> : codeSamples.map((codeSample, index) => (
							<GridItem
								className={css.gridItem}
								key={`code-sample-grid-item-${codeSample.id}`}
								location={`/code-sample/${codeSample.id}`}
								actionOptions={
									currentUserType === 'mihin-admin'
										? [
											{
												label: 'Edit Code Sample',
												action: event => {
													createModal(editCodeSampleModal(codeSample), event);
												}
											},
											{
												label: 'Delete Code Sample',
												action: event => {
													createModal(deleteCodeSampleModal(codeSample, deleteCodeSample), event);
												}
											}
										]
										: null
								}
							>
								<div className={css.itemTop}>
									<h5
										dangerouslySetInnerHTML={{
											__html: codeSample.name
										}}
									/>
									<div
										dangerouslySetInnerHTML={{
											__html: codeSample.descr
										}}
									/>
								</div>
							</GridItem>
						))}
					</GridContainer>

				</InnerContainer>
			</AppFrame>
		);
	}
}

const mapStateToProps = (state: ApplicationState): ICodeSampleLibraryViewReduxProps => {
	return {
		codeSamples: state.codeSample.allCodeSamples ? convertIndexedObjectToArray(clone(state.codeSample.allCodeSamples)) : null,
		allCodeSamplesUpdateState: state.codeSample.allCodeSamplesUpdateState,
		canCreateCodeSample: canCreateCodeSample(state.auth.user),
		currentUserType: state.auth.user.attributes['custom:user_type']
	};
};

export const CodeSampleLibraryView = connect(
	mapStateToProps,
	codeSampleViewActionCreators
)(CodeSampleLibraryViewBase);
