import * as React from "react";
import { LoadingSpinner } from "components/LoadingSpinner";
import css from "./loading.module.scss";

interface ILoadingState {
	shouldRender: boolean;
}

const delayAmount = 300;

export class Loading extends React.PureComponent<{}, ILoadingState> {
	timeout;

	constructor(props) {
		super(props);

		this.state = {
			shouldRender: false
		};
	}

	UNSAFE_componentWillMount() {
		this.wait();
	}

	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	wait = async () => {
		this.timeout = setTimeout(
			() =>
				this.setState({
					shouldRender: true
				}),
			delayAmount
		);
	};

	render() {
		return this.state.shouldRender ? (
			<LoadingSpinner className={css.loadingSpinner} />
		) : null;
	}
}
