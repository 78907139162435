import apiLibraryActions from 'stores/actionInterfaces/apiLibraryActionInterfaces';
import { AppThunkAction } from 'stores';

import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { url } from 'settings';
import { IApiDoc, ICodeSample } from 'common/interfaces';
import { TSetErrorMessage } from 'common/types/TSetErrorMessage';
import { TSetFormLoadingAttribute } from 'common/types/TSetFormLoadingAttribute';
import { modalActionCreators } from 'stores/actions/modalActions';
import { messageActionCreators } from './messageActions';
import { errorMessages } from 'common/data/errorMessages';
import { sharedHistory } from '../../sharedStore';

export const apiLibraryActionCreators = {
	// Get all API docs
	getApiDocs: (): AppThunkAction<apiLibraryActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'GET_API_DOCS_REQUEST' });
			const headers = await getCurrentAuthHeaders('json');

			try {

				const getApiDocsRequest = await fetch(`${url}api`, {
					headers
				});

				const apis: IApiDoc[] = (await getApiDocsRequest.json());

				dispatch({
					type: 'GET_API_DOCS_RECEIVED',
					apis: apis
				});
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},

	// Get a single API documentation entry
	getApiDoc: (api_doc_id: number): AppThunkAction<apiLibraryActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'GET_API_DOC_REQUEST' });
			const headers = await getCurrentAuthHeaders('json');

			try {
				const getApiDocRequest = await fetch(`${url}api/${api_doc_id}`, {
					headers
				});
				const api: IApiDoc = await getApiDocRequest.json();

				const getApiCodeSamplesRequest = await fetch(`${url}api/${api_doc_id}/code_samples`, {
					headers
				});
				const codeSamples: ICodeSample[] = await getApiCodeSamplesRequest.json();

				dispatch({
					type: 'GET_API_DOC_RECEIVED',
					api: api,
					codeSamples: codeSamples
				});
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},

	// Create a new API documentation entry
	createApiDoc: (
		apiDoc: IApiDoc,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute
	) => {
		return async (dispatch, getState) => {
			dispatch({ type: 'CREATE_API_DOC_REQUEST' });
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-type', 'application/json');

				const createApiDoc = await fetch(`${url}api/create`, {
					method: 'POST',
					headers,
					body: JSON.stringify({
						auto_start_stop: false,
						...apiDoc
					})
				})

				const createApiDocResponse = await createApiDoc.json();
				
				if (createApiDoc.ok === false) {
					setErrorMessage(createApiDocResponse.errorMessage);
					setFormLoadingAttribute(false);
					return;
				}

				dispatch(modalActionCreators.dismissModal());
				dispatch({
					type: 'CREATE_API_DOC_RESPONSE',
					apiDoc
				})
			} catch (err) {
				console.error(err);
				setErrorMessage(errorMessages.general)
				setFormLoadingAttribute(false);
			}
		}
	},

		// Create a new API documentation entry
	updateApiDoc: (
		apiDoc: IApiDoc,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute
	) => {
		return async (dispatch, getState) => {
			dispatch({ type: 'UPDATE_API_DOC_REQUEST' });
			const headers = await getCurrentAuthHeaders();
			const apiDocId = apiDoc.id;
			try {
				headers.set('Content-type', 'application/json');

				const updateApiDoc = await fetch(`${url}api/${apiDocId}`, {
					method: 'PUT',
					headers,
					body: JSON.stringify(apiDoc)
				})

				const updateApiDocResponse = await updateApiDoc.json();
				
				if (updateApiDoc.ok === false) {
					setErrorMessage(updateApiDocResponse.errorMessage);
					setFormLoadingAttribute(false);
					return;
				}

				dispatch(modalActionCreators.dismissModal());
				dispatch({
					type: 'UPDATE_API_DOC_RESPONSE',
					apiDocId: apiDoc.id,
					apiDoc
				})
			} catch (err) {
				console.error(err);
				setErrorMessage(errorMessages.general)
				setFormLoadingAttribute(false);
			}
		}
	},

	// Delete an API documentation entry
		deleteApiDoc: (apiDocId: number): AppThunkAction<apiLibraryActions> => {
		return async (dispatch, getState) => {
			try {
				dispatch({ type: 'DELETE_API_DOC_REQUEST' });

				const headers = await getCurrentAuthHeaders('json');
				const a = await fetch(`${url}api/${apiDocId}`, {
					method: 'delete',
					headers
				});
				dispatch({
					type: 'DELETE_API_DOC_RESPONSE',
					apiDocId
				});

				//@ts-ignore
				dispatch(modalActionCreators.dismissModal());
				sharedHistory.push(`/api`);
			} catch (e) {
				console.error(e);
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
};
