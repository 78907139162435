import websocketActions from 'stores/actionInterfaces/websocketActionInterfaces';
import { AppThunkAction } from 'stores';
import io from 'socket.io-client';
import { url } from 'settings';
import { getCurrentJwt } from 'common/utils/getCurrentJwt';
import { IRofTemplate } from 'common/interfaces/IRofTemplate';
import { IEntitlementSummary } from 'common/interfaces/IEntitlementSummary';
import { IEntitlement } from 'common/interfaces/IEntitlement';
import { IBackupStatus } from 'common/interfaces/IBackupStatus';

export const websocketActionCreators = {
	connectToSandboxUpdates: (): AppThunkAction<websocketActions> => {
		return async (dispatch, getState) => {
			if (!getState().websocket.socket) {
				const socket = io(url);

				console.log('connectToSandboxUpdates', socket);
				socket.on('connect', async () => {
					console.log('connected');
					socket.send('User has connected!');
					const JWT = await getCurrentJwt();
					socket.emit('join', JWT);
				});

				socket.on('rof_status', data => {
					console.log('rof_status data', data);
					const { rof_id, org_id, rof_status } = JSON.parse(data);
					console.log('rof_id, org_id, rof_status', `${rof_id}, ${org_id}, ${rof_status}`);
					if (rof_status === 'DELETE_IN_PROGRESS' || rof_status === 'DELETE_COMPLETE') {
						dispatch({ type: 'REMOVE_SANDBOX_FROM_STATE', sandboxId: rof_id });
					} else {
						dispatch({
							type: 'UPDATE_SANDBOX_STATUS',
							sandboxId: rof_id,
							orgId: org_id,
							status: rof_status
						});
					}
				});

				socket.on('rof_create', data => {
					console.log('rof_create data', data);
					const sandbox = JSON.parse(data);
					dispatch({ type: 'ADD_SANDBOX_TO_STATE', sandbox });
				});

				socket.on('template', data => {
					const templates: IRofTemplate[] = JSON.parse(data).data;
					console.log('template', templates);
					dispatch({ type: 'UPDATE_TEMPLATES', templates });
				});

				socket.on('entitlement_summary', data => {
					const entitlementSummary: IEntitlementSummary = JSON.parse(data);
					console.log('entitlementSummary', entitlementSummary);
					dispatch({ type: 'UPDATE_ENTITLEMENT_SUMMARY', entitlementSummary });
				});

				socket.on('entitlements', data => {
					const entitlements: IEntitlement[] = JSON.parse(data).data;
					console.log('entitlements', entitlements);
					dispatch({ type: 'UPDATE_ENTITLEMENTS', entitlements });
				});

				socket.on('rof_operation_status', data => {
					const backupStatus: IBackupStatus = JSON.parse(data);
					console.log('backupStatus', backupStatus);
					if (backupStatus.operation_status === 'iol-db-backup-delete') {
						dispatch({ type: 'REMOVE_BACKUP_FROM_STATE', backupStatus });
					} else if (backupStatus.operation_status === 'iol-db-backup-start') {
						dispatch({ type: 'ADD_BACKUP_TO_STATE', backupStatus });
					} else {
						dispatch({ type: 'UPDATE_BACKUP_STATUS', backupStatus });
					}
				});

				// @ts-ignore
				dispatch({ type: 'CONNECT_TO_SANDBOX_UPDATES', socket }); 
			}
		};
	}
};
