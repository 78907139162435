import * as React from 'react';

export const TermsOfServiceContent:React.FC = () => {
	return (<>
		<h1 className='h2'>Interoperability Land Terms of Service</h1>
		<b>Effective: October 15, 2020</b>
		<p>
			These Interoperability Land Terms of Service (the “<u>Terms</u>”) describe your rights and
			responsibilities when using our simulated healthcare network populated with the Personas (the
			“<u>Platform</u>”). “<u>Personas</u>” means the proprietary highly realistic, clinically
			relevant, synthetic patient data provided by Interoperability Institute LLC and its affiliates
			(“<u>we</u>,” “<u>us</u>,” or “<u>our</u>”). The Platform includes any software, programs,
			documentation, tools, internet-based services, add-on components, and any updates (including
			software maintenance, service information, help content, bug fixes or maintenance releases)
			provided to you by us, directly or indirectly.
		</p>
		<p>
			These Terms contain nine sections summarized below. The summary is for reference and convenience
			only and do not limit the scope of each section. Please read these Terms carefully as they apply
			to your use of the Platform and form a binding agreement between you and us. If you are entering
			into these Terms as part of an entity or organization, please make sure you have the necessary
			authority to enter into these Terms before proceeding. Any actions or omissions by your
			employees, contractors, agents, volunteers, or customers who are authorized by you to use the
			Platform (“<u>Authorized Users</u>”) will be deemed actions by you. You represent and warrant that
			each Authorized User has read and will comply with these Terms and any instruction issued by us
			and our licensors with respect to the use of the Platform.
		</p>
		<table>
			<thead>
				<tr>
					<th>Section</th>
					<th>Summary</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
						<a href='#thePlatform'>The Platform</a>
					</td>
					<td>
						You’re granted a limited right to use the Platform as described in these Terms.
						This section sets for the basic rules you must follow when using the Platform.
					</td>
				</tr>
				<tr>
					<td>
						<a href='#yourResponsibilities'>Your Responsibilities</a>
					</td>
					<td>
						This section describes your responsibilities when using the Platform under these
						Terms.
					</td>
				</tr>
				<tr>
					<td>
						<a href='#commercialTerms'>Commercial Terms</a>
					</td>
					<td>
						You’re responsible for payment. We’re responsible for communicating our fees to you
						clearly and accurately and letting you know in advance of any price changes. You may
						terminate these Terms at any time.
					</td>
				</tr>
				<tr>
					<td>
						<a href='#yourContentAndUserContent'>Your Content & User Content</a>
					</td>
					<td>
						You own and control Your Content, but you allow us certain rights to it so that we
						can provide the Platform. We have the right to remove Your Content or suspend or
						terminate access to the Platform if we need to.
					</td>
				</tr>
				<tr>
					<td>
						<a href='#privateRepositories'>Private Repositories</a>
					</td>
					<td>
						You may have access to private repositories. We treat the content of private
						repositories as confidential, and we only access it with your consent or if required
						for security reasons.
					</td>
				</tr>
				<tr>
					<td>
						<a href='#thirdPartyApplications'>Third Party Applications</a>
					</td>
					<td>
						You need to follow certain rules if you create an application to use in connection
						with the Platform.
					</td>
				</tr>
				<tr>
					<td>
						<a href='#disclaimerOfWarranties'>Disclaimer of Warranties</a>
					</td>
					<td>
						We provide the Platform as is and make no promises or guarantees about the Platform.
						Please read this section carefully; you should understand what to expect.
					</td>
				</tr>
				<tr>
					<td>
						<a href='#riskAllocationProvisions'>Risk Allocation Provisions</a>
					</td>
					<td>
						You are fully responsible to us for your use of the Platform. If you harm someone
						else, or get into a dispute with someone else, we will not be involved. We will not
						be liable for certain damages or losses resulting from your use or inability to use
						the Platform or otherwise under these Terms. Please read this section carefully; it
						limits our obligations to you.
					</td>
				</tr>
				<tr>
					<td>
						<a href='#generalProvisions'>General Provisions</a>
					</td>
					<td>
						Please see this section for general legal details, including those related to
						dispute resolution.
					</td>
				</tr>
			</tbody>
		</table>
		<p>
			As our business evolves, we may change these Terms. If we make a material change to these Terms,
			we will provide you with reasonable notice prior to the change taking effect by emailing your
			email address. The materially revised Terms will become effective on the date set forth in our
			notice, and all other changes will become effective upon posting of the change. If you or any
			Authorized User accesses or uses the Platform after the effective date, that use will constitute
			your acceptance of any revisions to these Terms.
		</p>
		<ol>
			<li>
				<h2 className='h3 termsTitle' id='thePlatform'>
					The Platform
				</h2>
				<ol>
					<li>
						<h3 className='h5 termsTitle'>Your Right to Use the Platform.</h3>
						<p>
							We grant you a personal, revocable, non-exclusive,
							non-transferable, non-assignable, non-sublicensable, and limited right to have
							access to and use the Platform during the Term in accordance with these Terms. We
							own and will continue to own our Platform, including all related intellectual
							property rights. All of our rights not expressly granted by this license are
							retained by us.
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Restrictions of Use.</h3>
						<p>
							You shall not (and shall not permit any third party over
							which you exercise control to):
						</p>
						<ul>
							<li>
								duplicate, modify, adapt, translate, reverse engineer, decompile, disassemble or
								create a derivative work based on the Platform;
							</li>
							<li>
								copy or incorporate the Platform into any other computer program, hardware,
								firmware or product, except as specifically provided for under these Terms;
							</li>
							<li>
								sell, trade, resell, lease, or the functional equivalent, the Platform to a
								third party;
							</li>
							<li>
								attempt to create a substitute or similar service through the use of, or access to,
								the Platform;
							</li>
							<li>
								use the Platform in any way that is unlawful, illegal, fraudulent or harmful or
								in connection with any unlawful, illegal, fraudulent or harmful purpose or
								activity;
							</li>
							<li>
								provide access to or give any part of the Platform to any third party who is not
								an Authorized User;
							</li>
							<li>
								use the Platform in any way that causes, or may cause, damage to the Platform or
								impairment of the availability or accessibility of the Platform;
							</li>
							<li>
								excessive use of the Platform defined as more than one million (1,000,000) API
								calls per your use of the Platform, twenty-five (25) Authorized Users, one (1)
								GB of requests via API per hour, twenty-five (25) connected third party
								applications, or one (1) repository in your use of the Platform.
							</li>
							<li>
								make the Platform available on any file-sharing or application hosting service;
								or
							</li>
							<li>
								use any “robot,” “spider,” or other systematic or automatic device, program,
								script, algorithm, or methodology, or any similar or equivalent manual process,
								to access, acquire, copy, or monitor any portion of the Platform or the
								Personas, or in any way reproduce or circumvent the navigational structure or
								presentation of any of the Platform to obtain or attempt to obtain any
								materials, documents, services, or information through any means not purposely
								made available through the Platform.
							</li>
						</ul>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Ownership.</h3>
						<ul className='counterAlpha'>
							<li>
								<p>
									The Platform and all of the content and technology used to provide it to
									you, including, without exclusion, the Personas, our website, all logos
									related to the Platform, patents, copyrights, trademarks, service marks,
									trade names, and any other intellectual or other proprietary rights
									recognized in any jurisdiction that are owned or controlled by us, our
									affiliates, licensors, or certain other third parties except for Your
									Content and User Content (“Content”). All right, title, and interest in
									and to the Content available via the Platform is the property of us, our
									affiliates, our licensors, or certain other third parties, and is protected
									by U.S. and international copyright, trademark, trade dress, patent, or
									other intellectual property and unfair competition rights and laws to the
									fullest extent possible. We own the copyright in the selection, compilation,
									assembly, arrangement, and enhancement of the Content in the Platform.
								</p>
							</li>
							<li>
								<p>
									We grant to you, solely during the Term, a limited, personal, revocable,
									non-exclusive, non-transferable, and non-sublicensable right to use the
									Personas, subject to any restrictions on such use set forth in these Terms,
									solely in connection with your use of the Platform. To the extent that you
									create a derivative work of any Personas, you hereby irrevocably assign, and
									agree to assign, and shall cause your Authorized Users to assign, and agree
									to assign, to us, without further consideration, all of its and their right,
									title and interest in and to such derivative work, subject to our
									confidentiality obligations under Section 5 (Private Repositories); provided
									we will not commercialize the derivative works created by you without your
									consent (which will not be unreasonably withheld).
								</p>
							</li>
							<li>
								<p>
									The information, data, or other content related to the Platform, and your
									use of the Platform and Personas, including statistical or performance
									related data or information created by the Platform as a result of your
									operation or the use thereof, excluding Your Content, is owned by us.
								</p>
							</li>
						</ul>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Feedback is Welcome.</h3>
						<p>
							The more suggestions our users make, the better the
							Platform becomes. If you send us any feedback or suggestions regarding the Platform
							or our Content, there is a chance we will use it, so you hereby grant us (for
							yourself and all of your Authorized Users) an unlimited, irrevocable, perpetual,
							sublicensable, transferable, worldwide, fully paid-up, royalty-free license to use
							any such feedback or suggestions for any purpose without any obligation or
							compensation to you or any third party, subject to our confidentiality obligations
							under Section 5 (Private Repositories).
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Suspension.</h3>
						<p>
							We are entitled, on reasonable grounds, including the reasonable belief
							of your fraud or illegal activity in your use of the Platform, to suspend your
							access to the Platform.
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Changes to the Platform.</h3>
						<p>
							In accordance with applicable laws, we have the right to
							change, limit access to, or eliminate any aspect(s), feature(s) or functionality of
							the Platform in our sole discretion at any time without notice, and we make no
							commitment (express or implied) to offer, maintain, continue, or permit access to
							any aspect of the Platform.
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Third Party Materials.</h3>
						<p>
							Our Platform may include products, content (including User
							Content), or services from third parties (“Third Party Materials”). Use of the Third
							Party Materials included in the Platform may be subject to the third party terms and
							conditions that may be made available by us to you from time to time (collectively
							“Third Party Terms”), in addition to these Terms. Unless expressly provided
							otherwise in the Third Party Terms, you may only use the Third Party Materials in
							connection with your use of the Platform. In the event of a conflict between these
							Terms and any Third Party Terms, the terms of the Third Party Terms shall control as
							between you and the third party. Your use of the Third Party Materials will indicate
							your agreement to be bound by the Third Party Terms. ANY USE OF ANY THIRD PARTY
							MATERIALS IS SOLELY BETWEEN YOU AND THE APPLICABLE THIRD PARTY PROVIDER.
							NOTWITHSTANDING ANY OTHER SECTION OF THESE TERMS TO THE CONTRARY, WE MAKE NO
							WARRANTY OF ANY KIND, WHETHER EXPRESS OR IMPLIED WITH REGARD TO ANY THIRD PARTY
							MATERIALS. WE EXPRESSLY DISCLAIM ALL WARRANTIES, EXPRESS AND IMPLIED, INCLUDING, BUT
							NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, QUALITY
							OF INFORMATION, AND FITNESS FOR A PARTICULAR PURPOSE WITH REGARD TO THE THIRD PARTY
							MATERIALS. YOU SHOULD CONSULT THE THIRD PARTY TERMS, IF ANY, FOR WARRANTY AND
							PERFORMANCE INFORMATION REGARDING SUCH THIRD PARTY MATERIALS.
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Privacy Policy.</h3>
						<p>
							Please review our Privacy Policy available at
							<a target="_BLANK" href='https://interoperabilityinstitute.org/privacy-policy-for-interoperability-institute/'>
								https://interoperabilityinstitute.org/privacy-policy-for-interoperability-institute/
							</a>
							(“Privacy Policy”) for more information on how we collect and use data relating to
							the use and performance of our websites and products. Our Privacy Policy is
							incorporated into these Terms by reference.
						</p>
					</li>
				</ol>
			</li>
			<li>
				<h2 className='h3 termsTitle' id='yourResponsibilities'>
					Your Responsibilities
				</h2>
				<ol>
					<li>
						<h3 className='h5 termsTitle'>Eligibility.</h3>
						<p>
							In order to be eligible to access and use the Platform, you and your
							Authorized Users must (i) be eighteen (18) years of age or older, (ii) have the
							legal ability to enter into and be bound by these Terms, (iii) not be or working on
							behalf of a specially designated national or person subject to similar blocking or
							denied party prohibitions administered by a U.S. government agency; and (iv) comply
							with these Terms. If you access the Platform from outside the United States you are
							doing so on your own initiative and are responsible for compliance with applicable
							local laws regarding your online conduct and acceptable content, if and to the
							extent local laws apply. When you do business with us, you agree to, and shall cause
							all Authorized Users to, comply with all applicable laws, regulations or ordinances,
							and orders of governmental and governing authorities, including federal and state
							privacy laws.
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Minimum System Requirements.</h3>
						<p>
							You are responsible for procuring and
							maintaining, at your own expense, all equipment, software, services and testing
							necessary to effectively and reliably meet the minimum system requirements needed to
							access and use the Platform (available at
							<a target="_BLANK" href='https://mihin.org/minimum-system-requirements/'>
								https://mihin.org/minimum-system-requirements/
							</a>
							, as may be modified from time to time, the terms of which are incorporated into
							these Terms by reference). You are responsible for maintaining a secure environment
							to connect to the Platform.
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Patient Care.</h3>
						<p>
							The Platform does not provide medical advice. The Contents made
							available to you and your Authorized Users is synthetic and for informational
							purposes only. The Platform does not provide medical diagnoses or recommendations
							regarding medical treatment and is not a substitute for professional medical advice,
							diagnosis or treatment. Always seek the advice of a physician or other qualified
							health care provider with any questions you may have regarding a medical condition.
							Never disregard medical advice or delay in seeking it because of information you
							have read in connection with the Platform. In an emergency, call 911 or your local
							emergency assistance number. You are solely responsible for all decisions and
							actions taken or not taken involving patient care, utilization management, and
							quality management for your respective patients and clients resulting from, or in
							any way related to, the use of the Platform. We do not assume any role in the care
							of any patient. In no event will we be liable for any decision made or action taken
							in reliance upon the information provided through the Platform.
						</p>
					</li>
				</ol>
			</li>
			<li>
				<h2 className='h3 termsTitle' id='commercialTerms'>
					Commercial Terms
				</h2>
				<ol>
					<li>
						<h3 className='h5 termsTitle'>Fees</h3>
						<ul className='counterAlpha'>
							<li>
								<p>
									You agree to pay the applicable fees listed on our fee scheduled
									published at
									<a target="_BLANK" href='https://aws.amazon.com/marketplace/pp/B08K3VGH96'>
										https://aws.amazon.com/marketplace/pp/B08K3VGH96
									</a>
									. The fees will not change during your subscription term but are subject to change
									at the end of the Term upon thirty (30) days’ advance notice. By continuing to use
									the Platform after a change in the fees, you agree to such change.
								</p>
							</li>
							<li>
								<p>
									The Platform is billed in advance on a yearly basis and is
									non-refundable. There will be no refunds or credits for partial months
									of service, downgrade refunds, or refunds for months unused with a
									subscription to the Platform; however, your, and your Authorized User’s,
									access to the Platform will remain active for the length of the paid
									billing period.
								</p>
							</li>
							<li>
								<p>
									All fees and other amounts stated or referred to in these Terms are
									exclusive of taxes, duties, levies, tariffs, and other governmental
									charges. You are responsible for payment of all taxes and any related
									interest and/or penalties resulting from any payments made applicable to
									you, other than any taxes based on our net income, revenues, personnel,
									or corporate characteristics.
								</p>
							</li>
						</ul>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Term.</h3>
						<p>
							These Terms will commence on the date which you begin use of the
							Platform and will remain in effect for as long as you use the Platform (the
							“Term”).
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Termination.</h3>
						<ul className='counterAlpha'>
							<li>
								<p>
									At your discretion, if you no longer wish to be bound by these Terms you
									must stop using the Platform and provide at least one (1) day notice
									prior to termination.
								</p>
							</li>
							<li>
								<p>
									We have the right to terminate these Terms with thirty (30) days’ prior
									notice following any suspension under Section 1.5.
								</p>
							</li>
						</ul>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Survival.</h3>
						<p>
							The rights and obligations of the parties under Sections 1.8,
							2.3, 3.1, 3.4, 7, 8, and 9 will survive any expiration or termination of these
							Terms.
						</p>
					</li>
				</ol>
			</li>
			<li>
				<h2 className='h3 termsTitle' id='yourContentAndUserContent'>
					Your Content & User Content
				</h2>
				<ol>
					<li>
						<h3 className='h5 termsTitle'>Responsibility for Your Content.</h3>
						<p>
							“<u>Your Content</u>” refers to information, data, images, photographs, graphics,
							software, applications, designs, features, and other materials which are created,
							owned, or licensed by you or your Authorized Users that you or your Authorized Users
							post, upload, link to, or otherwise make available via the Platform. You are solely
							responsible for Your Content and for any harm resulting from any of Your Content,
							regardless of the form of Your Content. We are not responsible for any display or
							misuse of Your Content.
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Prohibited Content.</h3>
						<p>
							We do not pre-screen Your Content, but we have the right (though not the
							obligation) to refuse or remove any of Your Content that, in our sole
							discretion, violates any of these Terms or our policies. You acknowledge that
							the Platform is not designed with security and access management for processing
							the following categories of information:
						</p>
						<ul>
							<li>
								data that is classified and/or used on the U.S. Munitions list;
							</li>
							<li>
								articles, services and related technical data designated as defense articles
								or defense services;
							</li>
							<li>
								protected health information as defined in 45 C.F.R. § 160.103 of the Health
								Insurance Portability and Accountability Act of 1996, Title XIII of Division
								A and Title IV of Division B of the American Recovery and Reinvestment Act
								of 2009, and the regulations promulgated thereunder at 45 C.F.R. Parts 160
								and 164, each as may be amended from time to time;
							</li>
							<li>
								information that identifies, relates to, describes, is capable of being
								associated with, or could reasonably be linked, directly or indirectly, with
								a particular natural person; or
							</li>
							<li>
								ITAR (International Traffic in Arms Regulations) related data (each of the
								foregoing, “<u>Prohibited Content</u>”).
							</li>
						</ul>
						<p>
							You shall not, and shall not permit any Authorized User or other person to,
							provide any Prohibited Content to, or process any Prohibited Content through,
							the Platform. You are solely responsible for reviewing all of Your Content and
							any other information provided to us via the Platform, and shall ensure that
							Your Content or other information does not constitute or contain any Prohibited
							Content.
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Ownership of Your Content.</h3>
						<p>
							You retain ownership of and responsibility for Your Content. You retain all
							moral rights to Your Content, including the rights of integrity and attribution.
							However, you waive these rights and agree not to assert them against us, to
							enable us to reasonably exercise the rights granted in Section 4.4. If you
							submit anything you did not create yourself or do not own the rights to, you
							agree that you:
						</p>
						<ul>
							<li>
								are responsible for any of Your Content that you submit to the Platform;
							</li>
							<li>
								will only submit Your Content that you have the right to submit to the
								Platform; and
							</li>
							<li>
								will fully comply with any third party licenses relating to Your Content you
								submit to the Platform.
							</li>
						</ul>
						<p>
							Because you retain ownership of and responsibility for Your Content, we need you
							to grant us and other users certain legal permissions to use Your Content
							discussed in Section 4.4. You understand that you will not receive any payment
							for any of the rights granted in Section 4.4. The licenses you grant to us and
							any other users of the Platform will end when you remove Your Content from the
							Platform, unless other users own copies of Your Content from your repositories
							in repositories they control.
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>License Grant to Your Content.</h3>
						<p>
							We need the legal right to do things like host Your Content, publish it, and
							share it. You grant us and our legal successors the irrevocable (during the
							Term), non-exclusive, transferable, sublicensable, royalty-free, fully paid-up,
							and worldwide license to use, display, and perform Your Content through the
							Platform, as well as the right to store, parse, and display Your Content, and
							make incidental copies as necessary to provide the Platform. This includes the
							right to do things like copy it to our database and make backups, show it to you
							and other users, parse it into a search index or otherwise analyze it, share it
							with other users, and perform it, if Your Content is something like music or
							video. This license does not grant us the right to sell Your Content or
							otherwise distribute or use it outside of our provision of the Platform.
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>User Content.</h3>
						<p>
							“<u>User Content</u>” refers to information, data, images, photographs,
							graphics, software, applications, designs, features, and other materials which
							are created, owned, or licensed by other users or that other users post, upload,
							link to, or otherwise make available via the Platform. We are not responsible
							for User Content and for any harm resulting from any User Content, regardless of
							the form of User Content. You are granted the irrevocable (during the Term),
							non-exclusive, transferable, sublicensable, royalty-free, and fully paid-up
							license to use, display, and perform User Content through the Platform as well
							as the right to store, parse, and display User Content in the Platform solely
							during the Term.
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Copyright Notice.</h3>
						<p>
							If you have reason to believe that your work has been copied in a manner that
							constitutes copyright infringement, you may request removal of those materials
							(or access to them) by submitting a written notification to our copyright agent
							designated below that contains the information required under the Online
							Copyright Infringement Liability Limitation Act of the Digital Millennium
							Copyright Act (17 U.S.C. § 512) (the “DMCA”). Under the DMCA, the written notice
							must include substantially the following:
						</p>
						<ul>
							<li>
								your contact information, including your mailing address, telephone
								number(s), and e-mail address;
							</li>
							<li>
								your original or electronic signature, or that of the individual authorized
								to act on behalf of your interests as the copyright owner;
							</li>
							<li>
								a detailed description of the copyrighted material or work that you claim
								has been copied in a manner that constitutes copyright infringement;
							</li>
							<li>
								a detailed description of where on this website the copyrighted material or
								work that you claim is infringing is located (including at minimum, the URL
								and the exact date on which the material or work was displayed);
							</li>
							<li>
								a declaration made by you attesting to your good-faith belief that the
								alleged infringement has not been authorized by you as the copyright owner,
								your agent, or the law; and
							</li>
							<li>
								a declaration made by you, under penalty of perjury, attesting that all of
								the information provided above is accurate and that you are the copyright
								owner or is authorized to act on behalf of the copyright owner for any and
								all matters relating to the claimed copyright infringement.
								<p>Our designated copyright agent to receive DMCA notices is:</p>
								<p>
									Interoperability Institute LLC
									<br />
									Attention: Legal Department
									<br />
									1140 Abbot Road, Unit 1588
									<br />
									East Lansing, MI 48826
								</p>
								<p>
									E-mail:
									<a href='mailto:legal@interoperabilityinstitute.org'>
										legal@interoperabilityinstitute.org
									</a>
								</p>
							</li>
						</ul>
						<p>
							If you fail to comply with all of the requirements of Section 512(c)(3) of the
							DMCA, your DMCA notice may not be effective. Please be aware that if you
							knowingly materially misrepresent that material or activity on the Platform is
							infringing your copyright, you may be held liable for damages (including costs
							and attorneys’ fees) under Section 512(f) of the DMCA.
						</p>
						<p>
							You may not post, modify, distribute or reproduce in any way any copyrighted
							materials, trademarks or other proprietary information belonging to others
							without obtaining the prior written consent of the owner of such proprietary
							rights. We will terminate the account and access rights of any repeat infringer.
						</p>
					</li>
				</ol>
			</li>
			<li>
				<h2 className='h3 termsTitle' id='privateRepositories'>
					Private Repositories
				</h2>
				<ol>
					<li>
						<h3 className='h5 termsTitle'>
							Confidentiality of and Access to Private Repositories.
						</h3>
						<p>
							You may elect to have private repositories of Your Content, which allow you to
							control access to Your Content. We consider Your Content in your private
							repositories to be confidential to you. We will protect Your Content in your
							private repositories from unauthorized use, access, or disclosure in the same
							manner that we would use to protect our own confidential information of a
							similar nature and in no event with less than a reasonable degree of care.
						</p>
						<p>
							Anyone acting on our behalf may only access Your Content in your private
							repositories in the following situations:
						</p>
						<ul>
							<li>with your consent and knowledge;</li>
							<li>for support reasons (with your consent and knowledge);</li>
							<li>
								when access is required for security reasons, including when access is
								required to maintain ongoing confidentiality, integrity, availability, and
								resilience of our systems and the Platform; or
							</li>
							<li>
								you may choose to enable additional access to your private repositories
								which may vary depending on the service or feature (but we will always
								provide an explanation of the additional rights necessary in connection with
								the elected enabled access).
							</li>
						</ul>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Exclusions.</h3>
						<p>
							If we have reason to believe Your Content in your private repository are in
							violation of the law or of these Terms, we have the right to access, review, and
							remove them. Additionally, we may be compelled by law to disclose Your Content
							in your private repositories.
						</p>
					</li>
				</ol>
			</li>
			<li>
				<h2 className='h3 termsTitle' id='thirdPartyApplications'>
					Third Party Applications
				</h2>
				<ol>
					<li>
						<h3 className='h5 termsTitle'>Creating Applications.</h3>
						<p>
							If you create a third-party application or other developer product that collects
							Content, User Content, or Your Content and integrates with the Platform through
							our API, OAuth mechanism, or otherwise (“Developer Product”), and make it
							available for other users, then you must comply with the following requirements:
						</p>
						<ul>
							<li>
								you must comply with these Terms and the Privacy Policy;
							</li>
							<li>
								if you will provide a Developer Product in a consumer-facing production
								environment, you will conspicuously post a privacy policy that (i) complies
								with applicable law, (ii) describes the types of data the Developer Product
								collects, (iii) details how you will use the Content or User Content, and
								(iv) names us as a third party to whom the data will be shared;
							</li>
							<li>
								except as otherwise permitted by applicable law or by a license, you must
								limit your usage of the Content or User Content you collect to that purpose
								for which us or the relevant user has authorized its collection;
							</li>
							<li>
								you must take all reasonable security measures appropriate to the risks,
								such as against accidental or unlawful destruction, or accidental loss,
								alteration, unauthorized disclosure or access, presented by processing
								Content, User Content, or Your Content;
							</li>
							<li>
								you must not hold yourself out as collecting any Content, User Content, or
								Your Content on our behalf; and
							</li>
							<li>
								you must provide us or the relevant user with a method of deleting any
								Content or User Content you have collected through the Platform after it is
								no longer needed for the limited and specified purposes for which us or the
								relevant user authorized its collection, except where retention is required
								by law or otherwise permitted, such as through a license.
							</li>
						</ul>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Using Third-Party Applications.</h3>
						<p>
							You may grant a Developer Product authorization to use, access, and disclose the
							contents of your repositories, including your private repositories. If you set
							up a Developer Product, then you will be responsible for the Developer Product’s
							actions that are performed on or through your use of the Platform. We make no
							warranties of any kind in relation to Developer Products and are not liable for
							disclosures to third parties that you authorize to access Your Content. Your use
							of any third-party applications is at your sole risk.
						</p>
					</li>
				</ol>
			</li>
			<li>
				<h2 className='h3 termsTitle' id='disclaimerOfWarranties'>Warranty Disclaimer</h2>
				<div className='indented'>
					<p>
						We strive to ensure that the Platform is available, secure, and accurate. However,
						no system is perfectly secure, accurate, or reliable. The Internet is an inherently
						insecure medium, and the reliability of hosting services, data providers, Internet
						intermediaries, your Internet service provider, and other service providers cannot
						be assured. When you use the Platform, you accept these risks, and the
						responsibility for choosing to use a technology that does not provide perfect
						security, accuracy, or reliability.
					</p>
					<p>
						The Platform may contain inaccuracies or typographical errors. The Platform is
						provided on an “as is” and “as available” basis. We cannot guarantee and do not
						promise any specific results from use of the Platform. No advice or information,
						whether oral or written, obtained by you from us or the Platform will create any
						warranty not expressly stated in these Terms.
					</p>
					<p>
						YOU AGREE THAT YOUR USE OF THE PLATFORM WILL BE AT YOUR SOLE RISK. TO THE FULLEST
						EXTENT PERMITTED BY LAW, US AND OUR SERVICE PROVIDERS, SUPPLIERS, VENDORS, AND OTHER
						AGENTS MAKE NO WARRANTIES, EITHER EXPRESS OR IMPLIED, WITH RESPECT TO THE PLATFORM
						OR CONTENT AND DISCLAIM ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
						PARTICULAR PURPOSE, AND NON-INFRINGEMENT, AND ANY WARRANTY ARISING BY LAW OR FROM A
						COURSE OF DEALING OR USAGE OF TRADE. WITHOUT LIMITING THE FOREGOING, WE MAKE NO
						WARRANTY OF ANY KIND AND ASSUMES NO LIABILITY OR RESPONSIBILITY THAT:
					</p>
					<ul>
						<li>
							THE PLATFORM WILL MEET YOUR SPECIFIC REQUIREMENTS;
						</li>
						<li>
							THE PLATFORM OR THE CONTENT WILL BE UNINTERRUPTED, ACCURATE, COMPLETE, TIMELY,
							SECURE, FREE FROM HARMFUL CODE, OR ERROR-FREE;
						</li>
						<li>
							THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE PLATFORM WILL BE ACCURATE
							OR RELIABLE;
						</li>
						<li>
							THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL OBTAINED
							BY YOU THROUGH THE PLATFORM WILL MEET YOUR EXPECTATIONS; AND
						</li>
						<li>
							ANY ERRORS IN THE PLATFORM WILL BE CORRECTED.
						</li>
					</ul>
					<p>
						You understand that we use third party vendors and hosting partners to provide the
						necessary hardware, software, networking, storage, and related technology required
						to run the Platform. ALL THIRD-PARTY MATERIALS ARE PROVIDED “AS IS” AND ANY
						REPRESENTATION OR WARRANTY OF OR CONCERNING ANY THIRD PARTY MATERIALS IS STRICTLY
						BETWEEN YOU AND THE THIRD-PARTY OWNER OR DISTRIBUTOR OF THE THIRD-PARTY MATERIALS.
						WE WILL HAVE NO LIABILITY FOR ANY UNAUTHORIZED ACCESS TO THE PLATFORM. Some
						jurisdictions do not allow the disclaimer of implied warranties and, to that extent,
						these disclaimers may not apply to you.
					</p>
				</div>
			</li>
			<li>
				<h2 className='h3 termsTitle' id='riskAllocationProvisions'>Risk Allocation Provision</h2>
				<ol>
					<li>
						<h3 className='h5 termsTitle'>Indemnification.</h3>
						<ul className='counterAlpha'>
							<li>
								<p>
									You will defend, indemnify, and hold harmless us (and our affiliates and
									each of our respective directors, officers, shareholders, employees,
									agents, successors, and assigns) from and against any liability, claim,
									action, loss, damage, or expense (including court costs and reasonable
									attorneys’ fees) based on any claims arising out of or relating to (i)
									your use of the Platform (including, but not limited to, violation of
									these Terms or your negligent, willful, or illegal conduct); (ii) your
									unauthorized use of the Content or User Content; (iii) Your Content;
									(iv) your Developer Products; or (v) your breach of these Terms. We
									reserve the right to assume the exclusive defense and control of any
									matter otherwise subject to indemnification by you, and in such case,
									you agree to cooperate with our defense of such claim. You will not
									settle any claim without the prior, written consent of us.
								</p>
							</li>
							<li>
								<p>
									As a condition of access to the Platform, you release us (and our
									affiliates and each of our respective directors, officers, shareholders,
									employees, agents, successors, and assigns) from any and all liability,
									claim, action, loss, damage, or expense (including court costs and
									reasonable attorneys’ fees) based on any claims arising out of, relating
									to, or in any way connected with any dispute you have or claim to have
									with one or more users, whether or not we become or may become involved
									in any resolution or attempted resolution of the dispute. You agree and
									understand that we do not control and are not responsible for
									information you provide to parties other than us.
								</p>
							</li>
						</ul>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Limitation of Liability.</h3>
						<p>
							YOU UNDERSTAND AND AGREE THAT WE WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY
							FOR ANY LOSS OF PROFITS, USE, GOODWILL, OR DATA, OR FOR ANY INCIDENTAL,
							INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, HOWEVER
							ARISING, IN CONNECTION WITH THESE TERMS OF THE USE, PERFORMANCE, OR OPERATION OF
							THE PLATFORM. OUR TOTAL CUMULATIVE LIABILITY TO YOU OR TO ANY THIRD PARTY FROM
							ALL CAUSES OF ACTION AND ALL THEORIES OF LIABILITY, WILL BE LIMITED TO, AND WILL
							NOT EXCEED, THE GREATER OF TOTAL AMOUNTS PAID OR PAYABLE UNDER THESE TERMS OR
							$10,000. This limitation applies whether or not (a) we have been informed of the
							possibility of such damages; (b) a remedy set forth in these Terms is found to
							have failed of its essential purpose; or (c) such claim is based upon contract,
							warranty, tort (including negligence), product liability, or otherwise.
						</p>
					</li>
				</ol>
			</li>
			<li>
				<h2 className='h3 termsTitle' id='generalProvisions'>General Provisions</h2>
				<ol>
					<li>
						<h3 className='h5 termsTitle'>Assignment.</h3>
						<p>
							Neither you or us may assign or transfer any or all of its rights
							or obligations under these Terms or any part of it, nor any benefit or interest in
							or under it, to any third party without the written consent of the other which shall
							not be unreasonably withheld, provided, however, that this provision shall not apply
							where the assignment or transfer is effected by the sale or transfer of assets or of
							a controlling ownership interest in us.
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Dispute Resolution.</h3>
						<p>
							In the event of a dispute, you and we should work
							together in good faith to resolve the dispute. These Terms will be governed by and
							construed in accordance with the laws of the State of Michigan without reference to
							or application of conflict of laws rules or principles. The courts located within
							the jurisdiction of the county of Ingham, Michigan will have exclusive jurisdiction
							to adjudicate any dispute arising out of or relating to these Terms.
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Severability.</h3>
						<p>
							If for any reason a court of competent jurisdiction finds any
							provision of these Terms invalid or unenforceable, that provision of the Terms will
							be enforced to the maximum extent permissible and the other provisions of these
							Terms will remain in full force and effect.
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Waiver.</h3>
						<p>
							No failure or delay by either you or us in exercising your or our
							rights under these Terms shall operate as a waiver of such rights, and no waiver of
							any right shall constitute a waiver of any prior, concurrent, or subsequent right.
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Notices & Electronic Communications.</h3>
						<p>
							You authorize us to communicate
							electronically with you and agree that all Terms, agreements, notices, disclosures,
							and other communications we provide to you electronically satisfy any legal
							requirement that those communications would satisfy if they were on paper. Any
							notice, request, demand, or other communication, if sent to us, will be sent to:
							<a href='mailto:help@interoperabilityinstitute.org'>help@interoperabilityinstitute.org.</a>
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Force Majeure.</h3>
						<p>
							We will not be liable for any failure or delay due to matters
							beyond our reasonable control, including denial-of-service attacks, shortages of or
							inability to obtain labor, energy, raw materials or supplies, war, terrorism, riot,
							acts of God, or governmental action.
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Relationship of Parties.</h3>
						<p>
							You and we are independent contractors and these Terms will not establish any relationship
							of partnership, joint venture, employment, franchise, or agency between you and us. Neither
							you nor us will have the power to bind the other or incur obligations on the other’s behalf
							without the other’s prior written consent.
						</p>
					</li>
					<li>
						<h3 className='h5 termsTitle'>Entire Agreement.</h3>
						<p>
							These Terms, including all referenced documents, constitute
							the complete understanding and agreement of you and us regarding its subject matter
							and supersedes all prior or contemporaneous agreements or understandings, oral or
							written, relating to its subject matter.
						</p>
					</li>
				</ol>
			</li>
		</ol>
	</>)
}
