import * as React from 'react';
import codeSampleActions from 'stores/actionInterfaces/codeSampleActionInterfaces';
import { AppThunkAction } from 'stores';

import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { EUpdateState } from 'stores/shared/ENums';
import { url } from 'settings';
import { IApiDoc, ICodeSample } from 'common/interfaces';
import { TSetErrorMessage } from 'common/types/TSetErrorMessage';
import { TSetFormLoadingAttribute } from 'common/types/TSetFormLoadingAttribute';
import { modalActionCreators } from 'stores/actions/modalActions';
import { DateTimePicker } from '@material-ui/pickers';
import { messageActionCreators } from './messageActions';
import { sharedHistory } from '../../sharedStore';
import { errorMessages } from 'common/data/errorMessages';

export const codeSampleActionCreators = {
	getCodeSamples: (): AppThunkAction<codeSampleActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'GET_CODE_SAMPLES_REQUEST' });
			const headers = await getCurrentAuthHeaders('json');

			try {
				const getCodeSamplesRequest = await fetch(`${url}code_sample`, {
					headers
				});

				const codeSamples: ICodeSample[] = (await getCodeSamplesRequest.json());

				dispatch({
					type: 'GET_CODE_SAMPLES_RECEIVED',
					codeSamples: codeSamples
				});
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},

	getCodeSample: (code_sample_id: number): AppThunkAction<codeSampleActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'GET_CODE_SAMPLE_REQUEST' });
			const headers = await getCurrentAuthHeaders('json');

			try {
				const getCodeSampleRequest = await fetch(`${url}code_sample/${code_sample_id}`, {
					headers
				});
				const codeSample: ICodeSample = await getCodeSampleRequest.json();

				const getCodeSampleApisRequest = await fetch(`${url}code_sample/${code_sample_id}/apis`, {
					headers
				});
				const apis: IApiDoc[] = await getCodeSampleApisRequest.json();

				dispatch({
					type: 'GET_CODE_SAMPLE_RECEIVED',
					codeSample: codeSample,
					apis: apis
				});
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},

		// Create a new code sample
	createCodeSample: (
		codeSample: ICodeSample,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute
	) => {
		return async (dispatch, getState) => {
			dispatch({ type: 'CREATE_CODE_SAMPLE_REQUEST' });
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-type', 'application/json');

				const createCodeSample = await fetch(`${url}code_sample/create`, {
					method: 'POST',
					headers,
					body: JSON.stringify({
						auto_start_stop: false,
						...codeSample
					})
				})

				const createCodeSampleResponse = await createCodeSample.json();
				
				if (createCodeSample.ok === false) {
					setErrorMessage(createCodeSampleResponse.errorMessage);
					setFormLoadingAttribute(false);
					return;
				}

				dispatch(modalActionCreators.dismissModal());
				dispatch({
					type: 'CREATE_CODE_SAMPLE_RESPONSE',
					codeSample
				})
				sharedHistory.push(`/code-sample`);

			} catch (err) {
				console.error(err);
				setErrorMessage(errorMessages.general)
				setFormLoadingAttribute(false);
			}
		}
	},

	// Update an existing code sample
	updateCodeSample: (
		codeSample: Partial<ICodeSample>,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute
	) => {
			return async (dispatch, getState) => {
			dispatch({ type: 'UPDATE_CODE_SAMPLE_REQUEST' });
			const headers = await getCurrentAuthHeaders();
			const codeSampleId = codeSample.id;
			try {
				headers.set('Content-type', 'application/json');
				const updateCodeSample = await fetch(`${url}code_sample/${codeSampleId}`, {
					method: 'PUT',
					headers,
					body: JSON.stringify(
						codeSample
					)
				})

				const updateCodeSampleResponse = await updateCodeSample.json();
				
				if (updateCodeSample.ok === false) {
					setErrorMessage(updateCodeSampleResponse.errorMessage);
					setFormLoadingAttribute(false);
					return;
				}

				dispatch(modalActionCreators.dismissModal());
				dispatch({
					type: 'UPDATE_CODE_SAMPLE_RESPONSE',
					codeSampleId: codeSample.id,
					codeSample
				})
			} catch (err) {
				console.error(err);
				setErrorMessage(errorMessages.general)
				setFormLoadingAttribute(false);
			}
		}
	},

	// Delete a code sample
		deleteCodeSample: (codeSampleId: number): AppThunkAction<codeSampleActions> => {
		return async (dispatch, getState) => {
			try {
				dispatch({ type: 'DELETE_CODE_SAMPLE_REQUEST' });

				const headers = await getCurrentAuthHeaders('json');
				const a = await fetch(`${url}code_sample/${codeSampleId}`, {
					method: 'delete',
					headers
				});
				
				dispatch({
					type: 'DELETE_CODE_SAMPLE_RESPONSE',
					codeSampleId
				});

				//@ts-ignore
				dispatch(modalActionCreators.dismissModal());
				sharedHistory.push(`/code-sample`);
			} catch (e) {
				console.error(e);
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
};
