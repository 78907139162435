import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { push } from 'connected-react-router';
import { authActionCreators } from 'stores/actions/authActions';
import { ApplicationState } from 'stores';

interface IIsTermsAcceptedComponentReduxProps {
	isTermsAccepted: {};
}

const isTermsAcceptedComponentActionCreators = {
	...authActionCreators,
	push
};

export const HasAcceptedTerms = WrappedComponent => {
	class IsTermsAcceptedComponent extends React.Component<
		IIsTermsAcceptedComponentReduxProps &
		ResolveThunks<typeof isTermsAcceptedComponentActionCreators>
	> {
		UNSAFE_componentWillMount() {
			this.props.getTerms();
		}

		componentDidMount() {
			this._checkAndRedirect();
		}

		componentDidUpdate() {
			this._checkAndRedirect();
		}

		_checkAndRedirect() {
			const { isTermsAccepted } = this.props;
			if (isTermsAccepted === false) {
				this.props.push('/agreement');
			}
		}

		render() {
			return (
				<div className="authenticated">
					{this.props.isTermsAccepted ? (
						<WrappedComponent {...this.props} />
					) : null}
				</div>
			);
		}
	}

	const mapStateToProps = (
		state: ApplicationState
	): IIsTermsAcceptedComponentReduxProps => {
		return {
			isTermsAccepted: state.auth.isTermsAccepted
		};
	};

	return connect(
		mapStateToProps,
		isTermsAcceptedComponentActionCreators
	)(IsTermsAcceptedComponent);
};
