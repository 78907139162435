import { clone } from 'ramda';

export const trimAllStringProperties = <T>(formSubmissionObject: T): T => {
	const trimmedFormSubmissionObject = clone(formSubmissionObject);

	Object.keys(trimmedFormSubmissionObject).forEach((key, index) => {
		if (typeof trimmedFormSubmissionObject[key] === 'string') {
			trimmedFormSubmissionObject[key] = trimmedFormSubmissionObject[
				key
			].trim();
		}
	});

	return trimmedFormSubmissionObject;
};
