import * as React from 'react';
import { FormCreateCodeSample } from 'components/Forms/FormCreateCodeSample';
import { IModal } from 'common/interfaces';

export const createCodeSampleModal = (): IModal => ({
  title: 'Add a Code Sample',
  primaryButtonLabel: 'Add Sample',
  description:
    'Please fill out the form below to add a new code sample',
  content: props => (
    <FormCreateCodeSample {...props} />
  )
});
