import React from 'react';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import classes from 'classnames';
import { organizationActionCreators } from 'stores/actions/organizationActions';
import { ICognitoUser, IModal, IOrganization, IUser } from 'common/interfaces';
import { TUserType } from 'common/types/TUserType';
import { getUnixTime } from 'date-fns';
import { TSetErrorMessage } from 'common/types/TSetErrorMessage';
import { TSetFormLoadingAttribute } from 'common/types/TSetFormLoadingAttribute';
import { FormUser } from 'components/Forms/FormUser/FormUser';
import { authActionCreators } from 'stores/actions/authActions';
import { FormAddExistingUser } from 'components/Forms/FormAddExistingUser/FormAddExistingUser';
import { GridContainer, GridItem, GridItemContentAdd } from 'components/Grid';
import { Section } from 'components/Section/Section';
import { modalActionCreators } from 'stores/actions/modalActions';
import { EUpdateState } from 'stores/shared/ENums';
import { IActionOption } from 'common/interfaces/IActionOption';
import { UserType } from 'common/property-list/user-types';
import css from './form-organization-user.module.scss';
import { Button } from 'components/Button/Button';
import { Circle } from 'components/Circle/Circle';

const FormOrganizationUserActionCreators = {
	...organizationActionCreators,
	forgotPassword: authActionCreators.forgotPassword,
	...modalActionCreators,
};

interface FormOrganizationUserReduxProps {
	users: IUser[];
	currentUserType: TUserType;
	currentOrganizationId: number;
	isParentOrganization: boolean;
	organizationUserResendUpdateState: { [username: string]: EUpdateState };
	currentUser: ICognitoUser;
	currentOrganization: IOrganization;
}

export const FormOrganizationUserBase: React.FunctionComponent<
	FormOrganizationUserReduxProps & ResolveThunks<typeof FormOrganizationUserActionCreators>
> = ({
	users,
	currentUserType,
	currentOrganizationId,
	isParentOrganization,
	organizationUserResendUpdateState,
	currentUser,
	currentOrganization,
	addUser,
	createUser,
	updateOrganizationUser,
	deleteUser,
	forgotPassword,
	createModal,
	removeUser,
	resendUserInvite,
}) => {
	const resetUsersPasswordModalContent = () => {
		return <p>Are you sure you want to send a reset password email to the user?</p>;
	};

	const hasUserInviteExpired = (user: IUser): boolean => {
		user.status = 'FORCE_CHANGE_PASSWORD';
		const userUpdatedAtUnixTime = getUnixTime(new Date(user.updated_at));
		const currentDate = new Date();
		const currentUnixTimeInMilliseconds = currentDate.getTime();
		const currentUnixTime = Math.round(currentUnixTimeInMilliseconds / 1000);
		const secondsBetweenNowAndUserUpdateAt = currentUnixTime - userUpdatedAtUnixTime;
		const secondsInSevenDays = 7 * 24 * 60 * 60;
		const hasUserInviteExpired = secondsBetweenNowAndUserUpdateAt > secondsInSevenDays;
		return hasUserInviteExpired;
	};

	const hasUserAcceptedInvite = (user: IUser): boolean => {
		return user.status !== 'FORCE_CHANGE_PASSWORD';
	};

	const disableEditUser = (userType: TUserType, currentUserType: TUserType): boolean => {
		return userType === 'mihin-admin' && currentUserType !== 'mihin-admin';
	};

	const addUserAction = (
		user: IUser,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute
	) => {
		addUser(currentOrganizationId, user, setErrorMessage, setFormLoadingAttribute);
	};

	const createUserAction = (
		user: IUser,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute
	) => {
		createUser(currentOrganizationId, user, setErrorMessage, setFormLoadingAttribute);
	};

	const editUserAction = (
		updatedUser: IUser,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute
	) => {
		updateOrganizationUser(updatedUser, currentOrganizationId, setErrorMessage, setFormLoadingAttribute);
	};

	const resetUsersPassword = (
		username: string,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute
	) => {
		forgotPassword(username, setErrorMessage, setFormLoadingAttribute);
	};

	const createUserModal: IModal = {
		title: 'Create New User',
		content: (props) => <FormUser {...props} isParentOrganization={isParentOrganization} />,
		primaryButtonLabel: 'Create User',
		formAction: createUserAction,
	};

	const addUserModal: IModal = {
		title: 'Add Existing User',
		content: (props) => <FormAddExistingUser {...props} isParentOrganization={isParentOrganization} />,
		primaryButtonLabel: 'Add User',
		formAction: addUserAction,
	};

	return (
		<Section title={`Users${users.length > 0 ? ` (${users.length})` : ''}`}>
			<GridContainer>
				<GridItem type='create' action={(event) => createModal(createUserModal, event)}>
					<GridItemContentAdd label='Create new user' />
				</GridItem>
				<GridItem type='create' action={(event) => createModal(addUserModal, event)}>
					<GridItemContentAdd label='Add existing user' />
				</GridItem>
				{users &&
					users.map((user: IUser, index) => {
						const userResendUpdateState =
							organizationUserResendUpdateState && organizationUserResendUpdateState[user.username]
								? organizationUserResendUpdateState[user.username]
								: EUpdateState.Empty;

						const userActionOptions: IActionOption[] = [
							{
								label: 'Edit User',
								action: (event) => {
									createModal(
										{
											title: 'Edit details',
											content: FormUser,
											primaryButtonLabel: 'Update User',
											defaultFormContent: user,
											formAction: editUserAction,
										},
										event
									);
								},
							},
						];

						if (hasUserAcceptedInvite(user)) {
							userActionOptions.push({
								label: 'Reset Password',
								action: (event) => {
									createModal(
										{
											title: `Reset ${user.username}'s Password`,
											content: resetUsersPasswordModalContent,
											primaryButtonLabel: 'Yes',
											primaryButtonActionWithModalActions: (modalActions) =>
												resetUsersPassword(
													user.username,
													modalActions.setErrorMessage,
													modalActions.setFormLoadingAttribute
												),
										},
										event
									);
								},
							});
						}

						if (
							currentUser.username !== user.username &&
							user.organization_id == currentOrganizationId
						) {
							userActionOptions.push({
								label: 'Delete User',
								action: (event) => {
									createModal(
										{
											title: `Delete ${user.username}'s Account`,
											description: () => <p>Are you sure you want to delete this user account?</p>,
											primaryButtonLabel: 'Delete Account',
											primaryButtonActionWithModalActions: (modalActions) => {
												deleteUser(currentOrganization.id, user.username, modalActions);
											},
											primaryButtonStyle: 'destructive',
										},
										event
									);
								},
								type: 'warning',
							});
						}

						if (
							currentUser.username !== user.username &&
							user.organization_id != currentOrganizationId
						) {
							userActionOptions.push({
								label: 'Remove User',
								action: (event) => {
									createModal(
										{
											title: `Remove ${user.username}'s Account from Current Organization`,
											description: () => (
												<p>
													Are you sure you want to remove this user account from the current
													organization?
												</p>
											),
											primaryButtonLabel: 'Remove Account',
											primaryButtonActionWithModalActions: (modalActions) => {
												removeUser(currentOrganization.id, user.username, modalActions);
											},
											primaryButtonStyle: 'destructive',
										},
										event
									);
								},
								type: 'warning',
							});
						}

						return (
							<GridItem
								key={index}
								contentLevel1={user.username}
								contentLevel2={user.email}
								contentLevel3={UserType[user.user_type]}
								SubContent={() => {
									return (
										<>
											{userResendUpdateState === EUpdateState.Loaded ? (
												<div className={css.userInviteInfo}>Invitation Resent</div>
											) : (
												<>
													{!hasUserAcceptedInvite(user) && (
														<div
															className={classes(css.userInviteInfo, {
																[css.loading]: userResendUpdateState === EUpdateState.Loading,
															})}
														>
															{hasUserInviteExpired(user) && (
																<span className={css.expiredInviteText}>User Invite Expired</span>
															)}{' '}
															<Button
																style='link'
																className={classes({
																	[css.inactiveButton]: userResendUpdateState === EUpdateState.Loading,
																})}
																onClick={
																	userResendUpdateState !== EUpdateState.Loading
																		? () => resendUserInvite(currentOrganizationId, user.username)
																		: null
																}
															>
																Resend Invite
															</Button>
														</div>
													)}
												</>
											)}
										</>
									);
								}}
								isLowContrast={!hasUserAcceptedInvite(user)}
								//Start adding User Actions
								actionOptions={
									!disableEditUser(user.user_type, currentUserType) ? userActionOptions : null
								}
							>
								<Circle isCentered={true} isLowContrast={!hasUserAcceptedInvite(user)}>
									{user.username && user.username[0]}
								</Circle>
							</GridItem>
						);
					})}
			</GridContainer>
		</Section>
	);
};

const mapStateToProps = (state: ApplicationState): FormOrganizationUserReduxProps => {
	const currentOrganization = state.organization.organizations[state.organization.currentOrganization]
		? state.organization.organizations[state.organization.currentOrganization]
		: null;

	return {
		users: state.organization.organizationUsers[state.organization.currentOrganization],
		currentUserType: state.auth.user.attributes['custom:user_type'],
		currentOrganizationId: state.organization.currentOrganization,
		isParentOrganization: currentOrganization
			? typeof currentOrganization.parent_organization_id !== 'number'
			: false,
		organizationUserResendUpdateState:
			state.organization.organizationUserResendUpdateState[state.organization.currentOrganization],
		currentUser: state.auth.user,
		currentOrganization,
	};
};

export const FormOrganizationUser = connect(
	mapStateToProps,
	FormOrganizationUserActionCreators
)(FormOrganizationUserBase);
