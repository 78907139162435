import * as React from 'react';
import { AppFrame, InnerContainer } from 'components/Page';
import { PatientDetailsTab } from './PatientDetailsTab/PatientDetailsTab';
import { PatientAllergyTab } from './PatientAllergyTab/PatientAllergyTab';
import { PatientAddressTab } from './PatientAddressTab/PatientAddressTab';
import { PatientConditionTab } from './PatientConditionTab/PatientConditionTab';
import { PatientMedicationTab } from './PatientMedicationTab/PatientMedicationTab';
import { PatientCoverageTab } from './PatientCoverageTab/PatientCoverageTab';
import { PatientFamilyHistoryTab } from './PatientFamilyHistoryTab/PatientFamilyHistoryTab';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import css from './patient-dashboard.module.scss';
import { PatientDescriptionTab } from './PatientDescription/PatientDescriptionTab';
import { NavLink, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { PersonaBuilderActionCreators } from '../../../../stores/actions/personaBuilder/personaBuilderActions';
import { IPatient } from 'common/interfaces/personaBuilder/IPatient';
import { PatientTitleArea } from 'components/Page/PatientTitleArea/PatientTitleArea';

interface IPatientDetailsRouteProps {
	id?: string
}

interface IPatientDashboardViewReduxProps {
	currentPatient: IPatient;
	personaBuilderDropdowns: {};
	personaBuilderHL7Valuesets: {};
}

const PatientDashboardViewBase: React.FC<IPatientDashboardViewReduxProps &
	RouteComponentProps<IPatientDetailsRouteProps> &
	ResolveThunks<typeof PersonaBuilderActionCreators>> = props => {
	let _submitForm = [];
	const isNew = props.match.params.id === 'create';
	React.useEffect(()=>{
		if (!isNew){
			props.getPatient(props.match.params.id);
			props.getPatientAddresses(props.match.params.id);
			props.getPatientDescription(props.match.params.id);
			props.getPatientDescriptionUseCases(props.match.params.id);
			props.getPatientAllergyIntolerances(props.match.params.id);
			props.getPatientFamilyMembers(props.match.params.id);
			props.getPatientCoverages(props.match.params.id);
			props.getPatientMedications(props.match.params.id);
			props.getPatientConditions(props.match.params.id);
		}
		props.populateDropdowns();
		props.getAllHL7Valuesets();
		_submitForm = [];
	}, []);

	const [loading, setLoading] = React.useState(false);

	const currentPatient = isNew ? {
		first_name: 'Create New Patient',
		last_name: '',
		middle_name: '',
		dob: null,
		home_phone: '',
		mobile_phone: '',
		marital_status_id: null,
		race_id: null,
		gender_id: null,
		ethnicity_id: null,
		language_id: null,
		deceased_flag: '',
		height: '',
		weight: ''
	} : props.currentPatient;

	const navigationItems = [
		{
			label: 'Patient',
			location: `/persona-builder/patient-listing/${props.match.params.id}`,
			route: `/persona-builder/patient-listing/${props.match.params.id}`,
				component: () => <PatientDetailsTab {...props}/>,
			disabled: false
		},
		{
			label: 'Description',
			location: `/persona-builder/patient-listing/${props.match.params.id}/description`,
			route: `/persona-builder/patient-listing/${props.match.params.id}/description`,
			component: PatientDescriptionTab,
			disabled: !currentPatient || isNew
		},
		{
			label: 'Address',
			location: `/persona-builder/patient-listing/${props.match.params.id}/address`,
			route: `/persona-builder/patient-listing/${props.match.params.id}/address`,
			component: PatientAddressTab,
			disabled: isNew
		},
		{
			label: 'Condition',
			location: `/persona-builder/patient-listing/${props.match.params.id}/condition`,
			route: `/persona-builder/patient-listing/${props.match.params.id}/condition`,
			component: PatientConditionTab,
			disabled: true
		},
		{
			label: 'Medication',
			location: `/persona-builder/patient-listing/${props.match.params.id}/medication`,
			route: `/persona-builder/patient-listing/${props.match.params.id}/medication`,
			component: PatientMedicationTab,
			disabled: isNew
		},
		{
			label: 'Allergy',
			location: `/persona-builder/patient-listing/${props.match.params.id}/allergy`,
			route: `/persona-builder/patient-listing/${props.match.params.id}/allergy`,
			component: PatientAllergyTab,
			disabled: isNew
		},
		{
			label: 'Family History',
			location: `/persona-builder/patient-listing/${props.match.params.id}/family-history`,
			route: `/persona-builder/patient-listing/${props.match.params.id}/family-history`,
			component: PatientFamilyHistoryTab,
			disabled: isNew
		},
		{
			label: 'Coverage',
			location: `/persona-builder/patient-listing/${props.match.params.id}/coverage`,
			route: `/persona-builder/patient-listing/${props.match.params.id}/coverage`,
			component: PatientCoverageTab,
			disabled: isNew
		}
	];
	return currentPatient && (
		<AppFrame>
			<InnerContainer>
				<Grid container justify={'center'} alignItems={'flex-end'} className={css.outerGrid}>
					<Grid container xs={12} direction={'row'}>
						<Grid container xs={12} md={2} justify={'center'} className={css.imageContainer}>
							<img className={css.imageAvatar} width={'100%'}
										src={'https://www.sovereignsolutionscorp.com/wp-content/uploads/2018/12/img-avatar-blank.jpg'}/>
						</Grid>
						<Grid container xs={12} md={10}>
							<PatientTitleArea
								patient={currentPatient}
								// saveAction={handleSubmitForm}
							/>
							<ul className={css.tabBar}>
								{navigationItems.map((item, index) => {
									return (
										<li key={index}>
											<NavLink
												exact
												to={item.location}
												onClick={(e)=>{item.disabled && e.preventDefault()}}
												className={item.disabled ? css.disabled : ''}
											>
												{item.label}
											</NavLink>
										</li>
									);
								})}
							</ul>
						</Grid>
					</Grid>
				</Grid>
				<Grid container xs={12}>
					<Grid item xs={12}>
						<Switch>
							{navigationItems.map((item, index) => {
								return (
									<Route
										key={index}
										exact
										path={item.route}
										component={item.component}
									/>
								);
							})}
							<Redirect to='/404'/>
						</Switch>
					</Grid>
				</Grid>
			</InnerContainer>
		</AppFrame>
	);
};

const mapStateToProps = (state: ApplicationState): IPatientDashboardViewReduxProps => {
	return {
		currentPatient: state.personaBuilder.currentPatient,
		personaBuilderDropdowns: state.personaBuilder.dropdowns || {},
		personaBuilderHL7Valuesets: state.personaBuilder.hl7Valuesets || {}
	};
};

export const PatientDashboardView = connect(
	mapStateToProps,
	PersonaBuilderActionCreators
)(PatientDashboardViewBase);
