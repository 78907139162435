export interface ISmartAuthUser {
	id?: number,
	username: string,
	password: string, 
	user_type: number, 
	user_fhir_id: string, 
	created_at?: string
}

export enum SmartAuthUserType {
    Practitioner = 1,
    Patient = 2
}
