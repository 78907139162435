import * as React from 'react';
import { Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { Button } from 'components/Button';
import { Delete, Warning } from '@material-ui/icons';
import { hl7AllergySubstanceValidationSchema } from 'common/validation-schema/personaBuilder/hl7ValuesetSchemas/allergySubstanceSchema';
import { TSetFormLoadingAttribute } from 'common/types/TSetFormLoadingAttribute';
import { TSetErrorMessage } from 'common/types/TSetErrorMessage';
import css from './hl7-valuesets.module.scss';

interface IFormAllergySubstanceReduxProps {
  cancelModalAction?: any;
  deleteAction?: any;
  defaultFormContent?: any;
  formAction?: any;
  setFormLoadingAttribute?: TSetFormLoadingAttribute;
  setErrorMessage?: TSetErrorMessage;
}

interface IComponentState {
  isLoading: boolean;
  confirmDelete: boolean;
}

export class FormAllergySubstance extends React.PureComponent<
IFormAllergySubstanceReduxProps,
  IComponentState> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      confirmDelete: false
    };
  }

  setFormLoadingAttribute = (isLoading: boolean) => {
    this.setState({
      isLoading
    });
  };

  setDeleteConfirmation = (confirmDelete: boolean) => {
    this.setState({
      confirmDelete
    });
  };

  render() {
    let setDeleteConfirmation = this.setDeleteConfirmation;
    let { setFormLoadingAttribute } = this.props;
    const {
      formAction,
      defaultFormContent,
      setErrorMessage,
      cancelModalAction,
      deleteAction
    } = this.props;

    let initialValues = {
      allergy_substance_display: '',
      allergy_substance_snomed: ''
    };
    
    const isEditMode = defaultFormContent ? true : false;

    if (isEditMode) {
      initialValues = defaultFormContent;
    }
    
    if (!setFormLoadingAttribute) {
      setFormLoadingAttribute = this.setFormLoadingAttribute;
    }
    
    return (
      <>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            setFormLoadingAttribute(true);
            if (formAction) {
              formAction(trimAllStringProperties(values), setErrorMessage, setFormLoadingAttribute);
            }
            setSubmitting(false);
          }}
          validationSchema={hl7AllergySubstanceValidationSchema()}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              submitForm,
              isValid
            } = props;
            return (
              <form onSubmit={handleSubmit}>
                {isEditMode ? (<h4>Edit Allergy Substance Value</h4>) : (<h4>Add Allergy Substance Value</h4>)}
                <Grid container className={css.formContainer}>
                  <Grid item xs={12} md={6}>
                    <label>Value Name</label>
                    <input
                      id='allergy_substance_display'
                      type='text'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.allergy_substance_display}
                      maxLength={200} />
                    {errors.allergy_substance_display && touched.allergy_substance_display && (
                      <div className='input-feedback'>{errors.allergy_substance_display}</div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label>SNOMED Code</label>
                    <input
                      id='allergy_substance_snomed'
                      type='text'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.allergy_substance_snomed}
                      maxLength={30} />
                    {errors.allergy_substance_snomed && touched.allergy_substance_snomed && (
                      <div className='input-feedback'>{errors.allergy_substance_snomed}</div>
                    )}
                  </Grid>
                  <Grid item xs={6} md={6}>
                    {isEditMode && <Button type="button" style="link" className={css.deleteConfirmationButton} onClick={() => setDeleteConfirmation(true)}><Delete /> Delete</Button>}
                  </Grid>
                  <Grid item xs={6} md={6} style={{ textAlign: 'right' }}>
                    <Button type="button" style="link" className={css.cancelButton} onClick={cancelModalAction}>Cancel</Button>
                    <Button type="submit" className={css.saveButton}>Save</Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
        {this.state.confirmDelete && 
          <Grid container className={css.deleteConfirmationContainer}>
            <Grid item xs={10} md={10}>
              <div className={css.deleteConfirmationMessage}><Warning /> Are you sure you want to delete <strong>{initialValues.allergy_substance_display}</strong>?</div>
            </Grid>
            <Grid item xs={2} md={2} style={{ textAlign: 'right' }}>
              <Button type="button" className={css.deleteButton} onClick={deleteAction}>Confirm</Button>
            </Grid>
          </Grid>
        }
      </>
    );
  }
}