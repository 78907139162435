import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ProviderActionCreators } from 'stores/actions/personaBuilder/providerActions';
import { useHistory } from 'react-router-dom';
import { ApplicationState } from 'stores';
import { Button, Grid, Card } from '@material-ui/core';
import { ChevronLeft, ArrowDownward } from '@material-ui/icons';
import DataTable from 'react-data-table-component';
import { MuiButton } from 'components/MaterialUI/index';
import classes from 'classnames';
import css from './provider-timeslot-tab.module.scss';
import { IProviderTimeSlot } from 'common/interfaces/personaBuilder/IProviderTimeSlot';
import { convert12hTo24h } from 'common/utils/convert12hTo24h';
const sortIcon = <ArrowDownward />;

interface IProviderDetailsRouteProps {
	id?: string
}

interface IProviderDetailsTabReduxProps {
	currentProvider: any;
	providerTimeSlots: IProviderTimeSlot[];
}

export const ProviderTimeSlotTabBase: React.FC<RouteComponentProps<IProviderDetailsRouteProps> &
IProviderDetailsTabReduxProps &
ResolveThunks<typeof ProviderActionCreators>> = props => {

	let _submitForm = null;
	const { currentProvider, providerTimeSlots } = props;
	const history = useHistory();
	const prevLink = currentProvider ? `/persona-builder/provider-listing/${currentProvider.id}/description` : '/persona-builder/provider-listing';
	const providerTimeSlotData = providerTimeSlots.map(timeslot => {
		return {
			...timeslot,
			provider_time_slot_start_24h: parseInt(convert12hTo24h(timeslot.provider_time_slot_start)),
			provider_time_slot_stop_24h: parseInt(convert12hTo24h(timeslot.provider_time_slot_stop))
		}
	});

	const _bindSubmitForm = (submitForm) => {
		_submitForm = submitForm;
	}

	const handleSubmit = () => {
		if (_submitForm) {
			_submitForm();
		}
	}

  const saveProviderTimeSlot = (values) => {
    
	}
	
	const columns = [
		{
			name: 'Start',
			sortable: true,
			grow: 0,
			cell: row => `${row.provider_time_slot_start}`,
			selector: 'provider_time_slot_start_24h'
		},
		{
			name: 'End',
			sortable: true,
			cell: row => `${row.provider_time_slot_stop}`,
			selector: 'provider_time_slot_stop_24h'
		}
	];

  return (
    <>
			<Grid container className={css.listContainer}>
				<Grid item xs={6}>
					<p>Provider Schedule: <strong>{currentProvider?.provider_schedule}</strong></p>
				</Grid>
				<Grid item xs={6}>
					<p>Time Slot Length: <strong>{currentProvider?.appointment_length}</strong></p>
				</Grid>
				<Grid item xs={12}>
					<h4>Time Slots</h4>
				</Grid>
				<Grid item xs={12}>
					<Card className={css.listCard}>
						<DataTable
							className={css.dataTable}
							columns={columns}
							data={providerTimeSlotData}
							noHeader
							highlightOnHover
							defaultSortField="provider_time_slot_start_24h"
							sortIcon={sortIcon}
							pagination
							paginationRowsPerPageOptions={[10,25,50,100]}
						/>
					</Card>
				</Grid>
			</Grid>
			<Grid container className={css.actionsContainer}>
				<Grid item xs={12} md={6}>
					<Button onClick={() => history.push(`${prevLink}`)}>
						<ChevronLeft className={classes([css.leftIcon], [css.iconSmall])}/>
						Description
					</Button>
				</Grid>
				<Grid item xs={12} md={6} style={{textAlign: 'right'}}>
					<MuiButton variant="contained" className={css.button} disabled={true} onClick={handleSubmit}>
						Save
					</MuiButton>
				</Grid>
			</Grid>
    </>
  )
}

const mapStateToProps = (state: ApplicationState): IProviderDetailsTabReduxProps => {
	return {
		currentProvider: state.personaBuilderProvider.currentProvider,
		providerTimeSlots: state.personaBuilderProvider.providerTimeSlots
	};
};

export const ProviderTimeSlotTab = connect(
	mapStateToProps,
	ProviderActionCreators
)(ProviderTimeSlotTabBase);