import * as React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { IModalForm } from 'common/interfaces';
import { FormLabel } from 'components/FormElements/FormLabel';
import { rofActionCreators } from 'stores/actions/rofActions';
import { connect, ResolveThunks } from 'react-redux';

const formCreateRofBackup = {
	...rofActionCreators,
};

interface IFormCreateRofBackup {
	rofId: number;
}

class FormCreateRofBackupBase extends React.PureComponent<
	IModalForm & ResolveThunks<typeof formCreateRofBackup> & IFormCreateRofBackup
> {
	render() {
		const {
			bindSubmitForm,
			createRofBackup,
			rofId,
			setFormDisabledAttribute,
			setFormLoadingAttribute,
			setErrorMessage,
		} = this.props;
		return (
			<div>
				<Formik
					initialValues={{
						rofBackupDescription: '',
					}}
					onSubmit={(values, { setSubmitting }) => {
						console.log('values', values);

						createRofBackup(rofId, values.rofBackupDescription, {
							setFormDisabledAttribute,
							setFormLoadingAttribute,
							setErrorMessage,
						});

						setSubmitting(false);
					}}
					validationSchema={yup.object()}
				>
					{(props) => {
						const {
							values,
							touched,
							errors,
							dirty,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit,
							handleReset,
							submitForm,
							validateForm,
						} = props;
						if (bindSubmitForm) {
							bindSubmitForm(submitForm);
						}
						return (
							<form onSubmit={handleSubmit}>
								<FormLabel
									htmlFor='rofBackupDescription'
									subLabel='Can use letters, numbers, spaces, or the following special characters: _ . : / = + - @'
								>
									Name
								</FormLabel>
								<input
									type='text'
									name='rofBackupDescription'
									id='rofBackupDescription'
									placeholder='Enter Name'
									value={values.rofBackupDescription}
									onChange={handleChange}
									onBlur={handleBlur}
									className={
										errors.rofBackupDescription && touched.rofBackupDescription
											? 'text-input error'
											: 'text-input'
									}
									maxLength={50}
								/>
								{errors.rofBackupDescription && touched.rofBackupDescription && (
									<div className='input-feedback'>{errors.rofBackupDescription}</div>
								)}
								<input type='submit' />
							</form>
						);
					}}
				</Formik>
			</div>
		);
	}
}

export const FormCreateRofBackup = connect(null, formCreateRofBackup)(FormCreateRofBackupBase);
