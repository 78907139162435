import { AppThunkAction } from '../../index';
import medicationActions from '../../actionInterfaces/personaBuilder/medicationActionInterfaces';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { url } from 'settings';
import { IMedication } from 'common/interfaces/personaBuilder/IMedication';

export const MedicationActionCreators = {
	getAllMedications: (
	): AppThunkAction<medicationActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const getMedications = await fetch(`${url}medications`, {
					method: 'GET',
					headers
				});

				const response = await getMedications.json();

				if (getMedications.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_ALL_MEDICATIONS_RECEIVED',
					medications: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	},

	getMedication: (
		id: string | number
	): AppThunkAction<medicationActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const getMedication = await fetch(`${url}medications/${id}`, {
					method: 'GET',
					headers
				});

				const response = await getMedication.json();

				if (getMedication.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_MEDICATION_RECEIVED',
					medication: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	},

	createMedication: (
		medication: IMedication
	):  AppThunkAction<medicationActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const createMedication = await fetch(`${url}medications/create`, {
					method: 'POST',
					body: JSON.stringify(medication),
					headers
				});

				const response = await createMedication.json();
				if (createMedication.ok === false) {
					return;
				}
				
				return response;

			} catch (e) {
				console.log(e);
			}
		};
	},

	updateMedication: (
		medication: IMedication
	):  AppThunkAction<medicationActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const updateMedication = await fetch(`${url}medications/${medication.id}`, {
					method: 'PUT',
					body: JSON.stringify(medication),
					headers
				});
				if (updateMedication.ok === false) {
					return;
				}
			} catch (e) {
				console.log(e);
			}
		};
	},

	deleteMedication: (
		id: string):  AppThunkAction<medicationActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const deleteMedication = await fetch(`${url}medications/${id}`, {
					method: 'DELETE',
					headers
				});

				if (deleteMedication.ok === false) {
					return;
				}
			} catch (e) {
				console.log(e);
			}
		};
	},
	
	populateMedicationDropdowns:() : AppThunkAction<medicationActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				Promise.all([
						fetch(`${url}medications/medication_forms`, {method: 'GET', headers}),
					]).then(async ([medication_forms]) => {
					const dropdowns = {
						medication_forms: await medication_forms.json(),
					};

					return dispatch({
						type: 'GET_MEDICATION_DROPDOWNS_RECEIVED',
						medication_dropdowns: dropdowns
					});

				});

			} catch (e) {
				console.log(e);
			}
		};
	},
};
