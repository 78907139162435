import * as React from 'react';
import { Formik } from 'formik';
import { IModalForm, SmartAuthUserType } from 'common/interfaces';
import { removePhoneNumberFormatting } from 'common/utils/removePhoneNumberFormatting';
import { userValidationSchema } from 'common/validation-schema/userValidationSchema';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { RequiredIndicator } from 'components/FormElements/RequiredIndicator';
import classes from 'classnames';
import * as yup from 'yup';
import { FormLabel } from 'components/FormElements/FormLabel';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { EUpdateState } from 'stores/shared/ENums';
import { Error } from 'components/Toast/Error';
import { rofActionCreators } from 'stores/actions/rofActions';
import css from './form-create-smart-auth-user.module.scss';
import { debounce } from 'debounce';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { base64 } from 'common/utils/base64';
import AsyncSelect from 'react-select/async';
import { errorMessages } from 'common/data/errorMessages';

export interface ISmartAuthUserForm {
	username: string;
	password: string;
	user_type: number;
	user_fhir_id: string;
}

interface IFormCreateSmartAuthUser {
	rofId: number;
	pitId: number;
}

let initialValues = {
	username: '',
	password: '',
	user_type: SmartAuthUserType.Patient,
	user_fhir_id: null as string,
};

interface IFormCreateSmartAuthUserReduxProps {
	updateState: EUpdateState;
	errorMessage: string;
	currentPitUrl: string;
	currentAuthCredentials: string;
}

interface IFormCreateSmartAuthUserState {
	selectedOption: string;
}

const formCreateSmartAuthUserActionCreators = {
	...rofActionCreators
};

class FormCreateSmartAuthUserBase extends React.PureComponent<
	IModalForm &
		IFormCreateSmartAuthUserReduxProps &
		IFormCreateSmartAuthUser &
		ResolveThunks<typeof formCreateSmartAuthUserActionCreators>
> {
	debouncedFetchPatients;

	constructor(props) {
		super(props);

		this.state = {
			selectedOption: null,
		};

		this.debouncedFetchPatients = debounce((inputValue, callback) => this.fetchPatients(inputValue, callback), 300);
	}

	fetchPatients = async (value, callback) => {
		console.log('fetchPatients', value);
		try {
			const headers = await getCurrentAuthHeaders('json');
			headers.set('Authorization', `Basic ${this.props.currentAuthCredentials}`);
			const patientFetch = await fetch(`${this.props.currentPitUrl}fhir/Patient?name=${value}&_pretty=true`, {
				headers
			});
			const patientResults = await patientFetch.json();
			console.log('patientResults', patientResults);
			console.log('patientResults.entry', patientResults.entry);

			if (patientResults.entry && patientResults.entry.length > 0) {
				const options = patientResults.entry.map(patient => {
					console.log('patient', patient);
					console.log('patient.resource.name', patient.resource.name);
					let patientName = '';
					patient.resource.name[0].given.forEach((name, index) => {
						if (index > 0) {
							patientName = patientName + ' ';
						}
						patientName = patientName + name;
					});

					patientName = `${patientName} ${patient.resource.name[0].family}`;
					const patientId = patient.resource.id;

					console.log('patientName', patientName);
					return {
						label: patientName,
						value: patientId
					};
				});
				console.log('options', options);
				console.log('running callback');
				console.log('callback', callback);
				callback(options);
			} else {
				callback([]);
			}
		} catch (error) {
			this.props.setErrorMessage(errorMessages.general);
		}
	};

	render() {
		const {
			bindSubmitForm,
			formAction,
			defaultFormContent,
			isMultistepModal,
			updateState,
			errorMessage,
			createSmartAuthUser,
			setFormLoadingAttribute,
			setErrorMessage,
			rofId,
			pitId
		} = this.props;
		const isEditMode = defaultFormContent ? true : false;
		if (isEditMode) {
			initialValues = defaultFormContent;
		}

		const customStyles = {
			placeholder: (provided, state) => ({
				...provided,
				paddingLeft: '2rem'
			}),
			input: (provided, state) => ({
				...provided,
				padding: '1.2rem 2rem',
				marginBottom: 0
			}),
			control: (provided, state) => ({
				...provided,
				borderColor: state.isSelected ? '#e6e6e6' : '#e6e6e6',
				borderWidth: '.1rem',
				boxShadow: state.isFocused ? '.1rem #ff7d00' : 'none',
				boxShadowColor: '#ff7d00'
			}),
			menuPortal: base => ({ ...base, zIndex: 9999 })
		};

		return (
			<Formik
				initialValues={initialValues}
				onSubmit={(values, { setSubmitting }) => {
					console.log('Form Submission');
					createSmartAuthUser(
						rofId,
						pitId,
						trimAllStringProperties(values),
						setErrorMessage,
						setFormLoadingAttribute
					);
					setSubmitting(false);
				}}
				validationSchema={yup.object().shape({
				})}
			>
				{props => {
					const {
						values,
						touched,
						errors,
						dirty,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
						handleReset,
						submitForm,
						setFieldValue
					} = props;
					if (bindSubmitForm) {
						bindSubmitForm(submitForm);
					}
					return (
						<form onSubmit={handleSubmit}>
							<FormLabel
								htmlFor='username'
								isRequired={true}
							>
								Username
							</FormLabel>
							<input
								id='username'
								placeholder='Enter a username for this user'
								type='text'
								value={values.username}
								onChange={handleChange}
								onBlur={handleBlur}
								className={
									errors.username && touched.username
										? 'text-input error'
										: 'text-input'
								}
							/>
							{errors.username && touched.username && (
								<div className='input-feedback'>
									{errors.username}
								</div>
							)}

							<FormLabel
								htmlFor='password'
								isRequired={true}
							>
								Password
							</FormLabel>
							<input
								id='password'
								placeholder='Enter a password for this user'
								type='text'
								value={values.password}
								onChange={handleChange}
								onBlur={handleBlur}
								className={
									errors.password && touched.password
										? 'text-input error'
										: 'text-input'
								}
							/>
							{errors.password && touched.password && (
								<div className='input-feedback'>
									{errors.password}
								</div>
							)}

							<FormLabel htmlFor='user_type'>
								User Type
								<RequiredIndicator />
							</FormLabel>
							<select
								id='user_type'
								onChange={handleChange}
								onBlur={handleBlur}
								value={`${values.user_type}`}
								className={
									errors.user_type && touched.user_type
										? 'text-input error'
										: 'text-input'
								}
							>
								<option value='' disabled>
									Please Choose User Type
								</option>
								<option value={SmartAuthUserType.Practitioner}>Practitioner</option>
								<option value={SmartAuthUserType.Patient} selected>Patient</option>
							</select>
							{errors.user_type && touched.user_type && (
								<div className='input-feedback'>{errors.user_type}</div>
							)}

							<FormLabel isRequired={true} htmlFor='user_fhir_id'>
								Patient
							</FormLabel>
							<AsyncSelect
								isClearable
								cacheOptions
								loadOptions={(inputValue, callBack) => {
									console.log('call debouncedFetchPatients');
									this.debouncedFetchPatients(inputValue, callBack);
								}}
								id='user_fhir_id'
								placeholder='Search Patients'
								onChange={option => setFieldValue('user_fhir_id', (option as any).value)}
								onBlur={handleBlur}
								className={classes(
									css.select,
									errors.user_fhir_id && touched.user_fhir_id
										? 'text-input error'
										: 'text-input'
								)}
								styles={customStyles}
								menuPortalTarget={document.body}
							/>
							{errors.user_fhir_id && touched.user_fhir_id && (
								<div className='input-feedback'>{errors.user_fhir_id}</div>
							)}

							<input type='submit' />
						</form>
					);
				}}
			</Formik>
		);
	}
}

const mapStateToProps = (
	state: ApplicationState
): IFormCreateSmartAuthUserReduxProps => {
	const { currentRofId, currentPitId, allRofPits } = state.rof;
	const currentRofDetails = state.rof.allRofDetails[currentRofId];
	const currentPit = allRofPits[currentRofId][currentPitId];
	const currentPitUrl = currentPit.base_url;
	const currentAuthCredentials = currentRofDetails
		? base64(`${currentRofDetails.pit_basic_auth_username}:${currentRofDetails.pit_basic_auth_password}`)
		: null;
	
	return {
		updateState: state.rof.createSmartAppUpdateState,
		errorMessage: state.rof.createSmartAppErrorMessage,
		currentPitUrl: currentPitUrl,
		currentAuthCredentials: currentAuthCredentials
	}
};

export const FormCreateSmartAuthUser = connect(
	mapStateToProps,
	formCreateSmartAuthUserActionCreators
)(FormCreateSmartAuthUserBase);
