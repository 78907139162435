import * as React from 'react';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { EUpdateState } from 'stores/shared/ENums';
import { Link } from 'react-router-dom';
import { Card, Grid } from '@material-ui/core';
import { Button } from 'components/Button';
import {MuiTableHeader} from 'components/MaterialUI/index';
import DataTable from 'react-data-table-component';
import { sharedHistory } from 'sharedStore';
import { IPatient } from 'common/interfaces/personaBuilder/IPatient';
import css from '../../../persona-builder.module.scss';
import { AddCircle, ArrowDownward, Edit } from '@material-ui/icons';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { IModal } from 'common/interfaces';
import { modalActionCreators } from 'stores/actions/modalActions';

const sortIcon = <ArrowDownward />;

interface IPatientListingTabViewReduxProps {
	currentUserType: string;
	patientsTableState: EUpdateState,
	patients: IPatient[]
}

interface IPatientListingTabViewStateProps {
	patientTableExpanded: boolean;
	familyTableExpanded: boolean;
}

const actionCreators = {
	...PersonaBuilderActionCreators,
	...modalActionCreators
};

const exportPersonaModalContent = () => {
	return (
		<p>
			Are you sure you want to export this persona?
			<br />
		</p>
	);
};

const PatientListingTabViewBase: React.FC<IPatientListingTabViewReduxProps &
	ResolveThunks<typeof actionCreators> & 
	IPatientListingTabViewStateProps> = props => {
	const { patients } = props;
	const initialData = patients;
	const [filteredData, setFilteredData] = React.useState(null);
	
	const patientExportOnClickHandler = (patient) => {
		const exportPersonaModal: IModal = {
			title: 'Export Persona',
			content: exportPersonaModalContent,
			primaryButtonLabel: 'Export Persona',
			primaryButtonAction: () => {
				props.exportPatient(patient);
			}
		}
		props.createModal(exportPersonaModal);
	}

	const filterPatients = (param) => {
		if (param.length > 2) {
			let foundData = [];
			initialData.forEach(function(dataElement) {
				["first_name", "last_name"].forEach(function(property) {
					if (dataElement[property].toLowerCase().indexOf(param.toLowerCase()) > -1) {
						foundData.push(dataElement);
					}
				});
			});
			setFilteredData(foundData);
		}
		else if (param.length === 0) {
			setFilteredData(initialData);
		}
	}

	const title = 
		<div className={css.tableTitle}>
			<h4>
				Patients
				<Button style="link" onClick={() => sharedHistory.push(`/persona-builder/patient-listing/create`)}><AddCircle fontSize={'large'}/></Button>
			</h4>
		</div>;

	const columns = [
		{
			name: '',
			cell: row => <Button style="link" onClick={() => sharedHistory.push(`/persona-builder/patient-listing/${row.id}`)}><Edit /></Button>,
			button: true
		},
		{
			name: 'Name',
			sortable: true,
			selector: 'first_name',
			cell: row => `${row.first_name} ${row.last_name}`
		},
		{
			name: 'Description',
			selector: 'description',
			sortable: true
		},
		{
			name: '',
			cell: row => <Button style="link" onClick={
				() => patientExportOnClickHandler(row)
			}>Export to json</Button>,
			button: true
		}
	];
	return (
		<Card className={css.dataTableCard}>
			<DataTable
				className={css.dataTable}
				columns={columns}
				data={filteredData ? filteredData : initialData}
				noHeader
				highlightOnHover
				defaultSortField="first_name"
				sortIcon={sortIcon}
				pagination
				paginationRowsPerPageOptions={[10,25,50,100]}
				subHeader
				subHeaderComponent={(<MuiTableHeader title={title} onSearch={(e) => { filterPatients(e.target.value) }}/>)}
			/>
		</Card>
	);
};

const mapStateToProps = (state: ApplicationState): IPatientListingTabViewReduxProps => {
	return {
		currentUserType: ((state.auth || {}).user || {}).attributes['custom:user_type'],
		patientsTableState: state.personaBuilder.patientTableState,
		patients: state.personaBuilder.patients.filter((f) => {
			return f.persona_story_flag;
		})
	};
};

export const PatientListingTab = connect(
	mapStateToProps,
	actionCreators
)(PatientListingTabViewBase);
