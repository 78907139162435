import { Reducer } from 'redux';
import { NotificationState } from 'stores/stateInterfaces/notificationStateInterface';
import NotificationActions from 'stores/actionInterfaces/notificationActionInterfaces';
import { clone } from 'ramda';
import { EUpdateState } from 'stores/shared/ENums';


const initialState: NotificationState = {
    notificationAlerts: [],
    allNotificationLoadingState: EUpdateState.Empty,
};

export const NotificationReducers: Reducer<NotificationState> = (
    state: NotificationState,
    action: NotificationActions
) => {
    switch (action.type) {

        case 'CREATE_NEW_NOTIFICATION_REQUEST': {
            return {
                ...state,
            };
        }
        case 'CREATE_NEW_NOTIFICATION_RECEIVED': {
            const notifications = clone(state.notificationAlerts);
            const newNotifications = notifications.push(action.notification);

            return {
                ...state,
                notificationAlerts: notifications.reverse(),
            };
        }

        case 'GET_NOTIFICATIONS_REQUEST': {
            return {
                ...state,
                allNotificationLoadingState: EUpdateState.Loading
            };
        }
        case 'GET_NOTIFICATIONS_RECEIVED': {
            const notifications = action.notifications.reverse()

            return {
                ...state,
                notificationAlerts: notifications,
                allNotificationLoadingState: EUpdateState.Loaded
            };
        }
        case 'DELETE_NOTIFICATIONS_REQUEST': {
            return {
                ...state,
            };
        }
        case 'DELETE_NOTIFICATIONS_RECEIVED': {
            const notificationAlerts = [];

            return { ...state, notificationAlerts };
        }
        case 'DELETE_NOTIFICATION_REQUEST': {
            return {
                ...state,
            };
        }
        case 'DELETE_NOTIFICATION_RECEIVED': {

            const notificationAlerts = clone(state.notificationAlerts).filter(notifications => notifications.id != action.notificationId);

            return {
                ...state,
                notificationAlerts
            };
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }
    return state || initialState;
};