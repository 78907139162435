import * as React from 'react';
import css from './skip-nav.module.scss';

export const SkipNav: React.FC = () => {
	return (
		<a tabIndex={0} className={css.skipNav} href='#skipNavLocation'>
			Skip Nav
		</a>
	);
};
