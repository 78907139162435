import * as React from 'react';
import { AppFrame } from 'components/Page/AppFrame';
import { InnerContainer, PageTitleArea } from 'components/Page';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { modalActionCreators } from 'stores/actions/modalActions';
import { organizationActionCreators } from 'stores/actions/organizationActions';
import { ApplicationState } from 'stores';
import { IOrganization, IUser } from 'common/interfaces';
import { EUpdateState } from 'stores/shared/ENums';
import css from './organization-view.module.scss';
import classes from 'classnames';
import { IModal } from 'common/interfaces/IModal';
import { FormOrganization } from 'components/Forms/FormOrganization';
import { push } from 'connected-react-router';
import { Button } from 'components/Button';
import { IRof } from 'common/interfaces/IRof';
import { Panel, PanelContentSplit } from 'components/Panel';
import { convertIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';
import { clone } from 'ramda';
import { TUserType } from 'common/types/TUserType';
import { TSetErrorMessage } from 'common/types/TSetErrorMessage';
import { TSetFormLoadingAttribute } from 'common/types/TSetFormLoadingAttribute';
import { Loading } from 'components/Loading';
import { ReactComponent as IconExternalLink } from 'assets/icons/icon-external-link.svg';
import { IEntitlement } from 'common/interfaces/IEntitlement';
import { IEntitlementSummary } from 'common/interfaces/IEntitlementSummary';
import { FormDeleteOrg } from 'components/Forms/FormDeleteOrg';
import { OrgSandboxStatus } from 'components/SandboxStatus/SandboxStatus';
import { FormOrganizationUser } from 'components/Forms/FormOrganizationUser/FormOrganizationUser';
import { FormOrganizationRof } from 'components/Forms/FormOrganizationRof/FormOrganizationRof';
import { FormOrganizationSuborg } from 'components/Forms/FormOrganizationSuborg/FormOrganizationSuborg';
import { rofActionCreators } from 'stores/actions/rofActions';


const OrganizationViewActionCreators = {
	...modalActionCreators,
	push,
	...organizationActionCreators,
	...rofActionCreators,
};

interface OrganizationRouteProps {
	id?: string;
}

interface IOrganizationReduxProps {
	currentUpdateState: EUpdateState;
	currentOrganization: IOrganization;
	currentUsersLoadingState: EUpdateState;
	users: IUser[];
	currentUserOrganizationId: number;
	currentOrganizationRof: IRof[];
	currentOrganizationRofLoadingState: EUpdateState;
	subOrganizations: IOrganization[];
	currentOrganizationId: number;
	currentUserType: TUserType;
	currentUserOrganization: number;
	parentOrganization: IOrganization;
	isParentOrganization: boolean;
	isCurrentUsersOrganization: boolean;
	entitlements: IEntitlement[];
	entitlementsLoadingState: EUpdateState;
	entitlementsSummary: IEntitlementSummary;
	entitlementsSummaryLoadingState: EUpdateState;
	subOrganizationsRofs: IRof[];
	parentOrganizationName: string;
}

class OrganizationViewBase extends React.PureComponent<
	RouteComponentProps<OrganizationRouteProps> &
	ResolveThunks<typeof OrganizationViewActionCreators> &
	IOrganizationReduxProps
> {
	componentDidMount() {
		const organizationId = this.props.match.params.id
			? Number(this.props.match.params.id)
			: this.props.currentUserOrganizationId;

		this.props.getOrganizations();
		this.props.setCurrentOrganization(organizationId);

		this.getViewInformation(organizationId);
		this.props.getParentOrgName(organizationId)

	}

	componentDidUpdate(prevProps) {
		const organizationId = this.props.match.params.id
			? Number(this.props.match.params.id)
			: this.props.currentUserOrganizationId;

		if (
			this.props.currentUserOrganizationId !== prevProps.currentUserOrganizationId &&
			this.props.currentUserOrganizationId
		) {
			this.props.setCurrentOrganization();
			this.getViewInformation(organizationId);
			window.scrollTo(0, 0);
		}

		if (prevProps.currentOrganizationId != organizationId) {
			this.props.setCurrentOrganization(organizationId);
			this.getViewInformation(organizationId);
			window.scrollTo(0, 0);
		}

		if (this.props.currentOrganization && this.props.currentOrganization.parent_organization_id) {
			if (this.props.currentUserType === "mihin-admin") {
				this.props.getOrganization(this.props.currentOrganization.parent_organization_id);
			}
		}
	}

	getViewInformation = (organizationId: number) => {
		const { currentOrganizationId } = this.props;
		organizationId = currentOrganizationId ? currentOrganizationId : organizationId;
		if (this.props.currentOrganization && this.props.currentOrganization.parent_organization_id) {
			if (this.props.currentUserType === "mihin-admin") {
				this.props.getOrganization(this.props.currentOrganization.parent_organization_id);
			} else {
				this.props.getParentOrgName(this.props.currentOrganization.id)
			}

		}
		this.props.getOrganization(organizationId);
		this.props.getSuborganizations(organizationId);
		this.props.getOrganizationRof(organizationId);
		if (this.props.currentUserType !== "org-user") {
			this.props.getOrganizationUsers(organizationId);
		}
	};

	editDetailsAction = (
		organization: IOrganization,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute
	) => {
		this.props.updateOrganization(organization, setErrorMessage, setFormLoadingAttribute);
	};

	deleteOrganizationAction = () => {
		this.props.deleteOrganization(this.props.currentOrganizationId);
	};

	get breadcrumbItems() {
		const {
			currentOrganization,
			parentOrganization,
			currentUserType,
			isParentOrganization,
			parentOrganizationName,
		} = this.props;

		if (currentOrganization) {
			const allOrganizationsBreadcrumbItem = currentUserType === 'mihin-admin' ? {
				label: 'All organizations',
				location: '/organization-management',
			} : {
				label: 'All organizations',
				location: ``,
			}

			const currentOrganizationBreadcrumbItem = {
				label: currentOrganization.name,
				location: `/organization/${currentOrganization.id}`,
			};

			const parentOrganizationBreadcrumbItem = parentOrganization ? {
				label: parentOrganization.name,
				location: `/organization/${parentOrganization.id}`,
			} : {
				label: parentOrganizationName,
				location: ``,
			};

			if (currentUserType === 'mihin-admin') {
				if (isParentOrganization) {
					return [allOrganizationsBreadcrumbItem, currentOrganizationBreadcrumbItem];
				} else {
					if (parentOrganization) {
						return [
							allOrganizationsBreadcrumbItem,
							parentOrganizationBreadcrumbItem,
							currentOrganizationBreadcrumbItem,
						];
					}
				}
			} else {
				if (isParentOrganization) {
					return [allOrganizationsBreadcrumbItem, currentOrganizationBreadcrumbItem];
				}
				else {
					return [parentOrganizationBreadcrumbItem, currentOrganizationBreadcrumbItem];
				}
			}
		} else {
			return null;
		}
	}

	isLoading = (currentUserType): boolean => {
		const { currentUpdateState, currentUsersLoadingState, currentOrganization } = this.props;

		if (currentUserType !== 'org-user') {
			return !(
				(currentUpdateState === EUpdateState.Loaded || currentUpdateState === EUpdateState.Updating) &&
				currentUsersLoadingState === EUpdateState.Loaded &&
				!!currentOrganization
			);
		} else {
			return !(
				(currentUpdateState === EUpdateState.Loaded || currentUpdateState === EUpdateState.Updating) &&
				!!currentOrganization
			);
		}
	};


	render() {
		const {
			currentOrganization,
			currentUserType,
			users,
			currentOrganizationRof,
			currentOrganizationId,
			isParentOrganization,
			isCurrentUsersOrganization,
			entitlements,
			entitlementsLoadingState,
			entitlementsSummary,
			entitlementsSummaryLoadingState,
			subOrganizationsRofs,
			createModal,
		} = this.props;

		const orgRofs = [...currentOrganizationRof, ...subOrganizationsRofs]

		const hasDeletionRole = (isParentOrg, userType) => {
			if (isParentOrg) {
				return ['mihin-admin'].includes(userType)
			}

			return ['mihin-admin', 'org-admin'].includes(userType)
		}

		const isRofLoading = (orgRofs) => {
			const disableDeleteStatus = [
				"iol-db-backup-start",
				"iol-db-restore-start",
				"iol-db-reset-start"
			]

			return orgRofs.some(orgRof => {
				return disableDeleteStatus.includes(orgRof.operation_status)
			})
		}

		const deleteOrgLabelString = `Delete ${currentOrganization?.parent_organization_id &&
			currentOrganization?.parent_organization_id !== currentOrganization?.id
			? 'Suborganization'
			: 'Organization'
			}`;

		const editDetailsModal: IModal = {
			title: 'Edit Details',
			content: (props) => (
				<FormOrganization
					parentOrganizationId={isParentOrganization ? null : currentOrganizationId}
					{...props}
				/>
			),
			primaryButtonLabel: 'Update Details',
			defaultFormContent: currentOrganization,
			formAction: this.editDetailsAction,
		};

		const deleteOrganizationModal: IModal = {
			title: deleteOrgLabelString,
			content: FormDeleteOrg,
			primaryButtonLabel: deleteOrgLabelString,
			formAction: this.deleteOrganizationAction,
			confirmationString: currentOrganization ? currentOrganization.name : null,
			defaultFormContent: isParentOrganization
		};

		if (this.isLoading(currentUserType)) {
			return (
				<AppFrame>
					<Loading />
				</AppFrame>
			);
		}

		return (
			<AppFrame>
				<InnerContainer>
					<OrgSandboxStatus
						currentOrganizationRof={currentOrganizationRof}
						subOrganizationRof={subOrganizationsRofs}>
					</OrgSandboxStatus>
					{currentUserType !== 'org-user' ? (
						<PageTitleArea
							breadcrumbItems={this.breadcrumbItems}
							buttonList={
								!isParentOrganization
									? [
										{
											buttonLabel: 'Edit Suborganization',
											buttonAction: (event) => createModal(editDetailsModal, event),
											style: 'secondary',
										},
									]
									: null
							}
						>
							{currentOrganization.name}
						</PageTitleArea>
					) : (
						<PageTitleArea
							breadcrumbItems={this.breadcrumbItems}
						>
							{currentOrganization.name}
						</PageTitleArea>
					)}
					{isParentOrganization && currentUserType !== 'org-user' && (
						<>
							<Panel>
								<div className={css.metaDetails}>
									<div className={css.metaDetailsGroup}>
										<h2 className='h4'>Details</h2>
										<p className='copy'>{currentOrganization.descr}</p>
									</div>
									<div className={css.metaDetailsGroup}>
										<h2 className='h4'>Code</h2>
										<p className='copy'>{currentOrganization.code}</p>
									</div>
									<div className={css.metaDetailsGroup}>
										<Button
											onClick={(event) => createModal(editDetailsModal, event)}
										>
											Edit Details
										</Button>
									</div>
								</div>

								<div className={css.metaDetails}>
									<div className={css.metaDetailsGroup}>
										<h3 className={classes('h5', css.title)}>Organization Address</h3>
										<p>
											{currentOrganization.address}, {currentOrganization.city},{' '}
											{currentOrganization.state}, {currentOrganization.postal_code}
										</p>
									</div>
									<div className={css.metaDetailsGroup}>
										<h3 className={classes('h5', css.title)}>Organization Contact</h3>
										<ul className={css.contactInformation}>
											<li>{currentOrganization.contact_name}</li>
											<li>{currentOrganization.contact_email}</li>
											<li>{currentOrganization.contact_phone}</li>
										</ul>
									</div>
								</div>
							</Panel>
							{isCurrentUsersOrganization &&
								entitlementsLoadingState === EUpdateState.Loaded &&
								entitlementsSummaryLoadingState === EUpdateState.Loaded &&
								entitlementsSummary.rof_purchased !== null && (
									<Panel>
										<div className={css.metaDetails}>
											<div className={css.metaDetailsGroup}>
												<h2 className='h4'>Purchased Entitlements</h2>
											</div>

											<div className={css.metaLink}>
												<a
													href='https://console.aws.amazon.com/marketplace/home/#/subscriptions'
													target='_BLANK'
												>
													Manage Entitlements in Amazon Marketplace <IconExternalLink />
												</a>
											</div>
										</div>
										<div className={css.metaDetails}>
											<div className={css.metaDetailsGroup}>
												<h3 className={classes('h5', css.title)}>IOL Ring (12 connected IOL PITs)</h3>
												<p>
													{entitlements.find((entitlement) => {
														return entitlement.dimension === 'FHIR_Ring';
													})
														? entitlements.find((entitlement) => {
															return entitlement.dimension === 'FHIR_Ring';
														}).units
														: 0}{' '}
													Purchased
												</p>
											</div>
											<div className={css.metaDetailsGroup}>
												<h3 className={classes('h5', css.title)}>IOL PIT (A single server)</h3>
												<p>
													{entitlements.find((entitlement) => entitlement.dimension === 'Single_Server')
														? entitlements.find(
															(entitlement) => entitlement.dimension === 'Single_Server'
														).units
														: 0}{' '}
													Purchased
												</p>
											</div>
										</div>
									</Panel>
								)}
						</>
					)}
					{currentUserType !== 'org-user' && users && (
						<FormOrganizationUser ></FormOrganizationUser>
					)}
					<FormOrganizationRof></FormOrganizationRof>
					{isParentOrganization && (
						<FormOrganizationSuborg></FormOrganizationSuborg>
					)}
					{hasDeletionRole(isParentOrganization, currentUserType) &&
						(
							<Panel>
								<PanelContentSplit
									title={deleteOrgLabelString}
									ContentLeft={() => {
										return <p>This will permanently delete your data.</p>;
									}}
									ContentRight={() => {
										return (
											<Button
												style='destructive'
												onClick={(event) => createModal(
													deleteOrganizationModal,
													event
												)}
												disabled={isRofLoading(orgRofs)}
											>
												{deleteOrgLabelString}
											</Button>
										);
									}}
								/>
							</Panel>
						)}
				</InnerContainer>
			</AppFrame >
		);
	}
}

const mapStateToProps = (state: ApplicationState): IOrganizationReduxProps => {
	const currentOrganizationId = state.organization.currentOrganization;
	const organizations = state.organization.organizations;

	const currentOrganizationRof = convertIndexedObjectToArray(clone(state.rof.allRof)).filter(
		(ringOfFhir) => ringOfFhir.organization_id === currentOrganizationId
	);

	const currentOrganizationRofLoadingState =
		state.rof.organizationsRofLoadingState[currentOrganizationId];

	const subOrganizations = convertIndexedObjectToArray(clone(organizations)).filter(
		(organization) => organization.parent_organization_id === currentOrganizationId
	);

	let subOrganizationsRofs = []

	for (var i = 0; i < subOrganizations.length; i++) {
		const subOrgRof = convertIndexedObjectToArray(clone(state.rof.allRof)).filter(
			(ringOfFhir) => ringOfFhir.organization_id === subOrganizations[i].id
		);

		subOrganizationsRofs = subOrganizationsRofs.concat(subOrgRof)
	}

	const parentOrganizationId = organizations[currentOrganizationId]
		? organizations[currentOrganizationId].parent_organization_id
		: null;

	const parentOrganization =
		parentOrganizationId && organizations[parentOrganizationId]
			? organizations[parentOrganizationId]
			: null;

	const currentOrganization = state.organization.organizations[state.organization.currentOrganization]
		? state.organization.organizations[state.organization.currentOrganization]
		: null;

	const currentUser = state.auth.user;

	const isCurrentUsersOrganization = currentUser.attributes.organizationId === currentOrganizationId;

	const currentUserType = currentOrganization ? currentOrganization.user_type : state.auth.user.attributes['custom:user_type']

	return {
		currentUpdateState: state.organization.organizationLoadingState[
			state.organization.currentOrganization
		]
			? state.organization.organizationLoadingState[state.organization.currentOrganization]
			: EUpdateState.Empty,
		currentOrganization,
		currentUsersLoadingState: state.organization.organizationsUsersLoadingState[
			state.organization.currentOrganization
		]
			? state.organization.organizationsUsersLoadingState[state.organization.currentOrganization]
			: EUpdateState.Empty,
		users: state.organization.organizationUsers[state.organization.currentOrganization],
		currentUserOrganizationId: state.auth.user.attributes.organizationId,
		currentOrganizationRof,
		currentOrganizationRofLoadingState,
		subOrganizations,
		currentOrganizationId: state.organization.currentOrganization,
		currentUserType: currentUserType,
		currentUserOrganization: state.auth.user.attributes.organizationId,
		parentOrganization,
		isParentOrganization: currentOrganization
			? typeof currentOrganization.parent_organization_id !== 'number'
			: false,
		isCurrentUsersOrganization,
		entitlements: state.auth.entitlements,
		entitlementsLoadingState: state.auth.entitlementsLoadingState,
		entitlementsSummary: state.auth.entitlementSummary,
		entitlementsSummaryLoadingState: state.auth.entitlementSummaryLoadingState,
		subOrganizationsRofs: subOrganizationsRofs,
		parentOrganizationName: state.organization.parentOrganizationName
	};
};

export const OrganizationView = connect(
	mapStateToProps,
	OrganizationViewActionCreators
)(OrganizationViewBase);
