import { Reducer } from 'redux';

import OrganizationActions from 'stores/actionInterfaces/organizationActionInterfaces';
import { OrganizationState } from 'stores/stateInterfaces/organizationStateInterface';

import { clone, pipe, update, reject, isNil, mergeDeepRight } from 'ramda';
import { EUpdateState } from 'stores/shared/ENums';
import { convertArrayToIndexedObject } from 'common/utils/convertArrayToIndexedObject';

const initialState: OrganizationState = {
	allOrganizationsLoadingState: EUpdateState.Empty,
	currentOrganization: null,
	organizationLoadingState: {},
	organizationSuborganizationLoadingState: {},
	organizations: {},
	organizationUsers: {},
	organizationsUsersLoadingState: {},
	organizationUserResendUpdateState: {},
	parentOrganizationName: null,
};

export const OrganizationReducers: Reducer<OrganizationState> = (
	state: OrganizationState,
	action: OrganizationActions
) => {
	switch (action.type) {
		case 'CREATE_NEW_ORGANIZATION_REQUEST': {
			return {
				...state
			};
		}

		case 'CREATE_NEW_ORGANIZATION_RECEIVED': {
			const organizations = clone(state.organizations);
			const createdOrganization = action.organization;
			createdOrganization['created_at'] = new Date().toDateString();
			// organizations.push(createdOrganization);
			organizations[createdOrganization.id] = createdOrganization;
			return {
				...state,
				organizations
			};
		}

		case 'GET_ORGANIZATIONS_REQUEST': {
			return {
				...state,
				allOrganizationsLoadingState: EUpdateState.Loading
			};
		}

		case 'GET_ORGANIZATIONS_RECEIVED': {
			const organizations = convertArrayToIndexedObject(action.organizations);
			const currentOrganizationLoadingState = clone(state.organizationLoadingState);
			const organizationLoadingState = action.organizations.reduce(
				(organizationObject, organization) => {
					organizationObject[organization.id] = EUpdateState.Loaded;
					return organizationObject;
				},
				currentOrganizationLoadingState
			);

			console.log('organizationLoadingState', organizationLoadingState);

			return {
				...state,
				allOrganizationsLoadingState: EUpdateState.Loaded,
				organizations: organizations,
				organizationLoadingState
			};
		}

		case 'GET_SUBORGANIZATIONS_REQUEST': {
			const organizationSuborganizationLoadingState = clone(
				state.organizationSuborganizationLoadingState
			);

			organizationSuborganizationLoadingState[action.organizationId] = EUpdateState.Loading;

			return {
				...state,
				organizationSuborganizationLoadingState
			};
		}

		case 'GET_SUBORGANIZATIONS_RECEIVED': {
			const organizationSuborganizationLoadingState = clone(
				state.organizationSuborganizationLoadingState
			);

			organizationSuborganizationLoadingState[action.organizationId] = EUpdateState.Loaded;

			const organizations = {
				...clone(state.organizations),
				...convertArrayToIndexedObject(action.organizations)
			};

			console.log('GET_SUBORGANIZATIONS_RECEIVED organizations', organizations);
			const organizationLoadingState = {
				...clone(state.organizationLoadingState),
				...action.organizations.reduce((accumulator, organization) => {
					accumulator[organization.id] = EUpdateState.Loaded;
					return accumulator;
				}, {})
			};

			console.log('organizationLoadingState', organizationLoadingState);

			return {
				...state,
				organizations,
				organizationLoadingState,
				organizationSuborganizationLoadingState
			};
		}

		case 'GET_PARENT_ORG_NAME_REQUEST': {
			return {
				...state,
			};
		}

		case 'GET_PARENT_ORG_NAME_RECEIVED': {
			return {
				...state,
				parentOrganizationName: action.parentOrgName
			};
		}

		case 'REMOVE_ORGANIZATION_FROM_STATE': {
			const updatedOrganizations = clone(state.organizations);

			delete updatedOrganizations[action.id];

			return {
				...state,
				organizations: updatedOrganizations
			};
		}

		case 'GET_CURRENT_ORGANIZATION_USERS_REQUEST': {
			const updatedOrganizationsUsersLoadingState = clone(state.organizationsUsersLoadingState);

			updatedOrganizationsUsersLoadingState[action.organizationId] = EUpdateState.Loading;

			console.log('updatedOrganizationsUsersLoadingState', updatedOrganizationsUsersLoadingState);

			return {
				...state,
				organizationsUsersLoadingState: updatedOrganizationsUsersLoadingState
			};
		}

		case 'GET_CURRENT_ORGANIZATION_USERS_RECEIVED': {
			const updatedOrganizationUsers = clone(state.organizationUsers);
			const updatedOrganizationsUsersLoadingState = clone(state.organizationsUsersLoadingState);

			const enabledUsers = action.users.filter(user => user.enabled === true);

			updatedOrganizationUsers[action.organizationId] = enabledUsers;
			updatedOrganizationsUsersLoadingState[action.organizationId] = EUpdateState.Loaded;

			return {
				...state,
				organizationsUsersLoadingState: updatedOrganizationsUsersLoadingState,
				organizationUsers: updatedOrganizationUsers
			};
		}

		case 'SET_CURRENT_ORGANIZATION': {
			return {
				...state,
				currentOrganization: action.organizationId
			};
		}

		case 'GET_CURRENT_ORGANIZATION_REQUEST': {
			const organizationLoadingState = clone(state.organizationLoadingState);
			organizationLoadingState[action.organizationId] = EUpdateState.Loading;
			return {
				...state,
				organizationLoadingState
			};
		}

		case 'GET_CURRENT_ORGANIZATION_RECEIVED': {
			const updatedOrganizationLoadingState = clone(state.organizationLoadingState);
			updatedOrganizationLoadingState[action.organizationId] = EUpdateState.Loaded;
			const organizations = clone(state.organizations);

			organizations[action.organizationId] = action.organization;

			return {
				...state,
				organizations,
				organizationLoadingState: updatedOrganizationLoadingState
			};
		}

		case 'UPDATE_CURRENT_ORGANIZATION_REQUEST': {
			const updatedOrganizationLoadingState = clone(state.organizationLoadingState);
			updatedOrganizationLoadingState[action.organizationId] = EUpdateState.Updating;
			return {
				...state,
				organizationLoadingState: updatedOrganizationLoadingState
			};
		}

		case 'UPDATE_CURRENT_ORGANIZATION_RECEIVED': {
			const updatedOrganizationLoadingState = clone(state.organizationLoadingState);
			updatedOrganizationLoadingState[action.organizationId] = EUpdateState.Loaded;

			const updatedOrganizations = clone(state.organizations);

			updatedOrganizations[action.organizationId] = action.organization;

			return {
				...state,
				organizationLoadingState: updatedOrganizationLoadingState,
				organizations: updatedOrganizations
			};
		}

		case 'CREATE_USER_REQUEST': {
			return {
				...state
			};
		}

		case 'CREATE_USER_RECEIVED': {
			const organizationUsers = clone(state.organizationUsers);
			organizationUsers[action.organizationId].push(action.user);
			return {
				...state,
				organizationUsers
			};
		}

		case 'ADD_USER_REQUEST': {
			return {
				...state
			};
		}

		case 'ADD_USER_RECEIVED': {
			const organizationUsers = clone(state.organizationUsers);
			organizationUsers[action.organizationId].push(action.user);
			return {
				...state,
				organizationUsers
			};
		}

		case 'UPDATE_ORGANIZATION_USER_REQUEST': {
			return {
				...state
			};
		}

		case 'UPDATE_ORGANIZATION_USER_RECEIVED': {
			const oldOrganizationUsers = clone(state.organizationUsers);
			const userIndexToUpdate = oldOrganizationUsers[action.organizationId].findIndex(
				user => user.username === action.updatedUser.username
			);
			const actionOrganizationId = Number(action.organizationId);
			const updatedOrganizationId = Number(action.updatedUser.organization_id);
			const actionUserType = (action.updatedUser.user_type)


			// If we are changing an user to Super Admin in an organization that is not the organization they were created in,
			// we want to remove the user from the state
			const keepUser = (actionUserType, updatedOrganizationId, actionOrganizationId) => {
				if (actionUserType !== 'mihin-admin') {
					return true
				} else {
					return updatedOrganizationId === actionOrganizationId
				}
			}

			const updatedCurrentOrgUsers = pipe(
				update(
					userIndexToUpdate,
					keepUser(actionUserType, updatedOrganizationId, actionOrganizationId) ? action.updatedUser : null
				),
				reject(isNil)
			)(oldOrganizationUsers[action.organizationId])

			const organizationUsers = { ...oldOrganizationUsers, [action.organizationId]: updatedCurrentOrgUsers }


			return {
				...state,
				organizationUsers
			};
		}

		case 'DELETE_ORGANIZATION_REQUEST': {
			return {
				...state
			};
		}

		case 'DELETE_ORGANIZATION_RECEIVED': {
			const organizations = clone(state.organizations);

			delete organizations[action.organizationId];

			return {
				...state,
				organizations
			};
		}

		case 'CREATE_USER_AND_ORGANIZATION_REQUEST': {
			return {
				...state
			};
		}

		case 'CREATE_USER_AND_ORGANIZATION_RESPONSE': {
			return {
				...state
			};
		}

		case 'RESEND_USER_INVITE_REQUEST': {
			const organizationUserResendUpdateState = clone(state.organizationUserResendUpdateState);

			const updatedState = {
				[action.organizationId]: { [action.username]: EUpdateState.Loading }
			};

			const updatedOrganizationUserResendUpdateState = mergeDeepRight(
				organizationUserResendUpdateState,
				updatedState
			);

			return {
				...state,
				organizationUserResendUpdateState: updatedOrganizationUserResendUpdateState
			};
		}

		case 'RESEND_USER_INVITE_RESPONSE': {
			const organizationUsers = clone(state.organizationUsers);

			const currentOrganizationOrganizationUsers = organizationUsers[action.organizationId];

			const organizationUserArraySlot = currentOrganizationOrganizationUsers.findIndex(
				user => user.username === action.username
			);

			const currentDate = new Date();

			const currentDateString = currentDate.toDateString();

			currentOrganizationOrganizationUsers[organizationUserArraySlot].updated_at = currentDateString;

			organizationUsers[action.organizationId] = currentOrganizationOrganizationUsers;

			const organizationUserResendUpdateState = clone(state.organizationUserResendUpdateState);

			const updatedState = {
				[action.organizationId]: { [action.username]: EUpdateState.Loaded }
			};

			const updatedOrganizationUserResendUpdateState = mergeDeepRight(
				organizationUserResendUpdateState,
				updatedState
			);

			return {
				...state,
				organizationUsers,
				organizationUserResendUpdateState: updatedOrganizationUserResendUpdateState
			};
		}

		case 'DELETE_USER_REQUEST': {
			return {
				...state
			};
		}

		case 'DELETE_USER_RESPONSE': {
			const organizationUsers = clone(state.organizationUsers);

			const usersOrganizationUsers = organizationUsers[action.organizationId].filter(
				user => user.username !== action.username
			);

			return {
				...state,
				organizationUsers: {
					...organizationUsers,
					[action.organizationId]: usersOrganizationUsers
				}
			};
		}

		case 'REMOVE_USER_REQUEST': {
			return {
				...state
			};
		}

		case 'REMOVE_USER_RESPONSE': {
			const organizationUsers = clone(state.organizationUsers);

			const usersOrganizationUsers = organizationUsers[action.organizationId].filter(
				user => user.username !== action.username
			);

			return {
				...state,
				organizationUsers: {
					...organizationUsers,
					[action.organizationId]: usersOrganizationUsers
				}
			};
		}

		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state || initialState;
};
