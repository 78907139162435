import * as yup from 'yup';
import { validatePhoneNumber } from 'common/utils/validatePhoneNumber';

interface IProviderDetailsValidationSchemaConfig {
}

interface IProviderDetailsValidationSchema {
  provider_first_name: string;
  provider_last_name: string;
  dob: any;
  gender_id: number;
  provider_phone: any;
  provider_email: string;
  organization_id: number;
  practice_code_id: number;
  practitioner_role_id: number;
  qualification_id: number;
}

export const providerDetailsValidationSchema = (
	organizationDetailsValidationSchemaConfig: IProviderDetailsValidationSchemaConfig = {}
) => {
  let schemaDefinition = {
    provider_first_name: yup.string().required('First Name is Required'),
    provider_last_name: yup.string().required('Last Name is Required'),
    dob: yup.date().typeError('Date of Birth must be a valid date').required('Date of Birth is Required'),
    gender_id: yup.number().typeError('Gender is invalid.').required('Gender is required.'),
    provider_phone: yup.string().test(validatePhoneNumber).required("Phone Number is Required."),
    provider_email: yup.string().email('Email is not valid').required('Email is Required'),
    organization_id: yup.number().typeError('Organization is invalid.').required('Organization is required.'),
    practice_code_id: yup.number().typeError('Practice Code is invalid.').required('Practice Code is required.'),
    practitioner_role_id: yup.number().typeError('Practitioner Role is invalid.').required('Practitioner Role is required.'),
    qualification_id: yup.number().typeError('Qualification is invalid.').required('Qualification is required.')
  };

  return yup.object().shape<any>(schemaDefinition) as any;
};
