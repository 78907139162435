import * as React from 'react';
import { Button } from 'components/Button';
import { findDOMNode } from 'react-dom';
import css from './button-drop-down.module.scss';
import { ButtonDropDownRof } from 'components/ButtonDropdown/ButtonDropDownRof';
import { TComponent } from 'common/types/TComponent';

interface IButtonDropDownState {
	active: boolean;
}

interface IButtonDropDownProps {
	DropDownContent: any;
	isLoading?: boolean;
	isLoadingLabel?: string;
}
export class ButtonDropDown extends React.PureComponent<
	IButtonDropDownProps,
	IButtonDropDownState
> {
	constructor(props) {
		super(props);
		this.state = {
			active: false
		};
	}

	componentDidMount() {
		if (this.state.active) {
			document.addEventListener('keyup', this.dismissOnEscape, true);
			document.addEventListener('click', this.handleClickOutside, true);
		}
	}

	componentWillUnmount() {
		document.removeEventListener('keyup', this.dismissOnEscape, true);
		document.removeEventListener('click', this.handleClickOutside, true);
	}

	toggleActive = () => {
		this.setActive(!this.state.active);
	};

	dismissOnEscape = event => {
		if (event.keyCode === 27) {
			this.setActive(false);
		}
	};

	handleClickOutside = event => {
		const domNode = findDOMNode(this);

		if (!domNode || !domNode.contains(event.target)) {
			this.setActive(false);
		}
	};

	setActive = (active: boolean) => {
		if (active !== this.state.active) {
			if (active) {
				document.addEventListener(
					'click',
					this.handleClickOutside,
					true
				);
				document.addEventListener('keyup', this.dismissOnEscape, true);
			} else {
				document.removeEventListener(
					'click',
					this.handleClickOutside,
					true
				);
				document.removeEventListener(
					'keyup',
					this.dismissOnEscape,
					true
				);
			}
		}

		this.setState({
			active
		});
	};

	dismissDropDown = () => {
		this.setState({
			active: false
		});
	};

	render() {
		const {
			children,
			DropDownContent,
			isLoading,
			isLoadingLabel
		} = this.props;
		return (
			<div className={css.buttonDropDown}>
				<Button
					style='dropdown'
					isActive={this.state.active}
					onClick={this.toggleActive}
					className={css.button}
					isLoading={isLoading}
					loadingText={isLoadingLabel}
				>
					{children}
				</Button>
				{this.state.active && (
					<div className={css.dropDown}>
						<DropDownContent
							dismissDropDown={this.dismissDropDown}
						/>
					</div>
				)}
			</div>
		);
	}
}
