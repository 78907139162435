import React from 'react';
import classes from 'classnames';

import css from './radio-group.module.scss';

const InputFeedback = ({ error }) =>
	error ? <div className={classes('input-feedback')}>{error}</div> : null;

interface IRadioGroup {
	value?: any;
	error?: any;
	touched?: any;
	id?: any;
	label?: any;
	className?: any;
	children?: any;
}

// Radio group
export const RadioGroup = (props: IRadioGroup) => {
	const { value, error, touched, id, label, className, children } = props;
	const classString = classes(
		'input-field',
		{
			'is-success': value || (!error && touched), // handle prefilled or user-filled
			'is-error': !!error && touched
		},
		className
	);
	return (
		<div className={classString}>
			<fieldset>
				{/* <legend>{label}</legend> */}
				<div className={css.inputGroup}>{children}</div>

				{touched && <InputFeedback error={error} />}
			</fieldset>
		</div>
	);
};
