import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from 'stores';
import { rofActionCreators } from 'stores/actions/rofActions';
import { EUpdateState } from 'stores/shared/ENums';
import { IRofBackup } from 'common/interfaces/IRofBackup';
import {
	TableActionBarTop,
	TableBody,
	TableColumn,
	TableHeader,
	TableRow,
	TableColumnDelete
} from 'components/Table';
import { Button } from 'components/Button';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { Radio } from 'components/FormElements';
import { modalActionCreators } from 'stores/actions/modalActions';
import { FormCreateRofBackup } from 'components/Forms/FormCreateRofBackup';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Warning } from 'components/Warning';
import { Section } from 'components/Section/Section';
import { TRofBackupOperationStatus } from 'common/types/TRofBackupOperationStatus';
import { Message } from 'components/Message';
import css from './rof-backup-tab.module.scss';
import { format } from 'date-fns';
import { Activity } from 'components/Toast/Activity';
import { Loading } from 'components/Loading';
import { IRof } from 'common/interfaces/IRof';

const RofBackupTabActionCreators = {
	...rofActionCreators,
	...modalActionCreators
};

interface IRofBackupTabReduxProps {
	rofBackups: IRofBackup[];
	updateStatus: EUpdateState;
	currentRofId: number;
	userBackupAllotment: number;
	currentRof: IRof;
	isBackupOperationActive: boolean;
}

interface RofBackupRouteProps {
	id?: string;
}

class RofBackupTabBase extends React.PureComponent<
	RouteComponentProps<RofBackupRouteProps> &
		IRofBackupTabReduxProps &
		ResolveThunks<typeof RofBackupTabActionCreators>
> {
	UNSAFE_componentWillMount() {
		this.props.getRofBackups(this.props.currentRofId);
	}

	createBackup = event => {
		console.log('Create Backup');
		this.props.createModal(
			{
				title: 'Create a New Backup',
				description:
					'Save a backup of your sandbox that you can restore to at a later time. You can have maximum of two backups.',
				primaryButtonLabel: 'Create',
				content: props => <FormCreateRofBackup {...props} rofId={this.props.currentRofId} />
			},
			event
		);
	};

	isLoading = (): boolean => {
		return !(this.props.updateStatus === EUpdateState.Loaded);
	};

	render() {
		const {
			rofBackups,
			createModal,
			restoreRofBackup,
			userBackupAllotment,
			currentRofId,
			restoreRofOriginalState,
			deleteRofBackup,
			updateStatus,
			dismissModal,
			currentRof,
			isBackupOperationActive
		} = this.props;

		const formName = 'selectedRofBackup';

		console.log('rofBackups.length', rofBackups.length);

		console.log('rofBackups 1', rofBackups);

		const canCreateNewBackup = rofBackups.length < userBackupAllotment;

		console.log('canCreateNewBackup', canCreateNewBackup);

		if (this.isLoading()) {
			return <Loading />;
		}

		console.log('rofBackups 2', rofBackups);

		const RestoreButton = () => {
			return (
				<Button
					style='destructive'
					type='button'
					disabled={isBackupOperationActive}
					onClick={event =>
						createModal(
							{
								title: `Are you sure you want to reset the Sandbox to its initial state?`,
								description: `This will delete all of the data in the Sandbox and reset it to the initial state.`,
								primaryButtonActionWithModalActions: modalActions => {
									restoreRofOriginalState(currentRofId, modalActions);
								},
								primaryButtonLabel: `Reset Sandbox`,
								primaryButtonStyle: 'destructive'
							},
							event
						)
					}
					isLoading={currentRof.operation_status === 'iol-db-reset-start'}
					loadingText='Resetting...'
				>
					Reset to Initial State
				</Button>
			);
		};

		console.log('rofBackups 3', rofBackups);

		return (
			<div>
				{updateStatus === EUpdateState.Loaded && (
					<>
						{console.log('rofBackups 4', rofBackups)}
						{!canCreateNewBackup && (
							<Warning>
								You’ve reached your limit of 2 backups. Please delete a backup if you would like to make
								a new backup.
							</Warning>
						)}
						{rofBackups.length !== 0 ? (
							<>
								{console.log('rofBackups 5', rofBackups)}
								<Formik
									initialValues={{ selectedRofBackup: null }}
									onSubmit={(values, { setSubmitting }) => {
										console.log('values', values);
										const rofBackupId = values[formName].replace(formName, '');
										console.log('rofBackupId', rofBackupId);

										restoreRofBackup(currentRofId, rofBackupId);
										setSubmitting(false);
									}}
									validationSchema={yup.object().shape({
										selectedRofBackup: yup.string().required('Required')
									})}
								>
									{props => {
										const {
											values,
											touched,
											errors,
											dirty,
											isSubmitting,
											handleChange,
											handleBlur,
											handleSubmit,
											handleReset,
											isValid
										} = props;
										{
											console.log('rofBackups 6', rofBackups);
										}
										return (
											<>
												<form onSubmit={handleSubmit}>
													<TableActionBarTop ContentRight={RestoreButton}>
														<Button
															style='secondary'
															type='submit'
															disabled={!isValid || isBackupOperationActive}
														>
															Restore
														</Button>
														<Button
															type='button'
															disabled={isBackupOperationActive || !canCreateNewBackup}
															onClick={event => this.createBackup(event)}
														>
															Create New Backup
														</Button>
													</TableActionBarTop>
													{console.log('rofBackups 7', rofBackups)}
													<TableBody>
														<TableHeader>
															<TableColumn />
															<TableColumn>Date/Time</TableColumn>
															<TableColumn>Name</TableColumn>
															<TableColumn>Created by</TableColumn>
															<TableColumn />
														</TableHeader>
														{rofBackups &&
															rofBackups.map((rofBackup, index) => {
																const id = `${formName}${rofBackup.id}`;

																return (
																	<TableRow key={index} hover={!isBackupOperationActive}>
																		<TableColumn htmlFor={id}>
																			{rofBackup.id === currentRof.operation_backup_id &&
																			(currentRof.operation_status === 'iol-db-backup-start' ||
																				currentRof.operation_status === 'iol-db-restore-start') ? (
																				<LoadingSpinner size='small' />
																			) : (
																				<Field
																					component={props => <Radio {...props} />}
																					disabled={isBackupOperationActive}
																					name={formName}
																					id={id}
																				/>
																			)}
																		</TableColumn>
																		<TableColumn htmlFor={id}>
																			{format(new Date(rofBackup.date), 'MM/dd/yyyy hh:mm:ss a')}
																		</TableColumn>
																		<TableColumn htmlFor={id}>{rofBackup.name}</TableColumn>
																		<TableColumn htmlFor={id}>{rofBackup.created_by}</TableColumn>
																		<TableColumnDelete
																			onClickModal={{
																				title: `Are you sure you want to delete “${rofBackup.name}”?`,
																				content: () => (
																					<>{'This will permanently delete your backup.'}</>
																				),
																				primaryButtonActionWithModalActions: modalActions => {
																					deleteRofBackup(currentRofId, rofBackup.id, modalActions);
																				},
																				primaryButtonLabel: 'Delete Backup',
																				primaryButtonStyle: 'destructive'
																			}}
																			actionAriaLabel='Delete backup'
																			disabled={isBackupOperationActive}
																			isDark={true}
																		/>
																	</TableRow>
																);
															})}
													</TableBody>
												</form>
											</>
										);
									}}
								</Formik>
							</>
						) : (
							<Message
								title={`Create Your First Backup Point`}
								description={`You are allotted two backup points. You can also reset the sandbox to its initial state. This process can take up to 15 minutes.`}
							>
								<Button
									onClick={event => this.createBackup(event)}
									className={css.firstButtonEmptyScreen}
									disabled={isBackupOperationActive}
								>
									Create Backup
								</Button>
								<RestoreButton />
							</Message>
						)}
					</>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IRofBackupTabReduxProps => {
	const currentRofId = state.rof.currentRofId;
	const currentRof = state.rof.allRof[currentRofId];
	const currentRofOperationStatus = currentRof.operation_status;

	const isBackupOperationActive =
		currentRofOperationStatus === 'iol-db-backup-start' ||
		currentRofOperationStatus === 'iol-db-reset-start' ||
		currentRofOperationStatus === 'iol-db-restore-start';

	const rofBackups = state.rof.allRofBackups[currentRofId] ? state.rof.allRofBackups[currentRofId] : [];

	rofBackups.sort((rofBackupA, rofBackupB) => {
		const dateA = new Date(rofBackupA.date);
		const dateB = new Date(rofBackupB.date);
		return dateB.getTime() - dateA.getTime();
	});

	return {
		rofBackups,
		updateStatus: state.rof.allRofBackupsUpdateStatus[currentRofId],
		currentRofId,
		userBackupAllotment: state.auth.userBackupAllotment,
		currentRof,
		isBackupOperationActive
	};
};

export const RofBackupTab = connect(mapStateToProps, RofBackupTabActionCreators)(RofBackupTabBase);
