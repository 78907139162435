import * as React from 'react';
import { Circle } from 'components/Circle';
import css from './grid-item-content-sandbox.module.scss';
import { IRof } from 'common/interfaces/IRof';
import { Badge } from 'components/Badge';
import { TUserType } from 'common/types/TUserType';

interface IGridItemContentSandbox {
	sandbox: IRof;
	currentUserType: TUserType;
}

export const GridItemContentSandbox: React.FC<IGridItemContentSandbox> = props => {
	const { sandbox, currentUserType } = props;
	return (
		<>
			<div className={css.top}>
				<Circle isCentered={true} icon="rof"></Circle>
			</div>
			<div className={css.content}>
				<h5>{sandbox.name}</h5>
				<Badge>{`${sandbox.fhir_pit_count} PIT${sandbox.fhir_pit_count >= 2 ? 's' : ''}`}</Badge>
				<br></br>
				{currentUserType == 'mihin-admin' &&
					<div className={css.createdBy}>{`Created By: ${sandbox.created_by}`}</div>
				}
			</div>
		</>
	);
};
