import React from 'react';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { convertIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';
import { clone } from 'ramda';
import { modalActionCreators } from 'stores/actions/modalActions';
import { organizationActionCreators } from 'stores/actions/organizationActions';
import { Section } from 'components/Section/Section';
import { GridContainer, GridItem, GridItemContentAdd } from 'components/Grid';
import { Circle } from 'components/Circle/Circle';
import { addSubOrganizationModal } from 'common/modals/addSubOrganizationModal';
import { IOrganization } from 'common/interfaces';


interface FormOrganizationUserReduxProps {
    currentOrganizationId: number;
    subOrganizations: IOrganization[];
}

const FormOrganizationSuborgActionCreators = {
    ...modalActionCreators,
    ...organizationActionCreators,
};

export const FormOrganizationSuborgBase: React.FunctionComponent<FormOrganizationUserReduxProps &
    ResolveThunks<typeof FormOrganizationSuborgActionCreators>> = ({
        subOrganizations,
        currentOrganizationId,
        createModal,
        createOrganization,
    }) => {

        return (
            <Section
                title={`Suborganizations${subOrganizations.length > 0 ? ` (${subOrganizations.length})` : ''
                    }`}
            >
                <GridContainer>
                    <GridItem
                        type='create'
                        action={(event) =>
                            createModal(
                                addSubOrganizationModal(currentOrganizationId, createOrganization),
                                event
                            )
                        }
                    >
                        <GridItemContentAdd label='Add Suborganization' />
                    </GridItem>
                    {subOrganizations &&
                        subOrganizations.length > 0 &&
                        subOrganizations.map((organization, index) => {
                            return (
                                <GridItem
                                    key={index}
                                    contentLevel1={organization.name}
                                    location={`/organization/${organization.id}`}
                                >
                                    <Circle isCentered={true}>{organization.name[0]}</Circle>
                                </GridItem>
                            );
                        })}
                </GridContainer>
            </Section>
        )

    }

const mapStateToProps = (state: ApplicationState): FormOrganizationUserReduxProps => {
    const currentOrganizationId = state.organization.currentOrganization;
    return {
        currentOrganizationId,
        subOrganizations: convertIndexedObjectToArray(clone(state.organization.organizations)).filter(
            (organization) => organization.parent_organization_id === currentOrganizationId
        ),
    }
};

export const FormOrganizationSuborg = connect(
    mapStateToProps,
    FormOrganizationSuborgActionCreators
)(FormOrganizationSuborgBase);