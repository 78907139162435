import * as React from 'react';
import { FormCreateApiDoc } from 'components/Forms/FormCreateApiDoc';
import { IModal } from 'common/interfaces';
import { IApiDoc } from 'common/interfaces';

export const editApiDocModal = (apiDoc: IApiDoc): IModal => ({
  title: 'Update this API Documentation',
  primaryButtonLabel: 'Save Changes',
  description:
    'Please make changes below and press "Save Changes" when finished',
  content: props => (
    <FormCreateApiDoc apiDoc={apiDoc} {...props} />
  )
});
