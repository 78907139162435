import * as React from 'react';
import { AppFrameAuth } from 'components/Auth/AppFrameAuth';
import { connect } from 'react-redux';
import { FormAddUserAndOrganization } from 'components/Forms/FormAddUserAndOrganization';
import { RouteComponentProps } from 'react-router';
const queryString = require('query-string');

class CreateAccountViewBase extends React.PureComponent<RouteComponentProps> {
	render() {
		const getParamCustomer = 'token';

		const customerId =
			this.props.location && this.props.location.search
				? queryString.parse(this.props.location.search)[getParamCustomer]
				: null;

		return (
			<AppFrameAuth
				title='Create Account'
				description='Create an account to access your purchase.'
				backgroundImage='buildingsAndMedicine'
				hasHeader
				Content={props => <FormAddUserAndOrganization {...props} customerId={customerId} />}
			/>
		);
	}
}

export const CreateAccountView = connect()(CreateAccountViewBase);
