export enum EUpdateState {
	Empty = 0,
	Loading = 1,
	Loaded = 1 << 1,
	Updating = 1 << 2,
	Updated = 1 << 3,
	Failed = 1 << 4,
	Busy = Loading | Updating,
	FinishedOk = Loaded | Updated,
	Finished = FinishedOk | Failed
}
