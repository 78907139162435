import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import Dropzone from 'react-dropzone';
import { IModalForm, IPersonaUploadBioDetails } from 'common/interfaces';
import { personaUploadActionCreators } from 'stores/actions/personaUploadActions';
import { ApplicationState } from 'stores';
import { Button } from 'components/Button';
import css from './persona-upload.module.scss';
import { ReactComponent as IconFileArchive } from 'assets/icons/icon-file-archive.svg';
import { ReactComponent as IconRemove } from 'assets/icons/icon-remove.svg';
import LinearProgress from '@material-ui/core/LinearProgress';

const actionCreators = {
	...personaUploadActionCreators,
};

interface personaUploadReduxProps {
	filePreviewUrl: string;
	filename: string;
	filesize: number;
	details: IPersonaUploadBioDetails;
	errorType: string;
	errorMessage: string;
	bindSubmitForm?: (any) => void;
}

enum ScreenState {
    Start,
    ImageSelected,
}

class FormPersonaUploadImageSelectionBase extends React.PureComponent<
	ResolveThunks<typeof actionCreators> &
	personaUploadReduxProps &
	IModalForm
> {
	humanFileSize(size: number) {
		let i = Math.floor( Math.log(size) / Math.log(1024) );
		return (size / Math.pow(1024, i)).toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
	};

	render() {
		const { 
			setFormDisabledAttribute,
			filePreviewUrl,
			filename,
			filesize,
			details,
			errorType,
			errorMessage,
		} = this.props;
		
		let screenState = ScreenState.Start;
		setFormDisabledAttribute(true);
		if (filename) {
			screenState = ScreenState.ImageSelected;
			if (!errorType){
				setFormDisabledAttribute(false);
			}
		}

		return (
		<>
			<div>Drag and drop or browse to a picture for your persona.</div>
		{{
			[ScreenState.Start]: <>
				<Dropzone noClick={true} onDrop={acceptedFiles => { if(acceptedFiles.length > 0) { this.props.selectImage(acceptedFiles[0])} }}>
				{({acceptedFiles, getRootProps, getInputProps, open}) => (
					<div>
					<div {...getRootProps()} className={css.fileDropZone}>
						<input {...getInputProps()} />
						<div className={css.fileDropZoneTitle}>Drag &amp; Drop Image File Here</div>
						<div className={css.fileDropZoneTitleSub}>or</div>
						<Button className={css.fileDropZoneButton} onClick={open}>Browse for File</Button>
					</div>
					</div>
				)}
				</Dropzone>
			</>,
			[ScreenState.ImageSelected]: <>
				<div className={css.fileArea}>
					<IconFileArchive className={css.fileIcon} />
					<div className={css.fileDetails}>
						<span className={css.fileName}>{filename}</span>
						<span>{this.humanFileSize(filesize)}</span>
					</div>
					<div className={css.fileRemove}>
						<button onClick={() => this.props.clearImageState()}>
							<IconRemove />
						</button>
					</div>
				</div>
				<div className={css.filePreview}>
					<span className={css.filePreviewTitle}>Preview:</span>
					<img src={filePreviewUrl}/>
				</div>
			</>,
		 }[screenState]}
		</>
		);
	}
}

const mapStateToProps = (state: ApplicationState): personaUploadReduxProps => {
	return {
		filePreviewUrl: state.personaUpload.imagePreviewUrl,
		filename: state.personaUpload.imageFilename,
		filesize: state.personaUpload.imageFilesize,
		details: state.personaUpload.details,
		errorType: state.personaUpload.imageErrorType,
		errorMessage: state.personaUpload.imageErrorMessage
	};
};

export const FormPersonaUploadImageSelection = connect(
	mapStateToProps,
	actionCreators
)(FormPersonaUploadImageSelectionBase);
