import * as React from 'react';
import { ReactComponent as IconExternalLink } from 'assets/icons/icon-external-link.svg';
import { Formik } from 'formik';
import { connect, ResolveThunks } from 'react-redux';
import { authActionCreators } from 'stores/actions/authActions';
import { ApplicationState } from 'stores';
import { Redirect } from 'react-router';
import { Button } from 'components/Button';
import { SimpleFrame } from 'components/Page';

import css from './accept-terms-of-service.module.scss';
import classes from 'classnames';

const acceptTermsOfServiceActionCreators = {
	...authActionCreators
};

interface IAcceptTermsOfServiceReduxProps {
	isTermsAccepted: boolean;
	isTermsOutdated: boolean;
}

class AcceptTermsOfServiceBase extends React.PureComponent<
	IAcceptTermsOfServiceReduxProps & ResolveThunks<typeof acceptTermsOfServiceActionCreators>
> {
	accept = () => {
		this.props.acceptTermsOfService();
	};
	UNSAFE_componentWillMount() {
		this.props.getTerms();
	}
	render() {
		const { isTermsAccepted, isTermsOutdated } = this.props;
		if (isTermsAccepted) {
			return <Redirect to='/' />;
		}
		return (
			<SimpleFrame className={css.termsAgreement} size={'small'} hideBackLink={true}>
				<h3>Terms of Service</h3>
				{isTermsOutdated ? (
					<h5>Our Terms of Service have recently been updated. Please read and accept the terms.</h5>
				) : (
					''
				)}
				<Formik
					initialValues={{ acceptTermsOfService: false }}
					onSubmit={(values, { setSubmitting }) => {
						if (values.acceptTermsOfService) {
							this.accept();
							setSubmitting(false);
						}
					}}
				>
					{props => {
						const {
							values,
							touched,
							errors,
							dirty,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit,
							handleReset
						} = props;

						return (
							<form onSubmit={handleSubmit}>
								<div className='checkboxWrap'>
									<input
										id='acceptTermsOfService'
										placeholder='acceptTermsOfService'
										type='checkbox'
										checked={values.acceptTermsOfService}
										onChange={handleChange}
										onBlur={handleBlur}
										className={classes(
											errors.acceptTermsOfService && touched.acceptTermsOfService
												? 'text-input error'
												: 'text-input',
											css.checkbox
										)}
									/>
									<label htmlFor='acceptTermsOfService'>
										<span className={css.checkboxLabel}>
											I acknowledge that I have read and agree to the{' '}
											<a href='https://aws.amazon.com/marketplace/pp/B08K3VGH96' target='_BLANK'>
												Terms and Conditions <IconExternalLink />
											</a>
										</span>
									</label>
								</div>
								{errors.acceptTermsOfService && touched.acceptTermsOfService && (
									<div className='input-feedback'>{errors.acceptTermsOfService}</div>
								)}
								<div className='row'>
									<div className={css.acceptDecline}>
										<Button
											type='submit'
											disabled={isSubmitting || values.acceptTermsOfService ? false : true}
										>
											Continue
										</Button>
									</div>
								</div>
							</form>
						);
					}}
				</Formik>
			</SimpleFrame>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IAcceptTermsOfServiceReduxProps => ({
	isTermsAccepted: state.auth.isTermsAccepted,
	isTermsOutdated: state.auth.isTermsOutdated
});

export const AcceptTermsOfService = connect(
	mapStateToProps,
	authActionCreators
)(AcceptTermsOfServiceBase);
