import modalActions from 'stores/actionInterfaces/modalActionInterfaces';
import { AppThunkAction } from 'stores';
import { IModal, IMultistepModal } from 'common/interfaces';
import { THTMLElementEvent } from 'common/types/THTMLElementEvent';
import { TSourceRef } from 'common/types/TSourceRef';
import { clone } from 'ramda';

export const modalActionCreators = {
	createModal: (
		modal: IModal,
		sourceEvent?: THTMLElementEvent<TSourceRef>
	): AppThunkAction<modalActions> => {
		return async (dispatch, getState) => {
			if (sourceEvent) {
				sourceEvent.target.setAttribute('data-isModalSource', '');
			}
			dispatch({ type: 'CREATE_MODAL', modal });
		};
	},
	dismissModal: (
		returnToSource: boolean = true
	): AppThunkAction<modalActions> => {
		if (returnToSource) {
			const sourceElement: HTMLElement = document.querySelector(
				'[data-isModalSource]'
			);

			console.log('sourceElement', sourceElement);
			if (sourceElement) {
				sourceElement.focus();
				sourceElement.removeAttribute('data-isModalSource');
			}
		}

		return async (dispatch, getState) => {
			dispatch({ type: 'DISMISS_MODAL' });
		};
	},
	createMultistepModal: (
		multistepModal: IMultistepModal,
		sourceEvent: THTMLElementEvent<TSourceRef>
	): AppThunkAction<modalActions> => {
		if (sourceEvent) {
			sourceEvent.target.setAttribute('data-isModalSource', '');
		}
		return async (dispatch, getState) => {
			const modalCount = multistepModal.modals.length;
			let multistepModalContent = clone(multistepModal);
			const modals = multistepModalContent.modals.map((modal, index) => {
				modal.isMultistepModal = true;

				if (multistepModalContent.hasProgressLabels) {
					modal['actionAreaLabel'] = `Step ${index +
						1} of ${modalCount}`;
				}

				if (multistepModal.onCancel) {
					modal.onCancel = multistepModal.onCancel;
				}

				const goToNextModal = () =>
					dispatch(
						//@ts-ignore
						modalActionCreators.setCurrentMultistepModalStep(
							index + 1
						)
					);

				//Primary Button Multistep Properties
				if (modalCount != index + 1) {
					if (modal.formActionMultistepModal) {
						modal.formAction = modal.formActionMultistepModal(
							goToNextModal
						);
					} else {
						modal.primaryButtonAction = goToNextModal;
					}
					modal.primaryButtonLabel = 'Next';
				} else {
					if (typeof modal.isForm === 'undefined') {
						modal.isForm = false;
					}
					modal.primaryButtonAction = multistepModal.onFinish;
					modal.primaryButtonLabel = 'Finish';
				}

				//Secondary Button Multistep Properties
				if (index !== 0) {
					modal.secondaryButtonLabel = 'Back';
					modal.secondaryButtonAction = () =>
						dispatch(
							//@ts-ignore
							modalActionCreators.setCurrentMultistepModalStep(
								index - 1
							)
						);
				}
				return modal;
			});
			multistepModalContent.modals = modals;
			dispatch({
				type: 'CREATE_MULTISTEP_MODAL',
				multistepModal: multistepModalContent
			});
			dispatch(
				//@ts-ignore
				modalActionCreators.createModal(multistepModalContent.modals[0])
			);
		};
	},
	setCurrentMultistepModalStep: (
		step: number
	): AppThunkAction<modalActions> => {
		return async (dispatch, getState) => {
			//@ts-ignore
			dispatch(modalActionCreators.dismissModal(false));
			console.log(
				'getState().modal.multistepModal',
				getState().modal.multistepModal
			);
			dispatch(
				//@ts-ignore
				modalActionCreators.createModal(
					getState().modal.multistepModal.modals[step]
				)
			);
			dispatch({ type: 'SET_MULTISTEP_MODAL_STEP', step });
		};
	}
};
