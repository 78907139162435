import React, { useState, useEffect } from 'react';

import { codeSampleActionCreators } from 'stores/actions/codeSampleActions';
import { modalActionCreators } from 'stores/actions/modalActions';
import { apiLibraryActionCreators } from 'stores/actions/apiLibraryActions';

import { ResolveThunks, useSelector, useDispatch } from 'react-redux';
import { Formik, Form, ErrorMessage, Field, FormikActions } from 'formik';
import * as yup from 'yup';
import css from './form-create-code-sample.module.scss';
import { SegmentedController } from 'components/FormElements';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { url } from 'settings';
import { debounce } from 'debounce';
import { debouncedValidateCodeIsUnique } from 'common/utils/validateCodeIsUnique';
import { RequiredIndicator } from 'components/FormElements/RequiredIndicator';
import { IModalForm } from 'common/interfaces';
import { ICodeSample } from 'common/interfaces/ICodeSample';
import { IApiDoc } from 'common/interfaces/IApiDoc';
import { ApplicationState } from 'stores';
import classes from 'classnames';
import { FormLabel } from 'components/FormElements';
import { sharedHistory } from 'sharedStore';
import { clone, sort, pickBy } from 'ramda';
import { EUpdateState } from 'stores/shared/ENums';
import { codeSampleSchema } from './schemas';
import { encode, decode } from 'he';
import { encodeProperties, decodeProperties } from 'common/utils/htmlEntities'
import Select from 'react-select';

// import { map } from 'ramda';

const R = require('ramda');

const formCreateCodeSampleActionCreators = {
  ...codeSampleActionCreators,
  ...modalActionCreators,
  ...apiLibraryActionCreators,
};

interface IFormCreateCodeSampleProps {
  organizationId: number;
  codeSample: ICodeSample;
}

interface IFormCreateCodeSampleReduxProps { }

export const FormCreateCodeSample: React.StatelessComponent<ResolveThunks<typeof formCreateCodeSampleActionCreators> &
  IModalForm &
  IFormCreateCodeSampleProps
> = ({ codeSample, createCodeSample, setErrorMessage, setFormLoadingAttribute, bindSubmitForm }) => {

  const dispatch = useDispatch();

  const prepareOptions = (unprocessedOptions: object) => {
    const opts = R.compose(
      R.map(([apiDocIndex, apiDocOption]) => ({ value: apiDocOption.id, label: apiDocOption.name })),
      R.toPairs
    )(unprocessedOptions)
    return opts;
  }

  const apiDocs = useSelector<ApplicationState>((state: ApplicationState) => {
    const prepared = prepareOptions((state.apiLibrary || {}).allApis);
    return prepared;
  });

  // const apiDocs = null;

  const allApisUpdateState = useSelector <ApplicationState>((state: ApplicationState) => (state.apiLibrary || {}).allApisUpdateState);
      
  useEffect(() => {
    if (allApisUpdateState === EUpdateState.Empty) {
      dispatch(apiLibraryActionCreators.getApiDocs());
    }
  }, [allApisUpdateState, apiDocs, dispatch]);

  const handleFormSubmit = (formData: ICodeSample, { setSubmitting }) => {
    setFormLoadingAttribute(true);
    dispatch(formCreateCodeSampleActionCreators[formData.id ? 'updateCodeSample' : 'createCodeSample'](
      formData, setErrorMessage, setFormLoadingAttribute
    ));
    setSubmitting(false);
  }


  return (
    <div>
      <Formik
        initialValues={{
          name: '',
          descr: '',
          overview: '',
          repo_url: '',
          download_url: '',
          ...codeSample
        }}
        onSubmit={handleFormSubmit}
        validationSchema={codeSampleSchema}
      >
        {(formikProps) => {
          const { values, handleSubmit, submitForm, handleChange, handleBlur, errors, touched } = formikProps;
          if (bindSubmitForm) {
            bindSubmitForm(submitForm)
          }
          return (
            <form onSubmit={handleSubmit}>
              {/* Name */}
              <FormLabel htmlFor='name' subLabel='50 character limit'>
                Code Sample Name
              <RequiredIndicator />
              </FormLabel>
              <input
                type='text'
                name='name'
                id='new_code_sample_form_input_name'
                placeholder='The name of the sample'
                value={decode(values.name)}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.name && touched.name ? 'text-input error' : 'text-input'}
                maxLength={50}
              />
              <ErrorMessage name="name" component="div" className="input-feedback" />

              {/* Description */}
              <FormLabel htmlFor='descr' subLabel='255 character limit'>
                Description
              <RequiredIndicator />
              </FormLabel>
              <input
                type='text'
                name='descr'
                id='new_code_sample_form_input_descr'
                placeholder='A short description of the code sample'
                value={decode(values.descr)}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.descr && touched.descr ? 'text-input error' : 'text-input'}
                maxLength={255}
              />
              <ErrorMessage name="descr" component="div" className="input-feedback" />

              {/* Overview */}
              <FormLabel htmlFor='name'>
                Overview
              <RequiredIndicator />
              </FormLabel>
              <textarea
                rows={5}
                name='overview'
                id='new_code_sample_form_textarea_overview'
                placeholder='A more detailed overview of the code sample'
                value={decode(values.overview)}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.overview && touched.overview ? 'text-input error' : 'text-input'}
              />
              <ErrorMessage name="overview" component="div" className="input-feedback" />

              {/* Repo URL */}
              <FormLabel htmlFor='repo_url'>
                Repository URL
              <RequiredIndicator />
              </FormLabel>
              <input
                type='text'
                name='repo_url'
                id='new_code_sample_form_input_repo_url'
                placeholder='The repository URL for this example'
                value={values.repo_url}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.repo_url && touched.repo_url ? 'text-input error' : 'text-input'}
                maxLength={512}
              />
              <ErrorMessage name="repo_url" component="div" className="input-feedback" />

              {/* Download URL */}
              <FormLabel htmlFor='repo_url'>
                Download URL
              </FormLabel>
              <input
                type='text'
                name='download_url'
                id='new_code_sample_form_input_download_url'
                placeholder='The URL where this sample can be downloaded'
                value={values.download_url}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.download_url && touched.download_url ? 'text-input error' : 'text-input'}
                maxLength={512}
              />
              <ErrorMessage name="download_url" component="div" className="input-feedback" />

              {/* API Library Dropdown */}
              {/* <Select
                isClearable
                cacheOptions
                isMulti
                name="related_apis"
                id='new_code_sample_form_select_apis'
                placeholder='Select APIs'
                options={apiDocs}
                className={classes(
                  css.select,
                  errors.related_apis
                )}
              /> */}
              
              <input type='submit' />
            </form>
          )
        }}
      </Formik>
    </div >
  )
}
