import * as React from 'react';
import { OrganizationDropDownMenu } from '../OrganizationDropDownMenu';
import { connect, ResolveThunks } from 'react-redux';
import { findDOMNode } from 'react-dom';
import classes from 'classnames';
import { ApplicationState } from 'stores';
import { IOrganization } from 'common/interfaces';
import css from './organization-drop-down.module.scss';
import { organizationActionCreators } from 'stores/actions/organizationActions';
import { EUpdateState } from 'stores/shared/ENums';
import { convertIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';


const organizationDropDownActionCreators = {
    ...organizationActionCreators,
};

interface IOrganizationDropDownState {
    orgNavShown: boolean;
}

interface IOrganizationDropDownReduxProps {
    currentOrganization: IOrganization;
    allOrganizationsLoadingState: EUpdateState;
    organizations: IOrganization[];
}

class OrganizationDropDownBase extends React.PureComponent<
    IOrganizationDropDownReduxProps &
    ResolveThunks<typeof organizationDropDownActionCreators>,
    IOrganizationDropDownState
> {
    private thisComponentRef;

    constructor(props) {
        super(props);
        this.state = { orgNavShown: false };
    }

    orgNavToggle = () => {
        this.setState(state => {
            return {
                ...state,
                orgNavShown: !state.orgNavShown
            };
        });
    }

    setOrgNavVisibility = (isVisible: boolean) => {
        this.setState(state => {
            return {
                ...state,
                orgNavShown: isVisible
            };
        });
    };

    isLoading = (): boolean => {
        return !(this.props.allOrganizationsLoadingState === EUpdateState.Loaded);
    };

    componentDidMount() {
        this.thisComponentRef = findDOMNode(this);
        this.props.getOrganizations();
    }

    render() {
        const { currentOrganization } = this.props;
        return (
            <>
                {!this.isLoading() &&
                    <>
                        <div className={classes(css.menuToggle)} onClick={this.orgNavToggle}>
                            {currentOrganization.name}
                        </div>
                        <OrganizationDropDownMenu
                            setVisibility={this.setOrgNavVisibility}
                            isShown={this.state.orgNavShown}
                            ignoreTarget={this.thisComponentRef}
                        />
                    </>
                }
            </>
        );
    }
}

const mapStateToProps = (state: ApplicationState): IOrganizationDropDownReduxProps => {
    const userOrgId = state.auth.user.attributes.organizationId
    const currentOrganizationId = state.organization.currentOrganization in state.organization.organizations ? state.organization.currentOrganization : null

    const currentOrganization = currentOrganizationId ? state.organization.organizations[currentOrganizationId] : state.organization.organizations[userOrgId]
    const organizations = convertIndexedObjectToArray(state.organization.organizations).filter(
        (organization) => typeof organization.parent_organization_id !== 'number'
    );


    return {
        currentOrganization: currentOrganization,
        allOrganizationsLoadingState: state.organization.allOrganizationsLoadingState,
        organizations: organizations
    };
};

export const OrganizationDropDown = connect(
    mapStateToProps,
    organizationDropDownActionCreators
)(OrganizationDropDownBase);
