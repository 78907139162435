import * as React from 'react';
import { IRof } from 'common/interfaces/IRof';
import { GridItem } from '../GridItem/GridItem';
import { connect, ResolveThunks } from 'react-redux';
import { GridItemContentSandbox } from '../GridItemContentSandbox/GridItemContentSandbox';
import { modalActionCreators } from 'stores/actions/modalActions';
import { rofActionCreators } from 'stores/actions/rofActions';
import { deleteRofModal } from 'common/modals/deleteRofModal';
import { ApplicationState } from 'stores';
import { TUserType } from 'common/types/TUserType';

interface IGridItemSandbox {
	sandbox: IRof;
}

const GridItemSandboxBaseActionCreators = {
	...modalActionCreators,
	...rofActionCreators
};

interface IGridItemSandboxBaseReduxProps {
	currentUserType: TUserType;
}

const GridItemSandboxBase: React.FC<
	IGridItemSandbox & IGridItemSandboxBaseReduxProps & ResolveThunks<typeof GridItemSandboxBaseActionCreators>
> = props => {
	const { sandbox, createModal, deleteRof, currentUserType } = props;
	return (
		<GridItem
			location={`/sandbox/${sandbox.id}`}
			actionOptions={
				currentUserType === 'mihin-admin' || currentUserType === 'org-admin'
					? [
						{
							label: 'Delete Sandbox',
							action: event => {
								createModal(deleteRofModal(sandbox, deleteRof), event);
							}
						}
					]
					: null
			}
			hasPadding={false}
		>
			<GridItemContentSandbox sandbox={sandbox} currentUserType={currentUserType} />
		</GridItem>
	);
};

const mapStateToProps = (state: ApplicationState): IGridItemSandboxBaseReduxProps => {
	return {
		currentUserType: state.auth.user.attributes['custom:user_type']
	};
};

export const GridItemSandbox = connect(
	mapStateToProps,
	GridItemSandboxBaseActionCreators
)(GridItemSandboxBase);
