import React, { useState, useEffect } from 'react';
import SwaggerUIReact from 'swagger-ui-react';
import SwaggerUI from 'swagger-ui';
import 'swagger-ui-react/swagger-ui.css';
import { ISmartApp } from 'common/interfaces';
import { setuid } from 'process';
interface ISmartAppSwaggerDocProps {
	smartApp: ISmartApp;
}

const data = (scopes) => ({
	openapi: '3.0.0',
	servers: [
		{
			description: 'SMART on FHIR Application',
			url: 'https://cv7qd33x-sw202.devinteropland.com/five-lakes-health-system/fhir',
		},
	],
	info: {
		version: '1.0.0',
		title: 'SMART on FHIR Application',
		description:
			'SMART on FHIR leverages the OAuth 2.0 protocol for data access authorization. Below are some examples of requests that can be used to access your hosted data using OAuth 2.0 authentication. Additional information on SMART app authorization can be found at <a>http://docs.smarthealthit.org/authorization/</a>',
	},
	security: [
		{
			application: ['read', 'write'],
		},
	],
	paths: {
		'/Patient': {
			get: {
				tags: ['Patient'],
				summary: 'Get all available Patient records for the authenticated user',
				description: 'This is an example operation to show how security is applied to the call.',
				responses: {
					'200': {
						description: 'OK',
					},
				},
			},
			post: {
				tags: ['Patient'],
				summary: 'Create a new patient record',
				description: 'This is an example operation to show how security is applied to the call.',
				responses: {
					'200': {
						description: 'OK',
					},
				},
			},
			put: {
				tags: ['Patient'],
				summary: 'Update an existing patient record',
				description: 'This is an example operation to show how security is applied to the call.',
				responses: {
					'200': {
						description: 'OK',
					},
				},
			},
			delete: {
				tags: ['Patient'],
				summary: 'Delete a patient record',
				description: 'This is an example operation to show how security is applied to the call.',
				responses: {
					'200': {
						description: 'OK',
					},
				},
			},
		},
		'/Encounter': {
			get: {
				tags: ['Encounter'],
				summary: 'Get all encounters for the authenticated user',
				description:
					'This operation shows how to override the global security defined above, as we want to open it up for all users.',
				responses: {
					'200': {
						description: 'OK',
					},
				},
			},
		},
	},
	components: {
		securitySchemes: {
			application: {
				type: 'oauth2',
				flows: {
					authorizationCode: {
						authorizationUrl:
							'https://smart-auth.devinteropland.com/cv7qd33x/sw202/five-lakes-health-system/oauth2/patient/authorize',
						tokenUrl:
							'https://smart-auth.devinteropland.com/cv7qd33x/sw202/five-lakes-health-system/oauth2/token',
						scopes: scopes.split(' ').reduce((acc, curr) => ((acc[curr] = ''), acc), {}),
					},
				},
			},
		},
	},
});

export const SmartAppSwaggerDoc: React.FC<ISmartAppSwaggerDocProps> = (props) => {
	const [swaggerUi, setSwaggerUi] = useState();

	useEffect(() => {
		const {
			client_id: clientId,
			client_secret: clientSecret,
			oauth_server_uri: accessTokenUri,
			redirect_uris: redirectUris,
			oauth_scopes: scopes,
			name: appName,
		} = props.smartApp;

		const ui = SwaggerUI({
			spec: data(scopes),
			dom_id: '#swagger-ui',
			oauth2RedirectUrl: 'https://oauthdebugger.com/debug',
			presets: [SwaggerUI.presets.apis],
			plugins: [
				SwaggerUI.plugins.DownloadUrl,
				SwaggerUI.plugins.AuthActions, // <-- Iam trying to add plugins in order to enable OAuth2 authentication
				SwaggerUI.plugins.AuthIndex,
				SwaggerUI.plugins.AuthReducers,
				SwaggerUI.plugins.AuthSelectors,
				SwaggerUI.plugins.AuthSpecWrapActions,
			],
		});
		const oauth = ui.initOAuth({
			clientId,
			clientSecret,
			realm: 'api',
			authorizationUrl:
				'https://smart-auth.devinteropland.com/cv7qd33x/sw202/five-lakes-health-system/oauth2/patient/authorize',
			tokenUrl:
				'https://smart-auth.devinteropland.com/cv7qd33x/sw202/five-lakes-health-system/oauth2/token',
			redirectUri:
				'http://localhost:3000/sandbox/1211/pit/10344/app/e04ee0a75fc2d41e374d456c60ab29cbaa3a5dd613e034ca29cd774882735518',
			appName,
			scopeSeparator: ' ',
			scopes,
			additionalQueryStringParams: { nonce: Date.now() },
			useBasicAuthenticationWithAccessCodeGrant: true,
		});
		setSwaggerUi(ui);
	}, []);

	const initializeOAuth = () => {
		// Method can be called in any place after calling constructor SwaggerUIBundle
		if (swaggerUi) {
		}
	};

	return (
		<>
			<div id='swagger-ui' />
		</>
	);
};
