import * as yup from 'yup';
import { validatePhoneNumber } from 'common/utils/validatePhoneNumber';

interface IOrganizationDetailsValidationSchemaConfig {
}

interface IOrganizationDetailsValidationSchema {
	organization_name: string;
	address: string;
	city: string;
	state: string;
	country: string;
	postal_code: string;
	phone: any;
	organization_type_id: number;
	pit_id: number;
}

export const organizationDetailsValidationSchema = (
	organizationDetailsValidationSchemaConfig: IOrganizationDetailsValidationSchemaConfig = {}
) => {
	let schemaDefinition = {
		organization_name: yup.string().required('Organization Name is Required'),
		address: yup.string().required('Address is Required'),
		city: yup.string().required('City is Required'),
		state: yup.string().required('State is Required'),
		country: yup.string().required('Country is Required'),
		postal_code: yup.string().required('Postal Code is Required'),
		phone: yup.string().test(validatePhoneNumber).required("Phone Number is Required."),
		organization_type_id: yup.number().typeError('Organization Type is invalid.').required(),
		pit_id: yup.number().typeError('Pit is invalid.').required()
	};

	return yup.object().shape<any>(schemaDefinition) as any;
};
