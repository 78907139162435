import * as React from 'react';
import { Link } from 'react-router-dom';
import css from './page-bread-crumbs.module.scss';
import { TNavigationItems } from 'common/interfaces';

interface IPageBreadcrumbs {
	breadcrumbItems: TNavigationItems;
}

export const PageBreadcrumbs: React.FC<IPageBreadcrumbs> = props => {
	const { breadcrumbItems } = props;
	return (
		<ul className={css.list}>
			{breadcrumbItems &&
				breadcrumbItems.map((item, index) => {
					return (
						<li key={index} className={css.listItem}>
							{breadcrumbItems.length == index + 1 || !item.location ? (
								item.label
							) : (
								<Link to={item.location}>{item.label}</Link>
							)}
						</li>
					);
				})}
		</ul>
	);
};
