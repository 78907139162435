import * as React from 'react';
import css from './activity.module.scss';
import { LoadingSpinner } from 'components/LoadingSpinner';
import classes from 'classnames';

interface IActivityProps {
	className?: string;
}

export const Activity: React.FC<IActivityProps> = props => {
	const { className } = props;
	return (
		<div role='status' className={classes(css.activity, className)}>
			{props.children}
		</div>
	);
};
