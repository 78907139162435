import authActions from 'stores/actionInterfaces/authActionInterfaces';
import { AppThunkAction } from 'stores';

import { Auth } from 'aws-amplify';

import { getCurrentAuthHeaders, getCurrentAuthHeadersNoUserCheck } from 'common/utils/getCurrentAuthHeaders';
import { EUpdateState } from 'stores/shared/ENums';
import { modalActionCreators } from 'stores/actions/modalActions';

import { url } from 'settings';

import { sharedHistory } from '../../sharedStore';
import { IOrganization, IUser, IUserOrg } from 'common/interfaces';

import { organizationActionCreators } from 'stores/actions/organizationActions';
import { errorMessages } from 'common/data/errorMessages';
import { TSetErrorMessage } from 'common/types/TSetErrorMessage';
import { TSetFormLoadingAttribute } from 'common/types/TSetFormLoadingAttribute';
import { messageActionCreators } from './messageActions';
import { TSetSuccessMessage } from 'common/types/TSetSuccessMessage';
import { IFormActions } from 'common/interfaces/IFormActions';
import { LoginLocationState } from 'components/Auth/Login';
import emptyCache from 'common/utils/emptyCache';

export const authActionCreators = {
	signIn: (
		username: string,
		password: string,
		formActions: IFormActions
	): AppThunkAction<authActions> => {
		return async (dispatch, getState) => {
			try {
				const user = await Auth.signIn(username.trim(), password.trim());
				console.log('user', user);
				dispatch({ type: 'LOGIN_SUCCESS', user });
			} catch (error) {
				console.log('signIn error', error);

				formActions.setErrorMessage(error.message);
			}
		};
	},
	forgotPassword: (
		username: string,
		setErrorMessage,
		setLoadingProperty
	): AppThunkAction<authActions> => {
		return (dispatch, getState) => {
			setLoadingProperty(true);
			Auth.forgotPassword(username.trim())
				.then(code => {
					console.log('Forgot Password Code Details', code);
					dispatch({ type: 'FORGOT_PASSWORD_INITIATED', code });

					if (getState().auth.user) {
						//@ts-ignore
						dispatch(modalActionCreators.dismissModal());
					} else {
						sharedHistory.push('/login', {
							successMessage: `A password reset email has been sent to ${username}`
						} as LoginLocationState);
					}
				})
				.catch(error => {
					dispatch({ type: 'FORGOT_PASSWORD_ERROR' });
					setErrorMessage(error.message);
					setLoadingProperty(false);
					console.log(error);
				});
		};
	},
	passwordReset: (username, code, password): AppThunkAction<authActions> => {
		return (dispatch, getState) => {
			console.log('username', username);
			console.log('code', code);
			console.log('password', password);
			Auth.forgotPasswordSubmit(username, code, password)
				.then(passwordResetPromise => {
					console.log('Password Reset', passwordResetPromise);
					dispatch({ type: 'PASSWORD_RESET', passwordResetPromise });
					sharedHistory.push('/login', {
						successMessage: `Your password has been successfully reset. Please login.`
					} as LoginLocationState);
				})
				.catch(error => {
					dispatch({
						type: 'PASSWORD_RESET_ERROR',
						passwordResetError: error.message
					});

					console.log(error);
				});
		};
	},
	updatePassword: (
		user: IUser,
		oldPassword: string,
		newPassword: string,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute,
		setSuccessMessage: TSetSuccessMessage
	): AppThunkAction<authActions> => {
		return async (dispatch, getState) => {
			try {
				const changePasswordRequest = await Auth.changePassword(user, oldPassword, newPassword);
				setSuccessMessage('Your password has been successfully changed.');
				//@ts-ignore
				dispatch(modalActionCreators.dismissModal());
				console.log('changePasswordRequest', changePasswordRequest);
			} catch (error) {
				setFormLoadingAttribute(false);
				setErrorMessage(error.message);
				console.log(error);

			}
		};
	},
	deleteCurrentAccount: (
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute
	): AppThunkAction<authActions> => {
		return async (dispatch, getState) => {
			try {
				const deleteAccountRequest = await getState().auth.user.deleteUser((error, data) => {
					if (!error) {
						//@ts-ignore
						dispatch(authActionCreators.signOut());
					} else {
						setErrorMessage(error.message);
						setFormLoadingAttribute(false);
					}
				});
			} catch (error) {
				console.log(error);
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(error));
			}
		};
	},
	signOut: (): AppThunkAction<authActions> => {
		return (dispatch, getState) => {
			Auth.signOut()
				.then(user => {
					dispatch({ type: 'SIGN_OUT_SUCCESS' });
					emptyCache();
					//@ts-ignore
					dispatch({ type: 'RESET_APP' });
				})
				.catch(err => {
					console.log(err);
					//@ts-ignore
					dispatch(messageActionCreators.createMessage(err));
				});
		};
	},
	getAuth: (shouldForce: boolean = false): AppThunkAction<authActions> => {
		return (dispatch, getState) => {
			if (getState().auth.userUpdateState === EUpdateState.Empty || shouldForce) {
				dispatch({ type: 'GET_AUTH_REQUEST' });
				Auth.currentUserPoolUser()
					.then(user => {
						dispatch({ type: 'GET_AUTH_RECEIVED', user });
					})
					.catch(err => {
						dispatch({
							type: 'GET_AUTH_RECEIVED',
							user: null
						});
						console.log(err);
					});
			}
		};
	},
	getTerms: (): AppThunkAction<authActions> => {
		return async (dispatch, getState) => {
			if (getState().auth.termsUpdateState === EUpdateState.Empty) {
				const termsRequest = await fetch(`${url}terms_of_svc`);

				const terms = await termsRequest.json();

				console.log('terms text', terms);

				const currentIsTermsAccepted = getState().auth.isTermsAccepted;

				console.log('currentIsTermsAccepted', currentIsTermsAccepted);

				if (currentIsTermsAccepted === null && getState().auth.user) {
					dispatch({ type: 'TERMS_STATUS_REQUEST' });
					const headers = await getCurrentAuthHeadersNoUserCheck();
					try {
						const isTermsAccepted = fetch(`${url}user/terms_status`, {
							headers
						});

						isTermsAccepted
							.then(async response => {
								const message = await response.json();

								console.log('tos message', message);

								if (message.terms_accepted === false) {
									dispatch({
										type: 'TERMS_STATUS_RECEIVED',
										isTermsAccepted: false,
										isTermsOutdated: null,
										terms: terms
									});
								} else if (message.errorType === 'TermsOfServiceNotAccepted') {
									dispatch({
										type: 'TERMS_STATUS_RECEIVED',
										isTermsAccepted: false,
										isTermsOutdated: null,
										terms: terms
									});
								} else if (message.errorType === 'TermsOfServiceOutOfDate') {
									console.log(message);
									dispatch({
										type: 'TERMS_STATUS_RECEIVED',
										isTermsAccepted: false,
										isTermsOutdated: true,
										terms: terms
									});
								} else if (message.terms_accepted === true) {
									dispatch({
										type: 'TERMS_STATUS_RECEIVED',
										isTermsAccepted: true,
										isTermsOutdated: false,
										terms: terms
									});
								}
							})
							.catch();
					} catch (e) {
						//@ts-ignore
						dispatch(messageActionCreators.createMessage(e));
					}
				} else {
					dispatch({
						type: 'TERMS_STATUS_RECEIVED',
						isTermsAccepted: null,
						isTermsOutdated: null,
						terms
					});
				}
			}
		};
	},
	acceptTermsOfService: (): AppThunkAction<authActions> => {
		return async (dispatch, getState) => {
			console.log('termAcceptUpdateState 1', getState().auth.termsAcceptUpdateState);
			if (getState().auth.termsAcceptUpdateState === EUpdateState.Empty) {
				dispatch({
					type: 'ACCEPT_TERMS_OF_SERVICE_REQUEST'
				});
				const headers = await getCurrentAuthHeadersNoUserCheck();

				try {
					const latestTermsId = getState().auth.terms.id;

					const isTermsAccepted = await fetch(`${url}user/accept_terms?toc_id=${latestTermsId}`, {
						method: 'POST',
						headers
					});
					console.log('accept request isTermsAccepted', isTermsAccepted);
					await isTermsAccepted.status;
					if (isTermsAccepted.status == 200 || isTermsAccepted.status == 204) {
						dispatch({
							type: 'ACCEPT_TERMS_OF_SERVICE_RECEIVED'
						});
					}
				} catch (e) {
					//@ts-ignore
					dispatch(messageActionCreators.createMessage(e));
				}
			}
		};
	},
	newPasswordRequiredSet: (password: string): AppThunkAction<authActions> => {
		return async (dispatch, getState) => {
			const currentUser = getState().auth.user;

			currentUser.completeNewPasswordChallenge(password, [], {
				onSuccess: result => {
					console.log('completeNewPasswordChallenge result', result);
					//@ts-ignore
					dispatch(authActionCreators.getAuth(true));
				},
				onFailure: error => {
					console.log(error);
					dispatch({
						type: 'NEW_PASSWORD_REQUIRED_ERROR',
						message: error.message
					});
				}
			});
		};
	},
	getUserOrganization: (): AppThunkAction<authActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();

			if (getState().auth.getOrganizationLoadingState !== EUpdateState.Empty) {
				return;
			}

			try {
				dispatch({ type: 'GET_ORGANIZATION_REQUEST' });

				const getUserOrg = await fetch(`${url}user/org`, {
					headers
				});

				const organization: IOrganization = await getUserOrg.json();

				dispatch({ type: 'GET_ORGANIZATION_RECEIVED', organization });
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	getUserOrganizations: (username: string): AppThunkAction<authActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			dispatch({ type: 'GET_USER_ORGANIZATIONS_REQUEST' });
			const encodedUser = encodeURIComponent(encodeURIComponent(username));
			try {
				const getUserOrgsRequest = await fetch(`${url}org/user/${encodedUser}/organizations`, {
					headers
				});

				const userOrganizations: IUserOrg[] = await getUserOrgsRequest.json();

				dispatch({
					type: 'GET_USER_ORGANIZATIONS_RECEIVED',
					userOrganizations
				});

			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	getEntitlementSummary: (): AppThunkAction<authActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			if (
				getState().auth.entitlementSummaryLoadingState !== EUpdateState.Empty &&
				getState().auth.entitlementSummaryLoadingState !== EUpdateState.Loading
			) {
				return;
			}
			try {
				dispatch({ type: 'GET_ENTITLEMENT_SUMMARY_REQUEST' });
				const getEntitlementSummary = await fetch(`${url}marketplace/entitlement_summary`, {
					headers
				});

				const entitlementSummary = await getEntitlementSummary.json();

				dispatch({
					type: 'GET_ENTITLEMENT_SUMMARY_RECEIVED',
					entitlementSummary
				});
			} catch (error) {
				console.log(error);
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(error));
			}
		};
	},
	removeUserFromAuthState: (): AppThunkAction<authActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'REMOVE_USER_FROM_AUTH_STATE' });
		};
	},
	getEntitlements: (): AppThunkAction<authActions> => {
		return async (dispatch, getState) => {
			if (
				getState().auth.entitlementsLoadingState !== EUpdateState.Empty &&
				getState().auth.entitlementsLoadingState !== EUpdateState.Loading
			) {
				return;
			}
			try {
				dispatch({
					type: 'GET_ENTITLEMENTS_REQUEST'
				});

				const headers = await getCurrentAuthHeaders();

				const getEntitlements = await fetch(`${url}marketplace/entitlements`, {
					headers
				});

				const entitlements = await getEntitlements.json();

				console.log('entitlements', entitlements);

				dispatch({
					type: 'GET_ENTITLEMENTS_RECEIVED',
					entitlements
				});
			} catch (error) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(error));
			}
		};
	}
};
