import * as React from 'react';
import { AppFrame, InnerContainer, PageTitleArea } from 'components/Page';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { EUpdateState } from 'stores/shared/ENums';
import { PageTabBar } from 'components/Page/PageTabBar';
import { PatientListingFamilyTab, PatientListingTab } from './PatientListingTabs';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';

interface IPatientListingViewReduxProps {
	currentUserType: string;
	applyPersonaUpdateState: EUpdateState;
}

const PatientListingViewBase : React.FC<
	IPatientListingViewReduxProps &
	ResolveThunks<typeof PersonaBuilderActionCreators>
	> = props => {
		React.useEffect(()=>{
			props.getAllPatients();
		}, []);
		return (
			<AppFrame>
				<InnerContainer>
					<PageTitleArea
						breadcrumbItems={[
							{
								label: 'Creation Dashboard',
								location: '/persona-builder'
							},
							{
								label: 'All Patient Personas',
								location: `/persona-builder/patient-listing`
							}
						]}
					>
					</PageTitleArea>
					<PageTabBar navigationItems={[
						{
							label: 'Patients',
							location: '/persona-builder/patient-listing',
							route: '/persona-builder/patient-listing',
							component: PatientListingTab
						},
						{
							label: 'Patient Family Members',
							location: '/persona-builder/patient-listing/family',
							route: '/persona-builder/patient-listing/family',
							component: PatientListingFamilyTab
						}
					]}/>
				</InnerContainer>
			</AppFrame>
		);
}

const mapStateToProps = (state: ApplicationState): IPatientListingViewReduxProps => {
	return {
		currentUserType: ((state.auth || {}).user || {}).attributes['custom:user_type'],
		applyPersonaUpdateState: state.persona.applyPersonaUpdateState
	};
};

export const PatientListingView = connect(
	mapStateToProps,
	PersonaBuilderActionCreators
)(PatientListingViewBase);
