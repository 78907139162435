import personaUploadActions from 'stores/actionInterfaces/personaUploadActionInterfaces';
import { AppThunkAction } from 'stores';
import { url } from 'settings';
import { IPersonaUploadBioDetails } from 'common/interfaces';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { modalActionCreators } from 'stores/actions/modalActions';
import { EUpdateState } from 'stores/shared/ENums';
import { messageActionCreators } from 'stores/actions/messageActions';
import { TSetErrorMessage } from 'common/types/TSetErrorMessage';
import { errorMessages } from 'common/data/errorMessages';

export const personaUploadActionCreators = {
	createTemporaryUploadUrl: (): AppThunkAction<personaUploadActions> => {
		return async (dispatch, getState) => {
			if (
				getState().personaUpload.tempUploadInfoUpdateState ===
				EUpdateState.Loaded
			) {
				return;
			}
			try {
				dispatch({ type: 'GET_TEMPORARY_UPLOAD_INFO_REQUEST' });
				const headers = await getCurrentAuthHeaders('json');

				const request = await fetch(
					`${url}persona/create_upload_temp`,
					{
						method: 'POST',
						headers: headers
					}
				);

				const tempUploadInfo = await request.json();
				console.log('tempUploadInfo', tempUploadInfo);

				dispatch({
					type: 'GET_TEMPORARY_UPLOAD_INFO_RESPONSE',
					tempUploadInfo
				});
			} catch (error) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(error));
			}
		};
	},
	setFile: (
		file: File,
		setErrorMessage: TSetErrorMessage
	): AppThunkAction<personaUploadActions> => {
		return async (dispatch, getState) => {
			const tempUploadInfo = getState().personaUpload.tempUploadInfo;
			const tempUploadInfoData = tempUploadInfo.request_data.fields;
			try {
				dispatch({
					type: 'UPDATE_FILE_REQUEST',
					filename: file.name,
					filesize: file.size,
					file: file
				});

				let formData = new FormData();

				Object.keys(tempUploadInfoData).forEach(key => {
					formData.set(key, tempUploadInfoData[key]);
				});

				formData.set('file', file);

				console.log('formData', formData.has('key'));
				const contentLength = file.size;
				console.log('contentLength', contentLength);

				const request = await fetch(
					getState().personaUpload.tempUploadInfo.request_data.url,
					{
						method: 'POST',
						body: formData
					}
				);

				if (request.ok !== true) {
					setErrorMessage(errorMessages.general);
					return;
				}

				console.log('request', request);
				console.log('request status', request.status);

				const headers = await getCurrentAuthHeaders('json');

				const finalizeUploadTempRequest = await fetch(
					`${url}persona/finalize_upload_temp`,
					{
						method: 'POST',
						body: JSON.stringify({
							package_key: tempUploadInfo.package_key
						}),
						headers
					}
				);

				if (finalizeUploadTempRequest.ok !== true) {
					setErrorMessage(errorMessages.general);
					return;
				}

				dispatch({
					type: 'UPDATE_FILE_RESPONSE',
					packageKey: tempUploadInfo.package_key
				});
			} catch (e) {
				console.log('request failed');

				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	setDetails: (
		details: IPersonaUploadBioDetails
	): AppThunkAction<personaUploadActions> => {
		return async (dispatch, getState) => {
			dispatch({
				type: 'UPDATE_DETAILS',
				details: details
			});
		};
	},
	clearFileState: (): AppThunkAction<personaUploadActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'CLEAR_FILE' });
		};
	},
	clearImageState: (): AppThunkAction<personaUploadActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'CLEAR_IMAGE' });
		};
	},
	clearAllState: (): AppThunkAction<personaUploadActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'CLEAR_ALL' });
		};
	},
	createPersona: (): AppThunkAction<personaUploadActions> => {
		return async (dispatch, getState) => {
			try {
				dispatch({ type: 'CREATE_PERSONA_REQUEST' });

				const headers = await getCurrentAuthHeaders('json');

				const request = await fetch(`${url}persona/create`, {
					method: 'POST',
					headers: headers,
					body: JSON.stringify({
						...getState().personaUpload.details,
						type: getState().personaUpload.details.persona_type,
						package_key: getState().personaUpload.packageKey
					})
				});

				const result = await request.json();

				if ('errorType' in result) {
					dispatch({
						type: 'CREATE_PERSONA_RESPONSE',
						errorType: result.errorType,
						errorMessage: result.errorMessage
					});
					return;
				} else {
					headers.set(
						'Content-Type',
						getState().personaUpload.imageFile.type
					);

					const image_request = await fetch(
						`${url}persona/${result.persona_id}/set_image`,
						{
							method: 'POST',
							headers: headers,
							body: getState().personaUpload.imageFile
						}
					);

					if (image_request.status !== 204) {
						dispatch({
							type: 'CREATE_PERSONA_RESPONSE',
							errorType: 'ImageUploadFailed',
							errorMessage: 'ImageUploadFailed'
						});
						return;
					} else {
						dispatch({
							type: 'CREATE_PERSONA_RESPONSE'
						});
					}

					personaUploadActionCreators.clearFileState()(
						dispatch,
						getState,
						{}
					);

					//@ts-ignore
					dispatch(modalActionCreators.dismissModal());
					return;
				}
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	selectImage: (file: File): AppThunkAction<personaUploadActions> => {
		return async (dispatch, getState) => {
			let tmp = file;
			dispatch({
				type: 'SELECT_IMAGE',
				imagePreviewUrl: URL.createObjectURL(file),
				filename: file.name,
				filesize: file.size,
				file: file,
				errorType: null,
				errorMessage: null
			});
		};
	},
	setImage: (file: File): AppThunkAction<personaUploadActions> => {
		return async (dispatch, getState) => {
			try {
				dispatch({
					type: 'UPDATE_IMAGE_REQUEST'
				});
				const headers = await getCurrentAuthHeaders();

				const request = await fetch(`${url}persona/upload_package`, {
					method: 'POST',
					headers: headers,
					body: file
				});

				const result = await request.json();

				if ('errorType' in result) {
					dispatch({
						type: 'UPDATE_IMAGE_RESPONSE',
						isImageUploaded: true,
						errorType: result.errorType,
						errorMessage: result.errorMessage
					});
				} else {
					dispatch({
						type: 'UPDATE_IMAGE_RESPONSE',
						isImageUploaded: false,
						errorType: null,
						errorMessage: null
					});
				}
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	}
};
