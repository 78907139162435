import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { messageActionCreators } from 'stores/actions/messageActions';
import { Error } from 'components/Toast';
import { InnerContainer } from 'components/Page';

interface IMessageReduxProps {
	messages: string[];
}

class MessageControllerBase extends React.PureComponent<
	IMessageReduxProps & ResolveThunks<typeof messageActionCreators>
> {
	render() {
		const { messages } = this.props;
		console.log('messages', messages);
		return (
			<>
				{messages && messages.length > 0 && (
					<InnerContainer>
						{messages.map((message, index) => {
							return <Error key={index}>{message}</Error>;
						})}
					</InnerContainer>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IMessageReduxProps => {
	console.log('state.messages.messages', state.messages.messages);
	return {
		messages: state.messages.messages
	};
};

export const MessageController = connect(
	mapStateToProps,
	messageActionCreators
)(MessageControllerBase);
