import * as React from 'react';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IPatient } from 'common/interfaces/personaBuilder/IPatient';
import { IPatientMedication } from 'common/interfaces/personaBuilder/IPatientMedication';
import { IProvider } from 'common/interfaces/personaBuilder/IProvider';
import { IMedication } from 'common/interfaces/personaBuilder/IMedication';
import { IPatientCondition } from 'common/interfaces/personaBuilder/IPatientCondition';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { objectArrayToMap } from 'common/utils/objectArrayToMap';
import { Button, Card, Grid, Typography } from '@material-ui/core';
import { AddCircle, Edit, ArrowDownward, ChevronLeft, ChevronRight } from '@material-ui/icons';
import { MuiButton } from 'components/MaterialUI/index';
import DataTable from 'react-data-table-component';
import cssClasses from 'classnames';
import { FormPatientMedication } from 'components/Forms/PersonaBuilder/FormPersonaBuilder/FormPatientMedication';
import cssDetails from 'components/Forms/PersonaBuilder/FormPersonaBuilder/FormPatientDetails/persona-details.module.scss';
import css from './patient-medication-tab.module.scss';
const sortIcon = <ArrowDownward />;

interface IPatientMedicationTabReduxProps {
	currentPatient: IPatient;
	patientMedications: IPatientMedication[];
	patientConditions: IPatientCondition[];
	providers: IProvider[];
	medications: IMedication[];
	diagnosis: any;
	prescription_statuses: any;
}

const PatientMedicationTabBase : React.FC<
	IPatientMedicationTabReduxProps &
	ResolveThunks<typeof PersonaBuilderActionCreators>
> = props => {

	let _submitForm = null;
	const { currentPatient, patientMedications, patientConditions, providers, medications, diagnosis, prescription_statuses } = props;
	const newMedication = {
		id: 0,
		provider_id: '',
		patient_condition_id: '',
		medication_management_id: '',
		self_reported_flag: false,
		prescription_status_id: ''
	};

	const getMedicationName = (proprietary_name: string, non_proprietary_name: string) => {
		return proprietary_name !== "" ? proprietary_name : non_proprietary_name;
	};
	const getDiagnosisName = (diagnosisNames: any, conditionDiagnosisIds: any, patient_condition_id: number) => {
		const diagnosis_id = conditionDiagnosisIds[patient_condition_id];
		return diagnosisNames[diagnosis_id];
	};

	const providerFirstNames = objectArrayToMap(providers, 'id', 'provider_first_name');
	const providerLastNames = objectArrayToMap(providers, 'id', 'provider_last_name');
	const medicationProprietaryNames = objectArrayToMap(medications, 'id', 'proprietary_name');
	const medicationNonProprietaryNames = objectArrayToMap(medications, 'id', 'non_proprietary_name');
	const conditionDiagnosisIds = objectArrayToMap(patientConditions, 'id', 'diagnosis_id');
	const diagnosisNames = objectArrayToMap(diagnosis, 'id', 'diagnosis_name');
	const statusNames = objectArrayToMap(prescription_statuses, 'id', 'prescription_status_display');
	const medicationsData = patientMedications.map(medication => {
		return {
			...medication,
			provider_first_name: providerFirstNames[medication.provider_id],
			provider_last_name: providerLastNames[medication.provider_id],
			medication_name: getMedicationName(medicationProprietaryNames[medication.medication_management_id], medicationNonProprietaryNames[medication.medication_management_id]),
			diagnosis_name: getDiagnosisName(diagnosisNames, conditionDiagnosisIds, medication.patient_condition_id),
			status_name: statusNames[medication.prescription_status_id],
			self_reported_flag_label: medication.self_reported_flag ? "Yes" : "No"
		}
	});
	const initialData = medicationsData;

	const [filteredData, setFilteredData] = React.useState(null);
	const [currentMedication, setCurrentMedication] = React.useState(null);

	const history = useHistory();
	const prevLink = currentPatient ? `/persona-builder/patient-listing/${currentPatient.id}/condition` : '';
	const nextLink = currentPatient ? `/persona-builder/patient-listing/${currentPatient.id}/allergy` : '';
	
	const _bindSubmitForm = (submitForm) => {
		_submitForm = submitForm;
	};
	const handleSubmit = () => {
		if (_submitForm){
			_submitForm();
		}
	};

	const setForm = (medication) => {
		if (medication !== null) {
			setCurrentMedication(medication);
		}
		else {
			setCurrentMedication(null);
		}
	};
	const saveMedication = (values) => {
		if (!!values.id) {
			// update
			props.updatePatientMedication(values, currentPatient.id).then(()=>{
				props.getPatientMedications(currentPatient.id);
				setCurrentMedication(null);
			}).catch((e)=>{console.log(e)});
		}
		else {
			// add
			props.createPatientMedication(values, currentPatient.id).then(()=>{
				props.getPatientMedications(currentPatient.id);
				setCurrentMedication(null);
			}).catch((e)=>{console.log(e)});
		}
	};
	const deleteMedication = () => {
		props.deletePatientMedication(currentMedication, currentPatient.id).then(()=>{
			props.getPatientMedications(currentPatient.id);
			setCurrentMedication(null);
		}).catch((e)=>{console.log(e)});
	};
	const filterMedications = (param) => {
		if (param.length > 2) {
			let foundData = [];
			initialData.forEach(function(dataElement) {
				["provider_first_name", "provider_last_name", "medication_name", "diagnosis_name", "status_name", "self_reported_flag_label"].forEach(function(property) {
					if (dataElement[property].toLowerCase().indexOf(param.toLowerCase()) > -1) {
						foundData.push(dataElement);
					}
				});
			});
			setFilteredData(foundData);
		}
		else if (param.length === 0) {
			setFilteredData(initialData);
		}
	};

	const columns = [
		{
			name: '',
			cell: (row) => <Button onClick={() => setForm(row)}><Edit /></Button>,
			button: true
		},
		{
			name: 'Prescription Medication',
			sortable: true,
			selector: 'medication_name',
		},
		{
			name: 'Condition',
			sortable: true,
			selector: 'diagnosis_name',
		},
		{
			name: 'Prescribed By',
			sortable: true,
			selector: 'provider_first_name',
			cell: (row) => `${row.provider_first_name} ${row.provider_last_name}`,
		},
		{
			name: 'Status',
			sortable: true,
			selector: 'status_name'
		},
		{
			name: 'Self Reported?',
			sortable: true,
			selector: 'self_reported_flag_label'
		}
	];

	return (
		<>
			{currentMedication !== null ? (
				<>
					<FormPatientMedication
						bindSubmitForm={_bindSubmitForm}
						defaultFormContent={currentMedication} 
						formAction={saveMedication}
						deleteAction={deleteMedication}
					/>
				</>
			) : (
				<>
					{patientMedications.length > 0 && 
						<Grid container className={css.listContainer}>
							<Grid item xs={6}>
								<h4>Medication <MuiButton className={css.addButton} onClick={() => setForm(newMedication)}><AddCircle /></MuiButton></h4>
							</Grid>
							<Grid item xs={6}>
								<input placeholder='Search Medication' type='text' onChange={(e) => filterMedications(e.target.value)} />
							</Grid>
							<Grid item xs={12}>
								<Card className={css.listCard}>
									<DataTable
										className={css.dataTable}
										columns={columns}
										data={filteredData ? filteredData : initialData}
										noHeader
										highlightOnHover
										defaultSortField="medication_name"
										sortIcon={sortIcon}
										pagination
										paginationRowsPerPageOptions={[10,25,50,100]}
									/>
								</Card>
							</Grid>
						</Grid>
					}
					{patientMedications.length < 1 && 
						<Grid container className={css.noDataContainer}>
							<Grid item xs={12}>
								<Typography variant={'h4'}>No known patient medications.</Typography>
								<p>Would you like to assign medications to your patient?</p>
							</Grid>
							<Grid item xs={12}>
								<MuiButton onClick={() => setForm(newMedication)}>Assign Medication</MuiButton>
							</Grid>
						</Grid>
					}
				</>
			)}
			<Grid container className={cssDetails.actionsContainer}>
				<Grid item xs={12} md={6}>
					<Button onClick={() => history.push(`${prevLink}`)}>
						<ChevronLeft className={cssClasses([cssDetails.leftIcon], [cssDetails.iconSmall])}/>
						Condition
					</Button>
				</Grid>
				<Grid item xs={12} md={6} style={{textAlign: 'right'}}>
					<MuiButton variant="contained" className={cssDetails.button} disabled={currentMedication === null} onClick={handleSubmit}>
						Save
					</MuiButton>
					<Button onClick={() => history.push(`${nextLink}`)}>
						Allergy
						<ChevronRight className={cssClasses([cssDetails.rightIcon], [cssDetails.iconSmall])}/>
					</Button>
				</Grid>
			</Grid>
		</>
	)
}

const mapStateToProps = (state: ApplicationState): IPatientMedicationTabReduxProps => {
	return {
		currentPatient: state.personaBuilder.currentPatient,
		patientMedications: state.personaBuilder.currentPatientMedications,
		patientConditions: state.personaBuilder.currentPatientConditions,
		providers: state.personaBuilder.dropdowns.providers,
		medications: state.personaBuilder.dropdowns.medications,
		diagnosis: state.personaBuilder.hl7Valuesets.diagnosis,
		prescription_statuses: state.personaBuilder.dropdowns.prescription_statuses
	};
};

export const PatientMedicationTab = connect(
	mapStateToProps,
	PersonaBuilderActionCreators
)(PatientMedicationTabBase);
