import * as React from 'react';
import { ReactComponent as ArrowDownGray } from 'assets/icons/icon-arrow-down-gray.svg';
import { Circle } from 'components/Circle';
import { HeaderDropDownMenu } from '../HeaderDropDownMenu';
import { connect, ResolveThunks } from 'react-redux';
import { findDOMNode } from 'react-dom';
import classes from 'classnames';
import { ApplicationState } from 'stores';
import { ICognitoUser, INotification } from 'common/interfaces';
import css from './header-drop-down.module.scss';
import { Badge } from '@material-ui/core';
import { notificationActionCreators } from 'stores/actions/notificationActions';


const headerDropDownActionCreators = {
	...notificationActionCreators,
};

interface IHeaderDropDownState {
	accountNavShown: boolean;
}

interface IHeaderDropDownReduxProps {
	user: ICognitoUser;
	notificationAlerts: INotification[];
	isTermsAccepted: boolean;
}

class HeaderDropDownBase extends React.PureComponent<
	IHeaderDropDownReduxProps &
	ResolveThunks<typeof headerDropDownActionCreators>,
	IHeaderDropDownState
> {
	private thisComponentRef;

	constructor(props) {
		super(props);
		this.state = { accountNavShown: false };
	}

	accountNavToggle = () => {
		this.setState(state => {
			return {
				...state,
				accountNavShown: !state.accountNavShown
			};
		});
	};

	setVisibility = (isVisible: boolean) => {
		this.setState(state => {
			return {
				...state,
				accountNavShown: isVisible
			};
		});
	};

	componentDidMount() {
		this.thisComponentRef = findDOMNode(this);
		if (this.props.isTermsAccepted) this.props.getNotifications();
	}

	render() {
		const { user, notificationAlerts } = this.props;
		return (
			<>

				<button onClick={this.accountNavToggle} className={classes(css.menuToggle)}>
					<Badge badgeContent={notificationAlerts.length}
						color="error"
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}>
						<Circle size='small' border={false} className={css.profileIcon}>
							{user.username.charAt(0)}
						</Circle>
						<ArrowDownGray />
					</Badge>
				</button>
				<HeaderDropDownMenu
					setVisibility={this.setVisibility}
					isShown={this.state.accountNavShown}
					ignoreTarget={this.thisComponentRef}
				/>
			</>
		);
	}
}
const mapStateToProps = (state: ApplicationState): IHeaderDropDownReduxProps => ({
	user: state.auth.user,
	isTermsAccepted: state.auth.isTermsAccepted,
	notificationAlerts: state.notifications.notificationAlerts
});

export const HeaderDropDown = connect(
	mapStateToProps,
	headerDropDownActionCreators
)(HeaderDropDownBase);
