import * as React from 'react';

import { authActionCreators } from 'stores/actions/authActions';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { Link } from 'react-router-dom';
import { SideBarSection, ISideBarItem } from './SideBarSection';
import { ICognitoUser, IOrganization } from 'common/interfaces';
import { modalActionCreators } from 'stores/actions/modalActions';

import css from './side-bar.module.scss';
import { rofActionCreators } from 'stores/actions/rofActions';
import classes from 'classnames';
import { EUpdateState } from 'stores/shared/ENums';
import { IRof } from 'common/interfaces/IRof';
import { createNewROFModal } from 'common/modals/createNewRofModal';
import { noEntitlementAvailableModal } from 'common/modals/noEntitlementAvailableModal';
import { clone } from 'ramda';
import { convertIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';
import { allowRofCreation } from 'common/utils/canCreateRof';
import { ICanCreateRof } from 'common/interfaces/ICanCreateRof';
import { isRofBusy } from 'common/utils/isRofBusy';
import { RofState } from 'stores/stateInterfaces/rofStateInterface';
import { ReactComponent as InteroperabilityInstituteLogo } from 'assets/logo_interoperability-institute_white.svg';
import { IEntitlementSummary } from 'common/interfaces/IEntitlementSummary';

const sideBarActionCreators = {
	...authActionCreators,
	...rofActionCreators,
	...modalActionCreators
};

interface ISideBarProps {
	sideBarShown: boolean;
	sideBarToggle: () => void;
}
interface ISideBarReduxProps {
	user: ICognitoUser;
	allRof: IRof[];
	currentUpdateState: EUpdateState;
	userType: string;
	rof: RofState;
	entitlementSummary: IEntitlementSummary;
	entitlementSummaryLoadingState: EUpdateState;
	currentOrganization: IOrganization;
}

interface ISidebarSection {
	sectionLabel: string;
	children: ISideBarItem[];
}

class SideBarBase extends React.PureComponent<
	ISideBarProps &
	ISideBarReduxProps &
	ResolveThunks<typeof sideBarActionCreators & typeof modalActionCreators>
> {
	UNSAFE_componentWillMount() {
		this.props.getOrganizationRof(this.props.user.attributes.organizationId);
		this.props.getRofTemplates();
	}
	logOut = () => {
		this.props.signOut();
	};

	render() {
		const {
			user,
			sideBarShown,
			sideBarToggle,
			allRof,
			createModal,
			rof,
			entitlementSummary,
			entitlementSummaryLoadingState,
			userType,
			currentOrganization
		} = this.props;

		let rofSideBarItems: ISideBarItem[] = allRof.map(currentRof => ({
			label: currentRof.name,
			icon: 'rof',
			location: `/sandbox/${currentRof.id}`,
			busy: isRofBusy(rof, currentRof.id)
		}));


		if (
			(entitlementSummaryLoadingState === EUpdateState.Loaded)
		) {
			if (userType !== 'org-user') {
				const hasEntitlementsRemaining =
					entitlementSummary.rof_count - entitlementSummary.rof_purchased !== 0;

				rofSideBarItems.push({
					label: 'Create Sandbox',
					icon: 'plusBold',
					action:
						allowRofCreation(entitlementSummary, userType)
							? event => createModal(createNewROFModal(currentOrganization.id), event)
							: event => createModal(noEntitlementAvailableModal(entitlementSummary), event),
					labelSecondary:
						entitlementSummary.rof_purchased !== null
							? `${entitlementSummary.rof_purchased - entitlementSummary.rof_count} Remaining`
							: null
				});
			}
		}

		let sideBarItems: ISidebarSection[] = [];

		if (rofSideBarItems) {
			sideBarItems.push({
				sectionLabel: `Sandboxes`,
				children: rofSideBarItems
			});
		}

		let libraries = [];

		libraries.push(
			{
				label: 'Persona Library',
				icon: 'users',
				location: '/persona'
			},
			{
				label: 'API Library',
				icon: 'apis',
				location: '/api'
			},
			{
				label: 'Code Sample Gallery',
				icon: 'codeSamples',
				location: '/code-sample'
			}
		);

		if (userType === 'mihin-admin') {
			libraries = [
				...libraries.slice(0, 1),
				{
					label: 'Persona Builder',
					icon: 'document',
					location: '/persona-builder'
				},
				...libraries.slice(1)
			];
		}

		sideBarItems.push({
			sectionLabel: 'Libraries',
			children: libraries
		});

		return (
			<nav
				className={classes(css.dashboardSideBar, {
					[css.shown]: sideBarShown
				})}
			>
				<div className={css.sideBarItems}>
					{sideBarItems.map((item, index) => (
						<SideBarSection sideBarLabel={item.sectionLabel} sideBarItems={item.children} key={index} />
					))}
				</div>

				<div className={css.footerSideBar}>
					<div className={css.footerSideBarLogoContainer}>
						<span className={css.footerSideBarLogoText}>Powered by</span>
						<a
							href='https://interoperabilityinstitute.org/'
							target='_BLANK'
							rel='noreferrer'
							className={css.footerSideBarLogoLink}
						>
							<InteroperabilityInstituteLogo className={css.footerSideBarLogo} />
						</a>
					</div>
					<p className={css.footerDisclaimer}>
						HL7, FHIR and the [FLAME] mark are the registered trademarks of Health Level Seven
						International. Use of these trademarks do not constitute an endorsement from HL7.
					</p>
					<div className={css.footerSideBarLinks}>
						<ul className={classes('reset')}>
							<li>
								<Link to='/terms'>Terms</Link>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		);
	}
}

const mapStateToProps = (state: ApplicationState): ISideBarReduxProps => {
	const currentOrganizationId = state.organization.currentOrganization ? state.organization.currentOrganization : state.auth.user.attributes.organizationId;
	const currentOrganization = state.organization.organizations[state.organization.currentOrganization]
		? state.organization.organizations[state.organization.currentOrganization]
		: null;
	const currentUserType = currentOrganization ? currentOrganization.user_type : state.auth.user.attributes['custom:user_type']

	const allRof = convertIndexedObjectToArray(clone(state.rof.allRof)).filter(
		ringOfFhir => ringOfFhir.organization_id === currentOrganizationId
	);

	const currentUpdateState = state.rof.organizationsRofLoadingState[currentOrganizationId];

	const user = state.auth.user;
	const userOrg = state.auth.user.attributes.organizationId

	return {
		user,
		allRof: allRof ? allRof : [],
		currentUpdateState,
		userType: currentUserType,
		rof: state.rof,
		entitlementSummary: state.auth.entitlementSummary,
		entitlementSummaryLoadingState: state.auth.entitlementSummaryLoadingState,
		currentOrganization: state.organization.organizations[state.organization.currentOrganization]
			? state.organization.organizations[state.organization.currentOrganization]
			: state.organization.organizations[user.attributes.organizationId]
	};
};

export const SideBar = connect(mapStateToProps, sideBarActionCreators)(SideBarBase);
