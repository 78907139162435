import * as React from 'react';
import css from './error.module.scss';
import classes from 'classnames';

interface ErrorProps {
	absolutePositioned?: boolean;
}
export const Error: React.FC<ErrorProps> = props => {
	const { absolutePositioned } = props;

	return (
		<div
			role='alert'
			className={classes(
				css.error,
				absolutePositioned ? css.absolutePositioned : ''
			)}
		>
			{props.children}
		</div>
	);
};
