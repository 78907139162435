import * as React from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { OAuthScopes } from 'common/data/OAuthScopes';
import classes from 'classnames';
import './style.scss';

interface IOAuthScopeSelectorProps {
	className?: string;
	defaultValue?: string;
	placeholder?: string;
	onChange: any;
}

// const selectStyles = {
// 	input: (provided, state) => ({
// 		...provided,
// 		// lineHeight: 'normal',
// 		backgroundColor: 'red',
// 	}),
// };

export const OAuthScopeSelector: React.FC<IOAuthScopeSelectorProps> = ({
	onChange,
	className,
	defaultValue,
	placeholder,
}) => {
	const oauthScopeOptions = OAuthScopes.map((scope) => {
		return {
			value: scope,
			label: scope,
		};
	});
	const formatScopesForProperty = (selectedValues) => {
		let optionsValue: string[] = [];
		_.each(selectedValues, (selectedValue) => {
			optionsValue.push(selectedValue.value);
		});
		return optionsValue.join(' ');
	};
	const formatScopesForControl = (optionsValue: string) => {
		if (optionsValue.length > 0) {
			const options = typeof optionsValue === 'string' ? optionsValue.split(' ') : optionsValue;
			return options.map((option) => {
				return {
					value: option,
					label: option,
				};
			});
		}
	};
	const handleScopeChange = (selectedValue) => {
		if (selectedValue) {
			const newValue = formatScopesForProperty(selectedValue);
			onChange(newValue);
		} else {
			onChange('');
		}
	};

	return (
		<Select
			classNamePrefix='react-select'
			className={classes([className, 'oauth-select'])}
			// styles={selectStyles}
			placeholder={placeholder}
			isMulti
			options={oauthScopeOptions}
			defaultValue={formatScopesForControl(defaultValue || '')}
			onChange={handleScopeChange}
		/>
	);
};
