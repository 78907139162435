import * as React from 'react';
import { authActionCreators } from 'stores/actions/authActions';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { SimpleFrame } from 'components/Page';
import css from './terms-of-service.module.scss';
import { TermsOfServiceContent } from './TermsOfServiceContent';

const termsOfServiceViewActionCreators = {
	...authActionCreators
};
interface ITermsReduxProps {
	terms: any;
}

class TermsOfServiceViewBase extends React.PureComponent<
	ITermsReduxProps & ResolveThunks<typeof termsOfServiceViewActionCreators>
> {
	UNSAFE_componentWillMount() {
		this.props.getTerms();
	}
	logOut = () => {
		this.props.signOut();
	};
	render() {
		const { terms } = this.props;
		return (
			<SimpleFrame>
				{/*terms ? (
					<div
						className={css.termsOfService}
						dangerouslySetInnerHTML={{
							__html: terms.corpus
						}}
					/>
				) : (
					''
				)*/}
				{<div className={css.termsOfService}>
					<TermsOfServiceContent />
				</div>}
			</SimpleFrame>
		);
	}
}

const mapStateToProps = (state: ApplicationState): ITermsReduxProps => ({
	terms: state.auth.terms
});
export const TermsOfServiceView = connect(
	mapStateToProps,
	termsOfServiceViewActionCreators
)(TermsOfServiceViewBase);
