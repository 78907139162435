import * as React from 'react';
import { authActionCreators } from 'stores/actions/authActions';
import { connect, ResolveThunks } from 'react-redux';
import classes from 'classnames';
import css from './header.module.scss';
import { rofActionCreators } from 'stores/actions/rofActions';
import { HeaderDropDown } from './HeaderDropDown';
import { OrganizationDropDown } from './OrganizationDropDown';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/logo.svg';


const headerActionCreators = {
	...authActionCreators,
	...rofActionCreators
};

interface IHeaderProps {
	navShown: boolean;
	navToggle: () => void;
}

class HeaderBase extends React.PureComponent<
	IHeaderProps & ResolveThunks<typeof headerActionCreators>
> {
	UNSAFE_componentWillMount() {
		this.props.getUserOrganization();
		this.props.getEntitlementSummary();
		this.props.getEntitlements();
	}

	handleRofStateChange = event => {
		console.log(
			'handleRofStateChange event.currentTarget.checked',
			event.currentTarget.checked
		);
		const setAs: boolean = event.currentTarget.checked;
		this.props.setCurrentRofState(setAs);
	};

	render() {
		const { navToggle, navShown } = this.props;

		return (
			<nav className={css.mainHeader}>
				<div>
					<Link className={css.logoLink} to='/'>
						<Logo className={css.logo} />
					</Link>
				</div>
				<div className={css.headerDropdowns}>
					<ul className={classes('reset', css.mainHeaderNav)}>
						<li className={classes(css.item, css.navItemAccount)}>
							<OrganizationDropDown />
						</li>
					</ul>

					<ul className={classes('reset', css.mainHeaderNav)}>
						<li className={classes(css.item, css.navItemAccount)}>
							<HeaderDropDown />
						</li>
					</ul>
				</div>


				<div
					className={classes(css.menuButton, {
						[css.active]: navShown
					})}
					onClick={navToggle}
				>
					<div className={css.target}>
						<span />
						<span />
						<span />
					</div>
				</div>
			</nav>
		);
	}
}

export const Header = connect(
	null,
	headerActionCreators
)(HeaderBase);
