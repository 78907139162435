import * as React from 'react';
import { Field, Formik } from 'formik';
import { IModalForm } from 'common/interfaces';
import { removePhoneNumberFormatting } from 'common/utils/removePhoneNumberFormatting';
import { PhoneNumberField } from 'components/FormElements/PhoneNumberField/PhoneNumberField';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import classes from 'classnames';
import { FormLabel } from 'components/FormElements';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { ErrorFocus } from 'components/ErrorFocus';
import { Card, CardContent, Divider, Grid } from '@material-ui/core';
import css from './persona-details.module.scss';
import { SegmentedController } from 'components/FormElements';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { patientDetailsValidationSchema } from 'common/validation-schema/personaBuilder/patientDetailsValidationSchema';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { IsFormDirty } from 'components/RouteGuards/IsFormDirty';
import { DatePickerField } from 'components/FormElements/DatePickerField'

interface IFormPatientDetailsState {
	isLoading: boolean;
}

interface IFormPatientDetailsReduxProps {
	dropdowns: any;
}

export class FormPatientDetailsBase extends React.PureComponent<ResolveThunks<typeof PersonaBuilderActionCreators> &
	IModalForm &
	IFormPatientDetailsReduxProps,
	IFormPatientDetailsState> {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false
		};
	}

	setFormLoadingAttribute = (isLoading: boolean) => {
		this.setState({
			isLoading
		});
	};

	render() {
		let { setFormLoadingAttribute } = this.props;
		const {
			bindSubmitForm,
			formAction,
			defaultFormContent,
			setErrorMessage,
			dropdowns,
		} = this.props;

		let initialValues = {
			first_name: '',
			middle_name: '',
			last_name: '',
			dob: null,
			home_phone: '',
			mobile_phone: '',
			marital_status_id: '',
			ethnicity_id: '',
			gender_id: '',
			race_id: '',
			language_id: '',
			deceased_flag: 'false'
		};

		if (defaultFormContent) {
			defaultFormContent.home_phone = removePhoneNumberFormatting(defaultFormContent.home_phone);
			defaultFormContent.mobile_phone = removePhoneNumberFormatting(defaultFormContent.mobile_phone);
			initialValues = defaultFormContent;
		}
		if (!setFormLoadingAttribute) {
			setFormLoadingAttribute = this.setFormLoadingAttribute;
		}

		return (
			<Formik
				initialValues={initialValues}
				enableReinitialize={true}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					values.home_phone = `+1${removePhoneNumberFormatting(values.home_phone)}`;
					values.mobile_phone = `+1${removePhoneNumberFormatting(values.mobile_phone)}`;

					setFormLoadingAttribute(true);
					if (formAction) {
						const trimmedValues = trimAllStringProperties(values);
						formAction(trimmedValues, setErrorMessage, setFormLoadingAttribute);
						resetForm(trimmedValues);
					}
					setSubmitting(false);
				}}
				validationSchema={patientDetailsValidationSchema()}
			>
				{props => {
					const {
						values,
						touched,
						errors,
						dirty,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
						handleReset,
						submitForm,
						isValid
					} = props;
					if (bindSubmitForm) {
						bindSubmitForm(submitForm);
					}
					return (
						<>
							<IsFormDirty
								title=""
								message=""
								dirty={dirty}
							/>
							<Card>
								<CardContent>
									<form onSubmit={handleSubmit}>
										<Grid container xs={12}>
											<Grid container xs={12} md={6} className={
												css.gridContainer
											}>
												<Grid item xs={12}>

													<FormLabel htmlFor='first_name'>First Name</FormLabel>
													<input
														id='first_name'
														placeholder='Enter First Name'
														type='text'
														value={values.first_name}
														onChange={handleChange}
														onBlur={handleBlur}
														className={errors.first_name && touched.first_name ? 'text-input error' : 'text-input'}
														autoComplete='first-name'
														maxLength={128}
													/>
													{errors.first_name && touched.first_name && (
														<div className='input-feedback'>{errors.first_name}</div>
													)}

													<FormLabel htmlFor='middle_name'>Middle Name</FormLabel>
													<input
														id='middle_name'
														placeholder='Enter Middle Name'
														type='text'
														value={values.middle_name}
														onChange={handleChange}
														onBlur={handleBlur}
														className={errors.middle_name && touched.middle_name ? 'text-input error' : 'text-input'}
													/>
													{errors.middle_name && touched.middle_name && (
														<div className='input-feedback'>{errors.middle_name}</div>
													)}

													<FormLabel htmlFor='last_name'>Last Name</FormLabel>
													<input
														id='last_name'
														placeholder='Enter Last Name'
														value={values.last_name}
														onChange={handleChange}
														onBlur={handleBlur}
														className={
															errors.last_name && touched.last_name ? 'text-input error' : 'text-input'
														}
													/>
													{errors.last_name && touched.last_name && (
														<div className='input-feedback'>{errors.last_name}</div>
													)}

													<FormLabel htmlFor='dob'>D.O.B.</FormLabel>
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<Field
															name='dob'
															value={values.dob}
															component={DatePickerField}
															className={
																classes(css.dateOfBirthPicker, 'mtl-picker-input', { 'error': errors.dob && touched.dob })
															}
															onBlur={handleBlur}
														/>
													</MuiPickersUtilsProvider>
													{errors.dob && touched.dob && (
														<div className='input-feedback'>{errors.dob}</div>
													)}

												</Grid>

												<Grid item xs={12} md={6} className={
													css.gridItem
												}>

													<FormLabel htmlFor='home_phone'>Home Phone</FormLabel>
													<PhoneNumberField
														id='home_phone'
														placeholder='Enter Home Phone Number'
														value={removePhoneNumberFormatting(values.home_phone)}
														onChange={handleChange}
														onBlur={handleBlur}
														autocomplete={'home tel'}
														className={
															errors.home_phone && touched.home_phone ? 'text-input error' : 'text-input'
														}
													/>

													{errors.home_phone && touched.home_phone && (
														<div className='input-feedback'>{errors.home_phone}</div>
													)}

												</Grid>

												<Grid item xs={12} md={6} className={
													css.gridItem
												}>

													<FormLabel htmlFor='mobile_phone'>Mobile Phone</FormLabel>
													<PhoneNumberField
														id='mobile_phone'
														placeholder='Enter Mobile Phone Number'
														value={removePhoneNumberFormatting(values.mobile_phone)}
														onChange={handleChange}
														autocomplete={'mobile tel'}
														onBlur={handleBlur}
														className={
															errors.mobile_phone && touched.mobile_phone ? 'text-input error' : 'text-input'
														}
													/>

													{errors.mobile_phone && touched.mobile_phone && (
														<div className='input-feedback'>{errors.mobile_phone}</div>
													)}
												</Grid>
											</Grid>
											<Grid container xs={12} md={6} className={
												css.gridContainer
											}>
												<Grid item xs={12}>
													<FormLabel htmlFor='marital_status'>Marital Status</FormLabel>
													<select
														id='marital_status_id'
														onChange={handleChange}
														onBlur={handleBlur}
														value={`${values.marital_status_id}`}
														className={errors.marital_status_id && touched.marital_status_id ? 'text-input error' : 'text-input'}
													>
														<option>Select</option>
														{dropdowns.marital_statuses.map((marital_status, index) => (
															<option key={index} value={marital_status.id}>
																{marital_status.marital_status}
															</option>
														))}
													</select>
													{errors.marital_status_id && touched.marital_status_id && (
														<div className='input-feedback'>{errors.marital_status_id}</div>
													)}
												</Grid>

												<Grid item xs={12}>
													<FormLabel htmlFor='ethnicity'>Ethnicity</FormLabel>
													<select
														id='ethnicity_id'
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.ethnicity_id}
														className={errors.ethnicity_id && touched.ethnicity_id ? 'text-input error' : 'text-input'}
													>
														<option>Select</option>
														{dropdowns.ethnicities.map((ethnicity, index) => (
															<option key={index} value={ethnicity.id}>
																{ethnicity.ethnicity}
															</option>
														))}
													</select>
													{errors.ethnicity_id && touched.ethnicity_id && (
														<div className='input-feedback'>{errors.ethnicity_id}</div>
													)}
												</Grid>

												<Grid item xs={12}>
													<FormLabel htmlFor='gender'>Gender</FormLabel>
													<select
														id='gender_id'
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.gender_id}
														className={errors.gender_id && touched.gender_id ? 'text-input error' : 'text-input'}
													>
														<option>Select</option>
														{dropdowns.genders.map((gender, index) => (
															<option key={index} value={gender.id}>
																{gender.gender}
															</option>
														))}
													</select>
													{errors.gender_id && touched.gender_id && (
														<div className='input-feedback'>{errors.gender_id}</div>
													)}</Grid>

												<Grid item xs={12}>
													<FormLabel htmlFor='race'>Race</FormLabel>
													<select
														id='race_id'
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.race_id}
														className={errors.race_id && touched.race_id ? 'text-input error' : 'text-input'}
													>
														<option>Select</option>
														{dropdowns.races.map((race, index) => (
															<option key={index} value={race.id}>
																{race.race}
															</option>
														))}
													</select>
													{errors.race_id && touched.race_id && (
														<div className='input-feedback'>{errors.race_id}</div>
													)}
												</Grid>

												<Grid item xs={12}>
													<FormLabel htmlFor='language'>Language</FormLabel>
													<select
														id='language_id'
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.language_id}
														className={errors.language_id && touched.language_id ? 'text-input error' : 'text-input'}
													>
														<option>Select</option>
														{dropdowns.languages.map((language, index) => (
															<option key={index} value={language.id}>
																{language.language} {language.language_region !== 'None' && ` - ${language.language_region}`}
															</option>
														))}
													</select>
													{errors.language_id && touched.language_id && (
														<div className='input-feedback'>{errors.language_id}</div>
													)}
												</Grid>

											</Grid>
											<ErrorFocus />
										</Grid>

										<Divider variant="middle" />

										<Grid container xs={12} className={css.gridContainer}>

											<Grid container xs={12} direction={'row'} alignItems={'center'}>
												<FormLabel style={css.label} htmlFor='deceased_flag'>Is the patient deceased?</FormLabel>
												<SegmentedController
													options={[{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }]}
													values={values.deceased_flag} name={'deceased_flag'} />
											</Grid>

										</Grid>


									</form>
								</CardContent>

							</Card>
						</>
					);
				}}
			</Formik>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IFormPatientDetailsReduxProps => ({
	dropdowns: state.personaBuilder.dropdowns
	// persistenceState: state.personaBuilder.persistenceState
});

export const FormPatientDetails = connect(
	mapStateToProps,
	PersonaBuilderActionCreators
)(FormPatientDetailsBase);

