import * as React from 'react';
import { Formik } from 'formik';
import { IModalForm, IUser } from 'common/interfaces';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { TUserType } from 'common/types/TUserType';
import classes from 'classnames';
import { FormLabel } from 'components/FormElements';
import { connect } from 'react-redux';
import { ApplicationState } from 'stores';
import { ErrorFocus } from 'components/ErrorFocus';
import { addUserValidationSchema } from 'common/validation-schema/userValidationSchema';

interface IFormAddExistingUserProps {
    isParentOrganization?: boolean;
}

interface IFormAddExistingUserState {
    isLoading: boolean;
}

interface IFormAddExistingUserReduxProps {
    currentUserType: TUserType;
    currentOrganization: number;
    isParentOrganization: boolean;
}

export class FormAddExistingUserBase extends React.PureComponent<
    IModalForm & IFormAddExistingUserProps & IFormAddExistingUserReduxProps,
    IFormAddExistingUserState
> {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    setFormLoadingAttribute = (isLoading: boolean) => {
        this.setState({
            isLoading,
        });
    };

    render() {
        let { setFormLoadingAttribute } = this.props;

        const {
            bindSubmitForm,
            formAction,
            defaultFormContent,
            isParentOrganization,
            setErrorMessage,
        } = this.props;

        let initialValues = {
            email: '',
            user_type: (defaultFormContent || {}).user_type || '',
        };

        if (!setFormLoadingAttribute) {
            setFormLoadingAttribute = this.setFormLoadingAttribute;
        }

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    if (!isParentOrganization) {
                        values['user_type'] = 'org-user';
                    }
                    setFormLoadingAttribute(true);
                    if (formAction) {
                        formAction(
                            trimAllStringProperties(values),
                            setErrorMessage,
                            setFormLoadingAttribute
                        );
                    }
                    setSubmitting(false);
                }}
                validationSchema={addUserValidationSchema({
                    isParentOrganization,
                })}
            >
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        submitForm,
                    } = props;
                    if (bindSubmitForm) {
                        bindSubmitForm(submitForm);
                    }
                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <FormLabel htmlFor='email'>Email</FormLabel>
                                <input
                                    id='email'
                                    placeholder='Enter Email'
                                    type='email'
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.email && touched.email ? 'text-input error' : 'text-input'}
                                />
                                {errors.email && touched.email && (
                                    <div className='input-feedback'>{errors.email}</div>
                                )}
                                {isParentOrganization && (
                                    <>
                                        <FormLabel htmlFor='user_type'>User Type</FormLabel>
                                        <select
                                            id='user_type'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.user_type}
                                            className={classes({
                                                default: values.user_type === '',
                                            })}
                                        >
                                            <option value='' disabled>
                                                Please Choose a User Type
											</option>
                                            <option value='org-user'>Organization User</option>
                                            <option value='org-admin'>Organization Admin</option>
                                        </select>
                                        {errors.user_type && touched.user_type && (
                                            <div className='input-feedback'>{errors.user_type}</div>
                                        )}
                                    </>
                                )}
                            </form>
                            <ErrorFocus />
                        </>
                    );
                }}
            </Formik>
        );
    }
}

const mapStateToProps = (state: ApplicationState): IFormAddExistingUserReduxProps => {
    const { currentOrganization, organizations } = state.organization;

    const parentOrgId = organizations[currentOrganization].parent_organization_id;

    return {
        currentUserType:
            state.auth && state.auth.user ? state.auth.user.attributes['custom:user_type'] : null,
        currentOrganization,
        isParentOrganization: !parentOrgId,
    };
};

export const FormAddExistingUser = connect(mapStateToProps)(FormAddExistingUserBase);
