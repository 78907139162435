import * as React from 'react';
import { clone } from 'ramda';
import { convertIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';
import { AppFrame, InnerContainer, PageTitleArea } from 'components/Page';
import { Success } from 'components/Toast';
import { IApiDoc } from 'common/interfaces';
import { canCreateApiDoc } from 'common/utils/canCreateApiDoc';
import { ICanCreateApiDoc } from 'common/interfaces/ICanCreateApiDoc';
import { apiLibraryActionCreators } from 'stores/actions/apiLibraryActions';
import { createApiDocModal } from 'common/modals/createApiDocModal'
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { GridContainer, GridItem, GridItemContentAdd } from 'components/Grid';
import { deleteApiDocModal } from 'common/modals/deleteApiDocModal';
import { editApiDocModal } from 'common/modals/editApiDocModal';
import { IMultistepModal } from 'common/interfaces';
import { THTMLElementEvent } from 'common/types/THTMLElementEvent';
import { modalActionCreators } from 'stores/actions/modalActions';
import { EUpdateState } from 'stores/shared/ENums';
import { Loading } from 'components/Loading';
import { TUserType } from 'common/types/TUserType';
import css from './api-library.module.scss';

const apiViewActionCreators = {
	...apiLibraryActionCreators,
	...modalActionCreators,
};

interface IApiLibraryViewReduxProps {
	apis: IApiDoc[];
	allApisUpdateState: EUpdateState;
	canCreateApiDoc: ICanCreateApiDoc;
	currentUserType: TUserType;
}

export class ApiLibraryViewBase extends React.PureComponent<
	IApiLibraryViewReduxProps & ResolveThunks<typeof apiViewActionCreators>
	> {
	UNSAFE_componentWillMount() {
		this.props.getApiDocs();
	}

	isLoading = () => {
		return !(
			this.props.allApisUpdateState === EUpdateState.Loaded
		);
	};


	render() {
		const { apis, allApisUpdateState, currentUserType, createModal, deleteApiDoc } = this.props;
		const pageDescription: string = 'Browse our library of 3rd party APIs.';
		return (
			<AppFrame> 
				<InnerContainer>
					<PageTitleArea
						description={pageDescription}
					>
						API Library
					</PageTitleArea>
					<GridContainer>
						{currentUserType === 'mihin-admin' && (
							<GridItem type='create' action={event => createModal(createApiDocModal(), event)}>
								<GridItemContentAdd
									label='Add API'
								/>
							</GridItem>
						)}
						{this.isLoading() ? <Loading /> : apis.map((api, index) => (
							<GridItem
								className={css.gridItem}
								key={`api-library-grid-item-${api.id}`}
								location={`/api/${api.id}`}
								actionOptions={
									currentUserType === 'mihin-admin'
										? [
											{
												label: 'Edit API',
												action: event => {
													createModal(editApiDocModal(api), event);
												}
											},
											{
												label: 'Delete API',
												action: event => {
													createModal(deleteApiDocModal(api, deleteApiDoc), event);
												}
											}
										]
										: null
								}
							>
								<div className={css.itemTop}>
									<h5
										dangerouslySetInnerHTML={{
											__html: api.name
										}}
									/>
									<div
										dangerouslySetInnerHTML={{
											__html: api.descr
										}}
									/>
								</div>
							</GridItem>
						))}
					</GridContainer>

				</InnerContainer>
			</AppFrame>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IApiLibraryViewReduxProps => {
	return {
		apis: state.apiLibrary.allApis ? convertIndexedObjectToArray(clone(state.apiLibrary.allApis)) : null,
		allApisUpdateState: state.apiLibrary.allApisUpdateState,
		canCreateApiDoc: canCreateApiDoc(state.auth.user),
		currentUserType: state.auth.user.attributes['custom:user_type']
	};
};

export const ApiLibraryView = connect(
	mapStateToProps,
	apiViewActionCreators
)(ApiLibraryViewBase);
