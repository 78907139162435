import * as React from 'react';
import { Formik } from 'formik';
import { IModalForm } from 'common/interfaces';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { ErrorFocus } from 'components/ErrorFocus';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { Card, CardHeader, CardContent, Grid } from '@material-ui/core';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { patientAllergyValidationSchema } from 'common/validation-schema/personaBuilder/patientAllergyValidationSchema';
import css from './form-patient-allergy.module.scss';
import { IsFormDirty } from 'components/RouteGuards/IsFormDirty';

interface IFormPatientAllergyState {
	isLoading: boolean;
}

interface IFormPatientAllergyReduxProps {
  hl7AllergyReactions: any;
  hl7AllergySubstances: any;
  allergy_criticalities: any;
  allergy_severities: any;
  allergy_categories: any;
  allergy_exposure_routes: any;
}

export class FormPatientAllergyBase extends React.PureComponent<
ResolveThunks<typeof PersonaBuilderActionCreators> &
IModalForm &
IFormPatientAllergyReduxProps,
IFormPatientAllergyState> {
  constructor(props) {
		super(props);
		this.state = {
			isLoading: false
		};
  }
  
	setFormLoadingAttribute = (isLoading: boolean) => {
		this.setState({
			isLoading
		});
	};

  render() {
    let { setFormLoadingAttribute } = this.props;
    const {
      bindSubmitForm,
      formAction,
      defaultFormContent,
      setErrorMessage,
      hl7AllergyReactions,
      hl7AllergySubstances,
      allergy_criticalities,
      allergy_severities,
      allergy_categories,
      allergy_exposure_routes,
      setSaveDisabled
    } = this.props;

		let initialValues = {
      allergy_intolerance_name: '',
      clinical_status: '',
      allergy_category_id: '',
      allergy_severity_id: '',
      allergy_critical_value_id: '',
      onset_date: '',
      onset_age: '',
      allergy_reaction_id: '',
      allergy_substance_id: '',
      allergy_exposure_route_id: ''
		};

		const isEditMode = defaultFormContent.id !== 0;

		if (isEditMode) {
			initialValues = defaultFormContent;
		}
		if (!setFormLoadingAttribute) {
			setFormLoadingAttribute = this.setFormLoadingAttribute;
    }
    
    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setFormLoadingAttribute(true);
          if (formAction) {
            const trimmedValues = trimAllStringProperties(values);
            formAction(trimmedValues, setErrorMessage, setFormLoadingAttribute);
            resetForm(trimmedValues);
          }
          setSubmitting(false);
        }}
        validationSchema={patientAllergyValidationSchema()}
      >
        {props => {
          const {
						values,
						touched,
						errors,
						dirty,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
						handleReset,
						submitForm,
						isValid
					} = props;
					if (bindSubmitForm) {
						bindSubmitForm(submitForm);
          }
          return (
            <>
              <IsFormDirty
                title=""
                message=""
                dirty={dirty}
              />
              <form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={6} className={css.formColumn}>
                    <Card className={css.formCard}>
                      <CardHeader title="General Information" className={css.fieldsetHeader} />
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <label>Name</label>
                            <input
                              id='allergy_intolerance_name'
                              placeholder='Enter Name'
                              type='text'
                              value={values.allergy_intolerance_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={errors.allergy_intolerance_name && touched.allergy_intolerance_name ? 'text-input error' : 'text-input'}
                              autoComplete='allergy_intolerance_name'
                              maxLength={128}
                            />
                            {errors.allergy_intolerance_name && touched.allergy_intolerance_name && (
                              <div className='input-feedback'>{errors.allergy_intolerance_name}</div>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>Clinical Status</label>
                            <select
                              id='clinical_status'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.clinical_status}
                              className={errors.clinical_status && touched.clinical_status ? 'text-input error' : 'text-input'}
                            >
                              <option value={""}>Select</option>
                              <option value={"active"}>Active</option>
                              <option value={"inactive"}>Inactive</option>
                            </select>
                            {errors.clinical_status && touched.clinical_status && (
                              <div className='input-feedback'>{errors.clinical_status}</div>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>Category</label>
                            <select
                              id='allergy_category_id'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.allergy_category_id}
                              className={errors.allergy_category_id && touched.allergy_category_id ? 'text-input error' : 'text-input'}
                            >
                              <option value={""}>Select</option>
                              {allergy_categories.map((category, index) => (
                                <option key={index} value={category.id}>
                                  {category.allergy_category_display}
                                </option>
                              ))}
                            </select>
                            {errors.allergy_category_id && touched.allergy_category_id && (
                              <div className='input-feedback'>{errors.allergy_category_id}</div>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>Criticality</label>
                            <select
                              id='allergy_critical_value_id'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.allergy_critical_value_id}
                              className={errors.allergy_critical_value_id && touched.allergy_critical_value_id ? 'text-input error' : 'text-input'}
                            >
                              <option value={""}>Select</option>
                              {allergy_criticalities.map((criticality, index) => (
                                <option key={index} value={criticality.id}>
                                  {criticality.allergy_criticality_display}
                                </option>
                              ))}
                            </select>
                            {errors.allergy_critical_value_id && touched.allergy_critical_value_id && (
                              <div className='input-feedback'>{errors.allergy_critical_value_id}</div>
                            )}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                    <Card className={css.formCard}>
                      <CardHeader title="Allergy Reactions" className={css.fieldsetHeader} />
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <label>Reaction ID</label>
                            <select
                              id='allergy_reaction_id'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.allergy_reaction_id}
                              className={errors.allergy_reaction_id && touched.allergy_reaction_id ? 'text-input error' : 'text-input'}
                            >
                              <option value={""}>Select</option>
                              {hl7AllergyReactions.map((reaction, index) => (
                                <option key={index} value={reaction.id}>
                                  {reaction.allergy_reaction_display} ({reaction.allergy_reaction_snomed})
                                </option>
                              ))}
                            </select>
                            {errors.allergy_reaction_id && touched.allergy_reaction_id && (
                              <div className='input-feedback'>{errors.allergy_reaction_id}</div>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>Severity</label>
                            <select
                              id='allergy_severity_id'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.allergy_severity_id}
                              className={errors.allergy_severity_id && touched.allergy_severity_id ? 'text-input error' : 'text-input'}
                            >
                              <option value={""}>Select</option>
                              {allergy_severities.map((severity, index) => (
                                <option key={index} value={severity.id}>
                                  {severity.allergy_severity_display}
                                </option>
                              ))}
                            </select>
                            {errors.allergy_severity_id && touched.allergy_severity_id && (
                              <div className='input-feedback'>{errors.allergy_severity_id}</div>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>Exposure Route</label>
                            <select
                              id='allergy_exposure_route_id'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.allergy_exposure_route_id}
                              className={errors.allergy_exposure_route_id && touched.allergy_exposure_route_id ? 'text-input error' : 'text-input'}
                            >
                              <option value={""}>Select</option>
                              {allergy_exposure_routes.map((exposure_route, index) => (
                                <option key={index} value={exposure_route.id}>
                                  {exposure_route.allergy_exposure_route_display} ({exposure_route.allergy_exposure_route_snomed})
                                </option>
                              ))}
                            </select>
                            {errors.allergy_exposure_route_id && touched.allergy_exposure_route_id && (
                              <div className='input-feedback'>{errors.allergy_exposure_route_id}</div>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>Substances</label>
                            <select
                              id='allergy_substance_id'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.allergy_substance_id}
                              className={errors.allergy_substance_id && touched.allergy_substance_id ? 'text-input error' : 'text-input'}
                            >
                              <option value={""}>Select</option>
                              {hl7AllergySubstances.map((substance, index) => (
                                <option key={index} value={substance.id}>
                                  {substance.allergy_substance_display} ({substance.allergy_substance_snomed})
                                </option>
                              ))}
                            </select>
                            {errors.allergy_substance_id && touched.allergy_substance_id && (
                              <div className='input-feedback'>{errors.allergy_substance_id}</div>
                            )}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} className={css.formColumn}>
                    <Card className={css.formCard}>
                      <CardHeader title="Onset" className={css.fieldsetHeader} />
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <label>Date</label>
                            <input
                              id='onset_date'
                              placeholder='Enter Onset Date'
                              type='text'
                              value={values.onset_date}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={errors.onset_date && touched.onset_date ? 'text-input error' : 'text-input'}
                              autoComplete='onset_date'
                              maxLength={128}
                            />
                            {errors.onset_date && touched.onset_date && (
                              <div className='input-feedback'>{errors.onset_date}</div>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>Age</label>
                            <input
                              id='onset_age'
                              placeholder='Enter Onset Age'
                              type='text'
                              value={values.onset_age}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={errors.onset_age && touched.onset_age ? 'text-input error' : 'text-input'}
                              autoComplete='onset_age'
                              maxLength={128}
                            />
                            {errors.onset_age && touched.onset_age && (
                              <div className='input-feedback'>{errors.onset_age}</div>
                            )}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <ErrorFocus/>
                </Grid>
              </form>
            </>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state: ApplicationState): IFormPatientAllergyReduxProps => ({
  hl7AllergyReactions: state.personaBuilder.hl7Valuesets.allergy_reactions,
  hl7AllergySubstances: state.personaBuilder.hl7Valuesets.allergy_substances,
  allergy_criticalities: state.personaBuilder.dropdowns.allergy_criticalities,
  allergy_severities: state.personaBuilder.dropdowns.allergy_severities,
  allergy_categories: state.personaBuilder.dropdowns.allergy_categories,
  allergy_exposure_routes: state.personaBuilder.dropdowns.allergy_exposure_routes
});

export const FormPatientAllergy = connect(mapStateToProps,PersonaBuilderActionCreators)(FormPatientAllergyBase);