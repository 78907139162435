export interface INotification {
    id: number;
    type: NotifcationTypes;
    message: string;
}

export enum NotifcationTypes {
    error = "error",
    warning = "warning",
    info = "info",
    success = "success",
}