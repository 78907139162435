import * as React from 'react';
import { Route, Switch } from 'react-router';

import * as Views from 'views';
import { HasLoggedIn, HasAcceptedTerms, IsUserOfType } from 'components/RouteGuards';

export const Routes = (
	<Switch>
		<Route exact path='/' component={HasLoggedIn(HasAcceptedTerms(Views.DashboardView))} />
		<Route exact path='/login' component={Views.LoginView} />
		<Route exact path='/forgot-password' component={Views.ForgotPasswordView} />
		<Route exact path='/password-reset' component={Views.PasswordResetView} />
		<Route exact path='/create-account' component={Views.CreateAccountView} />
		<Route exact path='/new-password-required' component={Views.NewPasswordRequiredView} />
		<Route exact path='/terms' component={Views.TermsOfServiceView} />
		<Route exact path='/agreement' component={HasLoggedIn(Views.AcceptTermsOfService)} />
		<Route exact path='/settings' component={HasLoggedIn(HasAcceptedTerms(Views.AccountSettingsView))} />
		<Route
			exact
			path='/organization'
			component={HasLoggedIn(
				HasAcceptedTerms(
					IsUserOfType(Views.OrganizationView, [
						'mihin-admin',
						//todo: Remove Account Admin user type when testing is done: ok to do after Aug 20, 2019
						'account-admin',
						'org-admin',
						'org-user'
					])
				)
			)}
		/>
		<Route
			exact
			path='/organization/:id'
			component={HasLoggedIn(
				HasAcceptedTerms(
					IsUserOfType(Views.OrganizationView, [
						'mihin-admin',
						//todo: Remove Account Admin user type when testing is done: ok to do after Aug 20, 2019
						'account-admin',
						'org-admin',
						'org-user'
					])
				)
			)}
		/>
		<Route
			exact
			path='/organization-management'
			component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.OrganizationManagementView, ['mihin-admin'])))}
		/>
		<Route
			exact
			path='/user-management'
			component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.UserManagementView, ['mihin-admin'])))}
		/>
		<Route
			exact
			path='/rof-management'
			component={HasLoggedIn(HasAcceptedTerms(Views.RofManagementView))}
		/>
		<Route
			path='/sandbox/:rofId/pit/:pitId/app/:smartAppId'
			key='/pit/:id'
			component={HasLoggedIn(HasAcceptedTerms(Views.SmartAppView))}
		/>
		<Route
			exact
			path='/notification-center'
			component={HasLoggedIn(HasAcceptedTerms(Views.NotificationsView))}
		/>
		<Route
			path='/sandbox/:rofId/pit/:pitId'
			key='/pit/:id'
			component={HasLoggedIn(HasAcceptedTerms(Views.PitView))}
		/>
		<Route path='/sandbox/:id' key='/sandbox/:id' component={HasLoggedIn(HasAcceptedTerms(Views.RofView))} />
		<Route exact path='/persona' component={HasLoggedIn(HasAcceptedTerms(Views.PersonaLibraryView))} />
		<Route exact path='/persona/history' component={HasLoggedIn(HasAcceptedTerms(Views.PersonaLibraryView))} />
		<Route exact path='/persona/:id' component={HasLoggedIn(HasAcceptedTerms(Views.PersonaView))} />

		<Route exact path='/api' component={HasLoggedIn(HasAcceptedTerms(Views.ApiLibraryView))} />
		<Route exact path='/api/:id' component={HasLoggedIn(HasAcceptedTerms(Views.ApiView))} />
		<Route exact path='/code-sample' component={HasLoggedIn(HasAcceptedTerms(Views.CodeSampleLibraryView))} />
		<Route exact path='/code-sample/:id' component={HasLoggedIn(HasAcceptedTerms(Views.CodeSampleView))} />

		<Route exact path='/persona-builder' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.PersonaBuilderView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/patient-listing' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.PatientListingView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/patient-listing/family' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.PatientListingView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/patient-listing/family/:id' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.FamilyMemberDashboardView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/patient-listing/family/:id/condition' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.FamilyMemberDashboardView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/patient-listing/:id' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.PatientDashboardView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/patient-listing/:id/description' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.PatientDashboardView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/patient-listing/:id/address' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.PatientDashboardView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/patient-listing/:id/condition' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.PatientDashboardView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/patient-listing/:id/allergy' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.PatientDashboardView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/patient-listing/:id/family-history' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.PatientDashboardView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/patient-listing/:id/coverage' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.PatientDashboardView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/patient-listing/:id/medication' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.PatientDashboardView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/hl7valueset/:type' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.HL7ValuesetView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/organization-listing' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.OrganizationListingView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/organization-listing/:id' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.OrganizationDashboardView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/provider-listing' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.ProviderView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/provider-listing/:id' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.ProviderDashboardView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/provider-listing/:id/description' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.ProviderDashboardView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/provider-listing/:id/time-slots' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.ProviderDashboardView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/medication-listing' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.MedicationView, ['mihin-admin'])))} />
		<Route exact path='/persona-builder/medication-listing/:id' component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.MedicationDashboardView, ['mihin-admin'])))} />

		<Route exact path='/faq' component={HasLoggedIn(HasAcceptedTerms(Views.FaqView))} />
		<Route
			exact
			path='/notifications'
			component={HasLoggedIn(HasAcceptedTerms(IsUserOfType(Views.WebsocketView, ['mihin-admin'])))}
		/>
		<Route component={HasLoggedIn(HasAcceptedTerms(Views.PageNotFoundView))} />
	</Switch>
);
