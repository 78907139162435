import * as yup from 'yup';
import { validatePhoneNumber } from 'common/utils/validatePhoneNumber';

interface IUserValidationSchemaConfig {
	isEditSchema?: boolean;
	isParentOrganization?: boolean;
}

interface IUserValidationSchema {
	username?: any;
	email?: any;
	user_type?: any;
	phone_number: any;
}

interface IAddUserValidationSchema {
	email?: any;
	user_type?: any;
}

interface IAddUserValidationSchemaConfig {
	isParentOrganization?: boolean;
}

export const userValidationSchema = (
	userValidationSchemaConfig: IUserValidationSchemaConfig = {
		isEditSchema: false
	}
) => {
	let schemaDefinition;
	
	schemaDefinition = {
		phone_number: yup.string().test(validatePhoneNumber)
	};

	if (userValidationSchemaConfig.isParentOrganization) {
		schemaDefinition = {
			...schemaDefinition,
			user_type: yup.string().required('Required')
		};
	}

	if (!userValidationSchemaConfig.isEditSchema) {
		schemaDefinition = {
			...schemaDefinition,
			username: yup.string().required('Required'),
			email: yup
				.string()
				.email()
				.required('Required')
		};
	}

	return yup.object().shape<any>(schemaDefinition) as any;
};


export const addUserValidationSchema = (
	addUserValidationSchemaConfig: IAddUserValidationSchemaConfig = {
	}
) => {
	let schemaDefinition;
	schemaDefinition = {
		email: yup
			.string()
			.email()
			.required('Required')
	};

	if (addUserValidationSchemaConfig.isParentOrganization) {
		schemaDefinition = {
			...schemaDefinition,
			user_type: yup.string().required('Required')
		};
	}

	return yup.object().shape<any>(schemaDefinition) as any;
};
