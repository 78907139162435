import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { push } from 'connected-react-router';
import { authActionCreators } from 'stores/actions/authActions';
import { ApplicationState } from 'stores';
import { EUpdateState } from 'stores/shared/ENums';
import { ICognitoUser } from 'common/interfaces';

interface IIsLoggedInComponentReduxProps {
	isLoggedIn: boolean;
	userUpdateState: EUpdateState;
}

const isLoggedInComponentActionCreators = {
	...authActionCreators,
	push
};

export const HasLoggedIn = WrappedComponent => {
	class IsLoggedInComponent extends React.Component<
		IIsLoggedInComponentReduxProps &
			ResolveThunks<typeof isLoggedInComponentActionCreators>
	> {
		UNSAFE_componentWillMount() {
			this.props.getAuth();
		}

		componentDidMount() {
			this._checkAndRedirect();
		}

		componentDidUpdate() {
			this._checkAndRedirect();
		}

		_checkAndRedirect() {
			const { isLoggedIn, userUpdateState } = this.props;

			if (!isLoggedIn && userUpdateState == EUpdateState.Loaded) {
				this.props.push('/login');
			}
		}

		render() {
			const { userUpdateState } = this.props;
			const canRender = userUpdateState === EUpdateState.Loaded;
			return (
				<>
					{canRender && (
						<div className='authenticated'>
							{this.props.isLoggedIn ? (
								<WrappedComponent {...this.props} />
							) : null}
						</div>
					)}
				</>
			);
		}
	}

	const mapStateToProps = (
		state: ApplicationState
	): IIsLoggedInComponentReduxProps => {
		return {
			isLoggedIn:
				state.auth.user && state.auth.user.signInUserSession
					? true
					: false,
			userUpdateState: state.auth.userUpdateState
		};
	};

	return connect(
		mapStateToProps,
		isLoggedInComponentActionCreators
	)(IsLoggedInComponent);
};
