import * as React from 'react';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Card, Typography, Grid } from '@material-ui/core';
import { Edit, ArrowDownward, Add, ChevronLeft, ChevronRight, Warning } from '@material-ui/icons';
import DataTable from 'react-data-table-component';
import { MuiButton } from 'components/MaterialUI/index';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { EUpdateState } from 'stores/shared/ENums';
import { IPatient } from 'common/interfaces/personaBuilder/IPatient';
import classes from 'classnames';
import { IPatientAllergyIntolerance } from 'common/interfaces/personaBuilder/IPatientAllergyIntolerance';
import cssDetails from 'components/Forms/PersonaBuilder/FormPersonaBuilder/FormPatientDetails/persona-details.module.scss';
import css from './patient-allergy-tab.module.scss';
import { FormPatientAllergy } from 'components/Forms/PersonaBuilder/FormPersonaBuilder/FormPatientAllergy/FormPatientAllergy';
const sortIcon = <ArrowDownward />;

interface IPatientAllergyRouteProps {
	id?: string
}

interface IPatientAllergyTabReduxProps {
	currentPatient: IPatient;
	patientAllergyIntolerances: IPatientAllergyIntolerance[];
	allergySeverities: any;
	currentUserType: string;
	applyPersonaUpdateState: EUpdateState;
}

const PatientAllergyTabBase : React.FC<
	RouteComponentProps<IPatientAllergyRouteProps> & 
	IPatientAllergyTabReduxProps & 
	ResolveThunks<typeof PersonaBuilderActionCreators>
	> = props => {

	let _submitForm = null;
	const { currentPatient, patientAllergyIntolerances, allergySeverities } = props;	
	const [currentAllergyIntolerance, setCurrentAllergyIntolerance] = React.useState(null);
	const [isAddMode, setIsAddMode] = React.useState(false);

	const newAllergyIntolerance = {
		id: 0,
		allergy_intolerance_name: '',
		clinical_status: '',
		allergy_category_id: '',
		allergy_severity_id: '',
		allergy_critical_value_id: '',
		onset_date: '',
		onset_age: '',
		allergy_reaction_id: '',
		allergy_substance_id: '',
		allergy_exposure_route_id: ''
	};

	const bindSubmitForm = (submitForm) => {
		_submitForm = submitForm;
	};

	const handleSubmit = () => {
		if (_submitForm){
			_submitForm();
		}
	};

	const saveAllergyIntolerance = (values) => {
		if ('id' in values) {
			props.updatePatientAllergyIntolerance(values, currentPatient.id).then(()=>{
				props.getPatientAllergyIntolerances(currentPatient.id.toString());
				setForm(null, false);
			}).catch((e)=>{console.log(e)});
		}
		else {
			// create data
			props.createPatientAllergyIntolerance(values, currentPatient.id).then(()=>{
				props.getPatientAllergyIntolerances(currentPatient.id.toString());
				setForm(null, false);
			}).catch((e)=>{console.log(e)});
		}
	};

	const deleteAllergyIntolerance = () => {
		props.deletePatientAllergyIntolerance(currentAllergyIntolerance, currentPatient.id).then(()=>{
			props.getPatientAllergyIntolerances(currentPatient.id.toString());
			setForm(null, false);
		}).catch((e)=>{console.log(e)});
	};

	const setForm = (allergyIntolerance, isAddMode) => {
		setCurrentAllergyIntolerance(allergyIntolerance);
		setIsAddMode(isAddMode);
	};


	if (patientAllergyIntolerances && allergySeverities) {
		patientAllergyIntolerances.forEach(function(allergyIntolerance) {
			let allergySeverityName = "";
			allergySeverities.forEach(function(severity) {
				if (severity.id === allergyIntolerance.allergy_severity_id) allergySeverityName = severity.allergy_severity_display; 
			});
			allergyIntolerance.allergy_severity_name = allergySeverityName;
		});
	}

	const columns = [
		{
			name: '',
			cell: (allergy) => <Button onClick={() => setForm(allergy, false)}><Edit /></Button>,
			button: true
		},
		{
			name: 'Name',
			sortable: true,
			selector: 'allergy_intolerance_name',
		},
		{
			name: 'Clinical Status',
			sortable: true,
			selector: 'clinical_status',
		},
		{
			name: 'Onset Date',
			sortable: true,
			selector: 'onset_date',
		},
		{
			name: 'Severity',
			sortable: true,
			selector: 'allergy_severity_name'
		}
	];

	return (
		<>
			{currentAllergyIntolerance !== null ? (
				<>
					<Grid container className={css.formHeader}>
						{isAddMode && 
							<Grid item xs={12} className={css.addConfirmationContainer}>
								<Warning /> You are <strong>adding a new patient allergy</strong> for {currentPatient.first_name} {currentPatient.last_name}.
							</Grid>
						}
						<Grid item xs={4}>
							<h4>Allergy &amp; Intolerance {isAddMode && <span className={css.newBug}>New</span>}</h4>
						</Grid>
						<Grid item xs={8} alignContent={'flex-end'} className={css.formHeaderAction}>
							{isAddMode ? (
								<MuiButton variant="contained" className={cssDetails.button} onClick={() => setForm(null, false)}>
									Cancel
								</MuiButton>
							) : (
								<div>
									<small>Would you like to unassign any allergy and intolerance information from your patient?</small>
									<MuiButton variant="contained" className={cssDetails.button} onClick={() => deleteAllergyIntolerance()}>
										Remove this Data
									</MuiButton>
								</div>
							)}
						</Grid>
					</Grid>
					<FormPatientAllergy defaultFormContent={currentAllergyIntolerance} formAction={saveAllergyIntolerance} bindSubmitForm={bindSubmitForm} />
				</>
			) : (
				<>
					{patientAllergyIntolerances.length > 0 && 
						<Grid container className={css.listContainer}>
							<Grid item xs={12} className={css.addButtonRow}>
								<MuiButton onClick={() => setForm(newAllergyIntolerance, true)}>Add Allergy &amp; Intolerance <Add /></MuiButton>
							</Grid>
							<Grid item xs={6}>
								<h4>Allergy &amp; Intolerance</h4>
							</Grid>
							<Grid item xs={6}>
								<input placeholder='Search Allergy &amp; Intolerance' type='text' />
							</Grid>
							<Grid item xs={12}>
								<Card className={css.listCard}>
									<DataTable
										className={css.dataTable}
										columns={columns}
										data={patientAllergyIntolerances}
										noHeader
										highlightOnHover
										defaultSortField="allergy_intolerance_name"
										sortIcon={sortIcon}
										pagination
										paginationRowsPerPageOptions={[10,25,50,100]}
									/>
								</Card>
							</Grid>
						</Grid>
					}
					{patientAllergyIntolerances.length < 1 && 
						<Grid container className={css.noDataContainer}>
							<Grid item xs={12}>
								<Typography variant={'h4'}>No known allergies and intolerances.</Typography>
							</Grid>
							<Grid item xs={12}>
								<p>Would you like to assign allergie and intolerance information to your patient?</p>
								<MuiButton onClick={() => setForm(newAllergyIntolerance, true)}>Assign Allergy &amp; Intolerance Data</MuiButton>
							</Grid>
						</Grid>
					}
				</>
			)}
			<Grid container xs={12} className={cssDetails.actionsContainer}>
				<Grid container xs={12} md={6} justify={'flex-start'} alignContent={'center'}>
					<Button component={Link} to={`/persona-builder/patient-listing/${currentPatient.id}/medication`}>
						<ChevronLeft className={classes([cssDetails.leftIcon], [cssDetails.iconSmall])}/>
						Medication
					</Button>
				</Grid>
				<Grid container xs={12} md={6} justify={'flex-end'} alignContent={'center'}>
					<MuiButton variant="contained" className={cssDetails.button} disabled={currentAllergyIntolerance === null} onClick={handleSubmit}>
						Save
					</MuiButton>
					<Button component={Link} to={`/persona-builder/patient-listing/${currentPatient.id}/address`}>
						Family
						<ChevronRight className={classes([cssDetails.rightIcon], [cssDetails.iconSmall])}/>
					</Button>
				</Grid>
			</Grid>
		</>
	);
}

const mapStateToProps = (state: ApplicationState): IPatientAllergyTabReduxProps => {
	return {
		currentPatient: state.personaBuilder.currentPatient,
		allergySeverities: state.personaBuilder.dropdowns.allergy_severities,
		patientAllergyIntolerances: state.personaBuilder.currentPatientAllergyIntolerances,
		
		currentUserType: ((state.auth || {}).user || {}).attributes['custom:user_type'],
		applyPersonaUpdateState: state.persona.applyPersonaUpdateState
	};
};

export const PatientAllergyTab = connect(
	mapStateToProps,
	PersonaBuilderActionCreators
)(PatientAllergyTabBase);
