import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { ApplicationState } from 'stores';
import { IPatient } from 'common/interfaces/personaBuilder/IPatient';
import { IPatientFamilyMember } from 'common/interfaces/personaBuilder/IPatientFamilyMember';
import { Card, CardContent, Button, Grid } from '@material-ui/core';
import { ChevronLeft, ChevronRight, Delete } from '@material-ui/icons';
import { MuiButton } from 'components/MaterialUI';
import classes from 'classnames';
import cssDashboard from '../familymember-dashboard.module.scss';
import { FormPatientFamilyMember } from 'components/Forms/PersonaBuilder/FormPersonaBuilder/FormPatientFamilyMember';

interface IFamilyMemberDetailsRouteProps {
	id?: string
}

interface IFamilyMemberDetailsTabReduxProps {
	currentPatient: any;
	familyMembers: IPatientFamilyMember[];
}

export const FamilyMemberDetailsTabBase: React.FC<RouteComponentProps<IFamilyMemberDetailsRouteProps> &
IFamilyMemberDetailsTabReduxProps &
ResolveThunks<typeof PersonaBuilderActionCreators>> = props => {
	let _submitForm = null;
  const { currentPatient, familyMembers } = props;

  const familyMemberDetails = familyMembers.filter((member) => { return member.patient_family_member_id === currentPatient.id; });
  const familyMember = props.match.params.id === "create" ? {} : {
    ...(familyMemberDetails.length > 0 ? familyMemberDetails[0] : {}), 
    ...currentPatient
  };

	const history = useHistory();
	const prevLink = familyMember ? `/persona-builder/patient-listing/family` : '';
	const nextLink = familyMember ? `/persona-builder/patient-listing/family/${props.match.params.id}/condition` : '';

	const _bindSubmitForm = (submitForm) => {
		_submitForm = submitForm;
	};

	const handleSubmit = () => {
		if (_submitForm){
			_submitForm();
		}
  };

  const saveFamilyMember = (values) => {
    const savePatient: IPatient = {...values};
    const saveFamilyMember: IPatientFamilyMember = {...values};

    if (!!values.patient_family_member_id) {
      // update
      props.updatePatientFamilyMember(saveFamilyMember, savePatient).then(()=>{
        props.getPatient(savePatient.id.toString());
        props.getAllPatients();
        props.getAllFamilyMembers();
      }).catch((e)=>{console.log(e)});
    }
    else {
      // add
      props.createPatientFamilyMember(saveFamilyMember, savePatient).then((response)=>{
        props.getAllPatients();
        props.getAllFamilyMembers();
        history.push("/persona-builder/patient-listing/family");
      }).catch((e)=>{console.log(e)});
    }
  }
  const deleteFamilyMember = () => {
    props.deletePatientFamilyMember(familyMember).then(()=>{
      props.getAllFamilyMembers();
      history.push("/persona-builder/patient-listing/family");
    }).catch((e)=>{console.log(e)});
  }
  
  return (
    <>
      <Grid container>
        <Grid item>
          <Card>
            <CardContent>
              <FormPatientFamilyMember
                defaultFormContent={familyMember}
                bindSubmitForm={_bindSubmitForm}
                formAction={saveFamilyMember}
                deleteAction={deleteFamilyMember}
                forPatientFamilyHistoryTab={false}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container className={cssDashboard.actionsContainer}>
				<Grid item xs={12} md={6}>
					<Button onClick={() => history.push(`${prevLink}`)}>
						<ChevronLeft className={classes([cssDashboard.leftIcon], [cssDashboard.iconSmall])}/>
						Back
					</Button>
				</Grid>
				<Grid item xs={12} md={6} style={{textAlign: 'right'}}>
					<MuiButton variant="contained" className={cssDashboard.button} disabled={familyMember === null} onClick={handleSubmit}>
						Save
					</MuiButton>
					<Button onClick={() => history.push(`${nextLink}`)}>
						Coverage
						<ChevronRight className={classes([cssDashboard.rightIcon], [cssDashboard.iconSmall])}/>
					</Button>
				</Grid>
			</Grid>
    </>
  )
}

const mapStateToProps = (state: ApplicationState): IFamilyMemberDetailsTabReduxProps => {
	return {
		currentPatient: state.personaBuilder.currentPatient || {},
		familyMembers: state.personaBuilder.allFamilyMembers
	};
};

export const FamilyMemberDetailsTab = connect(
	mapStateToProps,
	PersonaBuilderActionCreators
)(FamilyMemberDetailsTabBase);