import * as yup from 'yup';
import { validatePhoneNumber } from 'common/utils/validatePhoneNumber';

interface IPatientMedicationValidationSchemaConfig {
}

interface PatientMedicationValidationSchema {
	provider_id: number;
	patient_condition_id: number;
	medication_management_id: number;
	prescription_status_id: number;
}

export const patientMedicationValidationSchema = (
	patientMedicationValidationSchemaConfig: IPatientMedicationValidationSchemaConfig = {}
) => {
	let schemaDefinition = {
		medication_management_id: yup.number().typeError('Prescription Medication is invalid.').required('Prescription Medication is required.'),
		patient_condition_id: yup.number().typeError('For Condition is invalid.').required('For Condition is required.'),
		provider_id: yup.number().typeError('Prescribed by is invalid.').required('Prescribed by is required.'),
		prescription_status_id: yup.number().typeError('Status is invalid.').required('Status is required.'),
	};

	return yup.object().shape<any>(schemaDefinition) as any;
};
