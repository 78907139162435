import * as React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';

export const DatePickerField = ({ field, form, ...other }) => {
    const currentError = form.errors[field.name];
    return (
        <KeyboardDatePicker
            clearable
            name={field.name}
            value={field.value}
            format='dd/MM/yyyy'
            error={Boolean(currentError)}
            onChange={date => form.setFieldValue(field.name, date, true)}
            {...other}
        />
    );
};