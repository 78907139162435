import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import rootReducer from 'stores';

export const sharedHistory = createBrowserHistory();

const composeEnhancer: typeof compose =
	(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const sharedStore = createStore(
	rootReducer(sharedHistory),
	composeEnhancer(
		applyMiddleware(routerMiddleware(sharedHistory)),
		applyMiddleware(thunk)
	)
);
