import * as React from 'react';
import { Field, Formik } from 'formik';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { ErrorFocus } from 'components/ErrorFocus';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { Grid } from '@material-ui/core';
import { Button } from 'components/Button';
import { Delete, Warning } from '@material-ui/icons';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { convertToISODateString } from 'common/utils/convertToISODateString';
import { getDayDiffBetweenDates } from 'common/utils/getDayDiffBetweenDates';
import { addDaysToDate } from 'common/utils/addDaysToDate';
import { addYearsToDate } from 'common/utils/addYearsToDate';
import { TSetFormLoadingAttribute } from 'common/types/TSetFormLoadingAttribute';
import { TSetErrorMessage } from 'common/types/TSetErrorMessage';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { IPatientCoverage } from 'common/interfaces/personaBuilder/IPatientCoverage';
import { IsFormDirty } from 'components/RouteGuards/IsFormDirty';

import { patientCoverageValidationSchema } from 'common/validation-schema/personaBuilder/patientCoverageValidationSchema';
import css from './form-patient-coverage.module.scss';
import classes from 'classnames';
import { DatePickerField } from 'components/FormElements/DatePickerField'

interface IFormPatientCoverageState {
  isLoading: boolean;
  confirmDelete: boolean;
  initialValues: any;
  startDateLimit?: Date;
  isEditMode: boolean;
  coverageLengthLimitError: boolean;
}

interface IFormPatientCoverageProps {
  cancelModalAction?: any;
  deleteAction?: any;
  defaultFormContent?: any;
  previousCoverageEndDate?: Date;
  formAction?: any;
  bindSubmitForm?: (any) => void;
  setFormLoadingAttribute?: TSetFormLoadingAttribute;
  setErrorMessage?: TSetErrorMessage;
}

interface IFormPatientCoverageReduxProps {
  statuses: any;
  coverage_types: any;
  dependent_relationships: any;
  insurance_organizations: any;
  coverage_class_types: any;
  patientCoverages: IPatientCoverage[];
}

export class FormPatientCoverageBase extends React.PureComponent<
  ResolveThunks<typeof PersonaBuilderActionCreators> &
  IFormPatientCoverageProps &
  IFormPatientCoverageReduxProps,
  IFormPatientCoverageState> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      confirmDelete: false,
      startDateLimit: this.props.previousCoverageEndDate,
      isEditMode: false,
      coverageLengthLimitError: false,
      initialValues: {
        coverage_period_start: null,
        coverage_period_end: null,
        coverage_status_id: '',
        coverage_type_id: '',
        dependent: '',
        coverage_dependent_relationship_id: '',
        organization_id: '',
        coverage_class_id: '',
        latest_flag: false
      }
    };
  }

  componentDidMount() {
    const { patientCoverages, defaultFormContent } = this.props;
    const isEditMode = defaultFormContent && defaultFormContent.id !== 0;

    if (!isEditMode) {
      const startDate = patientCoverages.length > 0 ? addDaysToDate(patientCoverages[0].coverage_period_end, 1) : new Date();
      const endDate = addYearsToDate(startDate, 1);
      defaultFormContent.coverage_period_start = startDate;
      defaultFormContent.coverage_period_end = endDate;
    }
    this.setState({
      initialValues: defaultFormContent,
      isEditMode: isEditMode
    });
  }

  setFormLoadingAttribute = (isLoading: boolean) => {
    this.setState({
      isLoading
    });
  };

  handleFormSubmit = (values, setSubmitting, resetForm) => {
    if (getDayDiffBetweenDates(values.coverage_period_start, values.coverage_period_end) > 366) {
      this.setState({ coverageLengthLimitError: true })
      return false;
    };

    const { formAction, setErrorMessage } = this.props;
    this.setFormLoadingAttribute(true);
    if (formAction) {
      values.coverage_period_start = convertToISODateString(values.coverage_period_start, true);
      values.coverage_period_end = convertToISODateString(values.coverage_period_end, true);

      const trimmedValues = trimAllStringProperties(values);
      formAction(trimmedValues, setErrorMessage, this.setFormLoadingAttribute);
      resetForm(trimmedValues);
    }
    setSubmitting(false);
  };

  render() {
    let { setFormLoadingAttribute } = this.props;
    const {
      bindSubmitForm,
      formAction,
      defaultFormContent,
      setErrorMessage,
      statuses,
      coverage_types,
      insurance_organizations,
      dependent_relationships,
      coverage_class_types,
      patientCoverages,
      setSaveDisabled,
      cancelModalAction,
      deleteAction
    } = this.props;

    if (!setFormLoadingAttribute) {
      setFormLoadingAttribute = this.setFormLoadingAttribute;
    }

    return (
      <>
        <Formik
          initialValues={this.state.initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting, resetForm }) => this.handleFormSubmit(values, setSubmitting, resetForm)}
          validationSchema={patientCoverageValidationSchema()}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              submitForm,
              isValid
            } = props;
            if (bindSubmitForm) {
              bindSubmitForm(submitForm);
            }
            return (
              <>
                <IsFormDirty
                  title=""
                  message=""
                  dirty={dirty}
                />
                <form onSubmit={handleSubmit}>
                  <Grid container className={css.formContainer}>
                    <Grid item xs={6} className={css.formColumn}>
                      <Grid container>
                        <Grid item xs={6}>
                          <h4>Coverage</h4>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                          {this.state.isEditMode && <Button type="button" style="link" className={css.deleteConfirmationButton} onClick={deleteAction}>Delete <Delete /></Button>}
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <label>Status</label>
                        <select
                          name='coverage_status_id'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.coverage_status_id}
                          disabled={this.state.isEditMode}
                          className={errors.coverage_status_id && touched.coverage_status_id ? 'text-input error' : 'text-input'}
                        >
                          <option value={""}>Select</option>
                          {statuses.map((status, index) => (
                            <option key={index} value={status.id}>
                              {status.coverage_status}
                            </option>
                          ))}
                        </select>
                        {errors.coverage_status_id && touched.coverage_status_id && (
                          <div className='input-feedback'>{errors.coverage_status_id}</div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <label>Type</label>
                        <select
                          name='coverage_type_id'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.coverage_type_id}
                          disabled={this.state.isEditMode}
                          className={errors.coverage_type_id && touched.coverage_type_id ? 'text-input error' : 'text-input'}
                        >
                          <option value={""}>Select</option>
                          {coverage_types.map((type, index) => (
                            <option key={index} value={type.id}>
                              {type.coverage_type} ({type.coverage_type_code})
                            </option>
                          ))}
                        </select>
                        {errors.coverage_type_id && touched.coverage_type_id && (
                          <div className='input-feedback'>{errors.coverage_type_id}</div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <label>Relationship to Subscriber</label>
                        <select
                          name='coverage_dependent_relationship_id'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.coverage_dependent_relationship_id}
                          disabled={this.state.isEditMode}
                          className={errors.coverage_dependent_relationship_id && touched.coverage_dependent_relationship_id ? 'text-input error' : 'text-input'}
                        >
                          <option value={""}>Select</option>
                          {dependent_relationships.map((relationship, index) => (
                            <option key={index} value={relationship.id}>
                              {relationship.coverage_dependent_relationship}
                            </option>
                          ))}
                        </select>
                        {errors.coverage_dependent_relationship_id && touched.coverage_dependent_relationship_id && (
                          <div className='input-feedback'>{errors.coverage_dependent_relationship_id}</div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <label>Number of Dependents</label>
                        <input
                          id='dependent'
                          placeholder='Enter number of dependents'
                          type='text'
                          value={values.dependent}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={errors.dependent && touched.dependent ? 'text-input error' : 'text-input'}
                          autoComplete='dependent'
                          disabled={this.state.isEditMode}
                        />
                        {errors.dependent && touched.dependent && (
                          <div className='input-feedback'>{errors.dependent}</div>
                        )}
                      </Grid>
                      <Grid container>
                        <Grid item xs={6} className={css.gridItem}>
                          <label>Start Date</label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Field
                              name='coverage_period_start'
                              value={values.coverage_period_start}
                              component={DatePickerField}
                              className={
                                classes(css.dateOfBirthPicker, 'mtl-picker-input', { 'error': errors.coverage_period_start && touched.coverage_period_start })
                              }
                              onBlur={handleBlur}
                            />
                          </MuiPickersUtilsProvider>
                          {errors.coverage_period_start && touched.coverage_period_start && (
                            <div className='input-feedback'>{errors.coverage_period_start}</div>
                          )}
                        </Grid>
                        <Grid item xs={6} className={css.gridItem}>
                          <label>End Date</label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Field
                              name='coverage_period_end'
                              value={values.coverage_period_end}
                              component={DatePickerField}
                              className={
                                classes(css.dateOfBirthPicker, 'mtl-picker-input', { 'error': errors.coverage_period_end && touched.coverage_period_end })
                              }
                              onBlur={handleBlur}
                            />
                          </MuiPickersUtilsProvider>
                          {errors.coverage_period_end && touched.coverage_period_end && (
                            <div className='input-feedback'>{errors.coverage_period_end}</div>
                          )}
                          {this.state.coverageLengthLimitError &&
                            <div className='input-feedback'>Coverage cannot be longer than one year.</div>
                          }
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <label>Payer</label>
                        <select
                          name='organization_id'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.organization_id}
                          disabled={this.state.isEditMode}
                          className={errors.organization_id && touched.organization_id ? 'text-input error' : 'text-input'}
                        >
                          <option value={""}>Select</option>
                          {insurance_organizations.map((organization, index) => (
                            <option key={index} value={organization.id}>
                              {organization.organization_name}
                            </option>
                          ))}
                        </select>
                        {errors.organization_id && touched.organization_id && (
                          <div className='input-feedback'>{errors.organization_id}</div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <label>Class Type</label>
                        <select
                          name='coverage_class_id'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.coverage_class_id}
                          disabled={this.state.isEditMode}
                          className={errors.coverage_class_id && touched.coverage_class_id ? 'text-input error' : 'text-input'}
                        >
                          <option value={""}>Select</option>
                          {coverage_class_types.map((type, index) => (
                            <option key={index} value={type.id}>
                              {type.coverage_class} ({type.coverage_class_code})
                            </option>
                          ))}
                        </select>
                        {errors.coverage_class_id && touched.coverage_class_id && (
                          <div className='input-feedback'>{errors.coverage_class_id}</div>
                        )}
                      </Grid>
                    </Grid>
                    <ErrorFocus />
                  </Grid>
                </form>
              </>
            );
          }}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState): IFormPatientCoverageReduxProps => ({
  statuses: state.personaBuilder.dropdowns.coverage_statuses,
  coverage_types: state.personaBuilder.dropdowns.coverage_types,
  dependent_relationships: state.personaBuilder.dropdowns.coverage_dependent_relationships,
  insurance_organizations: state.personaBuilder.dropdowns.insurance_organizations,
  coverage_class_types: state.personaBuilder.dropdowns.coverage_classes,
  patientCoverages: state.personaBuilder.currentPatientCoverages.sort(function (a, b) {
    if (a.coverage_period_end < b.coverage_period_end) { return 1; }
    if (a.coverage_period_end > b.coverage_period_end) { return -1; }
    return 0;
  }),
});

export const FormPatientCoverage = connect(
  mapStateToProps,
  PersonaBuilderActionCreators
)(FormPatientCoverageBase);