import * as React from 'react';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IPatient } from 'common/interfaces/personaBuilder/IPatient';
import { IPatientCoverage } from 'common/interfaces/personaBuilder/IPatientCoverage';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { Button, Card, Grid, Modal, Backdrop, Fade } from '@material-ui/core';
import { AddCircle, Edit, ArrowDownward, Add, Check, ChevronLeft, ChevronRight, Warning } from '@material-ui/icons';
import DataTable from 'react-data-table-component';
import { MuiButton } from 'components/MaterialUI/index';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import classes from 'classnames';
import cssDetails from 'components/Forms/PersonaBuilder/FormPersonaBuilder/FormPatientDetails/persona-details.module.scss';
import css from './patient-coverage-tab.module.scss';
import { FormPatientCoverage } from 'components/Forms/PersonaBuilder/FormPersonaBuilder/FormPatientCoverage';
import { FormPatientCoverageRenewal } from 'components/Forms/PersonaBuilder/FormPersonaBuilder/FormPatientCoverageRenewal';
import { objectArrayToMap } from 'common/utils/objectArrayToMap';
const sortIcon = <ArrowDownward />;

interface IPatientConditionTabReduxProps {
	currentPatient: IPatient;
	patientCoverages: IPatientCoverage[];
	insurance_organizations: any;
	coverageTypes: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
			width: '650px'
		},
  }),
);

const PatientCoverageTabBase: React.FC<IPatientConditionTabReduxProps &
	ResolveThunks<typeof PersonaBuilderActionCreators>
	> = props => {

	let _submitForm = null;
	const { currentPatient, patientCoverages, insurance_organizations, coverageTypes } = props;
	const newCoverage = {
		id: 0,
		coverage_period_start: null,
		coverage_period_end: null,
		coverage_status_id: '',
		coverage_type_id: '',
		dependant: '',
		coverage_dependent_relationship_id: '',
		organization_id: '',
		coverage_class_id: '',
		latest_flag: false
	};
	const organizationNames = objectArrayToMap(insurance_organizations, 'id', 'organization_name');
	const coverageTypeNames = objectArrayToMap(coverageTypes, 'id', 'coverage_type');
	const coverageTypeCodes = objectArrayToMap(coverageTypes, 'id', 'coverage_type_code');
	const coverageData = patientCoverages.map(coverage => {
		return {
			...coverage,
			organization_name: organizationNames[coverage.organization_id],
			coverage_type: coverageTypeNames[coverage.coverage_type_id],
			coverage_type_code: coverageTypeCodes[coverage.coverage_type_id],
			active: new Date(coverage.coverage_period_end) >= new Date()
		}
	});
	const initialData = coverageData;
	const modalCSS = useStyles(props);

	const [currentCoverage, setCurrentCoverage] = React.useState(null);
	const [currentCoverageRenewal, setCurrentCoverageRenewal] = React.useState(null);
	const [isAddMode, setIsAddMode] = React.useState(false);
	const [filteredData, setFilteredData] = React.useState(null);
	const [modalOpen, setModalOpen] = React.useState(false);
	const history = useHistory();
	const prevLink = currentPatient ? `/persona-builder/patient-listing/${currentPatient.id}/family-history` : '';
	const nextLink = currentPatient ? `/persona-builder/patient-listing/` : '';

	const _bindSubmitForm = (submitForm) => {
		_submitForm = submitForm;
	};
	const handleSubmit = () => {
		if (_submitForm){
			_submitForm();
		}
	};
	const setForm = (coverage, isAddMode) => {
		if (coverage !== null) {
			setCurrentCoverage(coverage);
			setIsAddMode(isAddMode);
		}
		else {
			setCurrentCoverage(null);
		}
	};
	const saveCoverage = (values) => {
		if (!!values.id) {
			// update
			props.updatePatientCoverage(values, currentPatient.id).then(()=>{
				props.getPatientCoverages(currentPatient.id);
				setCurrentCoverage(null);
			}).catch((e)=>{console.log(e)});
		}
		else {
			// add
			props.createPatientCoverage(values, currentPatient.id).then(()=>{
				props.getPatientCoverages(currentPatient.id);
				setCurrentCoverage(null);
			}).catch((e)=>{console.log(e)});
		}
	};
	const saveCoverageRenewal = (values) => {
		props.updatePatientCoverage(values, currentPatient.id).then(()=>{
			props.getPatientCoverages(currentPatient.id);
			setCurrentCoverage(null);
			setModalOpen(false);
		}).catch((e)=>{console.log(e)});
	};
	const deleteCoverage = () => {
		props.deletePatientCoverage(currentCoverage, currentPatient.id).then(()=>{
			props.getPatientCoverages(currentPatient.id);
			setCurrentCoverage(null);
		}).catch((e)=>{console.log(e)});
	};
	const filterCoverage = (param) => {
		if (param.length > 2) {
			let foundData = [];
			initialData.forEach(function(dataElement) {
				["organization_name", "coverage_type", "coverage_type_code"].forEach(function(property) {
					if (dataElement[property].toLowerCase().indexOf(param.toLowerCase()) > -1) {
						foundData.push(dataElement);
					}
				});
			});
			setFilteredData(foundData);
		}
		else if (param.length === 0) {
			setFilteredData(initialData);
		}
	};
	const handleRenewModal = (coverage) => {
		setCurrentCoverageRenewal(coverage);
		setModalOpen(coverage !== null);
	};

	const getPrevCoverageEndDate = () => {
		if (patientCoverages.length > 0) {

			const sortedCoverage = patientCoverages.sort(function(a, b) {
				if (a.coverage_period_end < b.coverage_period_end) { return 1; }
				if (a.coverage_period_end > b.coverage_period_end) { return -1; }
				return 0;
			});

			// set the start date limit to the latest coverage end date
			let previousEndDate = new Date(sortedCoverage[0].coverage_period_end);
			
			// if we're editing, get the prev coverage item in the list from the current one being edited
			if (!isAddMode) {
				let coverageIndex = 0;
				for (let i=0; i<sortedCoverage.length; i++) {
					if (sortedCoverage[i].id === currentCoverage.id) {
						coverageIndex = i;
					}
				}

				// index to the next in the array
				coverageIndex++;
				previousEndDate = sortedCoverage.length > coverageIndex ? new Date(sortedCoverage[coverageIndex].coverage_period_end) : null;
			}
			return previousEndDate;
		}
		return null;
	};

	const columns = [
		{
			name: '',
			grow: 0,
			cell: (row) => <Button onClick={() => setForm(row, false)}><Edit /></Button>,
			button: true
		},
		{
			name: 'Payer',
			sortable: true,
			selector: 'organization_name'
		},
		{
			name: 'Type',
			selector: 'coverage_type',
			cell: (row) => <span className={css.coverageLabel}>{row.coverage_type} ({row.coverage_type_code})</span>,
			sortable: true
		},
		{
			name: 'Start Date',
			selector: 'coverage_period_start',
			sortable: true
		},
		{
			name: 'End Date',
			selector: 'coverage_period_end',
			sortable: true
		},
		{
			name: 'Active?',
			grow: 0,
			selector: 'active',
			cell: (row) => row.active && <Check />,
			sortable: true
		},
		{
			name: '',
			grow: 0,
			cell: (row) => !row.active && <MuiButton variant="contained" className={classes([cssDetails.button], [css.renewButton])} onClick={() => handleRenewModal(row)}>Renew</MuiButton>,
			button: true
		}
	];

	return (
		<>
			{currentCoverage !== null ? (
				<>
					<FormPatientCoverage
						bindSubmitForm={_bindSubmitForm}
						defaultFormContent={currentCoverage} 
						formAction={saveCoverage}
						deleteAction={deleteCoverage}
						previousCoverageEndDate={getPrevCoverageEndDate()}
					/>
					{!isAddMode && 
						<Grid container>
							<Grid item xs={12}>
								<MuiButton variant="contained" className={cssDetails.button} onClick={handleSubmit}>
									Renew Coverage
								</MuiButton>
							</Grid>
						</Grid>
					}
				</>
			) : (
				<Grid container className={css.listContainer}>
					<Grid item xs={6}>
						<h4>Coverage <MuiButton className={css.addButton} onClick={() => setForm(newCoverage, true)}><AddCircle /></MuiButton></h4>
					</Grid>
					<Grid item xs={6}>
						<input placeholder='Search Coverage' type='text' onChange={(e) => filterCoverage(e.target.value)} />
					</Grid>
					<Grid item xs={12}>
						<Card className={css.listCard}>
							<DataTable
								className={css.dataTable}
								columns={columns}
								data={filteredData ? filteredData : initialData}
								noHeader
								highlightOnHover
								defaultSortField="coverage_period_end"
								defaultSortAsc={false}
								sortIcon={sortIcon}
							/>
						</Card>
						<Modal
								aria-labelledby="transition-modal-title"
								aria-describedby="transition-modal-description"
								className={modalCSS.modal}
								open={modalOpen}
								onClose={() => handleRenewModal(null)}
								closeAfterTransition
								BackdropComponent={Backdrop}
								BackdropProps={{
									timeout: 500,
								}}
							>
							<Fade in={modalOpen}>
									<div className={modalCSS.paper}>
										{currentCoverageRenewal !== null && <>
											<FormPatientCoverageRenewal
												bindSubmitForm={_bindSubmitForm}
												defaultFormContent={currentCoverageRenewal} 
												formAction={saveCoverageRenewal}
											/>
										</>}
									</div>
							</Fade>
						</Modal>
					</Grid>
				</Grid>
			)}
			<Grid container className={cssDetails.actionsContainer}>
				<Grid item xs={12} md={6}>
					<Button onClick={() => history.push(`${prevLink}`)}>
						<ChevronLeft className={classes([cssDetails.leftIcon], [cssDetails.iconSmall])}/>
						Family History
					</Button>
				</Grid>
				<Grid item xs={12} md={6} style={{textAlign: 'right'}}>
					<MuiButton variant="contained" className={cssDetails.button} disabled={currentCoverage === null || !isAddMode} onClick={handleSubmit}>
						Save
					</MuiButton>
					{/* <Button onClick={() => history.push(`${nextLink}`)}>
						Coverage
						<ChevronRight className={classes([cssDetails.rightIcon], [cssDetails.iconSmall])}/>
					</Button> */}
				</Grid>
			</Grid>
		</>
	);
}

const mapStateToProps = (state: ApplicationState): IPatientConditionTabReduxProps => {
	return {
		currentPatient: state.personaBuilder.currentPatient,
		patientCoverages: state.personaBuilder.currentPatientCoverages,
		insurance_organizations: state.personaBuilder.dropdowns.insurance_organizations,
		coverageTypes: state.personaBuilder.dropdowns.coverage_types
	};
};

export const PatientCoverageTab = connect(
	mapStateToProps,
	PersonaBuilderActionCreators
)(PatientCoverageTabBase);