import { IModal } from 'common/interfaces';
import { FormDeleteApiDoc } from 'components/Forms/FormDeleteApiDoc/FormDeleteApiDoc';
import { apiLibraryActionCreators } from 'stores/actions/apiLibraryActions';
import { IApiDoc } from 'common/interfaces/IApiDoc';

export const deleteApiDocModal = (apiDoc: IApiDoc, formAction): IModal => ({
	title: 'Delete API',
	content: FormDeleteApiDoc,
	primaryButtonLabel: 'Delete API',
	formAction: () => formAction(apiDoc.id),
});
