import { connect, FormikProps } from 'formik';
import { Component, useEffect } from 'react';

interface IProps {
	formik: FormikProps<any>;
}

const ErrorFocusInternal = (props: IProps) => {
	useEffect(() => {
		const { isSubmitting, isValidating, errors } = props.formik;
		const keys = Object.keys(errors);
		if (keys.length > 0 && isSubmitting && !isValidating) {
			const errorElement = document.getElementsByClassName('error')[0] as HTMLElement;
			if (errorElement) {
				errorElement.scrollIntoView({ block: 'nearest' });
			}
		}
	});
	return null;
};

export const ErrorFocus = connect<{}>(ErrorFocusInternal);
