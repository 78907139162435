import * as React from 'react';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { FormPatientDetails } from 'components/Forms/PersonaBuilder/FormPersonaBuilder';
import { Button, Grid, makeStyles, Theme, createStyles } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { IPatient } from 'common/interfaces/personaBuilder/IPatient';
import css from 'components/Forms/PersonaBuilder/FormPersonaBuilder/FormPatientDetails/persona-details.module.scss';
import { Link } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { MuiButton } from 'components/MaterialUI';
import classes from 'classnames';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {

		},
		disabled: {
			color: '#c1c1c1',
			cursor: 'not-allowed'
		}
	}),
);

interface IPatientDetailsRouteProps {
	id?: string
}

interface IPatientDetailsTabReduxProps {
	currentPatient: any;
}

const PatientDetailsTabBase: React.FC<RouteComponentProps<IPatientDetailsRouteProps> &
	IPatientDetailsTabReduxProps &
	ResolveThunks<typeof PersonaBuilderActionCreators>> = props => {
	const {
		currentPatient
	} = props;

	const styles = useStyles({});
	let _submitForm = null;
	const _bindSubmitForm = (submitForm) => {
		_submitForm = submitForm;
	};

	const _handleSubmit = () => {
		if (_submitForm) {
			_submitForm();
		}
	};

	const isNew = props.match.params.id === 'create';

	const savePatient = (values) => {
		if (isNew) {
			props.createPatient(values);
		} else {
			props.updatePatient(values);
		}
	};

	return (
		<>
			<FormPatientDetails
				bindSubmitForm={_bindSubmitForm}
				defaultFormContent={!isNew && props.currentPatient}
				formAction={savePatient}
			/>
			<Grid container xs={12} className={css.actionsContainer}>
				<Grid container xs={12} md={6} justify={'flex-start'} alignContent={'center'}>
					<Button component={Link} to={`/persona-builder/patient-listing/`} >
						<ChevronLeft className={classes([css.leftIcon], [css.iconSmall])} />
						Back
					</Button>
				</Grid>
				<Grid container xs={12} md={6} justify={'flex-end'} alignContent={'center'}>
					<MuiButton variant="contained" className={css.button} disabled={false} onClick={_handleSubmit}>
						Save
					</MuiButton>
					<Button component={Link} 
										to={`/persona-builder/patient-listing/${currentPatient.id}/description`}
										classes={{root: isNew && styles.disabled}}
										onClick={(e)=>{isNew && e.preventDefault()}}>
						Description
						<ChevronRight className={classes([css.rightIcon], [css.iconSmall])}/>
					</Button>
				</Grid>
			</Grid>
		</>
	);
};

const mapStateToProps = (state: ApplicationState): IPatientDetailsTabReduxProps => {
	return {
		currentPatient: state.personaBuilder.currentPatient || {}
	};
};

export const PatientDetailsTab = connect(
	mapStateToProps,
	PersonaBuilderActionCreators
)(PatientDetailsTabBase);
