import * as React from 'react';
import { FormSmartApp } from 'views/SmartApp/FormSmartApp';
import { IModal, ISmartApp } from 'common/interfaces';

export const editSmartAppModal = (smartApp: ISmartApp, rofId, pitId): IModal => ({
	title: 'Update SMART App Configuration',
	primaryButtonLabel: 'Save Changes',
	description: 'Please make changes below and press "Save Changes" when finished',
	content: (props) => <FormSmartApp smartApp={smartApp} rofId={rofId} pitId={pitId} {...props} />,
});
