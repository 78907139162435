import { Reducer } from 'redux';
import { websocketState } from 'stores/stateInterfaces/websocketStateInterface';
import websocketActions from 'stores/actionInterfaces/websocketActionInterfaces';

const initialState: websocketState = {
	socket: null
};

export const WebsocketReducers: Reducer<websocketState> = (state: websocketState, action) => {
	switch (action.type) {
		case 'CONNECT_TO_SANDBOX_UPDATES': {
			return {
				...state,
				socket: action.socket
			};
		}
	}
	return state || initialState;
};
