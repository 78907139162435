import * as React from 'react';
import css from './table-row.module.scss';
import classes from 'classnames';

interface ITableRowProps {
	className?: string;
	hover?: boolean;
}

export const TableRow: React.FC<ITableRowProps> = props => {
	const { hover } = props;
	return (
		<div
			className={classes(css.tableRow, 'tableRow', {
				[css.hover]: hover
			})}
		>
			{props.children}
		</div>
	);
};
