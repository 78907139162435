import * as yup from 'yup';

interface IHL7AllergySubstanceValidationSchemaConfig {
}

interface HL7AllergySubstanceValidationSchema {
	allergy_substance_display: string;
	allergy_substance_snomed: string;
}

export const hl7AllergySubstanceValidationSchema = (
	hl7AllergySubstanceValidationSchemaConfig: IHL7AllergySubstanceValidationSchemaConfig = {}
) => {
	let schemaDefinition = {
		allergy_substance_display: yup.string().required('Display is required'),
		allergy_substance_snomed: yup.string().required('Code is required')
	};

	return yup.object().shape<any>(schemaDefinition) as any;
};
