import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect, ResolveThunks } from 'react-redux';
import { differenceInCalendarYears } from 'date-fns';

import { AppFrame, InnerContainer, PageTitleArea } from 'components/Page';
import { Panel } from 'components/Panel';
import { Circle } from 'components/Circle';
import css from './persona.module.scss';
import classes from 'classnames';
import { ReactComponent as IconEkg } from 'assets/icons/icon-ekg.svg';
import { ReactComponent as IconPills } from 'assets/icons/icon-pills.svg';
import { ReactComponent as IconBuilding } from 'assets/icons/icon-building.svg';
import { ReactComponent as IconPrescriptionList } from 'assets/icons/icon-prescription-list.svg';
import { ReactComponent as IconCross } from 'assets/icons/icon-cross.svg';
import { ReactComponent as IconDoctor } from 'assets/icons/icon-doctor.svg';
import { ReactComponent as IconPillBottle } from 'assets/icons/icon-pill-bottle.svg';
import { ReactComponent as IconPillIV } from 'assets/icons/icon-iv.svg';
import { ReactComponent as IconDocument } from 'assets/icons/icon-document.svg';
import { ReactComponent as IconSingleUser } from 'assets/icons/icon-single-user.svg';
import { ReactComponent as IconPrescription } from 'assets/icons/icon-prescription.svg';
import { ReactComponent as IconMapLocation } from 'assets/icons/icon-map-location.svg';
import { ReactComponent as IconChatConversation } from 'assets/icons/icon-chat-conversation.svg';
import { ReactComponent as IconClipboardHeart } from 'assets/icons/icon-clipboard-heart.svg';
import { ReactComponent as IconClipboardCheck } from 'assets/icons/icon-clipboard-check.svg';
import { ReactComponent as IconClipboardContent } from 'assets/icons/icon-clipboard-content.svg';

import { ResourceListItem } from './ResourceListItem/ResourceListItem';

import { ApplicationState } from 'stores';
import { IPersona } from 'common/interfaces';
import { personaActionCreators } from 'stores/actions/personaActions';
import { modalActionCreators } from 'stores/actions/modalActions';
import { Loading } from 'components/Loading';
import { IModal } from 'common/interfaces/IModal';

const actionCreators = {
	...personaActionCreators,
	...modalActionCreators
};

interface IPersonaRouteProps {
	id?: string;
}

interface IPersonaViewReduxProps {
	currentPersona: number;
	persona: IPersona;
	currentUserType: string;
}

const deletePersonaModalContent = () => {
	return (
		<p>
			Are you sure you want to delete this persona?
			<br />
			<b>This action can not be undone.</b>
		</p>
	);
};

class PersonaViewBase extends React.PureComponent<
	RouteComponentProps<IPersonaRouteProps> & ResolveThunks<typeof actionCreators> & IPersonaViewReduxProps
> {
	UNSAFE_componentWillMount() {
		const personaId = parseInt(this.props.match.params.id);
		this.props.getPersona(personaId);
	}

	isLoading = () => {
		const { persona } = this.props;
		const screenId = parseInt(this.props.match.params.id);
		return !(persona && persona.id === screenId);
	};

	deletePersonaAction = () => {
		this.props.deletePersona(this.props.currentPersona);
	};

	render() {
		const { persona, currentPersona, currentUserType, createModal } = this.props;

		const deletePersonaModal: IModal = {
			title: 'Delete Persona',
			content: deletePersonaModalContent,
			primaryButtonLabel: 'Delete Persona',
			primaryButtonAction: this.deletePersonaAction
		};

		const resourceTypeToIcon = (resourceType: string): any => {
			let result: any = null;
			switch (resourceType) {
				case 'Condition':
					result = <IconCross />;
					break;
				case 'Encounter':
					result = <IconEkg />;
					break;
				case 'Medication':
					result = <IconPillBottle />;
					break;
				case 'MedicationRequest':
					result = <IconPrescriptionList />;
					break;
				case 'Organization':
					result = <IconBuilding />;
					break;
				case 'Patient':
					result = <IconSingleUser />;
					break;
				case 'Practitioner':
					result = <IconDoctor />;
					break;
				case 'MedicationStatement':
					result = <IconDocument />;
					break;
				case 'Procedure':
					result = <IconPillIV />;
					break;
				case 'CareTeam':
					result = <IconDoctor />;
					break;
				case 'MedicationAdministration':
					result = <IconPrescription />;
					break;
				case 'MedicationDispense':
					result = <IconPills />;
					break;
				case 'Location':
					result = <IconMapLocation />;
					break;
				case 'ExplanationOfBenefit':
					result = <IconChatConversation />;
					break;
				case 'Coverage':
					result = <IconClipboardHeart />;
					break;
				case 'ClaimResponse':
					result = <IconClipboardCheck />;
					break;
				case 'Claim':
					result = <IconClipboardContent />;
					break;
			}
			return result;
		};

		if (this.isLoading()) {
			return (
				<AppFrame>
					<Loading />
				</AppFrame>
			);
		}

		return (
			<AppFrame>
				<InnerContainer>
					<PageTitleArea 
						backLabel='Back to all persona' 
						backLocation='/persona'
						buttonList={
							currentUserType === 'mihin-admin' && [
								{
									buttonLabel: 'Delete Persona',
									buttonAction: event => {
										createModal(deletePersonaModal, event);
									},
									style: 'destructive'
								}
							]
						}
						>
						{persona.first_name} {persona.last_name}
					</PageTitleArea>
					<Panel type='callOut'>{persona.descr}</Panel>
					<div className={css.content}>
						<div className={css.contentMain}>
							<Panel className={css.meta}>
								<div className={css.metaImageArea}>
									<Circle
										style={{
											backgroundImage: `url('${persona.image_url}')`
										}}
										className={css.metaImage}
									/>
								</div>
								<div className={css.metaContent}>
									<h2 className={classes('h4', css.title)}>Demographics</h2>
									<div className={css.metaLists}>
										<ul className={classes('reset', css.metaList)}>
											<li>
												<b>Age:</b>{' '}
												{differenceInCalendarYears(new Date(), persona.dob).toString()} Years
												Old
											</li>
											<li>
												<b>Type:</b> {persona.type}
											</li>
											{/*<li>
												<b>Gender:</b> {persona.gender}
											</li>*/}
										</ul>
										{/*<ul
											className={classes(
												'reset',
												css.metaList
											)}
										>
											<li>
												<b>Marital Status:</b> Single
											</li>
											<li>
												<b>Children:</b> No
											</li>
											<li>
												<b>Race:</b> Caucasian
											</li>
										</ul>
										<ul
											className={classes(
												'reset',
												css.metaList
											)}
										>
											<li>
												<b>Ethnicity:</b> Not Hispanic or
												Latino
											</li>
											<li>
												<b>Location:</b> Bay, City Michigan
											</li>
										</ul>*/}
									</div>
									<div className={css.metaUseCases}>
										<b>Use Cases:</b> {persona.use_cases}
									</div>
								</div>
							</Panel>
							<Panel className={css.bio}>
								<h2 className={classes('h4', css.title)}>{persona.first_name}'s Bio</h2>
								<h3 className='h6'>Description</h3>
								<div
									dangerouslySetInnerHTML={{
										__html: persona.bio
									}}
								/>
							</Panel>
						</div>
						<Panel className={css.contentSide}>
							<h2 className={classes('h4', css.title)}>Resource Summary</h2>
							<ul className={classes('reset', css.resourceList)}>
								{persona.resources &&
									persona.resources.map((resource, index) => (
										<ResourceListItem
											Icon={() => resourceTypeToIcon(resource.resource_type)}
											fhirPits={resource.fhir_pits}
											key={index}
										>
											{resource.resource_type} ({resource.resource_count})
										</ResourceListItem>
									))}
							</ul>
						</Panel>
					</div>
				</InnerContainer>
			</AppFrame>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IPersonaViewReduxProps => ({
	persona: state.persona.currentPersona ? state.persona.allPersonas[state.persona.currentPersona] : null,
	currentPersona: state.persona.currentPersona,
	currentUserType: state.auth.user.attributes ? state.auth.user.attributes['custom:user_type'] : null
});

export const PersonaView = connect(
	mapStateToProps,
	actionCreators
)(PersonaViewBase);
