import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { TableBody, TableColumn, TableHeader, TableRow, TableColumnDelete } from 'components/Table';
import { ISmartApp, IMultistepModal } from 'common/interfaces';
import { Button } from 'components/Button';
import { FormSmartApp } from 'views/SmartApp/FormSmartApp';
import { modalActionCreators } from 'stores/actions/modalActions';
import { rofActionCreators } from 'stores/actions/rofActions';
import { THTMLElementEvent } from 'common/types/THTMLElementEvent';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from 'stores';
import { ViewRegisteredSmartAppCredentials } from './ViewRegisteredSmartAppCredentials';
import { Message } from 'components/Message';
import { EUpdateState } from 'stores/shared/ENums';
import { IModal } from 'common/interfaces/IModal';
import { TableActionBarTop } from 'components/Table';
import css from './smart-apps-tab.module.scss';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { convertStringIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';
import { deleteSmartAppModal } from 'common/modals/deleteSmartAppModal';
import { Loading } from 'components/Loading';

const smartAppsTabActionCreators = {
	...modalActionCreators,
	...rofActionCreators,
};

interface RofRouteProps {
	rofId?: string;
	pitId?: string;
}

interface smartAppsTabsReduxProps {
	smartApps: ISmartApp[];
	smartAppsUpdateState: EUpdateState;
	currentFhirPitName: string;
	currentRofId: number;
	currentPitId: number;
}

class SmartAppsTabBase extends React.PureComponent<
	RouteComponentProps<RofRouteProps> &
		ResolveThunks<typeof smartAppsTabActionCreators> &
		smartAppsTabsReduxProps
> {
	UNSAFE_componentWillMount() {
		const { rofId, pitId } = this.getContext();
		this.props.getSmartApps(rofId, pitId);
	}

	getContext = (): { rofId: number; pitId: number } => {
		return {
			rofId: Number(this.props.match.params.rofId),
			pitId: Number(this.props.match.params.pitId),
		};
	};

	isLoading = (): boolean => {
		return !(this.props.smartAppsUpdateState === EUpdateState.Loaded);
	};

	render() {
		const {
			createMultistepModal,
			smartApps,
			smartAppsUpdateState,
			currentFhirPitName,
			currentRofId,
			currentPitId,
			createModal,
		} = this.props;

		const registerSmartAppModal: IModal = {
			title: 'Register Smart App',
			content: (props) => <FormSmartApp {...props} rofId={currentRofId} pitId={currentPitId} />,
			description: `Register your SMART app to gain access to ${currentFhirPitName}.`,
		};

		if (this.isLoading()) {
			return <Loading />;
		}

		return (
			<>
				{smartAppsUpdateState === EUpdateState.Loaded && (
					<>
						{smartApps.length > 0 ? (
							<>
								<TableActionBarTop>
									<Button onClick={(event) => createModal(registerSmartAppModal, event)}>
										Register New App
									</Button>
								</TableActionBarTop>
								<TableBody>
									<TableHeader>
										<TableColumn className={css.columnName}>Name</TableColumn>
										<TableColumn className={css.columnClientId}>Client Id</TableColumn>
										<TableColumn className={css.columnRegisteredOn}>Registered On</TableColumn>
										<TableColumn className={css.lastColumn} />
									</TableHeader>
									{smartApps &&
										smartApps.map((smartApp, index) => (
											<TableRow key={index}>
												<TableColumn>
													<Link
														to={`/sandbox/${currentRofId}/pit/${currentPitId}/app/${smartApp.client_id}`}
													>
														{smartApp.name}
													</Link>
												</TableColumn>
												<TableColumn>{smartApp.client_id}</TableColumn>
												<TableColumn>
													{format(new Date(smartApp.registered_on), 'MM/dd/yyyy hh:mm:ss a')}
												</TableColumn>
												<TableColumnDelete
													onClickModal={deleteSmartAppModal(
														smartApp.client_id,
														this.getContext(),
														this.props.deleteSmartApp
													)}
													actionAriaLabel='Delete Smart App'
												/>
											</TableRow>
										))}
								</TableBody>
							</>
						) : (
							<Message
								title='There are currently no SMART apps registered with this FHIR PIT'
								description='Registering an app will allow you to connect using the SMART on FHIR launch sequence.'
							>
								<Button onClick={(event) => createModal(registerSmartAppModal, event)}>
									Register a New App
								</Button>
							</Message>
						)}
					</>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: ApplicationState): smartAppsTabsReduxProps => ({
	smartApps:
		state.rof.allRofPitsSmartAppsUpdateState &&
		state.rof.allRofPitsSmartApps.hasOwnProperty(state.rof.currentRofId) &&
		state.rof.allRofPitsSmartApps[state.rof.currentRofId].hasOwnProperty(state.rof.currentPitId)
			? convertStringIndexedObjectToArray(
					state.rof.allRofPitsSmartApps[state.rof.currentRofId][state.rof.currentPitId]
			  )
			: [],
	smartAppsUpdateState:
		state.rof.allRofPitsSmartAppsUpdateState &&
		state.rof.allRofPitsSmartAppsUpdateState.hasOwnProperty(state.rof.currentRofId) &&
		state.rof.allRofPitsSmartAppsUpdateState[state.rof.currentRofId].hasOwnProperty(
			state.rof.currentPitId
		)
			? state.rof.allRofPitsSmartAppsUpdateState[state.rof.currentRofId][state.rof.currentPitId]
			: EUpdateState.Empty,
	currentFhirPitName: state.rof.allRofPits[state.rof.currentRofId][state.rof.currentPitId].name,
	currentRofId: state.rof.currentRofId,
	currentPitId: state.rof.currentPitId,
});

export const SmartAppsTab = connect(mapStateToProps, smartAppsTabActionCreators)(SmartAppsTabBase);
