import * as React from 'react';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { Card, Grid } from '@material-ui/core';
import { Button } from 'components/Button';
import DataTable from 'react-data-table-component';
import { MuiButton } from 'components/MaterialUI/index';
import { sharedHistory } from 'sharedStore';
import { IProvider } from 'common/interfaces/personaBuilder/IProvider';
import { ProviderActionCreators } from 'stores/actions/personaBuilder/providerActions';
import css from './provider-listing-tab.module.scss';
import { ArrowDownward, AddCircle, Edit } from '@material-ui/icons';
import { objectArrayToMap } from 'common/utils/objectArrayToMap';
const sortIcon = <ArrowDownward />;

interface IProviderListingTabReduxProps {
	providers: IProvider[];
	provider_dropdowns: [];
}

const ProviderListingTabBase: React.FC<IProviderListingTabReduxProps &
	ResolveThunks<typeof ProviderActionCreators>
	> = props => {
	const { providers, provider_dropdowns } = props;
	const organizationNames = objectArrayToMap(provider_dropdowns['organizations'], 'id', 'organization_name')
	const practitionerRoleDisplays = objectArrayToMap(provider_dropdowns['practitioner_roles'], 'id', 'practitioner_role_display')
	const practiceCodeDisplays = objectArrayToMap(provider_dropdowns['practice_codes'], 'id', 'practice_code_display')
	const qualificationNames = objectArrayToMap(provider_dropdowns['qualifications'], 'id', 'qualification_name')
	const providersData = props.providers.map(provider => {
		return {
			...provider,
			organization_name: organizationNames[provider.organization_id],
			practitioner_role: practitionerRoleDisplays[provider.practitioner_role_id],
			practice_code: practiceCodeDisplays[provider.practice_code_id],
			qualification_name: qualificationNames[provider.qualification_id] || '',
		}
	})

	const initialData = providersData;

	const [filteredData, setFilteredData] = React.useState(null);

	const filterProviders = (param) => {
		if (param.length > 2) {
			let foundData = [];
			initialData.forEach(function(dataElement) {
				["provider_first_name", "provider_last_name", "organization_name", "practitioner_role"].forEach(function(property) {
					if (dataElement[property].toLowerCase().indexOf(param.toLowerCase()) > -1) {
						foundData.push(dataElement);
					}
				});
			});
			setFilteredData(foundData);
		}
		else if (param.length === 0) {
			setFilteredData(initialData);
		}
	}

	const columns = [
		{
			name: '',
			cell: row => <Button style="link" onClick={() => sharedHistory.push(`/persona-builder/provider-listing/${row.id}`)}><Edit /></Button>,
			button: true
		},
		{
			name: 'Name',
			sortable: true,
			cell: row => `${row.provider_first_name} ${row.provider_last_name}`
		},
		{
			name: 'Organization',
			sortable: true,
			selector: 'organization_name'
		},
		{
			name: 'Practitioner Role',
			sortable: true,
			selector: 'practitioner_role'
		}
	];

	return (
		<Grid container className={css.listContainer}>
			<Grid item xs={6}>
				<h4>
					Providers 
					<MuiButton className={css.addButton} onClick={() => sharedHistory.push(`/persona-builder/provider-listing/create`)}><AddCircle /></MuiButton>
				</h4>
			</Grid>
			<Grid item xs={6}>
				<input placeholder='Search Providers' type='text' onChange={(e) => filterProviders(e.target.value)} />
			</Grid>
			<Grid item xs={12}>
				<Card className={css.listCard}>
					<DataTable
						className={css.dataTable}
						columns={columns}
						data={filteredData ? filteredData : initialData}
						noHeader
						highlightOnHover
						defaultSortField="patient_relative_name"
						sortIcon={sortIcon}
						pagination
            paginationRowsPerPageOptions={[10,25,50,100]}
					/>
				</Card>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state: ApplicationState): IProviderListingTabReduxProps => {
	return {
		providers: state.personaBuilderProvider.providers,
		provider_dropdowns: state.personaBuilderProvider.provider_dropdowns
	};
};

export const ProviderListingTab = connect(
	mapStateToProps,
	ProviderActionCreators
)(ProviderListingTabBase);
