import * as React from 'react';
import css from './button.module.scss';
import classes from 'classnames';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { TButtonStyle } from 'common/types/TButtonStyle';
import { getTextFromReactChild } from 'common/utils/getTextFromReactChild';

interface IButtonProps {
	style?: TButtonStyle;
	onClick?: any;
	disabled?: boolean;
	type?: 'button' | 'reset' | 'submit';
	isLoading?: boolean;
	loadingText?: string;
	className?: string;
	isActive?: boolean;
	animate?: 'copy';
}

interface IButtonState {
	animate: string;
}

export class Button extends React.PureComponent<IButtonProps, IButtonState> {
	constructor(props) {
		super(props);
		this.state = {
			animate: null
		};
	}

	render() {
		const {
			style,
			children,
			onClick,
			disabled,
			type,
			isLoading,
			className,
			isActive,
			animate,
			loadingText
		} = this.props;

		return (
			<button
				onClick={event => {
					if (animate) {
						if (this.state.animate !== animate) {
							this.setState({
								animate
							});
							setTimeout(() => {
								this.setState({
									animate: null
								});
							}, 400);
						}
					}
					console.log('onClick', onClick);
					if (onClick) {
						onClick(event);
					}
				}}
				className={classes(
					css.button,
					css[style],
					{ [css.isLoading]: isLoading },
					className,
					{ [css.active]: isActive },
					{ [css[animate]]: this.state.animate }
				)}
				disabled={disabled || isLoading}
				type={type}
				data-content={animate ? getTextFromReactChild(children) : ''}
			>
				{isLoading ? (
					<>
						{loadingText ? loadingText : 'Loading...'}
						<div className={css.loadingSpinnerContainer}>
							<LoadingSpinner
								type={style === 'dropdown' ? 'dark' : 'light'}
								className={css.loadingSpinner}
							/>
						</div>
					</>
				) : (
					children
				)}
			</button>
		);
	}
}
