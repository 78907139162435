import React, { useState } from 'react';

import { AppFrameAuth } from 'components/Auth/AppFrameAuth';

import { Formik } from 'formik';
import * as yup from 'yup';

import { authActionCreators } from 'stores/actions/authActions';
import { ApplicationState } from 'stores';
import { connect } from 'react-redux';
import css from './auth.module.scss';
import { Link } from 'react-router-dom';
import { Error } from 'components/Toast';
import { Button } from 'components/Button';
import PasswordStrengthIndicator from 'components/FormElements/PasswordStrengthIndicator/PathwordStrengthIndicator';
import { FormLabel } from 'components/FormElements';
import { isNumberRegx, lowerCaseCharRegx, specialCharacterRegx, upperCaseCharRegx } from 'common/utils/validatePassword';

const passwordResetActionCreators = {
	...authActionCreators
};

interface IPasswordResetStateProps {
	passwordResetPromise: any;
	userEmail: any;
	error: string;
}
export const PasswordResetBase: React.FunctionComponent<
	IPasswordResetStateProps &
	typeof passwordResetActionCreators & any
> = ({
	passwordReset,
	passwordResetPromise,
	error,
}) => {

		const [passwordValidity, setPasswordValidity] = useState({
			minChar: null,
			number: null,
			upperCaseChar: null,
			lowerCaseChar: null,
			specialChar: null,
		})
		const params = new URLSearchParams(window.location.search);
		const userEmail = params.get('email');
		const validationCode = params.get('code');

		const onChangePassword = (password, values) => {
			values.password = password;

			setPasswordValidity({
				minChar: !!(password.length >= 8),
				number: !!isNumberRegx.test(password),
				specialChar: !!specialCharacterRegx.test(password),
				upperCaseChar: !!upperCaseCharRegx.test(password),
				lowerCaseChar: !!lowerCaseCharRegx.test(password),
			});
		};


		return (
			<AppFrameAuth title='Password Reset' description='Enter your new password here:'>
				{passwordResetPromise ? (
					<p className='message success'>Your password has been reset successfully.</p>
				) : error ? (
					<Error>{error}</Error>
				) : (
					''
				)}
				<Formik
					initialValues={{
						email: userEmail,
						code: validationCode,
						password: ''
					}}
					onSubmit={(values, { setSubmitting }) => {
						passwordReset(userEmail, validationCode, values.password);
						setSubmitting(false);
					}}
					validationSchema={yup.object().shape({
						email: yup.string().required('Required')
					})}
				>
					{props => {
						const {
							values,
							touched,
							errors,
							dirty,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit,
							handleReset
						} = props;
						return (
							<form onSubmit={handleSubmit}>
								<FormLabel htmlFor='password' infoText='The password must be at least eight characters long and include the following character types: uppercase, lowercase, number, and special.'>
									New Password
								</FormLabel>
								<input
									id='password'
									placeholder='Enter your password'
									type='password'
									value={values.password}
									onChange={e =>
										onChangePassword(e.target.value, values)
									}
									onBlur={handleBlur}
									className={errors.password && touched.password ? 'text-input error' : 'text-input'}
								/>
								<PasswordStrengthIndicator
									validity={passwordValidity}
								/>
								<br></br>
								<div className='row'>
									<Button className={css.resetSubmit} type='submit' disabled={isSubmitting}>
										Reset Password
									</Button>
									<Link className={css.backLink} to='/login'>
										Back to Sign In
									</Link>
								</div>
							</form>
						);
					}}
				</Formik>
			</AppFrameAuth >
		);
	}


const mapStateToProps = (state: ApplicationState): IPasswordResetStateProps => ({
	passwordResetPromise: state.auth.passwordResetPromise,
	userEmail: '',
	error: state.auth.passwordResetError
});

export const PasswordReset = connect(mapStateToProps, passwordResetActionCreators)(PasswordResetBase);
