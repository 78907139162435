export const getDayDiffBetweenDates = (startDate: string, endDate: string): number => {
  //Get 1 day in milliseconds
  const oneDay=1000*60*60*24;

  // Convert both dates to milliseconds
  var startDateMS = new Date(startDate).getTime();
  var endDateMS = new Date(endDate).getTime();

  // Calculate the difference in milliseconds
  var diffMS = endDateMS - startDateMS;
    
  // Convert back to days and return
  return Math.round(diffMS/oneDay);
};