import * as React from 'react';
import { IModal } from 'common/interfaces';
import css from './modal.module.scss';
import classes from 'classnames';
import { Button } from 'components/Button';
import { ReactComponent as IconX } from 'assets/icons/icon-x.svg';
import { ActionIcon } from 'components/ActionIcon';
import { TButtonStyle } from 'common/types/TButtonStyle';
import {
	disableBodyScroll,
	enableBodyScroll,
	clearAllBodyScrollLocks,
} from 'common/utils/bodyScrollLock';
import { Error } from 'components/Toast';
import { IFormState } from 'common/interfaces/IFormState';

interface IModalProps {
	modal: IModal;
	dismissModal: () => void;
}

interface IModalState {
	isModalBackdropCancellable: boolean;
}

export class Modal extends React.PureComponent<IModalProps, IFormState & IModalState> {
	private modal;
	private title;

	constructor(props) {
		super(props);
		this.state = {
			isDisabled: this.props.modal.confirmationString || this.props.modal.isDisabled ? true : false,
			isLoading: false,
			errorMessage: null,
			isModalBackdropCancellable: false,
		};
		this.modal = React.createRef();
		this.title = React.createRef();
	}

	_submitMyForm = null;

	handleSubmitMyForm = (event) => {
		if (this._submitMyForm) {
			this._submitMyForm(event);
		}
	};

	bindSubmitForm = (submitForm) => {
		this._submitMyForm = submitForm;
	};

	setFormDisabledAttribute = (isDisabled: boolean) => {
		this.setState({
			isDisabled,
		});
	};

	setFormLoadingAttribute = (isLoading: boolean) => {
		this.setState({
			isLoading,
		});
	};

	componentDidMount() {
		document.addEventListener('keyup', this.dismissOnEscape, true);
		// document.addEventListener('click', this.handleClickOutside, true);
		document.addEventListener('mouseup', this.handleMouseUp, true);
		document.addEventListener('mousedown', this.handleMouseDown, true);

		this.title.current.focus();

		const bodyRef = document.querySelector('body');

		disableBodyScroll(bodyRef);
	}

	componentWillUnmount() {
		document.removeEventListener('keyup', this.dismissOnEscape, true);
		document.removeEventListener('mouseup', this.handleMouseUp, true);
		document.removeEventListener('mousedown', this.handleMouseDown, true);

		const bodyRef = document.querySelector('body');

		enableBodyScroll(bodyRef);
	}

	dismissOnEscape = (event) => {
		if (event.keyCode === 27) {
			this.props.dismissModal();
		}
	};

	isCancellableEvent = (event): boolean => {
		if (
			!this.modal.current ||
			(!this.modal.current.contains(event.target) &&
				!document.querySelector('.MuiDialog-root') &&
				!document.querySelector('.MuiMenu-list') &&
				!document.querySelector(`[class*="-MenuList"]`) &&
				!document.querySelector(`[class*="-menu"]`))
		) {
			return true;
		}

		return false;
	};

	handleMouseDown = (event) => {
		if (this.isCancellableEvent(event)) {
			this.setState({
				isModalBackdropCancellable: true,
			});
		}
	};

	handleMouseUp = (event) => {
		if (this.isCancellableEvent(event) && this.state.isModalBackdropCancellable) {
			this.onCancel();
		} else {
			this.setState({
				isModalBackdropCancellable: false,
			});
		}
	};

	onCancel = () => {
		if (this.props.modal.onCancel) {
			this.props.modal.onCancel();
		} else {
			this.props.dismissModal();
		}
	};

	setErrorMessage = (errorMessage: string) => {
		this.setState({
			errorMessage,
		});
	};

	render() {
		const { modal } = this.props;

		let primaryButtonStyle: TButtonStyle;

		if (modal.confirmationString) {
			primaryButtonStyle = 'destructive';
		} else {
			primaryButtonStyle = modal.primaryButtonStyle;
		}

		const modalActions = {
			setFormDisabledAttribute: this.setFormDisabledAttribute,
			setFormLoadingAttribute: this.setFormLoadingAttribute,
			setErrorMessage: this.setErrorMessage,
		};

		return (
			<div className={css.backdrop} aria-describedby='modalDescription'>
				<div className={classes('modal', css.modal)} ref={this.modal}>
					<div className={css.modalHeader}>
						<div className='screenreader-text' id='modalDescription'>
							This is a dialog window which overlays the main content of the page. The modal begins with
							a heading 1 called &quot;{modal.title}&quot;. Pressing the escape key or cancel button at
							the bottom of the modal will close the modal and bring you back to where you were on the
							page.
						</div>
						<ActionIcon className={css.close} onClick={this.onCancel} ariaLabel='Close Modal'>
							<IconX />
						</ActionIcon>
						<h1 className={classes('h4', css.title)} tabIndex={0} ref={this.title} id='modal-title'>
							{modal.title}
						</h1>
					</div>
					{this.state.errorMessage && <Error>{this.state.errorMessage}</Error>}
					{modal.description &&
						(typeof modal.description === 'string' ? <p>{modal.description}</p> : <modal.description />)}
					{modal.content && (
						<div className={css.modalContent}>
							{modal.primaryButtonAction && !modal.isMultistepModal ? (
								<modal.content />
							) : (
								<modal.content
									bindSubmitForm={this.bindSubmitForm}
									defaultFormContent={modal.defaultFormContent}
									formAction={
										modal.formActionMultistepModal ? modal.formAction(modalActions) : modal.formAction
									}
									confirmationString={modal.confirmationString}
									setFormDisabledAttribute={this.setFormDisabledAttribute}
									setFormLoadingAttribute={this.setFormLoadingAttribute}
									isMultistepModal={modal.isMultistepModal}
									setErrorMessage={this.setErrorMessage}
								/>
							)}
						</div>
					)}
					<div className={css.actionArea}>
						{modal.actionAreaLabel && (
							<span className={css.actionAreaLabel}>{modal.actionAreaLabel}</span>
						)}
						{modal.hasSecondaryButton ||
							(typeof modal.hasSecondaryButton === 'undefined' && (
								<Button
									onClick={modal.secondaryButtonAction ? modal.secondaryButtonAction : this.onCancel}
									style={modal.secondaryButtonStyle ? modal.secondaryButtonStyle : 'secondary'}
								>
									{modal.secondaryButtonLabel ? modal.secondaryButtonLabel : 'Cancel'}
								</Button>
							))}

						<Button
							onClick={(event) => {
								console.log('Primary Button Clicked');
								console.log('modal', modal);
								//This could be improved
								if (modal.primaryButtonActionWithModalActions) {
									console.log('modal.primaryButtonActionWithModalActions');
									modal.primaryButtonActionWithModalActions(modalActions);
								} else if (
									(typeof modal.isForm === 'undefined' &&
										(modal.primaryButtonAction || this._submitMyForm)) ||
									modal.isForm === true
								) {
									if (modal.isMultistepModal) {
										this.handleSubmitMyForm(event);
									} else {
										modal.primaryButtonAction
											? modal.primaryButtonAction()
											: this.handleSubmitMyForm(event);
									}
								} else {
									modal.primaryButtonAction ? modal.primaryButtonAction() : this.onCancel();
								}
							}}
							disabled={this.state.isDisabled}
							style={primaryButtonStyle}
							isLoading={this.state.isLoading}
						>
							{modal.primaryButtonLabel ? modal.primaryButtonLabel : 'OK'}
						</Button>
					</div>
				</div>
			</div>
		);
	}
}
