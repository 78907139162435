import * as React from 'react';
import { Link } from 'react-router-dom';
import css from './application-error.module.scss';
import { InnerContainer } from 'components/Page';
import { Button } from 'components/Button/Button';

export const ApplicationError: React.FC = props => {
	return (
		<div className={css.applicationError}>
			<InnerContainer>
				<h1>Unexpected Error</h1>
				<p className={css.description}>
					The application has encountered an unknown error.
				</p>
				<a className={css.backLink} href='/'>
					&lt; Back to Home
				</a>
			</InnerContainer>
		</div>
	);
};
