import * as React from 'react';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Button, Card, CardContent, CardHeader, Grid, GridList, GridListTile, IconButton } from '@material-ui/core';
import { FormPatientAddress } from 'components/Forms/PersonaBuilder/FormPersonaBuilder';
import css from './patient-address-tab.module.scss';
import { AddCircle, ChevronLeft, ChevronRight, Close } from '@material-ui/icons';
import classes from 'classnames';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { IPatientAddress } from 'common/interfaces/personaBuilder/IPatientAddress';
import { MuiButton } from 'components/MaterialUI';
import { Link } from 'react-router-dom';
import { IPatient } from 'common/interfaces/personaBuilder/IPatient';

interface IPatientAddressRouteProps {
	id?: string
}
interface IPatientAddressTabReduxProps {
	currentPatient: IPatient,
	patientAddresses: IPatientAddress[],
	saveDisabled?: boolean
}
const PatientAddressTabBase : React.FC<
	RouteComponentProps<IPatientAddressRouteProps> &
	IPatientAddressTabReduxProps &
	ResolveThunks<typeof PersonaBuilderActionCreators>
	> = props => {

	const {
		currentPatient
	} = props;
	const [addresses, setAddresses] = React.useState([]);

	let _submitForm = null;
	const _bindSubmitForm = (submitForm) => {
		_submitForm = submitForm;
	};

	const _handleSubmit = () => {
		if (_submitForm){
			_submitForm();
		}
	};

	React.useEffect(()=>{
		setAddresses(props.patientAddresses);
	}, [props.patientAddresses])

	const onClickAddAddress = (event) => {
		setAddresses([...addresses,{
			patient_id: props.match.params.id,
			city: '',
			country: '',
			patient_address_use_id: '',
			patient_address_type_id: '',
			state: '',
			street: '',
			zip: ''
		}])
	};

	const onClickRemoveAddress = (id) =>{
		props.deleteAddress(id, currentPatient.id).then(() => {
			props.getPatientAddresses(currentPatient.id);
		}).catch((e)=>{console.log(e)});
	};

	const saveAddress = (values) => {
		if (!!values.id){
			props.updateAddress(values, currentPatient.id);
		}else{
			props.createAddress(values, currentPatient.id).then(()=>{
				props.getPatientAddresses(currentPatient.id);
			}).catch((e)=>{console.log(e)});
		}
	};
	return (
		<>
			<GridList cellHeight={"auto"} spacing={25}>
				{addresses.map((address, index)=>{
					return (
						<GridListTile cols={1}>
							<Card>
								<CardHeader
									action={
										<IconButton className={css.button} onClick={()=>onClickRemoveAddress(address.id)}>
											<Close/>
										</IconButton>
									}
									style={{paddingBottom: 0}}
								/>
								<CardContent className={css.cardContent}>
									<FormPatientAddress 
										defaultFormContent={address} 
										bindSubmitForm={_bindSubmitForm}
										formAction={saveAddress}/>
								</CardContent>
							</Card>
						</GridListTile>
					)
				})}
			</GridList>
			<Grid container xs={12}>
				<Button className={css.button} onClick={onClickAddAddress}>
					Add Additional Patient Address
					<AddCircle fontSize={'large'} className={classes([css.iconRight],[css.icon])}/>
				</Button>
			</Grid>
			<Grid container xs={12} className={css.actionsContainer}>
				<Grid container xs={12} md={6} justify={'flex-start'} alignContent={'center'}>
					<Button component={Link} to={`/persona-builder/patient-listing/${currentPatient.id}/description`} >
						<ChevronLeft className={classes([css.leftIcon], [css.iconSmall])} />
						Description
					</Button>
				</Grid>
				<Grid container xs={12} md={6} justify={'flex-end'} alignContent={'center'}>
					<MuiButton variant="contained" className={css.button} disabled={false} onClick={_handleSubmit}>
						Save
					</MuiButton>
					<Button component={Link} to={`/persona-builder/patient-listing/${currentPatient.id}/condition`}>
						Condition
						<ChevronRight className={classes([css.rightIcon], [css.iconSmall])} />
					</Button>
				</Grid>
			</Grid>
			</>
		);
};

const mapStateToProps = (state: ApplicationState): IPatientAddressTabReduxProps => {
	return {
		currentPatient: state.personaBuilder.currentPatient,
		patientAddresses: state.personaBuilder.currentPatientAddresses,
		saveDisabled: state.personaBuilder.saveDisabled
	};
};

export const PatientAddressTab = connect(
	mapStateToProps,
	PersonaBuilderActionCreators
)(PatientAddressTabBase);
