import * as React from 'react';
import classes from 'classnames';
import css from './gridItem.module.scss';
import { Link } from 'react-router-dom';
import { ActionIcon } from 'components/ActionIcon';
import { IActionOption } from 'common/interfaces/IActionOption';
import { OptionsTrigger } from 'components/Grid/GridItem/OptionsTrigger';

interface GridItemProps {
	className?: string;
	location?: string;
	action?: any;
	actionOptions?: IActionOption[];
	contentLevel1?: string;
	contentLevel2?: string;
	contentLevel3?: string;
	hasPadding?: boolean;
	SubContent?: React.FC;
	isLowContrast?: boolean;
	type?: 'create';
}

interface IGridItemStateProps {
	isOptionsVisible: boolean;
}

export class GridItem extends React.PureComponent<GridItemProps, IGridItemStateProps> {
	constructor(props) {
		super(props);
		this.state = {
			isOptionsVisible: false
		};
	}

	dismissOptionsAndCallFunction(functionToCall: () => void) {
		this.setState({
			isOptionsVisible: false
		});
		functionToCall();
	}

	render() {
		const {
			actionOptions,
			location,
			children,
			action,
			className,
			contentLevel1,
			contentLevel2,
			contentLevel3,
			hasPadding = true,
			SubContent,
			isLowContrast,
			type
		} = this.props;

		const classString = classes(css.gridItemBehavior, css.gridItemStyle, css[type], className, {
			[css.padding]: hasPadding
		});

		const content = (
			<>
				{children}
				{contentLevel1 && (
					<h3
						className={classes('h5', css.overflowEllipse, css.contentLevel1, {
							[css.isLowContrast]: isLowContrast
						})}
					>
						{contentLevel1}
					</h3>
				)}
				{contentLevel2 && (
					<div
						className={classes(css.overflowEllipse, css.contentLevel2, {
							[css.isLowContrast]: isLowContrast
						})}
					>
						{contentLevel2}
					</div>
				)}
				{contentLevel3 && (
					<div
						className={classes(css.contentLevel3, css.overflowEllipse, {
							[css.isLowContrast]: isLowContrast
						})}
					>
						{contentLevel3}
					</div>
				)}
				{SubContent && <SubContent />}
			</>
		);

		if (actionOptions && location) {
			console.log('gridItem 1');
			return (
				<div className={classes(css.gridItemBehavior, css.linkContainer, css[className])}>
					<Link
						className={classes(css.gridItemStyle, css.linkContent, {
							[css.padding]: hasPadding
						})}
						to={location}
					>
						{content}
					</Link>
					<OptionsTrigger actionOptions={actionOptions} isDark={true} />
				</div>
			);
		} else if (location) {
			console.log('gridItem 2');
			console.log('classString', classString);
			return (
				<Link className={classString} to={location}>
					{content}
				</Link>
			);
		} else if (action) {
			console.log('gridItem 3');
			return (
				<button onClick={action} className={classString}>
					{content}
				</button>
			);
		} else {
			console.log('gridItem 4');
			return (
				<div className={classString}>
					{actionOptions && <OptionsTrigger actionOptions={actionOptions} />}
					{content}
				</div>
			);
		}
	}
}
