import * as React from 'react';
import { AppFrame, InnerContainer, PageTitleArea } from 'components/Page';
import { Success } from 'components/Toast';
import { personaActionCreators } from 'stores/actions/personaActions';
import { personaUploadActionCreators } from 'stores/actions/personaUploadActions';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { PageTabBar } from 'components/Page/PageTabBar';
import { AllPersonasTab } from './AllPersonasTab';
import { UsageHistoryTab } from './UsageHistoryTab';
import { IMultistepModal } from 'common/interfaces';
import { THTMLElementEvent } from 'common/types/THTMLElementEvent';
import { FormPersonaUploadFileSelection } from 'components/Forms/FormPersonaUploadFileSelection';
import { FormPersonaUploadDetailsEntry } from 'components/Forms/FormPersonaUploadDetailsEntry';
import { FormPersonaUploadImageSelection } from 'components/Forms/FormPersonaUploadImageSelection';
import { modalActionCreators } from 'stores/actions/modalActions';
import { EUpdateState } from 'stores/shared/ENums';

const personaViewActionCreators = {
	...modalActionCreators,
	...personaActionCreators,
	...personaUploadActionCreators
};

interface IPersonaLibraryViewReduxProps {
	currentUserType: string;
	applyPersonaUpdateState: EUpdateState;
}

export class PersonaLibraryViewBase extends React.PureComponent<
	IPersonaLibraryViewReduxProps & ResolveThunks<typeof personaViewActionCreators>
> {
	UNSAFE_componentWillMount() {
		this.props.getPersonas();
		this.props.clearApplyPersona();
	}

	createPersonaUploadMultistepModal = (event: THTMLElementEvent<HTMLButtonElement>): IMultistepModal => {
		console.log('event.target', event.target);
		return {
			modals: [
				{
					title: 'Upload Persona File',
					content: FormPersonaUploadFileSelection,
					isForm: false
				},
				{
					title: 'Edit Persona Biographical Details',
					content: FormPersonaUploadDetailsEntry,
					isForm: true,
					formActionMultistepModal: goToNextModal => modalActions => formValues => {
						goToNextModal();
					}
				},
				{
					title: 'Upload Persona Picture',
					content: FormPersonaUploadImageSelection,
					isForm: false
				}
			],
			onCancel: () => {
				this.props.clearAllState();
				this.props.dismissModal();
			},
			onFinish: async () => {
				await this.props.createPersona();
				/*
				if (result) {
					this.props.clearFileState();
					this.props.dismissModal();	
				}
				*/
			},
			hasProgressLabels: true
		};
	};

	render() {
		const { createMultistepModal, currentUserType, applyPersonaUpdateState } = this.props;

		const pageDescription: string = 'Browse our library of synthetic patients.';

		return (
			<AppFrame>
				<InnerContainer>
					{applyPersonaUpdateState == EUpdateState.FinishedOk && (
						<Success>Persona is being added to the Sandbox. This can take up to 15 minutes.</Success>
					)}
					<PageTitleArea
						description={pageDescription}
						buttonList={
							currentUserType === 'mihin-admin' && [
								{
									buttonLabel: 'Upload New Persona',
									buttonAction: (event: THTMLElementEvent<HTMLButtonElement>) =>
										createMultistepModal(this.createPersonaUploadMultistepModal(event), event)
								}
							]
						}
					>
						Persona Library
					</PageTitleArea>
					<PageTabBar
						navigationItems={[
							{
								label: 'All Personas',
								location: '/persona',
								route: '/persona',
								component: AllPersonasTab
							}
							/*
							{
								label: 'Usage History',
								location: '/persona/history',
								route: '/persona/history',
								component: UsageHistoryTab
							}
							*/
						]}
					/>
				</InnerContainer>
			</AppFrame>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IPersonaLibraryViewReduxProps => {
	return {
		currentUserType: state.auth.user.attributes ? state.auth.user.attributes['custom:user_type'] : null,
		applyPersonaUpdateState: state.persona.applyPersonaUpdateState
	};
};

export const PersonaLibraryView = connect(
	mapStateToProps,
	personaViewActionCreators
)(PersonaLibraryViewBase);
