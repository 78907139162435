import messageActions from 'stores/actionInterfaces/messageActionInterfaces';

import { AppThunkAction } from 'stores';
import { errorMessages } from 'common/data/errorMessages';

export const messageActionCreators = {
	createMessage: (error?: Error): AppThunkAction<messageActions> => {
		return async (dispatch, getState) => {
			console.log('error', error);
			var message = errorMessages.general;
			if (getState().messages.messages.includes(message)) {
				console.log('Message already exists');
			} else {
				dispatch({ type: 'CREATE_MESSAGE', message });
				setTimeout(() => {
					dispatch({ type: 'REMOVE_MESSAGE' });
				}, 5000);
			}
		};
	},
	clearMessages: (): AppThunkAction<messageActions> => {
		return async (dispatch, getState) => {
			console.log('CLEAR MESSAGES');
			dispatch({ type: 'CLEAR_MESSAGES' });
		};
	}
};
