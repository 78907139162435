import { getCurrentAuthHeaders } from "common/utils/getCurrentAuthHeaders";
import { url } from "settings";
import { AppThunkAction } from "stores";
import superAdminActions from 'stores/actionInterfaces/superAdminActionInterfaces';
import { IUser } from 'common/interfaces/IUser';
import { IUserOrg } from 'common/interfaces/IUserOrg';
import { messageActionCreators } from './messageActions';
import { IFormActions } from "common/interfaces/IFormActions";
import { modalActionCreators } from 'stores/actions/modalActions';
import { TSetErrorMessage } from "common/types/TSetErrorMessage";
import { TSetFormLoadingAttribute } from "common/types/TSetFormLoadingAttribute";

export const superAdminActionCreators = {
    getAllUsers: (): AppThunkAction<superAdminActions> => {
        return async (dispatch, getState) => {

            dispatch({ type: 'GET_ALL_USERS_REQUEST' });

            const headers = await getCurrentAuthHeaders('json');
            try {
                const getAllUsersRequest = await fetch(`${url}org/all_users`, {
                    headers
                });

                const users: IUser[] = await getAllUsersRequest.json();

                dispatch({
                    type: 'GET_ALL_USERS_RECEIVED',
                    users
                });
            } catch (e) {
                //@ts-ignore
                dispatch(messageActionCreators.createMessage(e));
            }
        };
    },
    getUserOrgs: (): AppThunkAction<superAdminActions> => {
        return async (dispatch, getState) => {

            dispatch({ type: 'GET_USER_ORGS_REQUEST' });

            const headers = await getCurrentAuthHeaders('json');
            try {
                const getUserOrgsRequest = await fetch(`${url}org/user_orgs`, {
                    headers
                });


                const userOrgs: IUserOrg[] = await getUserOrgsRequest.json();

                dispatch({
                    type: 'GET_USER_ORGS_RECEIVED',
                    userOrgs
                });
            } catch (e) {
                //@ts-ignore
                dispatch(messageActionCreators.createMessage(e));
            }
        };
    },
    deleteUser: (
        organizationId: number,
        username: string,
        formActions: IFormActions
    ): AppThunkAction<superAdminActions> => {
        return async (dispatch, getState) => {
            try {
                dispatch({ type: 'DELETE_USER_ACCOUNT_REQUEST', organizationId, username });
                formActions.setFormLoadingAttribute(true);
                // Double-encode username to account for werkzeug's pre-decoding quirks
                const encodedUser = encodeURIComponent(encodeURIComponent(username));

                const headers = await getCurrentAuthHeaders();
                const deleteUserRequest = await fetch(`${url}org/${organizationId}/user/${encodedUser}/delete`, {
                    method: 'post',
                    headers
                });

                if (deleteUserRequest.ok === false) {
                    const deleteUserResponse = await deleteUserRequest.json();
                    formActions.setErrorMessage(deleteUserResponse.errorMessage);
                    formActions.setFormLoadingAttribute(false);
                    return;
                }

                //@ts-ignore
                dispatch(modalActionCreators.dismissModal());
                dispatch({ type: 'DELETE_USER_ACCOUNT_RESPONSE', organizationId, username });
                console.log('success');
            } catch (error) {
                console.log('error');
                //@ts-ignore
                dispatch(messageActionCreators.createMessage(error));
            }
        };
    },

    updateUser: (
        updatedUser: IUser,
        currentOrganizationId: number,
        setErrorMessage: TSetErrorMessage,
        setFormLoadingAttribute: TSetFormLoadingAttribute,
    ): AppThunkAction<superAdminActions> => {
        return async (dispatch, getState) => {
            dispatch({ type: 'UPDATE_USER_REQUEST' });
            const {
                email,
                phone_number,
                user_type,
                organization_id,
                username
            } = updatedUser;
            const headers = await getCurrentAuthHeaders('json');
            const fetchUrl = `${url}org/${currentOrganizationId}/user/${username}`;

            const bodyContent = {
                email,
                phone_number,
                user_type,
                organization_id
            };

            try {
                const updateOrganizationUserRequest = await fetch(fetchUrl, {
                    method: 'put',
                    headers,
                    body: JSON.stringify(bodyContent)
                });

                if (updateOrganizationUserRequest.ok === false) {
                    const updateOrganizationUserResponse = await updateOrganizationUserRequest.json();
                    setErrorMessage(updateOrganizationUserResponse.errorMessage);
                    setFormLoadingAttribute(false);
                    return;
                }

                const getAllUsersRequest = await fetch(`${url}org/all_users`, {
                    headers
                });


                const users: IUser[] = await getAllUsersRequest.json();

                //@ts-ignore
                dispatch(modalActionCreators.dismissModal());
                dispatch({
                    type: 'UPDATE_USER_RECEIVED',
                    users
                });
            } catch (e) {
                console.error('Error in updateOrganizationUser action:', e);
                //@ts-ignore
                dispatch(messageActionCreators.createMessage(e));
            }
        };
    }
};