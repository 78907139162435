import * as React from 'react';
import css from './provider-title-area.module.scss';
import { PageBreadcrumbs } from 'components/Page';
import classes from 'classnames';
import { Button } from 'components/Button';
import { IProvider } from 'common/interfaces/personaBuilder/IProvider';

interface IProviderTitleAreaProps {
	provider: IProvider;
}

export const ProviderTitleArea: React.FC<IProviderTitleAreaProps> = props => {
		const {	provider } = props;

		return (
			<div
				className={classes(css.pageTitleArea, css.center)}
				role='banner'
			>
				<div>
					<PageBreadcrumbs breadcrumbItems={[
						{
							label: 'Creation Dashboard',
							location: '/persona-builder'
						},
						{
							label: 'All Providers',
							location: `/persona-builder/provider-listing`
						},
						{
							label: `${provider.provider_first_name} ${provider.provider_last_name}`,
							location: `/persona-builder/provider-listing/${provider.id}`
						}
					]} />
					<h1 className={classes('h2', css.title)}>
						{provider.provider_first_name} {provider.provider_last_name}
					</h1>
				</div>

				<ul className='reset'>
					<li style={{display: 'inline', padding: 12}}>
						<Button
							onClick={event => {	}}
							style={'link'}
						>
							Export JSON
						</Button>
					</li>

				</ul>
			</div>
		);
}