export const content = {
	contact: {
		email: {
			general: {
				label: 'help@mihin.org',
				email: 'help@mihin.org'
			}
		}
	}
};
