import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { PageTabBar, PageTitleArea, AppFrame, InnerContainer } from 'components/Page';
import { TNavigationItems, INavigationItem } from 'common/interfaces';
import { rofActionCreators } from 'stores/actions/rofActions';
import { EUpdateState } from 'stores/shared/ENums';

import { OverviewTab } from './RofOverviewTab';
import { RofSettingsTab } from './RofSettingsTab';
import { RofBackupTab } from './RofBackupTab';
import { IRof } from 'common/interfaces/IRof';
import { IsUserOfType } from 'components/RouteGuards/IsUserOfType';
import { TUserType } from 'common/types/TUserType';

import { content } from 'common/data/content';
import css from './rof-view.module.scss';
import classes from 'classnames';
import { ReactComponent as IconWarning } from 'assets/icons/icon-warning.svg';
import { Loading } from 'components/Loading';

import { push } from 'connected-react-router';
import { SandboxStatus } from 'components/SandboxStatus/SandboxStatus';
import { organizationActionCreators } from 'stores/actions/organizationActions';

const rofViewActionCreators = {
	...rofActionCreators,
	...organizationActionCreators,
	push
};

interface RofRouteProps {
	id?: string;
}

interface IRofViewReduxProps {
	currentRofId: number;
	currentRof: IRof;
	currentLoadingState: EUpdateState;
	currentUserType: TUserType;
	currentOrganizationId: number;
}

const ProvisioningContent = () => {
	return (
		<div className={css.message}>
			<h2 className={classes('h3', css.messageTitle)}>
				Your sandbox was successfully created and is now spinning up. This may take up to 30 minutes. You
				can wait or come back later.
			</h2>
			<div className={css.loadingIndicator}>
				Loading
				<hr />
			</div>
		</div>
	);
};

const ErrorContent = () => {
	return (
		<div className={css.message}>
			<IconWarning className={css.iconWarning} />
			<h2 className={classes('h3', css.messageTitle)}>This Sandbox has Experienced an Error</h2>
			<p>
				Please refresh the page or contact{' '}
				<a href={`mailto:${content.contact.email.general.email}`}>
					{content.contact.email.general.label}
				</a>{' '}
				for assistance.
			</p>
		</div>
	);
};

const DeletedContent = () => {
	return <h2 className='h3'>This sandbox has been deleted.</h2>;
};

const CreationErrorContent = () => {
	return (
		<div className={css.message}>
			<IconWarning className={css.iconWarning} />
			<h2 className={classes('h3', css.messageTitle)}>This Sandbox has Experienced an Error During the Creation</h2>
			<p>
				Please Delete and Recreate.
			</p>
		</div>
	);
};

class RofBase extends React.PureComponent<
	RouteComponentProps<RofRouteProps> & IRofViewReduxProps & ResolveThunks<typeof rofViewActionCreators>
> {
	UNSAFE_componentWillMount() {
		console.log('this.props.getCurrentRof(rofId);');

		const rofId = Number(this.props.match.params.id);

		this.props.setCurrentRofId(rofId);
		this.props.getRof(rofId);
		this.props.getRofDetails(rofId);
		this.props.setCurrentRofOrganization(rofId)
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		if (newProps.match.params.id !== this.props.match.params.id) {
			const rofId = newProps.match.params.id;

			this.props.setCurrentRofId(rofId);
			this.props.getRof(rofId);
		}

		// if (this.props.currentRof && this.props.currentRof !== newProps.currentRof && !newProps.currentRof) {
		// 	this.props.push('/');
		// }
	}

	handleRofStateChange = event => {
		const setAs: boolean = event.currentTarget.checked;
		this.props.setCurrentRofState(setAs);
	};

	get breadcrumbItems(): INavigationItem[] {
		const { currentRof } = this.props;
		if (currentRof) {
			return [
				{
					label: 'All Sandboxes',
					location: '/rof-management'
				},
				{
					label: currentRof.name,
					location: `/sandbox/${currentRof.id}`
				}
			];
		} else {
			return null;
		}
	}

	isLoading = (): boolean => {
		return !this.props.currentRof;
	};

	pageTabBarItems = () => {
		const { currentUserType, currentRofId } = this.props;
		const pageTabBarItems: TNavigationItems = [
			{
				label: 'InterOp PITs',
				location: `/sandbox/${currentRofId}`,
				route: `/sandbox/:id`,
				component: OverviewTab
			},
			{
				label: 'Backup',
				location: `/sandbox/${currentRofId}/backup`,
				route: `/sandbox/:id/backup`,
				component: RofBackupTab
			}
		];

		if (currentUserType === 'mihin-admin' || currentUserType === 'org-admin') {
			const settings = {
				label: 'Settings',
				location: `/sandbox/${currentRofId}/settings`,
				route: `/sandbox/:id/settings`,
				component: IsUserOfType(RofSettingsTab, ['org-admin', 'mihin-admin'])
			};
			pageTabBarItems.splice(1, 0, settings);
		}
		return pageTabBarItems;
	};

	content() {
		switch (this.props.currentRof.stack_status) {
			case 'CREATE_QUEUED':
			case 'CREATE_IN_PROGRESS':
			case 'ROLLBACK_IN_PROGRESS':
			case 'UPDATE_IN_PROGRESS':
			case 'CREATE_COMPLETE':
				return <ProvisioningContent />;
				break;
			case 'ROLLBACK_FAILED':
			case 'ROLLBACK_COMPLETE':
				return <ErrorContent />;
				break;
			case 'DELETE_COMPLETE':
				return <DeletedContent />;
				break;
			case 'CREATE_FAILED':
				return <CreationErrorContent />;
				break;
			default:
				return <PageTabBar navigationItems={this.pageTabBarItems()} />;
		}
	}

	render() {
		const { currentRof } = this.props;

		if (this.isLoading()) {
			return (
				<AppFrame>
					<Loading />
				</AppFrame>
			);
		}

		return (
			<AppFrame>
				<InnerContainer>
					<SandboxStatus currentSandbox={currentRof}></SandboxStatus>
					<PageTitleArea
						currentRofStatus={currentRof.stack_status}
						isCurrentRofOn={true}
						isCurrentRofAutomatic={currentRof.pit_auto_on_off}
						handleRofStateChange={this.handleRofStateChange}
						verticalAlignment={currentRof.pit_auto_on_off ? 'top' : 'center'}
						breadcrumbItems={this.breadcrumbItems}
					>
						{currentRof.name}
					</PageTitleArea>
					{this.content()}
				</InnerContainer>
			</AppFrame>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IRofViewReduxProps => {
	const userOrgId = Number(state.auth.user.attributes.organizationId)
	const currentOrganizationId = state.organization.currentOrganization ? state.organization.currentOrganization : null
	const currentOrganization = currentOrganizationId ? state.organization.organizations[currentOrganizationId] : state.organization.organizations[userOrgId]
	const currentUserType = currentOrganization ? currentOrganization.user_type : state.auth.user.attributes['custom:user_type']

	return {
		currentRofId: state.rof.currentRofId,
		currentRof:
			state.rof.allRof && state.rof.currentRofId ? state.rof.allRof[state.rof.currentRofId] : null,
		currentLoadingState: state.rof.allRofLoadingState[state.rof.currentRofId],
		currentUserType: currentUserType,
		currentOrganizationId: currentOrganizationId,
	};
};

export const RofView = connect(mapStateToProps, rofViewActionCreators)(RofBase);
