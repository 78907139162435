import * as React from 'react';
import { IModal } from 'common/interfaces';
import { ReactComponent as IconExternalLink } from 'assets/icons/icon-external-link.svg';
import { IEntitlementSummary } from 'common/interfaces/IEntitlementSummary';


export const noEntitlementAvailableModal = (entitlementsSummary: IEntitlementSummary): IModal => ({
    title: entitlementsSummary.rof_purchased === null ? 'No sandboxes purchased' : 'All sandboxes are currently provisioned.',
    description: entitlementsSummary.rof_purchased === null ? () => (
        <p>
            There are no sandboxes purchased. To purchase a new sandbox, {' '}
            <a href='https://console.aws.amazon.com/marketplace/home/#/subscriptions' target='_BLANK'>
                click here <IconExternalLink />
            </a>
        </p>
    ) : () => (
        <p>
            All sandboxes are currently in use. To create a new sandbox, you can{' '}
            <a href='https://console.aws.amazon.com/marketplace/home/#/subscriptions' target='_BLANK'>
                purchase more <IconExternalLink />
            </a>{' '}
			or delete an existing instance.
        </p>
    ),
    primaryButtonLabel: 'Dismiss',
    hasSecondaryButton: false
});

