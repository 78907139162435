import * as React from 'react';
import classes from 'classnames';
import css from './panel.module.scss';

interface PanelProps {
	type?: 'callOut';
	className?: string;
	style?: React.CSSProperties;
}

export const Panel: React.FC<PanelProps> = props => {
	const { children, type, style, className } = props;
	return (
		<div
			className={classes(
				css.panel,
				{
					[css.callOut]: type === 'callOut'
				},
				className
			)}
			style={style}
		>
			{children}
		</div>
	);
};
