export const convertArrayToIndexedObject = <T extends { id?: number }>(
	array: T[]
): { [id: number]: T } => {
	if (array.reduce) {
		return array.reduce((object, previousObject: T) => {
			object[previousObject.id] = previousObject;
			return object;
		}, {});
	} else {
		return {};
	}
};

export const convertArrayToStringIndexedObject = <T>(
	array: T[],
	idPropertyName: string = 'id'
): { [id: string]: T } => {
	if (array.reduce) {
		return array.reduce((object, previousObject: T) => {
			object[previousObject[idPropertyName]] = previousObject;
			return object;
		}, {});
	} else {
		return {};
	}
};
