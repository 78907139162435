import notificationActions from 'stores/actionInterfaces/notificationActionInterfaces';
import { AppThunkAction } from 'stores';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { url } from 'settings';
import { INotification } from 'common/interfaces/INotification';
import { messageActionCreators } from './messageActions';
import { modalActionCreators } from 'stores/actions/modalActions';
import { EUpdateState } from 'stores/shared/ENums';

export const notificationActionCreators = {
    createNotification: (
        notification: INotification
    ): AppThunkAction<notificationActions> => {
        return async (dispatch, getState) => {
            dispatch({ type: 'CREATE_NEW_NOTIFICATION_REQUEST' });

            const headers = await getCurrentAuthHeaders('json');

            try {
                const createNotificationRequest = await fetch(`${url}notification/create`, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(notification)
                });

                const createNotificationResponse = await createNotificationRequest.json();

                if (createNotificationRequest.ok === false) {
                    console.log('createOrganizationsRequest.ok === false');
                    return;
                }

                //@ts-ignore
                dispatch(modalActionCreators.dismissModal());

                dispatch({
                    type: 'CREATE_NEW_NOTIFICATION_RECEIVED',
                    notification
                });
            } catch (e) {
                //@ts-ignore
                dispatch(messageActionCreators.createMessage(e));
            }
        };
    },
    getNotifications: (): AppThunkAction<notificationActions> => {
        return async (dispatch, getState) => {

            dispatch({ type: 'GET_NOTIFICATIONS_REQUEST' });

            const headers = await getCurrentAuthHeaders('json');
            try {
                const getNotificationsRequest = await fetch(`${url}notification`, {
                    headers
                });

                const notifications: INotification[] = await getNotificationsRequest.json();

                dispatch({
                    type: 'GET_NOTIFICATIONS_RECEIVED',
                    notifications
                });
            } catch (e) {
                //@ts-ignore
                dispatch(messageActionCreators.createMessage(e));
            }
        };
    },
    deleteNotification: (notificationId: number): AppThunkAction<notificationActions> => {
        return async (dispatch, getState) => {
            dispatch({ type: 'DELETE_NOTIFICATION_REQUEST' });
            const headers = await getCurrentAuthHeaders('json');
            const fetchUrl = `${url}notification/${notificationId}`;
            try {
                const deleteNotificationRequest = await fetch(fetchUrl, {
                    method: 'delete',
                    headers,
                });
            } catch (e) {
                //@ts-ignore
                dispatch(messageActionCreators.createMessage(e));
            }
            dispatch({ type: 'DELETE_NOTIFICATION_RECEIVED', notificationId: notificationId });
        };
    },
    deleteNotifications: (notifications): AppThunkAction<notificationActions> => {
        return async (dispatch, getState) => {
            dispatch({ type: 'DELETE_NOTIFICATIONS_REQUEST' });
            const headers = await getCurrentAuthHeaders('json');
            const fetchUrl = `${url}notification/`;
            try {
                const deleteNotificationRequest = await fetch(fetchUrl, {
                    method: 'delete',
                    headers,
                });
            } catch (e) {
                //@ts-ignore
                dispatch(messageActionCreators.createMessage(e));
            }
            dispatch({ type: 'DELETE_NOTIFICATIONS_RECEIVED' });
        };
    }
};