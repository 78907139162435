import * as React from 'react';
import { IRof } from 'common/interfaces/IRof';
import { Activity } from 'components/Toast/Activity/Activity';
import css from './sandbox-status.module.scss';
import classes from 'classnames';

interface ISandboxStatusProps {
	currentSandbox: IRof;
	className?: string;
}

interface IOrgSandboxStatusProps {
	currentOrganizationRof?: IRof[];
	subOrganizationRof?: IRof[];
	className?: string;
}

export const SandboxStatus: React.FC<ISandboxStatusProps> = props => {
	const { currentSandbox, className } = props;
	switch (currentSandbox.operation_status) {
		case 'iol-db-backup-start':
			return (
				<Activity className={classes(className, css.activity)}>Your sandbox is backing up.</Activity>
			);
		case 'iol-db-restore-start':
			return (
				<Activity className={classes(className, css.activity)}>
					Your sandbox is restoring from a backup.
				</Activity>
			);
		case 'iol-db-reset-start':
			return (
				<Activity className={classes(className, css.activity)}>Your sandbox is resetting.</Activity>
			);
		default:
			return null;
	}
};

export const OrgSandboxStatus: React.FC<IOrgSandboxStatusProps> = props => {
	const { currentOrganizationRof, subOrganizationRof, className } = props;

	const disableDeleteStatus = [
		"iol-db-backup-start",
		"iol-db-restore-start",
		"iol-db-reset-start"
	]

	if (currentOrganizationRof.some(currentRof => {
		return disableDeleteStatus.includes(currentRof.operation_status)
	})) {
		return (
			<Activity className={classes(className, css.activity)}>There is a sandbox operating in your organization.</Activity>
		);
	}

	if (subOrganizationRof) {
		if (subOrganizationRof.some(currentRof => {
			return disableDeleteStatus.includes(currentRof.operation_status)
		})) {
			return (
				<Activity className={classes(className, css.activity)}>There is a sandbox operating in a suborganization.</Activity>
			);
		}
	}

	return null
}