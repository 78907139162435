import { ICognitoUser } from 'common/interfaces';
import { ICanCreateApiDoc } from 'common/interfaces/ICanCreateApiDoc';

export const canCreateApiDoc = (user: ICognitoUser): ICanCreateApiDoc => {
	const userType = user.attributes['custom:user_type'];

	return {
		hasPrivilege: userType === 'mihin-admin'
	};
};
