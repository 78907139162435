import * as React from 'react';
import css from './message.module.scss';
import { Button } from 'components/Button';

interface IMessageProps {
	title: string;
	description: string;
}

export const Message: React.FC<IMessageProps> = props => {
	const { description, title, children } = props;
	return (
		<div className={css.message}>
			<h3>{title}</h3>
			<p className={css.description}>{description}</p>
			{children}
		</div>
	);
};
