import * as React from 'react';
import { ApplicationState } from 'stores';
import { connect } from 'react-redux';
import { EUpdateState } from 'stores/shared/ENums';
import { Link } from 'react-router-dom';
import { MuiTable } from 'components/MaterialUI/MuiTable/MuiTable';
import { sharedHistory } from 'sharedStore';
import { IOrganization } from 'common/interfaces/personaBuilder/IOrganization';
import css from './organization-listing-tab.module.scss';
import { AddCircle } from '@material-ui/icons';
import { objectArrayToMap } from 'common/utils/objectArrayToMap';

interface IOrganizationListingTabViewReduxProps {
	currentUserType: string;
	organizationsTableState: EUpdateState,
	organizations: IOrganization[],
	org_dropdowns: any
}

interface IOrganizationListingTabViewStateProps {
	organizationTableExpanded: boolean;
}

const OrganizationListingTabViewBase: React.FC<IOrganizationListingTabViewReduxProps &
	IOrganizationListingTabViewStateProps> = props => {
	const { organizationsTableState, organizations, org_dropdowns} = props;
	const organizationTypeDisplays = objectArrayToMap(org_dropdowns['organization_types'], 'id', 'organization_type_display')
	const pitNames = objectArrayToMap(org_dropdowns['pits'], 'id', 'pit_name')
	const parentOrganizationNames = objectArrayToMap(org_dropdowns['parent_organizations'], 'id', 'organization_name')
	const organizationsData = props.organizations.map(organization => {
		return {
			...organization,
			organization_type: organizationTypeDisplays[organization.organization_type_id],
			pit_name: pitNames[organization.pit_id],
			parent_organization_name: parentOrganizationNames[organization.parent_organization_id] || '',
		}
	})

	const columns = [
		{
			name: 'Organization Name',
			sortable: true,
			cell: row => `${row.organization_name}`
		},
		{
			name: 'Parent Organization',
			cell: row => `${row.parent_organization_name}`
		},
		{
			name: 'Type',
			cell: row => `${row.organization_type}`
		},
		{
			name: 'Pit',
			cell: row => `${row.pit_name}`
		}
	];
	return (
		<MuiTable
			title={
				<div className={css.tableTitle}>
					<h4>Organization</h4>
					<Link to={'/persona-builder/organization-listing/create'} className={css.actionButton}>
						<AddCircle fontSize={'large'}/>
					</Link>
				</div>
			}
			loading={organizationsTableState === EUpdateState.Loading}
			columns={columns}
			data={organizationsData}
			onRowClicked={(row) => {
				sharedHistory.push(`/persona-builder/organization-listing/${row.id}`);
			}}/>
	);
};

const mapStateToProps = (state: ApplicationState): IOrganizationListingTabViewReduxProps => {
	return {
		currentUserType: ((state.auth || {}).user || {}).attributes['custom:user_type'],
		organizationsTableState: state.personaBuilderOrganization.organizationTableState,
		organizations: state.personaBuilderOrganization.organization,
		org_dropdowns: state.personaBuilderOrganization.organization_dropdowns
	};
};

export const OrganizationListingTab = connect(
	mapStateToProps
)(OrganizationListingTabViewBase);
