import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import Dropzone from 'react-dropzone';
import { IModalForm, IPersonaUploadBioDetails } from 'common/interfaces';
import { personaUploadActionCreators } from 'stores/actions/personaUploadActions';
import { ApplicationState } from 'stores';
import { Button } from 'components/Button';
import css from './persona-upload.module.scss';
import { ReactComponent as IconFileArchive } from 'assets/icons/icon-file-archive.svg';
import { ReactComponent as IconRemove } from 'assets/icons/icon-remove.svg';
import LinearProgress from '@material-ui/core/LinearProgress';

const actionCreators = {
	...personaUploadActionCreators
};

interface personaUploadReduxProps {
	filename: string;
	filesize: number;
	details: IPersonaUploadBioDetails;
	packageKey: string;
	packageErrorType: string;
	packageErrorMessage: string;
	bindSubmitForm?: (any) => void;
}

enum ScreenState {
	Start,
	PackageProcessing,
	PackageProcessed
}

class FormPersonaUploadFileSelectionBase extends React.PureComponent<
	ResolveThunks<typeof actionCreators> & personaUploadReduxProps & IModalForm
> {
	humanFileSize(size: number) {
		let i = Math.floor(Math.log(size) / Math.log(1024));
		return (
			(size / Math.pow(1024, i)).toFixed(2) +
			' ' +
			['B', 'kB', 'MB', 'GB', 'TB'][i]
		);
	}

	UNSAFE_componentWillMount() {
		this.props.createTemporaryUploadUrl();
	}

	render() {
		const {
			setFormDisabledAttribute,
			filename,
			filesize,
			details,
			packageKey,
			packageErrorType,
			packageErrorMessage,
			setErrorMessage
		} = this.props;

		let screenState = ScreenState.Start;
		setFormDisabledAttribute(true);
		if (filename) {
			screenState = ScreenState.PackageProcessing;
			if (packageKey || packageErrorType) {
				screenState = ScreenState.PackageProcessed;

				if (!packageErrorType) {
					setFormDisabledAttribute(false);
				}
			}
		}

		return (
			<>
				<div>
					Drag and drop or browse for the ZIP file containing Persona
					Details.
				</div>
				<div>
					Note: Files must pass system validation before being
					accepted.
				</div>
				{
					{
						[ScreenState.Start]: (
							<>
								<Dropzone
									noClick={true}
									onDrop={acceptedFiles => {
										if (acceptedFiles.length > 0) {
											this.props.setFile(
												acceptedFiles[0],
												setErrorMessage
											);
										}
									}}
								>
									{({
										acceptedFiles,
										getRootProps,
										getInputProps,
										open
									}) => (
										<div>
											<div
												{...getRootProps()}
												className={css.fileDropZone}
											>
												<input {...getInputProps()} />
												<div
													className={
														css.fileDropZoneTitle
													}
												>
													Drag &amp; Drop ZIP File
													Here
												</div>
												<div
													className={
														css.fileDropZoneTitleSub
													}
												>
													or
												</div>
												<Button
													className={
														css.fileDropZoneButton
													}
													onClick={open}
												>
													Browse for File
												</Button>
											</div>
										</div>
									)}
								</Dropzone>
							</>
						),
						[ScreenState.PackageProcessing]: (
							<>
								<div className={css.fileArea}>
									<IconFileArchive className={css.fileIcon} />
									<div className={css.fileDetails}>
										<span className={css.fileName}>
											{filename}
										</span>
										<span>
											{this.humanFileSize(filesize)}
										</span>
									</div>
									<div className={css.fileProgress}>
										<LinearProgress
											className={css.fileProgressBar}
											classes={{
												colorPrimary: css.colorPrimary,
												barColorPrimary:
													css.colorPrimary
											}}
										/>
										<div
											className={css.fileProgressMessage}
										>
											Uploading and validating file...
										</div>
									</div>
									<div className={css.fileRemove}>
										<button
											onClick={() =>
												this.props.clearFileState()
											}
										>
											<IconRemove />
										</button>
									</div>
								</div>
							</>
						),
						[ScreenState.PackageProcessed]: (
							<>
								<div className={css.fileArea}>
									<IconFileArchive className={css.fileIcon} />
									<div className={css.fileDetails}>
										<span className={css.fileName}>
											{filename}
										</span>
										<span>
											{this.humanFileSize(filesize)}
										</span>
									</div>
									<div className={css.fileProgress}>
										{(packageErrorType && (
											<p>{packageErrorMessage}</p>
										)) || (
											<span>
												The file has successfully
												uploaded and passed validation.
											</span>
										)}
									</div>
									<div className={css.fileRemove}>
										<button
											onClick={() =>
												this.props.clearFileState()
											}
										>
											<IconRemove />
										</button>
									</div>
								</div>
							</>
						)
					}[screenState]
				}
			</>
		);
	}
}

const mapStateToProps = (state: ApplicationState): personaUploadReduxProps => {
	return {
		filename: state.personaUpload.filename,
		filesize: state.personaUpload.filesize,
		details: state.personaUpload.details,
		packageKey: state.personaUpload.packageKey,
		packageErrorType: state.personaUpload.packageErrorType,
		packageErrorMessage: state.personaUpload.packageErrorMessage
	};
};

export const FormPersonaUploadFileSelection = connect(
	mapStateToProps,
	actionCreators
)(FormPersonaUploadFileSelectionBase);
