import * as React from 'react';
import css from './success.module.scss';
import classes from 'classnames';

interface ISuccessProps {
	className?: string;
}

export const Success: React.FC<ISuccessProps> = props => {
	const { className } = props;
	return (
		<div role='status' className={classes(css.success, className)}>
			{props.children}
		</div>
	);
};
