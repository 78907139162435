import * as React from 'react';
import { Formik } from 'formik';
import { IModalForm } from 'common/interfaces';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import classes from 'classnames';
import { FormLabel } from 'components/FormElements';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { ErrorFocus } from 'components/ErrorFocus';
import { Button, Card, CardContent, Grid, MuiThemeProvider, Theme, createMuiTheme } from '@material-ui/core';
import css from './form-provider-description.module.scss';
import { providerDescriptionValidationSchema } from 'common/validation-schema/personaBuilder/providerDescriptionValidationSchema';
import { ProviderActionCreators } from 'stores/actions/personaBuilder/providerActions';
import { MuiSelect } from 'components/MaterialUI';
import MUIRichTextEditor from 'mui-rte';
import { ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import { stateToHTML } from "draft-js-export-html";
import { IPatientUseCase } from 'common/interfaces/personaBuilder/IPatientUseCase';
import { IsFormDirty } from 'components/RouteGuards/IsFormDirty';

interface IFormProviderDescriptionState {
	isLoading: boolean;
	file: any;
}

interface IFormProviderDescriptionReduxProps {
	useCases: IPatientUseCase[]
}

const defaultTheme: Theme = createMuiTheme({
	palette: {
		primary: {
			main: '#000000'
		}
	}
});

Object.assign(defaultTheme, {
	overrides: {
		MUIRichTextEditor: {
			root: {
				width: '100%',
				border: '1px solid #526578',
				borderRadius: '5px'
			},
			container: {
				margin: 0
			},
			editor: {
				padding: '1.8rem 2.5rem',
				height: '300px',
				overflow: 'auto',
				width: '100%'
			},
			placeHolder: {
				padding: '1.8rem 2.5rem'
			},
			toolbar: {
				borderBottom: '1px solid #526578',
				padding: '5px'
			}
		}
	}
});

export class FormProviderDescriptionBase extends React.PureComponent<
	ResolveThunks<typeof ProviderActionCreators> &
	IFormProviderDescriptionReduxProps &
	IModalForm,
	IFormProviderDescriptionState> {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			file: null
		};
	}

	setFormLoadingAttribute = (isLoading: boolean) => {
		this.setState({
			isLoading
		});
	};

	handleFileChange = (event: any) => {
		if (event.target.files.length > 0){
			this.setState({file: URL.createObjectURL(event.target.files[0])})
		}

  }
  
  handleFormSubmit = (values, setSubmitting, resetForm) => {
		const { formAction, setErrorMessage } = this.props;
		this.setFormLoadingAttribute(true);
		if (formAction) {
			const trimmedValues = trimAllStringProperties(values);
			formAction(trimmedValues, setErrorMessage, this.setFormLoadingAttribute);
			resetForm(trimmedValues);
		}
		setSubmitting(false);
	};

	render() {
		let { setFormLoadingAttribute } = this.props;
		const {
			bindSubmitForm,
			formAction,
			defaultFormContent,
			setErrorMessage
		} = this.props;

		let initialValues = {
			type: '',
			bio: '',
			description: '',
			image_url: '',
			base_path: '',
			location: '',
			use_cases: [],
			formatted_content: null
		};

		const options = this.props.useCases ? this.props.useCases.map(u=>{ return {id: u.id, label: u.persona_use_case}}) : []
		
		if (defaultFormContent) {
			initialValues = defaultFormContent;
			if (defaultFormContent.bio) {
				const contentHTML = convertFromHTML(defaultFormContent.bio);
				const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap);
				initialValues.formatted_content = convertToRaw(state);
			}
			
			initialValues.use_cases = options.filter(u => defaultFormContent.use_cases.includes(u.id));
		}
		else {
			initialValues.formatted_content = convertToRaw(ContentState.createFromText(initialValues.bio));
		}

		if (!setFormLoadingAttribute) {
			setFormLoadingAttribute = this.setFormLoadingAttribute;
		}

		return (
			<Formik
				initialValues={initialValues}
				enableReinitialize={true}
				onSubmit={(values, { setSubmitting, resetForm }) => this.handleFormSubmit(values, setSubmitting, resetForm)}
				validationSchema={providerDescriptionValidationSchema()}
			>
				{props => {
					const {
						values,
						touched,
						errors,
						dirty,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
						handleReset,
						submitForm,
						isValid
					} = props;
					if (bindSubmitForm) {
						bindSubmitForm(submitForm);
					}

					return (
						<>
							<IsFormDirty
								title=""
								message=""
								dirty={dirty}
							/>
							<Card>
								<CardContent>
									<form onSubmit={handleSubmit}>
										<Grid container className={css.formContainer}>
											<Grid item xs={12} md={6} className={css.formColumn}>
												<Grid item xs={12}>
													<FormLabel htmlFor='use_cases'>Use Cases</FormLabel>
													<MuiSelect labels={options} selected={values.use_cases} onClose={(selected)=>{
														const useCases = selected.map(s=>{return {id: s.id, persona_use_case: s.label}});
														props.setFieldValue('use_cases', useCases);
													}}/>
												</Grid>
												<Grid item xs={12}>
													<FormLabel htmlFor='type'>Provider Type</FormLabel>
													<textarea
														id='type'
														placeholder='Freeform text field to set patient type specific to the story.'
														rows={4}
														value={values.type}
														onChange={handleChange}
														onBlur={handleBlur}
														className={classes( 'text-input', css.noMargin, {'error': errors.type && touched.type})}
														autoComplete='provider-type'
														maxLength={255}
													/>
													{errors.type && touched.type && (
														<div className='input-feedback' style={{marginTop: 0}}>{errors.type}</div>
													)}
													<div className={css.charRemainingContainer}><strong>{values.type && 255 - values.type.length}</strong> characters left</div>
												</Grid>
												<Grid item xs={12}>
													<FormLabel htmlFor='bio'>Biography</FormLabel>
													<MuiThemeProvider theme={defaultTheme}>
														<MUIRichTextEditor
															controls={['title', 'bold', 'italic', 'underline', 'strikethrough', 'highlight', 'link', 'numberList', 'bulletList', 'quote', 'code', 'clear']}
															label={'Add a biography for your patient.'}
															value={JSON.stringify(values.formatted_content) || ''}
															onChange={(state)=>{
																const html = stateToHTML(state.getCurrentContent());
																props.setFieldValue('bio', html);
															}}
															inheritFontSize
															inlineToolbar
														/>
													</MuiThemeProvider>
													{errors.formatted_content && touched.formatted_content && (
														<div className='input-feedback' style={{marginTop: 0}}>{errors.formatted_content}</div>
													)}
												</Grid>
											</Grid>
											<Grid item xs={12} md={6} className={css.formColumn}>
												<Grid item>
													<label>Profile Photo</label>
													<img className={css.imageAvatar} width={'35%'}
																src={this.state.file || 'https://ipog.edu.br/wp-content/themes/ipog/assets/img/graduacao/blank-avatar.png'}/>
													<input
														type={'file'}
														className={css.fileUploadInput}
														accept={'image/*'}
														onChange={this.handleFileChange}
													/>
													<div className={css.removeLink}>
														<Button onClick={(e) => {
															e.preventDefault();
															this.setState({file: null})
														}}>
															Remove Photo
														</Button>
													</div>
												</Grid>
												<Grid item xs={12}>
													<FormLabel htmlFor='description'>One Sentence Summary</FormLabel>
													<textarea
														id='description'
														placeholder='Input Value'
														rows={4}
														value={values.description}
														onChange={handleChange}
														onBlur={handleBlur}
														className={classes( 'text-input', css.noMargin, {'error': errors.description && touched.description})}
														autoComplete='description'
														maxLength={255}
													/>
													{errors.description && touched.description && (
														<div className='input-feedback' style={{marginTop: 0}}>{errors.description}</div>
													)}
													<div className={css.charRemainingContainer}><strong>{values.description && 255 - values.description.length}</strong> characters left</div>
												</Grid>
											</Grid>
											<ErrorFocus/>
										</Grid>
									</form>
								</CardContent>
							</Card>
						</>
					);
				}}
			</Formik>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IFormProviderDescriptionReduxProps => ({
	useCases: state.personaBuilderProvider.provider_dropdowns.use_cases
});

export const FormProviderDescription = connect(
	mapStateToProps,
	ProviderActionCreators
)(FormProviderDescriptionBase);

