import * as yup from 'yup';
import { validatePhoneNumber } from 'common/utils/validatePhoneNumber';
import {
	validateOrgName,
} from 'common/utils/validateOrg';


interface IOrgValidationSchemaConfig {
	isEditSchema?: boolean;
	isParentOrganization?: boolean;
}

export const orgValidationSchema = (
	userValidationSchemaConfig: IOrgValidationSchemaConfig = {
		isEditSchema: false,
		isParentOrganization: false
	}
) => {
	let schemaDefinition;

	schemaDefinition = {
		name: yup.string()
			.required('Required')
			.test(
				userValidationSchemaConfig.isParentOrganization
					? 'Organization Name'
					: 'Suborganization Name',
				userValidationSchemaConfig.isParentOrganization
					? 'Organization name must contain only letters, numbers, spaces, and allowed characters (_.:+=-@/)'
					: 'Suborganization name must contain only letters, numbers, spaces, and allowed characters (_.:+=-@/)',
				validateOrgName
			)
	};


	if (userValidationSchemaConfig.isParentOrganization) {
		schemaDefinition = {
			...schemaDefinition,
			address: yup.string().required('Required'),

			city: yup.string().required('Required'),
			state: yup.string().required('Required'),
			postal_code: yup
				.number()
				.test(
					'Postal Code',
					'Postal code should be 5 characters',
					value => {
						if (value) {
							return value.toString().length === 5;
						} else {
							return false;
						}
					}
				)
				.required('Required'),
			contact_name: yup.string().required('Required'),
			contact_email: yup
				.string()
				.email()
				.required('Required'),
			contact_phone: yup
				.string()
				.required('Required')
				.test(
					'Phone Number',
					'Phone Number is Not Valid',
					validatePhoneNumber
				)
		};
	}
	return yup.object().shape<any>(schemaDefinition) as any;
};
