import React from 'react';
import { useTheme, fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import AddIcon from '@material-ui/icons/Add';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete, { AutocompleteCloseReason } from '@material-ui/lab/Autocomplete';
import ButtonBase from '@material-ui/core/ButtonBase';
import InputBase from '@material-ui/core/InputBase';
import { MuiButton } from '../index';
import { Link } from 'react-router-dom';
import { ChevronRight } from '@material-ui/icons';
import classes from 'classnames';
import css from '../../Forms/FormPersonaBuilder/FormPatientDetails/persona-details.module.scss';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			fontSize: 13,
			border: '1px solid #526578',
			borderRadius: 5,
			marginBottom: 10
		},
		button: {
			padding: '1.8rem 2.5rem',
			fontSize: 13,
			width: '100%',
			textAlign: 'left',
			color: '#586069',
			fontWeight: 600,
			'&:hover,&:focus': {
				color: '#0366d6',
			},
			'& span': {
				width: '100%',
			},
			'& svg': {
				width: 16,
				height: 16,
			},
		},
		tag: {
			marginTop: 5,
		},
		popper: {
			border: '1px solid #526578',
			borderBottomLeftRadius: 5,
			borderBottomRightRadius: 5,
			width: 400,
			fontSize: 13,
			color: '#586069',
			backgroundColor: '#fff',
		},
		header: {
			borderBottom: '1px solid #e1e4e8',
			padding: '8px 10px',
			fontWeight: 600,
		},
		inputBase: {
			padding: 10,
			width: '100%',
			borderBottom: '1px solid #dfe2e5',
			'& input': {
				border: '1px solid #526578',
				borderRadius: 5,
				padding: '1.8rem 2.5rem',
				backgroundColor: theme.palette.common.white,
				transition: theme.transitions.create(['border-color', 'box-shadow']),
				fontSize: 14,
				'&:focus': {
					boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
					borderColor: theme.palette.primary.main,
				},
			},
		},
		paper: {
			boxShadow: 'none',
			margin: 0,
			color: '#586069',
			fontSize: 13
		},
		option: {
			alignItems: 'center'
		},
		popperDisablePortal: {
			position: 'relative',
		},
		iconSelected: {
			width: 17,
			height: 17,
			marginRight: 5,
			marginLeft: -2,
		},
		text: {
			flexGrow: 1,
		},
		listbox:{
			borderBottom: '1px solid #526578',
			maxHeight: 300
		},
		actionsContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
			margin: 10
		}
	}),
);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface IMuiSelectProps {
	title?: string;
	labels: LabelType[]
	selected?: LabelType[];
	onClose?: (selected: LabelType[]) => void;
}
export const MuiSelect: React.FC<IMuiSelectProps> = props => {
	const classes = useStyles({});
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [value, setValue] = React.useState<LabelType[]>(props.selected);
	const [pendingValue, setPendingValue] = React.useState<any>([]);
	const theme = useTheme();

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setPendingValue(value);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
		if (reason === 'toggleInput') {
			return;
		}
		setValue(pendingValue);
		if (anchorEl) {
			anchorEl.focus();
		}
		setAnchorEl(null);
		!!props.onClose && props.onClose(pendingValue);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'github-label' : undefined;
	const ids = props.labels.map(l=>{return l.id});
	const vids = value.map(l=>{return l.id});
	return (
		<React.Fragment>
			<div className={classes.root}>
				<ButtonBase
					disableRipple
					className={classes.button}
					aria-describedby={id}
					onClick={handleClick}
				>
					<span>{props.title}</span>
					<AddIcon />
				</ButtonBase>

			</div>
			<Popper
				id={id}
				open={open}
				anchorEl={anchorEl}
				placement="bottom-start"
				className={classes.popper}

			>
				<Autocomplete
					open
					// onClose={handleClose}
					multiple
					classes={{
						paper: classes.paper,
						option: classes.option,
						popperDisablePortal: classes.popperDisablePortal,
						listbox: classes.listbox
					}}
					value={pendingValue}
					onChange={(event, newValue) => {
						setPendingValue(newValue);
					}}
					disableCloseOnSelect
					disablePortal
					renderTags={() => null}
					noOptionsText="No matches"
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
								{option.label}
						</React.Fragment>
					)}
					options={[...props.labels].sort((a, b) => {
						// Display the selected labels first.
						let ai = vids.indexOf(a.id);
						ai = ai === -1 ? vids.length + ids.indexOf(a.id) : ai;
						let bi = vids.indexOf(b.id);
						bi = bi === -1 ? vids.length + ids.indexOf(b.id) : bi;
						return ai - bi;
					})}
					getOptionLabel={(option) => option.label}
					getOptionSelected={(option, value)=>{return option.id === value.id;}}
					renderInput={(params) => (
						<InputBase
							ref={params.InputProps.ref}
							inputProps={params.inputProps}
							autoFocus
							className={classes.inputBase}
						/>
					)}
				/>

				<div className={classes.actionsContainer}>
					<Button component={Link}
									to={``}
									onClick={(e)=>{
										e.preventDefault();
										if (anchorEl) {
											anchorEl.focus();
										}
										setAnchorEl(null);
									}}>
						Cancel
					</Button>
					{/*
					//@ts-ignore*/}
					<MuiButton variant="contained" disabled={false} onClick={handleClose}>
						Save
					</MuiButton>
				</div>
			</Popper>
		</React.Fragment>
	);
}

interface LabelType {
	id: string | number;
	label: string;
}
