import React, { useState, useLayoutEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import classes from 'classnames';
import css from './side-bar-section.module.scss';
import { ReactComponent as ArrowBlue } from 'assets/icons/icon-arrow-blue.svg';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import usePrevious from 'common/hooks/usePrevious';

export interface ISideBarItem {
	label: string;
	icon: string;
	location?: string;
	action?: (event) => void;
	busy?: boolean;
	labelSecondary?: string;
}

interface IProps {
	sideBarItems: ISideBarItem[];
	sideBarLabel: string;
	isSideBarHidden?: boolean;
	listHeight?: number;
}


export const SideBarSection: React.FC<IProps> = (props) => {
	const sideBarList = useRef(null);
	const { sideBarItems } = props;
	const [isSideBarHidden, setIsSideBarHidden] = useState(false);
	const [listHeight, setListHeight] = useState(props.listHeight)
	const previousProps: IProps = usePrevious(props);
	const needsLayoutUpdate = (((previousProps || {}).sideBarItems || []).length) !== (sideBarItems || []).length;

	useLayoutEffect(() => {
		if (needsLayoutUpdate === true) {
			const currentHeight = (sideBarList.current || {}).clientHeight;
			setListHeight(currentHeight);
		}
	}, [needsLayoutUpdate])


	const sideBarToggle = () => {
		if (!isSideBarHidden) {
			sideBarList.current.style.height = 0;
			setIsSideBarHidden(true);
		} else {
			sideBarList.current.style.height = `${listHeight}px`;
			setIsSideBarHidden(false);
		}
	};


	const sideBarListElement = (sideBarItems || []).map((item, index) => {
		const classesToAdd = classes(
			css.sideBarItem,
			{
				[css.sideBarItemAction]: item.action
			},
			{ [css.sideBarItemIcon]: item.icon },
			{
				[css.sideBarItemIconPlusBold]: item.icon === 'plusBold'
			},
			{ [css.sideBarItemIconRof]: item.icon === 'rof' },
			{
				[css.sideBarItemIconUsers]: item.icon === 'users'
			},
			{
				[css.sideBarItemIconDocument]: item.icon === 'document'
			},
			{
				[css.sideBarItemIconApis]: item.icon === 'apis'
			},
			{
				[css.sideBarItemIconCodeSamples]: item.icon === 'codeSamples'
			},
			{ [css.sideBarItemBusy]: item.busy === true }
		);

		let itemOutput = <></>;

		if (item.action) {
			itemOutput = (
				<button onClick={item.action} className={classesToAdd}>
					<div>{item.label}</div>
					<div className={css.sideBarItemActionSecondaryLabel}>{item.labelSecondary}</div>
				</button>
			);
		} else {
			itemOutput = (
				<NavLink key={`side-bar-item-navlink-${index}`} to={item.location} className={classesToAdd}>
					{item.label}
					{item.busy && (
						<div className={css.loadingSpinnerContainer}>
							<LoadingSpinner size='small' className={css.loadingSpinner} />
						</div>
					)}
				</NavLink>
			);
		}

		return <li key={`side-bar-item-${index}`}>{itemOutput}</li>;
	});

	return (
		<div
			className={
				classes(css.sideBarList, {
					[css.sideBarListHidden]: isSideBarHidden
				})
			}
		>
			<button className={css.sideBarLabel} onClick={sideBarToggle}>
				<h2 className={css.sideBarLabelText}>{props.sideBarLabel}</h2>
				<ArrowBlue
					className={
						classes(css.arrowBlue, {
							[css.arrowBlueHidden]: isSideBarHidden
						})
					}
				/>
			</button>
			<ul
				className={
					classes('reset', css.sideBarListItems, {
						[css.isSideBarHidden]: isSideBarHidden
					})
				}
				ref={sideBarList}
			>
				{sideBarListElement}
			</ul>
		</div >
	);
}
