import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ProviderActionCreators } from 'stores/actions/personaBuilder/providerActions';
import { ApplicationState } from 'stores';
import { Button, Grid } from '@material-ui/core';
import { ChevronLeft, ChevronRight, Delete } from '@material-ui/icons';
import { MuiButton } from 'components/MaterialUI';
import classes from 'classnames';
import css from './provider-details-tab.module.scss';
import { FormProviderDetails } from 'components/Forms/PersonaBuilder/FormPersonaBuilderProvider/FormProviderDetails';

interface IProviderDetailsRouteProps {
	id?: string
}

interface IProviderDetailsTabReduxProps {
	currentProvider: any;
}

export const ProviderDetailsTabBase: React.FC<RouteComponentProps<IProviderDetailsRouteProps> &
IProviderDetailsTabReduxProps &
ResolveThunks<typeof ProviderActionCreators>> = props => {
  
	let _submitForm = null;
	const history = useHistory();
	const prevLink = `/persona-builder/provider-listing`;
	const nextLink = props.currentProvider ? `/persona-builder/provider-listing/${props.currentProvider.id}/description` : '/persona-builder/provider-listing';

	const currentProvider = props.currentProvider ? props.currentProvider : {
		id: 0,
		provider_identifier: '',
		provider_first_name: '',
		provider_last_name: '',
		dob: null,
		provider_phone: '',
		provider_email: '',
		appointment_length: '',
		organization_id: null,
		practice_code_id: null,
		practitioner_role_id: null,
		gender_id: null,
		qualification_id: null
	};

	const _bindSubmitForm = (submitForm) => {
		_submitForm = submitForm;
	}

	const handleSubmit = () => {
		if (_submitForm) {
			_submitForm();
		}
	}

	const isNew = props.match.params.id === 'create';

	const saveProvider = (values) => {
		if (isNew) {
			props.createProvider(values).then(response => {
				window.location.href = `/persona-builder/provider-listing/${response.provider_id}`;
			}).catch((e)=>{console.log(e)});
		} else {
			props.updateProvider(values).then(() => {
				props.getProvider(values.id);
			}).catch((e)=>{console.log(e)});
		}
	}
	const deleteProvider = () => {
		props.deleteProvider(currentProvider.id).then(()=>{
			props.getAllProviders();
			history.push(`/persona-builder/provider-listing/`);
		}).catch((e)=>{console.log(e)});
	}
  
  return (
    <>
      <Grid container>
        <Grid item xs={6} className={css.formHeader}>
          <h4>Provider</h4>
        </Grid>
        <Grid item xs={6} className={css.formDeleteColumn}>
          {!isNew && 
            <Button onClick={deleteProvider}>
              Delete <Delete />
            </Button>
          }
        </Grid>
      </Grid>
      <FormProviderDetails
        bindSubmitForm={_bindSubmitForm}
        defaultFormContent={!isNew && currentProvider} 
        formAction={saveProvider}
      />
			<Grid container className={css.actionsContainer}>
				<Grid item xs={12} md={6}>
					<Button onClick={() => history.push(`${prevLink}`)}>
						<ChevronLeft className={classes([css.leftIcon], [css.iconSmall])}/>
						Back
					</Button>
				</Grid>
				<Grid item xs={12} md={6} style={{textAlign: 'right'}}>
					<MuiButton variant="contained" className={css.button} onClick={handleSubmit}>
						Save
					</MuiButton>
					<Button disabled={props.currentProvider === null} onClick={() => history.push(`${nextLink}`)}>
						Description
						<ChevronRight className={classes([css.rightIcon], [css.iconSmall])}/>
					</Button>
				</Grid>
			</Grid>
    </>
  )
}

const mapStateToProps = (state: ApplicationState): IProviderDetailsTabReduxProps => {
	return {
		currentProvider: state.personaBuilderProvider.currentProvider
	};
};

export const ProviderDetailsTab = connect(
	mapStateToProps,
	ProviderActionCreators
)(ProviderDetailsTabBase);