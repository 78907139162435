import * as React from 'react';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { EUpdateState } from 'stores/shared/ENums';
import { Card } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { sharedHistory } from 'sharedStore';
import {MuiTableHeader} from 'components/MaterialUI/index';
import { IPatient } from 'common/interfaces/personaBuilder/IPatient';
import { Button } from 'components/Button';
import css from '../../../persona-builder.module.scss';
import { ArrowDownward, AddCircle, Edit } from '@material-ui/icons';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { IPatientFamilyMember } from 'common/interfaces/personaBuilder/IPatientFamilyMember';
const sortIcon = <ArrowDownward />;

interface IPatientListingFamilyTabViewReduxProps {
	applyPersonaUpdateState: EUpdateState;
	familyMembers: IPatientFamilyMember[];
	patients: IPatient[];
	relationships: [];
}

interface IPatientListingFamilyTabViewStateProps {
	patientTableExpanded: boolean;
	familyTableExpanded: boolean;
}

const PatientListingFamilyTabViewBase : React.FC<IPatientListingFamilyTabViewReduxProps & 
	IPatientListingFamilyTabViewStateProps &
	ResolveThunks<typeof PersonaBuilderActionCreators>> = props => {

		React.useEffect(()=>{
			props.getAllFamilyMembers();
			props.populateDropdowns();
		}, []);

		const [filteredData, setFilteredData] = React.useState(null);
		const { patients, familyMembers} = props;

		familyMembers.forEach((familyMember) => {
			const patient = patients.filter((p) => { return p.id === familyMember.patient_id; });
			familyMember.patient_name = patient.length > 0 ? `${patient[0].first_name} ${patient[0].last_name}` : '';
		});

		const initialData = familyMembers;

		const filterFamilyMembers = (param) => {
			if (param.length > 2) {
				let foundData = [];
				initialData.forEach(function(dataElement) {
					["patient_relative_name", "patient_name", "patient_relationship_display"].forEach(function(property) {
						if (dataElement[property].toLowerCase().indexOf(param.toLowerCase()) > -1) {
							foundData.push(dataElement);
						}
					});
				});
				setFilteredData(foundData);
			}
			else if (param.length === 0) {
				setFilteredData(initialData);
			}
		}

		const title = 
			<div className={css.tableTitle}>
				<h4>
					Patient Family Members
					<Button style="link" onClick={() => sharedHistory.push(`/persona-builder/patient-listing/family/create`)}><AddCircle fontSize={'large'}/></Button>
				</h4>
			</div>;

		const columns = [
			{
				name: '',
				cell: (row) => <Button style="link" onClick={() => sharedHistory.push(`/persona-builder/patient-listing/family/${row.id}`)}><Edit /></Button>,
				button: true
			},
			{
				name: 'Family Member',
				sortable: true,
				selector: 'patient_relative_name'
			},
			{
				name: 'Related to Patient',
				selector: 'patient_name',
				sortable: true,
			},
			{
				name: 'Relationship Type',
				selector: 'patient_relationship_display',
				sortable: true,
				cell: (row) => <span className={css.relationshipDisplay}>{row.patient_relationship_display}</span>
			}
		];

		return (
			<Card className={css.dataTableCard}>
				<DataTable
					className={css.dataTable}
					columns={columns}
					data={filteredData ? filteredData : initialData}
					noHeader
					highlightOnHover
					defaultSortField="patient_name"
					sortIcon={sortIcon}
					pagination
					paginationRowsPerPageOptions={[10,25,50,100]}
					subHeader
					subHeaderComponent={(<MuiTableHeader title={title} onSearch={(e) => { filterFamilyMembers(e.target.value) }}/>)}
				/>
			</Card>
		);

}

const mapStateToProps = (state: ApplicationState): IPatientListingFamilyTabViewReduxProps => {
	return {
		applyPersonaUpdateState: state.persona.applyPersonaUpdateState,
		familyMembers: state.personaBuilder.allFamilyMembers,
		patients: state.personaBuilder.patients,
		relationships: state.personaBuilder.dropdowns.relationships
	};
};

export const PatientListingFamilyTab = connect(
	mapStateToProps,
	PersonaBuilderActionCreators
)(PatientListingFamilyTabViewBase);
