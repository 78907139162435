import * as React from 'react';
import { AppFrame, InnerContainer, PageTitleArea } from 'components/Page';
import { personaActionCreators } from 'stores/actions/personaActions';
import { personaUploadActionCreators } from 'stores/actions/personaUploadActions';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { modalActionCreators } from 'stores/actions/modalActions';
import { EUpdateState } from 'stores/shared/ENums';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Avatar,
	Table,
	TableBody,
	TableCell,
	TableRow, Typography, Grid
} from '@material-ui/core';

import { AddCircle, LaunchRounded, AssignmentOutlined } from '@material-ui/icons';
import css from './persona-builder.module.scss';
import {Link} from 'react-router-dom';
import { hl7Valuesets } from 'common/data/hl7Valuesets';

// const personaViewActionCreators = {
// 	...modalActionCreators,
// 	...personaActionCreators,
// 	...personaUploadActionCreators
// };

interface IPersonaBuilderViewReduxProps {
	currentUserType: string;
	applyPersonaUpdateState: EUpdateState;
}
interface IComponentState {
	hl7ValuesetData: any;
}

export class PersonaBuilderViewBase extends React.PureComponent<
	IPersonaBuilderViewReduxProps, IComponentState
> {
	constructor(props) {
		super(props);
		this.state = {
			hl7ValuesetData: hl7Valuesets
		};
	}
  // UNSAFE_componentWillMount() {
	// 	this.props.getPersonas();
	// 	this.props.clearApplyPersona();
	// }

	filterHL7Valueset(param) {
		if (param.length > 2) {
			let foundData = [];
      hl7Valuesets.forEach(function(dataElement) {
				if (dataElement["label"].toLowerCase().indexOf(param.toLowerCase()) > -1) {
					foundData.push(dataElement);
				}
			});
			this.setState({hl7ValuesetData: foundData});
		}
		else if (param.length === 0) {
			this.setState({hl7ValuesetData: hl7Valuesets});
		}
	}

	render() {
		const { currentUserType, applyPersonaUpdateState } = this.props;

		const pageDescription: string = 'Build a new synthetic patient.';
		
		return (
			<AppFrame>
				<InnerContainer>
					<PageTitleArea>
						Creation Dashboard
					</PageTitleArea>
					<Card className={css.card}>
						<CardHeader
							avatar={
								<Avatar aria-label="Recipe">
									O
								</Avatar>
							}
							title="Organizations and PITs"
						/>
						<CardContent>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell><Typography variant={'h5'}>Organizations</Typography></TableCell>
										<TableCell align={'right'}>
											<Link to={'/persona-builder/organization-listing/create'} className={css.actionButton}>
												<AddCircle fontSize={'large'}/>
											</Link>
											<Link to={'/persona-builder/organization-listing'}  className={css.actionButton}>
												<LaunchRounded fontSize={'large'}/>
											</Link>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><Typography variant={'h5'}>PITs</Typography></TableCell>
										<TableCell align={'right'}>
											<Link to={''}  className={css.actionButton}>
												<AddCircle fontSize={'large'}/>
											</Link>
											<Link to={''}  className={css.actionButton}>
												<LaunchRounded fontSize={'large'}/>
											</Link>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</CardContent>
					</Card>

					<Card className={css.card}>
						<CardHeader
							avatar={
								<Avatar aria-label="Recipe">
									P
								</Avatar>
							}
							title="Providers"
						/>
						<CardContent>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell><Typography variant={'h5'}>Providers</Typography></TableCell>
										<TableCell align={'right'}>
											<Link to={'/persona-builder/provider-listing/create'} className={css.actionButton}>
												<AddCircle fontSize={'large'}/>
											</Link>
											<Link to={'/persona-builder/provider-listing'}  className={css.actionButton}>
												<LaunchRounded fontSize={'large'}/>
											</Link>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</CardContent>
					</Card>

					<Card className={css.card}>
						<CardHeader
							avatar={
								<Avatar aria-label="Recipe">
									M
								</Avatar>
							}
							title="Medications"
						/>
						<CardContent>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell><Typography variant={'h5'}>Medications</Typography></TableCell>
										<TableCell align={'right'}>
											<Link to={'/persona-builder/medication-listing/create'} className={css.actionButton}>
												<AddCircle fontSize={'large'}/>
											</Link>
											<Link to={'/persona-builder/medication-listing'}  className={css.actionButton}>
												<LaunchRounded fontSize={'large'}/>
											</Link>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</CardContent>
					</Card>

					<Card className={css.card}>
						<CardHeader
							title={
								<Grid container>
									<Grid item xs={12} md={6} className={css.cardHeaderInfo}>
										<AssignmentOutlined fontSize={'large'} /> HL7 Valuesets
									</Grid>
									<Grid item xs={12} md={6}>
										<input
											className={css.cardHeaderSearch}
											id={'search'}
											placeholder={'Search for Valueset'}
											type={'text'}
											onChange={(e) => this.filterHL7Valueset(e.target.value)}
										/>
									</Grid>
								</Grid>
							}
						/>
						<CardContent>
							<Table>
								<TableBody>
									{this.state.hl7ValuesetData.map((hl7valueset, index) => (
										<TableRow key={index}>
											<TableCell><Typography variant={'h5'}>{hl7valueset.label}</Typography></TableCell>
											<TableCell align={'right'}>
												<Link to={`/persona-builder/hl7valueset/${hl7valueset.type}`}  className={css.actionButton}>
													<LaunchRounded fontSize={'large'}/>
												</Link>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</CardContent>
					</Card>

					<Card className={css.card}>
						<CardHeader
							avatar={
								<Avatar aria-label="Recipe">
									P
								</Avatar>
							}
							title="Patients"
						/>
						<CardContent>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell><Typography variant={'h5'}>Patients</Typography></TableCell>
										<TableCell align={'right'}>
											<Link to={'/persona-builder/patient-listing/create'} className={css.actionButton}>
												<AddCircle fontSize={'large'}/>
											</Link>
											<Link to={'/persona-builder/patient-listing'}  className={css.actionButton}>
												<LaunchRounded fontSize={'large'}/>
											</Link>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><Typography variant={'h5'}>Patient Family Members</Typography></TableCell>
										<TableCell align={'right'}>
											<Link to={'/persona-builder/patient-listing/family/create'}  className={css.actionButton}>
												<AddCircle fontSize={'large'}/>
											</Link>
											<Link to={'/persona-builder/patient-listing/family'}  className={css.actionButton}>
												<LaunchRounded fontSize={'large'}/>
											</Link>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</CardContent>
					</Card>

				</InnerContainer>
			</AppFrame>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IPersonaBuilderViewReduxProps => {
	return {
		currentUserType: ((state.auth || {}).user || {}).attributes['custom:user_type'],
		applyPersonaUpdateState: state.persona.applyPersonaUpdateState
	};
};

export const PersonaBuilderView = connect(
	mapStateToProps,
	// personaViewActionCreators
)(PersonaBuilderViewBase);
