import * as React from 'react';
import { Formik } from 'formik';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { ErrorFocus } from 'components/ErrorFocus';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { Grid, Card, CardContent } from '@material-ui/core';
import { Button } from 'components/Button';
import { HighlightOff, Warning } from '@material-ui/icons';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { TSetFormLoadingAttribute } from 'common/types/TSetFormLoadingAttribute';
import { TSetErrorMessage } from 'common/types/TSetErrorMessage';
import { FormLabel } from 'components/FormElements';
import { SegmentedController } from 'components/FormElements';
import { IProvider } from 'common/interfaces/personaBuilder/IProvider';
import { IMedication } from 'common/interfaces/personaBuilder/IMedication';
import { IPatientCondition } from 'common/interfaces/personaBuilder/IPatientCondition';
import { IsFormDirty } from 'components/RouteGuards/IsFormDirty';
import { objectArrayToMap } from 'common/utils/objectArrayToMap';
import { patientMedicationValidationSchema } from 'common/validation-schema/personaBuilder/patientMedicationValidationSchema';
import css from './form-patient-medication.module.scss';
import classes from 'classnames';

interface IFormPatientMedicationState {
  isLoading: boolean;
  confirmDelete: boolean;
  initialValues: any;
  isEditMode: boolean;
  patientConditions: any;
}

interface IFormPatientMedicationProps {
  cancelModalAction?: any;
  deleteAction?: any;
  defaultFormContent?: any;
  formAction?: any;
  bindSubmitForm?: (any) => void;
  setFormLoadingAttribute?: TSetFormLoadingAttribute;
  setErrorMessage?: TSetErrorMessage;
}

interface IFormPatientMedicationReduxProps {
  prescription_statuses: any;
  providers: IProvider[];
  medications: IMedication[];
  patientConditions: IPatientCondition[];
  diagnosis: any;
}

export class FormPatientMedicationBase extends React.PureComponent<
ResolveThunks<typeof PersonaBuilderActionCreators> &
IFormPatientMedicationProps &
IFormPatientMedicationReduxProps,
IFormPatientMedicationState> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      confirmDelete: false,
      isEditMode: false,
      patientConditions: [],
      initialValues: {
        provider_id: '',
        patient_condition_id: '',
        medication_management_id: '',
        self_reported_flag: 'false',
        prescription_status_id: ''
      }
    };
  }

  componentDidMount() {
    const { defaultFormContent } = this.props;
    const isEditMode = defaultFormContent && defaultFormContent.id !== 0;

    const diagnosisNames = objectArrayToMap(this.props.diagnosis, 'id', 'diagnosis_name');
    const conditionsData = this.props.patientConditions.map(condition => {
      return {
        ...condition,
        diagnosis_name: diagnosisNames[condition.diagnosis_id]
      }
    });

    if (isEditMode) {
      defaultFormContent.self_reported_flag = defaultFormContent.self_reported_flag !== undefined ? defaultFormContent.self_reported_flag.toString() : 'false';
      this.setState({
        initialValues: defaultFormContent,
        isEditMode: isEditMode,
        patientConditions: conditionsData
      });
    }
    else {
      this.setState({
        patientConditions: conditionsData
      });
    }
  }
  
  setFormLoadingAttribute = (isLoading: boolean) => {
    this.setState({
      isLoading
    });
  };

  handleFormSubmit = (values, setSubmitting, resetForm) => {
    const { formAction, setErrorMessage } = this.props;
    this.setFormLoadingAttribute(true);
    if (formAction) {
      const trimmedValues = trimAllStringProperties(values);
      formAction(trimmedValues, setErrorMessage, this.setFormLoadingAttribute);
      resetForm(trimmedValues);
    }
    setSubmitting(false);
  };

  render() {
    let { setFormLoadingAttribute } = this.props;
    const {
      bindSubmitForm,
      formAction,
      defaultFormContent,
      setErrorMessage,
      prescription_statuses,
      providers,
      medications,
      setSaveDisabled,
      cancelModalAction,
      deleteAction
    } = this.props;
    
    if (!setFormLoadingAttribute) {
      setFormLoadingAttribute = this.setFormLoadingAttribute;
    }
    
    return (
      <>
        <Formik
          initialValues={this.state.initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting, resetForm }) => this.handleFormSubmit(values, setSubmitting, resetForm)}
          validationSchema={patientMedicationValidationSchema()}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              submitForm,
              isValid
            } = props;
            if (bindSubmitForm) {
              bindSubmitForm(submitForm);
            }
            return (
              <>
                <IsFormDirty
                  title=""
                  message=""
                  dirty={dirty}
                />
                <form onSubmit={handleSubmit}>
                  <Grid container className={css.formContainer}>
                    <Grid item xs={8} className={css.formColumn}>
                      <Grid container>
                        <Grid item xs={6}>
                          <h4>Prescription Medication</h4>
                        </Grid>
                        <Grid item xs={6} style={{textAlign: 'right'}}>
                          {this.state.isEditMode && 
                            <Button type="button" style="link" className={css.deleteConfirmationButton} onClick={deleteAction}>
                              <HighlightOff fontSize="large" /> <span>Remove as patient medication</span>
                            </Button>
                          }
                        </Grid>
                      </Grid>
                      {this.state.isEditMode && 
                        <Grid item xs={8}>
                          <span className={css.currentlyPrescribed}>Currently Prescribed</span>
                        </Grid>
                      }
                      <Grid item xs={12}>
                        <label>Prescription Medication</label>
                        <select
                          name='medication_management_id'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.medication_management_id}
                          className={errors.medication_management_id && touched.medication_management_id ? 'text-input error' : 'text-input'}
                        >
                          <option value={""}>Select</option>
                          {medications.map((medication, index) => (
                            <option key={index} value={medication.id}>
                              {medication.proprietary_name !== '' ? `${medication.proprietary_name}` : `${medication.non_proprietary_name}`}
                            </option>
                          ))}
                        </select>
                        {errors.medication_management_id && touched.medication_management_id && (
                          <div className='input-feedback'>{errors.medication_management_id}</div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <label>For Condition</label>
                        <select
                          name='patient_condition_id'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.patient_condition_id}
                          className={errors.patient_condition_id && touched.patient_condition_id ? 'text-input error' : 'text-input'}
                        >
                          <option value={""}>Select</option>
                          {this.state.patientConditions.map((condition, index) => (
                            <option key={index} value={condition.id}>
                              {condition.diagnosis_name}
                            </option>
                          ))}
                        </select>
                        {errors.patient_condition_id && touched.patient_condition_id && (
                          <div className='input-feedback'>{errors.patient_condition_id}</div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <label>Prescribed by</label>
                        <select
                          name='provider_id'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.provider_id}
                          className={errors.provider_id && touched.provider_id ? 'text-input error' : 'text-input'}
                        >
                          <option value={""}>Select</option>
                          {providers.map((provider, index) => (
                            <option key={index} value={provider.id}>
                              {provider.provider_first_name} {provider.provider_last_name}
                            </option>
                          ))}
                        </select>
                        {errors.provider_id && touched.provider_id && (
                          <div className='input-feedback'>{errors.provider_id}</div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <label>Status</label>
                        <select
                          name='prescription_status_id'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.prescription_status_id}
                          className={errors.prescription_status_id && touched.prescription_status_id ? 'text-input error' : 'text-input'}
                        >
                          <option value={""}>Select</option>
                          {prescription_statuses.map((status, index) => (
                            <option key={index} value={status.id}>
                              {status.prescription_status_display}
                            </option>
                          ))}
                        </select>
                        {errors.prescription_status_id && touched.prescription_status_id && (
                          <div className='input-feedback'>{errors.prescription_status_id}</div>
                        )}
                      </Grid>
                      <Grid container xs={12} direction={'row'} alignItems={'center'}>
                        <FormLabel style={css.label} htmlFor='self_reported_flag'>Is the medication self-reported?</FormLabel>
                        <SegmentedController
                          options={[{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }]}
                          values={values.self_reported_flag} name={'self_reported_flag'}/>
                      </Grid>
                    </Grid>
                    <ErrorFocus/>
                  </Grid>
                </form>
              </>
            );
          }}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState): IFormPatientMedicationReduxProps => ({
  providers: state.personaBuilder.dropdowns.providers,
  medications: state.personaBuilder.dropdowns.medications,
  patientConditions: state.personaBuilder.currentPatientConditions,
  diagnosis: state.personaBuilder.hl7Valuesets.diagnosis,
  prescription_statuses: state.personaBuilder.dropdowns.prescription_statuses
});

export const FormPatientMedication = connect(
  mapStateToProps,
  PersonaBuilderActionCreators
)(FormPatientMedicationBase);