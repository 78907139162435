import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ProviderActionCreators } from 'stores/actions/personaBuilder/providerActions';
import { useHistory } from 'react-router-dom';
import { ApplicationState } from 'stores';
import { Button, Grid } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { MuiButton } from 'components/MaterialUI';
import classes from 'classnames';
import css from './provider-description-tab.module.scss';
import { IProvider } from 'common/interfaces/personaBuilder/IProvider';
import { IProviderMetadata } from 'common/interfaces/personaBuilder/IProviderMetadata';
import { FormProviderDescription } from 'components/Forms/PersonaBuilder/FormPersonaBuilderProvider/FormProviderDescription';
import { IPatientUseCase } from 'common/interfaces/personaBuilder/IPatientUseCase';

interface IProviderDetailsRouteProps {
	id?: string
}

interface IProviderDetailsTabReduxProps {
	currentProvider: IProvider;
	providerMetadata: IProviderMetadata;
	providerUseCases: IPatientUseCase[];
}

export const ProviderDescriptionTabBase: React.FC<RouteComponentProps<IProviderDetailsRouteProps> &
IProviderDetailsTabReduxProps &
ResolveThunks<typeof ProviderActionCreators>> = props => {

	let _submitForm = null;
	const { providerMetadata, providerUseCases, currentProvider } = props;
	const history = useHistory();
	const prevLink = currentProvider ? `/persona-builder/provider-listing/${currentProvider.id}` : '/persona-builder/provider-listing';
	const nextLink = currentProvider ? `/persona-builder/provider-listing/${currentProvider.id}/time-slots` : '/persona-builder/provider-listing';
	const isNew = providerMetadata ? ('id' in providerMetadata ? false : true) : true;

	let providerMetadataFormData = null;
	if (!isNew) {
		providerMetadataFormData = {
			...providerMetadata,
			use_cases: providerUseCases.map((u)=>{ return u.id })
		}
	}

	const _bindSubmitForm = (submitForm) => {
		_submitForm = submitForm;
	}

	const handleSubmit = () => {
		if (_submitForm) {
		_submitForm();
		}
	}

	const saveProviderDescription = (values) => {
		if (isNew) {
			props.createProviderMetadata(currentProvider, values).then(response => {
				props.getProviderMetadata(currentProvider.id);
			}).catch((e)=>{console.log(e)});
		}
		else {
			props.updateProviderMetadata(currentProvider, values).then(() => {
				props.getProviderMetadata(currentProvider.id);
			}).catch((e)=>{console.log(e)});
		}
	}

  return (
    <>
			<Grid container>
				<Grid item xs={12} className={css.formHeader}>
					<h4>Biography</h4>
				</Grid>
				<Grid item xs={12}>
					<FormProviderDescription 
						bindSubmitForm={_bindSubmitForm}
						defaultFormContent={providerMetadataFormData} 
						formAction={saveProviderDescription}/>
				</Grid>
			</Grid>
      <Grid container className={css.actionsContainer}>
				<Grid item xs={12} md={6}>
					<Button onClick={() => history.push(`${prevLink}`)}>
						<ChevronLeft className={classes([css.leftIcon], [css.iconSmall])}/>
						Details
					</Button>
				</Grid>
				<Grid item xs={12} md={6} style={{textAlign: 'right'}}>
					<MuiButton variant="contained" className={css.button} onClick={handleSubmit}>
						Save
					</MuiButton>
					<Button disabled={props.currentProvider === null} onClick={() => history.push(`${nextLink}`)}>
						Time Slots
						<ChevronRight className={classes([css.rightIcon], [css.iconSmall])}/>
					</Button>
				</Grid>
			</Grid>
    </>
  )
}

const mapStateToProps = (state: ApplicationState): IProviderDetailsTabReduxProps => {
	return {
		currentProvider: state.personaBuilderProvider.currentProvider,
		providerMetadata: state.personaBuilderProvider.providerMetadata,
		providerUseCases: state.personaBuilderProvider.providerUseCases
	};
};

export const ProviderDescriptionTab = connect(
	mapStateToProps,
	ProviderActionCreators
)(ProviderDescriptionTabBase);