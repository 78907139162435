import React, { useState } from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { rofActionCreators } from 'stores/actions/rofActions';
import { RouteComponentProps } from 'react-router';
import { AppFrame } from 'components/Page/AppFrame';
import { PageTitleArea, InnerContainer } from 'components/Page';
import { ApplicationState } from 'stores';
import { ISmartApp, IModal, IOrganization, client_type_map, grant_type_map } from 'common/interfaces';
import { EUpdateState } from 'stores/shared/ENums';
import css from './smart-app-view.module.scss';
import { Section } from 'components/Section';
import { TableBody, TableColumn, TableHeader, TableColumnDelete, TableRow } from 'components/Table';
import { Button } from 'components/Button/Button';
import { ISmartAppLauncher } from 'common/interfaces/ISmartAppLauncher';
import { convertIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';
import { IFormActions } from 'common/interfaces/IFormActions';
import { modalActionCreators } from 'stores/actions/modalActions';
import { FormCreateSmartAppLauncher } from 'components/Forms/FormCreateSmartAppLauncher/FormCreateSmartAppLauncher';
import { deleteSmartAppModal } from 'common/modals/deleteSmartAppModal';
import { editSmartAppModal } from './modals/editSmartApp';
import { IRof } from 'common/interfaces/IRof';
import { IFhirPit } from 'common/interfaces/IFhirPit';
import { Loading } from 'components/Loading';
import { ListItemBasic } from 'components/List/ListItemBasic';
import { copy } from 'common/utils/copy';
import classes from 'classnames';
import { SmartAppSwaggerDoc } from './SwaggerDoc';
import { Box, Card, Collapse } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { url } from 'settings';

const smartAppViewActionCreators = {
	...rofActionCreators,
	...modalActionCreators,
};

interface ISmartAppViewRouteProps {
	rofId?: string;
	pitId?: string;
	smartAppId: string;
}

interface ISmartAppViewReduxProps {
	currentSmartApp: ISmartApp;
	currentSmartAppUpdateState: EUpdateState;
	currentPitId: number;
	currentPit: IFhirPit;
	currentRofId: number;
	currentRof: IRof;
	currentOrganization: IOrganization;
	currentSmartAppId: string;
	smartAppLaunchers: ISmartAppLauncher[];
	smartAppLaunchersLoadingState: EUpdateState;
}

interface ISmartAppViewState {
	visibleClientSecret: string;
	isClientSecretRevealed: boolean;
	swaggerDocVisible: boolean;
}

type createSmartAppLauncherReturn = (
	smartAppLauncher: ISmartAppLauncher,
	modalActions: IFormActions
) => void;

class SmartAppViewBase extends React.PureComponent<
	RouteComponentProps<ISmartAppViewRouteProps> &
	ResolveThunks<typeof smartAppViewActionCreators> &
	ISmartAppViewReduxProps,
	ISmartAppViewState
> {
	hiddenCredentials =
		'••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••';

	constructor(props) {
		super(props);
		this.state = {
			visibleClientSecret: this.hiddenCredentials,
			isClientSecretRevealed: false,
			swaggerDocVisible: false,
		};
	}

	UNSAFE_componentWillMount() {
		const { rofId, pitId, smartAppId } = this.getContext();
		this.props.setCurrentRofId(Number(rofId));
		this.props.setCurrentPitId(Number(pitId));
		this.props.setCurrentSmartAppId(smartAppId);
		this.props.getSmartApps(rofId, pitId);
		this.props.getRof(Number(rofId));
		console.log(`this.props.getSmartAppLaunchers(${rofId}, ${pitId}, ${smartAppId})`);
		this.props.getSmartAppLaunchers(rofId, pitId, smartAppId);
		this.props.getRofDetails(rofId);
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		if (this.props.currentSmartAppId !== nextProps.match.params.smartAppId) {
			this.props.setCurrentSmartAppId(nextProps.match.params.smartAppId);
		}
	}

	getContext = (): { rofId: number; pitId: number; smartAppId: string } => {
		return {
			rofId: Number(this.props.match.params.rofId),
			pitId: Number(this.props.match.params.pitId),
			smartAppId: this.props.match.params.smartAppId,
		};
	};

	createSmartAppLauncher =
		(rofId: number, pitId: number, smartAppId: string): createSmartAppLauncherReturn =>
			(smartAppLauncher: ISmartAppLauncher, modalActions: IFormActions) => {
				this.props.createSmartAppLauncher(smartAppLauncher, modalActions, rofId, pitId, smartAppId);
			};

	isLoading = (): boolean => {
		const {
			currentSmartAppUpdateState,
			currentSmartAppId,
			currentRofId,
			currentPitId,
			currentSmartApp,
			currentPit,
			currentRof,
			smartAppLaunchersLoadingState,
		} = this.props;
		return !(
			currentSmartAppUpdateState === EUpdateState.Loaded &&
			currentSmartAppId &&
			currentRofId &&
			currentPitId &&
			currentSmartApp &&
			currentPit &&
			currentRof &&
			smartAppLaunchersLoadingState === EUpdateState.Loaded
		);
	};

	toggleVisibleClientSecret = () => {
		this.setState({
			visibleClientSecret: !this.state.isClientSecretRevealed
				? this.props.currentSmartApp.client_secret
				: this.hiddenCredentials,
			isClientSecretRevealed: !this.state.isClientSecretRevealed,
		});
	};

	render() {
		const {
			currentSmartAppUpdateState,
			currentSmartApp,
			currentRofId,
			currentRof,
			currentPitId,
			currentPit,
			currentOrganization,
			currentSmartAppId,
			smartAppLaunchers,
			smartAppLaunchersLoadingState,
			createModal,
		} = this.props;

		const isLoaded =
			currentSmartAppUpdateState === EUpdateState.Loaded &&
			currentSmartAppId &&
			currentRofId &&
			currentPitId &&
			currentSmartApp &&
			currentPit &&
			currentRof &&
			currentOrganization;
		if (this.isLoading()) {
			return (
				<AppFrame>
					<Loading />
				</AppFrame>
			);
		}

		const createSmartAppLauncherModal: IModal = {
			primaryButtonLabel: 'Create Launcher',
			content: FormCreateSmartAppLauncher,
			description: 'Enter in the required fields to launch the SMART App from Interopability Land.',
			title: 'Create New SMART App Launcher',
			formAction: this.createSmartAppLauncher(currentRofId, currentPitId, currentSmartAppId),
		};

		const deleteSmartAppLauncherModal = (smartAppLauncherId: number): IModal => ({
			primaryButtonLabel: 'Delete Launcher',
			description:
				'This action is permanent. You will need to recreate your SMART App Launcher to use it again.',
			title: 'Delete SMART App Launcher',
			primaryButtonActionWithModalActions: (modalActions) => {
				this.props.deleteSmartAppLauncher(
					modalActions,
					currentRofId,
					currentPitId,
					currentSmartAppId,
					smartAppLauncherId
				);
			},
			primaryButtonStyle: 'destructive',
		});

		const showSwaggerDoc = () => {
			this.setState({ swaggerDocVisible: !this.state.swaggerDocVisible });
		};

		return (
			<AppFrame>
				<InnerContainer>
					<PageTitleArea
						buttonList={[
							{
								tooltip: 'Toggle SMART Auth documentation',
								buttonAction: showSwaggerDoc,
								icon: CheckCircleOutlineIcon,
								active: this.state.swaggerDocVisible,
							},
							{
								tooltip: 'Edit SMART App',
								buttonAction: (event) => {
									createModal(editSmartAppModal(currentSmartApp, currentRofId, currentPitId));
								},
								icon: EditIcon,
							},
							{
								tooltip: 'Delete SMART App',
								buttonAction: (event) => {
									createModal(
										deleteSmartAppModal(
											currentSmartAppId,
											this.getContext(),
											this.props.deleteSmartApp,
											true
										),
										event
									);
								},
								icon: DeleteIcon,
							},
						]}
						breadcrumbItems={[
							{
								label: 'All Sandboxes',
								location: '/rof-management',
							},
							{
								label: currentRof.name,
								location: `/sandbox/${currentRofId}`,
							},
							{
								label: currentPit.name,
								location: `/sandbox/${currentRofId}/pit/${currentPitId}`,
							},
							{
								label: currentSmartApp.name,
								location: `/sandbox/${currentRofId}/pit/${currentPitId}/app/${currentSmartApp.client_id}`,
							},
						]}
					>
						{currentSmartApp.name}
					</PageTitleArea>
					<Box hidden={!this.state.swaggerDocVisible}>
						<Collapse in={this.state.swaggerDocVisible}>
							<Card>
								<SmartAppSwaggerDoc smartApp={currentSmartApp} />
							</Card>
						</Collapse>
					</Box>
					<Section title='Details'>
						<div className={css.maxWidth}>
							{currentSmartApp.client_id && (
								<ListItemBasic
									title='Client ID'
									ContentRight={() => (
										<Button style='link' onClick={() => copy(currentSmartApp.client_id)} animate='copy'>
											Copy
										</Button>
									)}
								>
									{currentSmartApp.client_id}
								</ListItemBasic>
							)}
							{currentSmartApp.client_secret && (
								<>
									<ListItemBasic
										title='Client Secret'
										ContentRight={() => (
											<>
												<Button style='link' onClick={this.toggleVisibleClientSecret}>
													{this.state.isClientSecretRevealed ? 'Conceal' : 'Reveal'}
												</Button>
												<Button
													style='link'
													onClick={() => copy(currentSmartApp.client_secret)}
													animate='copy'
												>
													Copy
												</Button>
											</>
										)}
									>
										<span
											className={classes({
												[css.concealedText]: !this.state.isClientSecretRevealed,
											})}
										>
											{this.state.visibleClientSecret}
										</span>
									</ListItemBasic>
								</>
							)}
							<ListItemBasic title='Client Type'>
								{client_type_map[currentSmartApp.client_type]}
							</ListItemBasic>
							<ListItemBasic title='Grant Type'>
								{grant_type_map[currentSmartApp.grant_type]}
							</ListItemBasic>
							<ListItemBasic
								title='Allowed Scopes'
								ContentRight={() => (
									<Button style='link' onClick={() => copy(currentSmartApp.oauth_scopes)} animate='copy'>
										Copy
									</Button>
								)}
							>
								{currentSmartApp.oauth_scopes}
							</ListItemBasic>
							<ListItemBasic title='Redirect URIs'>
								{Array.isArray(currentSmartApp.redirect_uris) &&
									currentSmartApp.redirect_uris.map((redirectUri, index) => {
										if (index === 0) {
											return redirectUri;
										} else {
											return `, ${redirectUri}`;
										}
									})}
							</ListItemBasic>
							<ListItemBasic
								title='Auth Server URI'
								ContentRight={() => (
									<Button
										style='link'
										onClick={() => copy(currentSmartApp.oauth_server_uri)}
										animate='copy'
									>
										Copy
									</Button>
								)}
							>
								{currentSmartApp.oauth_server_uri}
							</ListItemBasic>
						</div>
					</Section>
					<Section
						title='SMART App Launchers'
						ContentRight={() => (
							<Button onClick={(event) => createModal(createSmartAppLauncherModal, event)}>
								Create New Launcher
							</Button>
						)}
					>
						{smartAppLaunchers.length >= 1 ? (
							<TableBody>
								<TableHeader>
									<TableColumn>Name</TableColumn>
									<TableColumn>Launch URL</TableColumn>
									<TableColumn></TableColumn>
									<TableColumn></TableColumn>
								</TableHeader>
								{smartAppLaunchers.map((smartAppLauncher, index) => (
									<TableRow key={index}>
										<TableColumn>{smartAppLauncher.name}</TableColumn>
										<TableColumn>{smartAppLauncher.launch_url}</TableColumn>
										<TableColumn>
											<a
												target='_BLANK'
												href={`${smartAppLauncher.launch_url}?launch=${smartAppLauncher.launch_param}&iss=${currentSmartApp.oauth_server_uri}`}
											>
												Launch Smart App
											</a>
										</TableColumn>
										<TableColumnDelete
											onClickModal={deleteSmartAppLauncherModal(smartAppLauncher.id)}
											actionAriaLabel='Delete Smart App Launcher'
										/>
									</TableRow>
								))}
							</TableBody>
						) : (
							<div className={css.emptyMessage}>
								There are currently No SMART App Launchers. Creating a SMART App Launcher will allow you
								to launch a SMART App from Interoperability Land&trade;.
							</div>
						)}
					</Section>
				</InnerContainer>
			</AppFrame>
		);
	}
}

const mapStateToProps = (state: ApplicationState): ISmartAppViewReduxProps => {
	const { currentPitId, currentRofId, currentSmartAppId } = state.rof;

	const currentSmartApp =
		state.rof.allRofPitsSmartApps[currentRofId] && currentRofId && currentPitId && currentSmartAppId
			? state.rof.allRofPitsSmartApps[currentRofId][currentPitId][currentSmartAppId]
			: null;

	const currentRof = state.rof.allRof[currentRofId];
	const currentPit = state.rof.allRofPits[currentRofId]
		? state.rof.allRofPits[currentRofId][currentPitId]
		: null;
	const currentOrganization = currentRof
		? state.organization.organizations[currentRof.organization_id]
		: null;

	const currentSmartAppUpdateState = state.rof.allRofPitsSmartAppsUpdateState[currentRofId]
		? state.rof.allRofPitsSmartAppsUpdateState[currentRofId][currentPitId]
		: EUpdateState.Empty;

	const smartAppLaunchers = (state.rof.allRofSmartAppLaunchers[currentRofId] || [])[currentPitId]
		? convertIndexedObjectToArray(
			state.rof.allRofSmartAppLaunchers[currentRofId][currentPitId][currentSmartAppId]
		)
		: [];

	const smartAppLaunchersLoadingState = state.rof.allRofSmartAppLaunchersLoadingState[currentRofId]
		? state.rof.allRofSmartAppLaunchersLoadingState[currentRofId][currentPitId][currentSmartAppId]
		: EUpdateState.Empty;

	return {
		currentSmartApp,
		currentSmartAppUpdateState,
		currentRofId,
		currentRof,
		currentPitId,
		currentPit,
		currentOrganization,
		currentSmartAppId,
		smartAppLaunchers,
		smartAppLaunchersLoadingState,
	};
};

export const SmartAppView = connect(mapStateToProps, smartAppViewActionCreators)(SmartAppViewBase);
