import * as React from 'react';
import { RadioGroup, Radio } from 'components/FormElements';
import { Field } from 'formik';
import css from './segmented-controller.module.scss';
import classes from 'classnames';

interface IRadio {
	label: string;
	value: string;
}

interface ISegmentedControllerProps {
	options: IRadio[];
	values: string;
	name: string;
	onChange?: any;
}

export const SegmentedController: React.FC<
	ISegmentedControllerProps
> = props => {
	const { options, values, name, onChange } = props;

	return (
		<RadioGroup
			value={values}
			id='rofAutomatic'
			className={classes('radioGroup', css.segmentedControllerGroup)}
		>
			{options.map((option, index) => {
				const fieldSetting = onChange ? (
					<Field
						component={Radio}
						name={name}
						id={option.value}
						label={option.label}
						className={css.segmentedController}
						onChange={e => {
							onChange(e);
						}}
					/>
				) : (
					<Field
						component={Radio}
						name={name}
						id={option.value}
						label={option.label}
						className={css.segmentedController}
					/>
				);
				return (
					<React.Fragment key={index}>{fieldSetting}</React.Fragment>
				);
			})}
		</RadioGroup>
	);
};
