import { url } from 'settings';

var awsmobile = {};

awsmobile = {
	aws_project_region: `${process.env.REACT_APP_AWS_REGION}`,
	Auth: {
		// REQUIRED - Amazon Cognito Region
		region: `${process.env.REACT_APP_AWS_REGION}`,

		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: `${process.env.REACT_APP_AWS_USER_POOL_ID}`,

		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: `${process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID}`,

		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		mandatorySignIn: true,
	},
	Storage: {
		bucket: process.env.REACT_APP_BUCKET_NAME,
		region: process.env.REACT_APP_AWS_REGION,
		identityPoolId: process.env.REACT_APP_identityPoolId,
	},
};

if (!(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')) {
	let parsedUrl = new URL(url);
	let elem = parsedUrl.host.split('.').reverse();
	let domain = `.${elem[1]}.${elem[0]}`;

	awsmobile['Auth']['cookieStorage'] = {
		domain: domain,
		path: '/',
		expires: 1,
		secure: true,
	};
}

export default awsmobile;
