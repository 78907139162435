import * as React from 'react';
import css from './table-header.module.scss';
import classes from 'classnames';

interface ITableHeader {
	className?: string;
}

export const TableHeader: React.FC<ITableHeader> = props => {
	const { children, className } = props;
	return (
		<div className={classes(css.tableHeader, className)}>{children}</div>
	);
};
