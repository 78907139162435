import * as React from 'react';
import css from './split.module.scss';
import classes from 'classnames';
import cssPanel from '../../panel.module.scss';

interface IPanelContentSplitProps {
	title: string;
	ContentLeft: React.FC;
	ContentRight: React.FC;
}

const ContentLeft: React.FC = () => (
	<>
		<h2>Delete Sandbox</h2>
		<p>This will permanently delete your data.</p>
	</>
);

export const PanelContentSplit: React.FC<IPanelContentSplitProps> = props => {
	const { ContentLeft, ContentRight, title } = props;
	return (
		<div className={css.split}>
			<div>
				<h2 className={classes('h4', cssPanel.title)}>{title}</h2>
				<ContentLeft />
			</div>
			<div>
				<ContentRight />
			</div>
		</div>
	);
};
