import * as React from 'react';
import { authActionCreators } from 'stores/actions/authActions';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';

import { PasswordReset } from 'components/Auth/PasswordReset';

const passwordResetActionCreators = {
	...authActionCreators
};

interface IPasswordResetProps {
	passwordResetPromise: any;
}

class CustomAuthenticator extends React.PureComponent<
	IPasswordResetProps & ResolveThunks<typeof passwordResetActionCreators>
> {
	render() {
		return <PasswordReset />;
	}
}

const mapStateToProps = (state: ApplicationState): IPasswordResetProps => ({
	passwordResetPromise: state.auth.passwordResetPromise
});

export const PasswordResetView = connect(
	mapStateToProps,
	passwordResetActionCreators
)(CustomAuthenticator);
