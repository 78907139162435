import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'stores';
import { EUpdateState } from 'stores/shared/ENums';
import { GridContainer, GridItem } from 'components/Grid';
import { RouteComponentProps } from 'react-router';
import css from './rof-overview-tab.module.scss';
import { IFhirPit } from 'common/interfaces/IFhirPit';
import { convertIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';
import { Loading } from 'components/Loading';
import { Badge } from 'components/Badge';
import * as R from 'ramda';

interface IRofOverviewTabReduxProps {
	currentRofId: any;
	currentRof: any;
	currentPits: IFhirPit[];
	currentRofLoadingState: EUpdateState;
}

class RofOverviewTabBase extends React.PureComponent<RouteComponentProps<{ id?: string }> & IRofOverviewTabReduxProps> {
	isLoading = (): boolean => {
		return !this.props.currentPits || this.props.currentRofLoadingState !== EUpdateState.Loaded;
	};

	sortPits = R.sortBy(
		R.compose((code: String = '') => {
			const [type, index = ''] = code.match(/[a-zA-Z]+|[0-9]+/g);
			switch (type) {
				case 'ehr':
					return `0${index}`;
				case 'pharm':
					return `1${index}`;
				case 'payer':
					return `2${index}`;
				case 'hin':
					return `3${index}`;
				default:
					return `9${index}`;
			}
		}, R.prop('code'))
	)

	render() {
		const { currentRofId, currentPits } = this.props;

		if (this.isLoading()) {
			return <Loading />;
		}

		return (
			<GridContainer>
				{this.sortPits(currentPits).map((fhirPit, index) => (
					<GridItem className={css.gridItem} key={index} location={`/sandbox/${currentRofId}/pit/${fhirPit.id}`}>
						<div className={css.itemTop}>
							<h5>{fhirPit.name}</h5>
							<div className={css.badge}>HL7&reg; FHIR&reg; Pilot Interopability Testbed</div>
							<Badge className={css.code}>{fhirPit.code}</Badge>
						</div>
					</GridItem>
				))}
			</GridContainer>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IRofOverviewTabReduxProps => {
	const currentPits = convertIndexedObjectToArray(state.rof.allRofPits[state.rof.currentRofId]);
	const currentRofId = state.rof.currentRofId;
	return {
		currentRofId,
		currentRof: state.rof.allRof && currentRofId ? state.rof.allRof[currentRofId] : null,
		currentPits,
		currentRofLoadingState: state.rof.allRofLoadingState[currentRofId]
	};
};

export const OverviewTab = connect(mapStateToProps)(RofOverviewTabBase);
