import * as React from 'react';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { IModalForm, IOrganization } from 'common/interfaces';
import { connect, useDispatch } from 'react-redux';
import { notificationActionCreators } from 'stores/actions/notificationActions';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { FormLabel } from 'components/FormElements';
import { DatePickerField } from 'components/FormElements/DatePickerField'
import { RequiredIndicator } from 'components/FormElements/RequiredIndicator';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import css from './form-notification.module.scss';
import DateFnsUtils from '@date-io/date-fns';
import classes from 'classnames';
import { convertToISODateString } from 'common/utils/convertToISODateString';
import { ApplicationState } from 'stores';
import { convertIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';


let initialValues = {
    type: '',
    message: '',
    expiration_date: null,
    organization_id: null,
};

let today = new Date(Date.now());
today.setHours(0, 0, 0, 0);


interface IFormCreateNotificationReduxProps {
    organizations: IOrganization[];
}

export const FormCreateNotificationBase: React.FunctionComponent<IModalForm & IFormCreateNotificationReduxProps>
    = ({
        bindSubmitForm,
        formAction,
        organizations,
    }) => {
        const dispatch = useDispatch();

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    if (formAction) {
                        values.expiration_date = convertToISODateString(values.expiration_date, true);
                        formAction(trimAllStringProperties(values));
                    }
                    setSubmitting(false);
                }}
                validationSchema={yup.object().shape({
                    type: yup
                        .string()
                        .required('Required'),
                    message: yup
                        .string()
                        .required('Required'),
                    expiration_date: yup
                        .date().typeError('Expiration Date must be a valid date (dd/MM/yyyy)').nullable().default(null)
                        .min(today, "Date cannot be in the past")
                })}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        submitForm
                    } = props;
                    if (bindSubmitForm) {
                        bindSubmitForm(submitForm);
                    }

                    return (
                        <form onSubmit={handleSubmit}>
                            <FormLabel htmlFor='type'>Notification Type</FormLabel>
                            <select
                                id='type'
                                name='type'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.type}
                            >
                                <option value='' disabled>
                                    Please Choose Notification Type
                                </option>

                                <option value='info' selected>Info</option>
                                <option value='error'>Error</option>
                                <option value='warning'>Warning</option>
                                <option value='success'>Success</option>
                            </select>
                            <label htmlFor='message'>
                                Message<RequiredIndicator />
                            </label>
                            <input
                                id='message'
                                placeholder='Enter Message'
                                type='text'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.message}
                                className={
                                    errors.message && touched.message
                                        ? 'text-input error'
                                        : 'text-input'
                                }
                            />
                            {errors.message && touched.message && (
                                <div className='input-feedback'>
                                    {errors.message}
                                </div>
                            )}
                            <input type='submit' />
                            <FormLabel htmlFor='type'>Expiration Date</FormLabel>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Field
                                    name='expiration_date'
                                    value={values.expiration_date}
                                    component={DatePickerField}
                                    className={
                                        classes(css.datePicker, 'mtl-picker-input')
                                    }
                                    onBlur={handleBlur}
                                />
                            </MuiPickersUtilsProvider>
                            {errors.expiration_date && touched.expiration_date && (
                                <div className='input-feedback'>{errors.expiration_date}</div>
                            )}
                            <FormLabel htmlFor='organization_id'>Organization</FormLabel>
                            <select
                                id='organization_id'
                                value={values.organization_id}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={classes({
                                    default: !values.organization_id,
                                })}
                            >
                                <option disabled>
                                    Select an Organization
                                </option>
                                <option value="">
                                    All Organizations
                                </option>
                                {organizations.map((org) => (
                                    <option value={org.id} key={`org_option_${org.id}`}>
                                        {org.name}
                                    </option>
                                ))}
                            </select>


                        </form>
                    );
                }}
            </Formik>
        );
    };

const mapStateToProps = (state: ApplicationState): IFormCreateNotificationReduxProps => {
    const { organizations } = state.organization;

    return {
        organizations: convertIndexedObjectToArray(organizations)
    };
};

export const FormCreateNotification = connect(
    mapStateToProps,
    notificationActionCreators
)(FormCreateNotificationBase);