import * as React from 'react';
import css from './mui-table.module.scss'
import classes from 'classnames';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import {
	Card,
	Checkbox, Grid,
	IconButton
} from '@material-ui/core';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { useState } from 'react';
const sortIcon = <ArrowDownward />;


interface IMuiTableHeaderProps {
	title?: any;
	onSearch:  any;
}

export const MuiTableHeader: React.FC<IMuiTableHeaderProps> = props => {
	const {title, onSearch } = props;

	return (
		<Grid container>
			<Grid item xs={12} md={6}>
				{title}
			</Grid>
			<Grid item xs={12} md={6}>
				<input
					id={'search'}
					placeholder={'Search'}
					type={'text'}
					onChange={onSearch}
				/>
			</Grid>
		</Grid>
	);
};
