import * as React from 'react';
import { AppFrame, InnerContainer, PageTitleArea } from 'components/Page';
import { ApplicationState } from 'stores';
import { EUpdateState } from 'stores/shared/ENums';
import { PageTabBar } from 'components/Page/PageTabBar';
import { connect, ResolveThunks } from 'react-redux';
import { OrganizationListingTab } from './OrganizationListingTab/OrganizationListingTab';
import { OrganizationActionCreators } from 'stores/actions/personaBuilder/organizationActions';
// import { NavLink, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

interface IOrganizationDashboardViewReduxProps {
	currentUserType: string;
	applyPersonaUpdateState: EUpdateState;
}

const OrganizationDashboardViewBase: React.FC<IOrganizationDashboardViewReduxProps &
	ResolveThunks<typeof OrganizationActionCreators>> = props => {
		React.useEffect(()=>{
			props.populateDropdowns();
			props.getAllOrganizations();
		}, []);
		return (
			<AppFrame>
				<InnerContainer>
					<PageTitleArea
						breadcrumbItems={[
							{
								label: 'Creation Dashboard',
								location: '/persona-builder'
							},
							{
								label: 'All Organizations',
								location: `/persona-builder/organization-listing`
							}
						]}
					>
					</PageTitleArea>
					<PageTabBar navigationItems={[
						{
							label: 'Organizations',
							location: '/persona-builder/organization-listing',
							route: '/persona-builder/organization-listing',
							component: OrganizationListingTab
						}
					]}/>
				</InnerContainer>
			</AppFrame>
		);
	}
	
	const mapStateToProps = (state: ApplicationState): IOrganizationDashboardViewReduxProps => {
		return {
			currentUserType: ((state.auth || {}).user || {}).attributes['custom:user_type'],
			applyPersonaUpdateState: state.persona.applyPersonaUpdateState
		};
	};
	
	export const OrganizationListingView = connect(
		mapStateToProps,
		OrganizationActionCreators
	)(OrganizationDashboardViewBase);
	