import { Children } from 'react';


const hasChildren = child =>
	Boolean(child && child.props && child.props.children);

export const getTextFromReactChild = children => { 
	return Children.toArray(children)
		.reduce(
			(flattened: Array<string>, child: any) => [
				...flattened,
				hasChildren(child)
					? getTextFromReactChild(child.props.children)
					: child
			],
			[]
		)
};
