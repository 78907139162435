import * as React from 'react';

import { rofActionCreators } from 'stores/actions/rofActions';
import { modalActionCreators } from 'stores/actions/modalActions';

import { connect, ResolveThunks } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import css from './form-create-rof.module.scss';
import { url } from 'settings';
import { debouncedValidateCodeIsUnique } from 'common/utils/validateCodeIsUnique';
import { IIsCodeUnique } from 'common/interfaces/IIsCodeUnique';
import { ICognitoUser } from 'common/interfaces';
import { RequiredIndicator } from 'components/FormElements/RequiredIndicator';
import { IModalForm } from 'common/interfaces';
import { IRof } from 'common/interfaces/IRof';
import { IRofTemplate } from 'common/interfaces/IRofTemplate';
import { ApplicationState } from 'stores';
import classes from 'classnames';
import { FormLabel } from 'components/FormElements';
import { clone, sort } from 'ramda';
import { EUpdateState } from 'stores/shared/ENums';

const formCreateRofActionCreators = {
	...rofActionCreators,
	...modalActionCreators,
};

interface IFormCreatRofProps {
	organizationId: number;
}

interface IFormCreateRofReduxProps {
	user: ICognitoUser;
	templates: IRofTemplate[];
}

const infoText = {
	cqfRuler:
		'The Clinical Quality Framework Ruler is an implementation of the FHIR Clinical Reasoning Module. If you choose this option, your sandbox will have resources to query for use cases applicable to the HL7 Data Exchange for Quality Measures (DEQM) standard.',
	blueButton:
		'The Centers for Medicare and Medicaid Services Blue Button 2.0 API delivers data for Medicare Part A, B, and D beneficiaries. If you choose this option, your sandbox will have resources that use the HL7 FHIR standard for beneficiary data and the OAuth 2.0 standard for beneficiary authorization.',
	priorAuth:
		'Prior authorization refers to requests and responses for medications that need insurance approval to be covered. If you choose this option, your sandbox will have data resources that align with Da Vinci FHIR Prior Authorization Support standards.',
};

class FormCreateRofBase extends React.PureComponent<
	ResolveThunks<typeof formCreateRofActionCreators> &
	IModalForm &
	IFormCreatRofProps &
	IFormCreateRofReduxProps,
	IIsCodeUnique,
	ICognitoUser
> {
	constructor(props) {
		super(props);
		this.state = {
			hasCodeInitiallyLoaded: false,
			codeLoadingState: EUpdateState.Empty,
			isCodeUnique: null,
		};
	}

	UNSAFE_componentWillMount() {
		this.props.getRofTemplates();
	}

	render() {
		const {
			createRof,
			bindSubmitForm,
			organizationId,
			templates,
			setErrorMessage,
			setFormLoadingAttribute,
		} = this.props;

		return (
			<div>
				<Formik
					initialValues={{
						name: '',
						code: '',
						rof_template_id: '',
						pit_compute_size: 'medium',
					}}
					onSubmit={(values: IRof, { setSubmitting }) => {
						values['organization_id'] = organizationId;
						const valuesToSubmit = clone(values);
						valuesToSubmit['code'] = valuesToSubmit.code.toLowerCase();
						setFormLoadingAttribute(true);
						createRof(valuesToSubmit, setErrorMessage, setFormLoadingAttribute);
						setSubmitting(false);
					}}
					validationSchema={yup.object().shape({
						name: yup.string().max(50).required('Required'),
						code: yup
							.string()
							.required('Required')
							.test('isCodeUnique', 'This unique identifier has already been used', () => {
								if (this.state.hasCodeInitiallyLoaded === true) {
									return this.state.isCodeUnique;
								} else {
									return true;
								}
							})
							.test(
								'isCodeUnique',
								'Unique Identifier must contain letters and numbers only.',
								(value) => {
									const regex = RegExp('^[a-zA-Z0-9_]*$');
									const isValid = regex.test(value);
									return isValid;
								}
							)
							.max(5),
						rof_template_id: yup.string().required('Required'),
						pit_compute_size: yup.string().oneOf(['small', 'medium', 'large', 'xlarge']),
					})}
				>
					{(formikProps) => {
						const {
							values,
							touched,
							errors,
							dirty,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit,
							handleReset,
							submitForm,
							validateForm,
						} = formikProps;
						if (bindSubmitForm) {
							bindSubmitForm(submitForm);
						}
						return (
							<form onSubmit={handleSubmit}>
								<FormLabel htmlFor='name' subLabel='50 character limit'>
									Name Your Sandbox
									<RequiredIndicator />
								</FormLabel>
								<input
									type='text'
									name='name'
									id='name'
									placeholder='Enter Name'
									value={values.name}
									onChange={handleChange}
									onBlur={handleBlur}
									className={errors.name && touched.name ? 'text-input error' : 'text-input'}
									maxLength={50}
								/>
								{errors.name && touched.name && <div className='input-feedback'>{errors.name}</div>}
								<FormLabel
									htmlFor='code'
									subLabel='5 character limit, letters and numbers'
									lowerLabel='The unique identifier will be used to generate URLs for your sandbox.'
								>
									Unique Identifier
									<RequiredIndicator />
								</FormLabel>
								<input
									type='text'
									name='code'
									id='code'
									placeholder='Enter Unique Identifier'
									value={values.code}
									onChange={(e) => {
										debouncedValidateCodeIsUnique(
											`${url}rof/rof_code_used?code=${e.target.value.toLowerCase()}&organization_id=${organizationId}`,
											this.setState.bind(this),
											this.forceUpdate.bind(this),
											validateForm,
											setErrorMessage
										);
										handleChange(e);
									}}
									onBlur={handleBlur}
									className={
										errors.code && (touched.code || this.state.isCodeUnique != null)
											? 'text-input error'
											: 'text-input'
									}
									maxLength={5}
								/>
								{errors.code && (touched.code || this.state.isCodeUnique != null) && (
									<div className='input-feedback'>{errors.code}</div>
								)}

								<FormLabel htmlFor='rof_template_id'>
									Sandbox Type
									<RequiredIndicator />
								</FormLabel>
								<select
									id='rof_template_id'
									onChange={handleChange}
									onBlur={handleBlur}
									value={`${values.rof_template_id}`}
									className={classes({
										default: !values.rof_template_id,
									})}
								>
									<option value='' disabled>
										Please Choose Sandbox Type
									</option>

									{templates.map((template, index) => (
										<option value={template.id} key={index}>
											{template.name}
										</option>
									))}
								</select>
								{errors.rof_template_id && touched.rof_template_id && (
									<div className='input-feedback'>{errors.rof_template_id}</div>
								)}
								{this.props.user.attributes['custom:user_type'] === 'mihin-admin' && (
									<>
										<FormLabel htmlFor='pit_compute_size_select'>Compute Size</FormLabel>
										<select
											id='pit_compute_size_select'
											name='pit_compute_size'
											onChange={handleChange}
											onBlur={handleBlur}
											value={`${values.pit_compute_size}`}
											className={classes({
												default: !values.pit_compute_size,
											})}
										>
											<option value='' disabled>
												Please Choose Compute Size
											</option>

											{/* Small configuration is currently insufficient for multi-pit sandboxes */}
											{/* <option value='small'>
										Small
									<t/option> */}

											<option value='medium' selected>
												Medium
											</option>
											<option value='large'>Large</option>
											<option value='xlarge'>Extra Large</option>
										</select>
										<div className={css.checkboxContainer}>
											<FormLabel htmlFor='cqf_ruler_enabled' infoText={`${infoText.cqfRuler}`}>
												CQF Ruler Enabled
											</FormLabel>
											<input
												id='cqf_ruler_enabled'
												name='cqf_ruler_enabled'
												type='checkbox'
												onChange={handleChange}
												value={`${values.cqf_ruler_enabled}`}
											/>
										</div>
										<div className={css.checkboxContainer}>
											<FormLabel htmlFor='cms_bb_enabled' infoText={`${infoText.blueButton}`}>
												CMS Blue Button 2.0 Enabled
											</FormLabel>
											<input
												id='cms_bb_enabled'
												name='cms_bb_enabled'
												type='checkbox'
												onChange={handleChange}
												value={`${values.cms_bb_enabled}`}
											/>
										</div>
										<div className={css.checkboxContainer}>
											<FormLabel htmlFor='prior_auth_enabled' infoText={`${infoText.priorAuth}`}>
												Prior Auth Enabled
											</FormLabel>
											<input
												id='prior_auth_enabled'
												name='prior_auth_enabled'
												type='checkbox'
												onChange={handleChange}
												value={`${values.prior_auth_enabled}`}
											/>
										</div>
									</>
								)}
								<input type='submit' />
							</form>
						);
					}}
				</Formik>
			</div>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IFormCreateRofReduxProps => {
	const templates = state.rof.templates;
	const diff = function (a, b) {
		return a.sort_index - b.sort_index;
	};

	const sortedTemplates = sort(diff, templates);

	const filteredTemplates = sortedTemplates.filter(
		(template) => template.is_available && template.is_populated
	);

	console.log('filteredTemplates', filteredTemplates);

	console.log('sortedTemplates', sortedTemplates);

	return {
		user: state.auth.user,
		templates:
			state.auth.user.attributes['custom:user_type'] === 'mihin-admin'
				? sortedTemplates
				: filteredTemplates,
	};
};

export const FormCreateRof = connect(mapStateToProps, formCreateRofActionCreators)(FormCreateRofBase);
