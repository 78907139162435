import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect, ResolveThunks } from 'react-redux';
import { differenceInCalendarYears } from 'date-fns';

import { AppFrame, InnerContainer, PageTitleArea } from 'components/Page';
import { Panel } from 'components/Panel';
import css from './api.module.scss';
import classes from 'classnames';
import { ApplicationState } from 'stores';
import { IApiDoc, ICodeSample } from 'common/interfaces';
import { apiLibraryActionCreators } from 'stores/actions/apiLibraryActions';
import { Loading } from 'components/Loading';
import { Link } from 'react-router-dom';
import { GridContainer, GridItem } from 'components/Grid';

const actionCreators = {
	...apiLibraryActionCreators,
};

interface IApiRouteProps {
	id?: string;
}

interface IApiViewReduxProps {
	api: IApiDoc;
	code_samples: ICodeSample[];
}

class ApiViewBase extends React.PureComponent<
	RouteComponentProps<IApiRouteProps> & ResolveThunks<typeof actionCreators> & IApiViewReduxProps
	> {
	UNSAFE_componentWillMount() {
		const apiId = parseInt(this.props.match.params.id);
		this.props.getApiDoc(apiId);
	}

	isLoading = () => {
		const { api } = this.props;
		const screenId = parseInt(this.props.match.params.id);
		return !(api && api.id === screenId);
	};


	render() {
		const { api, code_samples } = this.props;

		if (this.isLoading()) {
			return (
				<AppFrame>
					<Loading />
				</AppFrame>
			);
		}

		return (
			<AppFrame>
				<InnerContainer>
					<PageTitleArea
						backLabel="Back to all API's"
						backLocation='/api'
					>
						<span
							dangerouslySetInnerHTML={{
								__html: api.name
							}}
						/>
					</PageTitleArea>
					<Panel type='callOut' className={css.descriptionCallOut}>
						<div
							dangerouslySetInnerHTML={{
								__html: api.descr
							}}
						/>
					</Panel>
					<div className={css.content}>
						<div className={css.contentMain}>
							<div className={css.linkArea}>
								<a className={css.linkButton} href={api.documentation_url} target="_blank" rel="noopener noreferrer">
									<span>View the Documentation</span>
								</a>
								<a className={css.linkButton} href={api.signup_url} target="_blank" rel="noopener noreferrer">
									<span>Sign Up for the Developer Sandbox</span>
								</a>
							</div>
							<Panel className={css.overview}>
								<h2 className={classes('h4', css.title)}>Overview</h2>
								<div
									dangerouslySetInnerHTML={{
										__html: api.overview
									}}
								/>
							</Panel>
							{code_samples.length ? <h2 className={classes('h4', css.title)}>Code Samples</h2> : null}
							<GridContainer>
								{code_samples.map((code_sample, index) => (
									<GridItem className={css.gridItem} key={index} location={`/code-sample/${code_sample.id}`}>
										<div className={css.itemTop}>
											<h5>{code_sample.name}</h5>
											<div
												dangerouslySetInnerHTML={{
													__html: code_sample.descr
												}}
											/>
										</div>
									</GridItem>
								))}
							</GridContainer>
						</div>
					</div>
				</InnerContainer>
			</AppFrame>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IApiViewReduxProps => ({
	api: state.apiLibrary.currentApi ? state.apiLibrary.allApis[state.apiLibrary.currentApi] : null,
	code_samples: state.apiLibrary.currentApi ? state.apiLibrary.allApiCodeSamples[state.apiLibrary.currentApi] : null
});

export const ApiView = connect(
	mapStateToProps,
	actionCreators
)(ApiViewBase);
