import { IModal } from 'common/interfaces';
import { FormDeleteCodeSample } from 'components/Forms/FormDeleteCodeSample/FormDeleteCodeSample';
import { codeSampleActionCreators } from 'stores/actions/codeSampleActions';
import { ICodeSample } from 'common/interfaces/ICodeSample';

export const deleteCodeSampleModal = (codeSample: ICodeSample, formAction): IModal => ({
	title: 'Delete Code Sample',
	content: FormDeleteCodeSample,
	primaryButtonLabel: 'Delete Code Sample',
	formAction: () => formAction(codeSample.id),
});
