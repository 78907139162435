import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect, ResolveThunks } from 'react-redux';
import { differenceInCalendarYears } from 'date-fns';

import { AppFrame, InnerContainer, PageTitleArea } from 'components/Page';
import { Panel } from 'components/Panel';
import css from './code-sample.module.scss';
import classes from 'classnames';
import { ApplicationState } from 'stores';
import { IApiDoc, ICodeSample } from 'common/interfaces';
import { codeSampleActionCreators } from 'stores/actions/codeSampleActions';
import { Loading } from 'components/Loading';
import { Link } from 'react-router-dom';
import { GridContainer, GridItem } from 'components/Grid';

const actionCreators = {
	...codeSampleActionCreators,
};

interface ICodeSampleRouteProps {
	id?: string;
}

interface ICodeSampleViewReduxProps {
	codeSample: ICodeSample;
	apis: IApiDoc[];
}

class CodeSampleViewBase extends React.PureComponent<
	RouteComponentProps<ICodeSampleRouteProps> & ResolveThunks<typeof actionCreators> & ICodeSampleViewReduxProps
	> {
	UNSAFE_componentWillMount() {
		const codeSampleId = parseInt(this.props.match.params.id);
		this.props.getCodeSample(codeSampleId);
	}

	isLoading = () => {
		const { codeSample } = this.props;
		const screenId = parseInt(this.props.match.params.id);
		return !(codeSample && codeSample.id === screenId);
	};


	render() {
		const { codeSample, apis } = this.props;
		if (this.isLoading()) {
			return (
				<AppFrame>
					<Loading />
				</AppFrame>
			);
		}

		return (
			<AppFrame>
				<InnerContainer>
					<PageTitleArea
						backLabel="Back to all Code Samples"
						backLocation='/code-sample'
					>
						<span
							dangerouslySetInnerHTML={{
								__html: codeSample.name
							}}
						/>
					</PageTitleArea>
					<Panel type='callOut' className={css.descriptionCallOut}>
						<div
							dangerouslySetInnerHTML={{
								__html: codeSample.descr
							}}
						/>
					</Panel>
					<div className={css.content}>
						<div className={css.contentMain}>
							<div className={css.linkArea}>
								<a className={css.linkButton} href={codeSample.repo_url} target="_blank">
									<span>View the Documentation and Code</span>
								</a>
								<a className={`${css.linkButton} ${css.download}`} href={codeSample.download_url} target="_blank">
									<span>Download the Code</span>
								</a>
							</div>
							<Panel className={css.overview}>
								<h2 className={classes('h4', css.title)}>Overview</h2>
								<div
									dangerouslySetInnerHTML={{
										__html: codeSample.overview
									}}
								/>
							</Panel>
							{apis.length ? <h2 className={classes('h4', css.title)}>API's Used by this Code Sample</h2> : null}
							<GridContainer>
								{Array.isArray(apis) && apis.map((api, index) => (
									<GridItem className={css.gridItem} key={index} location={`/api/${api.id}`}>
										<div className={css.itemTop}>
											<h5>{api.name}</h5>
											<div
												dangerouslySetInnerHTML={{
													__html: api.descr
												}}
											/>
										</div>
									</GridItem>
								))}
							</GridContainer>
						</div>
					</div>
				</InnerContainer>
			</AppFrame>
		);
	}
}

const mapStateToProps = (state: ApplicationState): ICodeSampleViewReduxProps => ({
	codeSample: state.codeSample.currentCodeSample ? state.codeSample.allCodeSamples[state.codeSample.currentCodeSample] : null,
	apis: state.codeSample.currentCodeSample ? state.codeSample.allCodeSampleApis[state.codeSample.currentCodeSample] : null
});

export const CodeSampleView = connect(
	mapStateToProps,
	actionCreators
)(CodeSampleViewBase);
