import * as React from 'react';
import css from './table-body.module.scss';
import classes from 'classnames';

interface ITableBodyProps {
	className?: string;
}

export const TableBody: React.FC<ITableBodyProps> = props => {
	const { children, className } = props;
	return (
		<div className={classes(css.tableContainer, className)}>
			<div className={css.table}>{children}</div>
		</div>
	);
};
