import * as React from 'react';
import { AppFrame, InnerContainer, PageTitleArea } from 'components/Page';
import { ApplicationState } from 'stores';
import { EUpdateState } from 'stores/shared/ENums';
import { connect, ResolveThunks } from 'react-redux';
import { MedicationListingTab } from './MedicationListingTab/MedicationListingTab';
import { MedicationActionCreators } from 'stores/actions/personaBuilder/medicationActions';

interface IMedicationViewReduxProps {
	applyPersonaUpdateState: EUpdateState;
}

const MedicationViewBase: React.FC<IMedicationViewReduxProps &
	ResolveThunks<typeof MedicationActionCreators>> = props => {
		React.useEffect(()=>{
			props.populateMedicationDropdowns();
			props.getAllMedications();
		}, []);
		return (
			<AppFrame>
				<InnerContainer>
					<PageTitleArea
						breadcrumbItems={[
							{
								label: 'Creation Dashboard',
								location: '/persona-builder'
							},
							{
								label: 'All Medications',
								location: `/persona-builder/medication-listing`
							}
						]}
					>
					</PageTitleArea>
					<MedicationListingTab />
				</InnerContainer>
			</AppFrame>
		);
	}
	
	const mapStateToProps = (state: ApplicationState): IMedicationViewReduxProps => {
		return {
			applyPersonaUpdateState: state.persona.applyPersonaUpdateState
		};
	};
	
	export const MedicationView = connect(
		mapStateToProps,
		MedicationActionCreators
	)(MedicationViewBase);
	