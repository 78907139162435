import * as React from 'react';
import { authActionCreators } from 'stores/actions/authActions';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';

import { NewPasswordRequired } from 'components/Auth/NewPasswordRequired';

const newPasswordRequiredActionCreators = {
	...authActionCreators
};

interface INewPasswordRequiredProps {
	passwordResetPromise: any;
}

class CustomAuthenticator extends React.PureComponent<
	INewPasswordRequiredProps &
		ResolveThunks<typeof newPasswordRequiredActionCreators>
> {
	render() {
		return <NewPasswordRequired />;
	}
}

const mapStateToProps = (
	state: ApplicationState
): INewPasswordRequiredProps => ({
	passwordResetPromise: state.auth.passwordResetPromise
});

export const NewPasswordRequiredView = connect(
	mapStateToProps,
	newPasswordRequiredActionCreators
)(CustomAuthenticator);
