import * as React from 'react';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { Card, Grid } from '@material-ui/core';
import { Button } from 'components/Button';
import DataTable from 'react-data-table-component';
import { MuiButton } from 'components/MaterialUI/index';
import { sharedHistory } from 'sharedStore';
import { IMedication } from 'common/interfaces/personaBuilder/IMedication';
import { MedicationActionCreators } from 'stores/actions/personaBuilder/medicationActions';
import css from './medication-listing-tab.module.scss';
import { ArrowDownward, AddCircle, Edit } from '@material-ui/icons';
import { objectArrayToMap } from 'common/utils/objectArrayToMap';
const sortIcon = <ArrowDownward />;

interface IMedicationListingTabReduxProps {
	medications: IMedication[];
	medication_dropdowns: [];
}

const MedicationListingTabBase: React.FC<IMedicationListingTabReduxProps &
	ResolveThunks<typeof MedicationActionCreators>
	> = props => {
  const { medications, medication_dropdowns } = props;
  
	const medicationFormNames = objectArrayToMap(medication_dropdowns['medication_forms'], 'id', 'medication_form_display');
	const medicationsData = medications.map(medication => {
		return {
			...medication,
			medication_form_name: medicationFormNames[medication.medication_form_id]
		}
	});

	const initialData = medicationsData;
	const [filteredData, setFilteredData] = React.useState(null);

	const filterMedications = (param) => {
		if (param.length > 2) {
			let foundData = [];
			initialData.forEach(function(dataElement) {
				["proprietary_name", "non_proprietary_name", "strength", "ingredient_unit", "medication_form_name"].forEach(function(property) {
					if (dataElement[property].toLowerCase().indexOf(param.toLowerCase()) > -1) {
						foundData.push(dataElement);
					}
				});
			});
			setFilteredData(foundData);
		}
		else if (param.length === 0) {
			setFilteredData(initialData);
		}
	}

	const columns = [
		{
			name: '',
			cell: row => <Button style="link" onClick={() => sharedHistory.push(`/persona-builder/medication-listing/${row.id}`)}><Edit /></Button>,
			button: true
		},
		{
			name: 'Proprietary Name',
			sortable: true,
			selector: 'proprietary_name'
		},
		{
			name: 'Non-Proprietary Name',
			sortable: true,
			selector: 'non_proprietary_name'
		},
		{
			name: 'Strength',
			sortable: true,
			selector: 'strength'
		},
		{
			name: 'Strength Unit',
			sortable: true,
			selector: 'ingredient_unit'
		},
		{
			name: 'Form',
			sortable: true,
			selector: 'medication_form_name'
		}
	];

	return (
		<Grid container className={css.listContainer}>
			<Grid item xs={6}>
				<h4>
					Medication 
					<MuiButton className={css.addButton} onClick={() => sharedHistory.push(`/persona-builder/medication-listing/create`)}><AddCircle /></MuiButton>
				</h4>
			</Grid>
			<Grid item xs={6}>
				<input placeholder='Search Medication' type='text' onChange={(e) => filterMedications(e.target.value)} />
			</Grid>
			<Grid item xs={12}>
				<Card className={css.listCard}>
					<DataTable
						className={css.dataTable}
						columns={columns}
						data={filteredData ? filteredData : initialData}
						noHeader
						highlightOnHover
						defaultSortField="proprietary_name"
						sortIcon={sortIcon}
						pagination
						paginationRowsPerPageOptions={[10,25,50,100]}
					/>
				</Card>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state: ApplicationState): IMedicationListingTabReduxProps => {
	return {
		medications: state.personaBuilderMedication.medications,
		medication_dropdowns: state.personaBuilderMedication.medication_dropdowns
	};
};

export const MedicationListingTab = connect(
	mapStateToProps,
	MedicationActionCreators
)(MedicationListingTabBase);
