import { ICognitoUser } from 'common/interfaces';
import { ICanCreateCodeSample } from 'common/interfaces/ICanCreateCodeSample';

export const canCreateCodeSample = (user: ICognitoUser): ICanCreateCodeSample => {
	const userType = user.attributes['custom:user_type'];

	return {
		hasPrivilege: userType === 'mihin-admin'
	};
};
