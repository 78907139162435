import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import css from './modal.module.scss';
import { ApplicationState } from 'stores';
import { IModal } from 'common/interfaces';
import { modalActionCreators } from 'stores/actions/modalActions';
import { Modal } from './Modal';

interface IModalReduxProps {
	modals: IModal[];
}

class ModalControllerBase extends React.PureComponent<
	IModalReduxProps & ResolveThunks<typeof modalActionCreators>
> {
	render() {
		const { modals, dismissModal } = this.props;

		return (
			<>
				{modals &&
					modals.map((modal, index) => {
						return (
							<Modal
								modal={modal}
								key={index}
								dismissModal={dismissModal}
							/>
						);
					})}
			</>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IModalReduxProps => {
	return {
		modals: state.modal.modals
	};
};

export const ModalController = connect(
	mapStateToProps,
	modalActionCreators
)(ModalControllerBase);
