import { Reducer } from 'redux';

import ProviderActions from 'stores/actionInterfaces/personaBuilder/providerActionInterfaces';
import { PersonaBuilderProviderState } from 'stores/stateInterfaces/personaBuilder/providerStateInterface';
import { EUpdateState } from 'stores/shared/ENums';

const initialState: PersonaBuilderProviderState = {
	providerTableState: EUpdateState.Empty,
	providers: [],
	currentProvider: null,
	provider_dropdowns: {
		organizations: [],
		practitioner_roles: [],
		practice_codes: [],
		qualifications: [],
		genders: [],
		use_cases: []
	},
	providerTimeSlots: [],
	providerMetadata: null,
	providerUseCases: [],
	saveDisabled: true
};

export const PersonaBuilderProviderReducers: Reducer<PersonaBuilderProviderState> = (
	state: PersonaBuilderProviderState,
	action: ProviderActions
) => {

	switch (action.type) {
		case 'GET_PROVIDER_DROPDOWNS_RECEIVED': {
			return {
				...state,
				provider_dropdowns: action.provider_dropdowns
			}
		}
		case 'GET_ALL_PROVIDERS_REQUEST': {
			return {
				...state,
				providerTableState: EUpdateState.Loading
			}
		}
		case 'GET_ALL_PROVIDERS_RECEIVED': {
			return {
				...state,
				providers: action.providers
			}
		}
		case 'GET_PROVIDER_REQUEST': {
			return {
				...state,
				currentProvider: null
			}
		}
		case 'GET_PROVIDER_RECEIVED': {
			return {
				...state,
				currentProvider: action.provider
			}
		}
		case 'GET_PROVIDER_TIMESLOTS_RECEIVED': {
			return {
				...state,
				providerTimeSlots: action.providerTimeSlots
			}
		}
		case 'GET_PROVIDER_METADATA_RECEIVED': {
			return {
				...state,
				providerMetadata: action.providerMetadata
			}
		}
		case 'GET_PROVIDER_METADATA_USE_CASES_RECEIVED': {
			return {
				...state,
				providerUseCases: action.providerUseCases
			}
		}
		case 'SET_SAVE_DISABLED': {
			return {
				...state,
				saveDisabled: action.saveDisabled
			}
		}
	}
	return state || initialState;
};