import * as React from 'react';
import { AppFrame, InnerContainer, PageTitleArea } from 'components/Page';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { hl7Valuesets } from 'common/data/hl7Valuesets';
import { 
  FormAllergyReaction,
  FormAllergySubstance,
  FormBodySite,
  FormDiagnosis
} from 'components/Forms/PersonaBuilder/FormHL7Valuesets';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import css from '../persona-builder.module.scss';
import { Card, Modal, Backdrop, Fade } from '@material-ui/core';
import { AddCircle, Edit, ArrowDownward } from '@material-ui/icons';
import DataTable from 'react-data-table-component';
import {MuiTableHeader} from 'components/MaterialUI/index';
import { Button } from 'components/Button';
import { IHL7Valueset } from 'common/interfaces/personaBuilder/IHL7Valueset';
const sortIcon = <ArrowDownward />;

interface IHL7ValuesetRouteProps {
	type?: string
}

interface IHL7ValuesetViewReduxProps {
	currentUserType: string
	personaBuilderHL7Valuesets: {}
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

const HL7ValuesetViewBase : React.FC<IHL7ValuesetViewReduxProps & 
  RouteComponentProps<IHL7ValuesetRouteProps> &
  ResolveThunks<typeof PersonaBuilderActionCreators>> = props => {

  React.useEffect(() => {
    props.getAllHL7Valuesets();
  }, []);

  let hl7Valueset = hl7Valuesets[0];
  hl7Valuesets.forEach(function(valueset){
    if (valueset.type === props.match.params.type) {
      hl7Valueset = valueset;
    }
  });
  
  let valuesetSearchColumns = [];
  hl7Valueset.columns.forEach(function(column){ valuesetSearchColumns.push(column.selector); });

  const valuesetColumns = [
    {
      name: '',
      cell: (valueset) => <Button style="link" onClick={() => handleFormEdit(valueset)}><Edit /></Button>,
      button: true
    },
    ...hl7Valueset.columns
  ];

  const initialData = props.personaBuilderHL7Valuesets[hl7Valueset.type];
  const classes = useStyles(props);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [valueset, setValueset] = React.useState({});
  const [valuesetData, setValuesetData] = React.useState(null);

  const handleFormClose = () => {
    setModalOpen(false);
  };
  const handleFormAdd = () => {
    setValueset(null);
    setModalOpen(true);
  };
  const handleFormEdit = (valueset) => {
    setValueset(valueset);
    setModalOpen(true);
  };

  const saveValueset = (values) => {
    if (!!values.id){
      props.updateHL7Valueset(values, hl7Valueset.api_endpoint).then(()=>{
        props.getAllHL7Valuesets();
        handleFormClose();
      }).catch((e)=>{console.log(e)});
    }
    else {
      props.createHL7Valueset(values, hl7Valueset.api_endpoint).then(()=>{
        props.getAllHL7Valuesets();
        handleFormClose();
      }).catch((e)=>{console.log(e)});
    }
  };
  const deleteValueset = () => {
    const _valueset: IHL7Valueset = valueset;
    props.deleteHL7Valueset(_valueset.id, hl7Valueset.api_endpoint).then(()=>{
      props.getAllHL7Valuesets();
      handleFormClose();
    }).catch((e)=>{console.log(e)});
  };
  const filterValueset = (param) => {
    if (param.length > 2) {
      let foundData = [];
      initialData.forEach(function(dataElement) {
        valuesetSearchColumns.forEach(function(property) {
          if (dataElement[property].toLowerCase().indexOf(param.toLowerCase()) > -1) {
            foundData.push(dataElement);
          }
        });
      });
      setValuesetData(foundData);
    }
    else if (param.length === 0) {
      setValuesetData(initialData);
    }
  };

  const title =
    <div className={css.tableTitle}>
      <h4>
        {hl7Valueset.label}
        <Button style="link" onClick={handleFormAdd}><AddCircle fontSize={'large'}/></Button>
      </h4>
    </div>;

  const formProps = {
    defaultFormContent: valueset,
    cancelModalAction: handleFormClose, 
    formAction: saveValueset, 
    deleteAction: deleteValueset
  };

  let formComponent = {};
  switch (hl7Valueset.type) {
    case "allergy_reactions":
      formComponent = <FormAllergyReaction {...formProps} />;
      break;
    case "allergy_substances":
      formComponent = <FormAllergySubstance {...formProps} />;
      break;
    case "body_sites":
      formComponent = <FormBodySite {...formProps} />;
      break;
    case "diagnosis":
      formComponent = <FormDiagnosis {...formProps} />;
      break;
  }

  return (
    <AppFrame>
      <InnerContainer>
        <PageTitleArea
          breadcrumbItems={[
            {
              label: 'Creation Dashboard',
              location: '/persona-builder'
            },
            {
              label: 'HL7 Valuesets',
              location: `/persona-builder/valueset`
            }
          ]}
        >
        </PageTitleArea>
        <Card style={{ height: '100%', padding: 20 }}>
          <DataTable
            className={css.dataTable}
            columns={valuesetColumns}
            data={valuesetData ? valuesetData : initialData}
            noHeader
            highlightOnHover
            defaultSortField="display"
            sortIcon={sortIcon}
            pagination
            paginationRowsPerPageOptions={[10,25,50,100]}
            subHeader
            subHeaderComponent={(<MuiTableHeader title={title} onSearch={(e) => { filterValueset(e.target.value) }}/>)}
          />
        </Card>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={modalOpen}
          onClose={handleFormClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
        <Fade in={modalOpen}>
          <div className={classes.paper}>
            {formComponent}
          </div>
        </Fade>
      </Modal>
      </InnerContainer>
    </AppFrame>
  );
}

const mapStateToProps = (state: ApplicationState): IHL7ValuesetViewReduxProps => {
	return {
		currentUserType: ((state.auth || {}).user || {}).attributes['custom:user_type'],
		personaBuilderHL7Valuesets: state.personaBuilder.hl7Valuesets || {}
	};
};

export const HL7ValuesetView = connect(
	mapStateToProps,
	PersonaBuilderActionCreators
)(HL7ValuesetViewBase);
