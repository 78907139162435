import * as yup from 'yup';

interface IHL7DiagnosisValidationSchemaConfig {
}

interface HL7DiagnosisValidationSchema {
		diagnosis_name: string;
		diagnosis_icd10_code: string;
		diagnosis_snomed_code: string;
}

export const hl7DiagnosisValidationSchema = (
	hl7DiagnosisValidationSchemaConfig: IHL7DiagnosisValidationSchemaConfig = {}
) => {
	let schemaDefinition= {
		diagnosis_name: yup.string().required('Display is required'),
		diagnosis_icd10_code: yup.string().required('ICD10 Code is required'),
		diagnosis_snomed_code: yup.string().required('Code is required')
	};

	return yup.object().shape<any>(schemaDefinition) as any;
};
