import * as React from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { IconButton, TablePagination } from '@material-ui/core';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { useState } from 'react';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const sortIcon = <ArrowDownward />;

interface IMuiTablePaginationProps {
	rowsPerPage: number;
	rowCount: number;
	onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => void;
	onChangeRowsPerPage: (rowsPerPage: number) => void;
	currentPage: number;
}

export const MuiTablePagination: React.FC<IMuiTablePaginationProps> = (props) => {
	const { rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage, currentPage } = props;

	const getNumberOfPages = (count, rowsPerPage) => {
		return Math.ceil(count / rowsPerPage);
	};
	return (
		<TablePagination
			component='nav'
			count={rowCount}
			rowsPerPage={rowsPerPage}
			page={currentPage - 1}
			onChangePage={onChangePage}
			onChangeRowsPerPage={({ target }) => onChangeRowsPerPage(Number(target.value))}
			ActionsComponent={({ count, page, rowsPerPage, onChangePage }) => {
				return (
					<>
						<IconButton
							onClick={(e) => onChangePage(e, 1)}
							disabled={page === 0}
							aria-label='first page'
						>
							<FirstPageIcon />
						</IconButton>
						<IconButton
							onClick={(e) => onChangePage(e, page)}
							disabled={page === 0}
							aria-label='previous page'
						>
							<KeyboardArrowLeft />
						</IconButton>
						<IconButton
							onClick={(e) => onChangePage(e, page + 2)}
							disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
							aria-label='next page'
						>
							<KeyboardArrowRight />
						</IconButton>
						<IconButton
							onClick={(e) => onChangePage(e, getNumberOfPages(count, rowsPerPage))}
							disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
							aria-label='last page'
						>
							<LastPageIcon />
						</IconButton>
					</>
				);
			}}
		/>
	);
};
