//Type variant located at 'common/types/TUserTypes'

interface IUserList {
	'mihin-admin': string;
	'org-user': string;
	'org-admin': string;
}

export const UserType: IUserList = {
	'mihin-admin': 'Super Admin',
	'org-user': 'Organization User',
	'org-admin': 'Organization Admin'
};
