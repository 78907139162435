import * as React from 'react';

import { authActionCreators } from 'stores/actions/authActions';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';

import { ForgotPassword } from 'components/Auth/ForgotPassword';

const forgotPasswordActionCreators = {
	...authActionCreators
};

interface IForgotPasswordProps {
	user: any;
}

class CustomAuthenticator extends React.PureComponent<
	IForgotPasswordProps & ResolveThunks<typeof forgotPasswordActionCreators>
> {
	render() {
		return <ForgotPassword />;
	}
}

const mapStateToProps = (state: ApplicationState): IForgotPasswordProps => ({
	user: state.auth.user
});

export const ForgotPasswordView = connect(
	mapStateToProps,
	forgotPasswordActionCreators
)(CustomAuthenticator);
