import * as React from 'react';
import { AppFrame, InnerContainer } from 'components/Page';
import { MedicationDetailsTab } from './MedicationDetailsTab/MedicationDetailsTab';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import css from './medication-dashboard.module.scss';
import { NavLink, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { MedicationActionCreators } from 'stores/actions/personaBuilder/medicationActions';
import { IMedication } from 'common/interfaces/personaBuilder/IMedication';
import { MedicationTitleArea } from 'components/Page/MedicationTitleArea';

interface IMedicationDashboardRouteProps {
	id?: string
}

interface IMedicationDashboardViewReduxProps {
	currentMedication: IMedication;
}

const MedicationDashboardViewBase: React.FC<IMedicationDashboardViewReduxProps &
	RouteComponentProps<IMedicationDashboardRouteProps> &
	ResolveThunks<typeof MedicationActionCreators>> = props => {
	
	const isNew = props.match.params.id === 'create';
	React.useEffect(()=>{
		if (!isNew){
			props.getMedication(props.match.params.id);
		}
		props.populateMedicationDropdowns();
	}, []);

	const currentMedication = isNew ? {
		proprietary_name: 'Create New Medication',
		non_proprietary_name: '',
		substance_name: '',
		medication_ndc_code: '',
		medication_snomed_code: '',
		strength: '',
		ingredient_unit: '',
		medication_form_id: null
	} : props.currentMedication;

	const navigationItems = [
		{
			label: 'Medication',
			location: `/persona-builder/medication-listing/${props.match.params.id}`,
			route: `/persona-builder/medication-listing/${props.match.params.id}`,
			component: () => <MedicationDetailsTab {...props}/>,
			disabled: false
		},
	];
	return currentMedication && (
		<AppFrame>
			<InnerContainer>
				<Grid container>
					<Grid item xs={12} md={2} justify={'center'} className={css.imageContainer}>
						<img className={css.imageAvatar} width={'100%'}
									src={'https://ipog.edu.br/wp-content/themes/ipog/assets/img/graduacao/blank-avatar.png'}/>
					</Grid>
					<Grid item xs={12} md={10}>
						<MedicationTitleArea
							medication={currentMedication}
						/>
						<ul className={css.tabBar}>
							{navigationItems.map((item, index) => {
								return (
									<li key={index}>
										<NavLink
											exact
											to={item.location}
											onClick={(e)=>{item.disabled && e.preventDefault()}}
											className={item.disabled ? css.disabled : ''}
										>
											{item.label}
										</NavLink>
									</li>
								);
							})}
						</ul>
					</Grid>
				</Grid>

				<Grid container xs={12}>
					<Grid item xs={12}>
						<Switch>
							{navigationItems.map((item, index) => {
								return (
									<Route
										key={index}
										exact
										path={item.route}
										component={item.component}
									/>
								);
							})}
							<Redirect to='/404'/>
						</Switch>
					</Grid>
				</Grid>
			</InnerContainer>
		</AppFrame>
	);
};

const mapStateToProps = (state: ApplicationState): IMedicationDashboardViewReduxProps => {
	return {
		currentMedication: state.personaBuilderMedication.currentMedication
	};
};

export const MedicationDashboardView = connect(
	mapStateToProps,
	MedicationActionCreators
)(MedicationDashboardViewBase);
