import * as React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { connect, ResolveThunks } from 'react-redux';
import { organizationActionCreators } from 'stores/actions/organizationActions';
import { IModal } from 'common/interfaces/IModal';
import { IModalForm, IOrganization } from 'common/interfaces';
import css from './form-create-smart-app-launcher.module.scss';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { statesList } from 'common/data/states';
import classes from 'classnames';
import NumberFormat from 'react-number-format';
import { PhoneNumberField } from 'components/FormElements/PhoneNumberField';
import { validatePhoneNumber } from 'common/utils/validatePhoneNumber';
import { debouncedValidateCodeIsUnique } from 'common/utils/validateCodeIsUnique';
import { IIsCodeUnique } from 'common/interfaces/IIsCodeUnique';
import { url } from 'settings';
import { orgValidationSchema } from 'common/validation-schema/orgValidationSchema';
import { RequiredIndicator } from 'components/FormElements/RequiredIndicator';
import { Button } from 'components/Button';
import { FormLabel } from 'components/FormElements/FormLabel';
import { ISmartAppLauncher } from 'common/interfaces/ISmartAppLauncher';
import { IFormActions } from 'common/interfaces/IFormActions';
import { ApplicationState } from 'stores';
import Select from 'react-select';
// import Select from '@material-ui/core/Select';
import { debounce } from 'debounce';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { base64 } from 'common/utils/base64';
import AsyncSelect from 'react-select/async';
import { errorMessages } from 'common/data/errorMessages';

interface IFormCreateSmartAppLauncherReduxProps {
	currentPitUrl: string;
	currentAuthCredentials: string;
}

interface IFormCreateSmartAppLauncherState {
	selectedOption: string;
	options: { value: string; label: string }[];
}

export class FormCreateSmartAppLauncherBase extends React.PureComponent<
	IModalForm & IFormCreateSmartAppLauncherReduxProps,
	IFormCreateSmartAppLauncherState
> {
	debouncedFetchPatients;

	constructor(props) {
		super(props);

		this.state = {
			selectedOption: null,
			options: [
				{ value: 'chocolate', label: 'Chocolate' },
				{ value: 'strawberry', label: 'Strawberry' },
				{ value: 'vanilla', label: 'Vanilla' }
			]
		};

		this.debouncedFetchPatients = debounce((inputValue, callback) => this.fetchPatients(inputValue, callback), 300);
	}

	handleChange = selectedOption => {
		this.setState({ selectedOption });
		console.log(`Option selected:`, selectedOption);
	};

	fetchPatients = async (value, callback) => {
		console.log('fetchPatients', value);
		try {
			const headers = await getCurrentAuthHeaders('json');
			headers.set('Authorization', `Basic ${this.props.currentAuthCredentials}`);
			const patientFetch = await fetch(`${this.props.currentPitUrl}fhir/Patient?name=${value}&_pretty=true`, {
				headers
			});
			const patientResults = await patientFetch.json();
			console.log('patientResults', patientResults);
			console.log('patientResults.entry', patientResults.entry);

			if (patientResults.entry && patientResults.entry.length > 0) {
				const options = patientResults.entry.map(patient => {
					console.log('patient', patient);
					console.log('patient.resource.name', patient.resource.name);
					let patientName = '';
					patient.resource.name[0].given.forEach((name, index) => {
						if (index > 0) {
							patientName = patientName + ' ';
						}
						patientName = patientName + name;
					});

					patientName = `${patientName} ${patient.resource.name[0].family}`;
					const patientId = patient.resource.id;

					console.log('patientName', patientName);
					return {
						label: patientName,
						value: patientId
					};
				});
				console.log('options', options);
				console.log('running callback');
				console.log('callback', callback);
				callback(options);
			} else {
				callback([]);
			}
		} catch (error) {
			this.props.setErrorMessage(errorMessages.general);
		}
	};

	render() {
		const {
			bindSubmitForm,
			defaultFormContent,
			formAction,
			setErrorMessage,
			setFormLoadingAttribute,
			setFormDisabledAttribute
		} = this.props;

		const { selectedOption, options } = this.state;

		console.log('this.state.options', options);

		let initialValues = {
			name: '',
			fhir_patient_id: '',
			fhir_encounter_id: '',
			launch_url: ''
		};

		const modalActions: IFormActions = {
			setErrorMessage,
			setFormDisabledAttribute,
			setFormLoadingAttribute
		};

		const customStyles = {
			placeholder: (provided, state) => ({
				...provided,
				paddingLeft: '2rem'
			}),
			input: (provided, state) => ({
				...provided,
				padding: '1.2rem 2rem',
				marginBottom: 0
			}),
			control: (provided, state) => ({
				...provided,
				borderColor: state.isSelected ? '#e6e6e6' : '#e6e6e6',
				borderWidth: '.1rem',
				boxShadow: state.isFocused ? '.1rem #ff7d00' : 'none',
				boxShadowColor: '#ff7d00'
			})
		};

		console.log('this.debouncedFetchPatients', this.debouncedFetchPatients);

		return (
			<>
				<Formik
					initialValues={initialValues}
					onSubmit={(values, { setSubmitting }) => {
						const trimmedValues = trimAllStringProperties(values);
						const smartAppLauncher: ISmartAppLauncher = {
							name: trimmedValues.name,
							launch_url: trimmedValues.launch_url,
							scopes: [
								{
									key: 'patient',
									value: trimmedValues.fhir_patient_id
								},
								{
									key: 'encounter',
									value: trimmedValues.fhir_encounter_id
								}
							]
						};

						if (formAction) {
							formAction(smartAppLauncher, modalActions);
						}
						setSubmitting(false);
					}}
					validationSchema={yup.object().shape({
						name: yup.string().required('Required'),
						fhir_patient_id: yup.string().required('Required'),
						fhir_encounter_id: yup.string().required('Required'),
						launch_url: yup.string().required('Required')
					})}
				>
					{props => {
						const {
							values,
							touched,
							errors,
							dirty,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit,
							handleReset,
							submitForm,
							validateForm,
							setFieldValue
						} = props;

						if (bindSubmitForm) {
							bindSubmitForm(submitForm);
						}

						return (
							<form onSubmit={handleSubmit}>
								<FormLabel isRequired={true} htmlFor='name'>
									Name
								</FormLabel>
								<input
									id='name'
									placeholder='Enter Name'
									type='text'
									value={values.name}
									onChange={handleChange}
									onBlur={handleBlur}
									className={errors.name && touched.name ? 'text-input error' : 'text-input'}
								/>
								{errors.name && touched.name && <div className='input-feedback'>{errors.name}</div>}

								<FormLabel isRequired={true} htmlFor='fhir_patient_id'>
									Patient
								</FormLabel>
								<AsyncSelect
									isClearable
									cacheOptions
									loadOptions={(inputValue, callBack) => {
										console.log('call debouncedFetchPatients');
										this.debouncedFetchPatients(inputValue, callBack);
									}}
									id='fhir_patient_id'
									placeholder='Search Patients'
									onChange={option => setFieldValue('fhir_patient_id', (option as any).value)}
									onBlur={handleBlur}
									className={classes(
										css.select,
										errors.fhir_patient_id && touched.fhir_patient_id
											? 'text-input error'
											: 'text-input'
									)}
									styles={customStyles}
								/>
								{errors.fhir_patient_id && touched.fhir_patient_id && (
									<div className='input-feedback'>{errors.fhir_patient_id}</div>
								)}

								<FormLabel
									isRequired={true}
									htmlFor='fhir_encounter_id'
									subLabel='The Encounter ID can be found in the FHIR PIT data attached to each encounter record'
								>
									Encounter ID
								</FormLabel>
								<input
									id='fhir_encounter_id'
									placeholder='Enter Encounter ID'
									type='text'
									value={values.fhir_encounter_id}
									onChange={handleChange}
									onBlur={handleBlur}
									className={
										errors.fhir_encounter_id && touched.fhir_encounter_id
											? 'text-input error'
											: 'text-input'
									}
								/>
								{errors.fhir_encounter_id && touched.fhir_encounter_id && (
									<div className='input-feedback'>{errors.fhir_encounter_id}</div>
								)}

								<FormLabel isRequired={true} htmlFor='launch_url'>
									Launch Url
								</FormLabel>
								<input
									id='launch_url'
									placeholder='Enter Launch Url'
									type='text'
									value={values.launch_url}
									onChange={handleChange}
									onBlur={handleBlur}
									className={
										errors.launch_url && touched.launch_url ? 'text-input error' : 'text-input'
									}
								/>
								{errors.launch_url && touched.launch_url && (
									<div className='input-feedback'>{errors.launch_url}</div>
								)}

								<Button type='submit'>Create Organization</Button>
							</form>
						);
					}}
				</Formik>
			</>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IFormCreateSmartAppLauncherReduxProps => {
	const { currentRofId, currentPitId, allRofPits, allRof } = state.rof;
	const currentRofDetails = state.rof.allRofDetails[currentRofId];
	const currentPit = allRofPits[currentRofId][currentPitId];
	const currentRof = allRof[currentRofId];
	const currentPitUrl = currentPit.base_url;
	const currentAuthCredentials = currentRofDetails
		? base64(`${currentRofDetails.pit_basic_auth_username}:${currentRofDetails.pit_basic_auth_password}`)
		: null;

	console.log('currentAuthCredentials', currentAuthCredentials);
	return {
		currentPitUrl,
		currentAuthCredentials
	};
};

export const FormCreateSmartAppLauncher = connect(mapStateToProps)(FormCreateSmartAppLauncherBase);
