import * as React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { IModalForm } from 'common/interfaces';
import css from './form-delete-rof.module.scss';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';

let initialValues = {
	deleteConfirmation: ''
};

export class FormDeleteRof extends React.PureComponent<IModalForm> {
	render() {
		const {
			bindSubmitForm,
			formAction,
			defaultFormContent,
			confirmationString,
			setFormDisabledAttribute
		} = this.props;

		return (
			<Formik
				initialValues={initialValues}
				onSubmit={(values, { setSubmitting }) => {
					if (formAction) {
						formAction(trimAllStringProperties(values));
					}
					setSubmitting(false);
				}}
				validationSchema={yup.object().shape({
					deleteConfirmation: yup
						.string()
						.oneOf([confirmationString], 'Passwords must match')
				})}
			>
				{props => {
					const {
						values,
						touched,
						errors,
						dirty,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
						handleReset,
						submitForm
					} = props;
					if (bindSubmitForm) {
						bindSubmitForm(submitForm);
					}
					return (
						<form onSubmit={handleSubmit}>
							<p>
								Are you sure you want to delete this sandbox and its
								contents? This includes any smart apps and smart app
								users created.
							</p>
							<p>
								Please type{' '}
								<span className={css.code}>
									{confirmationString}
								</span>{' '}
								to confirm the deletion of the sandbox
							</p>
							<input
								id='deleteConfirmation'
								placeholder='Enter unique identifier to delete'
								type='text'
								value={values.deleteConfirmation}
								onChange={e => {
									handleChange(e);
									const isDisabled =
										e.target.value !== confirmationString;
									setFormDisabledAttribute(isDisabled);
								}}
								onBlur={handleBlur}
								className={css.confirmationInput}
							/>
							<input type='submit' />
						</form>
					);
				}}
			</Formik>
		);
	}
}
