import * as React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { IModalForm } from 'common/interfaces';
import css from './form-delete-api-doc.module.scss';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';

let initialValues = {
	deleteConfirmation: ''
};

export class FormDeleteApiDoc extends React.PureComponent<IModalForm> {
	render() {
		const {
			bindSubmitForm,
			formAction,
			defaultFormContent,
			confirmationString,
			setFormDisabledAttribute
		} = this.props;

		return (
			<Formik
				initialValues={initialValues}
				onSubmit={(values, { setSubmitting }) => {
					if (formAction) {
						formAction(trimAllStringProperties(values));
					}
					setSubmitting(false);
				}}
				validationSchema={yup.object().shape({
					deleteConfirmation: yup
						.string()
						.oneOf([confirmationString], 'Passwords must match')
				})}
			>
				{props => {
					const {
						values,
						touched,
						errors,
						dirty,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
						handleReset,
						submitForm
					} = props;
					if (bindSubmitForm) {
						bindSubmitForm(submitForm);
					}
					return (
						<form onSubmit={handleSubmit}>
							<p>
								Are you sure you would like to delete this API?
							</p>
							<input type='submit' />
						</form>
					);
				}}
			</Formik>
		);
	}
}
