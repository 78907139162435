import * as React from 'react';
import { connect } from 'react-redux';
import { EUpdateState } from 'stores/shared/ENums';
import { ApplicationState } from 'stores';
import { Warning } from 'components/Warning';
import css from './overview-tab.module.scss';
import { base64 } from 'common/utils/base64';
import { Button } from 'components/Button';
import classes from 'classnames';
import { ListItemBasic } from 'components/List/ListItemBasic';
import { copy } from 'common/utils/copy';
import { IRofDetails } from 'common/interfaces/IRofDetails';

interface IOverviewTabReduxProps {
	currentRofId: number;
	currentRof: any;
	currentPit: any;
	currentPitDetails: IRofDetails;
}

interface IOverviewTabState {
	visiblePassword: string;
	isPasswordRevealed: boolean;
	visibleAuthCredentials: string;
	isAuthCredentialsRevealed: boolean;
}

class OverviewTabBase extends React.PureComponent<IOverviewTabReduxProps, IOverviewTabState> {
	hiddenCredentials = '••••••••••••••••';

	constructor(props) {
		super(props);
		this.state = {
			visiblePassword: this.hiddenCredentials,
			isPasswordRevealed: false,
			visibleAuthCredentials: this.hiddenCredentials,
			isAuthCredentialsRevealed: false
		};
	}

	toggleVisiblePassword = () => {
		this.setState({
			visiblePassword: !this.state.isPasswordRevealed
				? this.props.currentPitDetails.pit_basic_auth_password
				: this.hiddenCredentials,
			isPasswordRevealed: !this.state.isPasswordRevealed
		});
	};

	toggleVisibleAuthCredentials = () => {
		this.setState({
			visibleAuthCredentials: !this.state.isAuthCredentialsRevealed
				? base64(
						`${this.props.currentPitDetails.pit_basic_auth_username}:${this.props.currentPitDetails.pit_basic_auth_password}`
				  )
				: this.hiddenCredentials,
			isAuthCredentialsRevealed: !this.state.isAuthCredentialsRevealed
		});
	};

	render() {
		const { currentPit, currentPitDetails } = this.props;

		const username = currentPitDetails ? currentPitDetails.pit_basic_auth_username : '';
		const password = currentPitDetails ? currentPitDetails.pit_basic_auth_password : '';

		console.log('username', username);
		console.log('password', password);

		console.log('currentPit', currentPit);

		return (
			<div className={css.maxWidth}>
				<h2 className='h3'>{currentPit.name}</h2>
				<Warning>
					This is not a production server. Do not store any personal health or other confidential information
					here.
				</Warning>
				<ul className={classes('reset', css.list)}>
					{/* <li>
				<b>Type:</b> Electronic Health Record
			</li> */}
					<ListItemBasic
						title='Link'
						ContentRight={() => (
							<Button style='link' onClick={() => copy(currentPit.base_url)} animate='copy'>
								Copy
							</Button>
						)}
					>
						<a target='_blank' href={currentPit.base_url}>
							{currentPit.base_url}
						</a>
					</ListItemBasic>

					{username && (
						<ListItemBasic
							title='Username'
							ContentRight={() => (
								<Button style='link' onClick={() => copy(username)} animate='copy'>
									Copy
								</Button>
							)}
						>
							{username}
						</ListItemBasic>
					)}
					{password && (
						<>
							<ListItemBasic
								title='Password'
								ContentRight={() => (
									<>
										<Button style='link' onClick={this.toggleVisiblePassword}>
											{this.state.isPasswordRevealed ? 'Conceal' : 'Reveal'}
										</Button>
										<Button style='link' onClick={() => copy(password)} animate='copy'>
											Copy
										</Button>
									</>
								)}
							>
								<span
									className={classes({
										[css.concealedText]: !this.state.isPasswordRevealed
									})}
								>
									{this.state.visiblePassword}
								</span>
							</ListItemBasic>
							<ListItemBasic
								title='Basic Authentication Credentials'
								ContentRight={() => (
									<>
										<Button style='link' onClick={this.toggleVisibleAuthCredentials}>
											{this.state.isAuthCredentialsRevealed ? 'Conceal' : 'Reveal'}
										</Button>
										<Button
											style='link'
											onClick={() => copy(base64(`${username}:${password}`))}
											animate='copy'
										>
											Copy
										</Button>
									</>
								)}
							>
								<span
									className={classes({
										[css.concealedText]: !this.state.isAuthCredentialsRevealed
									})}
								>
									{this.state.visibleAuthCredentials}
								</span>
							</ListItemBasic>
						</>
					)}
				</ul>
			</div>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IOverviewTabReduxProps => {
	const currentPitDetails =
		state.rof.currentRofId && state.rof.allRofDetails[state.rof.currentRofId]
			? state.rof.allRofDetails[state.rof.currentRofId]
			: null;

	console.log('currentPitDetails', currentPitDetails);

	return {
		currentPit:
			state.rof.allRofPits && state.rof.currentRofId && state.rof.allRofPits[state.rof.currentRofId]
				? state.rof.allRofPits[state.rof.currentRofId][state.rof.currentPitId]
				: null,
		currentRofId: state.rof.currentRofId,
		currentRof: state.rof.allRof && state.rof.currentRofId ? state.rof.allRof[state.rof.currentRofId] : null,
		currentPitDetails
	};
};

export const OverviewTab = connect(mapStateToProps)(OverviewTabBase);
