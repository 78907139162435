export const OAuthScopes = [
  "openid",
  "profile",
  "patient/Observation.read",
  "patient/Procedure.read",
  "patient/Encounter.read",
  "patient/Appointment.read",
  "patient/Slot.read",
  "patient/DiagnosticReport.read",
  "patient/Condition.read",
  "patient/Immunization.read",
  "patient/CarePlan.read",
  "patient/MedicationStatement.read",
  "patient/Patient.read",
  "patient/Goal.read",
  "patient/AllergyIntolerance.read",
  "patient/DocumentReference.read",
  "patient/Device.read",
  "patient/Practitioner.read",
  "patient/Schedule.read",
  "patient/Medication.read",
  "patient/Location.read",
  "patient/Account.read",
  "patient/ActivityDefinition.read",
  "patient/AdverseEvent.read",
  "patient/AppointmentResponse.read",
  "patient/AuditEvent.read",
  "patient/Basic.read",
  "patient/Binary.read",
  "patient/BiologicallyDerivedProduct.read",
  "patient/BodyStructure.read",
  "patient/Bundle.read",
  "patient/CapabilityStatement.read",
  "patient/CareTeam.read",
  "patient/CatalogEntry.read",
  "patient/ChargeItem.read",
  "patient/ChargeItemDefinition.read",
  "patient/Claim.read",
  "patient/ClaimResponse.read",
  "patient/ClinicalImpression.read",
  "patient/CodeSystem.read",
  "patient/Communication.read",
  "patient/CommunicationRequest.read",
  "patient/CompartmentDefinition.read",
  "patient/Composition.read",
  "patient/ConceptMap.read",
  "patient/Consent.read",
  "patient/Contract.read",
  "patient/Coverage.read",
  "patient/CoverageEligibilityRequest.read",
  "patient/CoverageEligibilityResponse.read",
  "patient/DetectedIssue.read",
  "patient/DeviceDefinition.read",
  "patient/DeviceMetric.read",
  "patient/DeviceRequest.read",
  "patient/DeviceUseStatement.read",
  "patient/DocumentManifest.read",
  "patient/EffectEvidenceSynthesis.read",
  "patient/Endpoint.read",
  "patient/EnrollmentRequest.read",
  "patient/EnrollmentResponse.read",
  "patient/EpisodeOfCare.read",
  "patient/EventDefinition.read",
  "patient/Evidence.read",
  "patient/EvidenceVariable.read",
  "patient/ExampleScenario.read",
  "patient/ExplanationOfBenefit.read",
  "patient/FamilyMemberHistory.read",
  "patient/Flag.read",
  "patient/GraphDefinition.read",
  "patient/Group.read",
  "patient/GuidanceResponse.read",
  "patient/HealthcareService.read",
  "patient/ImagingStudy.read",
  "patient/ImmunizationEvaluation.read",
  "patient/ImmunizationRecommendation.read",
  "patient/ImplementationGuide.read",
  "patient/InsurancePlan.read",
  "patient/Invoice.read",
  "patient/Library.read",
  "patient/Linkage.read",
  "patient/List.read",
  "patient/Measure.read",
  "patient/MeasureReport.read",
  "patient/Media.read",
  "patient/MedicationAdministration.read",
  "patient/MedicationDispense.read",
  "patient/MedicationKnowledge.read",
  "patient/MedicationRequest.read",
  "patient/MedicinalProduct.read",
  "patient/MedicinalProductAuthorization.read",
  "patient/MedicinalProductContraindication.read",
  "patient/MedicinalProductIndication.read",
  "patient/MedicinalProductIngredient.read",
  "patient/MedicinalProductInteraction.read",
  "patient/MedicinalProductManufactured.read",
  "patient/MedicinalProductPackaged.read",
  "patient/MedicinalProductPharmaceutical.read",
  "patient/MedicinalProductUndesirableEffect.read",
  "patient/MessageDefinition.read",
  "patient/MessageHeader.read",
  "patient/MolecularSequence.read",
  "patient/NamingSystem.read",
  "patient/NutritionOrder.read",
  "patient/ObservationDefinition.read",
  "patient/OperationDefinition.read",
  "patient/OperationOutcome.read",
  "patient/Organization.read",
  "patient/OrganizationAffiliation.read",
  "patient/Parameters.read",
  "patient/PaymentNotice.read",
  "patient/PaymentReconciliation.read",
  "patient/Person.read",
  "patient/PlanDefinition.read",
  "patient/PractitionerRole.read",
  "patient/Provenance.read",
  "patient/Questionnaire.read",
  "patient/QuestionnaireResponse.read",
  "patient/RelatedPerson.read",
  "patient/RequestGroup.read",
  "patient/ResearchDefinition.read",
  "patient/ResearchElementDefinition.read",
  "patient/ResearchStudy.read",
  "patient/ResearchSubject.read",
  "patient/RiskAssessment.read",
  "patient/RiskEvidenceSynthesis.read",
  "patient/SearchParameter.read",
  "patient/ServiceRequest.read",
  "patient/Specimen.read",
  "patient/SpecimenDefinition.read",
  "patient/StructureDefinition.read",
  "patient/StructureMap.read",
  "patient/Subscription.read",
  "patient/Substance.read",
  "patient/SubstanceNucleicAcid.read",
  "patient/SubstancePolymer.read",
  "patient/SubstanceProtein.read",
  "patient/SubstanceReferenceInformation.read",
  "patient/SubstanceSourceMaterial.read",
  "patient/SubstanceSpecification.read",
  "patient/SupplyDelivery.read",
  "patient/SupplyRequest.read",
  "patient/Task.read",
  "patient/TerminologyCapabilities.read",
  "patient/TestReport.read",
  "patient/TestScript.read",
  "patient/ValueSet.read",
  "patient/VerificationResult.read",
  "patient/VisionPrescription.read"
];
