import * as React from 'react';
import { FormCreateRof } from 'components/Forms/FormCreateRof';
import { IModal } from 'common/interfaces';

export const createNewROFModal = (organizationId: number): IModal => ({
	title: 'Set up your Sandbox',
	primaryButtonLabel: 'Create Sandbox',
	description:
		'To set up your sandbox, please fill in the required fields. This process may take several minutes to complete.',
	content: props => (
		<FormCreateRof organizationId={organizationId} {...props} />
	)
});
