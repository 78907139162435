import * as React from 'react';
import css from './badge.module.scss';
import classes from 'classnames';

interface IBadge {
	className?: string;
}

export const Badge: React.FC<IBadge> = props => {
	return <div className={classes(css.badge, props.className)}>{props.children}</div>;
};
