import * as React from 'react';
import { connect } from 'react-redux';
import { IModalForm } from 'common/interfaces';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { FormLabel } from 'components/FormElements';
import { PhoneNumberField } from 'components/FormElements/PhoneNumberField/PhoneNumberField';
import { removePhoneNumberFormatting } from 'common/utils/removePhoneNumberFormatting';
import { ErrorFocus } from 'components/ErrorFocus/ErrorFocus';
import { Formik } from 'formik';


interface IFormEditPhoneNumberState {
    isLoading: boolean;
}


export class FormEditPhoneNumberBase extends React.PureComponent<
    IModalForm &
    IFormEditPhoneNumberState
> {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    setFormLoadingAttribute = (isLoading: boolean) => {
        this.setState({
            isLoading,
        });
    };


    render() {
        let { setFormLoadingAttribute } = this.props;

        const {
            bindSubmitForm,
            formAction,
            defaultFormContent,
            setErrorMessage,
        } = this.props;

        let [
            username,
            email,
            phone_number,
            organization_id,
            user_type
        ] = defaultFormContent;

        let initialValues = {
            username: username,
            email: email,
            user_type: user_type,
            phone_number: phone_number,
            organization_id: organization_id,
        };

        let handleSubmit = (values, { setSubmitting }) => {
            values.phone_number = `+1${removePhoneNumberFormatting(values.phone_number)}`;
            setFormLoadingAttribute(true);

            if (formAction) {
                formAction(
                    trimAllStringProperties(values),
                    initialValues.organization_id,
                    setErrorMessage,
                    setFormLoadingAttribute
                );
            }
            setSubmitting(false);
        }


        if (!setFormLoadingAttribute) {
            setFormLoadingAttribute = this.setFormLoadingAttribute;
        }

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values, { setSubmitting })
                }}
            >
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        submitForm,
                    } = props;
                    if (bindSubmitForm) {
                        bindSubmitForm(submitForm);
                    }
                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <FormLabel htmlFor='phone_number'>Phone Number</FormLabel>
                                <PhoneNumberField
                                    id='phone_number'
                                    placeholder='Enter Phone Number'
                                    value={removePhoneNumberFormatting(values.phone_number)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                        errors.phone_number && touched.phone_number ? 'text-input error' : 'text-input'
                                    }
                                />
                                {errors.phone_number && touched.phone_number && (
                                    <div className='input-feedback'>{errors.phone_number}</div>
                                )}
                            </form>
                            <ErrorFocus />
                        </>
                    );
                }}
            </Formik>
        );
    }
}

export const FormEditPhoneNumber = connect()(FormEditPhoneNumberBase);