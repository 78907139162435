import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { rofActionCreators } from 'stores/actions/rofActions';
import { modalActionCreators } from 'stores/actions/modalActions';
import { RouteComponentProps } from 'react-router';
import { Panel, PanelContentSplit } from 'components/Panel';
import { Button } from 'components/Button';
import { ListItemBasic } from 'components/List/ListItemBasic';
import { deleteRofModal } from 'common/modals/deleteRofModal';
import { format } from 'date-fns';
import css from './rof-settings-teb.module.scss';
import { TUserType } from 'common/types/TUserType';

const rofSettingsTabActionCreators = {
	...rofActionCreators,
	...modalActionCreators
};

interface IRofSettingsTabReduxProps {
	currentRofId: number;
	currentRof: any;
	currentPits: any;
	currentUserType: TUserType;
}

const ContentLeft: React.FC = () => (
	<>
		<h2>Delete Ring</h2>
		<p>This will permanently delete your data.</p>
	</>
);

class RofSettingsTabBase extends React.PureComponent<
	RouteComponentProps<{ id?: string }> &
	IRofSettingsTabReduxProps &
	ResolveThunks<typeof rofSettingsTabActionCreators>
> {
	deleteRof() {
		this.props.deleteRof(this.props.currentRofId);
	}

	render() {
		const { createModal, currentRof, deleteRof, currentUserType } = this.props;
		return (
			<div>
				<h2 className='h3'>Sandbox Type Information</h2>

				<ListItemBasic title='Sandbox Type'>
					{`${currentRof.template_name} Ver. ${currentRof.template_version} (${format(new Date(currentRof.template_version_date), 'MM/dd/yyyy')})`}
				</ListItemBasic>
				<ListItemBasic title='Description'>
					{currentRof.template_descr}
				</ListItemBasic>
				<ListItemBasic title='Unique Identifier'>
					{currentRof.code}
				</ListItemBasic>
				<ListItemBasic title='FHIR Version'>
					{currentRof.template_fhir_version}
				</ListItemBasic>
				<ListItemBasic title='FHIR Server Version'>
					{currentRof.template_fhir_lib_version}
				</ListItemBasic>
				{currentUserType == 'mihin-admin' && <ListItemBasic title='Created By'>
					{currentRof.created_by}
				</ListItemBasic>}

				<Panel className={css.deletePanel}>
					<PanelContentSplit
						title='Delete Sandbox'
						ContentLeft={() => (
							<>
								<p>This will permanently delete your data.</p>
							</>
						)}
						ContentRight={() => (
							<Button
								style='destructive'
								onClick={event =>
									createModal(
										deleteRofModal(currentRof, deleteRof),
										event
									)
								}
							>
								Delete
							</Button>
						)}
					/>
				</Panel>
			</div>
		);
	}
}

const mapStateToProps = (
	state: ApplicationState
): IRofSettingsTabReduxProps => ({
	currentRofId: state.rof.currentRofId,
	currentRof:
		state.rof.allRof && state.rof.currentRofId
			? state.rof.allRof[state.rof.currentRofId]
			: null,
	currentPits:
		state.rof.allRof && state.rof.currentRofId
			? state.rof.allRofPits[state.rof.currentRofId]
			: null,
	currentUserType: state.auth.user.attributes['custom:user_type']
});

export const RofSettingsTab = connect(
	mapStateToProps,
	rofSettingsTabActionCreators
)(RofSettingsTabBase);
