import React, { Component } from 'react';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { Routes } from 'routes';
import './App.scss';
import { connect, ResolveThunks } from 'react-redux';
import { websocketActionCreators } from 'stores/actions/websocketActions';

import Amplify, { Auth } from 'aws-amplify';
import awsmobile from './aws-exports';
import { ApplicationState } from 'stores';
import { ICognitoUser } from 'common/interfaces';

Amplify.configure(awsmobile);

interface AppProps {
	history: History;
}

const AppActionInterfaces = {
	...websocketActionCreators,
};

interface IAppReduxProps {
	user: ICognitoUser;
}

class App extends React.PureComponent<
	IAppReduxProps & AppProps & ResolveThunks<typeof AppActionInterfaces>
> {
	componentDidMount() {
		if (this.props.user) {
			this.props.connectToSandboxUpdates();
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.user &&
			nextProps.user.challengeName !== 'NEW_PASSWORD_REQUIRED' &&
			this.props.user !== nextProps.user
		) {
			console.log('Connect time', Date.now());
			console.log('Connect this.props.user', this.props.user);
			console.log('Connect nextProps.user', nextProps.user);
			console.log('Connect web socket');
			this.props.connectToSandboxUpdates();
		}
	}

	render() {
		const { history } = this.props;
		return <ConnectedRouter history={history}>{Routes}</ConnectedRouter>;
	}
}

function handleFirstTab(e) {
	if (e.keyCode === 9) {
		// the "I am a keyboard user" key
		document.body.classList.add('user-is-tabbing');
		window.removeEventListener('keydown', handleFirstTab);
	}
}

window.addEventListener('keydown', handleFirstTab);

const mapStateToProps = (state: ApplicationState): IAppReduxProps => {
	return {
		user: state.auth.user,
	};
};

export default connect(mapStateToProps, AppActionInterfaces)(App);
