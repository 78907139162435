import * as React from 'react';
import { Formik } from 'formik';
import { IModalForm } from 'common/interfaces';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import classes from 'classnames';
import { FormLabel } from 'components/FormElements';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { ErrorFocus } from 'components/ErrorFocus';
import { Card, CardContent, Divider, Grid, MuiThemeProvider, Theme, createMuiTheme } from '@material-ui/core';
import css from './form-patient-description.module.scss';
import { patientDescriptionValidationSchema } from 'common/validation-schema/personaBuilder/patientDescriptionValidationSchema';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { Link } from 'react-router-dom';
import { MuiSelect } from 'components/MaterialUI';
import MUIRichTextEditor from 'mui-rte';
import { ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import { stateToHTML } from "draft-js-export-html";
import { IPatientUseCase } from 'common/interfaces/personaBuilder/IPatientUseCase';
import { IsFormDirty } from 'components/RouteGuards/IsFormDirty';

interface IFormPatientDescriptionState {
	isLoading: boolean;
	file: any;
}

interface IFormPatientDescriptionReduxProps {
	useCases: IPatientUseCase[]
}

const defaultTheme: Theme = createMuiTheme({
	palette: {
		primary: {
			main: '#000000'
		}
	}
});

Object.assign(defaultTheme, {
	overrides: {
		MUIRichTextEditor: {
			root: {
				width: '100%',
				border: '1px solid #526578',
				borderRadius: '5px'
			},
			container: {
				margin: 0
			},
			editor: {
				padding: '1.8rem 2.5rem',
				height: '300px',
				overflow: 'auto',
				width: '100%'
			},
			placeHolder: {
				padding: '1.8rem 2.5rem'
			},
			toolbar: {
				borderBottom: '1px solid #526578',
				padding: '5px'
			}
		}
	}
});

export class FormPatientDescriptionBase extends React.PureComponent<
	ResolveThunks<typeof PersonaBuilderActionCreators> &
	IFormPatientDescriptionReduxProps &
	IModalForm,
	IFormPatientDescriptionState> {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			file: null
		};
	}

	setFormLoadingAttribute = (isLoading: boolean) => {
		this.setState({
			isLoading
		});
	};

	handleFileChange = (event: any) => {
		if (event.target.files.length > 0){
			this.setState({file: URL.createObjectURL(event.target.files[0])})
		}

	}

	render() {
		let { setFormLoadingAttribute } = this.props;
		const {
			bindSubmitForm,
			formAction,
			defaultFormContent,
			setErrorMessage,
			setSaveDisabled
		} = this.props;


		let initialValues = {
			type: '',
			bio: '',
			description: '',
			image_url: '',
			use_cases: [],
			formatted_content: null,
			height: '',
			weight: ''
		};

		const options = this.props.useCases ? this.props.useCases.map(u=>{ return {id: u.id, label: u.persona_use_case}}) : []

		if (defaultFormContent) {
			initialValues = defaultFormContent;
			if (defaultFormContent.bio) {
				const contentHTML = convertFromHTML(defaultFormContent.bio);
				const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap);
				initialValues.formatted_content = convertToRaw(state);
			}
			
			initialValues.use_cases = options.filter(u => defaultFormContent.use_cases.includes(u.id));
		}
		if (!setFormLoadingAttribute) {
			setFormLoadingAttribute = this.setFormLoadingAttribute;
		}

		return (
			<Formik
				initialValues={initialValues}
				enableReinitialize={true}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					setFormLoadingAttribute(true);
					if (formAction) {
						const trimmedValues = trimAllStringProperties(values);
						formAction(trimmedValues, setErrorMessage, setFormLoadingAttribute);
						resetForm(trimmedValues);
					}
					setSubmitting(false);
				}}
				validationSchema={patientDescriptionValidationSchema()}
			>
				{props => {
					const {
						values,
						touched,
						errors,
						dirty,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
						handleReset,
						submitForm,
						isValid
					} = props;
					if (bindSubmitForm) {
						bindSubmitForm(submitForm);
					}

					return (
						<>
							<IsFormDirty
								title=""
								message=""
								dirty={dirty}
							/>
							<Card>
								<CardContent>
									<form onSubmit={handleSubmit}>
										<Grid container xs={12}>
											<Grid container xs={12} md={6} className={
												css.gridContainer
											}>
												<Grid item xs={12}>
													<FormLabel htmlFor='type'>Patient Type</FormLabel>
													<textarea
														id='type'
														placeholder='Enter Patient Type'
														rows={4}
														value={values.type}
														onChange={handleChange}
														onBlur={handleBlur}
														className={classes( 'text-input', css.noMargin, {'error': errors.type && touched.type})}
														autoComplete='first-name'
														maxLength={255}
													/>
													{errors.type && touched.type && (
														<div className='input-feedback'>{errors.type}</div>
													)}
													<div className={css.charRemainingContainer}><strong>{values.type && 255 - values.type.length}</strong> characters left</div>
												</Grid>
												<Grid item xs={12}>
													<FormLabel htmlFor='use_cases'>Use Cases</FormLabel>
													<MuiSelect labels={options} selected={values.use_cases} onClose={(selected)=>{
														const useCases = selected.map(s=>{return {id: s.id, persona_use_case: s.label}});
														props.setFieldValue('use_cases', useCases);
													}}/>
												</Grid>

												<Grid item xs={12}>
													<FormLabel htmlFor='bio'>Biography</FormLabel>
													<MuiThemeProvider theme={defaultTheme}>
														<MUIRichTextEditor
															controls={['title', 'bold', 'italic', 'underline', 'strikethrough', 'highlight', 'link', 'numberList', 'bulletList', 'quote', 'code', 'clear']}
															label={'Enter biography'}
															value={JSON.stringify(values.formatted_content) || ''}
															onChange={(state)=>{
																const html = stateToHTML(state.getCurrentContent());
																props.setFieldValue('bio', html);
															}}
															inheritFontSize
															inlineToolbar
														/>
													</MuiThemeProvider>
													{errors.type && touched.type && (
														<div className='input-feedback'>{errors.type}</div>
													)}
												</Grid>
											</Grid>
											<Grid item xs={12} md={6} className={
												css.gridContainer
											}>
												<Grid item>
													<label>Profile Photo</label>
													<img className={css.imageAvatar} width={'50%'}
															 src={this.state.file || 'https://www.sovereignsolutionscorp.com/wp-content/uploads/2018/12/img-avatar-blank.jpg'}/>
													<input
														type={'file'}
														className={css.fileUploadInput}
														accept={'image/*'}
														onChange={this.handleFileChange}
													/>
													<div className={css.removeLink}>
														<Link to={''} onClick={(e) => {
															e.preventDefault();
															this.setState({file: null})
														}}>
															Remove Photo
														</Link>
													</div>
												</Grid>
												<Grid item xs={12}>
													<FormLabel htmlFor='description'>One Sentence Summary</FormLabel>
													<textarea
														id='description'
														placeholder='Enter One Sentence Summary'
														rows={4}
														value={values.description}
														onChange={handleChange}
														onBlur={handleBlur}
														className={classes( 'text-input', css.noMargin, {'error': errors.description && touched.description})}
														autoComplete='first-name'
														maxLength={255}
													/>
													{errors.type && touched.type && (
														<div className='input-feedback'>{errors.type}</div>
													)}
													<div className={css.charRemainingContainer}><strong>{values.description && 255 - values.description.length}</strong> characters left</div>
												</Grid>
												<Grid item xs={12} className={
													css.gridItem
												}>
													<FormLabel htmlFor=''>Vitals</FormLabel>
												</Grid>
												<Grid container>
													<Grid item xs={12} md={6} className={
														css.gridItem
													}>

														<label htmlFor='height' className={css.normalLabel}>Height (in)</label>
														<input
															id='height'
															placeholder='Enter Height'
															value={values.height}
															onChange={handleChange}
															onBlur={handleBlur}
															className={
																errors.height && touched.height ? 'text-input error' : 'text-input'
															}
														/>

														{errors.height && touched.height && (
															<div className='input-feedback'>{errors.height}</div>
														)}

													</Grid>
													<Grid item xs={12} md={6} className={
														css.gridItem
													}>

														<label htmlFor='weight' className={css.normalLabel}>Weight (in)</label>
														<input
															id='weight'
															placeholder='Enter Weight'
															value={values.weight}
															onChange={handleChange}
															onBlur={handleBlur}
															className={
																errors.weight && touched.weight ? 'text-input error' : 'text-input'
															}
														/>

														{errors.weight && touched.weight && (
															<div className='input-feedback'>{errors.weight}</div>
														)}
													</Grid>
												</Grid>
											</Grid>
											<ErrorFocus/>
										</Grid>
									</form>
								</CardContent>

							</Card>
						</>
					);
				}}
			</Formik>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IFormPatientDescriptionReduxProps => ({
	useCases: state.personaBuilder.dropdowns.use_cases,
	// persistenceState: state.personaBuilder.persistenceState
});

export const FormPatientDescription = connect(
	mapStateToProps,
	PersonaBuilderActionCreators
)(FormPatientDescriptionBase);

