import * as React from 'react';
import { InnerContainer } from '../InnerContainer/InnerContainer';
import css from './page-title-banner.module.scss';
import classes from 'classnames';

interface IPageTitleBannerProps {
	title: string;
	description: string;
	subDescription?: string;
	background: 'heartAndChip' | 'buildingsAndComputer';
}

export const PageTitleBanner: React.FC<IPageTitleBannerProps> = props => {
	const { title, description, subDescription, children, background } = props;
	return (
		<div className={classes(css.pageTitleBanner, css[background])}>
			<InnerContainer>
				<div className={css.textContain}>
					<h1 id='skipNavLocation'>{title}</h1>
					<p className={classes('h4', css.description)}>{description}</p>
					{subDescription && <p>{subDescription}</p>}
					{children}
				</div>
			</InnerContainer>
		</div>
	);
};
