import * as yup from 'yup';

interface IMedicationDetailsValidationSchemaConfig {
}

interface IMedicationDetailsValidationSchema {
  proprietary_name: string;
  non_proprietary_name: string;
  substance_name: string;
  medication_ndc_code: string;
  medication_snomed_code: string;
  medication_form_id: number;

  // Leaving these here in case we do actually need this validation
  // strength: string;
  // ingredient_unit: string;
}

export const medicationDetailsValidationSchema = (
	medicationDetailsValidationSchemaConfig: IMedicationDetailsValidationSchemaConfig = {}
) => {
  let schemaDefinition = {
    proprietary_name: yup.string().required('Proprietary Name is Required'),
    non_proprietary_name: yup.string().required('Non Proprietary Name is Required'),
    substance_name: yup.string().required('Substance Name is Required'),
    medication_ndc_code: yup.string().required('NDC Code is Required'),
    medication_snomed_code: yup.string().required('SNOMED Code is Required'),
    medication_form_id: yup.number().typeError('Form is invalid.').required('Form is required.')
    
    // Leaving these here in case we do actually need this validation
    // strength: yup.string().required('Strength is Required'), 
    // ingredient_unit: yup.string().required('Strength Unit is Required'),
  };

  return yup.object().shape<any>(schemaDefinition) as any;
};
