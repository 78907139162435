import * as React from 'react';
import { useEffect } from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { IModalForm, IOrganization } from 'common/interfaces';
import { ReactComponent as IconBuilding } from 'assets/icons/icon-building.svg';
import { organizationActionCreators } from 'stores/actions/organizationActions';
import { ApplicationState } from 'stores';
import { convertIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';
import { NavLink } from 'react-router-dom';
import css from './organization-drop-down-menu.module.scss';
import classes from 'classnames';
import { convertArrayToIndexedObject } from 'common/utils/convertArrayToIndexedObject';

interface IOrganizationDropdownMenuProps {
    isShown: boolean;
    setVisibility: (isVisible: boolean) => void;
    ignoreTarget: any;
}


interface IOrganizationDropdownMenuReduxProps {
    organizations: IOrganization[];
    suborganizations: IOrganization[];
    noParentSuborganizations: IOrganization[];
}

const OrganizationDropdownMenuActionCreators = {
    ...organizationActionCreators,
};


export const OrganizationDropdownMenuBase: React.FunctionComponent<
    IModalForm &
    IOrganizationDropdownMenuProps &
    IOrganizationDropdownMenuReduxProps &
    ResolveThunks<typeof OrganizationDropdownMenuActionCreators>
> = ({ organizations, suborganizations, noParentSuborganizations, isShown, setVisibility, ignoreTarget }) => {
    const dismissOnEscape = event => {
        if (event.keyCode === 27) {
            setVisibility(false);
        }
    };

    const handleClickOutside = event => {
        const domNode = ignoreTarget;

        if (!domNode || !domNode.contains(event.target)) {
            setVisibility(false);
        }
    };

    const subOrgWithNoParent =

        useEffect(() => {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keyup', dismissOnEscape, true);
            return () => {
                document.removeEventListener('click', handleClickOutside, true);
                document.removeEventListener('keyup', dismissOnEscape, true);
            }
        });

    return (
        <div
            className={classes('reset', css.dropDown, {
                [css.shown]: isShown
            })}
        >
            <ul className='reset'>
                {noParentSuborganizations &&
                    noParentSuborganizations.map((noParentSuborgItem, index) => {
                        return (
                            <li>
                                <NavLink className={css.dropDownItem} to={`/organization/${noParentSuborgItem.id}`}>
                                    <IconBuilding /> &nbsp;&nbsp;{noParentSuborgItem.name}
                                </NavLink>
                            </li>
                        );
                    })
                }
                {organizations &&
                    organizations.map((orgItem, index) => {
                        return (
                            <>
                                <li>
                                    <NavLink className={css.dropDownItem} to={`/organization/${orgItem.id}`}>
                                        {orgItem.name}
                                    </NavLink>
                                </li>
                                {suborganizations &&
                                    suborganizations.map((item, index) => {
                                        return (
                                            <>
                                                {item.parent_organization_id === orgItem.id &&
                                                    <li>
                                                        <NavLink className={css.dropDownItem} to={`/organization/${item.id}`}>
                                                            <IconBuilding /> &nbsp;&nbsp;{item.name}
                                                        </NavLink>
                                                    </li>
                                                }
                                            </>
                                        );
                                    })}
                            </>
                        );
                    })}
            </ul>
        </div>
    );
}


const mapStateToProps = (state: ApplicationState): IOrganizationDropdownMenuReduxProps => {
    const stateOrganizations = state.organization.organizations
    const organizations = convertIndexedObjectToArray(stateOrganizations).filter(
        (organization) => typeof organization.parent_organization_id !== 'number'
    );

    const suborganizations = convertIndexedObjectToArray(stateOrganizations).filter(
        (organization) => typeof organization.parent_organization_id === 'number'
    );

    const filteredOrg = convertArrayToIndexedObject(organizations)
    const orgIds = Object.keys(filteredOrg)

    const suborgsWithNoParent = convertIndexedObjectToArray(stateOrganizations).filter(
        (organization) => !orgIds.includes(String(organization.parent_organization_id)) && !!organization.parent_organization_id
    )


    return {
        organizations: organizations ? organizations : [],
        suborganizations: suborganizations ? suborganizations : [],
        noParentSuborganizations: suborgsWithNoParent ? suborgsWithNoParent : [],
    };
};

export const OrganizationDropDownMenu = connect(
    mapStateToProps,
    OrganizationDropdownMenuActionCreators
)(OrganizationDropdownMenuBase);