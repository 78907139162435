import React from 'react';
import { IModalForm, IUserOrg } from "common/interfaces";
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { TableBody, TableColumn, TableHeader, TableRow } from 'components/Table';
import { Link } from 'react-router-dom';
import { modalActionCreators } from 'stores/actions/modalActions';

interface UserOrganizationListingTabViewReduxProps {
    organizations: {},
    userOrganizations: IUserOrg[]
}

const UserOrganizationListingTabViewActionCreators = {
    ...modalActionCreators
};

export const UserOrganizationListingTabViewBase: React.FunctionComponent<
    IModalForm &
    UserOrganizationListingTabViewReduxProps &
    ResolveThunks<typeof UserOrganizationListingTabViewActionCreators & typeof modalActionCreators>
>
    = ({
        organizations,
        defaultFormContent,
        dismissModal,
        userOrganizations,
    }) => {

        const currentUserName = defaultFormContent[0]
        const currentUserOrganizations = userOrganizations.filter(userOrg => {
            return userOrg.username == currentUserName;
        })

        return (
            <TableBody>
                <TableHeader>
                    <TableColumn>Organization&nbsp;Name</TableColumn>
                    <TableColumn>User&nbsp;Type</TableColumn>
                </TableHeader>
                {currentUserOrganizations &&
                    currentUserOrganizations.map((item, index) => {
                        const orgName = organizations[item.organization_id].name
                        const userType = item.user_type == 'mihin-admin' ? 'super-admin' : item.user_type
                        return (
                            <TableRow key={index}>
                                <TableColumn>
                                    <Link to={`/organization/${item.organization_id}`} onClick={() => dismissModal()}>
                                        {orgName}
                                    </Link>
                                </TableColumn>
                                <TableColumn>
                                    {userType}
                                </TableColumn>
                            </TableRow>
                        );
                    })}
            </TableBody >
        );

    }

const mapStateToProps = (state: ApplicationState): UserOrganizationListingTabViewReduxProps => ({
    organizations: state.organization.organizations,
    userOrganizations: state.superAdmin.userOrgs
});

export const UserOrganizationListingTab = connect(
    mapStateToProps,
    UserOrganizationListingTabViewActionCreators,
)(UserOrganizationListingTabViewBase);