import * as React from 'react';
import { AppFrameAuth } from './AppFrameAuth';
import { Formik } from 'formik';
import { authActionCreators } from 'stores/actions/authActions';
import { ApplicationState } from 'stores';
import { connect } from 'react-redux';
import css from './auth.module.scss';
import { Link } from 'react-router-dom';
import { Button } from 'components/Button';
import { Success } from 'components/Toast';
import * as yup from 'yup';
import { Error } from 'components/Toast/Error';

const forgotPasswordActionCreators = {
	...authActionCreators
};

interface IForgotPasswordProps {
	user: any;
	error: string;
}

interface IForgotPasswordState {
	errorMessage: string;
	isLoading: boolean;
}

class ForgotPasswordBase extends React.PureComponent<
	IForgotPasswordProps & typeof forgotPasswordActionCreators & any,
	IForgotPasswordState
> {
	constructor(props) {
		super(props);

		this.state = {
			errorMessage: null,
			isLoading: false
		};
	}

	forgotPassword = (username: string, setErrorMessage, setLoadingProperty) => {
		this.props.forgotPassword(username, setErrorMessage, setLoadingProperty);
	};

	UNSAFE_componentWillMount() {
		this.props.getAuth();
	}

	setErrorMessage = (errorMessage: string) => {
		this.setState({
			errorMessage
		});
	};

	setLoadingProperty = (isLoading: boolean) => {
		this.setState({
			isLoading
		});
	};

	render() {
		const { user, code, authState, error } = this.props;
		return (
			<AppFrameAuth
				title='Forgotten Password'
				description='Enter your username and a password reset link will be
			emailed to you.'
				backgroundImage='testTubeAndComputer'
			>
				{this.state.errorMessage && <Error>{this.state.errorMessage}</Error>}
				<Formik
					initialValues={{ username: '' }}
					onSubmit={(values, { setSubmitting }) => {
						this.forgotPassword(values.username, this.setErrorMessage, this.setLoadingProperty);
						setSubmitting(false);
					}}
					validationSchema={yup.object().shape({
						username: yup.string().required('Required')
					})}
				>
					{props => {
						const {
							values,
							touched,
							errors,
							dirty,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit,
							handleReset
						} = props;
						return (
							<form onSubmit={handleSubmit}>
								<label htmlFor='username' style={{ display: 'block' }}>
									Username
								</label>
								<input
									id='username'
									placeholder='Enter your username'
									type='text'
									value={values.username}
									onChange={handleChange}
									onBlur={handleBlur}
									className={errors.username && touched.username ? 'text-input error' : 'text-input'}
								/>
								{errors.username && touched.username && (
									<div className='input-feedback'>{errors.username}</div>
								)}
								<div className='row'>
									<Button
										className={css.resetSubmit}
										type='submit'
										disabled={isSubmitting}
										isLoading={this.state.isLoading}
									>
										Reset Password
									</Button>
									<Link className={css.backLink} to='/login'>
										Back to Sign In
									</Link>
								</div>
							</form>
						);
					}}
				</Formik>
			</AppFrameAuth>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IForgotPasswordProps => ({
	user: state.auth.user,
	error: state.auth.authError
});

export const ForgotPassword = connect(mapStateToProps, forgotPasswordActionCreators)(ForgotPasswordBase);
