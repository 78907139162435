import * as React from 'react';
import css from './patient-title-area.module.scss';
import { PageBreadcrumbs } from 'components/Page';
import classes from 'classnames';
import { Button } from 'components/Button';
import { IPatient } from 'common/interfaces/personaBuilder/IPatient';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { modalActionCreators } from 'stores/actions/modalActions';
import { IModal } from 'common/interfaces';

interface IPatientTitleAreaReduxProps {
	saveDisabled: boolean
}

interface IPatientTitleArea {
	// saveAction: any;
	patient: IPatient;
}

const actionCreators = {
	...PersonaBuilderActionCreators,
	...modalActionCreators
};

const exportPersonaModalContent = () => {
	return (
		<p>
			Are you sure you want to export this persona?
			<br />
		</p>
	);
};


class PatientTitleAreaBase extends React.PureComponent<IPatientTitleArea & 
	ResolveThunks<typeof actionCreators> & 
	IPatientTitleAreaReduxProps> {
	
	exportPatientAction = () => {
		this.props.exportPatient(this.props.patient);
	};

	render() {
		const exportPersonaModal: IModal = {
			title: 'Export Persona',
			content: exportPersonaModalContent,
			primaryButtonLabel: 'Export Persona',
			primaryButtonAction: this.exportPatientAction
		};
		
		const {
			// saveAction,
			patient,
			createModal,
			saveDisabled
		} = this.props;

		return (
			<div
				className={classes(css.pageTitleArea, css.center)}
				role='banner'
			>
				<div>
					<h1
						className={classes('h2', css.title)}
						id='skipNavLocation'
					>
						{patient.first_name} {patient.last_name}
					</h1>
					<PageBreadcrumbs breadcrumbItems={[
						{
							label: 'Creation Dashboard',
							location: '/persona-builder'
						},
						{
							label: 'All Patient Personas',
							location: `/persona-builder/patient-listing`
						},
						{
							label: `${patient.first_name} ${patient.last_name}`,
							location: `/persona-builder/patient-listing/${patient.id}`
						}
					]} />
				</div>

				<ul className='reset'>
					<li style={{display: 'inline', padding: 12}}>
						<Button
							onClick={event => {
								createModal(exportPersonaModal, event);
							}}
							style={'link'}
						>
							Export to JSON
						</Button>
					</li>

				</ul>
			</div>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IPatientTitleAreaReduxProps => {
	return {
		saveDisabled: state.personaBuilder.saveDisabled
	};
};

export const PatientTitleArea = connect(
	mapStateToProps,
	actionCreators
)(PatientTitleAreaBase);
