import * as React from 'react';
import personaActions from 'stores/actionInterfaces/personaActionInterfaces';
import { AppThunkAction } from 'stores';

import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { EUpdateState } from 'stores/shared/ENums';
import { url } from 'settings';
import { IPersona } from 'common/interfaces';
import { modalActionCreators } from 'stores/actions/modalActions';
import { DateTimePicker } from '@material-ui/pickers';
import { messageActionCreators } from './messageActions';
import { sharedHistory } from '../../sharedStore';

export const personaActionCreators = {
	getPersonas: (): AppThunkAction<personaActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'GET_PERSONAS_REQUEST' });
			const headers = await getCurrentAuthHeaders('json');

			try {
				const getPersonasRequest = await fetch(`${url}persona`, {
					headers
				});

				const personas: IPersona[] = (await getPersonasRequest.json()).map(persona => {
					return { ...persona, dob: new Date(persona.dob) };
				});

				dispatch({
					type: 'GET_PERSONAS_RECEIVED',
					personas: personas
				});
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	getPersona: (persona_id: number): AppThunkAction<personaActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'GET_PERSONA_REQUEST' });
			const headers = await getCurrentAuthHeaders('json');

			try {
				const getPersonaRequest = await fetch(`${url}persona/${persona_id}`, {
					headers
				});

				let personaData = await getPersonaRequest.json();
				const persona: IPersona = {
					...personaData,
					dob: new Date(personaData.dob)
				};

				dispatch({
					type: 'GET_PERSONA_RECEIVED',
					persona: persona
				});
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	applyPersona: (personaId: number, rofIds: number[], skipCheck: boolean): AppThunkAction<personaActions> => {
		return async (dispatch, getState) => {
			dispatch({
				type: 'APPLY_PERSONA_REQUEST',
				personaId: personaId,
				rofIds: rofIds
			});

			try {
				let success: boolean = true;
				if (!skipCheck) {
					for (let rofId of rofIds) {
						const headers = await getCurrentAuthHeaders();
						const checkPersonaRequest = await fetch(
							`${url}rof/${rofId}/is_persona_applied?persona_id=${personaId}`,
							{
								headers
							}
						);
						let checkPersonaResult = await checkPersonaRequest.json();

						if (checkPersonaResult.is_applied) {
							success = false;
							dispatch({
								type: 'APPLY_PERSONA_RECEIVED',
								personaId: personaId,
								rofIds: rofIds,
								updateState: EUpdateState.Failed,
								errorMessage: `Persona already applied to RoF ID ${rofId}.`
							});

							dispatch(
								//@ts-ignore
								modalActionCreators.createModal(
									{
										title: `Add Persona`,
										content: props =>
											React.createElement(
												'div',
												null,
												'The persona has already been added to one or more of the selected rings. Would you like to add the persona again? Some data loss/modification may occur.'
											),
										primaryButtonLabel: 'Yes',
										primaryButtonAction: () => {
											dispatch(
												//@ts-ignore=
												modalActionCreators.dismissModal()
											);
											dispatch(
												//@ts-ignore
												personaActionCreators.applyPersona(personaId, rofIds, true)
											);
										},
										secondaryButtonLabel: 'No',
										secondaryButtonAction: () => {
											dispatch(
												//@ts-ignore

												modalActionCreators.dismissModal()
											);
										}
									},
									null
								)
							);
							break;
						}
					}
				}

				if (success) {
					for (let rofId of rofIds) {
						const headers = await getCurrentAuthHeaders('json');
						const applyPersonaRequest = await fetch(`${url}rof/${rofId}/apply_persona`, {
							method: 'POST',
							headers,
							body: JSON.stringify({ persona_id: personaId })
						});

						if (applyPersonaRequest.status == 204) {
							dispatch({
								type: 'APPLY_PERSONA_RECEIVED',
								personaId: personaId,
								rofIds: rofIds,
								updateState: EUpdateState.FinishedOk,
								errorMessage: null
							});
						} else if (applyPersonaRequest.status == 404) {
							dispatch({
								type: 'APPLY_PERSONA_RECEIVED',
								personaId: personaId,
								rofIds: rofIds,
								updateState: EUpdateState.Failed,
								errorMessage: `RoF ID ${rofId} was not found.`
							});
						} else {
							let applyPersonaResult = await applyPersonaRequest.json();
							if ('errorMessage' in applyPersonaResult) {
								dispatch({
									type: 'APPLY_PERSONA_RECEIVED',
									personaId: personaId,
									rofIds: rofIds,
									updateState: EUpdateState.Failed,
									errorMessage: applyPersonaResult.errorMessage
								});
							} else {
								dispatch({
									type: 'APPLY_PERSONA_RECEIVED',
									personaId: personaId,
									rofIds: rofIds,
									updateState: EUpdateState.Failed,
									errorMessage: 'RoF application failed.'
								});
							}
						}
					}
				}
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	clearApplyPersona: (): AppThunkAction<personaActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'CLEAR_APPLY_PERSONA' });
		};
	},
	deletePersona: (personaId: number): AppThunkAction<any> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'DELETE_PERSONA_REQUEST' });
			const headers = await getCurrentAuthHeaders('json');
			const fetchUrl = `${url}persona/${personaId}`;
			try {
				const deletePersonaRequest = await fetch(fetchUrl, {
					method: 'delete',
					headers
				});

				dispatch({
					type: 'DELETE_PERSONA_RECEIVED',
					personaId
				});

				dispatch(modalActionCreators.dismissModal());
				sharedHistory.push(`/persona`);
			} catch (e) {
				console.log(e);
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	}
};
