import * as React from 'react';
import css from './code.module.scss';
import classes from 'classnames';
import { copy } from 'common/utils/copy';
import dompurify from 'dompurify';

interface ICodeProps {
	code: string;
}

export const Code: React.FC<ICodeProps> = props => {
	const { code } = props;

	let sanitizedCode;
	if (code) {
		const htmlEntitiesCode = code
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;');

		const formattedCode = htmlEntitiesCode.replace(
			/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
			function(match) {
				var cls = 'number';
				if (/^"/.test(match)) {
					if (/:$/.test(match)) {
						cls = 'key';
					} else {
						cls = 'string';
					}
				} else if (/true|false/.test(match)) {
					cls = 'boolean';
				} else if (/null/.test(match)) {
					cls = 'null';
				}
				return '<span class="' + cls + '">' + match + '</span>';
			}
		);

		sanitizedCode = dompurify.sanitize(formattedCode);
	}

	let handleCopyToClipboardClick = () => copy(code);

	return (
		<>
			{code && (
				<div className={css.codeContainer}>
					<div className={css.codeBody}>
						<pre className={css.codeContent} dangerouslySetInnerHTML={{ __html: sanitizedCode }} />
					</div>
					<button
						className={classes('buttonLink', css.copyToClipboardButton)}
						onClick={handleCopyToClipboardClick}
					>
						Copy
					</button>
				</div>
			)}
		</>
	);
};
