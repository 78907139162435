import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { debounce } from 'debounce';
import { IIsCodeUnique } from 'common/interfaces/IIsCodeUnique';
import { withRouter } from 'react-router-dom';
import { sharedHistory } from 'sharedStore';
import { errorMessages } from 'common/data/errorMessages';
import { TSetErrorMessage } from 'common/types/TSetErrorMessage';
import { messageActionCreators } from 'stores/actions/messageActions';
import { EUpdateState } from 'stores/shared/ENums';

export const validateCodeIsUnique = async (
	url: string,
	setState: (any) => void,
	forceUpdate: () => void,
	callback: () => void,
	setErrorMessage: TSetErrorMessage,
	includesAuthorization: boolean = true
) => {
	setState({
		codeLoadingState: EUpdateState
	});
	try {
		console.log('includesAuthorization 1', includesAuthorization);
		const headers = await getCurrentAuthHeaders('json', includesAuthorization);
		const isRofCodeUsedRequest = await fetch(url, {
			headers
		});

		const isRofCodeUsedRequestResponse = await isRofCodeUsedRequest.json();

		console.log(isRofCodeUsedRequestResponse);

		setState({
			hasCodeInitiallyLoaded: true,
			codeLoadingState: EUpdateState.Loaded,
			isCodeUnique: !isRofCodeUsedRequestResponse.code_used
		});
		if (callback) {
			callback();
			forceUpdate();
		}
	} catch (error) {
		console.log('error', error);
		// setErrorMessage(errorMessages.general);
		// //@ts-ignore
		// messageActionCreators.createMessage(error);
	}
};

export const debouncedValidateCodeIsUnique = debounce(validateCodeIsUnique, 500);
