import * as yup from 'yup';
import { validatePhoneNumber } from 'common/utils/validatePhoneNumber';

interface IPatientAddressValidationSchemaConfig {
}

interface PatientAddressValidationSchema {
	street: string;
	city: string;
	state: string;
	zip: string;
	country: string;
	patient_address_use_id: number;
	patient_address_type_id: number;
}

export const patientAddressValidationSchema = (
	patientAddressValidationSchemaConfig: IPatientAddressValidationSchemaConfig = {}
) => {
	let schemaDefinition = {
		street: yup.string().required('Street is required'),
		city: yup.string().required('City is required'),
		state: yup.string().required('State is required'),
		zip: yup.string().required('Zip is required'),
		country: yup.string().required('Country is required'),
		patient_address_use_id: yup.number().typeError('Address Use is invalid').required(),
		patient_address_type_id: yup.number().typeError('Address Type is invalid').required()
	};

	return yup.object().shape<any>(schemaDefinition) as any;
};
