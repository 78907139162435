import { Reducer } from 'redux';

import AuthActions from 'stores/actionInterfaces/authActionInterfaces';
import { AuthState } from 'stores/stateInterfaces/authStateInterface';

import { EUpdateState } from 'stores/shared/ENums';
import { normalizeUser } from 'common/utils/normalizeUser';

const initialState: AuthState = {
	authError: null,
	user: null,
	passwordResetPromise: null,
	completeNewPasswordPromise: null,
	isTermsAccepted: null,
	isTermsOutdated: null,
	newPasswordRequired: null,
	userUpdateState: EUpdateState.Empty,
	termsUpdateState: EUpdateState.Empty,
	termsAcceptUpdateState: EUpdateState.Empty,
	terms: null,
	userType: null,
	newPasswordRequiredError: null,
	passwordResetError: null,
	forgotPasswordError: null,
	loginError: null,
	organization: null,
	getOrganizationLoadingState: EUpdateState.Empty,
	userBackupAllotment: 2,
	entitlementSummaryLoadingState: EUpdateState.Empty,
	entitlementSummary: null,
	entitlementsLoadingState: EUpdateState.Empty,
	entitlements: null,
	userOrganizations: [],
};

export const AuthReducers: Reducer<AuthState> = (state: AuthState, action: AuthActions) => {
	switch (action.type) {
		case 'LOGIN_ERROR': {
			return {
				...state,
				loginError: action.loginError
			};
		}

		case 'LOGIN_SUCCESS': {
			return {
				...state,
				user: normalizeUser(action.user)
			};
		}

		case 'NEW_PASSWORD_REQUIRED': {
			return {
				...state,
				user: normalizeUser(action.user)
			};
		}

		case 'NEW_PASSWORD_REQUIRED_ERROR': {
			return {
				...state,
				newPasswordRequiredError: action.message
			};
		}

		case 'FORGOT_PASSWORD_INITIATED': {
			return {
				...state
			};
		}

		case 'FORGOT_PASSWORD_ERROR': {
			return {
				...state,
				forgotPasswordError: 'Please enter a valid email address'
			};
		}

		case 'PASSWORD_RESET_ERROR': {
			return { ...state, passwordResetError: action.passwordResetError };
		}

		case 'COMPLETED_NEW_PASSWORD_ERROR': {
			return { ...state, authError: 'Please enter a valid password' };
		}

		case 'PASSWORD_RESET': {
			return {
				...state,
				passwordResetPromise: true
			};
		}

		case 'UPDATE_PASSWORD': {
			return {
				...state
			};
		}

		case 'SIGN_OUT_SUCCESS': {
			return {
				...state,
				user: null
			};
		}

		case 'SIGN_UP_SUCCESS': {
			return {
				...state,
				authError: null
			};
		}

		case 'SIGN_UP_ERROR': {
			return {
				...state,
				authError: action.error.message
			};
		}

		case 'TERMS_STATUS_REQUEST': {
			return {
				...state,
				termsUpdateState: EUpdateState.Loading
			};
		}

		case 'TERMS_STATUS_RECEIVED': {
			return {
				...state,
				isTermsAccepted: action.isTermsAccepted,
				isTermsOutdated: action.isTermsOutdated,
				termsUpdateState: EUpdateState.Loaded,
				terms: action.terms
			};
		}

		case 'ACCEPT_TERMS_OF_SERVICE_REQUEST': {
			return {
				...state,
				termsAcceptUpdateState: EUpdateState.Loading
			};
		}

		case 'ACCEPT_TERMS_OF_SERVICE_RECEIVED': {
			return {
				...state,
				isTermsAccepted: true,
				isTermsOutdated: false,
				termsAcceptUpdateState: EUpdateState.Loaded
			};
		}

		case 'GET_AUTH_REQUEST': {
			return {
				...state,
				userUpdateState: EUpdateState.Loading
			};
		}

		case 'GET_AUTH_RECEIVED': {
			return {
				...state,
				user: normalizeUser(action.user),
				userUpdateState: EUpdateState.Loaded
			};
		}

		case 'GET_ORGANIZATION_REQUEST': {
			return {
				...state,
				getOrganizationLoadingState: EUpdateState.Loading
			};
		}

		case 'GET_ORGANIZATION_RECEIVED': {
			return {
				...state,
				organization: action.organization,
				getOrganizationLoadingState: EUpdateState.Loaded
			};
		}

		case 'GET_USER_ORGANIZATIONS_REQUEST': {
			return {
				...state,
			};
		}
		case 'GET_USER_ORGANIZATIONS_RECEIVED': {
			const userOrganizations = action.userOrganizations

			return {
				...state,
				userOrganizations: userOrganizations,
			};
		}

		case 'GET_ENTITLEMENT_SUMMARY_REQUEST': {
			return {
				...state,
				entitlementSummaryLoadingState: EUpdateState.Loading
			};
		}

		case 'GET_ENTITLEMENT_SUMMARY_RECEIVED': {
			return {
				...state,
				entitlementSummaryLoadingState: EUpdateState.Loaded,
				entitlementSummary: action.entitlementSummary
			};
		}

		case 'REMOVE_USER_FROM_AUTH_STATE': {
			return {
				...state,
				user: null
			};
		}

		case 'GET_ENTITLEMENTS_REQUEST': {
			return {
				...state,
				entitlementsLoadingState: EUpdateState.Loading
			};
		}

		case 'GET_ENTITLEMENTS_RECEIVED': {
			return {
				...state,
				entitlements: action.entitlements,
				entitlementsLoadingState: EUpdateState.Loaded
			};
		}

		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state || initialState;
};
