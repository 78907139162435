import * as React from "react";
import io from "socket.io-client";
import { AppFrame, InnerContainer, PageTitleArea } from "components/Page";
import { clone } from "ramda";
import { rofActionCreators } from "stores/actions/rofActions";
import { connect, ResolveThunks } from "react-redux";
import { ApplicationState } from "stores";
import { url } from "settings";
import { getCurrentJwt } from "common/utils/getCurrentJwt";

interface IWebsocketViewState {
	messages: string[];
}

interface IWebsocketView {
	orgCode: string;
}

class WebsocketViewBase extends React.PureComponent<
	IWebsocketView & ResolveThunks<typeof rofActionCreators>,
	IWebsocketViewState
> {
	socket;

	constructor(props) {
		super(props);

		this.state = {
			messages: []
		};
	}

	getTerms = async () => {
		const terms = await fetch("http://localhost:5000/terms_of_svc");
		const termsContent = await terms.json();
		console.log("termsContent", termsContent);
	};

	connectToWebsocket = () => {
		// this.socket = io('http://localhost:5000');
		this.socket = io(url);

		this.socket.on("connect", async () => {
			console.log("connected");
			this.socket.send("User has connected!");
			const JWT = await getCurrentJwt();
			this.socket.emit("join", JWT);
		});

		this.socket.on("notification", data => {
			console.log("data", data);
			const messages = clone(this.state.messages);
			messages.push(data.request);
			if (data.request === "toggleSpinners") {
				this.props.toggleSpinners();
			}
			this.setState({
				messages
			});
		});

		this.socket.on("iol", data => {
			console.log("data", data);
			console.log(JSON.parse(data));
			const stackUpdate = JSON.parse(data);
			const messages = clone(this.state.messages);
			messages.push(`Org Code: ${stackUpdate.org_code}`);
			messages.push(`Sandbox Code: ${stackUpdate.sandbox_code}`);
			messages.push(`Resource Status ${stackUpdate.resource_status}`);
			messages.push("");
			this.setState({
				messages
			});
		});

		this.socket.on("sandboxStatus", data => {
			console.log("data", data);
			console.log("sandboxStatus");
			console.log(JSON.parse(data));
			const stackUpdate = JSON.parse(data);
			const messages = clone(this.state.messages);
			messages.push(`Org Code: ${stackUpdate.org_code}`);
			messages.push(`Sandbox Code: ${stackUpdate.sandbox_code}`);
			messages.push(`Resource Status ${stackUpdate.resource_status}`);
			messages.push("");
			this.setState({
				messages
			});
		});

		this.socket.on("message", data => {
			console.log("data", data);
			console.log(JSON.parse(data));
			const stackUpdate = JSON.parse(data);
			const messages = clone(this.state.messages);
			messages.push(`Org Code: ${stackUpdate.org_code}`);
			messages.push(`Sandbox Code: ${stackUpdate.sandbox_code}`);
			messages.push(`Resource Status ${stackUpdate.resource_status}`);
			messages.push("");
			this.setState({
				messages
			});
		});

		this.socket.on("disconnect", () => {
			console.log("disconnect");
		});
	};

	componentDidMount() {
		this.getTerms();
		if (this.props.orgCode) {
			this.connectToWebsocket();
		}
	}

	UNSAFE_componentWillUpdate(nextProps) {
		if (this.props.orgCode !== nextProps.orgCode) {
			this.connectToWebsocket();
		}
	}

	toggleSidebarStatus = () => {
		this.socket.send("toggle");
	};

	render() {
		return (
			<AppFrame>
				<InnerContainer>
					<PageTitleArea
						buttonList={[
							{
								buttonLabel: "Toggle Spinners",
								buttonAction: this.toggleSidebarStatus
							}
						]}
					>
						Notifications
					</PageTitleArea>
					<ul>
						{this.state.messages.map((messege, index) => (
							<li key={index}>{messege}</li>
						))}
					</ul>
				</InnerContainer>
			</AppFrame>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IWebsocketView => ({
	orgCode: state.auth.organization ? state.auth.organization.code : null
});

export const WebsocketView = connect(
	mapStateToProps,
	rofActionCreators
)(WebsocketViewBase);
