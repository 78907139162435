import * as React from 'react';
import { Field, Formik } from 'formik';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { Grid, Button } from '@material-ui/core';
import { MuiButton } from 'components/MaterialUI/index';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ErrorFocus } from 'components/ErrorFocus';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { convertToISODateString } from 'common/utils/convertToISODateString';
import { getDayDiffBetweenDates } from 'common/utils/getDayDiffBetweenDates';
import { TSetFormLoadingAttribute } from 'common/types/TSetFormLoadingAttribute';
import { TSetErrorMessage } from 'common/types/TSetErrorMessage';

import { patientCoverageValidationSchema } from 'common/validation-schema/personaBuilder/patientCoverageValidationSchema';
import css from './form-patient-coverage-renewal.module.scss';
import classes from 'classnames';
import { DatePickerField } from 'components/FormElements/DatePickerField'

interface IFormPatientCoverageRenewalState {
  isLoading: boolean;
  initialValues: any;
  coverageLengthLimitError: boolean;
}

interface IFormPatientCoverageRenewalProps {
  cancelModalAction?: any;
  deleteAction?: any;
  defaultFormContent?: any;
  formAction?: any;
  bindSubmitForm?: (any) => void;
  setFormLoadingAttribute?: TSetFormLoadingAttribute;
  setErrorMessage?: TSetErrorMessage;
}

export class FormPatientCoverageRenewalBase extends React.PureComponent<
  ResolveThunks<typeof PersonaBuilderActionCreators> &
  IFormPatientCoverageRenewalProps,
  IFormPatientCoverageRenewalState> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      coverageLengthLimitError: false,
      initialValues: {
        organization_name: '',
        coverage_type: '',
        coverage_type_code: '',
        coverage_period_start: null,
        coverage_period_end: null
      }
    };
  }

  setFormLoadingAttribute = (isLoading: boolean) => {
    this.setState({
      isLoading
    });
  };

  handleFormSubmit = (values, setSubmitting) => {
    if (getDayDiffBetweenDates(values.coverage_period_start, values.coverage_period_end) > 366) {
      this.setState({ coverageLengthLimitError: true })
      return false;
    };

    const { formAction, setErrorMessage } = this.props;
    this.setFormLoadingAttribute(true);
    if (formAction) {
      values.coverage_period_start = convertToISODateString(values.coverage_period_start, true);
      values.coverage_period_end = convertToISODateString(values.coverage_period_end, true);

      formAction(trimAllStringProperties(values), setErrorMessage, this.setFormLoadingAttribute);
    }
    setSubmitting(false);
  };

  render() {
    let { setFormLoadingAttribute } = this.props;
    const {
      bindSubmitForm,
      formAction,
      defaultFormContent,
      setErrorMessage,
      setSaveDisabled,
      cancelModalAction,
      deleteAction
    } = this.props;

    const isEditMode = defaultFormContent !== null;

    if (isEditMode) {
      this.setState({ initialValues: defaultFormContent });
    }
    if (!setFormLoadingAttribute) {
      setFormLoadingAttribute = this.setFormLoadingAttribute;
    }

    return (
      <Formik
        initialValues={this.state.initialValues}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => this.handleFormSubmit(values, setSubmitting)}
        validationSchema={patientCoverageValidationSchema()}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            submitForm,
            isValid
          } = props;
          if (bindSubmitForm) {
            bindSubmitForm(submitForm);
          }
          return (
            <form onSubmit={handleSubmit}>
              <Grid container className={css.formContainer}>
                <Grid item xs={12}>
                  <h4>Renewing Coverage:</h4>
                </Grid>
                <Grid item xs={6}>
                  <p>{values.organization_name}</p>
                </Grid>
                <Grid item xs={6}>
                  <p className={css.coverageLabel}>{values.coverage_type} ({values.coverage_type_code})</p>
                </Grid>
                <Grid item xs={12}>
                  <p>Verify the new start and end dates for the renewed coverage.</p>
                </Grid>
                <Grid container>
                  <Grid item xs={6} className={css.gridItem}>
                    <label>Start Date</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Field
                        name='coverage_period_start'
                        value={values.coverage_period_start}
                        component={DatePickerField}
                        className={
                          classes(css.dateOfBirthPicker, 'mtl-picker-input', { 'error': errors.coverage_period_start && touched.coverage_period_start })
                        }
                        onBlur={handleBlur}
                      />
                    </MuiPickersUtilsProvider>
                    {errors.coverage_period_start && touched.coverage_period_start && (
                      <div className='input-feedback'>{errors.coverage_period_start}</div>
                    )}
                  </Grid>
                  <Grid item xs={6} className={css.gridItem}>
                    <label>End Date</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Field
                        name='coverage_period_end'
                        value={values.coverage_period_end}
                        component={DatePickerField}
                        className={
                          classes(css.dateOfBirthPicker, 'mtl-picker-input', { 'error': errors.coverage_period_end && touched.coverage_period_end })
                        }
                        onBlur={handleBlur}
                      />
                    </MuiPickersUtilsProvider>
                    {errors.coverage_period_end && touched.coverage_period_end && (
                      <div className='input-feedback'>{errors.coverage_period_end}</div>
                    )}
                    {this.state.coverageLengthLimitError &&
                      <div className='input-feedback'>Coverage cannot be longer than one year.</div>
                    }
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                  <MuiButton variant="contained" onClick={() => handleSubmit()}>Renew Coverage</MuiButton>
                </Grid>
                <ErrorFocus />
              </Grid>
            </form>
          );
        }}
      </Formik>
    )
  }

}

const mapStateToProps = (state: ApplicationState) => ({});

export const FormPatientCoverageRenewal = connect(
  mapStateToProps,
  PersonaBuilderActionCreators
)(FormPatientCoverageRenewalBase);