import { RofState } from 'stores/stateInterfaces/rofStateInterface';

export const isRofBusy = (rof: RofState, rofId: number): boolean => {
	const { allRof } = rof;
	if (
		allRof[rofId] &&
		(allRof[rofId].operation_status === 'iol-db-backup-start' ||
			allRof[rofId].operation_status === 'iol-db-reset-start' ||
			allRof[rofId].operation_status === 'iol-db-restore-start')
	) {
		return true;
	}

	const { stack_status } = rof.allRof[rofId];

	if (
		stack_status === 'CREATE_QUEUED' ||
		stack_status === 'CREATE_IN_PROGRESS' ||
		stack_status === 'ROLLBACK_IN_PROGRESS' ||
		stack_status === 'UPDATE_IN_PROGRESS' ||
		//todo: Next line is just for demo on 10/22
		rof.isSpinnersActive
	) {
		return true;
	}
	return false;
};
