import * as React from 'react';
import { Field, Formik } from 'formik';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { ErrorFocus } from 'components/ErrorFocus';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { Grid, Divider } from '@material-ui/core';
import { Button } from 'components/Button';
import { Delete, Warning } from '@material-ui/icons';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { TSetFormLoadingAttribute } from 'common/types/TSetFormLoadingAttribute';
import { TSetErrorMessage } from 'common/types/TSetErrorMessage';
import { FormLabel } from 'components/FormElements';
import { SegmentedController } from 'components/FormElements';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { removePhoneNumberFormatting } from 'common/utils/removePhoneNumberFormatting';
import { PhoneNumberField } from 'components/FormElements/PhoneNumberField/PhoneNumberField';
import { patientFamilyMemberValidationSchema } from 'common/validation-schema/personaBuilder/patientFamilyMemberValidationSchema';
import css from './form-patient-family-member.module.scss';
import classes from 'classnames';
import { IsFormDirty } from 'components/RouteGuards/IsFormDirty';
import { DatePickerField } from 'components/FormElements/DatePickerField'

interface IFormPatientFamilyMemberState {
  isLoading: boolean;
  confirmDelete: boolean;
  initialValues: any;
}

interface IFormPatientFamilyMemberFormProps {
  cancelModalAction?: any;
  deleteAction?: any;
  defaultFormContent?: any;
  formAction?: any;
  bindSubmitForm?: (any) => void;
  setFormLoadingAttribute?: TSetFormLoadingAttribute;
  setErrorMessage?: TSetErrorMessage;
  forPatientFamilyHistoryTab: boolean;
}

interface IFormPatientFamilyMemberReduxProps {
  marital_statuses: any;
  ethnicities: any;
  genders: any;
  races: any;
  languages: any;
  relationships: any;
  patients: any;
}

export class FormPatientFamilyMemberBase extends React.PureComponent<
  ResolveThunks<typeof PersonaBuilderActionCreators> &
  IFormPatientFamilyMemberFormProps &
  IFormPatientFamilyMemberReduxProps,
  IFormPatientFamilyMemberState> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      confirmDelete: false,
      initialValues: {
        first_name: '',
        middle_name: '',
        last_name: '',
        dob: null,
        home_phone: '',
        mobile_phone: '',
        marital_status_id: '',
        ethnicity_id: '',
        gender_id: '',
        race_id: '',
        language_id: '',
        patient_id: '',
        patient_relationship_id: '',
        deceased_flag: 'false'
      }
    };
  }

  setFormLoadingAttribute = (isLoading: boolean) => {
    this.setState({
      isLoading
    });
  };

  setDeleteConfirmation = (confirmDelete: boolean) => {
    this.setState({
      confirmDelete
    });
  };

  handleFormSubmit = (values, setSubmitting, resetForm) => {
    const { formAction, setErrorMessage } = this.props;
    this.setFormLoadingAttribute(true);
    if (formAction) {
      const trimmedValues = trimAllStringProperties(values);
      formAction(trimmedValues, setErrorMessage, this.setFormLoadingAttribute);
      resetForm(trimmedValues);
    }
    setSubmitting(false);
  };

  render() {
    let setDeleteConfirmation = this.setDeleteConfirmation;
    let { setFormLoadingAttribute } = this.props;
    const {
      bindSubmitForm,
      formAction,
      defaultFormContent,
      setErrorMessage,
      marital_statuses,
      ethnicities,
      genders,
      races,
      languages,
      patients,
      relationships,
      setSaveDisabled,
      cancelModalAction,
      deleteAction,
      forPatientFamilyHistoryTab
    } = this.props;

    const isEditMode = !!defaultFormContent.id;

    if (isEditMode) {
      defaultFormContent.home_phone = removePhoneNumberFormatting(defaultFormContent.home_phone);
      defaultFormContent.mobile_phone = removePhoneNumberFormatting(defaultFormContent.mobile_phone);

      const relationship = relationships.filter((r) => { return r.patient_relationship_display === defaultFormContent.patient_relationship_display; });
      defaultFormContent.patient_relationship_id = relationship.length > 0 ? relationship[0].id : '';
      defaultFormContent.deceased_flag = defaultFormContent.deceased_flag !== undefined ? defaultFormContent.deceased_flag.toString() : 'false';
    }
    this.setState({ initialValues: defaultFormContent });

    if (!setFormLoadingAttribute) {
      setFormLoadingAttribute = this.setFormLoadingAttribute;
    }

    return (
      <>
        <Formik
          initialValues={this.state.initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting, resetForm }) => this.handleFormSubmit(values, setSubmitting, resetForm)}
          validationSchema={patientFamilyMemberValidationSchema()}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              submitForm,
              isValid
            } = props;
            if (bindSubmitForm) {
              bindSubmitForm(submitForm);
            }
            return (
              <>
                <IsFormDirty
                  title=""
                  message=""
                  dirty={dirty}
                />
                <form onSubmit={handleSubmit}>
                  <Grid container className={css.formContainer}>
                    {!forPatientFamilyHistoryTab ? (
                      <Grid container style={{ marginBottom: '2.5rem' }}>
                        <Grid item xs={12}>
                          <h4>Relationship Information</h4>
                        </Grid>
                        <Grid item xs={6} className={css.formColumn}>
                          <label>Related to Patient</label>
                          <select
                            name='patient_id'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.patient_id}
                            className={errors.patient_id && touched.patient_id ? 'text-input error' : 'text-input'}
                          >
                            <option value={""}>Select</option>
                            {patients.map((patient, index) => (
                              <option key={index} value={patient.id}>
                                {patient.first_name} {patient.last_name}
                              </option>
                            ))}
                          </select>
                          {errors.patient_id && touched.patient_id && (
                            <div className='input-feedback'>{errors.patient_id}</div>
                          )}
                        </Grid>
                        <Grid item xs={6} className={css.formColumn}>
                          <label>Relationship Type</label>
                          <select
                            name='patient_relationship_id'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.patient_relationship_id}
                            className={errors.patient_relationship_id && touched.patient_relationship_id ? 'text-input error' : 'text-input'}
                          >
                            <option value={""}>Select</option>
                            {relationships.map((relationship, index) => (
                              <option key={index} value={relationship.id}>
                                {relationship.patient_relationship_display} ({relationship.patient_relationship_code})
                              </option>
                            ))}
                          </select>
                          {errors.patient_relationship_id && touched.patient_relationship_id && (
                            <div className='input-feedback'>{errors.patient_relationship_id}</div>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <hr />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container style={{ marginBottom: '2.5rem' }}>
                        <Grid item xs={12} className={css.formColumn}>
                          <label>Relationship Type</label>
                          <select
                            name='patient_relationship_id'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.patient_relationship_id}
                            className={errors.patient_relationship_id && touched.patient_relationship_id ? 'text-input error' : 'text-input'}
                          >
                            <option value={""}>Select</option>
                            {relationships.map((relationship, index) => (
                              <option key={index} value={relationship.id}>
                                {relationship.patient_relationship_display} ({relationship.patient_relationship_code})
                              </option>
                            ))}
                          </select>
                          {errors.patient_relationship_id && touched.patient_relationship_id && (
                            <div className='input-feedback'>{errors.patient_relationship_id}</div>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <hr />
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <h4>Patient</h4>
                    </Grid>
                    <Grid item xs={6} className={css.formColumn}>
                      <Grid item xs={12}>
                        <label>First Name</label>
                        <input
                          name='first_name'
                          placeholder='Enter First Name'
                          type='text'
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={errors.first_name && touched.first_name ? 'text-input error' : 'text-input'}
                          autoComplete='first_name'
                          maxLength={128}
                        />
                        {errors.first_name && touched.first_name && (
                          <div className='input-feedback'>{errors.first_name}</div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <label>Middle Name</label>
                        <input
                          name='middle_name'
                          placeholder='Enter Middle Name'
                          type='text'
                          value={values.middle_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={errors.middle_name && touched.middle_name ? 'text-input error' : 'text-input'}
                          autoComplete='middle_name'
                          maxLength={128}
                        />
                        {errors.middle_name && touched.middle_name && (
                          <div className='input-feedback'>{errors.middle_name}</div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <label>Last Name</label>
                        <input
                          name='last_name'
                          placeholder='Enter Last Name'
                          type='text'
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={errors.last_name && touched.last_name ? 'text-input error' : 'text-input'}
                          autoComplete='last_name'
                          maxLength={128}
                        />
                        {errors.last_name && touched.last_name && (
                          <div className='input-feedback'>{errors.last_name}</div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <label>D.O.B.</label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Field
                            name='dob'
                            value={values.dob}
                            component={DatePickerField}
                            className={
                              classes(css.dateOfBirthPicker, 'mtl-picker-input', { 'error': errors.dob && touched.dob })
                            }
                            onBlur={handleBlur}
                          />
                        </MuiPickersUtilsProvider>
                        {errors.dob && touched.dob && (
                          <div className='input-feedback'>{errors.dob}</div>
                        )}
                      </Grid>
                      <Grid container>
                        <Grid item xs={6} className={css.gridItem}>
                          <label>Home Phone</label>
                          <PhoneNumberField
                            id='home_phone'
                            placeholder='Enter Home Phone Number'
                            value={removePhoneNumberFormatting(values.home_phone)}
                            onChange={handleChange}
                            autocomplete={'home tel'}
                            onBlur={handleBlur}
                            className={
                              errors.home_phone && touched.home_phone ? 'text-input error' : 'text-input'
                            }
                          />
                          {errors.home_phone && touched.home_phone && (
                            <div className='input-feedback'>{errors.home_phone}</div>
                          )}
                        </Grid>
                        <Grid item xs={6} className={css.gridItem}>
                          <label>Mobile Phone</label>
                          <PhoneNumberField
                            id='mobile_phone'
                            placeholder='Enter Mobile Phone Number'
                            value={removePhoneNumberFormatting(values.mobile_phone)}
                            onChange={handleChange}
                            autocomplete={'mobile tel'}
                            onBlur={handleBlur}
                            className={
                              errors.mobile_phone && touched.mobile_phone ? 'text-input error' : 'text-input'
                            }
                          />
                          {errors.mobile_phone && touched.mobile_phone && (
                            <div className='input-feedback'>{errors.mobile_phone}</div>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} className={css.formColumn}>
                      <Grid item xs={12}>
                        <label>Marital Status</label>
                        <select
                          name='marital_status_id'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.marital_status_id}
                          className={errors.marital_status_id && touched.marital_status_id ? 'text-input error' : 'text-input'}
                        >
                          <option value={""}>Select</option>
                          {marital_statuses.map((status, index) => (
                            <option key={index} value={status.id}>
                              {status.marital_status} ({status.marital_status_code})
                            </option>
                          ))}
                        </select>
                        {errors.marital_status_id && touched.marital_status_id && (
                          <div className='input-feedback'>{errors.marital_status_id}</div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <label>Ethnicity</label>
                        <select
                          name='ethnicity_id'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.ethnicity_id}
                          className={errors.ethnicity_id && touched.ethnicity_id ? 'text-input error' : 'text-input'}
                        >
                          <option value={""}>Select</option>
                          {ethnicities.map((ethnicity, index) => (
                            <option key={index} value={ethnicity.id}>
                              {ethnicity.ethnicity} ({ethnicity.ethnicity_code})
                            </option>
                          ))}
                        </select>
                        {errors.ethnicity_id && touched.ethnicity_id && (
                          <div className='input-feedback'>{errors.ethnicity_id}</div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <label>Gender</label>
                        <select
                          name='gender_id'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.gender_id}
                          className={errors.gender_id && touched.gender_id ? 'text-input error' : 'text-input'}
                        >
                          <option value={""}>Select</option>
                          {genders.map((gender, index) => (
                            <option key={index} value={gender.id}>
                              {gender.gender}
                            </option>
                          ))}
                        </select>
                        {errors.gender_id && touched.gender_id && (
                          <div className='input-feedback'>{errors.gender_id}</div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <label>Race</label>
                        <select
                          name='race_id'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.race_id}
                          className={errors.race_id && touched.race_id ? 'text-input error' : 'text-input'}
                        >
                          <option value={""}>Select</option>
                          {races.map((race, index) => (
                            <option key={index} value={race.id}>
                              {race.race}
                            </option>
                          ))}
                        </select>
                        {errors.race_id && touched.race_id && (
                          <div className='input-feedback'>{errors.race_id}</div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <label>Language</label>
                        <select
                          name='language_id'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.language_id}
                          className={errors.language_id && touched.language_id ? 'text-input error' : 'text-input'}
                        >
                          <option value={""}>Select</option>
                          {languages.map((language, index) => (
                            <option key={index} value={language.id}>
                              {language.language} ({language.language_code})
                            </option>
                          ))}
                        </select>
                        {errors.language_id && touched.language_id && (
                          <div className='input-feedback'>{errors.language_id}</div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <hr />
                    </Grid>
                    <Grid container xs={12} direction={'row'} alignItems={'center'} style={{ marginTop: '2.5rem' }}>
                      <FormLabel style={css.label} htmlFor='deceased_flag'>Is the patient deceased?</FormLabel>
                      <SegmentedController
                        options={[{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }]}
                        values={values.deceased_flag} name={'deceased_flag'} />
                    </Grid>
                    {forPatientFamilyHistoryTab &&
                      <Grid item xs={12} className={css.formColumn} style={{ textAlign: 'right' }}>
                        <Button type="button" style="link" className={css.cancelButton} onClick={cancelModalAction}>Cancel</Button>
                        <Button type="submit" className={css.saveButton}>Continue</Button>
                      </Grid>
                    }
                    <ErrorFocus />
                  </Grid>
                </form>
              </>
            );
          }}
        </Formik>
        {this.state.confirmDelete &&
          <Grid container className={css.deleteConfirmationContainer}>
            <Grid item xs={9} md={9}>
              <div className={css.deleteConfirmationMessage}><Warning /> Are you sure you want to delete <strong>{this.state.initialValues.first_name} {this.state.initialValues.last_name}</strong>?</div>
            </Grid>
            <Grid item xs={3} md={3} style={{ textAlign: 'right' }}>
              <Button type="button" style="link" className={css.cancelButton} onClick={() => this.setDeleteConfirmation(false)}>Cancel</Button>
              <Button type="button" className={css.deleteButton} onClick={deleteAction}>Confirm Deletion</Button>
            </Grid>
          </Grid>
        }
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState): IFormPatientFamilyMemberReduxProps => ({
  marital_statuses: state.personaBuilder.dropdowns.marital_statuses,
  ethnicities: state.personaBuilder.dropdowns.ethnicities,
  genders: state.personaBuilder.dropdowns.genders,
  races: state.personaBuilder.dropdowns.races,
  languages: state.personaBuilder.dropdowns.languages,
  relationships: state.personaBuilder.dropdowns.relationships,
  patients: state.personaBuilder.patients.filter((f) => { return f.persona_story_flag; })
});

export const FormPatientFamilyMember = connect(mapStateToProps, PersonaBuilderActionCreators)(FormPatientFamilyMemberBase);