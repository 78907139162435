import * as yup from 'yup';
import { validatePhoneNumber } from 'common/utils/validatePhoneNumber';

interface IPatientAllergyValidationSchemaConfig {
}

interface PatientAllergyValidationSchema {
  allergy_intolerance_name: string;
  clinical_status: string;
  allergy_category_id: number;
  allergy_severity_id: number;
  allergy_critical_value_id: number;
  onset_date: string;
  onset_age: string;
  allergy_reaction_id: number;
  allergy_substance_id: number;
  allergy_exposure_route_id: number;
}

export const patientAllergyValidationSchema = (
	patientAllergyValidationSchemaConfig: IPatientAllergyValidationSchemaConfig = {}
) => {
	let schemaDefinition = {
		allergy_intolerance_name: yup.string().required('Name is required'),
		clinical_status: yup.string().required('Clinical Status is required'),
		onset_date: yup.string().required('Onset Date is required'),
		onset_age: yup.string().required('Onset Age is required'),
		allergy_category_id: yup.number().typeError('Category is invalid.').required('Category is required'),
		allergy_severity_id: yup.number().typeError('Severity is invalid.').required('Severity is required.'),
		allergy_critical_value_id: yup.number().typeError('Criticality is invalid.').required('Criticality is required.'),
		allergy_reaction_id: yup.number().typeError('Reaction ID is invalid.').required('Reaction ID is required.'),
		allergy_substance_id: yup.number().typeError('Substance is invalid.').required('Substance is required.'),
		allergy_exposure_route_id: yup.number().typeError('Exposure Route is invalid.').required('Exposure Route is required.')
	};

	return yup.object().shape<any>(schemaDefinition) as any;
};
