import * as React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { connect, ResolveThunks } from 'react-redux';
import { organizationActionCreators } from 'stores/actions/organizationActions';
import { IModal } from 'common/interfaces/IModal';
import { IModalForm, IOrganization } from 'common/interfaces';
// import css from './organization.module.scss';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { statesList } from 'common/data/states';
import classes from 'classnames';
import NumberFormat from 'react-number-format';
import { PhoneNumberField } from 'components/FormElements/PhoneNumberField';
import { debouncedValidateCodeIsUnique } from 'common/utils/validateCodeIsUnique';
import { url } from 'settings';
import { orgValidationSchema } from 'common/validation-schema/orgValidationSchema';
import { RequiredIndicator } from 'components/FormElements/RequiredIndicator';
import { Button } from 'components/Button';
import { FormLabel } from 'components/FormElements/FormLabel';
import { removePhoneNumberFormatting } from 'common/utils/removePhoneNumberFormatting';
import { RouteComponentProps } from 'react-router';
import { validatePhoneNumber } from 'common/utils/validatePhoneNumber';
import css from './form-add-user-and-organization.module.scss';
import { IFormState } from 'common/interfaces/IFormState';
import { Error } from 'components/Toast/Error';
const queryString = require('query-string');

const formAddUserAndOrganizationActionCreators = {
	...organizationActionCreators,
};

interface IFormAddUserAndOrganizationProps {
	parentOrganizationId?: number;
	customerId?: string;
}

class FormAddUserAndOrganizationBase extends React.PureComponent<
	IModalForm &
		ResolveThunks<typeof formAddUserAndOrganizationActionCreators> &
		IFormAddUserAndOrganizationProps,
	IFormState
> {
	constructor(props) {
		super(props);
		this.state = {
			isDisabled: false,
			isLoading: false,
			errorMessage: null,
		};
	}

	setFormDisabledAttribute = (isDisabled: boolean) => {
		this.setState({
			isDisabled,
		});
	};

	setFormLoadingAttribute = (isLoading: boolean) => {
		this.setState({
			isLoading,
		});
	};

	setErrorMessage = (errorMessage: string) => {
		this.setState({
			errorMessage,
		});
	};

	render() {
		const {
			bindSubmitForm,
			createOrganization,
			defaultFormContent,
			formAction,
			parentOrganizationId,
			setErrorMessage,
			setFormLoadingAttribute,
			createUserAndOrganization,
			customerId,
		} = this.props;
		const isEditMode = defaultFormContent ? true : false;

		let initialValuesBase = {
			name: '',
			username: '',
			phone_number: '',
			email: '',
			descr: '',
			address: '',
			city: '',
			state: '',
			postal_code: '',
			contact_name: '',
			contact_email: '',
			contact_phone: '',
		};

		const isParentOrganization = typeof parentOrganizationId !== 'number';

		let initialValues = { ...initialValuesBase };

		initialValues = defaultFormContent ? defaultFormContent : initialValuesBase;

		const organizationReferenceName = isParentOrganization ? 'organization' : 'suborganization';

		return (
			<>
				{this.state.errorMessage && <Error>{this.state.errorMessage}</Error>}
				<Formik
					initialValues={initialValues}
					onSubmit={(values, { setSubmitting }) => {
						console.log('values', values);

						values.phone_number = `+1${removePhoneNumberFormatting(values.phone_number)}`;

						values.contact_phone = removePhoneNumberFormatting(values.contact_phone);

						console.log('values2', values);

						createUserAndOrganization(trimAllStringProperties(values), customerId, {
							setErrorMessage: this.setErrorMessage,
							setFormDisabledAttribute: this.setFormDisabledAttribute,
							setFormLoadingAttribute: this.setFormLoadingAttribute,
						});

						setSubmitting(false);
					}}
					validationSchema={yup.object().shape({
						username: yup.string().required('Required'),
						email: yup.string().email().required('Required'),
						phone_number: yup.string().test(validatePhoneNumber),
						name: yup.string().required('Required'),
						address: yup.string().required('Required'),
						city: yup.string().required('Required'),
						state: yup.string().required('Required'),
						postal_code: yup
							.number()
							.test('Postal Code', 'Postal code should be 5 characters', (value) => {
								if (value) {
									return value.toString().length === 5;
								} else {
									return false;
								}
							})
							.required('Required'),
						contact_name: yup.string().required('Required'),
						contact_email: yup.string().email().required('Required'),
						contact_phone: yup
							.string()
							.required('Required')
							.test('Phone Number', 'Phone Number is Not Valid', validatePhoneNumber),
					})}
				>
					{(props) => {
						const {
							values,
							touched,
							errors,
							dirty,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit,
							handleReset,
							submitForm,
							validateForm,
						} = props;

						return (
							<form onSubmit={handleSubmit}>
								<h2 className='h3'>User</h2>
								{!isEditMode && (
									<>
										<FormLabel htmlFor='username' isRequired={true}>
											Username
										</FormLabel>
										<input
											id='username'
											placeholder='Enter Username'
											type='text'
											value={values.username}
											onChange={handleChange}
											onBlur={handleBlur}
											className={errors.username && touched.username ? 'text-input error' : 'text-input'}
											autoComplete='username'
										/>
										{errors.username && touched.username && (
											<div className='input-feedback'>{errors.username}</div>
										)}
										<FormLabel htmlFor='email' isRequired={true}>
											Email
										</FormLabel>
										<input
											id='email'
											placeholder='Enter Email'
											type='email'
											value={values.email}
											onChange={handleChange}
											onBlur={handleBlur}
											className={errors.email && touched.email ? 'text-input error' : 'text-input'}
										/>
										{errors.email && touched.email && (
											<div className='input-feedback'>{errors.email}</div>
										)}
									</>
								)}

								<FormLabel htmlFor='phone_number' isRequired={true}>
									Phone Number
								</FormLabel>
								<PhoneNumberField
									id='phone_number'
									placeholder='Enter Phone Number'
									value={removePhoneNumberFormatting(values.phone_number)}
									onChange={handleChange}
									onBlur={handleBlur}
									className={
										errors.phone_number && touched.phone_number ? 'text-input error' : 'text-input'
									}
								/>

								{errors.phone_number && touched.phone_number && (
									<div className='input-feedback'>{errors.phone_number}</div>
								)}
								<h2 className='h3'>Organization</h2>
								<label htmlFor='name'>
									{organizationReferenceName} Name
									<RequiredIndicator />
								</label>
								<input
									id='name'
									placeholder='Enter Name'
									type='text'
									value={values.name}
									onChange={handleChange}
									onBlur={handleBlur}
									className={errors.name && touched.name ? 'text-input error' : 'text-input'}
								/>
								{errors.name && touched.name && <div className='input-feedback'>{errors.name}</div>}
								{isParentOrganization && (
									<>
										<label htmlFor='descr'>{organizationReferenceName} Description</label>
										<input
											id='descr'
											placeholder='Enter Description'
											type='text'
											value={values.descr}
											onChange={handleChange}
											onBlur={handleBlur}
											className={errors.descr && touched.descr ? 'text-input error' : 'text-input'}
										/>
										{errors.descr && touched.descr && (
											<div className='input-feedback'>{errors.descr}</div>
										)}
									</>
								)}
								{isParentOrganization && (
									<>
										<div role='group'>
											<label htmlFor='name'>
												{organizationReferenceName} Address
												<RequiredIndicator />
											</label>
											<input
												id='address'
												placeholder='Enter Address'
												type='text'
												value={values.address}
												onChange={handleChange}
												onBlur={handleBlur}
												className={errors.address && touched.address ? 'text-input error' : 'text-input'}
												autoComplete='street-address'
											/>
											{errors.address && touched.address && (
												<div className='input-feedback'>{errors.address}</div>
											)}
											<div className={css.inputThirdsContainer}>
												<div className={css.inputThirdsItem}>
													<label htmlFor='city' className='screenreader-text'>
														City
													</label>
													<input
														id='city'
														placeholder='Enter City'
														type='text'
														value={values.city}
														onChange={handleChange}
														onBlur={handleBlur}
														className={errors.city && touched.city ? 'text-input error' : 'text-input'}
														autoComplete='address-level2'
													/>
													{errors.city && touched.city && (
														<div className='input-feedback'>{errors.city}</div>
													)}
												</div>
												<div className={css.inputThirdsItem}>
													<label htmlFor='state' className='screenreader-text'>
														State
													</label>
													<select
														id='state'
														value={values.state}
														onChange={handleChange}
														onBlur={handleBlur}
														className={classes(
															errors.state && touched.state ? 'text-input error' : 'text-input',
															{
																default: values.state === '',
															}
														)}
														autoComplete='address-level1'
													>
														<option value='' disabled>
															Select State
														</option>
														{statesList.map((state, index) => (
															<option key={index} value={state.abbreviation}>
																{state.name}
															</option>
														))}
													</select>

													{errors.state && touched.state && (
														<div className='input-feedback'>{errors.state}</div>
													)}
												</div>
												<div className={css.inputThirdsItem}>
													<label htmlFor='postal_code' className='screenreader-text'>
														Zip Code
													</label>
													<NumberFormat
														id='postal_code'
														placeholder='Enter Zip'
														value={values.postal_code}
														onChange={handleChange}
														onBlur={handleBlur}
														className={
															errors.postal_code && touched.postal_code
																? 'text-input error'
																: 'text-input'
														}
														format='#####'
													/>
													{errors.postal_code && touched.postal_code && (
														<div className='input-feedback'>{errors.postal_code}</div>
													)}
												</div>
											</div>
										</div>

										<label htmlFor='contact_name'>
											Main Contact Name
											<RequiredIndicator />
										</label>
										<input
											id='contact_name'
											placeholder='Enter Contact Name'
											type='text'
											value={values.contact_name}
											onChange={handleChange}
											onBlur={handleBlur}
											className={
												errors.contact_name && touched.contact_name ? 'text-input error' : 'text-input'
											}
											autoComplete='name'
										/>
										{errors.contact_name && touched.contact_name && (
											<div className='input-feedback'>{errors.contact_name}</div>
										)}
										<label htmlFor='contact_email'>
											Main Contact Email
											<RequiredIndicator />
										</label>
										<input
											id='contact_email'
											placeholder='Enter Contact Email'
											type='email'
											value={values.contact_email}
											onChange={handleChange}
											onBlur={handleBlur}
											className={
												errors.contact_email && touched.contact_email ? 'text-input error' : 'text-input'
											}
											autoComplete='email'
										/>
										{errors.contact_email && touched.contact_email && (
											<div className='input-feedback'>{errors.contact_email}</div>
										)}
										<label htmlFor='contact_phone'>
											Main Contact Phone
											<RequiredIndicator />
										</label>
										<PhoneNumberField
											id='contact_phone'
											placeholder='Enter Contact Phone'
											value={values.contact_phone}
											onChange={handleChange}
											onBlur={handleBlur}
											className={
												errors.contact_phone && touched.contact_phone ? 'text-input error' : 'text-input'
											}
										/>
										{errors.contact_phone && touched.contact_phone && (
											<div className='input-feedback'>{errors.contact_phone}</div>
										)}
									</>
								)}
								<Button type='submit' isLoading={this.state.isLoading}>
									Create Organization
								</Button>
							</form>
						);
					}}
				</Formik>
			</>
		);
	}
}

export const FormAddUserAndOrganization = connect(
	null,
	formAddUserAndOrganizationActionCreators
)(FormAddUserAndOrganizationBase);
