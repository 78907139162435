import * as React from 'react';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import css from 'components/Forms/PersonaBuilder/FormPersonaBuilder/FormPatientDetails/persona-details.module.scss';
import { Button, Card, Grid } from '@material-ui/core';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { MuiButton } from 'components/MaterialUI';
import classes from 'classnames';
import { IPatient } from 'common/interfaces/personaBuilder/IPatient';
import { FormPatientDescription } from 'components/Forms/PersonaBuilder/FormPersonaBuilder/FormPatientDescription/FormPatientDescription';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';

interface IPatientDescriptionRouteProps {
	id?: string
}

interface IPatientDescriptionTabReduxProps {
	currentPatient: IPatient;
	currentPatientDescription: any;
	currentPatientUseCases: any;
}

const PatientDescriptionTabBase: React.FC<RouteComponentProps<IPatientDescriptionRouteProps> &
	IPatientDescriptionTabReduxProps &
	ResolveThunks<typeof PersonaBuilderActionCreators>
	> = props => {
	
	const {
		currentPatient,
		currentPatientDescription,
		currentPatientUseCases
	} = props;

	let _submitForm = null;
	const _bindSubmitForm = (submitForm) => {
		_submitForm = submitForm;
	};

	const _handleSubmit = () => {
		if (_submitForm) {
			_submitForm();
		}
	};
	const savePatientDescription = (values) => {
		if (!!values.id){
			props.updateDescription({...values, patient_id: currentPatient.id});
		}else{
			props.createDescription({...values, patient_id: currentPatient.id});
		}
	};

	return (
		<>
			<FormPatientDescription
				bindSubmitForm={_bindSubmitForm}
				defaultFormContent={{...currentPatientDescription,
						use_cases: currentPatientUseCases.map((u)=>{ return u.persona_use_case_id }),
						base_path: '',
						image_url: '',
						location: ''
				}}
				formAction={savePatientDescription}
			/>
			<Grid container xs={12} className={css.actionsContainer}>
				<Grid container xs={12} md={6} justify={'flex-start'} alignContent={'center'}>
					<Button component={Link} to={`/persona-builder/patient-listing/${currentPatient.id}`}>
						<ChevronLeft className={classes([css.leftIcon], [css.iconSmall])}/>
						Patient
					</Button>
				</Grid>
				<Grid container xs={12} md={6} justify={'flex-end'} alignContent={'center'}>
					<MuiButton variant="contained" disabled={false} onClick={_handleSubmit}>
						Save
					</MuiButton>
					<Button component={Link} to={`/persona-builder/patient-listing/${currentPatient.id}/address`}>
						Address
						<ChevronRight className={classes([css.rightIcon], [css.iconSmall])}/>
					</Button>
				</Grid>
			</Grid>
		</>
	);
};

const mapStateToProps = (state: ApplicationState): IPatientDescriptionTabReduxProps => {
	return {
		currentPatient: state.personaBuilder.currentPatient,
		currentPatientDescription: state.personaBuilder.currentPatientDescription,
		currentPatientUseCases: state.personaBuilder.currentPatientUseCases
	};
};

export const PatientDescriptionTab = connect(
	mapStateToProps,
	PersonaBuilderActionCreators
)(PatientDescriptionTabBase);
