import * as React from 'react';

import css from './table-column.module.scss';
import classes from 'classnames';

interface ITableColumnProps {
	className?: string;
	htmlFor?: string;
}

export const TableColumn: React.FC<ITableColumnProps> = props => {
	const { children, className, htmlFor } = props;
	return (
		<div
			className={classes(css.tableColumn, 'tableColumn', className, {
				[css.hasLabel]: htmlFor
			})}
		>
			{htmlFor ? (
				<label htmlFor={htmlFor} className={css.label}>
					{children}
				</label>
			) : (
				children
			)}
		</div>
	);
};
