import * as yup from 'yup';

export const codeSampleSchema = yup.object({
  name: yup
    .string()
    .max(50)
    .required('Required'),
  descr: yup
    .string()
    .max(255)
    .required('Required'),
  overview: yup
    .string(),
  repo_url: yup.string().url('Please enter a valid URL').max(512).required('Required'),
  download_url: yup.string().url('Please enter a valid URL').max(512)
});
