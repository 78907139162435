import { Reducer } from 'redux';

import PersonaBuilderActions from 'stores/actionInterfaces/personaBuilder/personaBuilderActionInterfaces';
import { PersonaBuilderState } from 'stores/stateInterfaces/personaBuilder/personaBuilderStateInterface';

import { clone } from 'ramda';

import { EUpdateState } from 'stores/shared/ENums';
import { convertArrayToIndexedObject } from 'common/utils/convertArrayToIndexedObject';

import { mergeDeepRight } from 'ramda';
import { act } from 'react-dom/test-utils';

const initialState: PersonaBuilderState = {
	patientTableState: EUpdateState.Empty,
	patients: [],
	currentPatient: null,
	currentPatientAddresses: [],
	currentPatientDescription: null,
	currentPatientUseCases: [],
	currentPatientAllergyIntolerances: [],
	currentPatientFamilyMembers: [],
	currentPatientCoverages: [],
	currentPatientMedications: [],
	currentPatientConditions: [],
	dropdowns: {
		races: [],
		marital_statuses: [],
		languages: [],
		genders: [],
		ethnicities: [],
		address_uses: [],
		address_types: [],
		user_cases: [],
		allergy_categories: [],
		allergy_severities: [],
		allergy_criticalities: [],
		allergy_exposure_routes: [],
		relationships: [],
		coverage_classes: [],
		coverage_types: [],
		coverage_statuses: [],
		coverage_dependent_relationships: [],
		organizations: [],
		insurance_organizations: [],
		medications: [],
		prescription_statuses: [],
		providers: [],
		conditions: []
	},
	hl7Valuesets: {
		allergy_reactions: [],
		allergy_substances: [],
		body_sites: [],
		diagnosis: [],
		dispositions: [],
		dosage_reasons_as_needed: [],
		evaluations: [],
		goal_codes: [],
		goal_categories: [],
		goal_outcomes: [],
		physician_orders: [],
		reason_codes: [],
		service_orders: []
	},
	hl7ValuesetsTableState: EUpdateState.Empty,
	allFamilyMembers: [],
	saveDisabled: true
};

export const PersonaBuilderReducers: Reducer<PersonaBuilderState> = (
	state: PersonaBuilderState,
	action: PersonaBuilderActions
) => {

	switch (action.type) {
		case 'GET_PATIENT_DROPDOWNS_RECEIVED': {
			return {
				...state,
				dropdowns: action.dropdowns
			}
		}
		case 'GET_HL7VALUESETS_RECEIVED': {
			return {
				...state,
				hl7Valuesets: action.hl7Valuesets
			}
		}
		case 'GET_ALL_PATIENTS_REQUEST': {
			return {
				...state,
				patientTableState: EUpdateState.Loading
			}
		}
		case 'GET_ALL_PATIENTS_RECEIVED': {
			return {
				...state,
				patients: action.patients
			}
		}
		case 'GET_PATIENT_REQUEST': {
			return {
				...state,
				currentPatient: null
			}
		}
		case 'GET_PATIENT_RECEIVED': {
			return {
				...state,
				currentPatient: action.patient
			}
		}
		case 'GET_PATIENT_ADDRESSES_REQUEST': {
			return {
				...state,
				currentPatientAddresses: []
			}
		}
		case 'GET_PATIENT_ADDRESSES_RECEIVED': {
			return {
				...state,
				currentPatientAddresses: action.addresses
			}
		}
		case 'GET_PATIENT_DESCRIPTION_RECEIVED': {
			return {
				...state,
				currentPatientDescription: action.description
			}
		}
		case 'GET_PATIENT_ALLERGY_INTOLERANCES_RECEIVED': {
			return {
				...state,
				currentPatientAllergyIntolerances: action.allergyIntolerances
			}
		}
		case 'GET_PATIENT_FAMILY_RECEIVED': {
			return {
				...state,
				currentPatientFamilyMembers: action.familyMembers
			}
		}
		case 'GET_ALL_FAMILY_RECEIVED': {
			return {
				...state,
				allFamilyMembers: action.familyMembers
			}
		}
		case 'GET_PATIENT_COVERAGES_RECEIVED': {
			return {
				...state,
				currentPatientCoverages: action.coverages
			}
		}
		case 'GET_PATIENT_MEDICATIONS_RECEIVED': {
			return {
				...state,
				currentPatientMedications: action.medications
			}
		}
		case 'GET_PATIENT_CONDITIONS_RECEIVED': {
			return {
				...state,
				currentPatientConditions: action.conditions
			}
		}
		case 'SET_SAVE_DISABLED': {
			return {
				...state,
				saveDisabled: action.saveDisabled
			}
		}
		case 'GET_PATIENT_DESCRIPTION_USE_CASES_RECEIVED': {
			return {
				...state,
				currentPatientUseCases: action.useCases
			}
		}
		case 'EXPORT_PATIENT_REQUEST': {
			return {
				...state,
			}
		}
		case 'EXPORT_PATIENT_RECEIVED': {
			return {
				...state,
			}
		}
	}
	return state || initialState;
};
