import * as React from 'react';
import { Button, Theme, withStyles } from '@material-ui/core';
import { darken } from '@material-ui/core/styles';

export const MuiButton = withStyles((theme: Theme) => ({
	root: {
		color: '#fff',
		backgroundColor: '#1f818e',
		'&:hover': {
			backgroundColor: darken('#1f818e', .8),
		},
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
		fontSize: '1.5rem'
	}
}))(Button);
