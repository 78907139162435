import { Reducer } from 'redux';

import ModalActions from 'stores/actionInterfaces/modalActionInterfaces';
import { ModalState } from 'stores/stateInterfaces/modalStateInterfaces';
import { clone } from 'ramda';
import { IModal, IMultistepModal } from 'common/interfaces';

const initialState: ModalState = {
	modals: [],
	multistepModal: null,
	currentMultistepModalStep: null
};

export const ModalReducers: Reducer<ModalState> = (
	state: ModalState,
	action: ModalActions
) => {
	switch (action.type) {
		case 'CREATE_MODAL': {
			const modals = clone(state.modals);
			modals.push(action.modal);
			return {
				...state,
				modals
			};
		}

		case 'DISMISS_MODAL': {
			return {
				...state,
				modals: []
			};
		}

		case 'CREATE_MULTISTEP_MODAL': {
			return {
				...state,
				multistepModal: action.multistepModal,
				currentMultistepModalStep: 0
			};
		}

		case 'CREATE_MULTISTEP_MODAL': {
			return {
				...state,
				multistepModal: action.multistepModal,
				currentMultistepModalStep: 0
			};
		}

		case 'SET_MULTISTEP_MODAL_STEP': {
			return {
				...state,
				currentMultistepModalStep: action.step
			};
		}

		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state || initialState;
};
