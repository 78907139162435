import { Reducer } from 'redux';
import MessageActions from 'stores/actionInterfaces/messageActionInterfaces';
import { MessageState } from 'stores/stateInterfaces/messageStateInterface';
import { clone } from 'ramda';
import { LOCATION_CHANGE } from 'connected-react-router';

const initialState: MessageState = {
	messages: []
};

export const MessageReducers: Reducer<MessageState> = (
	state: MessageState,
	action: MessageActions
) => {
	switch (action.type) {
		case 'CREATE_MESSAGE': {
			const messages = clone(state.messages);
			messages.push(action.message);
			return {
				...state,
				messages
			};
		}
		case 'REMOVE_MESSAGE': {
			let messages = clone(state.messages);
			messages.shift();
			return { ...state, messages };
		}
		//@ts-ignore
		case LOCATION_CHANGE: {
			const messages = [];
			console.log('Clear Messages', messages);

			return { ...state, messages };
		}
		case 'CLEAR_MESSAGES': {
			const messages = [];
			return { ...state, messages };
		}
		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state || initialState;
};
