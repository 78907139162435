import * as React from 'react';
import { AppFrame } from 'components/Page/AppFrame';
import { authActionCreators } from 'stores/actions/authActions';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { IModal, ICognitoUser } from 'common/interfaces';
import { FormChangePassword } from 'components/Forms/FormChangePassword';
import css from './account-settings-view.module.scss';
import { modalActionCreators } from 'stores/actions/modalActions';
import { InnerContainer, PageTitleArea } from 'components/Page';
import { Panel, PanelContentSplit } from 'components/Panel';
import { Button } from 'components/Button';
import { UserType } from 'common/property-list/user-types';
import { IViewMessages } from 'common/interfaces/IViewMessages';

const accountSettingsActionCreators = {
	...authActionCreators,
	...modalActionCreators
};

interface IAccountSettingsReduxProps {
	user: ICognitoUser;
	organizationName: string;
}

class AccountSettingsViewBase extends React.PureComponent<
	IAccountSettingsReduxProps & ResolveThunks<typeof accountSettingsActionCreators>
> {
	UNSAFE_componentWillMount() {
		this.props.getAuth();
	}

	render() {
		const { user, createModal, organizationName, deleteCurrentAccount } = this.props;
		console.log('user', user);
		const deleteAccountModal: IModal = {
			primaryButtonLabel: 'Delete Account',
			description: 'Are you sure you would like to delete your account? This action cannot be undone.',
			title: 'Delete Account',
			primaryButtonStyle: 'destructive',
			primaryButtonActionWithModalActions: modalActions => {
				modalActions.setFormLoadingAttribute(true);
				deleteCurrentAccount(modalActions.setErrorMessage, modalActions.setFormLoadingAttribute);
			}
		};
		return (
			<AppFrame
				Content={appFrameProps => (
					<InnerContainer>
						<PageTitleArea>Account Settings</PageTitleArea>
						<Panel>
							<div className={css.profile}>
								<div className={css.profileIcon}>
									<div className={css.profileText}>{user.username.charAt(0)}</div>
								</div>
								<div className={css.profileInfo}>
									<h3 className={css.username}>{user.username}</h3>
									<p className={css.email}>{user.attributes.email}</p>
									{organizationName && (
										user.attributes['custom:user_type'] === 'mihin-admin' ? (
											<p className={css.memberSince}>
												{UserType[user.attributes['custom:user_type']]}
											</p>
										) : (
											<p className={css.memberSince}>
												{UserType[user.attributes['custom:user_type']]} of {organizationName}
											</p>

										))}
								</div>
							</div>
						</Panel>

						<Panel>
							<PanelContentSplit
								title='Password'
								ContentLeft={() => {
									return <p>Set a unique password to protect your account.</p>;
								}}
								ContentRight={() => {
									return (
										<Button
											onClick={event => {
												createModal(
													{
														primaryButtonLabel: 'Change Password',
														content: props => (
															<FormChangePassword
																{...props}
																setSuccessMessage={
																	appFrameProps.viewMessages.setSuccessMessage
																}
															/>
														),
														title: 'Change Password',
														description:
															'Enter your existing password and new one below. It’s a good idea to use a strong password that you’re not using elsewhere.'
													},
													event
												);
											}}
											style='secondary'
										>
											Change Password
										</Button>
									);
								}}
							/>
						</Panel>

						<Panel>
							<PanelContentSplit
								title='Delete Account'
								ContentLeft={() => {
									return <p>This will permanently delete your account.</p>;
								}}
								ContentRight={() => {
									return (
										<Button
											onClick={event => createModal(deleteAccountModal, event)}
											style='destructive'
										>
											Delete Account
										</Button>
									);
								}}
							/>
						</Panel>
					</ InnerContainer>
				)}
			></AppFrame>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IAccountSettingsReduxProps => ({
	user: state.auth.user,
	organizationName: state.auth.organization ? state.auth.organization.name : null
});

export const AccountSettingsView = connect(
	mapStateToProps,
	accountSettingsActionCreators
)(AccountSettingsViewBase);
