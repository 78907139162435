import * as yup from 'yup';

interface IPatientCoverageValidationSchemaConfig {
}

interface IPatientCoverageValidationSchema {
  coverage_status_id: number;
  coverage_type_id: number;
  coverage_period_start: any;
  coverage_period_end: any;
  coverage_dependent_relationship_id: number;
  organization_id: number;
  coverage_class_id: number;
  dependent: number;
}

export const patientCoverageValidationSchema = (
	patientCoverageValidationSchemaConfig: IPatientCoverageValidationSchemaConfig = {}
) => {
  let schemaDefinition = {
    coverage_status_id: yup.number().typeError('Status is invalid.').required('Status is required.'),
    coverage_type_id: yup.number().typeError('Type is invalid.').required('Type is required.'),
    
    coverage_period_start: yup
      .date().typeError('Start Date must be a valid date')
      .required('Start Date is Required'),

    coverage_period_end: yup
      .date().typeError('End Date must be a valid date')
      .required('End Date is Required')
      .when(
        'coverage_period_start',
        (coverage_period_start, schema) => (coverage_period_start && schema.min(coverage_period_start, 'End Date must be after Start Date')),
      ),

    coverage_dependent_relationship_id: yup.number().typeError('Relationship to Subscriber is invalid.').required('Relationship to Subscriber is required.'),
    dependent: yup.number().typeError('Number of Dependents is invalid.').required('Number of Dependents is required.'),
    organization_id: yup.number().typeError('Payer is invalid.').required('Payer is required.'),
    coverage_class_id: yup.number().typeError('Class Type is invalid.').required('Class Type is required.'),
  };

  return yup.object().shape<any>(schemaDefinition) as any;
};
