import { Reducer } from 'redux';

import apiLibraryActions from 'stores/actionInterfaces/apiLibraryActionInterfaces';
import { ApiLibraryState } from 'stores/stateInterfaces/apiLibraryStateInterface';
import { convertArrayToIndexedObject } from 'common/utils/convertArrayToIndexedObject';
import { clone } from 'ramda';

import { EUpdateState } from 'stores/shared/ENums';

const initialState: ApiLibraryState = {
	allApis: null,
	allApisUpdateState: EUpdateState.Empty,
	allApiCodeSamples: null,
	currentApi: null
};

export const ApiLibraryReducers: Reducer<ApiLibraryState> = (state: ApiLibraryState, action: apiLibraryActions) => {
	switch (action.type) {
		case 'GET_API_DOCS_REQUEST': {
			return {
				...state,
				allApisUpdateState: EUpdateState.Loading
			};
		}
		case 'GET_API_DOCS_RECEIVED': {
			return {
				...state,
				allApis: convertArrayToIndexedObject(action.apis),
				allApisUpdateState: EUpdateState.Loaded
			};
		}
		case 'GET_API_DOC_REQUEST': {
			return {
				...state
			};
		}
		case 'GET_API_DOC_RECEIVED': {
			return {
				...state,
				currentApi: action.api.id,
				allApis: {
					...state.allApis,
					[action.api.id]: action.api
				},
				allApiCodeSamples: {
					...state.allApiCodeSamples,
					[action.api.id]: action.codeSamples
				}
			};
		}
		case 'CREATE_API_DOC_REQUEST': {
			return {
				...state
			};
		}
		case 'CREATE_API_DOC_RESPONSE': {
			return {
				...state
			};
		}
		
		case 'CREATE_API_DOC_REQUEST': {
			return {
				...state
			};
		}
		case 'CREATE_API_DOC_RESPONSE': {
			return {
				...state
			};
		}
		
		case 'UPDATE_API_DOC_REQUEST': {
			return {
				...state
			};
		}
		case 'UPDATE_API_DOC_RESPONSE': {
			return {
				...state,
				allApis: {
					...state.allApis,
					[action.apiDocId]: action.apiDoc
				}
			};
		}
			
		case 'DELETE_API_DOC_REQUEST': {
			return {
				...state
			};
		}

		case 'DELETE_API_DOC_RESPONSE': {
			const allApis = clone(state.allApis);
			delete allApis[action.apiDocId];

			return {
				...state,
				allApis
			};
		}
			
		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state || initialState;
};
