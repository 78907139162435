import * as React from 'react';
import { IActionOption } from 'common/interfaces/IActionOption';
import css from './options-trigger.module.scss';
import { ActionIcon } from 'components/ActionIcon';
import classes from 'classnames';
import { findDOMNode } from 'react-dom';

interface IOptionsTriggerProps {
	actionOptions: IActionOption[];
	isDark?: boolean;
}

interface IOptionsTriggerState {
	isOptionsVisible: boolean;
}

export class OptionsTrigger extends React.PureComponent<IOptionsTriggerProps, IOptionsTriggerState> {
	constructor(props) {
		super(props);

		this.state = {
			isOptionsVisible: false
		};
	}

	componentDidMount() {
		if (this.state.isOptionsVisible) {
			document.addEventListener('keyup', this.dismissOnEscape, true);
			document.addEventListener('click', this.handleClickOutside, true);
		}
	}

	componentWillUnmount() {
		document.removeEventListener('keyup', this.dismissOnEscape, true);
		document.removeEventListener('click', this.handleClickOutside, true);
	}

	toggleActive = () => {
		this.setActive(!this.state.isOptionsVisible);
	};

	dismissOnEscape = event => {
		if (event.keyCode === 27) {
			this.setActive(false);
		}
	};

	handleClickOutside = event => {
		const domNode = findDOMNode(this);

		if (!domNode || !domNode.contains(event.target)) {
			this.setActive(false);
		}
	};

	setActive = (isOptionsVisible: boolean) => {
		console.log('setActive');
		if (isOptionsVisible !== this.state.isOptionsVisible) {
			if (isOptionsVisible) {
				document.addEventListener('click', this.handleClickOutside, true);
				document.addEventListener('keyup', this.dismissOnEscape, true);
			} else {
				document.removeEventListener('click', this.handleClickOutside, true);
				document.removeEventListener('keyup', this.dismissOnEscape, true);
			}
		}

		this.setState({
			isOptionsVisible
		});
	};

	dismissOptionsAndCallFunction = (functionToCall: () => void) => {
		this.setState({
			isOptionsVisible: false
		});
		functionToCall();
	};

	render() {
		const { actionOptions, isDark } = this.props;
		return (
			<div className={css.userMenu}>
				<ActionIcon
					className={classes('buttonIcon', css.actionIconDots)}
					onClick={event => {
						this.setActive(!this.state.isOptionsVisible);
					}}
					ariaLabel='Toggle user actions menu'
					isDark={isDark}
				>
					<div className={css.dots}>
						<div className={css.dot1}></div>
						<div className={css.dot2}></div>
						<div className={css.dot3}></div>
					</div>
				</ActionIcon>
				{this.state.isOptionsVisible && (
					<ul className={css.dropDownPanel}>
						{actionOptions.map((item, index) => {
							return (
								<li key={index}>
									<button
										className={classes('buttonListItem')}
										onClick={() => this.dismissOptionsAndCallFunction(item.action)}
									>
										{item.label}
									</button>
								</li>
							);
						})}
					</ul>
				)}
			</div>
		);
	}
}
