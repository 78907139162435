import * as yup from 'yup';

interface IProviderDescriptionValidationSchemaConfig {
}

interface IProviderDescriptionValidationSchema {
	type: string;
	bio: string;
	description: string;
}

export const providerDescriptionValidationSchema = (
	providerDescriptionValidationSchemaConfig: IProviderDescriptionValidationSchemaConfig = {}
) => {
	let schemaDefinition = {
		type: yup.string().required('Provider Type is Required'),
		bio: yup.string().required('Biography is Required'),
		description: yup.string().required('One Sentence Summary is Required')
	};

	return yup.object().shape<any>(schemaDefinition) as any;
};
