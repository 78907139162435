import * as React from 'react';
import { AppFrame, InnerContainer } from 'components/Page';
import { ProviderDetailsTab } from './ProviderDetailsTab/ProviderDetailsTab';
import { ProviderDescriptionTab } from './ProviderDescriptionTab/ProviderDescriptionTab';
import { ProviderTimeSlotTab } from './ProviderTimeSlotTab/ProviderTimeSlotTab';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import css from './provider-dashboard.module.scss';
import { NavLink, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { ProviderActionCreators } from 'stores/actions/personaBuilder/providerActions';
import { IProvider } from 'common/interfaces/personaBuilder/IProvider';
import { ProviderTitleArea } from 'components/Page/ProviderTitleArea';

interface IProviderDashboardRouteProps {
	id?: string
}

interface IProviderDashboardViewReduxProps {
	currentProvider: IProvider;
}

const ProviderDashboardViewBase: React.FC<IProviderDashboardViewReduxProps &
	RouteComponentProps<IProviderDashboardRouteProps> &
	ResolveThunks<typeof ProviderActionCreators>> = props => {
	
	const isNew = props.match.params.id === 'create';
	React.useEffect(()=>{
		if (!isNew){
			props.getProvider(props.match.params.id);
			props.getProviderTimeSlots(props.match.params.id);
			props.getProviderMetadata(props.match.params.id);
			props.getProviderMetadataUseCases(props.match.params.id);
		}
		props.populateProviderDropdowns();
	}, []);

	const currentProvider = isNew ? {
		provider_identifier: '',
		provider_first_name: '',
		provider_last_name: '',
		dob: null,
		provider_phone: '',
		provider_email: '',
		appointment_length: '',
		organization_id: null,
		practice_code_id: null,
		practitioner_role_id: null,
		gender_id: null,
		qualification_id: null
	} : props.currentProvider;

	const navigationItems = [
		{
			label: 'Provider',
			location: `/persona-builder/provider-listing/${props.match.params.id}`,
			route: `/persona-builder/provider-listing/${props.match.params.id}`,
			component: () => <ProviderDetailsTab {...props}/>,
			disabled: false
		},
		{
			label: 'Description',
			location: `/persona-builder/provider-listing/${props.match.params.id}/description`,
			route: `/persona-builder/provider-listing/${props.match.params.id}/description`,
			component: ProviderDescriptionTab,
			disabled: isNew
		},
		{
			label: 'Time Slots',
			location: `/persona-builder/provider-listing/${props.match.params.id}/time-slots`,
			route: `/persona-builder/provider-listing/${props.match.params.id}/time-slots`,
			component: ProviderTimeSlotTab,
			disabled: isNew
		},
	];
	return currentProvider && (
		<AppFrame>
			<InnerContainer>
				<Grid container>
					<Grid item xs={12} md={2} justify={'center'} className={css.imageContainer}>
						<img className={css.imageAvatar} width={'100%'}
									src={'https://ipog.edu.br/wp-content/themes/ipog/assets/img/graduacao/blank-avatar.png'}/>
					</Grid>
					<Grid item xs={12} md={10}>
						<ProviderTitleArea
							provider={currentProvider}
						/>
						<ul className={css.tabBar}>
							{navigationItems.map((item, index) => {
								return (
									<li key={index}>
										<NavLink
											exact
											to={item.location}
											onClick={(e)=>{item.disabled && e.preventDefault()}}
											className={item.disabled ? css.disabled : ''}
										>
											{item.label}
										</NavLink>
									</li>
								);
							})}
						</ul>
					</Grid>
				</Grid>

				<Grid container xs={12}>
					<Grid item xs={12}>
						<Switch>
							{navigationItems.map((item, index) => {
								return (
									<Route
										key={index}
										exact
										path={item.route}
										component={item.component}
									/>
								);
							})}
							<Redirect to='/404'/>
						</Switch>
					</Grid>
				</Grid>
			</InnerContainer>
		</AppFrame>
	);
};

const mapStateToProps = (state: ApplicationState): IProviderDashboardViewReduxProps => {
	return {
		currentProvider: state.personaBuilderProvider.currentProvider
	};
};

export const ProviderDashboardView = connect(
	mapStateToProps,
	ProviderActionCreators
)(ProviderDashboardViewBase);
