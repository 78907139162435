import { IModal } from 'common/interfaces';
import { FormDeleteRof } from 'components/Forms/FormDeleteRof';
import { rofActionCreators } from 'stores/actions/rofActions';
import { IRof } from 'common/interfaces/IRof';

export const deleteRofModal = (rof: IRof, formAction): IModal => ({
	title: 'Delete Sandbox',
	content: FormDeleteRof,
	primaryButtonLabel: 'Delete Sandbox',
	formAction: () => formAction(rof.id),
	confirmationString: rof && rof.code ? rof.code.toUpperCase() : null
});
