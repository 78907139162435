import * as React from 'react';
import css from './page-title-area.module.scss';
import { Link } from 'react-router-dom';
import { PageBreadcrumbs } from 'components/Page';
import { TNavigationItems } from 'common/interfaces';
import classes from 'classnames';
import { Button } from 'components/Button';
import { TButtonStyle } from 'common/types/TButtonStyle';
import { Tooltip, IconButton, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { arrayCast } from 'common/utils/arrayCast';

const useStyles = makeStyles({
	tooltip: {
		borderRadius: 3,
		border: 0,
		padding: '10px',
		boxShadow: '0 3px 5px 2px rgba(200, 200, 200, .3)',
		fontSize: '1em',
	},
});
interface IButton {
	buttonLabel?: string;
	buttonAction: any;
	isLoading?: boolean;
	disabled?: boolean;
	style?: TButtonStyle;
	tooltip?: string;
	icon?: any;
	active?: boolean;
}

interface IPageTitleArea {
	buttonList?: IButton[];
	backLabel?: string;
	backLocation?: string;
	currentRofStatus?: any;
	isCurrentRofOn?: boolean;
	handleRofStateChange?: any;
	breadcrumbItems?: TNavigationItems;
	isCurrentRofAutomatic?: boolean;
	description?: string;
	verticalAlignment?: 'top' | 'center';
}

export const PageTitleArea: React.FC<IPageTitleArea> = ({
	buttonList,
	backLabel,
	backLocation,
	currentRofStatus,
	isCurrentRofOn,
	handleRofStateChange,
	breadcrumbItems,
	isCurrentRofAutomatic,
	description,
	verticalAlignment,

	children,
}) => {
	const hasButtonList = arrayCast(buttonList).length > 0;
	const hasCurrentRofStatus = !!currentRofStatus;

	const muiStyles = useStyles();

	return (
		<div className={classes(css.pageTitleArea, css[verticalAlignment])} role='banner'>
			<div className={classes(css.pageTitleLeftWrapper)}>
				<h1 className={classes('h2', css.title)} id='skipNavLocation'>
					{children}
				</h1>
				{breadcrumbItems && <PageBreadcrumbs breadcrumbItems={breadcrumbItems} />}
				{backLabel && backLocation && <Link to={backLocation}>{backLabel}</Link>}
				{description && <p className={css.description}>{description}</p>}
			</div>

			{hasButtonList && (
				<div className={classes(css.pageTitleActionWrapper)}>
					<ul className={`reset ${classes(css.pageTitleActionContainer)}`}>
						{buttonList.map((button, index) => {
							const TooltipComponent = button.tooltip ? Tooltip : Box;
							const ButtonIcon = button.icon;
							return (
								<li key={`button-list-item-${index}`} className={css.listItem}>
									{!!button.icon ? (
										<Tooltip
											title={button.tooltip || ''}
											classes={{ tooltip: muiStyles.tooltip }}
											key={`page-title-button-${index}`}
										>
											<IconButton
												onClick={button.buttonAction}
												disabled={button.disabled}
												classes={{ root: classes([css.icon, css[button.style]]) }}
												disableRipple
												color={!!button.active ? 'primary' : 'default'}
											>
												<ButtonIcon fontSize='large' />
											</IconButton>
										</Tooltip>
									) : (
										<Tooltip
											title={button.tooltip || ''}
											classes={{ tooltip: muiStyles.tooltip }}
											key={`page-title-button-${index}`}
										>
											<Button
												onClick={button.buttonAction}
												isLoading={button.isLoading}
												disabled={button.disabled}
												style={button.style}
											>
												<div>{button.buttonLabel || ''}</div>
											</Button>
										</Tooltip>
									)}
								</li>
							);
						})}
					</ul>
				</div>
			)}
		</div>
	);
};
