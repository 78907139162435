import * as React from 'react';
import css from './faq-question.module.scss';
import { Panel } from 'components/Panel';
import classes from 'classnames';

interface IFaqQuestion {
	question: string;
	Answer: React.FC | string;
}

export const FaqQuestion: React.FC<IFaqQuestion> = props => {
	const { question, Answer } = props;
	return (
		<Panel className={css.faqQuestion}>
			<h3 className={classes(css.question)}>{question}</h3>
			{typeof Answer === 'string' ? <p>{Answer}</p> : <Answer />}
		</Panel>
	);
};
