import * as React from 'react';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card, Typography, Grid, Modal, Backdrop, Fade } from '@material-ui/core';
import { AddCircle, Edit, ArrowDownward, ChevronLeft, ChevronRight, HighlightOff } from '@material-ui/icons';
import DataTable from 'react-data-table-component';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { MuiButton } from 'components/MaterialUI/index';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { IPatient } from 'common/interfaces/personaBuilder/IPatient';
import { IPatientFamilyMember } from 'common/interfaces/personaBuilder/IPatientFamilyMember';
import cssClasses from 'classnames';
import cssDetails from 'components/Forms/PersonaBuilder/FormPersonaBuilder/FormPatientDetails/persona-details.module.scss';
import css from './patient-family-history-tab.module.scss';
import { FormPatientFamilyMember } from 'components/Forms/PersonaBuilder/FormPersonaBuilder/FormPatientFamilyMember';
import { FormPatientChooseExistingFamilyMember } from 'components/Forms/PersonaBuilder/FormPersonaBuilder/FormPatientChooseExistingFamilyMember';
const sortIcon = <ArrowDownward />;

interface IPatientFamilyHistoryTabReduxProps {
	currentPatient: IPatient;
	patientFamilyMembers: IPatientFamilyMember[];
	patients: IPatient[];
  relationships: any;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
			width: '960px'
		},
		header: {
			textAlign: 'center'
		},
		editHeaderName: {
			marginTop: '0'
		},
		removeButton: {
			fontSize: '1rem'
		},
		removeButtonIcon: {
			marginRight: '.5rem',
			fontSize: '2rem'
		}
	}),
);

const PatientFamilyHistoryTabBase : React.FC<
	IPatientFamilyHistoryTabReduxProps &
	ResolveThunks<typeof PersonaBuilderActionCreators>
	> = props => {
	
	let _submitForm = null;
	const { currentPatient, patientFamilyMembers, patients, relationships } = props;
	const initialData = patientFamilyMembers;

	const classes = useStyles(props);
	const [modalOpen, setModalOpen] = React.useState(false);
	const [chooseExistingModalOpen, setChooseExistingModalOpen] = React.useState(false);
	const [currentFamilyMember, setCurrentFamilyMember] = React.useState(null);
	const [familyMemberFormContent, setFamilyMemberFormContent] = React.useState({});
	const [changedRelationshipId, setChangedRelationshipId] = React.useState(0);
	const [editMoreInfo, setEditMoreInfo] = React.useState(false);
	const [filteredData, setFilteredData] = React.useState(null);

	const history = useHistory();
	const prevLink = currentPatient ? `/persona-builder/patient-listing/${currentPatient.id}/allergy` : '';
	const nextLink = currentPatient ? `/persona-builder/patient-listing/${currentPatient.id}/coverage` : '';

	const _bindSubmitForm = (submitForm) => {
		_submitForm = submitForm;
	};
	const handleSubmit = () => {
		if (_submitForm){
			_submitForm();
		}
	};
	
	const handleFormAdd = () => {
		setCurrentFamilyMember(null);
		setFamilyMemberFormContent({ patient_id: currentPatient.id });
		setEditMoreInfo(true);
		setModalOpen(true);
	};
	const handleFormEditRelationship = (member) => {
		const patient = patients.filter((p) => { return p.id === member.patient_family_member_id; });
		const relationship = relationships.filter((r) => { return r.patient_relationship_display === member.patient_relationship_display; });
		member.patient_relationship_id = relationship.length > 0 ? relationship[0].id : '';
		
		setChangedRelationshipId(member.patient_relationship_id);
		setCurrentFamilyMember(member);
		setFamilyMemberFormContent({
			patient_id: currentPatient.id,
			...(patient.length > 0 ? patient[0] : {}), 
			...member
		});
		setEditMoreInfo(false);
		setModalOpen(true);
	};
	const handleFormChooseExisting = () => {
		setChooseExistingModalOpen(true);
	};
	const handleFormClose = () => {
		setModalOpen(false);
		setChooseExistingModalOpen(false);
	};

	const saveFamilyMember = (values) => {
		const _savePatient: IPatient = {...values};
		const _saveFamilyMember: IPatientFamilyMember = {...values};

		if (!!values.patient_family_member_id) {
			// update
			props.updatePatientFamilyMember(_saveFamilyMember, _savePatient).then(()=>{
				props.getAllPatients();
				props.getPatientFamilyMembers(currentPatient.id);
				setCurrentFamilyMember(null);
				setModalOpen(false);
			}).catch((e)=>{console.log(e)});
		}
		else {
			// add
			props.createPatientFamilyMember(_saveFamilyMember, _savePatient).then(()=>{
				props.getAllPatients();
				props.getPatientFamilyMembers(currentPatient.id);
				setCurrentFamilyMember(null);
				setModalOpen(false);
			}).catch((e)=>{console.log(e)});
		}
	};
	const saveFamilyMemberRelationship = () => {
		// build family member relationship object
		const _saveFamilyMember: IPatientFamilyMember = {
			id: familyMemberFormContent["id"],
			patient_id: currentPatient.id,
			patient_family_member_id: familyMemberFormContent["patient_family_member_id"],
			patient_relationship_id: changedRelationshipId,
			patient_relative_name: "",
			patient_relationship_display: ""
		};

		// save changes
		props.updatePatientFamilyMember(_saveFamilyMember, null).then(()=>{
			props.getAllPatients();
			props.getPatientFamilyMembers(currentPatient.id);
			setCurrentFamilyMember(null);
			setModalOpen(false);
		}).catch((e)=>{console.log(e)});
	};
	const saveFamilyMemberAssignment = (values) => {
		const _saveFamilyMember: IPatientFamilyMember = {...values};
		props.createPatientFamilyMember(_saveFamilyMember, currentPatient).then(()=>{
			props.getPatientFamilyMembers(currentPatient.id);
			setChooseExistingModalOpen(false);
		}).catch((e)=>{console.log(e)});
	};
	const deleteFamilyMember = () => {
		props.deletePatientFamilyMember(currentFamilyMember).then(()=>{
			props.getPatientFamilyMembers(currentPatient.id);
			setCurrentFamilyMember(null);
			setModalOpen(false);
		}).catch((e)=>{console.log(e)});
	};
	const filterFamilyMembers = (param) => {
		if (param.length > 2) {
			let foundData = [];
			initialData.forEach(function(dataElement) {
				["patient_relative_name", "patient_relationship_display"].forEach(function(property) {
					if (dataElement[property].toLowerCase().indexOf(param.toLowerCase()) > -1) {
						foundData.push(dataElement);
					}
				});
			});
			setFilteredData(foundData);
		}
		else if (param.length === 0) {
			setFilteredData(initialData);
		}
	};

	const columns = [
		{
			name: '',
			grow: 0,
			cell: (row) => <Button onClick={() => handleFormEditRelationship(row)}><Edit /></Button>,
			button: true
		},
		{
			name: '',
			grow: 0,
			cell: (row) => <img className={css.imageAvatar} src={'https://ipog.edu.br/wp-content/themes/ipog/assets/img/graduacao/blank-avatar.png'} />
		},
		{
			name: 'Family Member',
			sortable: true,
			selector: 'patient_relative_name'
		},
		{
			name: 'Relationship Type',
			selector: 'patient_relationship_display',
			sortable: true,
			cell: (row) => <span className={css.relationshipDisplay}>{row.patient_relationship_display}</span>
		}
	];
	
	return (
		<>
			{patientFamilyMembers.length > 0 && 
				<Grid container className={css.listContainer}>
					<Grid item xs={6}>
						<h4>Patient Family Members <MuiButton className={css.addButton} onClick={handleFormAdd}><AddCircle /></MuiButton></h4>
					</Grid>
					<Grid item xs={6}>
						<input placeholder='Search Patient Family Members' type='text' onChange={(e) => filterFamilyMembers(e.target.value)} />
					</Grid>
					<Grid item xs={12}>
						<Card className={css.listCard}>
							<DataTable
								className={css.dataTable}
								columns={columns}
								data={filteredData ? filteredData : initialData}
								noHeader
								highlightOnHover
								defaultSortField="patient_relative_name"
								sortIcon={sortIcon}
								pagination
								paginationRowsPerPageOptions={[10,25,50,100]}
							/>
						</Card>
					</Grid>
				</Grid>
			}
			{patientFamilyMembers.length < 1 && 
				<Grid container className={css.noDataContainer}>
					<Grid item xs={12}>
						<Typography variant={'h4'}>{currentPatient ? `${currentPatient.first_name} ${currentPatient.last_name}` : ""} has no known family members. Would you like to create or assign a family member?</Typography>
					</Grid>
					<Grid item xs={12}>
						<MuiButton onClick={handleFormAdd}>Create a Family Member</MuiButton>
						<MuiButton onClick={handleFormChooseExisting}>Choose an Existing Person</MuiButton>
					</Grid>
				</Grid>
			}
			<Grid container className={cssDetails.actionsContainer}>
				<Grid item xs={12} md={6}>
					<Button onClick={() => history.push(`${prevLink}`)}>
						<ChevronLeft className={cssClasses([cssDetails.leftIcon], [cssDetails.iconSmall])}/>
						Allergy
					</Button>
				</Grid>
				<Grid item xs={12} md={6} style={{textAlign: 'right'}}>
					<MuiButton variant="contained" className={cssDetails.button} disabled={true}>
						Save
					</MuiButton>
					<Button onClick={() => history.push(`${nextLink}`)}>
						Coverage
						<ChevronRight className={cssClasses([cssDetails.rightIcon], [cssDetails.iconSmall])}/>
					</Button>
				</Grid>
			</Grid>
			<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					className={classes.modal}
					open={modalOpen}
					onClose={handleFormClose}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
				<Fade in={modalOpen}>
					<div className={classes.paper}>
						{currentFamilyMember !== null ? (
							<Grid container>
								<Grid item xs={12}>
									<em>You selected:</em>
								</Grid>
								<Grid item xs={6}>
									<h4 className={classes.editHeaderName}>{currentFamilyMember.patient_relative_name}</h4>
								</Grid>
								<Grid item xs={6} style={{ textAlign: 'right' }}>
									<Button onClick={deleteFamilyMember} className={classes.removeButton}><HighlightOff className={classes.removeButtonIcon} /> Remove as a Family Member</Button>
								</Grid>
							</Grid>
						) : (
							<h3 className={classes.header}>Create a New Patient Family Member</h3>
						)}
						{!editMoreInfo ? (
							<Grid container>
								<Grid item xs={12} className={css.editRelationshipType}>
									<label>Relationship Type</label>
									<select
											name='patient_relationship_id'
											value={changedRelationshipId}
											onChange={(e) => setChangedRelationshipId(parseInt(e.target.value))}
										>
											{relationships.map((relationship, index) => (
												<option key={index} value={relationship.id}>
													{relationship.patient_relationship_display} ({relationship.patient_relationship_code})
												</option>
											))}
										</select>
								</Grid>
								<Grid item xs={9}>
									<p>Do you need to edit this family member’s personal information or conditions?</p>
									<Button onClick={() => setEditMoreInfo(true)}>Edit More Information</Button>
								</Grid>
								<Grid item xs={3} className={css.formColumn} style={{ textAlign: 'right' }}>
									<Button type="button" className={css.cancelButton} onClick={handleFormClose}>Cancel</Button>
									<MuiButton variant="contained" onClick={saveFamilyMemberRelationship}>Save</MuiButton>
								</Grid>
							</Grid>
						) : (
							<FormPatientFamilyMember
								bindSubmitForm={_bindSubmitForm}
								defaultFormContent={familyMemberFormContent} 
								formAction={saveFamilyMember}
								cancelModalAction={handleFormClose}
								forPatientFamilyHistoryTab={true} />
						)}
					</div>
				</Fade>
			</Modal>
			<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					className={classes.modal}
					open={chooseExistingModalOpen}
					onClose={handleFormClose}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
				<Fade in={chooseExistingModalOpen}>
					<div className={classes.paper}>
						<FormPatientChooseExistingFamilyMember 
							patient={currentPatient}
							formAction={saveFamilyMemberAssignment}
							cancelModalAction={handleFormClose} />
					</div>
				</Fade>
			</Modal>
		</>
	);
}

const mapStateToProps = (state: ApplicationState): IPatientFamilyHistoryTabReduxProps => {
	return {
		currentPatient: state.personaBuilder.currentPatient,
		patients: state.personaBuilder.patients,
		patientFamilyMembers: state.personaBuilder.currentPatientFamilyMembers,
		relationships: state.personaBuilder.dropdowns.relationships,
	};
};

export const PatientFamilyHistoryTab = connect(
	mapStateToProps,
	PersonaBuilderActionCreators
)(PatientFamilyHistoryTabBase);
