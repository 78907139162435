import React, { useEffect } from 'react';
import { ApplicationState } from 'stores';
import { IModalForm, IUserOrg } from 'common/interfaces';
import { connect, ResolveThunks, useDispatch } from 'react-redux';
import { TUserType } from 'common/types/TUserType';
import { modalActionCreators } from 'stores/actions/modalActions';
import { AppFrame, InnerContainer, PageTitleArea } from 'components/Page';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Button } from 'components/Button';
import { Delete } from '@material-ui/icons';
import { convertIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';
import { clone, isEmpty } from 'ramda';
import { IRof } from 'common/interfaces/IRof';
import { rofActionCreators } from 'stores/actions/rofActions';
import { organizationActionCreators } from 'stores/actions/organizationActions';
import { deleteRofModal } from 'common/modals/deleteRofModal';
import { ReactComponent as IconExternalLink } from 'assets/icons/icon-external-link.svg';
import { Link } from 'react-router-dom';
import { authActionCreators } from 'stores/actions/authActions';


const options = {
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: 'none',
    elevation: 5,
    rowHover: false,
    responsive: 'scroll',
    rowsPerPageOptions: [5, 10, 20, 50],
    rowsPerPage: 10,
    searchOpen: true,
};

interface RofManagementViewReduxProps {
    currentUserType: TUserType;
    rofList: IRof[];
    allRof: {};
    currentUserOrganizations: {};
    currentUserName: string;
}

const rofManagementViewActionCreators = {
    ...modalActionCreators,
    ...rofActionCreators,
    ...organizationActionCreators,
    ...authActionCreators,
};

const deletionFailed = <span >
    Sandbox Deletion Failed: Please select{' '}
    <a href='mailto: help@mihin.org' target='_BLANK'>
        Contact Support <IconExternalLink />
    </a> for assistance.
</span>

const rofStatus = {
    "CREATE_INITIALIZED": "Active",
    "DELETE_COMPLETE": "Error: Sandbox resources deleted on AWS",
    "CREATE_FAILED": "Error: Sandbox failed during creation",
    "ROLLBACK_COMPLETE": "Generic Sandbox error has occurred",
    "ROLLBACK_FAILED": "Generic Sandbox error has occurred",
    "CREATE_QUEUED": "In Progress: Create queued",
    "CREATE_IN_PROGRESS": "In Progress: Create in progress",
    "ROLLBACK_IN_PROGRESS": "In progress: Rollback in progress",
    "UPDATE_IN_PROGRESS": "In progress: Update in progress",
    "CREATE_COMPLETE": "In progress: initializing",
    "DELETE_IN_PROGRESS": "Delete in progress",
    "DELETE_FAILED": deletionFailed,
}

export const RofManagementViewBase: React.FunctionComponent<
    IModalForm &
    RofManagementViewReduxProps &
    ResolveThunks<typeof rofManagementViewActionCreators & typeof modalActionCreators>
> = ({
    createModal,
    getAllRof,
    deleteRof,
    getOrganizations,
    getUserOrganizations,
    rofList,
    allRof,
    currentUserOrganizations,
    currentUserType,
    currentUserName,
}) => {
        const dispatch = useDispatch();
        useEffect(() => {
            getUserOrganizations(currentUserName);
            getOrganizations();
            getAllRof();
        }, []);


        const getMuiTheme = () => createMuiTheme({
            overrides: {
                MuiTable: {
                    root: {
                        zIndex: 1,
                    }
                },
                MuiTableCell: {
                    root: {
                        fontSize: "12px",
                        fontFamily: "sans-serif",
                        padding: "10px 25px",
                    },
                    head: {
                        fontSize: "14px",
                        fontFamily: "sans-serif",
                    },
                },
                MuiTablePagination: {
                    root: {
                        fontSize: '12px'
                    },
                    caption: {
                        fontSize: '12px'
                    }
                },

            }
        })

        const standardColumns = [
            {
                name: "name",
                label: "Sandbox Name",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rofId = tableMeta.rowData[4];
                        const url = `/sandbox/${rofId}`

                        return (
                            <>
                                <Link
                                    to={url}
                                >
                                    {tableMeta.rowData[0]} <IconExternalLink />
                                </Link>
                            </>
                        )
                    },
                    options: { sortDirection: 'asc' }
                }
            },
            {
                name: "organization_name",
                label: "Organization",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "rof_status",
                label: "Status",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "organization_id",
                label: "",
                options: {
                    display: false,
                }
            },
        ];

        const deleteRofColumn = {
            name: "id",
            label: "Actions",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const userRofOrganization = currentUserOrganizations[tableMeta.rowData[3]]

                    const isSuperAdmin = currentUserType == "mihin-admin"
                    const allowDelete = userRofOrganization ? userRofOrganization.user_type != 'org-user' : false
                    return (
                        <>
                            {allowDelete || isSuperAdmin ?
                                <Button style="link" onClick={(event) => {
                                    const rofId = tableMeta.rowData[4];

                                    createModal(
                                        deleteRofModal(allRof[rofId], deleteRof),
                                        event
                                    );
                                }} >
                                    <Delete />
                                </Button>
                                : 'N/A'
                            }

                        </>
                    )
                }
            }
        }

        return (
            <AppFrame>
                <InnerContainer >
                    <PageTitleArea>
                        Sandbox Management
                    </PageTitleArea>

                    <MuiThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            title={""}
                            data={rofList}
                            columns={[...standardColumns, deleteRofColumn]}
                            options={options}
                        />
                    </MuiThemeProvider>



                </InnerContainer>
            </AppFrame >
        );

    }


const mapStateToProps = (state: ApplicationState): RofManagementViewReduxProps => {
    const allRof = convertIndexedObjectToArray(clone(state.rof.rofList));
    const allOrgs = state.organization.organizations;

    allRof.forEach(rof => {
        rof.rof_status = rofStatus[rof.stack_status]
        if (!isEmpty(allOrgs)) rof.organization_name = allOrgs[rof.organization_id].name
    })

    const currentUserOrgsArray = state.auth.userOrganizations
    const currentUserOrganizations = currentUserOrgsArray ?
        currentUserOrgsArray.reduce((obj, item) => {
            return {
                ...obj,
                [item["organization_id"]]: item,
            };
        }, {}) : {}

    return {
        currentUserType: state.auth.user.attributes['custom:user_type'],
        rofList: allRof,
        allRof: clone(state.rof.rofList),
        currentUserOrganizations: currentUserOrganizations,
        currentUserName: state.auth.user.username,
    };
};

export const RofManagementView = connect(
    mapStateToProps,
    rofManagementViewActionCreators
)(RofManagementViewBase);