import * as React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { IModalForm } from 'common/interfaces';
import css from 'components/Forms/FormDeleteRof/form-delete-rof.module.scss';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { useDispatch } from 'react-redux';

let initialValues = {
    deleteConfirmation: ''
};


export const FormDeleteOrg: React.FunctionComponent<IModalForm>
    = ({
        bindSubmitForm,
        formAction,
        defaultFormContent,
        confirmationString,
        setFormDisabledAttribute,
    }) => {
        const dispatch = useDispatch();

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    if (formAction) {
                        formAction(trimAllStringProperties(values));
                    }
                    setSubmitting(false);
                }}
                validationSchema={yup.object().shape({
                    deleteConfirmation: yup
                        .string()
                        .oneOf([confirmationString], 'Passwords must match')
                })}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                        submitForm
                    } = props;
                    if (bindSubmitForm) {
                        bindSubmitForm(submitForm);
                    }

                    let warningText = ""
                    let deleteType = ""
                    if (defaultFormContent) {
                        warningText = `Are you sure you want to delete this
                        organization and its contents ? This includes any
                        suborganizations, organization admins and users,
                            sandboxes, smart apps, smart app users.`
                        deleteType = "organization"
                    }
                    else {
                        warningText = `Are you sure you want to delete this
                        suborganization and its contents? This includes any
                        users, sandboxes, smart apps, smart app users.`
                        deleteType = "suborganization"
                    }

                    return (
                        <form onSubmit={handleSubmit}>
                            <p>
                                {warningText}
                            </p>
                            <p>
                                Please type{' '}
                                <b>{confirmationString}</b>{' '}
                                to confirm the deletion of the {deleteType}
                            </p>
                            <input
                                id='deleteConfirmation'
                                placeholder='Enter unique identifier to delete'
                                type='text'
                                value={values.deleteConfirmation}
                                onChange={e => {
                                    handleChange(e);
                                    const isDisabled =
                                        e.target.value !== confirmationString;
                                    setFormDisabledAttribute(isDisabled);
                                }}
                                onBlur={handleBlur}
                                className={css.confirmationInput}
                            />
                            <input type='submit' />
                        </form>
                    );
                }}
            </Formik>
        );
    };



