import React, { useEffect } from 'react';
import { ApplicationState } from 'stores';
import { IModalForm, IUser, IUserOrg } from 'common/interfaces';
import { connect, ResolveThunks, useDispatch } from 'react-redux';
import { TUserType } from 'common/types/TUserType';
import { superAdminActionCreators } from 'stores/actions/superAdminActions';
import { modalActionCreators } from 'stores/actions/modalActions';
import { AppFrame, InnerContainer, PageTitleArea } from 'components/Page';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Button } from 'components/Button';
import { Edit, Delete, Pageview } from '@material-ui/icons';
import { TSetErrorMessage } from 'common/types/TSetErrorMessage';
import { TSetFormLoadingAttribute } from 'common/types/TSetFormLoadingAttribute';
import { UserOrganizationListingTab } from './UserOrganizationListingTab/UserOrganizationListingTab';
import { FormEditPhoneNumber } from 'components/Forms/FormEditPhoneNumber/FormEditPhoneNumber';


const options = {
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: 'none',
    elevation: 5,
    rowHover: false,
    responsive: 'scroll',
    rowsPerPageOptions: [5, 10, 20, 50],
    rowsPerPage: 10,
    searchOpen: true,
};

interface UserManagementViewReduxProps {
    users: IUser[];
    currentUserType: TUserType;
    userOrgs: IUserOrg[];
}

const userManagementViewActionCreators = {
    ...superAdminActionCreators,
    ...modalActionCreators
};

export const UserManagementViewBase: React.FunctionComponent<
    IModalForm &
    UserManagementViewReduxProps &
    ResolveThunks<typeof userManagementViewActionCreators & typeof modalActionCreators>
> = ({
    createModal,
    deleteUser,
    updateUser,
    getAllUsers,
    getUserOrgs,
    users,
    userOrgs,
}) => {
        const dispatch = useDispatch();

        useEffect(() => {
            getAllUsers();
            getUserOrgs();
        }, []);

        const getMuiTheme = () => createMuiTheme({
            overrides: {
                MuiTable: {
                    root: {
                        zIndex: 1,
                    }
                },
                MuiTableCell: {
                    root: {
                        fontSize: "12px",
                        fontFamily: "sans-serif",
                        padding: "10px 25px",
                    },
                    head: {
                        fontSize: "14px",
                        fontFamily: "sans-serif",
                    },
                },
                MuiTablePagination: {
                    root: {
                        fontSize: '12px'
                    },
                    caption: {
                        fontSize: '12px'
                    }
                },

            }
        })

        const editUserAction = (
            updatedUser: IUser,
            organizationId: number,
            setErrorMessage: TSetErrorMessage,
            setFormLoadingAttribute: TSetFormLoadingAttribute,
        ) => {
            updateUser(
                updatedUser,
                organizationId,
                setErrorMessage,
                setFormLoadingAttribute
            );
        }

        const columns = [
            {
                name: "username",
                label: "Username",
                options: {
                    filter: true,
                    sort: true,
                    options: { sortDirection: 'asc' }
                }
            },
            {
                name: "email",
                label: "Email",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "phone_number",
                label: "Phone Number",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "organization_id",
                label: "Organization(s)",
                options: {
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <>
                                <Button style="link" onClick={(event) => {
                                    createModal(
                                        {
                                            title: `Organizations Assigned to ${tableMeta.rowData[0]}`,
                                            content: UserOrganizationListingTab,
                                            primaryButtonLabel: 'Done',
                                            hasSecondaryButton: false,
                                            defaultFormContent: tableMeta.rowData,
                                        },
                                        event
                                    );
                                }}><Pageview /></Button>
                            </>
                        )
                    }
                }
            },
            {
                name: "user_type",
                label: "Actions",
                options: {
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <>
                                <Button style="link" onClick={(event) => {
                                    createModal(
                                        {
                                            title: 'Edit details',
                                            content: FormEditPhoneNumber,
                                            primaryButtonLabel: 'Update User',
                                            defaultFormContent: tableMeta.rowData,
                                            formAction: editUserAction,
                                        },
                                        event
                                    );
                                }}>
                                    <Edit />
                                </Button>&nbsp;&nbsp;
                                <Button style="link" onClick={(event) => {
                                    const orgId = tableMeta.rowData[3];
                                    const username = tableMeta.rowData[0];
                                    createModal(
                                        {
                                            title: `Delete ${tableMeta.rowData[0]}'s Account`,
                                            description: () => (
                                                <p>Are you sure you want to delete this user account?</p>
                                            ),
                                            primaryButtonLabel: 'Delete Account',
                                            primaryButtonActionWithModalActions: (modalActions) => {
                                                deleteUser(
                                                    orgId,
                                                    username,
                                                    modalActions
                                                );
                                            },
                                            primaryButtonStyle: 'destructive',
                                        },
                                        event
                                    );
                                }} >
                                    <Delete />
                                </Button>
                            </>
                        )
                    }
                }
            }
        ];



        return (
            <AppFrame>
                <InnerContainer >
                    <PageTitleArea>
                        User Management
                    </PageTitleArea>

                    <MuiThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            title={""}
                            data={users}
                            columns={columns}
                            options={options}
                        />
                    </MuiThemeProvider>



                </InnerContainer>
            </AppFrame >
        );

    }


const mapStateToProps = (state: ApplicationState): UserManagementViewReduxProps => ({
    users: state.superAdmin.users,
    currentUserType: state.auth.user.attributes['custom:user_type'],
    userOrgs: state.superAdmin.userOrgs,
});

export const UserManagementView = connect(
    mapStateToProps,
    userManagementViewActionCreators
)(UserManagementViewBase);