import * as React from 'react';
import { Formik } from 'formik';
import { IModalForm } from 'common/interfaces';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { ErrorFocus } from 'components/ErrorFocus';
import { MedicationActionCreators } from 'stores/actions/personaBuilder/medicationActions';
import { Card, CardContent, Grid } from '@material-ui/core';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { medicationDetailsValidationSchema } from 'common/validation-schema/personaBuilder/medicationDetailsValidationSchema';
import css from './form-medication-details.module.scss';
import { IsFormDirty } from 'components/RouteGuards/IsFormDirty';

interface IFormMedicationDetailsState {
  isLoading: boolean;
}

interface IFormMedicationDetailsReduxProps {
  medication_forms: any;
}

export class FormMedicationDetailsBase extends React.PureComponent<
ResolveThunks<typeof MedicationActionCreators> &
IModalForm &
IFormMedicationDetailsReduxProps,
IFormMedicationDetailsState> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  setFormLoadingAttribute = (isLoading: boolean) => {
    this.setState({
      isLoading
    });
  };
  
  handleFormSubmit = (values, setSubmitting, resetForm) => {
    const { formAction, setErrorMessage } = this.props;
    this.setFormLoadingAttribute(true);
    if (formAction) {
      const trimmedValues = trimAllStringProperties(values);
      formAction(trimmedValues, setErrorMessage, this.setFormLoadingAttribute);
      resetForm(trimmedValues);
    }
    setSubmitting(false);
  };

  render() {
    let { setFormLoadingAttribute } = this.props;
    const {
      bindSubmitForm,
      formAction,
      defaultFormContent,
      setErrorMessage,
      medication_forms
    } = this.props;

    let initialValues = {
      proprietary_name: '',
      non_proprietary_name: '',
      substance_name: '',
      medication_ndc_code: '',
      medication_snomed_code: '',
      strength: '',
      ingredient_unit: '',
      medication_form_id: null
    };

    if (defaultFormContent) {
      initialValues = defaultFormContent;
    }

    const isNew = !('id' in initialValues);

    if (!setFormLoadingAttribute) {
      setFormLoadingAttribute = this.setFormLoadingAttribute;
    }
    
    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting, resetForm }) => this.handleFormSubmit(values, setSubmitting, resetForm)}
        validationSchema={medicationDetailsValidationSchema()}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            submitForm,
            isValid
          } = props;
          if (bindSubmitForm) {
            bindSubmitForm(submitForm);
          }
          return (
            <>
              <IsFormDirty
                title=""
                message=""
                dirty={dirty}
              />
              <form onSubmit={handleSubmit}>
                <Grid container className={css.formContainer}>
                  <Grid item xs={12} className={css.formColumn}>
                    <Card className={css.formCard}>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <label>Proprietary Name</label>
                            <input
                              id='proprietary_name'
                              placeholder='Proprietary Name'
                              type='text'
                              value={values.proprietary_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={errors.proprietary_name && touched.proprietary_name ? 'text-input error' : 'text-input'}
                              autoComplete='proprietary_name'
                              maxLength={128}
                            />
                            {errors.proprietary_name && touched.proprietary_name && (
                              <div className='input-feedback'>{errors.proprietary_name}</div>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>Non Proprietary Name</label>
                            <input
                              id='non_proprietary_name'
                              placeholder='Non Proprietary Name'
                              type='text'
                              value={values.non_proprietary_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={errors.non_proprietary_name && touched.non_proprietary_name ? 'text-input error' : 'text-input'}
                              autoComplete='non_proprietary_name'
                              maxLength={128}
                            />
                            {errors.non_proprietary_name && touched.non_proprietary_name && (
                              <div className='input-feedback'>{errors.non_proprietary_name}</div>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>Substance Name</label>
                            <input
                              id='substance_name'
                              placeholder='Substance Name'
                              type='text'
                              value={values.substance_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={errors.substance_name && touched.substance_name ? 'text-input error' : 'text-input'}
                              autoComplete='substance_name'
                              maxLength={128}
                            />
                            {errors.substance_name && touched.substance_name && (
                              <div className='input-feedback'>{errors.substance_name}</div>
                            )}
                          </Grid>
                          <Grid container>
                            <Grid item xs={6} className={css.gridItem}>
                              <label>NDC Code</label>
                              <input
                                id='medication_ndc_code'
                                placeholder='NDC Code'
                                type='text'
                                value={values.medication_ndc_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.medication_ndc_code && touched.medication_ndc_code ? 'text-input error' : 'text-input'}
                                autoComplete='medication_ndc_code'
                                maxLength={128}
                              />
                              {errors.medication_ndc_code && touched.medication_ndc_code && (
                                <div className='input-feedback'>{errors.medication_ndc_code}</div>
                              )}
                            </Grid>
                            <Grid item xs={6} className={css.gridItem}>
                              <label>SNOMED Code</label>
                              <input
                                id='medication_snomed_code'
                                placeholder='SNOMED Code'
                                type='text'
                                value={values.medication_snomed_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.medication_snomed_code && touched.medication_snomed_code ? 'text-input error' : 'text-input'}
                                autoComplete='medication_snomed_code'
                                maxLength={128}
                              />
                              {errors.medication_snomed_code && touched.medication_snomed_code && (
                                <div className='input-feedback'>{errors.medication_snomed_code}</div>
                              )}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={6} className={css.gridItem}>
                              <label>Strength</label>
                              <input
                                id='strength'
                                placeholder='Strength'
                                type='text'
                                value={values.strength}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.strength && touched.strength ? 'text-input error' : 'text-input'}
                                autoComplete='strength'
                                maxLength={128}
                              />
                              {errors.strength && touched.strength && (
                                <div className='input-feedback'>{errors.strength}</div>
                              )}
                            </Grid>
                            <Grid item xs={6} className={css.gridItem}>
                              <label>Strength Unit</label>
                              <input
                                id='ingredient_unit'
                                placeholder='Strength Unit'
                                type='text'
                                value={values.ingredient_unit}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.ingredient_unit && touched.ingredient_unit ? 'text-input error' : 'text-input'}
                                autoComplete='ingredient_unit'
                                maxLength={128}
                              />
                              {errors.ingredient_unit && touched.ingredient_unit && (
                                <div className='input-feedback'>{errors.ingredient_unit}</div>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <label>Form</label>
                            <select
                              name='medication_form_id'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.medication_form_id || ''}
                              className={errors.medication_form_id && touched.medication_form_id ? 'text-input error' : 'text-input'}
                            >
                              <option value={""}>Select</option>
                              {medication_forms.map((form, index) => (
                                <option key={index} value={form.id}>
                                  {form.medication_form_display}
                                </option>
                              ))}
                            </select>
                            {errors.medication_form_id && touched.medication_form_id && (
                              <div className='input-feedback'>{errors.medication_form_id}</div>
                            )}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <ErrorFocus/>
                </Grid>
              </form>
            </>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state: ApplicationState): IFormMedicationDetailsReduxProps => ({
  medication_forms: state.personaBuilderMedication.medication_dropdowns.medication_forms
});

export const FormMedicationDetails = connect(mapStateToProps,MedicationActionCreators)(FormMedicationDetailsBase);