import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { IModalForm, ISmartApp, IUser } from 'common/interfaces';
import { removePhoneNumberFormatting } from 'common/utils/removePhoneNumberFormatting';
import { OAuthScopeSelector } from 'components/FormElements/OAuthScopeSelector';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { RequiredIndicator } from 'components/FormElements/RequiredIndicator';
import * as yup from 'yup';
import { FormLabel } from 'components/FormElements/FormLabel';
import { connect, ResolveThunks, useDispatch } from 'react-redux';
import { ApplicationState } from 'stores';
import { EUpdateState } from 'stores/shared/ENums';
import { rofActionCreators } from 'stores/actions/rofActions';

export interface ISmartAppForm {
	name: string;
	id?: number;
	client_id?: string;
	client_type?: number;
	redirect_uris: string;
	registered_on?: string;
	grant_type: number;
	oauth_scopes: string | string[];
	oauth_server_uri?: string;
}

interface IFormSmartApp {
	rofId: number;
	pitId: number;
	smartApp: ISmartApp;
}

let initialValues = {
	name: '',
	redirect_uris: '',
	client_type: 3,
	grant_type: 1,
	oauth_scopes:
		'launch openid profile offline_access launch/patient launch/encounter patient/Patient.read patient/ExplanationOfBenefit.read patient/Coverage.read patient/Practitioner.read patient/PractitionerRole.read patient/Location.read patient/Organization.read',
	oauth_server_uri: '',
	client_id: null,
};

interface IFormRegisterSmartAppReduxProps {
	updateState: EUpdateState;
	errorMessage: string;
}

const formRegisterSmartAppActionCreators = {
	...rofActionCreators,
};

export const FormRegisterSmartAppBase: React.FunctionComponent<
	IModalForm &
		IFormRegisterSmartAppReduxProps &
		IFormSmartApp &
		ResolveThunks<typeof formRegisterSmartAppActionCreators>
> = ({
	bindSubmitForm,
	formAction,
	isMultistepModal,
	updateState,
	errorMessage,
	createSmartApp,
	setFormLoadingAttribute,
	setErrorMessage,
	rofId,
	pitId,
	smartApp,
}) => {
	const dispatch = useDispatch();
	const handleSubmit = (formData: ISmartAppForm, { setSubmitting }) => {
		setFormLoadingAttribute(true);
		dispatch(
			rofActionCreators[smartApp?.id ? 'updateSmartApp' : 'createSmartApp'](
				rofId,
				pitId,
				formData,
				setErrorMessage,
				setFormLoadingAttribute
			)
		);
	};

	const isEditMode = smartApp?.id ? true : false;

	useEffect(() => {
		setFormLoadingAttribute(updateState === EUpdateState.Loading);
	}, [updateState]);

	return (
		<Formik
			initialValues={{
				...initialValues,
				...smartApp,
				redirect_uris: smartApp?.redirect_uris.join(', ') || '',
			}}
			onSubmit={handleSubmit}
			validationSchema={yup.object().shape({
				name: yup.string().required('Required'),
				redirect_uris: yup.string().required('Required'),
			})}
		>
			{(props) => {
				const {
					values,
					touched,
					errors,
					dirty,
					isSubmitting,
					handleChange,
					handleBlur,
					handleSubmit,
					handleReset,
					submitForm,
					setFieldValue,
				} = props;
				if (bindSubmitForm) {
					bindSubmitForm(submitForm);
				}
				return (
					<form onSubmit={handleSubmit}>
						<FormLabel
							htmlFor='name'
							isRequired={true}
							subLabel='Symbols other than +=,.@- are not permitted.'
						>
							Name
						</FormLabel>

						<input
							id='name'
							placeholder='Enter a name to identify this SMART app'
							type='text'
							value={values.name}
							onChange={handleChange}
							onBlur={handleBlur}
							className={errors.name && touched.name ? 'text-input error' : 'text-input'}
						/>
						{errors.name && touched.name && <div className='input-feedback'>{errors.name}</div>}

						<FormLabel htmlFor='client_type'>
							Client Type
							<RequiredIndicator />
						</FormLabel>
						<select
							id='client_type'
							onChange={handleChange}
							onBlur={handleBlur}
							value={`${values.client_type}`}
							defaultValue={3}
							className={errors.client_type && touched.client_type ? 'text-input error' : 'text-input'}
						>
							<option value='' disabled>
								Please Choose Client Type
							</option>
							<option value={1}>Public</option>
							<option value={2}>Confidential - Forms Auth</option>
							<option value={3}>Confidential - Basic Auth</option>
						</select>
						{errors.client_type && touched.client_type && (
							<div className='input-feedback'>{errors.client_type}</div>
						)}

						<FormLabel htmlFor='grant_type'>
							Grant Type
							<RequiredIndicator />
						</FormLabel>
						<select
							id='grant_type'
							onChange={handleChange}
							onBlur={handleBlur}
							value={`${values.grant_type}`}
							className={errors.grant_type && touched.grant_type ? 'text-input error' : 'text-input'}
						>
							<option value='' disabled>
								Please Choose Grant Type
							</option>
							<option value={1} selected>
								Authorization Code
							</option>
							<option value={2}>Implicit</option>
							<option value={3}>Client Credentials</option>
							<option value={4}>Password</option>
						</select>
						{errors.grant_type && touched.grant_type && (
							<div className='input-feedback'>{errors.grant_type}</div>
						)}

						<FormLabel htmlFor='oauth_scopes' isRequired={true}>
							Allowed Scopes
						</FormLabel>
						{/* <input
								id='oauth_scopes'
								placeholder='Enter the master list of scopes this app can request.'
								type='text'
								value={values.oauth_scopes}
								onChange={handleChange}
								onBlur={handleBlur}
								className={
									errors.oauth_scopes && touched.oauth_scopes ? 'text-input error' : 'text-input'
								}
							/> */}
						<OAuthScopeSelector
							placeholder='Select Scope(s)'
							defaultValue={values.oauth_scopes}
							onChange={(value: String) => {
								setFieldValue('oauth_scopes', value);
							}}
						/>
						{errors.oauth_scopes && touched.oauth_scopes && (
							<div className='input-feedback'>{errors.oauth_scopes}</div>
						)}

						<FormLabel
							htmlFor='redirect_uris'
							isRequired={true}
							subLabel='Add multiple URI(s) by separating addresses with a comma.'
						>
							Redirect URI(s)
						</FormLabel>
						<textarea
							id='redirect_uris'
							placeholder='Enter redirect URI(s)'
							value={values.redirect_uris}
							onChange={handleChange}
							onBlur={handleBlur}
							rows={3}
							className={
								errors.redirect_uris && touched.redirect_uris ? 'text-input error' : 'text-input'
							}
						/>
						{errors.redirect_uris && touched.redirect_uris && (
							<div className='input-feedback'>{errors.redirect_uris}</div>
						)}
						<input type='submit' />
					</form>
				);
			}}
		</Formik>
	);
};

const mapStateToProps = (state: ApplicationState): IFormRegisterSmartAppReduxProps => ({
	updateState: state.rof.createSmartAppUpdateState,
	errorMessage: state.rof.createSmartAppErrorMessage,
});

export const FormSmartApp = connect(
	mapStateToProps,
	formRegisterSmartAppActionCreators
)(FormRegisterSmartAppBase);
