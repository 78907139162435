import * as React from 'react';
import classes from 'classnames';
import css from './action-icon.module.scss';

interface IActionIconProps {
	className?: string;
	onClick: any;
	ariaLabel: string;
	type?: 'button' | 'submit' | 'reset';
	disabled?: boolean;
	isDark?: boolean;
}

export const ActionIcon: React.FC<IActionIconProps> = props => {
	const {
		className,
		onClick,
		ariaLabel,
		children,
		type = 'button',
		disabled,
		isDark
	} = props;
	return (
		<button
			className={classes(className, css.actionItem, {
				[css.isDark]: isDark
			})}
			onClick={onClick}
			aria-label={ariaLabel}
			type={type}
			disabled={disabled}
		>
			{children}
		</button>
	);
};
