import * as yup from 'yup';

interface IHL7BodySiteValidationSchemaConfig {
}

interface HL7BodySiteValidationSchema {
	body_site: string;
	body_site_code: string;
}

export const hl7BodySiteValidationSchema = (
	hl7BodySiteValidationSchemaConfig: IHL7BodySiteValidationSchemaConfig = {}
) => {
	let schemaDefinition = {
		body_site: yup.string().required('Display is required'),
		body_site_code: yup.string().required('Code is required')
	};

	return yup.object().shape<any>(schemaDefinition) as any;
};
