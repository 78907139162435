import { Reducer } from 'redux';
import { SuperAdminState } from 'stores/stateInterfaces/superAdminStateInterface';
import SuperAdminActions from 'stores/actionInterfaces/superAdminActionInterfaces';
import { EUpdateState } from 'stores/shared/ENums';
import { clone } from 'ramda';


const initialState: SuperAdminState = {
    users: [],
    userOrgs: [],
};

export const SuperAdminReducers: Reducer<SuperAdminState> = (
    state: SuperAdminState,
    action: SuperAdminActions
) => {
    switch (action.type) {

        case 'GET_ALL_USERS_REQUEST': {
            return {
                ...state,
            };
        }
        case 'GET_ALL_USERS_RECEIVED': {
            const users = action.users

            return {
                ...state,
                users: users,
            };
        }

        case 'GET_USER_ORGS_REQUEST': {
            return {
                ...state,
            };
        }
        case 'GET_USER_ORGS_RECEIVED': {
            const usersOrgs = action.userOrgs

            return {
                ...state,
                userOrgs: usersOrgs,
            };
        }

        case 'DELETE_USER_ACCOUNT_REQUEST': {
            return {
                ...state
            };
        }

        case 'DELETE_USER_ACCOUNT_RESPONSE': {
            const allUsers = clone(state.users);
            const updatedUsers = allUsers.filter(
                user => user.username !== action.username
            );

            return {
                ...state,
                users: updatedUsers
            };
        }

        case 'UPDATE_USER_REQUEST': {
            return {
                ...state
            };
        }

        case 'UPDATE_USER_RECEIVED': {
            const users = action.users

            return {
                ...state,
                users: users,
            };
        }

        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }
    return state || initialState;
}