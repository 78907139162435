import { Reducer } from 'redux';
import { EUpdateState } from 'stores/shared/ENums';
import PersonaUploadActions from 'stores/actionInterfaces/personaUploadActionInterfaces';
import { PersonaUploadState } from 'stores/stateInterfaces/personaUploadStateInterfaces';
import { clone } from 'ramda';

const initialState: PersonaUploadState = {
	filename: null,
	filesize: 0,
	details: null,
	file: null,
	packageKey: null,
	packageErrorType: null,
	packageErrorMessage: null,
	updateErrorType: null,
	updateErrorMessage: null,
	updateState: EUpdateState.Empty,
	imagePreviewUrl: null,
	imageFilename: null,
	imageFilesize: 0,
	imageFile: null,
	imageErrorType: null,
	imageErrorMessage: null,
	tempUploadInfo: null,
	tempUploadInfoUpdateState: EUpdateState.Empty
};

export const PersonaUploadReducers: Reducer<
	PersonaUploadState,
	PersonaUploadActions
> = (state: PersonaUploadState, action: PersonaUploadActions) => {
	switch (action.type) {
		case 'UPDATE_FILE_REQUEST': {
			return {
				...state,
				filename: action.filename,
				filesize: action.filesize,
				file: action.file
			};
		}
		case 'UPDATE_FILE_RESPONSE': {
			return {
				...state,
				packageKey: action.packageKey,
				packageErrorType: action.errorType,
				packageErrorMessage: action.errorMessage
			};
		}
		case 'UPDATE_DETAILS': {
			return {
				...state,
				details: clone(action.details)
			};
		}
		case 'CLEAR_FILE': {
			return {
				...state,
				filename: null,
				filesize: null,
				file: null,
				packageKey: null,
				packageErrorType: null,
				packageErrorMessage: null
			};
		}
		case 'CLEAR_IMAGE': {
			return {
				...state,
				imagePreviewUrl: null,
				imageFilename: null,
				imageFilesize: null,
				imageFile: null,
				isImageUploaded: false,
				imageErrorType: null,
				imageErrorMessage: null
			};
		}
		case 'CLEAR_ALL': {
			return clone(initialState);
		}
		case 'CREATE_PERSONA_REQUEST': {
			return {
				...state,
				updateState: EUpdateState.Updating,
				updateErrorType: null,
				updateErrorMessage: null
			};
		}
		case 'CREATE_PERSONA_RESPONSE': {
			return {
				...state,
				updateState: action.errorType
					? EUpdateState.Failed
					: EUpdateState.Updated,
				updateErrorType: action.errorType,
				updateErrorMessage: action.errorMessage
			};
		}
		case 'SELECT_IMAGE': {
			return {
				...state,
				imagePreviewUrl: action.imagePreviewUrl,
				imageFilename: action.filename,
				imageFilesize: action.filesize,
				imageFile: action.file,
				imageErrorType: action.errorType,
				imageErrorMessage: action.errorMessage
			};
		}
		case 'UPDATE_IMAGE_REQUEST': {
			return {
				...state,
				isImageUploaded: false
			};
		}
		case 'UPDATE_IMAGE_RESPONSE': {
			return {
				...state,
				imageErrorType: action.errorType,
				imageErrorMessage: action.errorMessage
			};
		}

		case 'GET_TEMPORARY_UPLOAD_INFO_REQUEST': {
			return {
				...state,
				tempUploadInfoUpdateState: EUpdateState.Loading
			};
		}

		case 'GET_TEMPORARY_UPLOAD_INFO_RESPONSE': {
			return {
				...state,
				tempUploadInfoUpdateState: EUpdateState.Loaded,
				tempUploadInfo: action.tempUploadInfo
			};
		}

		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state || initialState;
};
