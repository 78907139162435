import * as React from 'react';
import { Authenticator } from 'aws-amplify-react';

import { authActionCreators } from 'stores/actions/authActions';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';

import { Login } from 'components/Auth/Login';

import { Auth } from 'aws-amplify';
const loginActionCreators = {
	...authActionCreators,
};

interface ILoginProps {
	user: any;
}

class CustomAuthenticator extends React.PureComponent<
	RouteComponentProps<any> & ILoginProps & ResolveThunks<typeof loginActionCreators>
> {
	redirectUri: string;

	UNSAFE_componentWillMount() {
		const values = queryString.parse(this.props.location.search);
		this.redirectUri = values.redirect as string;
	}

	render() {
		return (
			<Authenticator hideDefault>
				<Login redirectUri={this.redirectUri} />
			</Authenticator>
		);
	}
}

const mapStateToProps = (state: ApplicationState): ILoginProps => ({
	user: state.auth.user,
});

export const LoginView = connect(mapStateToProps, loginActionCreators)(CustomAuthenticator);
