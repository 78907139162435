import { Auth } from "aws-amplify";
import { authActionCreators } from "stores/actions/authActions";
import { sharedStore } from "sharedStore";

export const getCurrentJwt = async () => {
	const getAuth = async (): Promise<any> => {
		try {
			const currentUserSession = await Auth.currentSession();

			return currentUserSession;
		} catch (err) {
			console.log("expired Session");
			sharedStore.dispatch(authActionCreators.signOut());
		}
	};

	const currentUserIdToken = await getAuth();

	if (currentUserIdToken && currentUserIdToken.idToken) {
		return currentUserIdToken.idToken.jwtToken;
	}
};
