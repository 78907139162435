import * as React from 'react';
import classes from 'classnames';
import css from './inner-container.module.scss';

interface InnerContainerProps {
	className?: string;
}
export const InnerContainer: React.FC<InnerContainerProps> = props => {
	return <div className={classes(css.innerContainer, props.className)}>{props.children}</div>;
};
