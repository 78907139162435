import * as React from 'react';
import { FormCreateCodeSample } from 'components/Forms/FormCreateCodeSample';
import { IModal } from 'common/interfaces';
import { ICodeSample } from 'common/interfaces';

export const editCodeSampleModal = (codeSample: ICodeSample): IModal => ({
  title: 'Update this Code Sample',
  primaryButtonLabel: 'Save Changes',
  description:
    'Please make changes below and press "Save Changes" when finished',
  content: props => (
    <FormCreateCodeSample codeSample={codeSample} {...props} />
  )
});
