import { AppThunkAction } from '../../index';
import providerActions from '../../actionInterfaces/personaBuilder/providerActionInterfaces';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { url } from 'settings';
import { IProvider } from 'common/interfaces/personaBuilder/IProvider';
import { IProviderMetadata } from 'common/interfaces/personaBuilder/IProviderMetadata';

export const ProviderActionCreators = {
	getAllProviders: (
	): AppThunkAction<providerActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const getProviders = await fetch(`${url}providers`, {
					method: 'GET',
					headers
				});

				const response = await getProviders.json();

				if (getProviders.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_ALL_PROVIDERS_RECEIVED',
					providers: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	},

	getProvider: (
		id: string | number
	): AppThunkAction<providerActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const getProvider = await fetch(`${url}providers/${id}`, {
					method: 'GET',
					headers
				});

				const response = await getProvider.json();

				if (getProvider.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_PROVIDER_RECEIVED',
					provider: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	},

	createProvider: (
		provider: IProvider
	):  AppThunkAction<providerActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const createProvider = await fetch(`${url}providers/create`, {
					method: 'POST',
					body: JSON.stringify({...provider, provider_schedule: '9:00am-5:00pm', appointment_length: '60 Minutes'}),
					headers
				});

				const response = await createProvider.json();
				if (createProvider.ok === false) {
					return;
				}
				
				// response simply contains provider_id
				return response;

			} catch (e) {
				console.log(e);
			}
		};
	},

	updateProvider: (
		provider: IProvider
	):  AppThunkAction<providerActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const updateProvider = await fetch(`${url}providers/${provider.id}`, {
					method: 'PUT',
					body: JSON.stringify(provider),
					headers
				});
				if (updateProvider.ok === false) {
					return;
				}
			} catch (e) {
				console.log(e);
			}
		};
	},

	deleteProvider: (
		id: string):  AppThunkAction<providerActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const deleteProvider = await fetch(`${url}providers/${id}`, {
					method: 'DELETE',
					headers
				});

				if (deleteProvider.ok === false) {
					return;
				}
			} catch (e) {
				console.log(e);
			}
		};
	},
	
	getProviderTimeSlots: (
		id: string
	): AppThunkAction<providerActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const getTimeSlots = await fetch(`${url}providers/${id}/provider_time_slots`, {
					method: 'GET',
					headers
				});

				const response = await getTimeSlots.json();

				if (getTimeSlots.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_PROVIDER_TIMESLOTS_RECEIVED',
					providerTimeSlots: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	},

	getProviderMetadata: (id: string | number): AppThunkAction<providerActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const getMetadata = await fetch(`${url}providers/${id}/metadata`, {
					method: 'GET',
					headers
				});

				const response = await getMetadata.json();

				if (getMetadata.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_PROVIDER_METADATA_RECEIVED',
					providerMetadata: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	},

	createProviderMetadata: (
		provider: IProvider,
		metadata: IProviderMetadata
	):  AppThunkAction<providerActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				// pass use_cases into this param: use_case_list
				metadata.use_case_list = metadata.use_cases.map(u=>{ return +u.id });

				const createMetadata = await fetch(`${url}providers/${provider.id}/metadata/create`, {
					method: 'POST',
					body: JSON.stringify(metadata),
					headers
				});

				const response = await createMetadata.json();
				if (createMetadata.ok === false) {
					return;
				}
				
				// response simply contains provider_id
				return response;

			} catch (e) {
				console.log(e);
			}
		};
	},

	updateProviderMetadata: (
		provider: IProvider,
		metadata: IProviderMetadata
	):  AppThunkAction<providerActions, Promise<any>> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				// pass use_cases into this param: use_case_list
				metadata.use_case_list = metadata.use_cases.map(u=>{ return +u.id });

				const updateMetadata = await fetch(`${url}providers/${provider.id}/metadata/${metadata.id}`, {
					method: 'PUT',
					body: JSON.stringify(metadata),
					headers
				});
				if (updateMetadata.ok === false) {
					return;
				}
			} catch (e) {
				console.log(e);
			}
		};
	},

	getProviderMetadataUseCases: (id: string | number): AppThunkAction<providerActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const getMetadataUseCases = await fetch(`${url}providers/${id}/use_cases`, {
					method: 'GET',
					headers
				});

				const response = await getMetadataUseCases.json();

				if (getMetadataUseCases.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_PROVIDER_METADATA_USE_CASES_RECEIVED',
					providerUseCases: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	},

	populateProviderDropdowns:() : AppThunkAction<providerActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				Promise.all([
						fetch(`${url}organizations`, {method: 'GET', headers}),
						fetch(`${url}providers/practitioner_roles`, {method: 'GET', headers}),
						fetch(`${url}providers/practice_codes`, {method: 'GET', headers}),
						fetch(`${url}providers/qualifications`, {method: 'GET', headers}),
						fetch(`${url}patients/valuesets/genders`, {method: 'GET', headers}),
						fetch(`${url}patients/valuesets/persona_use_cases`, {method: 'GET', headers}),
					]).then(async ([organizations, practitioner_roles, practice_codes, qualifications, genders, use_cases]) => {
					const dropdowns = {
						organizations: await organizations.json(),
						practitioner_roles: await practitioner_roles.json(),
						practice_codes: await practice_codes.json(),
						qualifications: await qualifications.json(),
						genders: await genders.json(),
						use_cases: await use_cases.json()
					};

					return dispatch({
						type: 'GET_PROVIDER_DROPDOWNS_RECEIVED',
						provider_dropdowns: dropdowns
					});

				});


			} catch (e) {
				console.log(e);
			}
		};
	},

};
