import * as React from 'react';
import { Circle } from 'components/Circle';
import classes from 'classnames';
import css from './grid-item-content-add.module.scss';

interface IGridItemContentAdd {
	label: string;
	secondaryLabel?: string;
}

export const GridItemContentAdd: React.FC<IGridItemContentAdd> = props => {
	const { label, secondaryLabel } = props;
	return (
		<>
			<Circle type='action' isCentered={true}>
				+
			</Circle>
			<p className={css.label}>{label}</p>
			{secondaryLabel && <p className={css.labelSecondary}>{secondaryLabel}</p>}
		</>
	);
};
