import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { push } from 'connected-react-router';
import { authActionCreators } from 'stores/actions/authActions';
import { ApplicationState } from 'stores';

interface IIsUserOfTypeComponentReduxProps {
	userType: string;
}

const IsUserOfTypeComponentActionCreators = {
	...authActionCreators,
	push
};

export const IsUserOfType = (WrappedComponent, userTypes: string[]) => {
	class IsUserOfTypeComponent extends React.Component<
		IIsUserOfTypeComponentReduxProps &
		ResolveThunks<typeof IsUserOfTypeComponentActionCreators>
	> {
		UNSAFE_componentWillMount() {
			this.props.getTerms();
		}

		componentDidMount() {
			this._checkAndRedirect();
		}

		componentDidUpdate() {
			this._checkAndRedirect();
		}

		_isUserAllowed() {
			const { userType } = this.props;
			return userTypes.includes(userType);
		}

		_checkAndRedirect() {
			if (!this._isUserAllowed()) {
				this.props.push('/');
			}
		}

		render() {
			return (
				<div className='authenticated'>
					{this._isUserAllowed() ? (
						<WrappedComponent {...this.props} />
					) : null}
				</div>
			);
		}
	}

	const mapStateToProps = (
		state: ApplicationState
	): IIsUserOfTypeComponentReduxProps => {
		return {
			//@ts-ignore
			userType: state.auth.user.attributes['custom:user_type']
		};
	};

	return connect(
		mapStateToProps,
		IsUserOfTypeComponentActionCreators
	)(IsUserOfTypeComponent);
};
