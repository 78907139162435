import React, { useState } from 'react';
import { Prompt, Router } from 'react-router';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { sharedHistory } from 'sharedStore';
import { MuiButton } from 'components/MaterialUI';
import { Button } from 'components/Button';

interface IsFormDirtyProps {
  title?: string;
  message?: string;
  dirty: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      textAlign: 'center'
    },
  }),
);

export const IsFormDirty: React.FC<IsFormDirtyProps> = props => {
  const { dirty } = props;
  const classes = useStyles(props);
  const [modalOpen, setModalOpen] = useState(false);
  const [location, setLocation] = useState(null);

  let _discard = false;

  const handleBlockedNavigation = (nextLocation) => {
    if (dirty && !_discard) {
      openModal(nextLocation);
      return false;
    }
    return true;
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const openModal = (nextLocation) => {
    setLocation(nextLocation);
    setModalOpen(true);
  };
  
  const discardChanges = () => {
    closeModal();
    _discard = true;
    sharedHistory.push(location?.pathname);
  };

  return (
    <>
      <Router history={sharedHistory}>
        <Prompt
          when={dirty}
          message={handleBlockedNavigation}
        />
      </Router>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalOpen}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <div className={classes.paper}>
            <h4>{props.title || "It looks like you’re trying to move on to other sections."}</h4>
            <p>{props.message || "Please complete the required input fields."}</p>
            <p><MuiButton variant="contained" onClick={closeModal}>Go Back &amp; Finish Input</MuiButton></p>
            <p><MuiButton variant="contained" onClick={discardChanges}>Discard Changes &amp; Continue</MuiButton></p>
            <p><Button style="link" onClick={closeModal}>Cancel</Button></p>
          </div>
        </Fade>
      </Modal>
    </>
  );
}