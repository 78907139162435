import * as React from 'react';
import { ChartC3 } from 'components/ChartC3';

export class VisualizationsTab extends React.PureComponent {
	render() {
		const age_range_counts_json = [
			{ range: '0-4', count: 100 },
			{ range: '5-9', count: 200 },
			{ range: '10-14', count: 225 },
			{ range: '15-19', count: 230 },
			{ range: '20-24', count: 220 },
			{ range: '25-29', count: 250 },
			{ range: '30-34', count: 275 },
			{ range: '35-39', count: 300 },
			{ range: '40-44', count: 285 },
			{ range: '45-49', count: 255 },
			{ range: '50-54', count: 215 },
			{ range: '55-59', count: 195 },
			{ range: '60-64', count: 180 },
			{ range: '65-69', count: 150 },
			{ range: '70-74', count: 120 },
			{ range: '75-79', count: 100 }
		];

		return (
			<>
				<div>Visualizations Tab</div>
				<ChartC3
					data={{
						json: age_range_counts_json,
						keys: {
							x: 'range',
							value: ['count'] // this must be an array even if there's only one value key
						},
						type: 'bar'
					}}
					axis={{
						x: {
							label: {
								position: 'outer-center',
								text: 'Age range'
							},
							type: 'category'
						},
						y: {
							label: {
								position: 'outer-middle',
								text: 'Count'
							}
						}
					}}
					bar={{
						width: {
							ratio: 0.9
						}
					}}
				/>
			</>
		);
	}
}
