import * as React from 'react';

import { AppFrame } from 'components/Page';

import { rofActionCreators } from 'stores/actions/rofActions';
import { modalActionCreators } from 'stores/actions/modalActions';

import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { ICognitoUser, IModal, IOrganization } from 'common/interfaces';

import { GridContainer, GridItem, GridItemContentAdd } from 'components/Grid';
import css from './dashboard-view.module.scss';
import { EUpdateState } from 'stores/shared/ENums';
import { InnerContainer } from 'components/Page';
import { isEmpty } from 'ramda';
import { filterRofWithIds } from 'common/utils/filterRofWithIds';
import { IRof } from 'common/interfaces/IRof';
import { createNewROFModal } from 'common/modals/createNewRofModal';
import { noEntitlementAvailableModal } from 'common/modals/noEntitlementAvailableModal';
import { clone } from 'ramda';
import { convertIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';
import { canCreateRof, allowRofCreation } from 'common/utils/canCreateRof';
import { ICanCreateRof } from 'common/interfaces/ICanCreateRof';
import { Section } from 'components/Section/Section';
import { organizationActionCreators } from 'stores/actions/organizationActions';
import { Button } from 'components/Button';
import { addSubOrganizationModal } from 'common/modals/addSubOrganizationModal';
import { ButtonLink } from 'components/Button/ButtonLink';
import { deleteRofModal } from 'common/modals/deleteRofModal';
import { GridItemContentSandbox } from 'components/Grid/GridItemContentSandbox/GridItemContentSandbox';
import { IEntitlementSummary } from 'common/interfaces/IEntitlementSummary';
import { GridItemSandbox } from 'components/Grid/GridItemSandbox/GridItemSandbox';
import { PageTitleBanner } from 'components/Page/PageTitleBanner';
import { TUserType } from 'common/types/TUserType';
import { Loading } from 'components/Loading';
import { ReactComponent as IconExternalLink } from 'assets/icons/icon-external-link.svg';

const dashboardViewActionCreators = {
	...rofActionCreators,
	...modalActionCreators,
	...organizationActionCreators,
};
interface IDashboardViewReduxProps {
	allRof: IRof[];
	currentUpdateState: EUpdateState;
	currentUsersOrganizationId: number;
	canCreateRof: ICanCreateRof;
	organizationName: string;
	currentSubOrganizations: IOrganization[];
	currentOrganizationSuborganizationLoadingstate: EUpdateState;
	entitlementSummary: IEntitlementSummary;
	entitlementSummaryLoadingState: EUpdateState;
	currentUserType: TUserType;
	user: ICognitoUser;
}

class DashboardViewBase extends React.PureComponent<
	IDashboardViewReduxProps &
	ResolveThunks<typeof dashboardViewActionCreators & typeof modalActionCreators>
> {
	UNSAFE_componentWillMount() {
		this.props.setCurrentRofId(null);
		this.props.getOrganizationRof(this.props.currentUsersOrganizationId);
		this.props.getSuborganizations(this.props.currentUsersOrganizationId);
		this.props.setCurrentOrganization(this.props.user.attributes.organizationId)
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.currentOrganizationSuborganizationLoadingstate === EUpdateState.Loaded &&
			prevProps.currentOrganizationSuborganizationLoadingstate !== EUpdateState.Loaded
		) {
			const currentSubOrganizationsArray = convertIndexedObjectToArray(
				this.props.currentSubOrganizations
			);

			const currentSubOrganizationsIds = currentSubOrganizationsArray.map(
				subOrganization => subOrganization.id
			);

			this.props.getOrganizationsRof(currentSubOrganizationsIds);
		}
	}

	isLoading = () => {
		const { entitlementSummaryLoadingState } = this.props;
		return (
			entitlementSummaryLoadingState === EUpdateState.Loading ||
			entitlementSummaryLoadingState === EUpdateState.Empty
		);
	};

	getOrganizationDescription = (
		organizationSandboxAmount: number,
		isSuborganization: boolean = false
	): string => {
		return `${organizationSandboxAmount} sandboxes created in this ${isSuborganization ? 'sub' : ''}
			organization. Once created, a sandbox cannot be moved.`;
	};

	getWelcomeDescriptions = (): {
		description: string;
		subDescription?: string;
	} => {
		if (this.props.canCreateRof.hasEntitlement && this.props.canCreateRof.hasPrivilege) {
			return {
				description: 'Welcome to Interoperability Land\u2122. Create and access your Sandboxes below.',
				subDescription:
					'Sandboxes must be assigned to an organization or suborganization and cannot be shared between multiple organizations. To move a sandbox to a new organization, delete the original instance and recreate it within the desired location.'
			};
		} else if (this.props.canCreateRof.hasPrivilege) {
			return {
				description:
					"Welcome to Interoperability Land\u2122. You no longer have any Sandboxes left to create.",
				subDescription:
					'All sandboxes are currently in use. To create a new sandbox, you must first delete an existing instance. Sandboxes must be assigned to an organization or suborganization and cannot be shared between multiple organizations. To move a sandbox to a new organization, delete the original instance and recreate it within the desired location.'
			};
		} else {
			return {
				description:
					'Welcome to Interoperability Land\u2122. Please contact your administrator to create a Sandbox.'
			};
		}
	};

	render() {
		const {
			createModal,
			allRof,
			currentUpdateState,
			currentUsersOrganizationId,
			canCreateRof,
			organizationName,
			currentSubOrganizations,
			createOrganization,
			currentUserType,
			entitlementSummaryLoadingState,
			entitlementSummary,

		} = this.props;


		const createSandboxTile = (organizationId: number) => {
			return canCreateRof.hasPrivilege && canCreateRof.hasEntitlement ? (
				<GridItem type='create' action={allowRofCreation(entitlementSummary, currentUserType) ?
					event => createModal(createNewROFModal(organizationId), event) :
					event => createModal(noEntitlementAvailableModal(entitlementSummary), event)}>
					<GridItemContentAdd
						label='Create Sandbox'
						secondaryLabel={
							(entitlementSummaryLoadingState === EUpdateState.Loaded) && (entitlementSummary.rof_purchased !== null)
								? `${entitlementSummary.rof_purchased - entitlementSummary.rof_count} Remaining`
								: null
						}
					/>
				</GridItem>
			) : null;
		};

		const currentUserOrganizationSandbox = allRof.filter(
			ringOfFhir => ringOfFhir.organization_id === currentUsersOrganizationId
		);

		if (this.isLoading()) {
			return (
				<AppFrame>
					<Loading />
				</AppFrame>
			);
		}

		return (
			<AppFrame
				ContentTop={() => (
					<PageTitleBanner
						title='Welcome'
						description={this.getWelcomeDescriptions().description}
						subDescription={this.getWelcomeDescriptions().subDescription}
						background='heartAndChip'
					/>
				)}
			>
				<InnerContainer className={css.organizationList}>
					<Section
						title={organizationName}
						description={this.getOrganizationDescription(currentUserOrganizationSandbox.length)}
						ContentRight={
							currentUserType === 'mihin-admin' || currentUserType === 'org-admin'
								? () => (
									<Button
										onClick={event =>
											createModal(
												addSubOrganizationModal(currentUsersOrganizationId, createOrganization),
												event
											)
										}
									>
										Create Suborganization
									</Button>
								)
								: null
						}
					>
						<GridContainer>
							{createSandboxTile(currentUsersOrganizationId)}
							{currentUserOrganizationSandbox.map((sandbox, index) => (
								<GridItemSandbox sandbox={sandbox} key={index} />
							))}
						</GridContainer>
					</Section>
					{currentSubOrganizations &&
						currentSubOrganizations.map((subOrganization, index) => {
							const currentSubOrganizationSandboxes = allRof.filter(
								ringOfFhir => ringOfFhir.organization_id === subOrganization.id
							);
							return (
								<Section
									title={subOrganization.name}
									key={index}
									ContentRight={
										currentUserType === 'mihin-admin' || currentUserType === 'org-admin'
											? () => (
												<ButtonLink to={`organization/${subOrganization.id}`} style='secondary'>
													Manage Suborganization
												</ButtonLink>
											)
											: null
									}
									description={this.getOrganizationDescription(
										currentSubOrganizationSandboxes.length,
										true
									)}
								>
									<GridContainer>
										{createSandboxTile(subOrganization.id)}
										{currentSubOrganizationSandboxes.map((sandbox, index) => (
											<GridItemSandbox sandbox={sandbox} key={index}></GridItemSandbox>
										))}
									</GridContainer>
								</Section>
							);
						})}
				</InnerContainer>
			</AppFrame>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IDashboardViewReduxProps => {
	const currentUsersOrganizationId = state.organization.currentOrganization ? state.organization.currentOrganization : state.auth.user.attributes.organizationId;
	const currentOrganization = state.organization.organizations[state.organization.currentOrganization]
		? state.organization.organizations[state.organization.currentOrganization]
		: null;
	const currentUserType = currentOrganization ? currentOrganization.user_type : state.auth.user.attributes['custom:user_type']
	const allRof = convertIndexedObjectToArray(clone(state.rof.allRof));

	const currentUpdateState = state.rof.organizationsRofLoadingState[currentUsersOrganizationId];

	const currentSubOrganizations = convertIndexedObjectToArray(state.organization.organizations).filter(
		organization => organization.parent_organization_id === currentUsersOrganizationId
	);


	return {
		allRof: allRof ? allRof : [],
		currentUpdateState,
		currentUsersOrganizationId,
		canCreateRof: canCreateRof(state.auth.user.attributes['custom:user_type']),
		organizationName: state.auth.organization ? state.auth.organization.name : null,
		currentSubOrganizations,
		currentOrganizationSuborganizationLoadingstate:
			state.organization.organizationSuborganizationLoadingState[currentUsersOrganizationId],
		entitlementSummary: state.auth.entitlementSummary,
		entitlementSummaryLoadingState: state.auth.entitlementSummaryLoadingState,
		currentUserType: state.auth.user.attributes['custom:user_type'],
		user: state.auth.user,
	};
};

export const DashboardView = connect(mapStateToProps, dashboardViewActionCreators)(DashboardViewBase);
