import { AppThunkAction } from '../../index';
import organizationActions from '../../actionInterfaces/personaBuilder/organizationActionInterfaces';
import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';
import { url } from 'settings';
import { IOrganization } from 'common/interfaces/personaBuilder/IOrganization';
import { sharedHistory } from 'sharedStore';
import { modalActionCreators } from 'stores/actions/modalActions';

export const OrganizationActionCreators = {
	getAllOrganizations: (
	): AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const getPatients = await fetch(`${url}organizations`, {
					method: 'GET',
					headers
				});

				const response = await getPatients.json();

				if (getPatients.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_ALL_ORGANIZATIONS_RECEIVED',
					organizations: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	},

	getOrganization: (
		id: string
	): AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const getPatient = await fetch(`${url}organizations/${id}`, {
					method: 'GET',
					headers
				});

				const response = await getPatient.json();

				if (getPatient.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_ORGANIZATION_RECEIVED',
					organization: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	},

	createOrganization: (
		organization: IOrganization
	):  AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const createOrganization = await fetch(`${url}organizations/create`, {
					method: 'POST',
					body: JSON.stringify(organization),
					headers
				});

				const response = await createOrganization.json();

				if (createOrganization.ok === false) {
					return;
				}

				console.log("ORGANIZATION CREATED: ", response);
				sharedHistory.push(`/persona-builder/organization-listing`);

			} catch (e) {
				console.log(e);
			}
		};
	},

	updateOrganization: (
		organization: IOrganization
	):  AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');


				const updateOrganization = await fetch(`${url}organizations/${organization.id}`, {
					method: 'PUT',
					body: JSON.stringify(organization),
					headers
				});

				const response = await updateOrganization.json();

				if (updateOrganization.ok === false) {
					return;
				}

				return dispatch({
					type: 'GET_ORGANIZATION_RECEIVED',
					organization: response
				});

			} catch (e) {
				console.log(e);
			}
		};
	},
	deleteOrganization: (
		id: string):  AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');
				const deleteValueset = await fetch(`${url}organizations/${id}`, {
					method: 'DELETE',
					headers
				});

				if (deleteValueset.ok === false) {
					return;
				}
				//@ts-ignore
				dispatch(modalActionCreators.dismissModal());
				sharedHistory.push(`/persona-builder/organization-listing`);
			} catch (e) {
				console.log(e);
			}
		};
	},
	populateDropdowns:() : AppThunkAction<organizationActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				Promise.all([
						fetch(`${url}organizations/organization_types`, {method: 'GET', headers}),
						fetch(`${url}organizations/pits`, {method: 'GET', headers}),
						fetch(`${url}organizations/parent_organizations`, {method: 'GET', headers}),
					]).then(async ([organization_types, pits, parent_organizations]) => {
					const dropdowns = {
						organization_types: await organization_types.json(),
						pits: await pits.json(),
						parent_organizations: await parent_organizations.json()
					};

					return dispatch({
						type: 'GET_ORG_DROPDOWNS_RECEIVED',
						org_dropdowns: dropdowns
					});

				});


			} catch (e) {
				console.log(e);
			}
		};
	},

	setSaveDisabled:(saveDisabled: boolean) => {
		return async (dispatch, getState) => {
			return dispatch({
				type: 'SET_SAVE_DISABLED',
				saveDisabled: saveDisabled
			});
		};
	}
};
