import * as React from 'react';
import { IModal } from 'common/interfaces';

export const deleteSmartAppModal = (
	client_id: string,
	context: { rofId: number; pitId: number },
	deleteSmartApp,
	onFinishRedirectToSmartAppTab?: boolean
): IModal => {
	const { rofId, pitId } = context;
	return {
		title: 'Delete SMART App',
		content: () => (
			<p>
				This action is permanent. You will need to re-register your
				SMART app to use it again.
			</p>
		),
		primaryButtonStyle: 'destructive',
		primaryButtonLabel: 'Delete',
		primaryButtonActionWithModalActions: modalActions => {
			deleteSmartApp(
				client_id,
				rofId,
				pitId,
				modalActions,
				onFinishRedirectToSmartAppTab
			);
		}
	};
};
