import * as React from 'react';

import { AppFrame } from 'components/Page';
import { InnerContainer } from 'components/Page';
import { connect, ResolveThunks } from 'react-redux';
import css from './page-not-found-view.module.scss';
import { Link } from 'react-router-dom';
import { PageTitleBanner } from 'components/Page/PageTitleBanner/PageTitleBanner';

class PageNotFoundBase extends React.PureComponent {
	render() {
		return (
			<AppFrame
				ContentTop={() => (
					<PageTitleBanner
						title='404 Page Not Found'
						description='The requested URL was not found on this server.'
						background='heartAndChip'
					>
						<Link className={css.backLink} to='/'>
							&lt; Back to Home
						</Link>
					</PageTitleBanner>
				)}
			></AppFrame>
		);
	}
}

export const PageNotFoundView = connect()(PageNotFoundBase);
