import * as React from 'react';
import { FormCreateApiDoc } from 'components/Forms/FormCreateApiDoc';
import { IModal } from 'common/interfaces';

export const createApiDocModal = (): IModal => ({
  title: 'Add a new API',
  primaryButtonLabel: 'Add API',
  description:
    'Please fill out the form below to add a new entry to the API Library',
  content: props => (
    <FormCreateApiDoc {...props} />
  )
});
