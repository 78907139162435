import React, { useEffect } from 'react';
import { connect, ResolveThunks, useDispatch } from 'react-redux';
import { AppFrame, InnerContainer } from 'components/Page';
import { PageTitleBanner } from 'components/Page/PageTitleBanner/PageTitleBanner';
import { Section } from 'components/Section/Section';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { notificationActionCreators } from 'stores/actions/notificationActions';
import { modalActionCreators } from 'stores/actions/modalActions';
import css from './notification-view.module.scss';
import { IModal, IModalForm } from 'common/interfaces';
import { INotification } from 'common/interfaces/INotification';
import { ApplicationState } from 'stores';
import parse from 'html-react-parser';
import { TUserType } from 'common/types/TUserType';
import { Panel } from 'components/Panel';
import { FormCreateNotification } from 'components/Forms/FormCreateNotification/FormCreateNotification';
import { Button as LargeButton } from 'components/Button'
import { Message } from 'components/Message';

interface INotificationViewReduxProps {
    notificationAlerts: INotification[];
    currentUserType: TUserType;
}

const notificationViewActionCreators = {
    ...notificationActionCreators,
    ...modalActionCreators
};


export const NotificationsViewBase: React.FunctionComponent<
    IModalForm &
    INotificationViewReduxProps &
    ResolveThunks<typeof notificationViewActionCreators & typeof modalActionCreators>
> = ({ notificationAlerts, currentUserType, createModal }) => {

    const dispatch = useDispatch();

    const deleteNotifications = () => {
        dispatch(
            notificationActionCreators['deleteNotifications'](notificationAlerts)
        );
    };

    const deleteNotification = (notitificationId) => {
        dispatch(
            notificationActionCreators['deleteNotification'](notitificationId)
        );
    }

    const createNotification = (notification) => {
        dispatch(
            notificationActionCreators['createNotification'](
                notification
            )
        );
    }

    const createNotificationModal: IModal = {
        title: "Create Notification",
        content: FormCreateNotification,
        primaryButtonLabel: "Create Notification",
        formAction: createNotification,
    };

    return (
        <AppFrame>
            <PageTitleBanner
                title="Notifications"
                description=''
                background='buildingsAndComputer'
            />

            <InnerContainer>
                <Section>
                    {currentUserType === "mihin-admin" &&
                        <div>
                            <div className={css.createButton}>
                                <LargeButton
                                    onClick={(event) => createModal(
                                        createNotificationModal,
                                        event
                                    )}
                                >
                                    Create New Notification
                                </LargeButton>
                            </div>
                        </div>

                    }

                    <Panel className={css.navigationPanel}>
                        {notificationAlerts.length !== 0 ? (
                            <>
                                <div className={css.deleteButton}>
                                    <Button onClick={deleteNotifications}>Delete All</Button>
                                </div>

                                {notificationAlerts.map((notification, index) => (
                                    <Alert
                                        className={css.alertItem}
                                        severity={notification.type}
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                onClick={() => deleteNotification(notification.id)
                                                }
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                    >
                                        {parse(notification.message)}
                                    </Alert>
                                ))}
                            </>
                        ) : (
                            <Message
                                title={`No Unread Notifications.`}
                                description={``}
                            ></Message>
                        )}
                    </Panel>
                </Section>
            </InnerContainer>
        </AppFrame >
    );
};


const mapStateToProps = (state: ApplicationState): INotificationViewReduxProps => ({
    notificationAlerts: state.notifications.notificationAlerts,
    currentUserType: state.auth.user.attributes['custom:user_type'],
});


export const NotificationsView = connect(
    mapStateToProps,
    notificationViewActionCreators
)(NotificationsViewBase);