import React from 'react';
import { findDOMNode } from 'react-dom';
import c3 from 'c3';
import classes from 'classnames';
import css from './chart-c3.module.scss';

interface IChartC3 {
	data: {};
	title?: {};
	size?: {};
	padding?: {};
	color?: {};
	interaction?: {};
	transition?: {};
	oninit?: any;
	onrendered?: any;
	onmouseover?: any;
	onmouseout?: any;
	onresize?: any;
	onresized?: any;
	axis?: {};
	grid?: {};
	regions?: [];
	legend?: {};
	tooltip?: {};
	subchart?: {};
	zoom?: {};
	point?: {};
	line?: {};
	area?: {};
	bar?: {};
	pie?: {};
	donut?: {};
	gauge?: {};
	className?: string;
	style?: {};
	unloadBeforeLoad?: boolean;
	onPropsChanged?: any;
}

export class ChartC3 extends React.PureComponent<IChartC3> {
	chart;
	static get displayName() {
		return 'C3Chart';
	}

	static get propTypes() {
		return {};
	}

	componentDidMount() {
		this.updateChart(this.props);
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		this.updateChart(newProps);
		if (newProps.onPropsChanged) {
			newProps.onPropsChanged(this.props, newProps, this.chart);
		}
	}

	componentWillUnmount() {
		this.destroyChart();
	}

	destroyChart() {
		try {
			this.chart = this.chart.destroy();
		} catch (error) {
			// throw new Error('Internal C3 error', error);
		}
	}

	generateChart(mountNode, config) {
		const newConfig = Object.assign({ bindto: mountNode }, config);
		return c3.generate(newConfig);
	}

	loadNewData(data) {
		this.chart.load(data);
	}

	unloadData() {
		this.chart.unload();
	}

	updateChart(config) {
		if (!this.chart) {
			this.chart = this.generateChart(findDOMNode(this), config);
		}

		if (config.unloadBeforeLoad) {
			this.unloadData();
		}

		this.loadNewData(config.data);
	}

	render() {
		const className = this.props.className
			? ` ${this.props.className}`
			: '';
		const style = this.props.style ? this.props.style : {};
		return <div className={classes(className, css.c3)} style={style} />;
	}
}
