import * as React from 'react';
import { Link } from 'react-router-dom';
import { TButtonStyle } from 'common/types/TButtonStyle';
import css from './button.module.scss';
import classes from 'classnames';

interface IButtonLink {
	style?: TButtonStyle;
	to: string;
}

export const ButtonLink: React.FC<IButtonLink> = props => {
	const { children, to, style } = props;
	return (
		<Link
			to={to}
			className={classes(css[style], css.button, css.buttonLink)}
		>
			{children}
		</Link>
	);
};
