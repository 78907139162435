import { IOrganization } from "common/interfaces/personaBuilder/IOrganization";
import { RouteComponentProps, NavLink, Switch, Route, Redirect } from "react-router-dom";
import { ResolveThunks, connect } from "react-redux";
import { OrganizationActionCreators } from "stores/actions/personaBuilder/organizationActions";
import React from "react";
import { ApplicationState } from "stores";
import { AppFrame, InnerContainer, PageTitleArea } from "components/Page";
import { Grid } from "@material-ui/core";
import css from './organization-dashboard.module.scss';
import { OrganizationDetailsTab } from "./OrganizationDetailsTab/OrganizationDetailsTab";
import { modalActionCreators } from "stores/actions/modalActions";
import { IModal } from "common/interfaces";

interface IOrganizationDetailsRouteProps {
	id?: string
}

interface IOrganizationDashboardViewReduxProps {
	currentOrganization: IOrganization
	organizationDropdowns: {}
}

const actionCreators = {
	...OrganizationActionCreators,
	...modalActionCreators
};

const deleteOrganizationModalContent = () => {
	return (
		<p>
			Are you sure you want to delete this organization?
			<br />
			<b>This action can not be undone.</b>
		</p>
	);
};

const OrganizationDashboardViewBase: React.FC<IOrganizationDashboardViewReduxProps &
	RouteComponentProps<IOrganizationDetailsRouteProps> &
	ResolveThunks<typeof actionCreators>> = props => {
	let _submitForm = [];
	let title = '';
	const isNew = props.match.params.id === 'create';
	React.useEffect(() => {
		if (!isNew) {
			props.getOrganization(props.match.params.id);	
		}
		props.populateDropdowns();
		_submitForm = [];
	}, []);

	const [loading, setLoading] = React.useState(false);

	const deleteOrganization = () => {
		props.deleteOrganization(props.match.params.id);
	};

	const deleteOrganizationModal: IModal = {
		title: 'Delete Organization',
		content: deleteOrganizationModalContent,
		primaryButtonLabel: 'Delete Organization',
		primaryButtonAction: deleteOrganization
	};

	const currentOrganization = isNew ? {
		organization_name: 'Create New Organization',
		address: '',
		city: '',
		state: '',
		country: '',
		postal_code: '',
		phone_number: '',
		organization_type_id: null,
		pit_id: null,
		parent_organization_id: null,
		id: ''
	} : props.currentOrganization;

	return currentOrganization && (
		<AppFrame> 
			<InnerContainer>
				<PageTitleArea
					buttonList={
						!isNew && [
							{
								buttonLabel: 'Delete Organization',
								buttonAction: event => {
									props.createModal(deleteOrganizationModal, event);
								},
								style: 'destructive'
							}
						]
					}
					breadcrumbItems={[
						{
							label: 'Creation Dashboard',
							location: '/persona-builder'
						},
						{
							label: 'All Organizations',
							location: `/persona-builder/organization-listing`
						},
						{
							label: `${currentOrganization.organization_name}`,
							location: `/persona-builder/organization-listings/${currentOrganization.id}`
						}
					]}
				>
					<h1>
						{currentOrganization.organization_name}
					</h1>
				</PageTitleArea>
				<Grid container justify={'center'} alignItems={'flex-end'} className={css.outerGrid}>
					<Grid container xs={12}>
						<Grid item xs={12}>
							<OrganizationDetailsTab {...props}/>
						</Grid>
					</Grid>
				</Grid>
			</InnerContainer>
		</AppFrame>
	);
};

const mapStateToProps = (state: ApplicationState): IOrganizationDashboardViewReduxProps => {
	return {
		currentOrganization: state.personaBuilderOrganization.currentOrganization,
		organizationDropdowns: state.personaBuilderOrganization.organization_dropdowns || {}
	};
};

export const OrganizationDashboardView = connect(
	mapStateToProps,
	actionCreators
)(OrganizationDashboardViewBase);
