import * as React from 'react';
import css from './mui-table.module.scss';
import classes from 'classnames';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import { Card, Checkbox, IconButton } from '@material-ui/core';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { useState } from 'react';
import { MuiTableHeader, MuiTablePagination } from 'components/MaterialUI/index';

const sortIcon = <ArrowDownward />;

interface IDataColumn {
	disablePadding: boolean;
	id: keyof string | number;
	label: string;
	numeric: boolean;
}

interface IMuiTableProps<T> {
	title?: any;
	loading: boolean;
	columns: IDataTableColumn<T>[];
	data: T[];
	onRowClicked: (row: T) => void;
}

const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };
const actions = (
	<IconButton color='primary'>
		<Add />
	</IconButton>
);

type MuiTableComponent<T = any> = React.FC<IMuiTableProps<T>>;

export const MuiTable: MuiTableComponent = (props) => {
	const [selectedRows, setSelectedRows] = React.useState([]);
	const { title, columns, data, onRowClicked } = props;

	const handleChange = (state) => {
		setSelectedRows(state.selectedRows);
	};

	return (
		<Card style={{ height: '100%', padding: 20 }}>
			<DataTable
				className={css.dataTable}
				columns={columns}
				data={data}
				noHeader
				// selectableRows
				highlightOnHover
				defaultSortField='name'
				// actions={actions}
				// contextActions={contextActions(this.deleteAll)}
				sortIcon={sortIcon}
				// selectableRowsComponent={Checkbox}
				// selectableRowsComponentProps={selectProps}
				onSelectedRowsChange={handleChange}
				// clearSelectedRows={toggleCleared}
				onRowClicked={onRowClicked}
				pagination
				paginationRowsPerPageOptions={[10, 25, 50, 100]}
				subHeader
				subHeaderComponent={
					<MuiTableHeader
						title={title}
						onSearch={(x) => {
							console.log(x);
						}}
					/>
				}
				// expandableRows
			/>
		</Card>
	);
};
