import { Reducer } from 'redux';

import OrganizationActions from 'stores/actionInterfaces/personaBuilder/organizationActionInterfaces';
import { PersonaBuilderOrganizationState } from 'stores/stateInterfaces/personaBuilder/organizationStateInterface';
import { EUpdateState } from 'stores/shared/ENums';

const initialState: PersonaBuilderOrganizationState = {
	organizationTableState: EUpdateState.Empty,
	organization: [],
	currentOrganization: null,
	organization_dropdowns: {
		pits: [],
		organization_types: [],
		parent_organizations: []
	},
	saveDisabled: true
};

export const PersonaBuilderOrganizationReducers: Reducer<PersonaBuilderOrganizationState> = (
	state: PersonaBuilderOrganizationState,
	action: OrganizationActions
) => {

	switch (action.type) {
		case 'GET_ORG_DROPDOWNS_RECEIVED': {
			return {
				...state,
				organization_dropdowns: action.org_dropdowns
			}
		}
		case 'GET_ALL_ORGANIZATIONS_REQUEST': {
			return {
				...state,
				organizationTableState: EUpdateState.Loading
			}
		}
		case 'GET_ALL_ORGANIZATIONS_RECEIVED': {
			return {
				...state,
				organization: action.organizations
			}
		}
		case 'GET_ORGANIZATION_REQUEST': {
			return {
				...state,
				currentOrganization: null
			}
		}
		case 'GET_ORGANIZATION_RECEIVED': {
			return {
				...state,
				currentOrganization: action.organization
			}
		}
		case 'SET_SAVE_DISABLED': {
			return {
				...state,
				saveDisabled: action.saveDisabled
			}
		}
	}
	return state || initialState;
};