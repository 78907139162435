import * as React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { IPatient } from 'common/interfaces/personaBuilder/IPatient';
import { Button, Grid, Divider, Checkbox } from '@material-ui/core';
import { ChevronLeft, CheckBoxOutlineBlank } from '@material-ui/icons';
import { MuiButton } from 'components/MaterialUI/index';
import DataTable from 'react-data-table-component';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import cssClasses from 'classnames';
import cssDetails from 'components/Forms/PersonaBuilder/FormPersonaBuilder/FormPatientDetails/persona-details.module.scss';
import css from './form-patient-choose-existing-family-member.module.scss';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IFormPatientChooseExistingFamilyMemberState {
  isLoading: boolean;
  selectedPatient: IPatient;
  filteredData?: IPatient[];
  viewableStep: number;
  selectedRelationshipId: number;
}

interface IFormPatientChooseExistingFamilyMemberFormProps {
  cancelModalAction?: any;
  formAction?: any;
  patient: IPatient;
}

interface IFormPatientChooseExistingFamilyMemberReduxProps {
  relationships: any;
  patients: any;
}

export class FormPatientChooseExistingFamilyMemberBase extends React.PureComponent<
ResolveThunks<typeof PersonaBuilderActionCreators> &
IFormPatientChooseExistingFamilyMemberFormProps &
IFormPatientChooseExistingFamilyMemberReduxProps,
IFormPatientChooseExistingFamilyMemberState> {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      filteredData: null,
      viewableStep: 1,
      selectedPatient: null,
      selectedRelationshipId: 0
    };
  }

  filterPatients = (param) => {
    const { patients } = this.props;
		if (param.length > 2) {
			let foundData = [];
			patients.forEach(function(dataElement) {
				["first_name", "last_name"].forEach(function(property) {
					if (dataElement[property].toLowerCase().indexOf(param.toLowerCase()) > -1) {
						foundData.push(dataElement);
					}
				});
			});
			this.setState({filteredData: foundData});
		}
		else if (param.length === 0) {
			this.setState({filteredData: patients});
		}
  };
  handleCreateFamilyMember = () => {
    if (this.props.formAction) {
      this.props.formAction({
        patient_id: this.props.patient.id,
        patient_family_member_id: this.state.selectedPatient.id,
        patient_relationship_id: this.state.selectedRelationshipId
      });
    }
  };

  render() {
    const columns = [
      {
        name: '',
        grow: 1,
        cell: (row) => 
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={this.state.selectedPatient === row}
              onClick={()=>this.setState({selectedPatient: row})}
            />
            {row.first_name} {row.last_name}
          </React.Fragment>
      },
    ];

    return (
      <>
        {this.state.viewableStep == 1 ? (
          <Grid container>
            <Grid item xs={12} className={css.formContainer}>
              <input 
                placeholder='Search the existing individuals that can be assigned as a family member' 
                type='text' 
                onChange={(e) => this.filterPatients(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <div style={{height: 300, overflowY: 'scroll', marginBottom: '2rem'}}>
                <DataTable
                  style={{width:'100%'}}
                  columns={columns}
                  data={this.state.filteredData ? this.state.filteredData : this.props.patients}
                  noHeader
                  noTableHead
                />
              </div>
              <Divider />
            </Grid>
            <Grid item xs={12} style={{textAlign: 'right', marginTop: '2rem'}}>
              <MuiButton variant="contained" disabled={this.state.selectedPatient === null} onClick={()=>this.setState({viewableStep: 2})}>Continue</MuiButton>
              <Button onClick={this.props.cancelModalAction}>Cancel</Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <em>You selected:</em>
            </Grid>
            <Grid item xs={12}>
              <h4 className={css.headerName}>{this.state.selectedPatient.first_name} {this.state.selectedPatient.last_name}</h4>
            </Grid>
            <Grid item xs={12}>
              <p>How is {this.state.selectedPatient.first_name} {this.state.selectedPatient.last_name} related to {this.props.patient.first_name} {this.props.patient.last_name}?</p>
            </Grid>
            <Grid item xs={12} className={css.formContainer}>
              <label>Relationship Type</label>
              <select
                  name='patient_relationship_id'
                  value={this.state.selectedRelationshipId}
                  onChange={(e) => this.setState({selectedRelationshipId: parseInt(e.target.value)})}
                  style={{textTransform: 'capitalize'}}
                >
                <option value={""}>Select</option>
                {this.props.relationships.map((relationship, index) => (
                  <option key={index} value={relationship.id}>
                    {relationship.patient_relationship_display} ({relationship.patient_relationship_code})
                  </option>
                ))}
              </select>
            </Grid>
            <Grid container className={cssDetails.actionsContainer}>
              <Grid item xs={6} style={{marginTop: '2rem'}}>
                <Button onClick={()=>this.setState({viewableStep: 1})}>
                  <ChevronLeft className={cssClasses([cssDetails.leftIcon], [cssDetails.iconSmall])}/>Back
                </Button>
              </Grid>
              <Grid item xs={6} style={{textAlign: 'right', marginTop: '2rem'}}>
                <MuiButton variant="contained" disabled={this.state.selectedRelationshipId === 0} onClick={()=>this.handleCreateFamilyMember()}>Create Family Member</MuiButton>
                <Button onClick={this.props.cancelModalAction}>Cancel</Button>
              </Grid>
            </Grid>
            
          </Grid>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState): IFormPatientChooseExistingFamilyMemberReduxProps => ({
  relationships: state.personaBuilder.dropdowns.relationships,
  patients: state.personaBuilder.patients
});

export const FormPatientChooseExistingFamilyMember = connect(mapStateToProps,PersonaBuilderActionCreators)(FormPatientChooseExistingFamilyMemberBase);