import * as React from 'react';
import { Field, Formik } from 'formik';
import { IModalForm } from 'common/interfaces';
import { connect, ResolveThunks } from 'react-redux';
import { ApplicationState } from 'stores';
import { ErrorFocus } from 'components/ErrorFocus';
import { PersonaBuilderActionCreators } from 'stores/actions/personaBuilder/personaBuilderActions';
import { Card, CardHeader, CardContent, Grid } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { trimAllStringProperties } from 'common/utils/trimAllStringProperties';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { PhoneNumberField } from 'components/FormElements/PhoneNumberField/PhoneNumberField';
import { removePhoneNumberFormatting } from 'common/utils/removePhoneNumberFormatting';
import { providerDetailsValidationSchema } from 'common/validation-schema/personaBuilder/providerDetailsValidationSchema';
import css from './form-provider-details.module.scss';
import classes from 'classnames';
import { IsFormDirty } from 'components/RouteGuards/IsFormDirty';
import { DatePickerField } from 'components/FormElements/DatePickerField'

interface IFormProviderDetailsState {
  isLoading: boolean;
}

interface IFormProviderDetailsReduxProps {
  organizations: any;
  practitioner_roles: any;
  practice_codes: any;
  genders: any;
  qualifications: any;
}

export class FormProviderDetailsBase extends React.PureComponent<
  ResolveThunks<typeof PersonaBuilderActionCreators> &
  IModalForm &
  IFormProviderDetailsReduxProps,
  IFormProviderDetailsState> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  setFormLoadingAttribute = (isLoading: boolean) => {
    this.setState({
      isLoading
    });
  };

  handleFormSubmit = (values, setSubmitting, resetForm) => {
    const { formAction, setErrorMessage } = this.props;
    values.provider_phone = `+1${removePhoneNumberFormatting(values.provider_phone)}`;

    this.setFormLoadingAttribute(true);
    if (formAction) {
      const trimmedValues = trimAllStringProperties(values);
      formAction(trimmedValues, setErrorMessage, this.setFormLoadingAttribute);
      resetForm(trimmedValues);
    }
    setSubmitting(false);
  };

  render() {
    let { setFormLoadingAttribute } = this.props;
    const {
      bindSubmitForm,
      formAction,
      defaultFormContent,
      setErrorMessage,
      organizations,
      practitioner_roles,
      practice_codes,
      genders,
      qualifications,
      setSaveDisabled
    } = this.props;

    let initialValues = {
      provider_identifier: '',
      provider_first_name: '',
      provider_last_name: '',
      dob: null,
      provider_phone: '',
      provider_email: '',
      appointment_length: '',
      organization_id: null,
      practice_code_id: null,
      practitioner_role_id: null,
      gender_id: null,
      qualification_id: null
    };

    if (defaultFormContent) {
      defaultFormContent.provider_phone = removePhoneNumberFormatting(defaultFormContent.provider_phone);
      initialValues = defaultFormContent;
    }

    const isNew = !('id' in initialValues);

    if (!setFormLoadingAttribute) {
      setFormLoadingAttribute = this.setFormLoadingAttribute;
    }

    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting, resetForm }) => this.handleFormSubmit(values, setSubmitting, resetForm)}
        validationSchema={providerDetailsValidationSchema()}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            submitForm,
            isValid
          } = props;
          if (bindSubmitForm) {
            bindSubmitForm(submitForm);
          }
          return (
            <>
              <IsFormDirty
                title=""
                message=""
                dirty={dirty}
              />
              <form onSubmit={handleSubmit}>
                <Grid container className={css.formContainer}>
                  {isNew &&
                    <Grid item xs={12} className={css.confirmationContainer}>
                      <p className={css.confirmationMessage}><Warning /> You are <strong> creating a new provider persona</strong>. Input all the data below to continue building out the additional sections of this new provider persona.</p>
                    </Grid>
                  }
                  <Grid item xs={6} className={css.formColumn}>
                    <Card className={css.formCard}>
                      <CardContent>
                        <Grid container>
                          {!isNew &&
                            <Grid item xs={12}>
                              <label>NPI Number (Identifier)</label>
                              <h5 className={css.providerIdentifier}>{values.provider_identifier}</h5>
                            </Grid>
                          }
                          <Grid item xs={12}>
                            <label>First Name</label>
                            <input
                              id='provider_first_name'
                              placeholder='First Name'
                              type='text'
                              value={values.provider_first_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={errors.provider_first_name && touched.provider_first_name ? 'text-input error' : 'text-input'}
                              autoComplete='provider_first_name'
                              maxLength={128}
                            />
                            {errors.provider_first_name && touched.provider_first_name && (
                              <div className='input-feedback'>{errors.provider_first_name}</div>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>Last Name</label>
                            <input
                              id='provider_last_name'
                              placeholder='Last Name'
                              type='text'
                              value={values.provider_last_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={errors.provider_last_name && touched.provider_last_name ? 'text-input error' : 'text-input'}
                              autoComplete='provider_last_name'
                              maxLength={128}
                            />
                            {errors.provider_last_name && touched.provider_last_name && (
                              <div className='input-feedback'>{errors.provider_last_name}</div>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>D.O.B.</label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Field
                                name='dob'
                                value={values.dob}
                                component={DatePickerField}
                                className={
                                  classes(css.dateOfBirthPicker, 'mtl-picker-input', { 'error': errors.dob && touched.dob })
                                }
                                onBlur={handleBlur}
                              />
                            </MuiPickersUtilsProvider>
                            {errors.dob && touched.dob && (
                              <div className='input-feedback'>{errors.dob}</div>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>Gender</label>
                            <select
                              name='gender_id'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.gender_id || ''}
                              className={errors.gender_id && touched.gender_id ? 'text-input error' : 'text-input'}
                            >
                              <option value={""}>Select</option>
                              {genders.map((gender, index) => (
                                <option key={index} value={gender.id}>
                                  {gender.gender}
                                </option>
                              ))}
                            </select>
                            {errors.gender_id && touched.gender_id && (
                              <div className='input-feedback'>{errors.gender_id}</div>
                            )}
                          </Grid>
                          <Grid container>
                            <Grid item xs={6} className={css.gridItem}>
                              <label>Phone Number</label>
                              <PhoneNumberField
                                id='provider_phone'
                                placeholder='Enter Phone Number'
                                value={removePhoneNumberFormatting(values.provider_phone)}
                                onChange={handleChange}
                                autocomplete={'tel'}
                                onBlur={handleBlur}
                                className={
                                  errors.provider_phone && touched.provider_phone ? 'text-input error' : 'text-input'
                                }
                              />
                              {errors.provider_phone && touched.provider_phone && (
                                <div className='input-feedback'>{errors.provider_phone}</div>
                              )}
                            </Grid>
                            <Grid item xs={6} className={css.gridItem}>
                              <label>Email</label>
                              <input
                                id='provider_email'
                                placeholder='Email'
                                type='text'
                                value={values.provider_email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.provider_email && touched.provider_email ? 'text-input error' : 'text-input'}
                                autoComplete='provider_email'
                                maxLength={128}
                              />
                              {errors.provider_email && touched.provider_email && (
                                <div className='input-feedback'>{errors.provider_email}</div>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <label>Qualification</label>
                            <select
                              name='qualification_id'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.qualification_id || ''}
                              className={errors.qualification_id && touched.qualification_id ? 'text-input error' : 'text-input'}
                            >
                              <option value={""}>Select</option>
                              {qualifications.map((qualification, index) => (
                                <option key={index} value={qualification.id}>
                                  {qualification.qualification_name} ({qualification.qualification_code})
                                </option>
                              ))}
                            </select>
                            {errors.qualification_id && touched.qualification_id && (
                              <div className='input-feedback'>{errors.qualification_id}</div>
                            )}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6} className={css.formColumn}>
                    <Card className={css.formCard}>
                      <CardHeader title="Organization Details" className={css.fieldsetHeader} />
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <label>Organization</label>
                            <select
                              id='organization_id'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.organization_id || ''}
                              className={errors.organization_id && touched.organization_id ? 'text-input error' : 'text-input'}
                            >
                              <option value={""}>Select</option>
                              {organizations.map((org, index) => (
                                <option key={index} value={org.id}>
                                  {org.organization_name}
                                </option>
                              ))}
                            </select>
                            {errors.organization_id && touched.organization_id && (
                              <div className='input-feedback'>{errors.organization_id}</div>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>Practice Code</label>
                            <select
                              id='practice_code_id'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.practice_code_id || ''}
                              className={errors.practice_code_id && touched.practice_code_id ? 'text-input error' : 'text-input'}
                            >
                              <option value={""}>Select</option>
                              {practice_codes.map((practice_code, index) => (
                                <option key={index} value={practice_code.id}>
                                  {practice_code.practice_code_display}
                                </option>
                              ))}
                            </select>
                            {errors.practice_code_id && touched.practice_code_id && (
                              <div className='input-feedback'>{errors.practice_code_id}</div>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <label>Practitioner Role</label>
                            <select
                              id='practitioner_role_id'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.practitioner_role_id || ''}
                              className={errors.practitioner_role_id && touched.practitioner_role_id ? 'text-input error' : 'text-input'}
                            >
                              <option value={""}>Select</option>
                              {practitioner_roles.map((practitioner_role, index) => (
                                <option key={index} value={practitioner_role.id}>
                                  {practitioner_role.practitioner_role_display}
                                </option>
                              ))}
                            </select>
                            {errors.practitioner_role_id && touched.practitioner_role_id && (
                              <div className='input-feedback'>{errors.practitioner_role_id}</div>
                            )}
                          </Grid>
                          <Grid container>
                            <Grid item xs={6}>
                              <label>Provider Schedule:</label>
                              <strong>9am - 5pm</strong>
                            </Grid>
                            <Grid item xs={6}>
                              <label>Time Slot Length:</label>
                              <strong>60 minutes</strong>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <ErrorFocus />
                </Grid>
              </form>
            </>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state: ApplicationState): IFormProviderDetailsReduxProps => ({
  organizations: state.personaBuilderProvider.provider_dropdowns.organizations,
  practitioner_roles: state.personaBuilderProvider.provider_dropdowns.practitioner_roles,
  practice_codes: state.personaBuilderProvider.provider_dropdowns.practice_codes,
  genders: state.personaBuilderProvider.provider_dropdowns.genders,
  qualifications: state.personaBuilderProvider.provider_dropdowns.qualifications,
});

export const FormProviderDetails = connect(mapStateToProps, PersonaBuilderActionCreators)(FormProviderDetailsBase);