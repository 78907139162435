import { clone } from 'ramda';

export const normalizeUser = user => {
	if (user) {
		user = clone(user);
		if (user.attributes) {
			user.attributes['organizationId'] = Number(
				user.attributes['custom:organization_id']
			);
			delete user.attributes['custom:organization_id'];
		}

		return user;
	} else {
		return null;
	}
};
