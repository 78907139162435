import * as React from 'react';
import css from './table-action-bar-top.module.scss';
import classes from 'classnames';

interface ITableActionBarTopProps {
	className?: string;
	ContentRight?: React.ElementType;
}

export const TableActionBarTop: React.FC<ITableActionBarTopProps> = props => {
	const { children, className, ContentRight } = props;
	return (
		<div className={classes(css.tableActionBarTop, className)}>
			<div className={css.leftContent}>{children}</div>
			{ContentRight && (
				<div className={css.rightContent}>
					<ContentRight />
				</div>
			)}
		</div>
	);
};
