import React, { useState, useEffect } from 'react';
import { AppFrame, PageTitleArea, InnerContainer } from 'components/Page';
import { FaqQuestion } from 'components/FaqQuestion';
import { Panel } from 'components/Panel';
import { PageTitleBanner } from 'components/Page/PageTitleBanner/PageTitleBanner';
import { Section } from 'components/Section/Section';
import { Link } from 'react-router-dom';
import css from './faq-view.module.scss';
import { Button } from 'components/Button';
import { faqQuestions } from 'common/data/faqQuestions';
import { RouteComponentProps } from 'react-router';
import classes from 'classnames';
import { content } from 'common/data/content';

export const FaqView = (routeProps: RouteComponentProps) => {
	const sectionTitles = new Map();
	const { location } = routeProps;
	const [isInitial, setIsInitial] = useState(true);

	useEffect(() => {
		const element = document.getElementById(location.hash.replace('#', '') || 'faq-scroll-top');
		setTimeout(
			() => {
				element.scrollIntoView({
					block: 'start',
					behavior: !isInitial ? 'smooth' : 'auto'
				});
			},
			isInitial ? 500 : 0
		);
	}, [location.hash]);

	const onNavigationClick = () => {
		if (isInitial) {
			setIsInitial(false);
		}
	};

	return (
		<AppFrame>
			<span id='faq-scroll-top' />
			<PageTitleBanner
				title='Frequently Asked Questions'
				description={`Have questions not answered here? Contact ${content.contact.email.general.email}`}
				background='buildingsAndComputer'
			/>
			<InnerContainer className={css.row}>
				<div className={css.columnLeft}>
					<Panel className={css.navigationPanel}>
						<h2 className={classes('h4', css.sidebarTitle)}>Sections</h2>
						<ul className='reset'>
							{faqQuestions.map(faqSection => (
								<li key={`nav-item-${faqSection.id}`}>
									<Link
										className={css.sidebarLink}
										to={{ hash: `#${faqSection.id}`, state: { isInitial } }}
										onClick={onNavigationClick}
									>
										{faqSection.title}
									</Link>
								</li>
							))}
						</ul>
					</Panel>
				</div>
				<div className={classes(css.columnRight, css.faqContent)}>
					{faqQuestions.map(faqSection => (
						<Section
							title={faqSection.title}
							titleRef={ref => sectionTitles.set(faqSection.id, ref)}
							titleSize='h2'
							titleId={faqSection.id}
							titleClass={css.sectionTitle}
							key={`section-${faqSection.id}`}
						>
							{faqSection.questions.map(question => (
								<FaqQuestion
									question={question.question}
									Answer={question.answer}
									key={`faq-question-element${question.question}`}
								/>
							))}
						</Section>
					))}
				</div>
			</InnerContainer>
		</AppFrame>
	);
};
