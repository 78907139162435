import * as React from 'react';
import { SideBar, Header } from 'components/Page';
import classes from 'classnames';
import { ModalController } from 'components/Modal/ModalController';
import { SkipNav } from 'components/Page/SkipNav';
import { connect } from 'react-redux';
import { ApplicationState } from 'stores';
import { IModal } from 'common/interfaces';
import css from './app-frame.module.scss';
import { MessageController } from 'components/MessageController/MessageController';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { TSetSuccessMessage } from 'common/types/TSetSuccessMessage';
import { IViewMessages } from 'common/interfaces/IViewMessages';
import { Success } from 'components/Toast/Success';
import { InnerContainer } from '../InnerContainer/InnerContainer';

interface IAppFrameReduxProps {
	modals: IModal[];
}
interface IAppFrameProps {
	ContentTop?: React.ElementType;
	Content?: React.ElementType<IViewMessages>;
}
interface IAppFrameState {
	navShown: boolean;
	successMessage: string;
}
class AppFrameBase extends React.PureComponent<IAppFrameReduxProps & IAppFrameProps, IAppFrameState> {
	constructor(props) {
		super(props);
		this.state = { navShown: false, successMessage: null };
	}

	accountNavToggle = () => {
		this.setState(state => {
			return {
				...state,
				navShown: !state.navShown
			};
		});
	};

	setSuccessMessage = (successMessage: string) => {
		console.log('successMessage', successMessage);
		this.setState({
			successMessage
		});
	};

	render() {
		const { children, modals, ContentTop, Content } = this.props;

		return (
			<ErrorBoundary>
				<main
					className={classes(
						css.container,
						{
							shown: this.state.navShown
						},
						css.main
					)}
					aria-hidden={modals.length > 0}
				>
					<SkipNav />
					<Header navShown={this.state.navShown} navToggle={this.accountNavToggle} />
					<div className={css.body}>
						<SideBar sideBarShown={this.state.navShown} sideBarToggle={this.accountNavToggle} />
						<div className={css.contentContainer}>
							{ContentTop && <ContentTop />}
							{this.state.successMessage && (
								<InnerContainer>
									<Success className={css.toastMessage}>{this.state.successMessage}</Success>
								</InnerContainer>
							)}
							<MessageController></MessageController>
							{Content && (
								<Content
									viewMessages={{
										setSuccessMessage: this.setSuccessMessage
									}}
								/>
							)}
							{children}
						</div>
					</div>
				</main>
				<ModalController />
			</ErrorBoundary>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IAppFrameReduxProps => {
	return {
		modals: state.modal.modals
	};
};

export const AppFrame = connect(mapStateToProps)(AppFrameBase) as any;
