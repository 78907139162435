import { getCurrentJwt } from 'common/utils/getCurrentJwt';
import { authActionCreators } from "stores/actions/authActions";
import { sharedStore } from "sharedStore";
import { url } from 'settings';

export const getCurrentAuthHeadersNoUserCheck = async (
	type?: 'json',
	includesAuthorization: boolean = true
) => {
	console.log('includesAuthorization', includesAuthorization);
	const headers = new Headers();
	if (includesAuthorization) {
		const currentUserIdToken = await getCurrentJwt();
		headers.set('Authorization', `Bearer ${currentUserIdToken}`);
	}

	if (type === 'json') {
		headers.set('Content-Type', 'application/json');
	}

	console.log('headers getCurrentAuthHeaders', headers);

	return headers;
};

export const getCurrentAuthHeaders = async (
	type?: 'json',
	includesAuthorization: boolean = true
) => {
	console.log('includesAuthorization', includesAuthorization);
	const headers = new Headers();
	if (includesAuthorization) {
		const currentUserIdToken = await getCurrentJwt();
		headers.set('Authorization', `Bearer ${currentUserIdToken}`);
	}

	if (type === 'json') {
		headers.set('Content-Type', 'application/json');
	}

	console.log('headers getCurrentAuthHeaders', headers);

	const getCurrentUserRequest = await fetch(`${url}user/current`, {
		headers
	});

	const getCurrentUserResponse = await getCurrentUserRequest.json();

	if (getCurrentUserResponse.errorMessage) {
		sharedStore.dispatch(authActionCreators.signOut());
	}

	return headers;
};