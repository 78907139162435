import * as React from 'react';
import css from './medication-title-area.module.scss';
import { PageBreadcrumbs } from 'components/Page';
import classes from 'classnames';
import { IMedication } from 'common/interfaces/personaBuilder/IMedication';

interface IMedicationTitleAreaProps {
	medication: IMedication;
}

export const MedicationTitleArea: React.FC<IMedicationTitleAreaProps> = props => {
		const {	medication } = props;

		const medicationName = medication.proprietary_name !== "" ? 
			`${medication.proprietary_name} - ${medication.strength} ${medication.ingredient_unit}` :
			`${medication.non_proprietary_name} - ${medication.strength} ${medication.ingredient_unit}`

		return (
			<div
				className={classes(css.pageTitleArea, css.center)}
				role='banner'
			>
				<div>
					<PageBreadcrumbs breadcrumbItems={[
						{
							label: 'Creation Dashboard',
							location: '/persona-builder'
						},
						{
							label: 'All Medications',
							location: `/persona-builder/medication-listing`
						},
						{
							label: medicationName,
							location: `/persona-builder/medication-listing/${medication.id}`
						}
					]} />
					<h1 className={classes('h2', css.title)}>
						{medicationName}
					</h1>
				</div>

				<ul className='reset'>
					<li style={{display: 'inline', padding: 12}}>
						
					</li>

				</ul>
			</div>
		);
}