import { Reducer } from 'redux';
import * as deepmerge from 'deepmerge';
import RofActions from 'stores/actionInterfaces/rofActionInterfaces';
import { RofState } from 'stores/stateInterfaces/rofStateInterface';
import { EUpdateState } from 'stores/shared/ENums';
import { clone } from 'ramda';
import {
	convertArrayToIndexedObject,
	convertArrayToStringIndexedObject
} from 'common/utils/convertArrayToIndexedObject';
import {
	convertIndexedObjectToArray,
	convertStringIndexedObjectToArray
} from 'common/utils/convertIndexedObjectToArray';
import { ISmartAppLauncher } from 'common/interfaces/ISmartAppLauncher';
import { TStackStatus } from 'common/types/TStackStatus';
import io from 'socket.io-client';
import { url } from 'settings';

const initialState: RofState = {
	currentRofId: null,
	currentPitId: null,
	currentSmartAppId: null,
	allRof: {},
	allRofLoadingState: {},
	allRofPits: {},
	allRofPitsSmartApps: {},
	allRofPitsSmartAppsUpdateState: {},
	createdSmartApp: null,
	createSmartAppErrorMessage: null,
	createSmartAppUpdateState: EUpdateState.Empty,
	rofPollingStatus: {},
	allRofBackups: {},
	allRofBackupsUpdateStatus: {},
	organizationsRofLoadingState: {},
	templates: [],
	templatesLoadingState: EUpdateState.Empty,
	// allRofBackupOperationStatus: {},
	rofBackupPollingStatus: {},
	allRofCapabilitiesStatement: {},
	allRofCapabilitiesStatementLoadingState: {},
	allRofSmartAppLaunchers: {},
	allRofSmartAppLaunchersLoadingState: {},
	allRofDetails: {},
	allRofDetailsLoadingState: {},
	//todo: isSpinnersActive is just for demo on 10/22
	isSpinnersActive: null,
	allRofPitsSmartAuthUsers: {},
	allRofPitsSmartAuthUsersLoadingState: {},
	rofList: {},
};

export const RofReducers: Reducer<RofState> = (state: RofState, action: RofActions) => {
	switch (action.type) {
		case 'CREATE_ROF_REQUEST': {
			return {
				...state
			};
		}

		case 'CREATE_ROF_RESPONSE': {
			const allRof = clone(state.allRof);
			allRof[action.rofId] = action.rof;

			const allRofLoadingState = clone(state.allRofLoadingState);
			allRofLoadingState[action.rofId] = EUpdateState.Loaded;
			return {
				...state,
				allRof,
				allRofLoadingState
			};
		}

		case 'GET_ORGANIZATION_ROF_REQUEST': {
			const organizationsRofLoadingState = clone(state.organizationsRofLoadingState);
			organizationsRofLoadingState[action.organizationId] = EUpdateState.Loading;

			return {
				...state,
				organizationsRofLoadingState
			};
		}

		case 'GET_ORGANIZATION_ROF_RESPONSE': {
			const allRof = clone(state.allRof);

			const organizationsRofLoadingState = clone(state.organizationsRofLoadingState);
			organizationsRofLoadingState[action.organizationId] = EUpdateState.Loaded;

			const allRofLoadingState = clone(state.allRofLoadingState);

			convertIndexedObjectToArray(action.allRof).forEach(rof => {
				allRofLoadingState[rof.id] = EUpdateState.Loaded;
				allRof[rof.id] = rof;
			});

			return {
				...state,
				allRof,
				organizationsRofLoadingState,
				allRofLoadingState
			};
		}

		case 'GET_ALL_ROF_REQUEST': {
			return {
				...state,
			};
		}

		case 'GET_ALL_ROF_RESPONSE': {
			const rofList = clone(state.rofList);

			const allRofLoadingState = clone(state.allRofLoadingState);

			convertIndexedObjectToArray(action.allRof).forEach(rof => {
				allRofLoadingState[rof.id] = EUpdateState.Loaded;
				rofList[rof.id] = rof;
			});

			return {
				...state,
				rofList,
				allRofLoadingState
			};
		}

		case 'GET_ORGANIZATIONS_ROF_REQUEST': {
			const organizationsRofLoadingState = clone(state.organizationsRofLoadingState);

			action.organizationIds.forEach(organizationId => {
				organizationsRofLoadingState[organizationId] = EUpdateState.Loading;
			});

			return {
				...state,
				organizationsRofLoadingState
			};
		}

		case 'GET_ORGANIZATIONS_ROF_RESPONSE': {
			const allRof = clone(state.allRof);

			const organizationsRofLoadingState = clone(state.organizationsRofLoadingState);

			action.organizationIds.forEach(organizationId => {
				organizationsRofLoadingState[organizationId] = EUpdateState.Loaded;
			});

			const allRofLoadingState = clone(state.allRofLoadingState);

			convertIndexedObjectToArray(action.allRof).forEach(rof => {
				allRofLoadingState[rof.id] = EUpdateState.Loaded;
				allRof[rof.id] = rof;
			});

			return {
				...state,
				allRof,
				organizationsRofLoadingState,
				allRofLoadingState
			};
		}

		case 'GET_ROF_REQUEST': {
			const allRofLoadingState = clone(state.allRofLoadingState);
			allRofLoadingState[action.rofId] = EUpdateState.Loading;

			return {
				...state,
				allRofLoadingState
			};
		}

		case 'GET_ROF_RESPONSE': {
			const allRofLoadingState = clone(state.allRofLoadingState);
			allRofLoadingState[action.rofId] = EUpdateState.Loaded;

			return {
				...state,
				allRof: {
					...state.allRof,
					[action.rofId]: action.rof
				},
				allRofPits: {
					...state.allRofPits,
					[action.rofId]: action.pits
				},
				allRofLoadingState
			};
		}

		case 'SET_ROF_AUTOMATIC_PREFERENCE_STATE': {
			const allRof = clone(state.allRof);

			allRof[action.rofId].pit_auto_on_off = action.preference;

			return {
				...state,
				allRof
			};
		}

		case 'SET_CURRENT_ROF_STATE': {
			const allRof = clone(state.allRof);

			allRof[action.rofId].stack_status = 'UPDATE_IN_PROGRESS';

			return {
				...state,
				allRof
			};
		}

		case 'GET_PITS_SMART_APPS_REQUEST': {
			const currentAllRofPitsSmartAppsUpdateState = clone(state.allRofPitsSmartAppsUpdateState);

			return {
				...state,
				allRofPitsSmartAppsUpdateState: {
					...currentAllRofPitsSmartAppsUpdateState,
					[action.rofId]: {
						...currentAllRofPitsSmartAppsUpdateState[action.rofId],
						[action.pitId]: EUpdateState.Loading
					}
				}
			};
		}

		case 'GET_PITS_SMART_APPS_RESPONSE': {
			const currentAllRofPitsSmartApps = clone(state.allRofPitsSmartApps);
			const currentAllRofPitsSmartAppsUpdateState = clone(state.allRofPitsSmartAppsUpdateState);

			return {
				...state,
				allRofPitsSmartApps: {
					...currentAllRofPitsSmartApps,
					[action.rofId]: {
						...currentAllRofPitsSmartApps[action.rofId],
						[action.pitId]: convertArrayToStringIndexedObject(action.smartApps, 'client_id')
					}
				},
				allRofPitsSmartAppsUpdateState: {
					...currentAllRofPitsSmartAppsUpdateState,
					[action.rofId]: {
						...currentAllRofPitsSmartAppsUpdateState[action.rofId],
						[action.pitId]: EUpdateState.Loaded
					}
				}
			};
		}

		case 'CREATE_SMART_APP_REQUEST': {
			return {
				...state,
				createSmartAppUpdateState: EUpdateState.Loading
			};
		}

		case 'CREATE_SMART_APP_ERROR': {
			return {
				...state,
				createSmartAppUpdateState: EUpdateState.Failed
			};
		}

		case 'CREATE_SMART_APP_RESPONSE': {
			const currentAllRofPitsSmartApps = clone(state.allRofPitsSmartApps);

			const createdSmartApp = clone(action.createdSmartApp);

			delete action.createdSmartApp.client_secret;

			currentAllRofPitsSmartApps[action.rofId][action.pitId][action.createdSmartApp.client_id] =
				action.createdSmartApp;

			return {
				...state,
				allRofPitsSmartApps: {
					...currentAllRofPitsSmartApps,
					[action.rofId]: {
						...currentAllRofPitsSmartApps[action.rofId],
						[action.pitId]: currentAllRofPitsSmartApps[action.rofId][action.pitId]
					}
				},
				createdSmartApp,
				createSmartAppUpdateState: EUpdateState.FinishedOk
			};
		}

		case 'UPDATE_SMART_APP_REQUEST': {
			return {
				...state,
				createSmartAppUpdateState: EUpdateState.Loading
			};
		}

		case 'UPDATE_SMART_APP_ERROR': {
			return {
				...state,
				createSmartAppUpdateState: EUpdateState.Failed
			};
		}

		case 'UPDATE_SMART_APP_RESPONSE': {
			const currentAllRofPitsSmartApps = clone(state.allRofPitsSmartApps);

			const updatedSmartApp = clone(action.updatedSmartApp);

			delete action.updatedSmartApp.client_secret;

			currentAllRofPitsSmartApps[action.rofId][action.pitId][action.updatedSmartApp.client_id] =
				action.updatedSmartApp;

			return {
				...state,
				allRofPitsSmartApps: {
					...currentAllRofPitsSmartApps,
					[action.rofId]: {
						...currentAllRofPitsSmartApps[action.rofId],
						[action.pitId]: currentAllRofPitsSmartApps[action.rofId][action.pitId]
					}
				},
				updatedSmartApp,
				createSmartAppUpdateState: EUpdateState.FinishedOk
			};
		}

		case 'CLEAR_CREATED_SMART_APP': {
			return {
				...state,
				createdSmartApp: null,
				createSmartAppErrorMessage: null,
				createSmartAppUpdateState: EUpdateState.Empty
			};
		}

		case 'DELETE_SMART_APP_REQUEST': {
			return {
				...state
			};
		}

		case 'DELETE_SMART_APP_RESPONSE': {
			const updatedAllRofPitsSmartApps = clone(state.allRofPitsSmartApps);

			delete updatedAllRofPitsSmartApps[action.rofId][action.pitId][action.smartAppId];

			return {
				...state,
				allRofPitsSmartApps: updatedAllRofPitsSmartApps
			};
		}

		case 'SET_ROF_POLLING_STATUS': {
			const rofPollingStatus = clone(state.rofPollingStatus);
			rofPollingStatus[action.rofId] = action.isPolling;
			return {
				...state,
				rofPollingStatus
			};
		}

		case 'SET_ROF_BACKUP_POLLING_STATUS': {
			const rofBackupPollingStatus = clone(state.rofBackupPollingStatus);
			rofBackupPollingStatus[action.rofId] = action.isPolling;
			return {
				...state,
				rofBackupPollingStatus
			};
		}

		case 'GET_ROF_BACKUPS_REQUEST': {
			const allRofBackupsUpdateStatus = clone(state.allRofBackupsUpdateStatus);
			allRofBackupsUpdateStatus[action.rofId] = EUpdateState.Loading;
			return {
				...state,
				allRofBackupsUpdateStatus
			};
		}

		case 'GET_ROF_BACKUPS_RESPONSE': {
			const allRofBackups = clone(state.allRofBackups);
			const allRofBackupsUpdateStatus = clone(state.allRofBackupsUpdateStatus);
			allRofBackups[action.rofId] = action.rofBackups;
			allRofBackupsUpdateStatus[action.rofId] = EUpdateState.Loaded;

			return {
				...state,
				allRofBackups,
				allRofBackupsUpdateStatus
			};
		}

		case 'CREATE_ROF_BACKUP_REQUEST': {
			return {
				...state
			};
		}

		case 'CREATE_ROF_BACKUP_RESPONSE': {
			const allRofBackups = clone(state.allRofBackups);

			console.log('CREATE_ROF_BACKUP_RESPONSE allRofBackups[action.rofId]', allRofBackups[action.rofId]);
			const isBackupInState = allRofBackups[action.rofId].some(backup => {
				console.log('CREATE_ROF_BACKUP_RESPONSE backup.id', backup.id);
				console.log('CREATE_ROF_BACKUP_RESPONSE action.rofBackup.id', action.rofBackup.id);
				console.log('CREATE_ROF_BACKUP_RESPONSE backup', backup);
				console.log('CREATE_ROF_BACKUP_RESPONSE action.rofBackup', action.rofBackup);
				console.log(
					`CREATE_ROF_BACKUP_RESPONSE ${backup.id} === ${action.rofBackup.id}`,
					backup.id === action.rofBackup.id
				);
				return backup.id === action.rofBackup.id;
			});

			console.log('CREATE_ROF_BACKUP_RESPONSE isBackupInState', isBackupInState);

			if (!isBackupInState) {
				allRofBackups[action.rofId].push(action.rofBackup);
			}

			const allRof = clone(state.allRof);

			allRof[action.rofId].operation_status = 'iol-db-backup-start';
			allRof[action.rofId].operation_backup_id = action.rofBackup.id;

			return {
				...state,
				allRofBackups,
				allRof
			};
		}

		case 'RESTORE_ROF_BACKUP_REQUEST': {
			return {
				...state
			};
		}

		case 'RESTORE_ROF_BACKUP_RESPONSE': {
			const allRof = clone(state.allRof);

			allRof[action.rofId].operation_status = 'iol-db-restore-start';
			allRof[action.rofId].operation_backup_id = action.rofBackupId;

			return {
				...state,
				allRof
			};
		}

		case 'DELETE_ROF_BACKUP_REQUEST': {
			return {
				...state
			};
		}

		case 'DELETE_ROF_BACKUP_RESPONSE': {
			const allRofBackups = clone(state.allRofBackups);
			const rofBackups = convertArrayToStringIndexedObject(allRofBackups[action.rofId]);
			delete rofBackups[action.rofBackupId];

			allRofBackups[action.rofId] = convertStringIndexedObjectToArray(rofBackups);
			return {
				...state,
				allRofBackups
			};
		}

		case 'RESTORE_ROF_ORIGINAL_STATE_REQUEST': {
			return {
				...state
			};
		}

		case 'RESTORE_ROF_ORIGINAL_STATE_RESPONSE': {
			const allRof = clone(state.allRof);
			allRof[action.rofId].operation_backup_id = null;
			allRof[action.rofId].operation_status = 'iol-db-reset-start';

			return {
				...state,
				allRof
			};
		}

		case 'SET_CURRENT_ROF_ID': {
			return {
				...state,
				currentRofId: action.currentRofId
			};
		}

		case 'DELETE_CURRENT_ROF_REQUEST': {
			return {
				...state
			};
		}

		case 'DELETE_CURRENT_ROF_RESPONSE': {
			const allRof = clone(state.allRof);
			const rofList = clone(state.rofList);
			delete allRof[action.rofId];
			delete rofList[action.rofId];

			return {
				...state,
				allRof,
				rofList,
			};
		}

		case 'GET_ROF_TEMPLATE_REQUEST': {
			return {
				...state,
				templatesLoadingState: EUpdateState.Loading
			};
		}

		case 'GET_ROF_TEMPLATE_RESPONSE': {
			return {
				...state,
				templates: action.templates,
				templatesLoadingState: EUpdateState.Loaded
			};
		}

		case 'SET_CURRENT_PIT_ID': {
			return {
				...state,
				currentPitId: action.currentPitId
			};
		}

		case 'GET_CAPABILITIES_STATEMENT_REQUEST': {
			const allRofCapabilitiesStatementLoadingState = clone(
				state.allRofCapabilitiesStatementLoadingState
			);
			return {
				...state,
				allRofCapabilitiesStatementLoadingState: {
					...allRofCapabilitiesStatementLoadingState,
					[action.rofId]: {
						...allRofCapabilitiesStatementLoadingState[action.rofId],
						[action.pitId]: EUpdateState.Loading
					}
				}
			};
		}

		case 'GET_CAPABILITIES_STATEMENT_RECEIVED': {
			const allRofCapabilitiesStatementLoadingState = clone(
				state.allRofCapabilitiesStatementLoadingState
			);
			const allRofCapabilitiesStatement = clone(state.allRofCapabilitiesStatement);
			return {
				...state,
				allRofCapabilitiesStatement: {
					...allRofCapabilitiesStatement,
					[action.rofId]: {
						...allRofCapabilitiesStatement[action.rofId],
						[action.pitId]: action.capabilitiesStatement
					}
				},
				allRofCapabilitiesStatementLoadingState: {
					...allRofCapabilitiesStatementLoadingState,
					[action.rofId]: {
						...allRofCapabilitiesStatementLoadingState[action.rofId],
						[action.pitId]: EUpdateState.Loaded
					}
				}
			};
		}

		case 'SET_CURRENT_SMART_APP_ID': {
			return {
				...state,
				currentSmartAppId: action.smartAppId
			};
		}

		case 'CREATE_SMART_APP_LAUNCHER_REQUEST': {
			return {
				...state
			};
		}

		case 'CREATE_SMART_APP_LAUNCHER_RECEIVED': {
			const allRofSmartAppLaunchers = clone(state.allRofSmartAppLaunchers);
			const pitLevelAppLaunchers = allRofSmartAppLaunchers[action.rofId]
				? allRofSmartAppLaunchers[action.rofId][action.pitId]
				: {};

			const smartAppLaunchers =
				allRofSmartAppLaunchers[action.rofId] && allRofSmartAppLaunchers[action.rofId][action.pitId]
					? allRofSmartAppLaunchers[action.rofId][action.pitId][action.smartAppId]
					: {};

			return {
				...state,
				allRofSmartAppLaunchers: {
					...allRofSmartAppLaunchers,
					[action.rofId]: {
						...allRofSmartAppLaunchers[action.rofId],
						[action.pitId]: {
							...pitLevelAppLaunchers,
							[action.smartAppId]: {
								...smartAppLaunchers,
								[action.smartAppLauncher.id]: action.smartAppLauncher
							}
						}
					}
				}
			};
		}

		case 'DELETE_SMART_APP_LAUNCHER_REQUEST': {
			return {
				...state
			};
		}

		case 'DELETE_SMART_APP_LAUNCHER_RECEIVED': {
			const allRofSmartAppLaunchers = clone(state.allRofSmartAppLaunchers);

			delete allRofSmartAppLaunchers[action.rofId][action.pitId][action.smartAppId][
				action.smartAppLauncherId
			];
			return {
				...state,
				allRofSmartAppLaunchers
			};
		}

		case 'GET_SMART_APP_LAUNCHERS_REQUEST': {
			const allRofSmartAppLaunchersLoadingState = clone(state.allRofSmartAppLaunchersLoadingState);

			console.log();

			console.log('allRofSmartAppLaunchersLoadingState', allRofSmartAppLaunchersLoadingState);

			const pitLevel = allRofSmartAppLaunchersLoadingState[action.rofId]
				? allRofSmartAppLaunchersLoadingState[action.rofId][action.pitId]
				: {};

			return {
				...state,
				allRofSmartAppLaunchersLoadingState: {
					...allRofSmartAppLaunchersLoadingState,
					[action.rofId]: {
						...allRofSmartAppLaunchersLoadingState[action.rofId],
						[action.pitId]: {
							...pitLevel,
							[action.smartAppId]: EUpdateState.Loading
						}
					}
				}
			};
		}
		case 'GET_SMART_APP_LAUNCHERS_RECEIVED': {
			const allRofSmartAppLaunchersLoadingState = clone(state.allRofSmartAppLaunchersLoadingState);

			const pitLevelLoadingState = allRofSmartAppLaunchersLoadingState[action.rofId]
				? allRofSmartAppLaunchersLoadingState[action.rofId][action.pitId]
				: {};

			const allRofSmartAppLaunchers = clone(state.allRofSmartAppLaunchers);

			const pitLevelAppLaunchers = allRofSmartAppLaunchers[action.rofId]
				? allRofSmartAppLaunchers[action.rofId][action.pitId]
				: {};

			const smartAppLaunchers = convertArrayToIndexedObject(action.smartAppLaunchers);

			return {
				...state,
				allRofSmartAppLaunchersLoadingState: {
					...allRofSmartAppLaunchersLoadingState,
					[action.rofId]: {
						...allRofSmartAppLaunchersLoadingState[action.rofId],
						[action.pitId]: {
							...pitLevelLoadingState,
							[action.smartAppId]: EUpdateState.Loaded
						}
					}
				},
				allRofSmartAppLaunchers: {
					...allRofSmartAppLaunchers,
					[action.rofId]: {
						...allRofSmartAppLaunchers[action.rofId],
						[action.pitId]: {
							...pitLevelAppLaunchers,
							[action.smartAppId]: smartAppLaunchers
						}
					}
				}
			};
		}

		case 'GET_ROF_DETAILS_REQUEST': {
			const allRofDetailsLoadingState = clone(state.allRofDetailsLoadingState);

			allRofDetailsLoadingState[action.rofId] = EUpdateState.Loading;

			return {
				...state,
				allRofDetailsLoadingState
			};
		}

		case 'GET_ROF_DETAILS_RESPONSE': {
			const allRofDetailsLoadingState = clone(state.allRofDetailsLoadingState);
			const allRofDetails = clone(state.allRofDetails);

			allRofDetailsLoadingState[action.rofId] = EUpdateState.Loaded;

			allRofDetails[action.rofId] = action.rofDetails;

			return {
				...state,
				allRofDetailsLoadingState,
				allRofDetails
			};
		}

		//todo: TOGGLE_SPINNER is just for demo on 10/22
		case 'TOGGLE_SPINNER': {
			return {
				...state,
				isSpinnersActive: state.isSpinnersActive ? false : true
			};
		}

		case 'CREATE_SMART_AUTH_USER_REQUEST': {
			return {
				...state
			};
		}

		case 'CREATE_SMART_AUTH_USER_RECEIVED': {
			const allRofPitsSmartAuthUsers = clone(state.allRofPitsSmartAuthUsers);

			const smartAuthUsers =
				allRofPitsSmartAuthUsers[action.rofId] && allRofPitsSmartAuthUsers[action.rofId][action.pitId]
					? allRofPitsSmartAuthUsers[action.rofId][action.pitId]
					: {};

			return {
				...state,
				allRofPitsSmartAuthUsers: {
					...allRofPitsSmartAuthUsers,
					[action.rofId]: {
						...allRofPitsSmartAuthUsers[action.rofId],
						[action.pitId]: {
							...smartAuthUsers,
							[action.smartAuthUser.id]: action.smartAuthUser
						}
					}
				}
			};
		}

		case 'DELETE_SMART_AUTH_USER_REQUEST': {
			return {
				...state
			};
		}

		case 'DELETE_SMART_AUTH_USER_RECEIVED': {
			const allRofPitsSmartAuthUsers = clone(state.allRofPitsSmartAuthUsers);

			delete allRofPitsSmartAuthUsers[action.rofId][action.pitId][
				action.smartAuthUserId
			];
			return {
				...state,
				allRofPitsSmartAuthUsers
			};
		}

		case 'GET_SMART_AUTH_USERS_REQUEST': {
			const allRofPitsSmartAuthUsersLoadingState = clone(state.allRofPitsSmartAuthUsersLoadingState);

			return {
				...state,
				allRofPitsSmartAuthUsersLoadingState: {
					...allRofPitsSmartAuthUsersLoadingState,
					[action.rofId]: {
						...allRofPitsSmartAuthUsersLoadingState[action.rofId],
						[action.pitId]: EUpdateState.Loading
					}
				}
			};
		}
		case 'GET_SMART_AUTH_USERS_RECEIVED': {
			const allRofPitsSmartAuthUsersLoadingState = clone(state.allRofPitsSmartAuthUsersLoadingState);
			const allRofPitsSmartAuthUsers = clone(state.allRofPitsSmartAuthUsers);
			const smartAuthUsers = convertArrayToIndexedObject(action.smartAuthUsers);

			return {
				...state,
				allRofPitsSmartAuthUsersLoadingState: {
					...allRofPitsSmartAuthUsersLoadingState,
					[action.rofId]: {
						...allRofPitsSmartAuthUsersLoadingState[action.rofId],
						[action.pitId]: EUpdateState.Loaded
					}
				},
				allRofPitsSmartAuthUsers: {
					...allRofPitsSmartAuthUsers,
					[action.rofId]: {
						...allRofPitsSmartAuthUsers[action.rofId],
						[action.pitId]: smartAuthUsers
					}
				}
			};
		}

		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state || initialState;
};
