import * as React from 'react';
import { AppFrame, InnerContainer } from 'components/Page';
import { FamilyMemberDetailsTab } from './FamilyMemberDetailsTab/FamilyMemberDetailsTab';
import { FamilyMemberConditionTab } from './FamilyMemberConditionTab/FamilyMemberConditionTab';
import { ApplicationState } from 'stores';
import { connect, ResolveThunks } from 'react-redux';
import { useHistory } from 'react-router-dom';
import css from './familymember-dashboard.module.scss';
import { NavLink, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { PersonaBuilderActionCreators } from '../../../../stores/actions/personaBuilder/personaBuilderActions';
import { IPatient } from 'common/interfaces/personaBuilder/IPatient';
import { IPatientFamilyMember } from 'common/interfaces/personaBuilder/IPatientFamilyMember';
import { FamilyMemberTitleArea } from 'components/Page/FamilyMemberTitleArea';

interface IFamilyMemberDashboardRouteProps {
	id?: string
}

interface IFamilyMemberDashboardViewReduxProps {
	currentPatient: IPatient;
	personaBuilderDropdowns: {};
	personaBuilderHL7Valuesets: {};
	familyMembers: IPatientFamilyMember[];
}

const FamilyMemberDashboardViewBase: React.FC<IFamilyMemberDashboardViewReduxProps &
	RouteComponentProps<IFamilyMemberDashboardRouteProps> &
	ResolveThunks<typeof PersonaBuilderActionCreators>> = props => {
	
	const { familyMembers } = props;
	let _submitForm = [];
	const isNew = props.match.params.id === 'create';
	const history = useHistory();
	
  React.useEffect(()=>{
    props.getAllFamilyMembers();
		props.populateDropdowns();
		props.getAllHL7Valuesets();
		_submitForm = [];

		if (!isNew) {
			const familyMember = familyMembers.filter((fm) => { return fm.id === parseInt(props.match.params.id) });
			if (familyMember.length > 0) {
				props.getPatient(familyMember[0].patient_family_member_id.toString());
			}
			else {
				history.push("/persona-builder/patient-listing/family");
			}
		}

	}, []);

	const currentPatient = isNew ? {
		first_name: 'Create New Family Member',
		last_name: '',
		middle_name: '',
		dob: null,
		home_phone: '',
		mobile_phone: '',
		marital_status_id: null,
		race_id: null,
		gender_id: null,
		ethnicity_id: null,
		language_id: null,
		deceased_flag: '',
		height: '',
		weight: ''
	} : props.currentPatient;

	const navigationItems = [
		{
			label: 'Patient',
			location: `/persona-builder/patient-listing/family/${props.match.params.id}`,
			route: `/persona-builder/patient-listing/family/${props.match.params.id}`,
				component: () => <FamilyMemberDetailsTab {...props}/>,
			disabled: false
		},
		{
			label: 'Condition',
			location: `/persona-builder/patient-listing/family/${props.match.params.id}/condition`,
			route: `/persona-builder/patient-listing/family/${props.match.params.id}/condition`,
			component: FamilyMemberConditionTab,
			disabled: !currentPatient || isNew
		},
  ];
  
	return currentPatient && (
		<AppFrame>
			<InnerContainer>
				<Grid container>
          <Grid item xs={12} md={2} justify={'center'} className={css.imageContainer}>
						<img className={css.imageAvatar} width={'100%'}
									src={'https://ipog.edu.br/wp-content/themes/ipog/assets/img/graduacao/blank-avatar.png'}/>
					</Grid>
					<Grid item xs={12} md={10}>
            <FamilyMemberTitleArea
								patient={currentPatient}
							/>
						<ul className={css.tabBar}>
							{navigationItems.map((item, index) => {
								return (
									<li key={index}>
										<NavLink
											exact
											to={item.location}
											onClick={(e)=>{item.disabled && e.preventDefault()}}
											className={item.disabled ? css.disabled : ''}
										>
											{item.label}
										</NavLink>
									</li>
								);
							})}
						</ul>
					</Grid>
				</Grid>
				<Grid container xs={12}>
					<Grid item xs={12}>
						<Switch>
							{navigationItems.map((item, index) => {
								return (
									<Route
										key={index}
										exact
										path={item.route}
										component={item.component}
									/>
								);
							})}
							<Redirect to='/404'/>
						</Switch>
					</Grid>
				</Grid>
			</InnerContainer>
		</AppFrame>
	);
};

const mapStateToProps = (state: ApplicationState): IFamilyMemberDashboardViewReduxProps => {
	return {
		currentPatient: state.personaBuilder.currentPatient,
		familyMembers: state.personaBuilder.allFamilyMembers,
		personaBuilderDropdowns: state.personaBuilder.dropdowns || {},
		personaBuilderHL7Valuesets: state.personaBuilder.hl7Valuesets || {}
	};
};

export const FamilyMemberDashboardView = connect(
	mapStateToProps,
	PersonaBuilderActionCreators
)(FamilyMemberDashboardViewBase);
