import * as yup from 'yup';

interface IHL7AllergyReactionValidationSchemaConfig {
}

interface HL7AllergyReactionValidationSchema {
	allergy_reaction_display: string;
	allergy_reaction_snomed: string;
}

export const hl7AllergyReactionValidationSchema = (
	hl7AllergyReactionValidationSchemaConfig: IHL7AllergyReactionValidationSchemaConfig = {}
) => {
	let schemaDefinition = {
		allergy_reaction_display: yup.string().required('Display is required'),
		allergy_reaction_snomed: yup.string().required('Code is required')
	};

	return yup.object().shape<any>(schemaDefinition) as any;
};
