import * as React from 'react';
import { ApplicationState } from 'stores';
import { connect } from 'react-redux';
import { EUpdateState } from 'stores/shared/ENums';

interface IPatientConditionTabReduxProps {
	currentUserType: string;
	applyPersonaUpdateState: EUpdateState;
}

export class PatientConditionTabBase extends React.PureComponent<
	IPatientConditionTabReduxProps
	> {
	render() {
		const { currentUserType, applyPersonaUpdateState } = this.props;

		const pageDescription: string = 'Patient Condition Tab.';

		return (
			<div>
				{pageDescription}
			</div>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IPatientConditionTabReduxProps => {
	return {
		currentUserType: ((state.auth || {}).user || {}).attributes['custom:user_type'],
		applyPersonaUpdateState: state.persona.applyPersonaUpdateState
	};
};

export const PatientConditionTab = connect(
	mapStateToProps
)(PatientConditionTabBase);
