import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { TNavigationItems } from 'common/interfaces';
import css from './page-tab-bar.module.scss';
import { Redirect, Router, Switch } from 'react-router-dom';
import { Route } from 'react-router';
import * as Views from 'views';

interface IPageTabBar {
	navigationItems: TNavigationItems;
}

export const PageTabBar: React.FC<IPageTabBar> = props => {
	const { navigationItems } = props;
	return (
		<>
			<ul className={css.tabBar}>
				{navigationItems.map((item, index) => {
					return (
						<li key={index}>
							<NavLink exact to={item.location}>
								{item.label}
							</NavLink>
						</li>
					);
				})}
			</ul>
			<Switch>
				{navigationItems.map((item, index) => {
					return (
						<Route
							key={index}
							exact
							path={item.route}
							component={item.component}
						/>
					);
				})}
				<Redirect to='/404' />
			</Switch>
		</>
	);
};
