import rofActions from 'stores/actionInterfaces/rofActionInterfaces';
import { AppThunkAction } from 'stores';
import { modalActionCreators } from 'stores/actions/modalActions';
import { Auth } from 'aws-amplify';

import { getCurrentAuthHeaders } from 'common/utils/getCurrentAuthHeaders';

import { url } from 'settings';
import { push } from 'connected-react-router';
import { EUpdateState } from 'stores/shared/ENums';
import { sharedHistory } from '../../sharedStore';
import { ISmartApp, ISmartAuthUser } from 'common/interfaces';
import { ISmartAppForm } from 'views/SmartApp/FormSmartApp';
import { convertArrayToIndexedObject } from 'common/utils/convertArrayToIndexedObject';
import { getIdsFromArray } from 'common/utils/getIdsFromArray';
import { organizationActionCreators } from 'stores/actions/organizationActions';
import { IRof } from 'common/interfaces/IRof';
import { clone, flatten } from 'ramda';
import { convertIndexedObjectToArray } from 'common/utils/convertIndexedObjectToArray';
import { IRofBackup } from 'common/interfaces/IRofBackup';
import { IRofTemplate } from 'common/interfaces/IRofTemplate';
// import { IRofBackupOperationStatus } from 'common/types/TRofBackupOperationStatus';
import { IFormActions } from 'common/interfaces/IFormActions';
import { base64 } from 'common/utils/base64';
import { ISmartAppLauncher } from 'common/interfaces/ISmartAppLauncher';
import { TSetErrorMessage } from 'common/types/TSetErrorMessage';
import { TSetFormLoadingAttribute } from 'common/types/TSetFormLoadingAttribute';
import { messageActionCreators } from './messageActions';
// import { processBackupResult } from 'common/utils/processBackupResult';
import { errorMessages } from 'common/data/errorMessages';
import io from 'socket.io-client';
import { getCurrentJwt } from 'common/utils/getCurrentJwt';

export const rofActionCreators = {
	createRof: (
		rof: IRof,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute
	): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'CREATE_ROF_REQUEST' });

			const headers = await getCurrentAuthHeaders();
			try {
				headers.set('Content-Type', 'application/json');

				const createRof = await fetch(`${url}rof/create`, {
					method: 'POST',
					headers,
					body: JSON.stringify({
						...rof
					})
				});

				const createRofResponse = await createRof.json();

				if (createRof.ok === false) {
					setErrorMessage(createRofResponse.errorMessage);
					setFormLoadingAttribute(false);
					return;
				}

				// @ts-ignore
				dispatch(modalActionCreators.dismissModal());
				dispatch({
					type: 'CREATE_ROF_RESPONSE',
					rofId: createRofResponse.rof_id,
					rof: {
						stack_status: 'CREATE_QUEUED',
						id: createRofResponse.rof_id,
						...rof
					}
				});
				sharedHistory.push(`/sandbox/${createRofResponse.rof_id}`);
			} catch (e) {
				console.log(e);
				setErrorMessage(errorMessages.general);
				setFormLoadingAttribute(false);
			}
		};
	},
	getOrganizationRof: (organizationId: number): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			try {
				const currentOrganizationsRofLoadingState = getState().rof.organizationsRofLoadingState[
					organizationId
				];
				if (
					currentOrganizationsRofLoadingState === EUpdateState.Loading ||
					currentOrganizationsRofLoadingState === EUpdateState.Loaded
				) {
					return;
				}
				dispatch({
					type: 'GET_ORGANIZATION_ROF_REQUEST',
					organizationId
				});
				const headers = await getCurrentAuthHeaders();
				headers.set('Content-Type', 'application/json');
				headers.set('Accept', 'application/json');
				const getOrganizationRof = await fetch(`${url}org/${organizationId}/rof`, {
					headers
				});
				const getOrganizationRofResponse: IRof[] = await getOrganizationRof.json();
				console.log('getAllRofResponse', getOrganizationRofResponse);
				const allRof = convertArrayToIndexedObject(getOrganizationRofResponse);
				dispatch({
					type: 'GET_ORGANIZATION_ROF_RESPONSE',
					allRof,
					organizationId
				});

				// if (organizationId === getState().auth.user.attributes.organizationId) {
				// 	dispatch(
				// 		//@ts-ignore
				// 		rofActionCreators.getCurrentOrganizationRofStatus()
				// 	);
				// }
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	getOrganizationsRof: (organizationIds: number[]): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			try {
				const organizationsToLoad = organizationIds.filter(organizationId => {
					const organizationsRofLoadingState = getState().rof.organizationsRofLoadingState[
						organizationId
					];

					if (
						organizationsRofLoadingState === EUpdateState.Loading ||
						organizationsRofLoadingState === EUpdateState.Loaded
					) {
						return false;
					} else {
						return true;
					}
				});

				console.log('organizationsToLoad', organizationsToLoad);
				const headers = await getCurrentAuthHeaders('json');

				const urlsToFetch = organizationsToLoad.map(organizationId =>
					fetch(`${url}org/${organizationId}/rof`, { headers }).then(response => response.json())
				);

				const rofRequests = await Promise.all(urlsToFetch);

				const rofs = flatten(rofRequests);

				console.log('rofRequests', flatten(rofRequests));
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	getAllRof: (): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			try {
				dispatch({
					type: 'GET_ALL_ROF_REQUEST',
				});
				const headers = await getCurrentAuthHeaders();
				headers.set('Content-Type', 'application/json');
				headers.set('Accept', 'application/json');
				const getAllRof = await fetch(`${url}rof/rof_listing`, {
					headers
				});
				const getAllRofResponse: IRof[] = await getAllRof.json();
				const allRof = convertArrayToIndexedObject(getAllRofResponse);
				dispatch({
					type: 'GET_ALL_ROF_RESPONSE',
					allRof,
				});
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	getRof: (rofId: number): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			if (getState().rof.allRof[rofId] && getState().rof.allRofPits[rofId]) {
				return;
			}

			try {
				dispatch({ type: 'GET_ROF_REQUEST', rofId });

				const headers = await getCurrentAuthHeaders('json');

				const [rof, pits] = await Promise.all([
					fetch(`${url}rof/${rofId}`, { headers }),
					fetch(`${url}rof/${rofId}/fhir-pits`, { headers })
				]);

				if (rof.status !== 404 && pits.status !== 404) {
					const rofResponse: IRof = await rof.json();
					const pitsResponse = <any[]>await pits.json();
					const allPits = pitsResponse.reduce((obj, pit) => {
						obj[pit.id] = pit;
						return obj;
					}, {});

					console.log('rofResponse', rofResponse);

					console.log('allPits', allPits);

					dispatch({
						type: 'GET_ROF_RESPONSE',
						rofId: rofResponse.id,
						rof: rofResponse,
						pits: allPits
					});
				} else {
					sharedHistory.push('/rof-management');
					// @ts-ignore
					dispatch(modalActionCreators.dismissModal());
				}
			} catch (e) {
				sharedHistory.push('/rof-management');
				console.log(e);
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	setRofAutomaticPreferenceState: (rofId: number, preference: boolean): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			dispatch({
				type: 'SET_ROF_AUTOMATIC_PREFERENCE_STATE',
				rofId,
				preference
			});
		};
	},
	setCurrentRofState: (setAs: boolean): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			try {
				const setAsValue = setAs ? 'start' : 'stop';

				const currentRofId = getState().rof.currentRofId;

				const headers = await getCurrentAuthHeaders('json');
				const updateOrganizationsRequest = await fetch(`${url}rof/${currentRofId}/${setAsValue}`, {
					method: 'post',
					headers
				});

				dispatch({
					type: 'SET_CURRENT_ROF_STATE',
					isCurrentRofOn: setAs,
					rofId: currentRofId
				});
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	getSmartApps: (rofId: number, pitId: number): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			if (
				getState().rof.allRofPitsSmartAppsUpdateState.hasOwnProperty(rofId) &&
				getState().rof.allRofPitsSmartAppsUpdateState[rofId].hasOwnProperty(pitId) &&
				getState().rof.allRofPitsSmartAppsUpdateState[rofId][pitId] === EUpdateState.Loaded
			) {
				return;
			}

			try {
				dispatch({ type: 'GET_PITS_SMART_APPS_REQUEST', rofId, pitId });
				const headers = await getCurrentAuthHeaders('json');
				const getSmartAppsRequest = await fetch(`${url}rof/${rofId}/fhir-pits/${pitId}/smart_app`, {
					headers
				});

				const smartApps: ISmartApp[] = await getSmartAppsRequest.json();

				smartApps.sort((smartAppA, smartAppB) => {
					const smartAppADate = new Date(smartAppA.registered_on);
					const smartAppBDate = new Date(smartAppB.registered_on);

					return smartAppADate.getTime() - smartAppBDate.getTime();
				});

				dispatch({
					type: 'GET_PITS_SMART_APPS_RESPONSE',
					rofId,
					pitId,
					smartApps
				});
			} catch (error) {
				console.log('error', error);
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(error));
			}
		};
	},
	createSmartApp: (
		rofId: number,
		pitId: number,
		formValues: ISmartAppForm,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute
	): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			try {
				setFormLoadingAttribute(true);
				dispatch({ type: 'CREATE_SMART_APP_REQUEST' });
				const headers = await getCurrentAuthHeaders('json');
				const createSmartAppRequest = await fetch(`${url}rof/${rofId}/fhir-pits/${pitId}/smart_app`, {
					method: 'post',
					headers,
					body: JSON.stringify({
						name: formValues.name,
						redirect_uris: formValues.redirect_uris,
						client_type: formValues.client_type,
						grant_type: formValues.grant_type,
						oauth_scopes: formValues.oauth_scopes,
					})
				});

				const createdSmartApp = await createSmartAppRequest.json();

				if (createSmartAppRequest.ok === false) {
					setErrorMessage(createdSmartApp.errorMessage);
					dispatch({
						type: 'CREATE_SMART_APP_ERROR'
					});
					return;
				}

				dispatch({
					type: 'CREATE_SMART_APP_RESPONSE',
					rofId,
					pitId,
					createdSmartApp
				});

				// @ts-ignore
				dispatch(modalActionCreators.dismissModal());

				sharedHistory.push(`/sandbox/${rofId}/pit/${pitId}/app/${createdSmartApp.client_id}`);
			} catch (error) {
				console.log('error', error);
				dispatch({
					type: 'CREATE_SMART_APP_ERROR'
				});
			}
		};
	},

	updateSmartApp: (
		rofId: number,
		pitId: number,
		formData: ISmartAppForm,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute
	): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			try {
				setFormLoadingAttribute(true);
				dispatch({ type: 'UPDATE_SMART_APP_REQUEST' });
				const headers = await getCurrentAuthHeaders('json');
				const updateSmartAppRequest = await fetch(`${url}rof/${rofId}/fhir-pits/${pitId}/smart_app/${formData.client_id}`, {
					method: 'PUT',
					headers,
					body: JSON.stringify({
						...formData,
						oauth_scopes: formData.oauth_scopes
					})
				});

				const updatedSmartApp = await updateSmartAppRequest.json();

				if (updateSmartAppRequest.ok === false) {
					setErrorMessage(updatedSmartApp.errorMessage);
					dispatch({
						type: 'UPDATE_SMART_APP_ERROR'
					});
					return;
				}

				dispatch({
					type: 'UPDATE_SMART_APP_RESPONSE',
					rofId,
					pitId,
					updatedSmartApp
				});

				// @ts-ignore
				dispatch(modalActionCreators.dismissModal());

				sharedHistory.push(`/sandbox/${rofId}/pit/${pitId}/app/${updatedSmartApp.client_id}`);
			} catch (error) {
				console.log('error', error);
				dispatch({
					type: 'UPDATE_SMART_APP_ERROR'
				});
			}
		};
	},

	clearCreatedSmartApp: (): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'CLEAR_CREATED_SMART_APP' });
		};
	},
	deleteSmartApp: (
		smartAppId: string,
		rofId: number,
		pitId: number,
		modalActions: IFormActions,
		onFinishRedirectToSmartAppTab?: boolean
	): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			console.log('delete smart app', smartAppId);
			try {
				modalActions.setFormLoadingAttribute(true);
				dispatch({ type: 'DELETE_SMART_APP_REQUEST' });

				const headers = await getCurrentAuthHeaders('json');
				const deleteSmartAppRequest = await fetch(
					`${url}rof/${rofId}/fhir-pits/${pitId}/smart_app/${smartAppId}`,
					{
						method: 'delete',
						headers
					}
				);

				if (deleteSmartAppRequest.ok === false) {
					const deleteSmartAppResponse = await deleteSmartAppRequest.json();
					modalActions.setErrorMessage(deleteSmartAppResponse.errorMessage);
					return;
				}

				// @ts-ignore
				dispatch(modalActionCreators.dismissModal());
				console.log('onFinishRedirectToSmartAppTab', onFinishRedirectToSmartAppTab);

				if (onFinishRedirectToSmartAppTab) {
					sharedHistory.push(`/sandbox/${rofId}/pit/${pitId}/app`);
				}

				dispatch({
					type: 'DELETE_SMART_APP_RESPONSE',
					smartAppId,
					rofId,
					pitId
				});
			} catch (e) {
				console.log(e);
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	getRofBackups: (rofId: number): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			const backupsUpdateState = getState().rof.allRofBackupsUpdateStatus[rofId];

			console.log('backupsUpdateState', backupsUpdateState);
			if (backupsUpdateState === EUpdateState.Loading || backupsUpdateState === EUpdateState.Loaded) {
				return;
			}
			try {
				dispatch({ type: 'GET_ROF_BACKUPS_REQUEST', rofId: rofId });
				const headers = await getCurrentAuthHeaders('json');
				const getBackupsRequest = await fetch(`${url}rof/${rofId}/backup`, {
					headers
				});

				const rofBackupsResponse = await getBackupsRequest.json();

				// const [rofBackups, rofBackupOperationStatus] = processBackupResult(
				// 	rofBackupsResponse
				// );

				dispatch({
					type: 'GET_ROF_BACKUPS_RESPONSE',
					rofId,
					rofBackups: rofBackupsResponse.backups
					// 	rofBackupOperationStatus
				});
				//@ts-ignore
				// dispatch(rofActionCreators.pollBackups());
			} catch (error) {
				console.log('error', error);
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	createRofBackup: (
		rofId: number,
		name: string,
		modalActions: IFormActions
	): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			try {
				dispatch({ type: 'CREATE_ROF_BACKUP_REQUEST' });
				modalActions.setFormLoadingAttribute(true);

				const headers = await getCurrentAuthHeaders('json');
				const getBackupsRequest = await fetch(`${url}rof/${rofId}/backup`, {
					method: 'post',
					headers,
					body: JSON.stringify({
						name
					})
				});

				if (getBackupsRequest.ok === false) {
					const getBackupsResponse = await getBackupsRequest.json();
					modalActions.setFormLoadingAttribute(false);
					if (
						getBackupsResponse.errorType === 'InvalidDBClusterStateFault' ||
						getBackupsResponse.errorType === 'InvalidDBInstanceStateFault'
					) {
						modalActions.setErrorMessage(errorMessages.busyBackup);
					} else {
						modalActions.setErrorMessage(getBackupsResponse.errorMessage);
					}
					return;
				}

				const rofBackup = await getBackupsRequest.json();

				rofBackup.date = new Date(rofBackup.date);

				// const rofBackupOperationStatus: IRofBackupOperationStatus = {
				// 	operationStatus: 'creating',
				// 	operationSubject: rofBackup.id
				// };

				dispatch({
					type: 'CREATE_ROF_BACKUP_RESPONSE',
					rofBackup,
					rofId
					// rofBackupOperationStatus
				});

				// @ts-ignore
				dispatch(modalActionCreators.dismissModal());
				//@ts-ignore
				// dispatch(rofActionCreators.pollBackups());
			} catch (e) {
				console.log(e);
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	restoreRofBackup: (rofId: number, rofBackupId: string): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			try {
				const headers = await getCurrentAuthHeaders('json');

				dispatch({
					type: 'RESTORE_ROF_BACKUP_REQUEST',
					rofId,
					rofBackupId
				});

				const restoreRofRequest = await fetch(`${url}rof/${rofId}/backup/${rofBackupId}/restore`, {
					method: 'post',
					headers,
					body: JSON.stringify({
						rofId
					})
				});

				dispatch({
					type: 'RESTORE_ROF_BACKUP_RESPONSE',
					rofId,
					rofBackupId
				});
				//@ts-ignore
				// dispatch(rofActionCreators.pollBackups());
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	deleteRofBackup: (
		rofId: number,
		rofBackupId: string,
		modalActions: IFormActions
	): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			try {
				const headers = await getCurrentAuthHeaders('json');
				dispatch({
					type: 'DELETE_ROF_BACKUP_REQUEST',
					rofId,
					rofBackupId
				});
				modalActions.setFormLoadingAttribute(true);

				const deleteRofRequest = await fetch(`${url}rof/${rofId}/backup/${rofBackupId}`, {
					method: 'delete',
					headers
				});

				if (deleteRofRequest.ok === false) {
					const deleteRofResponse = await deleteRofRequest.json();
					modalActions.setErrorMessage(deleteRofResponse.errorMessage);
					return;
				}

				dispatch({
					type: 'DELETE_ROF_BACKUP_RESPONSE',
					rofId,
					rofBackupId
				});
				// @ts-ignore
				dispatch(modalActionCreators.dismissModal());
				//@ts-ignore
				// dispatch(rofActionCreators.pollBackups());
			} catch (e) {
				console.log(e);
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	setCurrentRofId: (rofId: number): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			dispatch({
				type: 'SET_CURRENT_ROF_ID',
				currentRofId: rofId
			});
		};
	},
	deleteRof: (rofId: number): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			try {
				dispatch({ type: 'DELETE_CURRENT_ROF_REQUEST' });

				const headers = await getCurrentAuthHeaders('json');

				await fetch(`${url}rof/${rofId}`, {
					method: 'delete',
					headers
				});

				dispatch({
					type: 'DELETE_CURRENT_ROF_RESPONSE',
					rofId
				});

				//@ts-ignore
				dispatch(modalActionCreators.dismissModal());
				sharedHistory.push(`/rof-management`);
			} catch (e) {
				console.log(e);
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	restoreRofOriginalState: (rofId: number, modalActions: IFormActions): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			try {
				dispatch({ type: 'RESTORE_ROF_ORIGINAL_STATE_REQUEST' });

				const headers = await getCurrentAuthHeaders('json');

				modalActions.setFormLoadingAttribute(true);

				await fetch(`${url}rof/${rofId}/reset`, {
					method: 'post',
					headers
				});

				dispatch({
					type: 'RESTORE_ROF_ORIGINAL_STATE_RESPONSE',
					rofId
				});

				//@ts-ignore
				// dispatch(rofActionCreators.pollBackups());
				dispatch(
					//@ts-ignore=
					modalActionCreators.dismissModal()
				);
			} catch (e) {
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
				modalActions.setFormLoadingAttribute(false);
			}
		};
	},
	getRofTemplates: (): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			if (!(getState().rof.templatesLoadingState === EUpdateState.Empty)) {
				return;
			}

			try {
				dispatch({ type: 'GET_ROF_TEMPLATE_REQUEST' });
				const headers = await getCurrentAuthHeaders('json');
				const rofTemplateRequest = await fetch(`${url}rof/template`, {
					headers
				});

				const templates: IRofTemplate[] = await rofTemplateRequest.json();
				console.log('templates', templates);

				dispatch({ type: 'GET_ROF_TEMPLATE_RESPONSE', templates });
			} catch (e) {
				console.log(e);
				//@ts-ignore
				dispatch(messageActionCreators.createMessage(e));
			}
		};
	},
	// pollBackups: (): AppThunkAction<rofActions> => {
	// 	return async (dispatch, getState) => {
	// 		try {
	// 			const allRof = convertIndexedObjectToArray(clone(getState().rof.allRof));
	// 			// console.log(
	// 			// 	'getState().rof.allRofBackupOperationStatus',
	// 			// 	getState().rof.allRofBackupOperationStatus
	// 			// );
	// 			allRof.forEach(rof => {
	// 				if (
	// 					getState().rof.allRofBackupOperationStatus[rof.id] &&
	// 					getState().rof.allRofBackupOperationStatus[rof.id].operationStatus &&
	// 					getState().rof.rofBackupPollingStatus[rof.id] !== true
	// 				) {
	// 					dispatch({
	// 						type: 'SET_ROF_BACKUP_POLLING_STATUS',
	// 						rofId: rof.id,
	// 						isPolling: true
	// 					});
	// 					const checkRofBackupStatus = setInterval(async () => {
	// 						const headers = await getCurrentAuthHeaders('json');
	// 						const rofBackupRequest = await fetch(`${url}rof/${rof.id}/backup`, {
	// 							headers
	// 						});
	// 						const updatedBackup = await rofBackupRequest.json();
	// 						console.log('updatedBackup', updatedBackup);
	// 						const operationStatus = updatedBackup.operation_status;
	// 						console.log(`updatedBackup.operationStatus ${rof.id}`, operationStatus);
	// 						const rofBackups = updatedBackup.backups.map(backup => {
	// 							backup.date = new Date(backup.date);
	// 							return backup;
	// 						});
	// 						if (operationStatus === 'available') {
	// 							dispatch({
	// 								type: 'GET_ROF_BACKUPS_RESPONSE',
	// 								rofId: rof.id,
	// 								rofBackups,
	// 								rofBackupOperationStatus: {
	// 									operationStatus,
	// 									operationSubject: updatedBackup.operation_subject
	// 								}
	// 							});
	// 							clearInterval(checkRofBackupStatus);
	// 							dispatch({
	// 								type: 'SET_ROF_BACKUP_POLLING_STATUS',
	// 								rofId: rof.id,
	// 								isPolling: false
	// 							});
	// 						}
	// 					}, 10000);
	// 				}
	// 			});
	// 		} catch (e) {
	// 			//@ts-ignore
	// 			dispatch(messageActionCreators.createMessage(e));
	// 		}
	// 	};
	// },
	setCurrentPitId: (pitId): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			dispatch({
				type: 'SET_CURRENT_PIT_ID',
				currentPitId: pitId
			});
		};
	},
	getCapabilitiesStatement: (rofId: number, pitId: number): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			if (
				getState().rof.allRofCapabilitiesStatementLoadingState[getState().rof.currentRofId] &&
				getState().rof.allRofCapabilitiesStatementLoadingState[getState().rof.currentRofId][
				getState().rof.currentPitId
				]
			) {
				return;
			}
			let currentRof: IRof = getState().rof.allRof[rofId];
			const headers = await getCurrentAuthHeaders('json');
			const currentRofDetails = getState().rof.allRofDetails[rofId];
			if (currentRofDetails.pit_basic_auth_username && currentRofDetails.pit_basic_auth_password) {
				const basicAuthenticationCredentials = base64(
					`${currentRofDetails.pit_basic_auth_username}:${currentRofDetails.pit_basic_auth_password}`
				);
				dispatch({
					type: 'GET_CAPABILITIES_STATEMENT_REQUEST',
					rofId,
					pitId
				});

				console.log(`Basic ${basicAuthenticationCredentials}`);

				headers.set('Authorization', `Basic ${basicAuthenticationCredentials}`);
				const capabilitiesUrl = getState().rof.allRofPits[rofId][pitId].base_url;

				try {
					const capabilitiesStatementRequest = await fetch(`${capabilitiesUrl}fhir/metadata`, {
						headers
					});
					const capabilitiesStatement = await capabilitiesStatementRequest.json();

					dispatch({
						type: 'GET_CAPABILITIES_STATEMENT_RECEIVED',
						rofId,
						pitId,
						capabilitiesStatement
					});
				} catch (error) {
					console.log(error);
					//@ts-ignore
					dispatch(messageActionCreators.createMessage(e));
				}
			}
		};
	},
	setCurrentSmartAppId: (smartAppId: string): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			dispatch({
				type: 'SET_CURRENT_SMART_APP_ID',
				smartAppId
			});
		};
	},
	getSmartAppLaunchers: (
		rofId: number,
		pitId: number,
		smartAppId: string
	): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders('json');

			try {
				dispatch({
					type: 'GET_SMART_APP_LAUNCHERS_REQUEST',
					rofId,
					pitId,
					smartAppId
				});

				const getSmartAppLaunchersRequest = await fetch(
					`${url}rof/${rofId}/fhir-pits/${pitId}/smart_app/${smartAppId}/launch`,
					{
						headers
					}
				);

				const smartAppLaunchers = await getSmartAppLaunchersRequest.json();

				dispatch({
					type: 'GET_SMART_APP_LAUNCHERS_RECEIVED',
					rofId,
					pitId,
					smartAppId,
					smartAppLaunchers
				});
			} catch (error) {
				console.log(error);
			}
		};
	},
	createSmartAppLauncher: (
		smartAppLauncher: ISmartAppLauncher,
		modalActions: IFormActions,
		rofId: number,
		pitId: number,
		smartAppId: string
	): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			console.log('smartAppLauncher', smartAppLauncher);
			console.log('modalActions', modalActions);
			console.log('rofId', rofId);
			console.log('pitId', pitId);
			console.log('smartAppId', smartAppId);

			try {
				const headers = await getCurrentAuthHeaders('json');
				modalActions.setFormLoadingAttribute(true);

				dispatch({
					type: 'CREATE_SMART_APP_LAUNCHER_REQUEST',
					rofId,
					pitId
				});

				const createSmartAppLauncherRequest = await fetch(
					`${url}rof/${rofId}/fhir-pits/${pitId}/smart_app/${smartAppId}/launch`,
					{
						method: 'post',
						headers,
						body: JSON.stringify(smartAppLauncher)
					}
				);

				const createSmartAppLauncherResponse = await createSmartAppLauncherRequest.json();

				if (createSmartAppLauncherRequest.ok === false) {
					modalActions.setErrorMessage(createSmartAppLauncherResponse.errorMessage);
					dispatch({
						type: 'CREATE_SMART_APP_ERROR'
					});
					return;
				}

				console.log('createSmartAppLauncherResponse', createSmartAppLauncherResponse);

				const smartAppLauncherWithID: ISmartAppLauncher = {
					...smartAppLauncher,
					id: createSmartAppLauncherResponse.smart_app_launch_id
				};

				//@ts-ignore
				dispatch(modalActionCreators.dismissModal());

				dispatch({
					type: 'CREATE_SMART_APP_LAUNCHER_RECEIVED',
					rofId,
					pitId,
					smartAppId,
					smartAppLauncher: smartAppLauncherWithID
				});
			} catch (error) { }
		};
	},
	deleteSmartAppLauncher: (
		modalActions: IFormActions,
		rofId: number,
		pitId: number,
		smartAppId: string,
		smartAppLauncherId: number
	): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders('json');

			try {
				dispatch({ type: 'DELETE_SMART_APP_LAUNCHER_REQUEST' });
				modalActions.setFormLoadingAttribute(true);

				const deleteSmartAppLauncherRequest = await fetch(
					`${url}rof/${rofId}/fhir-pits/${pitId}/smart_app/${smartAppId}/launch/${smartAppLauncherId}`,
					{
						method: 'delete',
						headers
					}
				);

				//@ts-ignore
				dispatch(modalActionCreators.dismissModal());

				dispatch({
					type: 'DELETE_SMART_APP_LAUNCHER_RECEIVED',
					rofId,
					pitId,
					smartAppId,
					smartAppLauncherId
				});
			} catch (error) {
				console.log(error);
			}
		};
	},
	getRofDetails: (rofId: number): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			if (
				getState().rof.allRofDetailsLoadingState[rofId] === EUpdateState.Loading ||
				getState().rof.allRofDetailsLoadingState[rofId] === EUpdateState.Loaded
			) {
				return;
			}
			try {
				const headers = await getCurrentAuthHeaders('json');
				dispatch({ type: 'GET_ROF_DETAILS_REQUEST', rofId });

				const getRofDetailsRequest = await fetch(`${url}rof/${rofId}/detail`, {
					headers
				});

				const rofDetails = await getRofDetailsRequest.json();

				dispatch({ type: 'GET_ROF_DETAILS_RESPONSE', rofId, rofDetails });
			} catch (error) {
				console.log(error);
			}
		};
	},
	//todo: toggleSpinners is just for demo on 10/22
	toggleSpinners: (): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			dispatch({ type: 'TOGGLE_SPINNER' });
		};
	},
	getSmartAuthUsers: (
		rofId: number,
		pitId: number
	): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders('json');

			try {
				dispatch({
					type: 'GET_SMART_AUTH_USERS_REQUEST',
					rofId,
					pitId
				});

				const getSmartAuthUsersRequest = await fetch(
					`${url}rof/${rofId}/fhir-pits/${pitId}/smart_auth_user`,
					{
						headers
					}
				);

				const smartAuthUsers = await getSmartAuthUsersRequest.json() as ISmartAuthUser[];

				dispatch({
					type: 'GET_SMART_AUTH_USERS_RECEIVED',
					rofId,
					pitId,
					smartAuthUsers
				});
			} catch (error) {
				console.log(error);
			}
		};
	},
	createSmartAuthUser: (
		rofId: number,
		pitId: number,
		smartAuthUser: ISmartAuthUser,
		setErrorMessage: TSetErrorMessage,
		setFormLoadingAttribute: TSetFormLoadingAttribute
	): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			console.log('SmartAuthUser', smartAuthUser);
			console.log('rofId', rofId);
			console.log('pitId', pitId);

			try {
				setFormLoadingAttribute(true);
				dispatch({
					type: 'CREATE_SMART_AUTH_USER_REQUEST',
					rofId,
					pitId
				});
				const headers = await getCurrentAuthHeaders('json');
				const createSmartAuthUserRequest = await fetch(
					`${url}rof/${rofId}/fhir-pits/${pitId}/smart_auth_user`,
					{
						method: 'post',
						headers,
						body: JSON.stringify(smartAuthUser)
					}
				);

				const createSmartAuthUserResponse = await createSmartAuthUserRequest.json();

				if (createSmartAuthUserRequest.ok === false) {
					setErrorMessage(createSmartAuthUserResponse.errorMessage);
					dispatch({
						type: 'CREATE_SMART_APP_ERROR'
					});
					return;
				}

				console.log('createSmartAuthUserResponse', createSmartAuthUserResponse);

				const getSmartAuthUsersRequest = await fetch(
					`${url}rof/${rofId}/fhir-pits/${pitId}/smart_auth_user/${createSmartAuthUserResponse.smart_auth_user_id}`,
					{
						headers
					}
				);

				const smartAuthUserWithID = await getSmartAuthUsersRequest.json() as ISmartAuthUser;

				//@ts-ignore
				dispatch(modalActionCreators.dismissModal());

				dispatch({
					type: 'CREATE_SMART_AUTH_USER_RECEIVED',
					rofId,
					pitId,
					smartAuthUser: smartAuthUserWithID
				});

			} catch (error) { }
		};
	},
	deleteSmartAuthUser: (
		modalActions: IFormActions,
		rofId: number,
		pitId: number,
		smartAuthUserId: number
	): AppThunkAction<rofActions> => {
		return async (dispatch, getState) => {
			const headers = await getCurrentAuthHeaders('json');

			try {
				dispatch({ type: 'DELETE_SMART_AUTH_USER_REQUEST' });
				modalActions.setFormLoadingAttribute(true);

				await fetch(
					`${url}rof/${rofId}/fhir-pits/${pitId}/smart_auth_user/${smartAuthUserId}`,
					{
						method: 'delete',
						headers
					}
				);

				//@ts-ignore
				dispatch(modalActionCreators.dismissModal());

				dispatch({
					type: 'DELETE_SMART_AUTH_USER_RECEIVED',
					rofId,
					pitId,
					smartAuthUserId: smartAuthUserId
				});
			} catch (error) {
				console.log(error);
			}
		};
	}
	//,
	// connectToSandboxUpdates: (): AppThunkAction<rofActions> => {
	// 	return async (dispatch, getState) => {
	// 		if (!getState().rof.socket) {
	// 			const socket = io(url);

	// 			console.log('connectToSandboxUpdates', socket);
	// 			socket.on('connect', async () => {
	// 				console.log('connected');
	// 				socket.send('User has connected!');
	// 				const JWT = await getCurrentJwt();
	// 				socket.emit('join', JWT);
	// 			});

	// 			socket.on('rof_status', data => {
	// 				console.log('rof_status data', data);
	// 				const { rof_id, org_id, rof_status } = JSON.parse(data);
	// 				if (rof_status === 'DELETE_IN_PROGRESS' || rof_status === 'DELETE_COMPLETE') {
	// 					dispatch({ type: 'REMOVE_SANDBOX_FROM_STATE', sandboxId: rof_id });
	// 				} else {
	// 					dispatch({
	// 						type: 'UPDATE_SANDBOX_STATUS',
	// 						sandboxId: rof_id,
	// 						orgId: org_id,
	// 						status: rof_status
	// 					});
	// 				}
	// 			});

	// 			socket.on('rof_create', data => {
	// 				console.log('rof_create data', data);
	// 				const sandbox = JSON.parse(data);
	// 				dispatch({ type: 'ADD_SANDBOX_TO_STATE', sandbox });
	// 			});

	// 			dispatch({ type: 'CONNECT_TO_SANDBOX_UPDATES', socket });
	// 		}
	// 	};
	// }
};
