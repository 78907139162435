import React, { useEffect, useState } from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { Redirect } from 'react-router';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ICognitoUser } from "common/interfaces";
import { authActionCreators } from "stores/actions/authActions";
import { ApplicationState } from 'stores';
import { AppFrameAuth } from './AppFrameAuth';
import { Error } from 'components/Toast';
import { Button } from 'components/Button';
import css from './auth.module.scss';
import {
    isNumberRegx,
    lowerCaseCharRegx,
    specialCharacterRegx,
    upperCaseCharRegx
} from 'common/utils/validatePassword';
import { FormLabel } from 'components/FormElements';
import PasswordStrengthIndicator from 'components/FormElements/PasswordStrengthIndicator/PathwordStrengthIndicator';


const newPasswordRequiredActionCreators = {
    ...authActionCreators
};

interface INewPasswordRequiredStateProps {
    completeNewPasswordPromise: any;
    user: ICognitoUser;
    error: string;
    newPasswordRequireError: string;
}

export const NewPasswordRequiredBase: React.FunctionComponent<
    INewPasswordRequiredStateProps & ResolveThunks<typeof newPasswordRequiredActionCreators>
> = ({
    user,
    newPasswordRequireError,
    removeUserFromAuthState,
    newPasswordRequiredSet,
}) => {
        useEffect(() => {
            console.log(`initializing interval`);
            const interval = setInterval(() => {
                removeUserFromAuthState();
            }, 180000);


            return () => {
                console.log(`clearing interval`);
                clearInterval(interval);
            };
        }, []);

        const [passwordValidity, setPasswordValidity] = useState({
            minChar: null,
            number: null,
            upperCaseChar: null,
            lowerCaseChar: null,
            specialChar: null,
        })

        const onChangePassword = (password, values) => {
            values.password = password;

            setPasswordValidity({
                minChar: !!(password.length >= 8),
                number: !!isNumberRegx.test(password),
                specialChar: !!specialCharacterRegx.test(password),
                upperCaseChar: !!upperCaseCharRegx.test(password),
                lowerCaseChar: !!lowerCaseCharRegx.test(password),
            });
        };

        const completeNewPassword = (password: string) => {
            newPasswordRequiredSet(password);
        };

        console.log('NewPasswordRequired');

        if (!user || user.challengeName !== 'NEW_PASSWORD_REQUIRED') {
            return <Redirect to='/' />;
        }

        return (
            <AppFrameAuth
                title='New Password Required'
                description='The password must be at least eight characters long and include the following character types: uppercase, lowercase, number, and special.'
            >
                {newPasswordRequireError && <Error>{newPasswordRequireError}</Error>}
                <Formik
                    initialValues={{
                        password: ''
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        completeNewPassword(values.password);
                        setSubmitting(false);
                    }}
                    validationSchema={yup.object().shape({
                        password: yup.string().required('Required')
                    })}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <FormLabel htmlFor='password'>
                                    New Password
                                </FormLabel>
                                <input
                                    id='password'
                                    placeholder='Enter your password'
                                    autoComplete='new-password'
                                    type='password'
                                    value={values.password}
                                    onChange={e =>
                                        onChangePassword(e.target.value, values)
                                    }
                                    onBlur={handleBlur}
                                    className={errors.password && touched.password ? 'text-input error' : 'text-input'}
                                />
                                <PasswordStrengthIndicator
                                    validity={passwordValidity}
                                />
                                <br></br>
                                {errors.password && touched.password && (
                                    <div className='input-feedback'>{errors.password}</div>
                                )}
                                <div className='row'>
                                    <Button className={css.resetSubmit} type='submit' disabled={isSubmitting}>
                                        Change Password
                                    </Button>
                                    <Button style='link' className={css.backLink} onClick={removeUserFromAuthState}>
                                        Back to Sign In
                                    </Button>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </AppFrameAuth>
        );
    }


const mapStateToProps = (state: ApplicationState): INewPasswordRequiredStateProps => ({
    completeNewPasswordPromise: state.auth.completeNewPasswordPromise,
    user: state.auth.user,
    error: state.auth.authError,
    newPasswordRequireError: state.auth.newPasswordRequiredError
});

export const NewPasswordRequired = connect(
    mapStateToProps,
    newPasswordRequiredActionCreators
)(NewPasswordRequiredBase);