import * as React from 'react';
import classes from 'classnames';
import css from './list-item-basic.module.scss';

interface IListItemBasicProps {
	ContentRight?: React.ElementType;
	title?: string;
}

export const ListItemBasic: React.FC<IListItemBasicProps> = props => {
	const { children, ContentRight, title } = props;
	return (
		<li className={css.listItemBasic}>
			<div className={css.leftContent}>
				{title && <h3 className={classes('h6', css.title)}>{title}</h3>}

				{children}
			</div>
			{ContentRight && (
				<div className={css.rightContent}>
					<ContentRight />
				</div>
			)}
		</li>
	);
};
