export const convertIndexedObjectToArray = <
	T extends { id?: number }
>(indexObject: {
	[id: number]: T;
}): T[] => {
	if (indexObject) {
		return Object.keys(indexObject).map(id => indexObject[id]);
	} else {
		return [];
	}
};

export const convertStringIndexedObjectToArray = <T>(indexObject: {
	[id: string]: T;
}): T[] => {
	if (indexObject) {
		return Object.keys(indexObject).map(id => indexObject[id]);
	} else {
		return [];
	}
};
