import * as React from 'react';
import NumberFormat from 'react-number-format';

interface INumberField {
	id?: string;
	placeholder?: string;
	value?: string;
	onChange?: (e: string | React.ChangeEvent<any>) => void;
	onBlur?: (e: string | React.ChangeEvent<any>) => void;
	className?: string;
	autocomplete?: string
}

export const PhoneNumberField: React.FC<INumberField> = props => {
	const { id, placeholder, value, onBlur, onChange, className, autocomplete } = props;
	return (
		<NumberFormat
			id={id}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			className={className}
			format='+1 (###) ###-####'
			mask='_'
			autoComplete={autocomplete || 'phone'}
		/>
	);
};
