import { combineReducers, Action } from 'redux';
import { RouterState, connectRouter } from 'connected-react-router';
import { History } from 'history';
import { AuthState } from 'stores/stateInterfaces/authStateInterface';
import { RofState } from 'stores/stateInterfaces/rofStateInterface';
import { OrganizationState } from 'stores/stateInterfaces/organizationStateInterface';
import { AuthReducers } from 'stores/reducers/authReducers';
import { RofReducers } from 'stores/reducers/rofReducers';
import { OrganizationReducers } from 'stores/reducers/organizationReducers';
import { ModalState } from 'stores/stateInterfaces/modalStateInterfaces';
import { ModalReducers } from './reducers/modalReducers';
import { PersonaUploadReducers } from './reducers/personaUploadReducers';
import { PersonaUploadState } from './stateInterfaces/personaUploadStateInterfaces';
import { NotificationState } from 'stores/stateInterfaces/notificationStateInterface';
import { NotificationReducers } from './reducers/notificationReducers';
import { SuperAdminState } from 'stores/stateInterfaces/superAdminStateInterface';
import { SuperAdminReducers } from './reducers/superAdminReducers';


import { PersonaReducers } from './reducers/personaReducers';
import { PersonaState } from './stateInterfaces/personaStateInterface';
import { MessageReducers } from './reducers/messageReducers';
import { MessageState } from './stateInterfaces/messageStateInterface';
import { websocketState } from './stateInterfaces/websocketStateInterface';
import { WebsocketReducers } from './reducers/websocketReducers';
import WebsocketActions from 'stores/actionInterfaces/websocketActionInterfaces';
import ResetActions from 'stores/actionInterfaces/resetActionInterfaces';
import { IBackupStatus } from 'common/interfaces/IBackupStatus';

import { clone } from 'ramda';
import { ApiLibraryState } from './stateInterfaces/apiLibraryStateInterface';
import { ApiLibraryReducers } from './reducers/apiLibraryReducers';
import { CodeSampleState } from './stateInterfaces/codeSampleStateInterface';
import { CodeSampleReducers } from './reducers/codeSampleReducers';
import { PersonaBuilderState } from './stateInterfaces/personaBuilder/personaBuilderStateInterface';
import { PersonaBuilderReducers } from './reducers/personaBuilder/personaBuilderReducers';
import { PersonaBuilderOrganizationState } from './stateInterfaces/personaBuilder/organizationStateInterface';
import { PersonaBuilderOrganizationReducers } from './reducers/personaBuilder/organizationReducers';
import { PersonaBuilderProviderState } from './stateInterfaces/personaBuilder/providerStateInterface';
import { PersonaBuilderProviderReducers } from './reducers/personaBuilder/providerReducers';
import { PersonaBuilderMedicationState } from './stateInterfaces/personaBuilder/medicationStateInterface';
import { PersonaBuilderMedicationReducers } from './reducers/personaBuilder/medicationReducers';

export interface ApplicationState {
	auth: AuthState;
	rof: RofState;
	router: RouterState;
	modal: ModalState;
	organization: OrganizationState;
	personaUpload: PersonaUploadState;
	persona: PersonaState;
	personaBuilder: PersonaBuilderState;
	personaBuilderOrganization: PersonaBuilderOrganizationState;
	personaBuilderProvider: PersonaBuilderProviderState;
	personaBuilderMedication: PersonaBuilderMedicationState;
	messages: MessageState;
	websocket: websocketState;
	apiLibrary: ApiLibraryState;
	codeSample: CodeSampleState;
	notifications: NotificationState;
	superAdmin: SuperAdminState;
}

const rootReducer = (history: History) => {
	const appReducer = combineReducers({
		auth: AuthReducers,
		rof: RofReducers,
		organization: OrganizationReducers,
		modal: ModalReducers,
		personaUpload: PersonaUploadReducers,
		persona: PersonaReducers,
		personaBuilder: PersonaBuilderReducers,
		personaBuilderOrganization: PersonaBuilderOrganizationReducers,
		personaBuilderProvider: PersonaBuilderProviderReducers,
		personaBuilderMedication: PersonaBuilderMedicationReducers,
		router: connectRouter(history),
		messages: MessageReducers,
		websocket: WebsocketReducers,
		apiLibrary: ApiLibraryReducers,
		codeSample: CodeSampleReducers,
		notifications: NotificationReducers,
		superAdmin: SuperAdminReducers,
		console: (state: any, action: any) => {
			console.log('reduce', action);
			return state || {};
		}
	});

	return (
		state: ApplicationState & { console: (state: any, action: any) => ApplicationState },
		action //: WebsocketActions | ResetActions
	) => {
		console.log(action);
		switch (action.type) {
			case 'RESET_APP': {
				//@ts-ignore
				state.websocket.socket.close();
				state = undefined;
			}

			case 'UPDATE_SANDBOX_STATUS': {
				const allRof = clone(state.rof.allRof);

				console.log('UPDATE_SANDBOX_STATUS');
				console.log('action.status', action.status);

				if (allRof[action.sandboxId]) {
					allRof[action.sandboxId].stack_status = action.status;
				}
				return {
					...state,
					rof: {
						...state.rof,
						allRof
					}
				};
			}

			case 'REMOVE_SANDBOX_FROM_STATE': {
				const allRof = clone(state.rof.allRof);

				console.log('allRof before delete', allRof);

				delete allRof[action.sandboxId];

				console.log('allRof after delete', allRof);

				return {
					...state,
					rof: {
						...state.rof,
						allRof
					}
				};
			}

			case 'ADD_SANDBOX_TO_STATE': {
				const allRof = clone(state.rof.allRof);

				const sandbox = action.sandbox;

				allRof[sandbox.id] = sandbox;

				return {
					...state,
					rof: {
						...state.rof,
						allRof
					}
				};
			}

			case 'UPDATE_TEMPLATES': {
				return {
					...state,
					rof: {
						...state.rof,
						templates: action.templates
					}
				};
			}

			case 'UPDATE_ENTITLEMENT_SUMMARY': {
				return {
					...state,
					auth: {
						...state.auth,
						entitlementSummary: action.entitlementSummary
					}
				};
			}

			case 'UPDATE_ENTITLEMENTS': {
				return {
					...state,
					auth: {
						...state.auth,
						entitlements: action.entitlements
					}
				};
			}

			case 'UPDATE_BACKUP_STATUS': {
				const allRof = clone(state.rof.allRof);
				if (allRof[action.backupStatus.rof_id]) {
					allRof[action.backupStatus.rof_id].operation_status = action.backupStatus.operation_status;
					allRof[action.backupStatus.rof_id].operation_backup_id = action.backupStatus.backup_id;
				}
				return {
					...state,
					rof: {
						...state.rof,
						allRof
					}
				};
			}
			case 'REMOVE_BACKUP_FROM_STATE': {
				const allRofBackups = clone(state.rof.allRofBackups);

				const backupStatus: IBackupStatus = action.backupStatus;

				if (allRofBackups[action.backupStatus.rof_id]) {
					const backupIndex = allRofBackups[action.backupStatus.rof_id].findIndex(
						backup => backup.id === backupStatus.backup_id
					);
					console.log('backupIndex', backupIndex);
					console.log('allRofBackups 1', allRofBackups);
					if (backupIndex >= 0) {
						allRofBackups[action.backupStatus.rof_id].splice(backupIndex, 1);
					}
					console.log('allRofBackups 2', allRofBackups);
				}

				return {
					...state,
					rof: {
						...state.rof,
						allRofBackups
					}
				};
			}

			case 'ADD_BACKUP_TO_STATE': {
				const allRofBackups = clone(state.rof.allRofBackups);
				const backupStatus: IBackupStatus = action.backupStatus;
				const allRof = clone(state.rof.allRof);
				if (allRof[backupStatus.rof_id]) {
					allRof[backupStatus.rof_id].operation_status = backupStatus.operation_status;
					allRof[backupStatus.rof_id].operation_backup_id = backupStatus.backup_id;
				}

				if (allRofBackups[backupStatus.rof_id]) {
					console.log(
						'ADD_BACKUP_TO_STATE allRofBackups[backupStatus.rof_id]',
						allRofBackups[backupStatus.rof_id]
					);
					const isBackupInState = allRofBackups[backupStatus.rof_id].some(backup => {
						console.log('ADD_BACKUP_TO_STATE backupStatus.rof_id', backupStatus.rof_id);
						console.log('ADD_BACKUP_TO_STATE backup.id', backup.id);
						console.log('ADD_BACKUP_TO_STATE backupStatus.backup_id', backupStatus.backup_id);
						console.log('ADD_BACKUP_TO_STATE backup', backup);
						console.log('ADD_BACKUP_TO_STATE backupStatus', backupStatus);
						return backup.id === backupStatus.backup_id;
					});
					console.log('ADD_BACKUP_TO_STATE isBackupInState', isBackupInState);
					if (!isBackupInState) {
						allRofBackups[backupStatus.rof_id].push({
							id: backupStatus.backup_id,
							date: backupStatus.updated_date,
							name: backupStatus.backup_name,
							created_by: backupStatus.updated_by,
							rof_id: backupStatus.rof_id
						});
					}
				}
				return {
					...state,
					rof: {
						...state.rof,
						allRof,
						allRofBackups
					}
				};
			}
		}

		return appReducer(state, action);
	};
};

export interface AppThunkAction<TAction extends Action<string>, TReturn = void> {
	(dispatch: (action: TAction) => TReturn, getState: () => ApplicationState, { }: any): TReturn;
}

export default rootReducer;
