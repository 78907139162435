import * as React from 'react';
import { RequiredIndicator } from 'components/FormElements/RequiredIndicator';
import { Tooltip, IconButton, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import classes from 'classnames';
import css from './form-label.module.scss';

interface IFormLabelProps {
	isRequired?: boolean;
	subLabel?: string;
	htmlFor: string;
	lowerLabel?: string;
	style?: string;
	infoText?: string;
}

const useStyles = makeStyles({
	tooltip: {
		borderRadius: 3,
		border: 0,
		padding: '10px',
		boxShadow: '0 3px 5px 2px rgba(200, 200, 200, .3)',
		fontSize: '1em',
	},
	button: {
		'&&:hover': {
			backgroundColor: 'transparent',
		},
	},
	svg: {
		color: 'rgb(29, 119, 132)',
	},
	label: {
		textTransform: 'capitalize',
	},
});

export const FormLabel: React.FC<IFormLabelProps> = ({
	children,
	subLabel,
	isRequired,
	htmlFor,
	lowerLabel,
	style,
	infoText,
}) => {
	const muiClasses = useStyles();
	return (
		<>
			<Grid container direction='row' alignItems='center'>
				<label
					htmlFor={htmlFor}
					className={classes({ hasSubHeading: subLabel }, css.label, { [style]: style })}
				>
					{children}
					{isRequired && <RequiredIndicator />}
				</label>
				{infoText && (
					<Tooltip title={infoText} classes={{ tooltip: muiClasses.tooltip }} placement='right-end'>
						<IconButton
							aria-label={`${children}`}
							classes={{ root: muiClasses.button }}
							// color='primary'
							disableRipple
						>
							<InfoIcon classes={{ root: muiClasses.svg }} />
						</IconButton>
					</Tooltip>
				)}
				{subLabel && <span className='subLabel'>{subLabel}</span>}
			</Grid>
			{lowerLabel && <p className={css.lowerLabel}>{lowerLabel}</p>}
		</>
	);
};
