import { Reducer } from 'redux';

import codeSampleActions from 'stores/actionInterfaces/codeSampleActionInterfaces';
import { CodeSampleState } from 'stores/stateInterfaces/codeSampleStateInterface';
import { convertArrayToIndexedObject } from 'common/utils/convertArrayToIndexedObject';
import { clone } from 'ramda';

import { EUpdateState } from 'stores/shared/ENums';

const initialState: CodeSampleState = {
	allCodeSamples: null,
	allCodeSamplesUpdateState: EUpdateState.Empty,
	allCodeSampleApis: null,
	currentCodeSample: null
};

export const CodeSampleReducers: Reducer<CodeSampleState> = (state: CodeSampleState, action: codeSampleActions) => {
	switch (action.type) {
		case 'GET_CODE_SAMPLES_REQUEST': {
			return {
				...state,
				allCodeSamplesUpdateState: EUpdateState.Loading
			};
		}
		case 'GET_CODE_SAMPLES_RECEIVED': {
			return {
				...state,
				allCodeSamples: convertArrayToIndexedObject(action.codeSamples),
				allCodeSamplesUpdateState: EUpdateState.Loaded
			};
		}
		case 'GET_CODE_SAMPLE_REQUEST': {
			return {
				...state
			};
		}
			
		case 'GET_CODE_SAMPLE_RECEIVED': {
			return {
				...state,
				currentCodeSample: action.codeSample.id,
				allCodeSamples: {
					...state.allCodeSamples,
					[action.codeSample.id]: action.codeSample
				},
				allCodeSampleApis: {
					...state.allCodeSampleApis,
					[action.codeSample.id]: action.apis
				}
			};
		}
		case 'CREATE_CODE_SAMPLE_REQUEST': {
			return {
				...state
			};
		}
		case 'CREATE_CODE_SAMPLE_RESPONSE': {
			return {
				...state,
				allCodeSamples: {
					...state.allCodeSamples,
					[action.codeSample.id]: action.codeSample
				}
			};
		}
		
		case 'UPDATE_CODE_SAMPLE_REQUEST': {
			return {
				...state
			};
		}
		case 'UPDATE_CODE_SAMPLE_RESPONSE': {
			return {
				...state,
				allCodeSamples: {
					...state.allCodeSamples, 
					[action.codeSampleId]: action.codeSample
				}
			};
		}
			
		case 'DELETE_CODE_SAMPLE_REQUEST': {
			return {
				...state
			};
		}
			
		case 'DELETE_CODE_SAMPLE_RESPONSE': {
			const allCodeSamples = clone(state.allCodeSamples);
			delete allCodeSamples[action.codeSampleId];

			return {
				...state,
				allCodeSamples
			};
		}
			
		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state || initialState;
};
