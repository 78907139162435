import * as yup from 'yup';
import { validatePhoneNumber } from 'common/utils/validatePhoneNumber';

interface IPatientFamilyMemberValidationSchemaConfig {
}

interface PatientFamilyMemberValidationSchema {
	first_name: string;
	middle_name: string;
	last_name: string;
	dob: any;
	home_phone: any;
	mobile_phone: any;
	marital_status_id: number;
	race_id: number;
	language_id: number;
	gender_id: number;
	ethnicity_id: number;
	patient_id: number;
	patient_relationship_id: number;
}

export const patientFamilyMemberValidationSchema = (
	patientFamilyMemberValidationSchemaConfig: IPatientFamilyMemberValidationSchemaConfig = {}
) => {
	let schemaDefinition = {
		first_name: yup.string().required('First Name is Required'),
		middle_name: yup.string().required('Middle Name is Required'),
		last_name: yup.string().required('Last Name is Required'),
		dob: yup.date().typeError('Date of Birth must be a valid date').required('Date of Birth is Required'),
		home_phone: yup.string().test(validatePhoneNumber).required("Home Phone Number is required."),
		mobile_phone: yup.string().test(validatePhoneNumber),
		marital_status_id: yup.number().typeError('Marital Status is invalid.').required('Marital Status is required.'),
		race_id: yup.number().typeError('Race is invalid.').required('Race is required.'),
		ethnicity_id: yup.number().typeError('Ethnicity is invalid.').required('Ethnicity is required.'),
		gender_id: yup.number().typeError('Gender is invalid.').required('Gender is required.'),
		language_id: yup.number().typeError('Language is invalid.').required('Language is required.'),
		patient_id: yup.number().typeError('Related to Patient is invalid.').required('Related to Patient is required.'),
		patient_relationship_id: yup.number().typeError('Relationship Type is invalid.').required('Relationship Type is required.'),
	};

	return yup.object().shape<any>(schemaDefinition) as any;
};
