import * as React from 'react';
import classes from 'classnames';
import css from './section.module.scss';
import { Button } from 'components/Button';

interface ISection {
	className?: string;
	title?: string;
	description?: string;
	ContentRight?: React.ElementType;
	titleSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	titleRef?: (ref: HTMLHeadingElement) => void;
	titleId?: string;
	titleClass?: string;
}

export const Section: React.FC<ISection> = props => {
	const {
		className,
		children,
		title,
		description,
		ContentRight,
		titleSize = 'h4',
		titleRef,
		titleId,
		titleClass
	} = props;
	return (
		<section className={classes(className, css.section)}>
			<div className={css.titleArea}>
				<div>
					{title && (
						<h2
							className={classes(
								titleSize,
								{
									[css.titleWithDescription]: description
								},
								titleClass
							)}
							ref={titleRef ? ref => titleRef(ref) : null}
							id={titleId}
						>
							{title}
						</h2>
					)}
					{description && <p>{description}</p>}
				</div>
				{ContentRight && (
					<div className={css.contentRightContainer}>
						<ContentRight />
					</div>
				)}
			</div>
			{children}
		</section>
	);
};
