import * as React from 'react';
import css from './auth.module.scss';
import classes from 'classnames';

import { RouteComponentProps } from 'react-router';
import { IFormActions } from 'common/interfaces/IFormActions';
import { Error } from 'components/Toast/Error';
import { ReactComponent as InteroperabilityLandLogo } from 'assets/interoperability-land-logo.svg';
import { Link } from 'react-router-dom';

interface IAppFrameAuthProps {
	backgroundImage:
		| 'computerChip'
		| 'buildings'
		| 'testTubeAndComputer'
		| 'buildingsAndMedicine'
		| 'hospitalAndChip';
	ContentSecondary?: React.ElementType;
	title: string;
	titleSecondary?: string;
	description: string;
	descriptionSecondary?: string;
	Content?: React.ElementType<{ formActions: IFormActions }>;
	hasHeader?: boolean;
}

interface IAppFrameAuthState {
	errorMessage: string;
	successMessage: string;
	isLoading: boolean;
	isDisabled: boolean;
}

export class AppFrameAuth extends React.PureComponent<IAppFrameAuthProps, IAppFrameAuthState> {
	static defaultProps = {
		backgroundImage: 'hospitalAndChip'
	};

	constructor(props) {
		super(props);
		this.state = {
			errorMessage: null,
			successMessage: null,
			isLoading: null,
			isDisabled: null
		};
	}

	setErrorMessage = (errorMessage: string) => {
		this.setState({
			errorMessage
		});
	};

	setSuccessMessage = (successMessage: string) => {
		this.setState({
			successMessage
		});
	};

	setFormLoadingAttribute = (isLoading: boolean) => {
		this.setState({
			isLoading
		});
	};

	setFormDisabledAttribute = (isDisabled: boolean) => {
		this.setState({
			isDisabled
		});
	};

	render() {
		const {
			children,
			backgroundImage,
			ContentSecondary,
			title,
			description,
			Content,
			hasHeader,
			titleSecondary,
			descriptionSecondary
		} = this.props;

		return (
			<div className={css.appFrameAuth}>
				<div className={classes(css.authFrameRight, css[backgroundImage])} />
				<div
					className={classes(css.authFrameLeft, {
						[css.isLarge]: ContentSecondary
					})}
				>
					{hasHeader && (
						<div className={css.logoContainer}>
							<Link to='/'>
								<InteroperabilityLandLogo className={css.logoHeader} />
							</Link>
						</div>
					)}

					<div className={css.contentContainer}>
						<div
							className={classes(
								{
									[css.content]: ContentSecondary
								},
								css.primaryContent,
								{ [css.primaryContentWide]: ContentSecondary }
							)}
						>
							{!hasHeader && <InteroperabilityLandLogo className={css.logoInternal} />}
							<div
								className={classes({
									[css.contentSection]: ContentSecondary
								})}
							>
								<h1 className='h3'>{title}</h1>
								<p className={css.description}>{description}</p>
								{this.state.errorMessage && <Error>{this.state.errorMessage}</Error>}

								{Content && (
									<Content
										formActions={{
											setFormDisabledAttribute: this.setFormDisabledAttribute,
											setFormLoadingAttribute: this.setFormLoadingAttribute,
											setErrorMessage: this.setErrorMessage
										}}
									/>
								)}
								{children}
							</div>
							{ContentSecondary && (
								<div className={css.contentSection}>
									<h1 className='h3'>{titleSecondary}</h1>
									<p className={css.description}>{descriptionSecondary}</p>
									<ContentSecondary />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
