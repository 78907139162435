import * as React from 'react';
import { AppFrame, InnerContainer, PageTitleArea } from 'components/Page';
import { ApplicationState } from 'stores';
import { EUpdateState } from 'stores/shared/ENums';
import { connect, ResolveThunks } from 'react-redux';
import { ProviderListingTab } from './ProviderListingTab/ProviderListingTab';
import { ProviderActionCreators } from 'stores/actions/personaBuilder/providerActions';

interface IProviderViewReduxProps {
	applyPersonaUpdateState: EUpdateState;
}

const ProviderViewBase: React.FC<IProviderViewReduxProps &
	ResolveThunks<typeof ProviderActionCreators>> = props => {
		React.useEffect(()=>{
			props.populateProviderDropdowns();
			props.getAllProviders();
		}, []);
		return (
			<AppFrame>
				<InnerContainer>
					<PageTitleArea
						breadcrumbItems={[
							{
								label: 'Creation Dashboard',
								location: '/persona-builder'
							},
							{
								label: 'All Providers',
								location: `/persona-builder/provider-listing`
							}
						]}
					>
					</PageTitleArea>
					<ProviderListingTab />
				</InnerContainer>
			</AppFrame>
		);
	}
	
	const mapStateToProps = (state: ApplicationState): IProviderViewReduxProps => {
		return {
			applyPersonaUpdateState: state.persona.applyPersonaUpdateState
		};
	};
	
	export const ProviderView = connect(
		mapStateToProps,
		ProviderActionCreators
	)(ProviderViewBase);
	